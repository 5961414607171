import React, { useState } from "react"
import { Flex } from "@/utils/components/style/flex"
import ModalBodyForTable from "../ModalBodyForTable"
import styled from "styled-components"
import { Alert } from "@mui/material"
import NumberField from "@/utils/NumberField"
import {
	PrimaryButton,
	PrimaryTextButton,
} from "@/utils/components/style/button"
import { useBoolean } from "react-use"
import Loading from "@/utils/Loading"
import ModalFooter from "@/styles/organisms/Modal/ModalFooter"
import ModalHeader from "@/styles/organisms/Modal/ModalHeader"
import Modal from "@/styles/organisms/Modal"
import { ModalContent } from "@nextui-org/react"
import Button from "@/styles/atoms/Button"

const SModalBodyForTable = styled(ModalBodyForTable)`
    justify-content: center;
`
const SAlert = styled(Alert)`
    width: 100% !important;
    justify-content: center;
`

const ModalBuffing = ({
	isSelectingRange,
	setIsSelectingRange,
	isLoading,
	updateRangeBuff,
}: {
	isSelectingRange: boolean
	setIsSelectingRange: (isSelectingRange: boolean) => void
	isLoading: boolean
	updateRangeBuff: (rangeValue: number) => void
}) => {
	const [rangeValue, setRangeValue] = useState(0)
	return (
		<Modal
			isOpen={isSelectingRange || isLoading}
			size="2xl"
			onClose={() => setIsSelectingRange(false)}
		>
			<ModalContent>
				<ModalHeader>
					{isSelectingRange ? "Élargir la zone" : "Élargissement de la zone"}
				</ModalHeader>
				<SModalBodyForTable>
					{isSelectingRange && (
						<Flex fullWidth justifyCenter>
							<NumberField
								name="range"
								label="Étendre sur (optionnel)"
								initialValue={rangeValue}
								onChange={(newRange) => setRangeValue(newRange)}
								endAdornment="mètres"
							/>
						</Flex>
					)}
					{isLoading && (
						<SAlert severity="info">
							<Flex fullWidth justifyCenter directionColumn gap=".5em">
								<Flex fullWidth alignItemsCenter gap=".5em">
									<div>
										Veuillez patienter, l'élargissement de vôtre zone peu
										prendre plusieurs secondes.
									</div>
									<Loading />
								</Flex>
								<b>(Cette fenêtre se fermera automatiquement.)</b>
							</Flex>
						</SAlert>
					)}
				</SModalBodyForTable>
				{isSelectingRange && (
					<ModalFooter>
						<Button variant="light" onClick={() => setIsSelectingRange(false)}>
							Annuler
						</Button>
						<Button color="primary" onClick={() => updateRangeBuff(rangeValue)}>
							Élargir
						</Button>
					</ModalFooter>
				)}
			</ModalContent>
		</Modal>
	)
}
export default ModalBuffing
