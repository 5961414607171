import filetypeCsv from "@iconify/icons-bi/filetype-csv"
import filetypeDoc from "@iconify/icons-bi/filetype-doc"
import filetypeDocx from "@iconify/icons-bi/filetype-docx"
import filetypeGif from "@iconify/icons-bi/filetype-gif"
import filetypePdf from "@iconify/icons-bi/filetype-pdf"
import filetypePng from "@iconify/icons-bi/filetype-png"
import filetypeTxt from "@iconify/icons-bi/filetype-txt"
import filetypeJpg from "@iconify/icons-bi/filetype-jpg"
import filetypeXlsx from "@iconify/icons-bi/filetype-xlsx"
import filetypeXls from "@iconify/icons-bi/filetype-xls"
import fileIcon from "@iconify/icons-bi/file"
import fileEarmarkZip from "@iconify/icons-bi/file-earmark-zip"
import { Icon } from "@iconify/react"
import moment from "moment"
import { default as React, useMemo } from "react"
import styled, { css } from "styled-components"
import useUploadedFiles from "./useUploadedFiles"

const SIcon = styled(Icon)`
    ${({ color, backgroundColor }: any) =>
			css`
            color: ${color};
            padding: 14px;
            background-color: ${backgroundColor};
            border-radius: 5px;
        `}
` as any

/**
 * Take fileId as string in argument
 * return {
 *  icon: reactIcon,
 *  filename: string,
 *  updated_at: formated string
 * }
 */
const useUploadedFileBuilder = (file) => {
	const uploadedFiles = useUploadedFiles()
	const icon = useMemo(() => {
		const splittedName = file?.filename.split(".")
		if (!splittedName) {
			return ""
		}
		const extension = splittedName[splittedName.length - 1]

		switch (extension.toLowerCase()) {
			case "png":
				return (
					<SIcon
						color="#4309BF"
						backgroundColor="#EAE5F7"
						icon={filetypePng}
						width="68"
					/>
				)
			case "pdf":
				return (
					<SIcon
						color="#E0021B"
						backgroundColor="#FFE0E3"
						icon={filetypePdf}
						width="68"
					/>
				)
			case "gif":
				return (
					<SIcon
						color="#4309BF"
						backgroundColor="#EAE5F7"
						icon={filetypeGif}
						width="68"
					/>
				)
			case "doc":
			case "odt":
				return (
					<SIcon
						backgroundColor="#DEEAFF"
						color="#004FD4"
						icon={filetypeDoc}
						width="68"
					/>
				)
			case "docx":
				return (
					<SIcon
						backgroundColor="#DEEAFF"
						color="#004FD4"
						icon={filetypeDocx}
						width="68"
					/>
				)
			case "txt":
				return (
					<SIcon
						color={"black"}
						backgroundColor="#d3d3d3"
						icon={filetypeTxt}
						width="68"
					/>
				)
			case "jpg":
			case "jpeg":
				return (
					<SIcon
						color="#4309BF"
						backgroundColor="#EAE5F7"
						icon={filetypeJpg}
						width="68"
					/>
				)
			case "xls":
				return (
					<SIcon
						color="#2B8D20"
						backgroundColor="#EAE5F7"
						icon={filetypeXls}
						width="68"
					/>
				)
			case "xlsx":
				return (
					<SIcon
						color="#2B8D20"
						backgroundColor="#EAE5F7"
						icon={filetypeXlsx}
						width="68"
					/>
				)
			case "csv":
				return (
					<SIcon
						color="#2B8D20"
						backgroundColor="#E2F8DF"
						icon={filetypeCsv}
						width="68"
					/>
				)
			case "zip":
				return (
					<SIcon
						color="#A39700"
						backgroundColor="#FFF8A1"
						icon={fileEarmarkZip}
						width="68"
					/>
				)
			default:
				return (
					<SIcon
						color="black"
						backgroundColor="#EEEEF0"
						icon={fileIcon}
						width="68"
					/>
				)
		}
	}, [file, uploadedFiles])
	return {
		...file,
		icon,
		updated_at: file?.updated_at
			? moment(file.updated_at).format("DD/MM/YYYY HH:mm")
			: "",
	}
}

export default useUploadedFileBuilder
