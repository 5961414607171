import ArrowBackIcon from "@mui/icons-material/ArrowBack"
import usePoints from "@/hooks/usePoints"
import Debug from "debug"
import React from "react"
import { useHistory, useParams } from "react-router"
import PersistentCustomTabs from "utils/components/customTabs/PersistentCustomTabs"
import { PrimaryTextButton } from "utils/components/style/button"
import CareCard from "./CareCard/CareCard"
import FamilyEnterTable from "./FamilyEnterTable"
import FamilyLeavingTable from "./FamilyLeavingTable"

// app:javascript:components:maincourante:care:DaybookCare.tsx
const debug = Debug("app:javascript:components:maincourante:care:DaybookCare")
debug.log = console.log.bind(console)

const DaybookCare = () => {
	const history = useHistory()

	// @ts-ignore
	const { careId } = useParams()
	const points = usePoints()

	return (
		<>
			<PrimaryTextButton
				startIcon={<ArrowBackIcon />}
				onClick={() => {
					history.push("/maincourante/donnees")
				}}
			>
				Retour
			</PrimaryTextButton>
			<PersistentCustomTabs
				localStorageKey="DaybookCare:customtabs"
				tabChildrens={[
					{
						key: "Résumé",
						render: (
							<CareCard
								care={points[careId]}
								key={careId}
								showActions={false}
							/>
						),
					},
					{
						key: "Tableau des entrées",
						render: <FamilyEnterTable />,
					},
					{
						key: "Tableau des sorties",
						render: (
							<>
								<FamilyLeavingTable />
							</>
						),
					},
				]}
			/>
		</>
	)
}

export default DaybookCare
