import { yupResolver } from "@hookform/resolvers/yup"
import EditIcon from "@mui/icons-material/Edit"
import SaveIcon from "@mui/icons-material/Save"
import { IconButton } from "@mui/material"
import usePoints from "@/hooks/usePoints"
import useSelectedEvent from "@/hooks/useSelectedEvent"
import { updatePointWithUpdateEvent } from "@/redux-toolkit/data/points/resources"
import Moment from "moment"
import React, { useEffect, useState } from "react"
import { isMobile } from "react-device-detect"
import { FormProvider, WatchObserver, useForm } from "react-hook-form"
import { useDispatch } from "react-redux"
import { useParams } from "react-router-dom"
import styled, { css } from "styled-components"
import { Flex } from "utils/components/style/flex"
import TitleHeader from "utils/components/TitleHeader"
import FText from "utils/form/FText"
import {
	DaybookAlertCanal,
	DaybookAlertType,
} from "@/utils/types/daybook/IDaybookAlert"
import Yup from "utils/yup"
import DaybookAlertCampaignPrepare from "./alertStep/DaybookAlertCampaignPrepare"
import DaybookAlertCampaignReady from "./alertStep/DaybookAlertCampaignReady"
import DaybookAlertCampaignSending from "./alertStep/DaybookAlertCampaignSending"
import DaybookAlertStatusStepper from "./statusStepper/DaybookAlertStatusStepper"
import { TypeOfShape } from "yup/lib/object"
import DaybookAlertPrepareMessage from "./alertStep/components/prepare/DaybookAlertPrepareMessage"
import DaybookAlertPrepareDiffuseList from "./alertStep/components/prepare/DaybookAlertPrepareDiffuseList"
import useAccessRights from "@/hooks/useAccessRights"
import { PATH_MAIN_COURANTE_ALERTE } from "@/redux-toolkit/userSettings/constants"
import ErrorPermissions, {
	BACK_PATH_HOME,
	BACK_TITLE_HOME,
	FEATURE_ERROR_PERMISSION,
} from "@/hooks/ErrorPermissions"
import { EnumToolPath } from "@/hooks/services/useAccessRightsServices"

const BackgroundAlt = styled.div`
    background-color: #f9fafb;
    height: 100%;
    padding: 1.5rem;
    ${
			isMobile &&
			css`
        padding: 0;
        overflow-y: auto;
    `
		}
`

const SFText = styled(FText)`
    min-width: 400px;
    & .MuiOutlinedInput-input {
        padding: 0.99rem 0.75rem;
        background-color: white !important;
    }
    & .MuiOutlinedInput-root {
        max-width: 550px;
    }
`

const schema = Yup.object().shape({
	geojson: Yup.object().shape({
		properties: Yup.object().shape({
			name: Yup.string().required(),
			status: Yup.string().required().default(DaybookAlertType.PREPARE_DIFFUSE),
			type: Yup.string().required().default(DaybookAlertCanal.SMS),
			listeDiffusions: Yup.array().of(Yup.string()).default([]),
			listeDiffusionIds: Yup.array().of(Yup.string()).default([]),
			includeFixedPhones: Yup.boolean().default(true),
			smsText: Yup.string().when("type", {
				is: DaybookAlertCanal.SMS,
				then: Yup.string().required(),
			}),
			voiceContent: Yup.string().when("type", {
				is: DaybookAlertCanal.VOCALE,
				then: Yup.string().required(),
			}),
			selectedMultiPolygonId: Yup.string(),
			rangeValue: Yup.number().default(0),
			keepUngeolocDatas: Yup.boolean().default(true),
		}),
	}),
})

const DaybookAlertCampaign = () => {
	const { alertId } = useParams<{ alertId: string }>()
	const points = usePoints()
	const [isEditingName, setIsEditingName] = useState(false)
	const selectedEvent = useSelectedEvent()
	const dispatch = useDispatch()
	const readPermission = useAccessRights(EnumToolPath.ALERTE).read
	const isLocked = selectedEvent?.locked

	const methods = useForm({
		resolver: yupResolver(schema),
		defaultValues: schema.getDefault(),
		mode: "onChange",
	})

	useEffect(() => {
		if (alertId) {
			const currentAlert = points[alertId] as any
			methods.reset(currentAlert)
		} else {
			methods.reset(schema.getDefault())
		}
	}, [alertId, points])
	const statusPath = "geojson.properties.status" as unknown as WatchObserver<
		TypeOfShape<any>
	>
	const status = methods.watch(statusPath) as unknown as DaybookAlertType
	const statusLabel = [
		DaybookAlertType.PREPARE_DIFFUSE,
		DaybookAlertType.PREPARE_MESSAGE,
	].includes(status)
		? DaybookAlertType.PREPARE
		: status

	const statusName = "geojson.properties.name" as unknown as WatchObserver<
		TypeOfShape<any>
	>
	const name = methods.watch(statusName)
	const sstatus = status as DaybookAlertType

	if (!readPermission) {
		return (
			<ErrorPermissions
				errorMessage={FEATURE_ERROR_PERMISSION}
				backTitle={BACK_TITLE_HOME}
				backUrl={BACK_PATH_HOME}
			/>
		)
	}

	return (
		<BackgroundAlt>
			<FormProvider {...methods}>
				<Flex directionColumn gap="1rem" fullWidth alignItemsInitial>
					<TitleHeader
						title={`Campagne : ${statusLabel}`}
						backArrowUrl="/maincourante/alerte"
						backArrowTitle="Retour"
						wrapper={false}
						rightElements={
							<Flex fullWidth gap="1rem">
								{isEditingName && (
									<Flex>
										<SFText
											name="geojson.properties.name"
											label="Nom de la campagne"
											placeholder="Écrire un nom pour la campagne"
										/>
										<IconButton
											onClick={() => {
												setIsEditingName(false)
												dispatch(
													updatePointWithUpdateEvent({
														point: methods.getValues(),
														event: {
															...selectedEvent,
															updated_at: Moment().format(),
														},
													}),
												)
											}}
										>
											<SaveIcon />
										</IconButton>
									</Flex>
								)}
								{!isEditingName && (
									<>
										{name}
										{!isLocked && (
											<IconButton onClick={() => setIsEditingName(true)}>
												<EditIcon />
											</IconButton>
										)}
									</>
								)}
							</Flex>
						}
					/>
					<DaybookAlertStatusStepper />
					{
						{
							[DaybookAlertType.PREPARE_DIFFUSE]: (
								<DaybookAlertCampaignPrepare>
									<DaybookAlertPrepareDiffuseList />
								</DaybookAlertCampaignPrepare>
							),
							[DaybookAlertType.PREPARE_MESSAGE]: (
								<DaybookAlertCampaignPrepare>
									<DaybookAlertPrepareMessage />
								</DaybookAlertCampaignPrepare>
							),
							[DaybookAlertType.READY]: <DaybookAlertCampaignReady />,
							[DaybookAlertType.SENDING]: <DaybookAlertCampaignSending />,
							[DaybookAlertType.FINISHED]: <DaybookAlertCampaignSending />,
							[DaybookAlertType.ARCHIVED]: <DaybookAlertCampaignSending />,
						}[status as DaybookAlertType]
					}
				</Flex>
			</FormProvider>
		</BackgroundAlt>
	)
}

export default DaybookAlertCampaign
