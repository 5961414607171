import {
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
} from "@mui/material"
import {
	booleanContains,
	feature,
	featureCollection,
	multiPolygon,
	point as tpoint,
	polygon,
} from "@turf/turf"
import Debug from "debug"
import _ from "lodash"
import React, { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { PrimaryButton } from "utils/components/style/button"
import useKeyboardJs from "utils/keyboard/useKeyboardJs"
import { JsonSchemaPropertiesType } from "@/utils/types/IJsonSchema"
import IMultiPolygon from "utils/types/IMultiPolygon"
import JsonSchemaService from "./carto2/cartographie/service/JsonSchemaService"
import useJsonSchemasWithStatics from "@/hooks/useJsonSchemasWithStatics"
import useMultiPolygons from "@/hooks/useMultiPolygons"
import usePoints from "@/hooks/usePoints"
import PointsApi from "../redux-toolkit/data/points/PointsApi"
import DatabaseService from "../services/DatabaseService"
import SynchroPointService from "../services/synchro/SynchroPointService"

// app:javascript:components:SectorMigration.tsx
const debug = Debug("app:javascript:components:SectorMigration.tsx")
debug.log = console.log.bind(console)

const SectorMigration = () => {
	// * state
	const [isPressed] = useKeyboardJs("ctrl + shift + alt + p")
	const [isDialogOpen, setIsDialogOpen] = useState(false)
	const [toModifyPoints, setToModifyPoints] = useState([])
	const [currentStep, setCurrentStep] = useState(0)
	const [processedElement, setProcessedElement] = useState(0)
	// * selector
	// const selector = useSelector(state => state)
	const points = usePoints()
	const jsonSchemas = useJsonSchemasWithStatics()
	const multiPolygons = useMultiPolygons()

	// * dispatch
	const dispatch = useDispatch()

	// * history
	// const history = useHistory()

	// * useEffect
	useEffect(() => {
		if (isPressed) {
			setIsDialogOpen(true)
		}
	}, [isPressed])

	const migrate_step_1 = ({ withSecteur }) => {
		// * migration
		const pointsToModify = []
		Object.values(jsonSchemas)
			.filter((jsonSchema) => JsonSchemaService.isGeolocalisable(jsonSchema))
			.forEach((jsonSchema) => {
				Object.values(points)
					.filter((point) => point.jsonschema_id === jsonSchema.id)
					.forEach((point) => {
						const newPoint = _.cloneDeep(point)
						const geolocProperty = Object.values(
							jsonSchema.template.properties,
						).find(
							(property) => property.type === JsonSchemaPropertiesType.GEOLOC,
						)
						if (
							point.geojson.properties[geolocProperty.name]?.coo?.lat ===
								undefined ||
							point.geojson.properties[geolocProperty.name]?.coo?.lng ===
								undefined
						) {
							return
						}
						if (withSecteur) {
							pointsToModify.push({
								point,
								geolocPropertyName: geolocProperty.name,
							})
						} else {
							if (
								point.geojson.properties[geolocProperty.name]?.secteur ===
								undefined
							) {
								pointsToModify.push({
									point,
									geolocPropertyName: geolocProperty.name,
								})
							}
						}
					})
			})
		setToModifyPoints(pointsToModify)
		setCurrentStep(1)
	}

	const customContains = (feature, point) => {
		if (feature.type === "FeatureCollection") {
			return feature.features.some((f) => customContains(f, point))
		}
		if (feature.type === "Feature") {
			return customContains(feature.geometry, point)
		}
		if (feature.type === "MultiPolygon") {
			return feature.coordinates.some((polygonCoordinates) =>
				booleanContains(
					{
						type: "Polygon",
						coordinates: polygonCoordinates,
					},
					point,
				),
			)
		}
		return booleanContains(feature, point)
	}

	const migrate_step_2 = async () => {
		await Promise.all(
			toModifyPoints.map(async ({ point, geolocPropertyName }, index) => {
				setTimeout(async () => {
					const newPoint = _.cloneDeep(point)
					let calculatedSecteur
					await Promise.all(
						Object.values(multiPolygons)
							.filter(
								(multiPolygon: IMultiPolygon) =>
									multiPolygon.custom_props.secteur,
							)
							.map(async (poly: IMultiPolygon) => {
								if (!calculatedSecteur) {
									let couche = await DatabaseService.get(poly.id)
									couche = JSON.parse(couche)
									if (Array.isArray(couche)) {
										couche = {
											type: "FeatureCollection",
											features: couche,
										}
									}
									let turfCouche
									const turfPoint = tpoint([
										point.geojson.properties[geolocPropertyName].coo.lng,
										point.geojson.properties[geolocPropertyName].coo.lat,
									])
									let contained = false
									if (couche["type"] === "Polygon") {
										turfCouche = polygon(couche["coordinates"])
										contained = booleanContains(turfCouche, turfPoint)
									}
									if (couche["type"] === "MultiPolygon") {
										turfCouche = multiPolygon(couche["coordinates"])
										contained = booleanContains(turfCouche, turfPoint)
									}
									if (couche["type"] === "Feature") {
										turfCouche = feature(couche)
										contained = booleanContains(turfCouche, turfPoint)
									}
									if (couche["type"] === "FeatureCollection") {
										turfCouche = featureCollection(couche)
										contained = customContains(turfCouche.features, turfPoint)
									}
									if (!turfCouche) {
										return
									}
									if (contained) {
										calculatedSecteur = poly.id
									}
								}
							}),
					)
					if (calculatedSecteur) {
						newPoint.geojson.properties[geolocPropertyName].secteur =
							calculatedSecteur
						await PointsApi.updatePoint(newPoint)
					} else {
						newPoint.geojson.properties[geolocPropertyName].secteur =
							"Hors secteur"
						await PointsApi.updatePoint(newPoint)
					}
					setProcessedElement(index + 1)
				}, index * 400)
			}),
		)
	}

	useEffect(() => {
		if (processedElement === toModifyPoints.length && processedElement > 0) {
			setToModifyPoints([])
			setProcessedElement(0)
			setCurrentStep(0)
			setIsDialogOpen(false)
			dispatch(SynchroPointService.synchronizePointAction())
		}
	}, [processedElement])

	return (
		<>
			<Dialog
				open={isDialogOpen}
				onClose={() => {
					setIsDialogOpen(false)
				}}
			>
				{
					{
						0: (
							<>
								<DialogTitle>migration</DialogTitle>
								<DialogContent>
									Voulez vous vraiment Migrer vos adresses sur les secteurs ?
								</DialogContent>
								<DialogActions>
									<PrimaryButton
										onClick={() =>
											migrate_step_1({
												withSecteur: false,
											})
										}
									>
										Migrer point sans secteur
									</PrimaryButton>
									<PrimaryButton
										onClick={() =>
											migrate_step_1({
												withSecteur: true,
											})
										}
									>
										Migrer point avec secteur
									</PrimaryButton>
								</DialogActions>
							</>
						),
						1: (
							<>
								<DialogTitle>migration</DialogTitle>
								<DialogContent>
									{processedElement !== 0 &&
										`${processedElement}/${toModifyPoints.length}`}
									{processedElement === 0 && toModifyPoints.length} points à
									migrer
								</DialogContent>
								<DialogActions>
									<PrimaryButton onClick={migrate_step_2}>
										Valider
									</PrimaryButton>
								</DialogActions>
							</>
						),
					}[currentStep]
				}
			</Dialog>
		</>
	)
}

export default SectorMigration
