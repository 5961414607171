import JsonSchemaService from "@/pages/carto2/cartographie/service/JsonSchemaService"
import useAllPoints from "@/hooks/useAllPoints"
import useAllStockPlaces from "@/hooks/useAllStockPlaces"
import useAllStocks from "@/hooks/useAllStocks"
import useAllVehicules from "@/hooks/useAllVehicules"
import useCdcSharedJsonSchemas from "@/hooks/useCdcSharedJsonSchemas"
import useCooperationFilters from "@/hooks/useCooperationFilters"
import useJsonSchemasWithStatics from "@/hooks/useJsonSchemasWithStatics"
import { SearchMode } from "@/redux-toolkit/edit/cooperationFiltersRessources"
import _ from "lodash"
import { StaticFormType } from "utils/services/StaticFormsService"
import { JsonSchemaPropertiesType } from "@/utils/types/IJsonSchema"
import IPoint from "utils/types/IPoint"

function useTagFilter() {
	const coopFilters = useCooperationFilters()

	const allPoints = useAllPoints()
	const allStockPlaces = useAllStockPlaces()
	const cdcSharedJsonSchemas = useCdcSharedJsonSchemas()
	const jsonSchemasWithStatics = useJsonSchemasWithStatics()
	const allJsonSchemas = { ...cdcSharedJsonSchemas, ...jsonSchemasWithStatics }

	// point which have a stockplace property (stocks, vehicules and possibly others)
	const layersStockplacePropertyIds = Object.values(allJsonSchemas).map((jSchema) => jSchema.template.properties.map((prop) => prop.type === JsonSchemaPropertiesType.STOCKPLACE ? jSchema.id : null)).flat().filter((item) => item !== null)
	// stocks, vehicules and possible other point with tag properties
	const pointsWithStockplaceIds = Object.values(allPoints).filter((point) => layersStockplacePropertyIds.includes(point.jsonschema_id))
	const stockPlacesIds = Object.values(allStockPlaces)
		.filter((sp) => {

			if (
				_.isEmpty(coopFilters.tag) &&
				_.isEmpty(coopFilters.layer)
			) return true

			// all stocks inside the stockplace
			const filteredPoints = Object.values(pointsWithStockplaceIds)
				.filter(
					point => {
						const currentJsonSchema = allJsonSchemas[point.jsonschema_id]
						return point.geojson.properties[JsonSchemaService.getStockPlaceProperty(currentJsonSchema).name] === sp.id;
					},
				)

			//* keeping points with a stockplace and without tags properties
			let dataWOutTagsProperty = false
			filteredPoints.forEach((point) => {
				const currentJsonSchema = allJsonSchemas[point.jsonschema_id]
				const tagProperty = JsonSchemaService.getTagProperty(currentJsonSchema)
				if (
					!point.geojson.properties[tagProperty?.name] &&
					!layersStockplacePropertyIds.includes(coopFilters.layer.key)
				) {
					if (sp.id === "4ae920c6-2490-4840-8e67-b213d1418850") {
						debugger
					}
					return dataWOutTagsProperty = true
				}
			})
			if (dataWOutTagsProperty) return true

			const tags = filteredPoints.reduce(
				(acc, stock) => {
					const currentJsonSchema = allJsonSchemas[stock.jsonschema_id]
					const tagProperty = JsonSchemaService.getTagProperty(currentJsonSchema)
					return [...acc, ...(stock.geojson.properties[tagProperty?.name] ?? [])];
				},
				[],
			)

			if (coopFilters.tag === undefined) {
				return true
			}

			return tags.includes(coopFilters.tag.key)
		})
		.map((sp) => sp.id)

	return (item: IPoint) => {
		// Filter disabled
		if (SearchMode.DONNEES_PARTAGEES === coopFilters.search) {
			if (_.isEmpty(coopFilters.tag) && _.isEmpty(coopFilters.layer)) return true
		} else {
			if (_.isEmpty(coopFilters.tag)) return true
		}

		// Filter enabled
		if (coopFilters.layer.jsonschema_id === item.jsonschema_id) return true

		switch (item.jsonschema_id) {
			case StaticFormType.PCC:
			case StaticFormType.CARE:
			case StaticFormType.STOCK_PLACE:
				if (
					layersStockplacePropertyIds.includes(coopFilters.layer.key) ||
					!_.isEmpty(coopFilters.tag)
				) {
					return stockPlacesIds.includes(item.id)
				} else {
					return false
				}
			default:
				return false
		}
	}
}

export default useTagFilter
