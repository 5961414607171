import React, { ReactElement, useEffect } from "react"
import PanTool from "@mui/icons-material/PanTool"
import {
	EnumToolPath,
	IAccessRights,
} from "@/hooks/services/useAccessRightsServices"
import People from "@mui/icons-material/People"
import _ from "lodash"
import { useDispatch, useSelector } from "react-redux"
import { setSelectedEvent } from "@/redux-toolkit/common/reducer"
import Tune from "@mui/icons-material/Tune"
import DashboardIcon from "@mui/icons-material/Dashboard"
import Campaign from "@mui/icons-material/Campaign"
import Map from "@mui/icons-material/Map"
import intercoNameAdapter from "@/utils/intercoNameAdapter"
import ContactPhone from "@mui/icons-material/ContactPhone"
import Checklist from "@mui/icons-material/Checklist"
import useIsInterco from "@/hooks/useIsInterco"
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome"
import ReceiptLong from "@mui/icons-material/ReceiptLong"
import Share from "@mui/icons-material/Share"
import DescriptionIcon from "@mui/icons-material/Description"
import Upload from "@mui/icons-material/Upload"
import useCooperationConfig from "@/pages/cooperation/customdata/hooks/useCooperationConfig"
import { SearchMode } from "@/redux-toolkit/edit/cooperationFiltersRessources"
import Home from "@mui/icons-material/Home"
import { isMobile } from "react-device-detect"
import useKeyboardJs from "@/utils/keyboard/useKeyboardJs"
import { useHistory } from "react-router-dom"
import useSelectedEvent from "@/hooks/useSelectedEvent"
import ReportProblemIcon from "@mui/icons-material/ReportProblem"
import NoteAltIcon from "@mui/icons-material/NoteAlt"
import useIsOpenMenu from "@/hooks/useIsMenuOpen"

export interface ISubMenu {
	name: string
	route: string
	path: EnumToolPath
	description: string
	icon: ReactElement
	group?: string
	shortcut?: string
	disabled?: boolean
	color?: "primary" | "secondary" | "default" | "danger"
}

export interface ITool {
	name: string
	route: string
	path: EnumToolPath
	description: string
	icon: ReactElement
	subMenus?: ISubMenu[]
}

export const useSideBarTools = () => {
	const accessRights = useSelector(
		(state: any) => state.userSettings.settings.accessRights,
	) as IAccessRights
	const dispatch = useDispatch()
	const isInterco = useIsInterco()
	const { modesEnabled } = useCooperationConfig()
	const history = useHistory()
	const [isPressedCustomData] = useKeyboardJs("alt + 1", true)
	const selectedEvent = useSelectedEvent()

	const isOpenMenu = useIsOpenMenu()

	useEffect(() => {
		if (isPressedCustomData) {
			history.push("/customdata/my_forms")
		}
	}, [isPressedCustomData])

	const [isPressedCarto] = useKeyboardJs("alt + 2", true)

	useEffect(() => {
		if (isPressedCarto) {
			history.push("/customdata/cartographie")
		}
	})
	const [isPressedDaybookData] = useKeyboardJs("ctrl + 1", true)

	useEffect(() => {
		if (isPressedDaybookData) {
			history.push("/maincourante/donnees")
		}
	}, [isPressedDaybookData])

	const [isPressedDaybookCarto] = useKeyboardJs("ctrl + 2", true)

	useEffect(() => {
		if (isPressedDaybookCarto) {
			history.push("/maincourante/carto")
		}
	}, [isPressedDaybookCarto])

	const [isPressedDaybookDashboard] = useKeyboardJs("ctrl + 3", true)

	useEffect(() => {
		if (isPressedDaybookDashboard) {
			history.push("/maincourante/dashboard")
		}
	}, [isPressedDaybookDashboard])

	const [isPressedDaybookSelectCrisis] = useKeyboardJs("ctrl + 4", true)

	useEffect(() => {
		if (isPressedDaybookSelectCrisis) {
			history.push("/maincourante/select")
		}
	}, [isPressedDaybookSelectCrisis])

	const checkPermission = (tab_path: EnumToolPath) => {
		return _.get(accessRights?.tools, tab_path, {
			read: false,
			write: false,
			// @ts-ignore
		})?.read
	}

	const checkDisplayableSubMenu = (subMenu: ISubMenu[]) => {
		const subMenuDisplayed = []
		subMenu.forEach((element) => {
			const permissionsRead = checkPermission(element.path)
			if (permissionsRead) {
				subMenuDisplayed.push(element)
			}
		})

		return subMenuDisplayed
	}

	const notIntercoBuilder = (element) => {
		return isInterco ? [] : [element]
	}

	const groupName = selectedEvent ? `${selectedEvent.name}` : "Outils"

	const subMenuMainCourante = [
		{
			name: "Choisir une crise",
			route: "/maincourante/select",
			path: EnumToolPath.GC_DASHBOARD,
			description:
				"Choisissez une crise sur laquelle vous souhaitez travailler",
			icon: <Tune />,
			group: "Sélection",
			onClick: () => {
				dispatch(setSelectedEvent(undefined))
			},
			color: "primary",
			shortcut: "Ctrl + 4",
		},
		{
			name: "Tableau de bord",
			route: "/maincourante/dashboard",
			path: EnumToolPath.GC_DASHBOARD,
			description: "Le monitoring de votre crise ainsi que son fil d’actualité",
			icon: <DashboardIcon />,
			group: groupName,
			disabled: !selectedEvent,
			shortcut: "Ctrl + 3",
		},
		{
			name: "Organigramme de crise",
			route: "/maincourante/orga",
			path: EnumToolPath.GC_ORGA,
			icon: <People />,
			description:
				"Gérer le présentiel des membres de votre organigramme de crise",
			group: groupName,
			disabled: !selectedEvent,
		},
		{
			name: "Télé-alerte",
			route: "/maincourante/alerte",
			path: EnumToolPath.ALERTE,
			description:
				"Déclenchez une alerte depuis un annuaire ou une zone cartographique",
			icon: <Campaign />,
			group: groupName,
			disabled: !selectedEvent,
		},
		{
			name: "Main courante",
			route: "/maincourante/donnees",
			path: EnumToolPath.SUIVI_CRISE,
			description:
				"Visualisez et suivez l’ensemble des événements et actions de votre crise",
			icon: <NoteAltIcon />,
			group: groupName,
			disabled: !selectedEvent,
			shortcut: "Ctrl + 1",
		},
		{
			name: "Cartographie",
			route: "/maincourante/carto",
			path: EnumToolPath.GC_CARTOGRAPHY,
			description: "Visualisez les événements de votre crise sur une carte",
			icon: <Map />,
			group: groupName,
			disabled: !selectedEvent,
			shortcut: "Ctrl + 2",
		},
	] as ISubMenu[]
	const subMenuDonnéesPcs = [
		{
			name: "Tableau de bord",
			description: intercoNameAdapter(
				"Retrouvez les informations de monitoring de votre PCS, ainsi que des informations sur vos responsables",
				isInterco,
			),
			icon: <DashboardIcon />,
			route: "/customdata/board",
			path: EnumToolPath.CD_DASHBOARD,
		},
		{
			name: intercoNameAdapter("Données communales", isInterco),
			route: "/customdata/my_forms",
			path: EnumToolPath.DONNEES_COMMUNALES,
			description:
				"Accédez à l'ensemble des données communales de votre territoire",
			icon: <ContactPhone />,
			shortcut: "Alt + 1",
		},
		{
			name: "Cartographie",
			route: "/customdata/cartographie",
			path: EnumToolPath.CD_CARTOGRAPHY,
			description: intercoNameAdapter(
				"Visualisez les données de votre PCS sur une carte",
				isInterco,
			),
			icon: <Map />,
			shortcut: "Alt + 2",
		},
		{
			name: "Organigramme",
			route: "/customdata/organigramme",
			path: EnumToolPath.CD_ORGA,
			description: intercoNameAdapter(
				"Visualisez l'organigramme de votre PCS et vos cellules de crises",
				isInterco,
			),
			icon: <People />,
		},
		{
			name: "Fiches scénarios/actions",
			route: "/customdata/fiche",
			path: EnumToolPath.FICHES,
			description: intercoNameAdapter(
				"Retrouvez les fiches scénarios/actions de votre PCS",
				isInterco,
			),
			icon: <Checklist />,
		},
		{
			name: intercoNameAdapter("Mot du maire", isInterco),
			route: "/customdata/customize",
			path: EnumToolPath.CUSTOM_PCS,
			description: intercoNameAdapter(
				"Personnalisez votre PCS en ajoutant des informations spécifiques à votre territoire",
				isInterco,
			),
			icon: <AutoAwesomeIcon />,
		},
		...notIntercoBuilder({
			name: "Arrêtés municipaux",
			route: "/customdata/arretes",
			path: EnumToolPath.ARRETES_TEMPLATES,
			description: intercoNameAdapter(
				"Retrouvez les arrêtés municipaux de votre PCS",
				isInterco,
			),
			icon: <ReceiptLong />,
		}),
		...notIntercoBuilder({
			name: "Messages d'alerte",
			route: "/customdata/message_alerte",
			path: EnumToolPath.MESSAGE_ALERTE_TEMPLATE,
			description: intercoNameAdapter(
				"Retrouvez les messages d'alerte de votre PCS",
				isInterco,
			),
			icon: <Campaign />,
		}),
	] as ISubMenu[]
	const subMenuDocuments = [
		{
			name: intercoNameAdapter("Téléchargement PCS", isInterco),
			route: "/download",
			path: EnumToolPath.DOWNLOAD_PCS,
			description: intercoNameAdapter(
				"Téléchargez votre PCS complet ou un kit",
				isInterco,
			),
			icon: <DescriptionIcon />,
		},
		{
			name: "Téléversement",
			route: "/download/upload",
			path: EnumToolPath.UPLOAD,
			description: intercoNameAdapter(
				"Téléversez un document dans votre PCS",
				isInterco,
			),
			icon: <Upload />,
		},
	] as ISubMenu[]

	const allTools = [
		{
			name: "Salle de crise",
			icon: <ReportProblemIcon />,
			path: EnumToolPath.GESTION_CRISE,
			subMenus: checkDisplayableSubMenu(subMenuMainCourante),
			defaultRoute: "/maincourante/dashboard",
		},
		...(Object.values(modesEnabled).some((b) => b)
			? [
					{
						name: "Coopération",
						icon: <People />,
						path: EnumToolPath.COOPERATION,
						subMenus: [
							modesEnabled[SearchMode.ORGANIGRAMMES]
								? {
										name: "Organigrammes",
										route: `/cooperation/${SearchMode.ORGANIGRAMMES}`,
										description:
											"Visualisez l'organigramme des collectivités de votre territoire",
										icon: <People />,
									}
								: undefined,
							modesEnabled[SearchMode.DONNEES_PARTAGEES]
								? {
										name: "Données partagées",
										route: `/cooperation/${SearchMode.DONNEES_PARTAGEES}`,
										description:
											"Visualisez les données partagées par les collectivités de votre territoire",
										icon: <Share />,
									}
								: undefined,
						].filter((item) => item),
					},
				]
			: []),
		{
			name: isInterco ? "Données PICS" : "Données PCS",
			icon: <ContactPhone />,
			path: EnumToolPath.CUSTOMDATA,
			subMenus: checkDisplayableSubMenu(subMenuDonnéesPcs),
			defaultRoute: "/customdata/board",
		},
		{
			name: "Documents",
			icon: <DescriptionIcon />,
			path: EnumToolPath.DOCUMENTS,
			subMenus: checkDisplayableSubMenu(subMenuDocuments),
		},
	] as ITool[]

	const tempToolsList = []
	allTools.forEach((tool) => {
		if (checkPermission(tool.path)) {
			tempToolsList.push(tool)
		}
	})
	const homeTool = [
		{
			name: "Accueil",
			icon: <Home />,
			route: "/home",
			...(isMobile || isOpenMenu
				? {
						subMenus: [
							{
								name: "Page d'accueil",
								route: "/home",
								path: EnumToolPath.HOME,
								description: "Page d'accueil",
								icon: <Home />,
							},
						],
					}
				: {}),
		},
	]
	const toolsList = [...homeTool, ...tempToolsList] as ITool[]
	return toolsList
}
