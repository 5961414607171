import ErrorPermissions from "@/hooks/ErrorPermissions"
import useAccessRights from "@/hooks/useAccessRights"
import { PATH_MAIN_COURANTE_ORGA } from "@/redux-toolkit/userSettings/constants"
import Debug from "debug"
import React from "react"
import TempOrga from "./orga/TempOrga"
import { EnumToolPath } from "@/hooks/services/useAccessRightsServices"

// app:javascript:components:maincourante:DaybookEventOrga.tsx
const debug = Debug("app:javascript:components:maincourante:DaybookEventOrga")
debug.log = console.log.bind(console)

const DaybookEventOrga = () => {
	const permissions = useAccessRights(EnumToolPath.GC_ORGA)

	if (!permissions.read) {
		return <ErrorPermissions />
	}

	return <TempOrga />
}

export default DaybookEventOrga
