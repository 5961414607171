import React, { useContext, useEffect } from "react"
import ErrorService from "@/services/ErrorService"
import Axios from "axios"
import { connect } from "react-redux"
import { toast } from "react-toastify"
import { SelectionContext } from "../redux/selection/context"
import { FormProvider, useForm } from "react-hook-form"
import SuperModal from "utils/modals/SuperModal"
import FText from "utils/form/FText"
import { useDispatch } from "react-redux"
import FSwitch from "utils/form/FSwitch"
import ModalBody from "@/styles/organisms/Modal/ModalBody"
import { ModalContent } from "@nextui-org/react"
import ModalHeader from "@/styles/organisms/Modal/ModalHeader"
const ModalKitPCS = ({
	isOpen,
	onClose,
	regroupAllChecked,
	commune,
	fetchKits,
}) => {
	const { state: selectionState } = useContext(SelectionContext)
	const methods = useForm({
		defaultValues: {
			kitName: "",
			config: {
				anonymize: false,
				generate_livret: false,
			},
		},
	})

	useEffect(() => {
		methods.reset({ kitName: "" })
	}, [isOpen])

	const dispatch = useDispatch()

	const onSubmit = async (values) => {
		try {
			await Axios.post(`api/kit_pcs/`, {
				data: {
					commune_id: commune.id,
					kit_name: values.kitName,
					kit_content: selectionState.map((element) => element.key),
					config: {
						anonymize: values.config?.anonymize ?? false,
						generate_livret: values.config?.generate_livret ?? false,
					},
				},
			})
			onClose()
			toast.success("Le Kit " + values.kitName + " à bien été crée !")
		} catch (error) {
			const errorMessage = `Une erreur est survenue : ${error}`
			ErrorService.error({
				component: "ModalKitPCS:onSubmit",
				message: errorMessage,
				dispatch,
			})
		}
		fetchKits()
	}

	return (
		<FormProvider {...methods}>
			<SuperModal
				isOpen={isOpen}
				title="Ajouter un Kit PCS"
				onClick={methods.handleSubmit(onSubmit)}
				onClose={onClose}
				size="lg"
			>
				<>
					<FText name="kitName" label="Nom du kit" />
					<FSwitch
						name="config.anonymize"
						label="Anonymiser les données (retire les numéros de téléphones et les mails)"
					/>
					<FSwitch name="config.generate_livret" label="Générer les livrets" />
				</>
			</SuperModal>
		</FormProvider>
	)
}

const mapStateToProps = (state) => ({
	commune: state.commune,
})

export default connect(mapStateToProps, null)(ModalKitPCS)
