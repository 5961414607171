import React from "react"
import { PrimaryButton } from "utils/components/style/button"
import { Flex } from "utils/components/style/flex"
import { IFileConfigCategory } from "utils/types/IUploadedFile"
import FormStep from "../../component/FormStep"
import useStep from "../../hooks/useStep"

const choices: {
	[key in IFileConfigCategory]: string
} = {
	PROCEDURE: "Procédure",
	CONVENTION: "Convention",
	PEDAGOGIQUE: "Fiche pédagogique",
	CARTO: "Cartographie",
	EDITABLE: "Document éditable",
	IMAGE: "Image ou autre",
}

const StepCategory = ({ ...props }) => {
	const { value, setStepValue, onBack } = useStep("category")

	return (
		<FormStep
			question="Dans quelle catégorie voulez-vous téléverser un fichier ?"
			formatedValue={choices[value]}
			onBack={onBack}
			{...props}
		>
			<Flex gap wrap>
				{Object.entries(choices).map(([key, label]) => (
					<PrimaryButton key={key} onClick={() => setStepValue(key)}>
						{label}
					</PrimaryButton>
				))}
			</Flex>
		</FormStep>
	)
}

export default StepCategory
