import React from "react"
import { Route } from "react-router-dom"
import Contexts from "./Contexts"
import DownloadPCS from "./DownloadPCS/DownloadPCS"
import useAccessRights from "@/hooks/useAccessRights"
import { DOCUMENTS } from "@/redux-toolkit/userSettings/constants"
import ErrorPermissions, {
	BACK_PATH_HOME,
	BACK_TITLE_HOME,
	TOOL_ERROR_PERMISSION,
} from "@/hooks/ErrorPermissions"
import Upload from "./Upload/Upload"
import { EnumToolPath } from "@/hooks/services/useAccessRightsServices"

const Download = () => {
	const permissions = useAccessRights(EnumToolPath.DOCUMENTS)
	if (!permissions.read) {
		return (
			<ErrorPermissions
				errorMessage={TOOL_ERROR_PERMISSION}
				backTitle={BACK_TITLE_HOME}
				backUrl={BACK_PATH_HOME}
			/>
		)
	}

	return (
		<Contexts>
			<Route exact path="/download" component={DownloadPCS} />
			<Route exact path="/download/upload" component={Upload} />
		</Contexts>
	)
}
export default Download
