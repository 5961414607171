import { Radio } from "@mui/material"
import useAccessRights from "@/hooks/useAccessRights"
import useEvents from "@/hooks/useEvents"
import useFiches from "@/hooks/useFiches"
import { deleteEvent, updateEvent } from "@/redux-toolkit/data/events/resources"
import { MAIN_COURANTE } from "@/redux-toolkit/userSettings/constants"
import _ from "lodash"
import React, { useMemo, useState, useEffect } from "react"
import useKeyboardJs from "@/utils/keyboard/useKeyboardJs"
import { useDispatch, useSelector } from "react-redux"
import PageContainer from "@/utils/components/layout/PageContainer"
import { PrimaryButton } from "@/utils/components/style/button"
import { StyledChip } from "@/utils/components/style/chip"
import { Flex } from "@/utils/components/style/flex"
import SuperReactTable from "@/utils/components/tables/SuperReactTable/SuperReactTable"
import {
	width100,
	width50,
	width60,
	width80,
	widthCustom,
} from "@/utils/components/tables/widthProps"
import Moment from "moment"
import TitleHeader from "@/utils/components/TitleHeader"
import EditEvent from "./EditEvent"
import SynchroEventService from "@/services/synchro/SynchroEventService"
import { useEffectOnce } from "react-use"
import useSelectedEvent from "@/hooks/useSelectedEvent"
import useSelectCrisis from "@/hooks/useSelectCrisis"
import SuperLockButton from "@/utils/components/SuperLockButton"
import Check from "@mui/icons-material/Check"
import Warning from "@mui/icons-material/Warning"
import styled from "styled-components"
import IEvent from "@/utils/types/IEvent"
import { ColumnDef } from "@tanstack/react-table"
import { EnumToolPath } from "@/hooks/services/useAccessRightsServices"
import { useHistory } from "react-router-dom"
import Button from "@/styles/atoms/Button"
import LightTooltip from "@/styles/atoms/Tooltip/LightTooltip/LightTooltip"
import { Kbd } from "@nextui-org/react"

let selectEventInitialized = false

const SWarning = styled(Warning)`
    color: var(--red500) !important;
`
const SCheck = styled(Check)`
    color: var(--green500) !important;
`

const SelectEvent = () => {
	const selectedEvent = useSelectedEvent()
	const fiches = useFiches()
	const events = useEvents()
	const [isEventNew, setIsEventNew] = useState(true)
	const [isOpenModalEvent, setIsOpenModalEvent] = useState(false)
	const [currentEvent, setCurrentEvent] = useState(undefined)
	const activeEvent = []
	const lastSelectedEvent = useSelector(
		(state: any) => state.daybook.lastSelectedEvent,
	)
	const history = useHistory()
	const dispatch = useDispatch()
	const [isPressed] = useKeyboardJs("ctrl + space")
	const { canLockCrisis, canDeleteCrisis } = useAccessRights(
		EnumToolPath.GESTION_CRISE,
	)

	const daybookCreationCriseWritePerm = useAccessRights(
		EnumToolPath.CREATION_CRISE,
	).write
	const daybookGestionCriseWritePerm = useAccessRights(
		EnumToolPath.GESTION_CRISE,
	).write

	const selectCrisis = useSelectCrisis()

	useEffect(() => {
		if (isPressed && !isOpenModalEvent) {
			openCreateEvent()
		}
	}, [isPressed])

	useEffect(() => {
		const synchronizeEvent = () => {
			if (selectedEvent) {
				return null
			}
			dispatch(SynchroEventService.synchronizeAction())
		}
		// synchronize event every 15 seconds
		const interval = setInterval(synchronizeEvent, 5000)
		return () => clearInterval(interval)
	}, [selectedEvent])

	useEffectOnce(() => {
		dispatch(SynchroEventService.synchronizeAction())
	})

	Object.values(events).forEach((event) => {
		if (event.active) {
			activeEvent.push(event.id)
		}
	})

	if (lastSelectedEvent) {
		selectCrisis(events[lastSelectedEvent])
	} else if (activeEvent.length === 1 && !selectEventInitialized) {
		selectEventInitialized = true
		selectCrisis(events[activeEvent[0]])
	}

	const openCreateEvent = () => {
		setIsEventNew(true)
		setCurrentEvent(undefined)
		setCurrentEvent({
			name: "",
			ficheIds: undefined,
		})
		setIsOpenModalEvent(true)
	}

	const columns = useMemo(
		() =>
			[
				{
					header: "Choisir",
					accessorKey: "id", // String-based value accessors!
					...width50,
					enableSorting: false,
					cell: ({ getValue, row }) => {
						return (
							<Radio
								checked={selectedEvent?.id === getValue()}
								onChange={() => {
									selectCrisis(row.original)
								}}
							/>
						)
					},
				},
				{
					header: "Statut",
					accessorKey: "active", // String-based value accessors!
					cell: ({ getValue }) => {
						return (
							<>
								{getValue() ? (
									<StyledChip
										label="En cours"
										$colorProperty="var(--red500)"
										variant="outlined"
										icon={<SWarning />}
									/>
								) : (
									<StyledChip
										icon={<SCheck />}
										label="Terminé"
										$colorProperty="var(--green500)"
										variant="outlined"
									/>
								)}
							</>
						)
					},
					...widthCustom(120),
				},
				{
					header: "Date de début",
					accessorKey: "startDate",
					enableSorting: false,
					cell: ({ getValue }) => {
						return (
							<>
								{getValue()
									? Moment(getValue()).format("DD/MM/YYYY")
									: "Non défini"}
							</>
						)
					},
					...width100,
				},
				{
					header: "Date de fin",
					accessorKey: "endDate",
					enableSorting: false,
					cell: ({ getValue }) => {
						return (
							<>
								{getValue()
									? Moment(getValue()).format("DD/MM/YYYY")
									: "Non défini"}
							</>
						)
					},
					...width100,
				},
				{
					header: "Nom",
					accessorKey: "name", // String-based value accessors!
					cell: ({ getValue }) => <>{getValue()}</>,
				},
				{
					header: "Scénario",
					accessorKey: "scenarioIds",
					cell: ({ getValue }) => {
						const scenarioIds = (getValue() ?? []) as string[]
						const fichesTmp = scenarioIds.map((id) => {
							return fiches[id]
						})
						return (
							<>
								<Flex gap="1rem">
									{fichesTmp.map((scen) => (
										<StyledChip
											label={scen?.nom}
											key={scen?.nom}
											$colorProperty="var(--blue500)"
											variant="outlined"
											size="small"
										/>
									))}
								</Flex>
							</>
						)
					},
				},
				{
					header: "",
					Filter: (props) => <div />,
					accessorKey: "lock",
					...width60,
					enableSorting: false,
					isAction: true,
					cell: (props) => (
						<SuperLockButton
							rowInfos={props.row.original}
							onClick={() => {
								dispatch(
									updateEvent({
										...props.row.original,
										locked: !props.row.original.locked,
									}),
								)
							}}
							disabled={!canLockCrisis}
						/>
					),
				},
			] as ColumnDef<IEvent>[],
		[events, selectedEvent],
	)

	return (
		<PageContainer>
			<TitleHeader title="Choisir une crise" />
			<SuperReactTable
				actionsButtons={
					daybookCreationCriseWritePerm && (
						<LightTooltip
							placement="top"
							title={
								<>
									Raccourci : <Kbd>Ctrl + Espace</Kbd>
								</>
							}
						>
							<Button color="primary" onClick={openCreateEvent}>
								Créer une crise
							</Button>
						</LightTooltip>
					)
				}
				onRowClick={(row) => {
					selectCrisis(row.original)
					history.push("/maincourante/dashboard")
				}}
				initialSortBy={[
					{ id: "active", desc: true },
					{
						id: "endDate",
						desc: false,
					},
				]}
				data={_.sortBy(Object.values(events), (event) => !event.active)}
				columns={columns}
				selectable={false}
				writePermission={daybookGestionCriseWritePerm}
				isEditable
				onEditClick={(original) => {
					setCurrentEvent(original)
					setIsEventNew(false)
					setIsOpenModalEvent(true)
				}}
				isDeletable={canDeleteCrisis}
				onDeleteClick={(original) => {
					deleteEvent(original)
					dispatch(deleteEvent(original))
				}}
			/>
			<EditEvent
				isNew={isEventNew}
				isOpen={isOpenModalEvent}
				onClose={() => setIsOpenModalEvent(!isOpenModalEvent)}
				initialValues={currentEvent}
			/>
		</PageContainer>
	)
}
export default SelectEvent
