import React, { useState, useEffect, useMemo } from "react"
import Debug from "debug"
import { useSelector, useDispatch } from "react-redux"
import styled, { css } from "styled-components"
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos"
import { Flex } from "@/utils/components/style/flex"
import { Button, IconButton } from "@mui/material"
import { StyledChip } from "@/utils/components/style/chip"
import Moment from "moment"
import useSelectedEvent from "@/hooks/useSelectedEvent"
import { H6 } from "@/utils/components/style/header"
import { setPanel, setSelectedEvent } from "@/redux-toolkit/common/reducer"
import { useHistory } from "react-router-dom"
import useIsOpenMenu from "@/hooks/useIsMenuOpen"
import SynchroEventService from "@/services/synchro/SynchroEventService"
import SynchroPointService from "@/services/synchro/SynchroPointService"
import useEvents from "@/hooks/useEvents"
import IEvent from "@/utils/types/IEvent"
import useSelectCrisis from "@/hooks/useSelectCrisis"
import { Tooltip } from "@nextui-org/react"
// app:javascript:components:app:EventsStatusBar.tsx
const debug = Debug("app:javascript:components:app:EventsStatusBar")
debug.log = console.log.bind(console)

const StyledCard = styled.div<{ $isActive: boolean }>`
    background-color: var(--neutral50);
    padding: 8px;
    margin-bottom: 8px;
    display: flex;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.15);
    width: 90%;
    margin: auto;
    display: flex;
    align-items: center;
    border-radius: 4px !important;
    justify-content: space-between;
    color: var(--primary800);
    ${({ $isActive }) =>
			$isActive
				? css`
                  color: white;
                  // linear gradient from font-variant(--primary400) to font-variant(--primary600) with 45 degree angle
                  background-image: linear-gradient(
                      45deg,
                      var(--primary300) 0%,
                      var(--primary600) 100%
                  );
              `
				: css`
                  border: 1px solid var(--neutral800);
                  padding: 7px;
                  cursor: pointer;
                  &:hover {
                      padding: 6px;
                      border: 2px solid var(--primary500);
                      background-color: var(--neutral100);
                  }
              `}
`

const EventTitle = styled.div<{ $isActive: boolean }>`
    font-weight: 500;
    white-space: pre-line;
    color: var(--primary800);
    ${({ $isActive }) =>
			$isActive
				? css`
                  color: white;
              `
				: css`var(--primary800)`};
`

const SFlex = styled(Flex)``
const EventsStatusBar = () => {
	const events = useEvents()
	const selectedEvent = useSelectedEvent()
	const history = useHistory()
	const dispatch = useDispatch()
	const filteredEvent = Object.values(events).filter(
		(event: IEvent) => event.active,
	)
	const selectCrisis = useSelectCrisis()

	return (
		<SFlex gap="0.25rem" directionColumn fullWidth alignItemsStart>
			{filteredEvent.map((currentEvent) => {
				return (
					<Tooltip content={currentEvent.name} placement="right">
						<StyledCard
							key={currentEvent.id}
							$isActive={currentEvent.id === selectedEvent?.id}
							onClick={() => {
								if (
									window.location.pathname.includes("alerte/") &&
									currentEvent.id !== selectedEvent?.id
								) {
									history.goBack()
								}
								selectCrisis(currentEvent)
								history.push("/maincourante/dashboard")
							}}
						>
							<EventTitle $isActive={currentEvent.id === selectedEvent?.id}>
								{currentEvent.name.length >= 4 ? (
									<span>{currentEvent.name.slice(0, 3)}...</span>
								) : (
									<span>{currentEvent.name}</span>
								)}
							</EventTitle>
						</StyledCard>
					</Tooltip>
				)
			})}
		</SFlex>
	)
}

export default EventsStatusBar
