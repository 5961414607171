import { Alert } from "@mui/material"
import React from "react"
import Modal from "@/styles/organisms/Modal"
import { ModalContent } from "@nextui-org/react"
import ModalHeader from "@/styles/organisms/Modal/ModalHeader"
import ModalBody from "@/styles/organisms/Modal/ModalBody"
import ModalFooter from "@/styles/organisms/Modal/ModalFooter"
import CancelButton from "@/styles/atoms/Button/specialized/CancelButton"
import Button from "@/styles/atoms/Button"

const ModalTwilioSwitch = ({ isOpen, onClose, onConfirm }) => {
	return (
		<Modal size="small" isOpen={isOpen} onClose={onClose}>
			<ModalContent>
				<ModalHeader>Service indisponible</ModalHeader>
				<ModalBody>
					<Alert severity='info'>
						Les services principaux pour l'envoi de messages d'alertes
						rencontrent actuellement des difficultés, voulez vous poursuivre
						votre campagne d'alerte en passant par{" "}
						<b>les services secondaires</b> ?
					</Alert>
				</ModalBody>
				<ModalFooter>
					<CancelButton onClick={onClose} />
					<Button color="primary" onClick={onConfirm}>
						Continuer avec Twilio
					</Button>
				</ModalFooter>
			</ModalContent>
		</Modal>
	)
}
export default ModalTwilioSwitch
