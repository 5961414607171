import { IconButton } from "@mui/material"
import React from "react"

import UndoIcon from "@mui/icons-material/Undo"
import RedoIcon from "@mui/icons-material/Redo"
import ToolbarPart from "./ToolbarPart"

function HistoryToolbar({ onChange, currentState }) {
	const { undoDisabled, redoDisabled } = currentState

	return (
		<ToolbarPart>
			<IconButton onClick={() => onChange("undo")}>
				<UndoIcon color={undoDisabled ? "disabled" : "action"} />
			</IconButton>
			<IconButton onClick={() => onChange("redo")}>
				<RedoIcon color={redoDisabled ? "disabled" : "action"} />
			</IconButton>
		</ToolbarPart>
	)
}

export default HistoryToolbar
