import CitycService from "@/pages/carto2/cartographie/service/CitycService"
import JsonSchemaService from "@/pages/carto2/cartographie/service/JsonSchemaService"
import useFetchAlertInfo from "@/hooks/api/useFetchAlertInfo"
import useJsonSchemasWithStatics from "@/hooks/useJsonSchemasWithStatics"
import usePoints from "@/hooks/usePoints"
import Filter from "@/pages/maincourante/following/filter/Filter"
import useFilterForm from "@/pages/maincourante/following/filter/useFilterForm"
import Debug from "debug"
import React, { useEffect, useMemo } from "react"
import { isMobile } from "react-device-detect"
import ChatIcon from "@mui/icons-material/Chat"
import { useHistory, useParams } from "react-router-dom"
import styled, { css } from "styled-components"
import {
	BrownOutlinedButton,
	OrangeOutlinedButton,
	PrimaryOutlinedButton,
} from "utils/components/style/button"
import ArchiveIcon from "@mui/icons-material/Archive"
import { StyledChip } from "utils/components/style/chip"
import SuperReactTable from "utils/components/tables/SuperReactTable/SuperReactTable"
import IDaybookAlert, {
	DaybookAlertCanal,
	DaybookAlertType,
	StatusResponse,
} from "@/utils/types/daybook/IDaybookAlert"
import DaybookAlertStatusPie from "./components/sending/DaybookAlertStatusPie"
import _ from "lodash"
import { Flex } from "utils/components/style/flex"
import { ColumnDef } from "@tanstack/react-table"
import {
	Hour,
	ResponseText,
	Response,
} from "./DaybookAlertChat/DaybookAlertChat.styled"
import {
	width100,
	width130,
	width400,
} from "utils/components/tables/widthProps"
import { widthCustom } from "utils/components/tables/widthProps"
import { Alert } from "@mui/material"
import { useDispatch } from "react-redux"
import { updatePoint } from "@/redux-toolkit/data/points/resources"
import Loading from "utils/Loading"

// app:javascript:components:maincourante:alert:alertCreation:alertStep:DaybookAlertCampaignSending.tsx
const debug = Debug(
	"app:javascript:components:maincourante:alert:alertCreation:alertStep:DaybookAlertCampaignSending",
)
debug.log = console.log.bind(console)

const Grid = styled.div`
    display: grid;
    grid-template-columns: 1fr 3fr;
    ${isMobile &&
	css`
        grid-template-columns: 1fr;
    `
	}
    grid-gap: 1rem;
	max-height: calc(100vh - 300px);
	overflow-y: auto;
`

const DaybookAlertCampaignSending = () => {
	const { isLoading, fetchAlertInfo, data } = useFetchAlertInfo()
	const [nextUpdate, setNextUpdate] = React.useState(5)
	const points = usePoints()
	const history = useHistory()
	const { alertId } = useParams<{ alertId: string }>()
	const alert = points[alertId] as IDaybookAlert
	const jsonSchemaWithStatics = useJsonSchemasWithStatics()
	const dispatch = useDispatch()

	const format_number = (number: string) => {
		if (alert.geojson.properties.usedService === "CITYCALERT") return number

		return number.replace(
			/^\+(\d{2})(\d{1})(\d{2})(\d{2})(\d{2})(\d{2})$/,
			"0$2 $3 $4 $5 $6",
		) // "+33XXXXXXXXX" to "0X XX XX XX XX" format
	}

	const phonesToIdentifiants = useMemo(() => {
		const phoneToId = {}
		const listeDiffusionIds =
			alert?.geojson?.properties?.listeDiffusionIds ?? []
		listeDiffusionIds.map((id) => {
			const point = points[id]
			const jsonSchema = jsonSchemaWithStatics[point?.jsonschema_id]
			if (!jsonSchema) return
			const phoneProperty = JsonSchemaService.getPhoneProperty(jsonSchema)
			const identifiants = JsonSchemaService.getIdProperties(jsonSchema)
			const phones = point.geojson.properties[phoneProperty.name]
			if (_.isEmpty(phones)) return
			phones.forEach((phone) => {
				phoneToId[phone.Numéro] = identifiants
					.map((identifiant) => point.geojson.properties[identifiant.name])
					.join(" ")
			})
		})
		return phoneToId
	}, [points, alertId])

	useEffect(() => {
		if (alert.geojson.properties.status === DaybookAlertType.ARCHIVED) {
			return
		}
		const timer = setTimeout(async () => {
			setNextUpdate(nextUpdate - 1)
			if (nextUpdate === 0) {
				await fetchAlertInfo()
				setNextUpdate(10)
			}
		}, 1_000)
		return () => {
			clearTimeout(timer)
		}
	}, [nextUpdate, alert])

	const improvedData = useMemo(() => {
		const dataToReturn = (data ?? []).map((d) => {
			const number = d.recipientId
			const formatted_number = format_number(number)
			return {
				identifiant_recipient: phonesToIdentifiants[formatted_number],
				...d,
			}
		})

		const regexValidPhone = /^0[1-9] [0-9]{2}( [0-9]{2}){3}$/

		alert.geojson.properties["listeDiffusionIds"].forEach((id) => {
			const point = points[id]
			const jsonSchema = jsonSchemaWithStatics[point?.jsonschema_id]
			if (!jsonSchema) return
			const phoneProperty = JsonSchemaService.getPhoneProperty(jsonSchema)
			const identifiants = JsonSchemaService.getIdentifiants(point, jsonSchema)
			const phones = point.geojson.properties[phoneProperty.name]

			if (_.isEmpty(phones)) {
				dataToReturn.push({
					identifiant_recipient: Object.values(identifiants).join(" "),
					recipientId: "Aucun numéro",
					status: "NO_PHONE",
					received: [],
					citycId: "",
					sended: [],
				})
			} else if (!phones.some((phone) => regexValidPhone.test(phone.Numéro))) {
				dataToReturn.push({
					identifiant_recipient: Object.values(identifiants).join(" "),
					recipientId: "Aucun numéro valide",
					status: "NO_VALID_PHONE",
					received: [],
					citycId: "",
					sended: [],
				})
			} else if (!alert.geojson.properties["includeFixedPhones"]) {
				const regex = /^0[67] [0-9]{2} [0-9]{2} [0-9]{2} [0-9]{2}$/
				if (!phones.some((phone) => regex.test(phone.Numéro))) {
					dataToReturn.push({
						identifiant_recipient: Object.values(identifiants).join(" "),
						recipientId: "Aucun numéro mobile",
						status: "NO_MOBILE_PHONE",
						received: [],
						citycId: "",
						sended: [],
					})
				}
			}
		})

		return dataToReturn
	}, [data])

	const options = useMemo(
		() => [
			{
				name: "Statut",
				items: [
					"Envoyé",
					"Attente de prise en compte par l’opérateur",
					"Message envoyé",
					"Réponse reçue",
					"Erreur",
					"Problème de numéro de téléphone",
				].filter((code) => {
					return improvedData.some(
						(d) => CitycService.getStatusToMessageSimple(d) === code,
					)
				}),
				getItemValue: (item) => {
					return CitycService.getStatusToMessageSimple(item)
				},
				default: "Pas de filtre"
			},
		],
		[improvedData],
	)

	const { filteredData, ...filterOptions } = useFilterForm({
		options,
		data: improvedData,
		prefixPath: "",
	})

	const columns = useMemo(() => {
		return [
			{
				header: "Statut détaillé",
				accessorKey: "status",
				cell: ({ row: { original }, getValue }) => {
					const value = getValue() as StatusResponse
					let label = CitycService.getStatusToMessage(value)
					if (value === "queued") {
						label = "En cours d'envoi"
					}
					if (value === "2000" && original.received.length > 0) {
						label = "Réponse reçue"
					}

					return (
						<>
							<StyledChip
								$colorProperty={CitycService.getStatusColor(original)}
								$backgroundColor="var(--neutral100)"
								variant="outlined"
								label={label}
							/>
						</>
					)
				},
			},
			{
				header: "Identifiant",
				accessorKey: "identifiant_recipient",
			},
			{
				header: "Téléphone",
				accessorKey: "recipientId",
				cell: ({ row: { original } }) => {
					return <>{format_number(original.recipientId)}</>
				},
			},
			{
				header: "Réponses",
				accessorKey: "received",
				...widthCustom(400),
				cell: ({ row: { original } }) => {
					return (
						<Flex directionColumn gap="0.5rem" alignItemsInitial>
							{_.isEmpty(original?.received) && <div>Aucune réponse</div>}
							{Object.values(original?.received ?? {}).map(
								(singleResponse: any, index) => {
									const { reponse, date } = singleResponse
									return (
										<Response key={index}>
											<Hour>{date}</Hour>
											<ResponseText>{reponse}</ResponseText>
										</Response>
									)
								},
							)}
						</Flex>
					)
				},
			},
			{
				header: "Échanger",
				accessorKey: "answer",
				meta: {
					isAction: true,
				},
				...width130,
				cell: ({ row: { original } }) => {
					return (
						<PrimaryOutlinedButton
							onClick={() => {
								history.push(
									"/maincourante/alerte/" +
									alertId +
									"/answer/" +
									original.recipientId,
								)
							}}
							startIcon={<ChatIcon />}
							disabled={!original.citycId.includes("plateforme")} // feature disable for SMS unusing cityc services
						>
							Échanger
						</PrimaryOutlinedButton>
					)
				},
			},
		] as ColumnDef<any>[]
	}, [])

	if (alert.geojson.properties.type === DaybookAlertCanal.VOCALE) {
		return (
			<>
				<Alert severity="info">
					Votre campagne a été envoyée, le suivi des envois sera disponible dans
					une prochaine mise à jour
				</Alert>
			</>
		)
	}
	// const numberOfPhones = useMemo(() => {
	// 	return _.uniq(
	// 		alert.geojson.properties["listeDiffusionIds"]
	// 			.map((id) => {
	// 				const point = points[id]
	// 				const jsonSchema = jsonSchemaWithStatics[point.jsonschema_id]
	// 				const phoneProperty = JsonSchemaService.getPhoneProperty(jsonSchema)
	// 				return point.geojson.properties[phoneProperty.name]
	// 					.map((num) => num?.Numéro)
	// 					.filter((num) => {
	// 						if (!num) return false
	// 						const phoneRegex = /(\s?\d{2}){5}$/
	// 						if (!phoneRegex.test(num)) return false
	// 						if (!point.geojson.properties?.includeFixedPhones) {
	// 							return num.startsWith("06") || num.startsWith("07")
	// 						}
	// 						return true
	// 					})
	// 			})
	// 			.flat(),
	// 	).length
	// }, [points, alertId])

	return (
		<Grid>
			{!isMobile && <DaybookAlertStatusPie data={filteredData} />}
			<SuperReactTable
				data={filteredData}
				columns={columns}
				isDeletable={false}
				isEditable={false}
				selectable={false}
				initialSortBy={[
					{
						id: "identifiant_recipient",
						desc: false,
					},
				]}
				actionsButtons={
					<>
						<Filter {...filterOptions} />
						{alert.geojson.properties.status !== DaybookAlertType.ARCHIVED && (
							<PrimaryOutlinedButton
								disabled={isLoading}
								startIcon={isLoading && <Loading />}
								onClick={async () => {
									await fetchAlertInfo()
									setNextUpdate(10)
								}}
							>
								Mettre à jour les données (mise à jour auto dans {nextUpdate})
							</PrimaryOutlinedButton>
						)}
						{alert.geojson.properties.status === DaybookAlertType.FINISHED && (
							<BrownOutlinedButton
								onClick={() => {
									const alertDup = _.cloneDeep(alert)
									alertDup.geojson.properties.status = DaybookAlertType.ARCHIVED
									dispatch(updatePoint(alertDup))
								}}
								startIcon={<ArchiveIcon />}
							>
								Archiver
							</BrownOutlinedButton>
						)}
					</>
				}
			/>
		</Grid>
	)
}

export default DaybookAlertCampaignSending
