import useSelectedEvent from "@/hooks/useSelectedEvent"
import { updateEvent } from "@/redux-toolkit/data/events/resources"
import { useCallback } from "react"
import { useDispatch } from "react-redux"

const useSubstituteOrgaMember = () => {
	const selectedEvent = useSelectedEvent()
	const dispatch = useDispatch()

	return useCallback(
		async (id: string, substitute: string | undefined) => {
			if (substitute === undefined) {
				const { [id]: toDelete, ...rest } = selectedEvent.orga.substitutions
				await dispatch(
					updateEvent({
						...selectedEvent,
						orga: {
							...selectedEvent.orga,
							substitutions: rest,
						},
					}),
				)
			} else {
				await dispatch(
					updateEvent({
						...selectedEvent,
						orga: {
							...selectedEvent.orga,
							substitutions: {
								...selectedEvent.orga.substitutions,
								[id]: substitute,
							},
						},
					}),
				)
			}
		},
		[selectedEvent],
	)
}

export default useSubstituteOrgaMember
