import FolderIcon from "@mui/icons-material/Folder"
import SearchIcon from "@mui/icons-material/Search"
import { FormHelperText, InputAdornment, TextField } from "@mui/material"
import React, { useState } from "react"
import { useController, useFormContext } from "react-hook-form"
import styled from "styled-components"
import { treeDisplayConfig } from "utils/components/categoryNavigation/CategoryNavigation"
import useNavigationTree from "utils/components/categoryNavigation/useNavigationTree"
import SuperList from "utils/components/SuperList"
import getImage from "@/utils/getImage"

const Wrapper = styled.div``

const FJsonSchema = ({
	name,
	disabledItems = [],
	onItemClickCallback = (_item) => {},
}) => {
	const { control } = useFormContext()

	const {
		field: { value, onChange },
		fieldState: { error },
	} = useController({
		name,
		control,
		rules: { required: true },
		defaultValue: "",
	})

	const { tree } = useNavigationTree({ includeMultiPolygons: false })

	const [filter, setFilter] = useState("")

	return (
		<Wrapper>
			<FormHelperText error={Boolean(error)}>{error?.message}</FormHelperText>
			<TextField
				size="small"
				fullWidth
				variant="outlined"
				value={filter}
				placeholder={"Rechercher un tableau de données"}
				onChange={(e) => setFilter(e.target.value)}
				InputProps={{
					endAdornment: (
						<InputAdornment position="end">
							<SearchIcon />
						</InputAdornment>
					),
				}}
			/>
			<SuperList
				items={tree}
				getItemTitle={(category) => category.title}
				getItemIcon={(category) => treeDisplayConfig[category.title].icon}
				getItemColor={(category) => treeDisplayConfig[category.title].color}
				getItemLength={(category) => category.subCategories.length}
				flat={Boolean(filter)}
			>
				{(category) => (
					<SuperList
						items={category.subCategories}
						getItemTitle={(subCategory) => subCategory.title}
						getItemIcon={() => <FolderIcon />}
						getItemLength={(subCategory) => subCategory.items.length}
					>
						{(subCategory) => (
							<SuperList
								showItem={(item) =>
									item.title.toUpperCase().includes(filter.toUpperCase())
								}
								items={subCategory.items}
								getItemTitle={(item) => item.title}
								getItemIcon={(item) => (
									<img width={25} src={getImage(item.imgId)} />
								)}
								onItemClick={(item) => {
									onChange(item.id)
									onItemClickCallback?.(item)
								}}
								isItemSelected={(item) => item.id === value}
								isItemDisabled={(item) => disabledItems.includes(item.id)}
							/>
						)}
					</SuperList>
				)}
			</SuperList>
		</Wrapper>
	)
}

export default FJsonSchema
