import AddIcon from "@mui/icons-material/Add"
import ErrorPermissions, {
	BACK_PATH_HOME,
	BACK_TITLE_HOME,
	FEATURE_ERROR_PERMISSION,
} from "@/hooks/ErrorPermissions"
import { EnumToolPath } from "@/hooks/services/useAccessRightsServices"
import useAccessRights from "@/hooks/useAccessRights"
import useIsInterco from "@/hooks/useIsInterco"
import usePdfGenerators from "@/hooks/usePdfGenerators"
import { deletePdfsGenerator } from "@/redux-toolkit/data/pdfGenerator/resources"
import { PATH_ARRETES_TEMPLATES } from "@/redux-toolkit/userSettings/constants"
import React, { useMemo } from "react"
import { isMobile } from "react-device-detect"
import { useDispatch } from "react-redux"
import { useHistory } from "react-router"
import { PrimaryButton } from "utils/components/style/button"
import SuperReactTable from "utils/components/tables/SuperReactTable/SuperReactTable"
import TitleHeader from "utils/components/TitleHeader"

const TableArretesTemplates = () => {
	const permissions = useAccessRights(EnumToolPath.ARRETES_TEMPLATES)
	const isInterco = useIsInterco()

	if (!permissions.read || isInterco) {
		return (
			<ErrorPermissions
				errorMessage={FEATURE_ERROR_PERMISSION}
				backTitle={BACK_TITLE_HOME}
				backUrl={BACK_PATH_HOME}
			/>
		)
	}

	const pdfGenerators = Object.values(usePdfGenerators())
	const dispatch = useDispatch()
	const history = useHistory()

	const columns = useMemo(
		() => [
			{
				header: "Arrêté",
				accessorKey: "title",
			},
		],
		[],
	)

	return (
		<>
			<TitleHeader title="Modèles d'arrêtés municipaux" />
			<SuperReactTable
				data={pdfGenerators}
				columns={columns}
				initialSortBy={[{ id: "title", desc: false }]}
				actionsButtons={
					<>
						{permissions.write && (
							<PrimaryButton
								startIcon={<AddIcon />}
								onClick={() => history.push("/customdata/arretes/new")}
							>
								{isMobile ? "Nouveau modèle" : "Créer un nouveau modèle"}
							</PrimaryButton>
						)}
					</>
				}
				writePermission={permissions.write}
				onEditClick={(data: any) =>
					history.push(`/customdata/arretes/${data.id}`)
				}
				isDeletable={permissions.write}
				onDeleteClick={(data: any) => dispatch(deletePdfsGenerator(data.id))}
			/>
		</>
	)
}

export default TableArretesTemplates
