import ErrorPermissions, {
	BACK_PATH_HOME,
	BACK_TITLE_HOME,
	TOOL_ERROR_PERMISSION,
} from "@/hooks/ErrorPermissions"
import useAccessRights from "@/hooks/useAccessRights"
import useWebSocketEventSubscription from "@/hooks/useWebSocketEventSubscription"
import Report from "@/pages/telechargement/reports/pdf/Report"
import React, { useEffect } from "react"
import { Route, useHistory } from "react-router-dom"
import PageContainer from "@/utils/components/layout/PageContainer"
import CreateArrete from "./arretes/CreateArrete"
import DaybookCare from "./care/DaybookCare"
import DaybookDashboard from "./DaybookDashboard"
import DaybookFollowing from "./DaybookFollowing"
import DaybookMap from "./DaybookMap"
import SelectEvent from "./event/SelectEvent"
import DaybookFicheAction from "./ficheAction/DaybookFicheAction"
import DaybookEventOrga from "./following/DaybookEventOrga"
import { useDispatch } from "react-redux"
import {
	addCoucheMultiPolygons,
} from "@/redux-toolkit/daybook/reducer"
import useMultiPolygons from "@/hooks/useMultiPolygons"
import useSelectedEvent from "@/hooks/useSelectedEvent"
import DaybookAlert from "./alert/TableAlert"
import DaybookAlertCampaign from "./alert/alertCreation/DaybookAlertCampaign"
import DaybookAlertChat from "./alert/alertCreation/alertStep/DaybookAlertChat/DaybookAlertChat"
import DaybookPrintActions from "./printer/DaybookPrintActions"
import { EnumToolPath } from "@/hooks/services/useAccessRightsServices"
import TableSelectorSupport from "./suivi/TableSelectorSupport"

const Daybook = () => {
	const permissions = useAccessRights(EnumToolPath.GESTION_CRISE)
	const selectedEvent = useSelectedEvent()
	const multiPolygons = useMultiPolygons()
	const history = useHistory()
	const dispatch = useDispatch()

	useEffect(() => {
		Object.values(multiPolygons)
			.filter((multiPolygon) => multiPolygon.event_id === selectedEvent?.id)
			.forEach((multiPolygon) => {
				dispatch(addCoucheMultiPolygons(multiPolygon))
			})
	}, [selectedEvent, multiPolygons])

	useWebSocketEventSubscription()

	if (!permissions.read) {
		return (
			<ErrorPermissions
				errorMessage={TOOL_ERROR_PERMISSION}
				backTitle={BACK_TITLE_HOME}
				backUrl={BACK_PATH_HOME}
			/>
		)
	}
	if (
		!selectedEvent?.id &&
		history.location.pathname !== "/maincourante/select"
	) {
		history.push("/maincourante/select")
	}

	return (
		<>
			<Route exact path="/maincourante/select" component={SelectEvent} />
			<Route exact path="/maincourante/carto">
				<DaybookMap />
			</Route>
			<Route exact path="/maincourante/alerte/:alertId/answer/:answerId">
				<DaybookAlertChat />
			</Route>
			<Route exact path="/maincourante/alerte/:alertId">
				<DaybookAlertCampaign />
			</Route>
			<Route exact path="/maincourante/alerte">
				<DaybookAlert />
			</Route>
			<>
				<PageContainer>
					<Route exact path="/maincourante/dashboard">
						<DaybookDashboard />
					</Route>
					<Route exact path="/maincourante/donnees">
						<DaybookFollowing />
					</Route>
					<Route exact path="/maincourante/donnees/impression">
						<DaybookPrintActions />
					</Route>
					<Route exact path="/maincourante/arretes/create">
						<CreateArrete />
					</Route>
					<Route exact path="/maincourante/arretes/create/:id">
						<CreateArrete />
					</Route>
					<Route exact path="/maincourante/orga">
						<DaybookEventOrga />
					</Route>
					<Route exact path="/maincourante/ficheAction">
						<DaybookFicheAction />
					</Route>
					<Route exact path="/maincourante/care/:careId">
						<DaybookCare />
					</Route>
					<Route exact path="/maincourante/suivi/:facilityId">
						<TableSelectorSupport />
					</Route>
					<Route exact path="/maincourante/rapport">
						<Report />
					</Route>
				</PageContainer>
			</>
		</>
	)
}
export default Daybook as any
