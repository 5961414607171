import createDeepEqualSelector from "@/utils/createDeepEqualSelector"
import {
	acceptCurrentActionRequest,
	forceCurrentAction,
	rejectCurrentActionRequest,
} from "@/redux-toolkit/edit/reducer"
import { useEffect } from "react"
import { useSelector } from "react-redux"
import { useDispatch } from "react-redux"
import { useHistory } from "react-router-dom"

const selectCurrentActionRequest = createDeepEqualSelector(
	(state: any) => state.edit.currentAction.requestIsActive,
	(state: any) => state.edit.currentAction.requestPage,
	(state: any) => state.edit.currentAction.current,
	(requestIsActive, requestPage, current) => ({
		requestIsActive,
		requestPage,
		current,
	}),
)

const useCurrentActionRequest = (dirty) => {
	const { requestIsActive, requestPage, current } = useSelector(
		selectCurrentActionRequest,
	)
	const dispatch = useDispatch()
	const history = useHistory()
	const request = requestIsActive || requestPage
	const accept = () => {
		if (requestPage) {
			history.push(requestPage)
			dispatch(forceCurrentAction(undefined))
		}
		if (requestIsActive) {
			dispatch(acceptCurrentActionRequest())
		}
	}

	useEffect(() => {
		if (request && (!current || !dirty)) accept()
	}, [request])

	return {
		request,
		accept,
		reject: () => {
			dispatch(rejectCurrentActionRequest())
		},
		current,
	}
}

export default useCurrentActionRequest
