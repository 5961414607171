import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
} from "@mui/material"
import React, { useEffect, useMemo, useState } from "react"
import styled from "styled-components"
import ImageIcon from "@mui/icons-material/Image"

const DropZone = styled.label<{ dragIn: boolean }>`
    width: 400px;
    height: 250px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${({ dragIn }) => (dragIn ? "lightblue" : "grey")};
    border: ${({ dragIn }) =>
			dragIn ? "dashed 4px lightblue" : "dashed 4px grey"};
`

function UploadDialog({ open, onClose, onChange, inputAccept }) {
	const [dragIn, setDragIn] = useState(false)

	const [file, setFile] = useState(null)
	const image = useMemo(() => (file ? URL.createObjectURL(file) : ""), [file])

	const uploadImage = (file) => {
		setFile(file)
	}

	useEffect(() => {
		setFile(null)
	}, [open])

	const onImageDrop = (event) => {
		event.preventDefault()
		event.stopPropagation()

		setDragIn(false)

		// Check if property name is files or items
		// IE uses 'files' instead of 'items'
		let data
		let dataIsItems
		if (event.dataTransfer.items) {
			data = event.dataTransfer.items
			dataIsItems = true
		} else {
			data = event.dataTransfer.files
			dataIsItems = false
		}
		for (let i = 0; i < data.length; i += 1) {
			if (
				(!dataIsItems || data[i].kind === "file") &&
				data[i].type.match("^image/")
			) {
				const file = dataIsItems ? data[i].getAsFile() : data[i]
				uploadImage(file)
			}
		}
	}

	const selectImage = (event) => {
		if (event.target.files && event.target.files.length > 0) {
			uploadImage(event.target.files[0])
		}
	}

	return (
		<Dialog open={open} onClose={onClose}>
			<DialogTitle>Téléverser une image</DialogTitle>
			<DialogContent>
				<DropZone
					dragIn={dragIn}
					onDragEnter={() => setDragIn(true)}
					onDragLeave={() => setDragIn(false)}
					onDragOver={(ev) => {
						ev.preventDefault()
						ev.stopPropagation()
					}}
					onDrop={onImageDrop}
					style={{
						backgroundImage: `url(${image})`,
						backgroundRepeat: "no-repeat",
						backgroundPosition: "center",
						backgroundSize: "contain",
					}}
				>
					<input
						type="file"
						hidden
						onChange={selectImage}
						accept={inputAccept}
					/>
					{!file && <ImageIcon fontSize="large" />}
				</DropZone>
			</DialogContent>
			<DialogActions>
				<Button onClick={onClose}>Annuler</Button>
				<Button onClick={() => onChange(file)} disabled={!file}>
					Insérer
				</Button>
			</DialogActions>
		</Dialog>
	)
}

export default UploadDialog
