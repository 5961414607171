import { yupResolver } from "@hookform/resolvers/yup"
import { Alert, Stepper } from "@mui/material"
import { updateUploadedFile } from "@/redux-toolkit/data/uploadedFile/resources"
import React, { useEffect, useMemo, useState } from "react"
import { useForm } from "react-hook-form"
import { useDispatch } from "react-redux"
import { toast } from "react-toastify"
import { Flex } from "@/utils/components/style/flex"
import fileinfo from "@/utils/fileinfo"
import IUploadedFile from "@/utils/types/IUploadedFile"
import Yup from "@/utils/yup"
import StepFormProvider from "./component/StepFormProvider"
import StepUpdateName from "./steps/updateUpload/StepUpdateName"
import StepUpdateUpload from "./steps/updateUpload/StepUpdateUpload"
import Modal from "@/styles/organisms/Modal"
import ModalHeader from "@/styles/organisms/Modal/ModalHeader"
import ModalBody from "@/styles/organisms/Modal/ModalBody"
import ModalFooter from "@/styles/organisms/Modal/ModalFooter"
import { ModalContent } from "@nextui-org/react"
import CancelButton from "@/styles/atoms/Button/specialized/CancelButton"
import Button from "@/styles/atoms/Button"
const schema = Yup.object().shape({
	file: Yup.mixed().required().default(null),
	name: Yup.string().required().default(null),
})

const ModalUpdateUpload = ({
	isOpen,
	onClose,
	file,
}: {
	isOpen: boolean
	onClose: () => void
	file: IUploadedFile
}) => {
	const [step, setStep] = useState(0)
	const dispatch = useDispatch()

	const methods = useForm({
		resolver: yupResolver(schema),
	})

	useEffect(() => {
		if (isOpen) {
			methods.reset(schema.getDefault())
			setStep(0)
		}
	}, [isOpen])

	const steps = ["file", "name"]

	const onSumbit = async (values) => {
		const extension = fileinfo(values.file.name).ext
		const fileToUpload = new File([values.file], `${values.name}${extension}`)

		await dispatch(
			updateUploadedFile({
				id: file.id,
				file: fileToUpload,
			}),
		)
		onClose()
		toast.success("Le fichier a été correctement remplacé")
	}

	const { category, filename } = useMemo(
		() => ({
			category: file?.config.category,
			filename: file?.filename?.substring(file?.code?.length + 1),
		}),
		[file],
	)

	return (
		<StepFormProvider
			methods={methods}
			setActiveStep={setStep}
			activeStep={step}
			steps={steps}
		>
			<Modal isOpen={isOpen} onClose={onClose} $baseWidth={1000}>
				<ModalContent>
					<ModalHeader>Mettre à jour un fichier</ModalHeader>
					<ModalBody>
						<Flex gap directionColumn alignItemsStretch>
							<Stepper activeStep={step} orientation="vertical">
								<StepUpdateUpload category={category} />
								<StepUpdateName filename={filename} />
							</Stepper>
							{step === steps.length && (
								<Alert>
									Le mise à jour du fichier est prête, il sera remplacé en
									conservant tous les liens vers celui-ci. Cliquez sur
									&quot;Terminer&quot; pour l&apos;effectuer
								</Alert>
							)}
						</Flex>
					</ModalBody>
					<ModalFooter>
						<CancelButton onClick={onClose} />
						{step === steps.length && (
							<Button
								color="primary"
								onClick={methods.handleSubmit(onSumbit, console.error)}
							>
								Terminer
							</Button>
						)}
					</ModalFooter>
				</ModalContent>
			</Modal>
		</StepFormProvider>
	)
}

export default ModalUpdateUpload
