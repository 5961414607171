import { View as SView } from "@react-pdf/renderer"
import ReportService from "@/pages/carto2/cartographie/service/ReportService"
import _ from "lodash"
import React from "react"
import { Html } from "react-pdf-html"
import BoldText from "utils/pdf/BoldText"
import NormalText from "utils/pdf/NormalText"
import { actionsBannerColor, stylesheet } from "./DaybookEventsList"
import PointLinkedFileImage from "./PointLinkedFileImage"
import PointLinkedFilePdf from "./PointLinkedFilePdf"
const View = SView as any
const DaybookActions = ({
	actionsPoints,
	orgas,
	objectOfWisiwygBlobsArray = {},
}) => {
	return (
		<>
			{actionsPoints.map((point) => {
				const actionLinkedFiles = point?.srcBlob ?? []
				const actionLinkedFilesPdf = actionLinkedFiles.filter(
					(file) => file?.type !== "image",
				)
				const actionLinkedFilesImg = actionLinkedFiles.filter(
					(file) => file.type === "image",
				)
				const pointImageArray =
					point.geojson.properties["Pièces jointes"]?.filter?.(
						(object) => object.extension !== ".pdf",
					) ?? []
				const pointPdfArray =
					point.geojson.properties["Pièces jointes"]?.filter?.(
						(object) => object.extension === ".pdf",
					) ?? []

				return (
					<View
						key={point.id}
						style={{
							display: "flex",
							flexDirection: "column",
							paddingTop: "8px",
						}}
					>
						<View
							style={{
								width: "100%",
								display: "flex",
								alignItems: "center",
								flexDirection: "row",
								justifyContent: "space-between",
								backgroundColor: actionsBannerColor,
								padding: "5px",
								marginBottom: "5px",
							}}
						>
							<BoldText
								style={{
									width: "400px",
									fontSize: "12px",
								}}
							>
								<BoldText
									style={{
										fontSize: "12px",
									}}
								>
									{point.geojson.properties["Objet"]}{" "}
								</BoldText>
							</BoldText>
							<BoldText
								style={{
									fontSize: "12px",
								}}
							>
								{ReportService.displayDatetime(
									point.geojson.properties["Date de début"],
								)}
							</BoldText>
						</View>
						<View
							style={{
								width: "100%",
								display: "flex",
								flexDirection: "row",
							}}
						>
							<NormalText
								style={{
									width: "120px",
								}}
							>
								<BoldText>Statut : </BoldText>
								{point.geojson.properties["Statut"]}
							</NormalText>
							{point.geojson.properties["Statut"] === "Terminé" && (
								<NormalText>
									<BoldText>Date de fin : </BoldText>
									{ReportService.displayDatetime(
										point.geojson.properties["Date de fin"],
									)}
								</NormalText>
							)}
						</View>
						<View>
							<BoldText>Détails : </BoldText>
							<NormalText>
								<Html
									stylesheet={stylesheet}
									style={{
										fontSize: 11,
										alignItems: "flex-start",
									}}
								>
									{ReportService.displayDetails(
										point.geojson.properties["Détails"],
									)}
								</Html>
							</NormalText>
						</View>
						<View
							style={{
								display: "flex",
								flexDirection: point.geojson.properties["Réponses"]
									? "column"
									: "row",
								margin: "2px 0",
							}}
						>
							{!_.isEmpty(point.geojson.properties["Réponses"]) && (
								<>
									<BoldText>
										Point(s) de situation(s) :{" "}
										{point.geojson.properties["Réponses"].length}
									</BoldText>
									{ReportService.displayAnswers(
										point.geojson.properties["Réponses"],
										objectOfWisiwygBlobsArray[point.id]?.reponsesQrCodeBlobs,
									)}
								</>
							)}
							{_.isEmpty(point.geojson.properties["Réponses"]) && (
								<BoldText>
									Aucun point de situation pour cette intervention.
								</BoldText>
							)}
						</View>
						<View
							style={{
								display: "flex",
								flexDirection: "row",
								alignItems: "center",
								flexWrap: "wrap",
								margin: "2px 0",
							}}
						>
							<BoldText>Cellule(s) : </BoldText>
							{point.geojson.properties["Cellules"]?.length > 0 &&
								ReportService.displayCells(
									orgas,
									point.geojson.properties["Cellules"],
								)}
							{!(point.geojson.properties["Cellules"]?.length > 0) && (
								<NormalText>Aucune</NormalText>
							)}
						</View>

						{actionLinkedFilesImg.length >= 1 && (
							<View>
								<BoldText
									style={{
										margin: "4px 0",
									}}
								>
									Pièce(s) jointe(s) :{" "}
								</BoldText>
								<PointLinkedFileImage
									pointImageArray={pointImageArray}
									fileBlobs={actionLinkedFilesImg}
								/>
							</View>
						)}
						{actionLinkedFilesPdf.length >= 1 && (
							<PointLinkedFilePdf
								pointPdfArray={pointPdfArray}
								fileBlobs={actionLinkedFilesPdf}
							/>
						)}
					</View>
				)
			})}
		</>
	)
}

export default DaybookActions
