import { updateCommune } from "@/redux-toolkit/commune/ressources"
import { useDispatch } from "react-redux"
import useCommune from "./useCommune"

function useOrderedPCC() {
	const dispatch = useDispatch()
	return {
		orderedPCC: useCommune().orderedPCC,
		setOrderedPCC: (order) =>
			dispatch(
				updateCommune({
					orderedPCC: order,
				}),
			),
	}
}

export default useOrderedPCC
