import React, { useMemo } from "react"
import FileDropzone from "../../component/FileDropzone"
import FormStep from "../../component/FormStep"
import useStep from "../../hooks/useStep"
import { getAllowedExtension } from "../initialUpload/StepUpload"

const StepUpdateUpload = ({ category, ...props }) => {
	const { value, setStepValue, onBack } = useStep("file")

	const allowedExtension = useMemo(
		() => getAllowedExtension(category),
		[category],
	)

	return (
		<FormStep
			question="Quel fichier voulez-vous téléverser ?"
			formatedValue={value?.name}
			onBack={onBack}
			{...props}
		>
			<FileDropzone
				allowedExtension={allowedExtension}
				onFileUpload={setStepValue}
			/>
		</FormStep>
	)
}

export default StepUpdateUpload
