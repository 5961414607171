import Button from "@/styles/atoms/Button"
import Modal from "@/styles/organisms/Modal"
import {
	ModalBody,
	ModalContent,
	ModalFooter,
	ModalHeader,
} from "@nextui-org/react"
import Axios from "axios"
import { resetRedux } from "@/redux-toolkit/rootActions"
import Debug from "debug"
import React, { useEffect, useState } from "react"
import { FormProvider, useForm } from "react-hook-form"
import FSelect from "@/utils/form/FSelect"
import DatabaseService from "@/services/DatabaseService"
import synchronisationKeysToKeep from "@/utils/synchronisationKeysToKeep"
import { useDispatch } from "react-redux"
import useAdminRestrictions from "@/hooks/useAdminRestrictions"

const debug = Debug(
	"app:javascript:components:app:changeCommunes:ModalChangeCommune.tsx",
)
debug.log = console.log.bind(console)

const ModalChangeCommune = ({ isOpen, setIsOpen }) => {
	const dispatch = useDispatch()
	const adminRestrictions = useAdminRestrictions()
	const [communes, setCommunes] = useState([])

	useEffect(() => {
		const fetchCommunes = async () => {
			try {
				const response = await Axios.get("/api/communes")
				const data = response.data
				const parsedData = JSON.parse(data.communes)
				const filteredData = parsedData.filter((comm) =>
					adminRestrictions.allowedCommunes.includes(comm.id)
				)
				setCommunes(adminRestrictions.isRestricted ? filteredData : parsedData)
			} catch (error) {
				console.error(error)
			}
		}
		fetchCommunes()
	}, [])

	const methods = useForm({
		defaultValues: {
			commune: communes[0],
		},
	})

	const onSubmit = (data) => {
		const changeCommune = async () => {
			const commnuneId = communes.find(
				(commune) => commune.name === data.commune,
			).id
			try {
				const res = await Axios.post("/api/change_communes_admin", {
					communeId: commnuneId,
				})
			} catch (error) {
				console.error(error)
			}
			const resKeys = synchronisationKeysToKeep.map((key) => {
				return localStorage.getItem(key)
			})
			localStorage.clear()
			synchronisationKeysToKeep.forEach((key, index) => {
				localStorage.setItem(key, resKeys[index])
			})
			DatabaseService.clear()

			dispatch(resetRedux())
			// reload page
		}

		changeCommune()
	}

	return (
		<Modal
			isOpen={isOpen}
			onClose={() => setIsOpen(false)}
			isDismissable={false}
		>
			<ModalContent>
				<ModalHeader>Changer de commune</ModalHeader>
				<ModalBody>
					<FormProvider {...methods}>
						<FSelect
							options={communes.map((commune) => commune.name)}
							name="commune"
							label="Commune"
						/>
					</FormProvider>
				</ModalBody>
				<ModalFooter>
					<Button variant="light" onClick={() => setIsOpen(false)}>
						Annuler
					</Button>
					<Button color="primary" onClick={methods.handleSubmit(onSubmit)}>
						Valider
					</Button>
				</ModalFooter>
			</ModalContent>
		</Modal>
	)
}

export default ModalChangeCommune
