import { yupResolver } from "@hookform/resolvers/yup"
import useFiches from "@/hooks/useFiches"
import useOrga from "@/hooks/useOrga"
import { createFiche } from "@/redux-toolkit/data/fiche/resources"
import Debug from "debug"
import React, { useMemo } from "react"
import { FormProvider, useForm } from "react-hook-form"
import { connect } from "react-redux"
import FSelect from "utils/form/FSelect"
import SuperModal from "utils/modals/SuperModal"
import IFiche, { TypeFiche } from "utils/types/IFiche"
import Yup from "utils/yup"
import { v4 as uuidv4 } from "uuid"

// app:javascript:components:customdata:fiches:ModalFicheAction.tsx
const debug = Debug(
	"app:javascript:components:customdata:fiches:ModalFicheAction",
)
debug.log = console.log.bind(console)

const schema = Yup.object().shape({})

const ModalFicheAction = ({
	createFiche,
	toggleModal,
	isOpen,
}: {
	createFiche: (fiche: IFiche) => void
	toggleModal: () => void
	isOpen: boolean
}) => {
	const defaultValues = {
		"Type de risque": "",
		"Nom de la fiche": null,
	}
	const methods = useForm({
		resolver: yupResolver(schema),
		defaultValues,
	})

	const orga = useOrga()
	const fiches = useFiches()
	const orgaCellWithoutFiches = useMemo(() => {
		const toFilterCells = []
		Object.values(fiches).forEach((fiche) => {
			if (fiche.classification === TypeFiche.FICHE_SCENARIO) {
				return
			}
			toFilterCells.push(fiche.nom)
		})
		return Object.values(orga).filter((cell) => {
			return !toFilterCells.includes(cell.id)
		})
	}, [fiches, orga])

	const onSubmit = (values: any) => {
		createFiche({
			nom: values["Cellule"],
			risk: undefined,
			actions: {},
			actionsByCategory: [],
			id: uuidv4(),
			classification: TypeFiche.FICHE_ACTION,
		})
		methods.reset(defaultValues)
		toggleModal()
	}

	return (
		<>
			<SuperModal
				small
				isOpen={isOpen}
				onClose={() => {
					methods.reset(defaultValues)
					toggleModal()
				}}
				isNew
				title="Créer une nouvelle fiche action"
				onClick={methods.handleSubmit(onSubmit)}
			>
				<FormProvider {...methods}>
					<FSelect
						name="Cellule"
						label="Cellule"
						options={orgaCellWithoutFiches.map((cell) => cell.id)}
						getOptionLabel={(cellId) => orga[cellId].idName}
					/>
				</FormProvider>
			</SuperModal>
		</>
	)
}

const mapStateToProps = (state: any) => ({})
const mapDispatchToProps = (dispatch: any) => ({
	createFiche: (fiche) => dispatch(createFiche(fiche)),
})
export default connect(mapStateToProps, mapDispatchToProps)(ModalFicheAction)
