import AddTaskIcon from "@mui/icons-material/AddTask"
import EditIcon from "@mui/icons-material/Edit"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	IconButton,
} from "@mui/material"
import useCurrentAction from "@/hooks/useCurrentAction"
import useResponsive from "@/hooks/useResponsive"
import { requestCurrentAction } from "@/redux-toolkit/edit/reducer"
import React, { useState } from "react"
import { DragDropContext } from "react-beautiful-dnd"
import { useDispatch } from "react-redux"
import styled, { css } from "styled-components"
import { PrimaryOutlinedButton } from "utils/components/style/button"
import { Flex } from "utils/components/style/flex"
import SuperDeleteButton from "utils/components/SuperDeleteButton"
import SuperDraggable from "utils/dnd/SuperDraggable"
import SuperDragHandle from "utils/dnd/SuperDragHandle"
import SuperDroppable from "utils/dnd/SuperDroppable"
import dontPropagate from "utils/dontPropagate"
import IFiche, { IActionCategory } from "utils/types/IFiche"
import Action from "./Action"
import TitleDialog from "./components/TitleDialog"
import NewAction from "./NewAction"
import useDeleteAction from "./utils/useDeleteAction"
import useDeleteCategory from "./utils/useDeleteCategory"
import useRenameCategory from "./utils/useRenameCategory"
import useReorderFiche, { ACTION, CATEGORY } from "./utils/useReorderFiche"

export const SAccordion = styled(Accordion)`
    margin-bottom: 0.5em;
`

export const SAccordionDetails = styled(AccordionDetails)`
    padding: 0 !important;
    border-top: solid var(--neutral500) 1px;
    border-bottom: solid var(--neutral500) 1px;
`

const SAccordionSummary = styled(AccordionSummary)`
    padding-left: 0 !important;

    & .MuiAccordionSummary-content {
        margin: 8px 0 !important;
    }
`

const ButtonWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
`

export const TitleWrapper = styled.div`
    flex: 1;
    font-weight: 500;
    color: var(--primary800);
    font-size: 1.05em;
    display: flex;
    align-items: center;
    gap: 4px;
`

const HeaderWrapper = styled.div<{ $isSmall: boolean }>`
    display: flex;
    align-items: center;
    flex: 1;
    padding-right: 1rem;
    gap: 5px;

    ${({ $isSmall }) =>
			$isSmall &&
			css`
            flex-direction: column;
            ${ButtonWrapper} {
                align-self: flex-end;
            }
            ${TitleWrapper} {
                align-self: flex-start;
            }
        `}
`

const Title = styled.div`
    flex: 1;
`

const DragContainer = styled.div`
    display: flex;
    align-items: center;
    align-self: stretch;
    width: 2em;
    padding-left: 6px;
    justify-content: center;
`

export const ActionBorder = styled.div<{
	$isDragging?: boolean
	$last: boolean
}>`
    background-color: white;

    ${({ $last }) =>
			$last
				? css`
                  border: none;
              `
				: css`
                  border-bottom: solid 1px #dddddd;
              `}

    ${({ $isDragging, $last }) =>
			$isDragging &&
			($last
				? css`
                  box-shadow: 0 -1px 0 0 #dddddd, 0 1px 0 0 #dddddd;
              `
				: css`
                  box-shadow: 0 -1px 0 0 #dddddd;
              `)}
`

function Fiche({
	fiche,
	editable,
	opened,
	setOpened,
}: {
	fiche: IFiche
	editable: boolean
	opened: Record<string, boolean>
	setOpened: (opened: Record<string, boolean>) => void
}) {
	const dispatch = useDispatch()
	const currentAction = useCurrentAction()

	const handleOpenChange =
		(category: IActionCategory) => (event: never, isExpanded: boolean) => {
			if (category.actionIds.includes(currentAction?.id) && !isExpanded) return
			if (currentAction?.newFrom === category.id && !isExpanded) return
			setOpened({
				...opened,
				[category.id]: isExpanded,
			})
		}

	const onDragEnd = useReorderFiche(fiche)
	const deleteAction = useDeleteAction(fiche)
	const renameCategory = useRenameCategory(fiche)
	const deleteCategory = useDeleteCategory(fiche)

	const requestCreateAction = (categoryId) => {
		dispatch(
			requestCurrentAction({
				newFrom: categoryId,
				ficheId: fiche.id,
			}),
		)
		setOpened({
			...opened,
			[categoryId]: true,
		})
	}

	const [categoryToRename, setCategoryToRename] =
		useState<IActionCategory>(undefined)

	const isSmall = useResponsive(1000)
	const size = isSmall ? "small" : undefined

	return (
		<DragDropContext onDragEnd={onDragEnd}>
			<SuperDroppable
				droppableProps={{
					droppableId: CATEGORY,
					type: CATEGORY,
				}}
			>
				{fiche.actionsByCategory.map((category, index) => (
					<SuperDraggable
						component={SAccordion}
						key={category.id}
						id={category.id}
						index={index}
						fullHandle={false}
						TransitionProps={{
							unmountOnExit: true,
							timeout: currentAction?.newFrom === category.id ? 0 : undefined,
						}}
						expanded={Boolean(opened[category.id])}
						onChange={handleOpenChange(category)}
					>
						<SAccordionSummary expandIcon={<ExpandMoreIcon />}>
							<HeaderWrapper $isSmall={isSmall}>
								<TitleWrapper>
									{editable ? (
										<SuperDragHandle component={DragContainer} />
									) : (
										<Flex padding={"5px"} />
									)}
									<Title>{category.categoryName}</Title>
								</TitleWrapper>
								{editable && (
									<ButtonWrapper>
										<Flex>
											<IconButton
												size={size}
												onClick={dontPropagate(() =>
													setCategoryToRename(category),
												)}
											>
												<EditIcon />
											</IconButton>
											<SuperDeleteButton
												small={isSmall}
												onClick={() => deleteCategory(category)}
											/>
										</Flex>
										<PrimaryOutlinedButton
											size={size}
											startIcon={<AddTaskIcon />}
											onClick={dontPropagate(() =>
												requestCreateAction(category.id),
											)}
										>
											Ajouter une action
										</PrimaryOutlinedButton>
									</ButtonWrapper>
								)}
							</HeaderWrapper>
						</SAccordionSummary>
						<SuperDroppable
							component={SAccordionDetails}
							droppableProps={{
								droppableId: category.id,
								type: ACTION,
							}}
						>
							{category.actionIds.map((actionId, index) => {
								const action = fiche.actions[actionId]
								return (
									<SuperDraggable
										id={actionId}
										index={index}
										key={actionId}
										fullHandle={false}
										component={ActionBorder}
										$last={index + 1 === category.actionIds.length}
									>
										<Action
											editable={editable}
											action={action}
											handleDelete={() => deleteAction(action)}
											handleStartEdit={() =>
												dispatch(
													requestCurrentAction({
														id: action.id,
														ficheId: fiche.id,
													}),
												)
											}
										/>
									</SuperDraggable>
								)
							})}
						</SuperDroppable>
						{editable && (
							<NewAction
								active={currentAction?.newFrom === category.id}
								requestCreateActionForCategory={() =>
									requestCreateAction(category.id)
								}
							/>
						)}
					</SuperDraggable>
				))}
			</SuperDroppable>
			<TitleDialog
				title={categoryToRename?.categoryName}
				headerText="Renommer la catégorie"
				open={Boolean(categoryToRename)}
				onClose={() => setCategoryToRename(undefined)}
				onSave={(title) => {
					renameCategory(categoryToRename.id, title)
					setCategoryToRename(undefined)
				}}
				saveLabel={"Renommer"}
			/>
		</DragDropContext>
	)
}

export default Fiche
