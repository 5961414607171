import ClearIcon from "@mui/icons-material/Clear"
import SearchIcon from "@mui/icons-material/Search"
import { IconButton, TextField } from "@mui/material"
import InputAdornment from "@mui/material/InputAdornment"
import useAccessRights from "@/hooks/useAccessRights"
import React, { useState, useRef, useEffect } from "react"
import useKeyboardJs from "@/utils/keyboard/useKeyboardJs"
import styled, { css } from "styled-components"
import ModalSearch from "./ModalSearch"
import intercoNameAdapter from "@/utils/intercoNameAdapter"
import useIsInterco from "@/hooks/useIsInterco"
import { EnumToolPath } from "@/hooks/services/useAccessRightsServices"
import { isMobile } from "react-device-detect"
import Button from "@/styles/atoms/Button"
import { Kbd } from "@nextui-org/react"
import Tooltip from "@/styles/atoms/Tooltip"

const STextField = styled(TextField)`
	${isMobile && css`flex-grow: 1;`}
    transition: all 0.3s ease-in-out;
    margin: 16px !important;
    display: flex !important;
    min-width: 40% !important;

    * {
        color: white !important;
        border-color: white !important;
    }
    ${({ value }) =>
			value &&
			css`
            width: 40vw !important;
            * {
                color: white !important;
                border-color: white !important;
            }
        `}

    & input {
        transition: all 0.3s ease-in-out;
        padding-top: 4px;
        padding-bottom: 4px;
        ${({ value }) =>
					value &&
					css`
                padding-top: 6px !important;
                padding-bottom: 6px !important;
            `}
    }
`
const SSearchIcon = styled(SearchIcon)`
    margin-top: 0.25rem;
`
const SearchBarMenu = () => {
	const [openModalSearch, setOpenModalSearch] = useState(false)
	const permissions = useAccessRights(EnumToolPath.CUSTOMDATA)
	const textFieldRef = useRef(null)
	const [isPressed] = useKeyboardJs("ctrl + f", true)
	const isInterco = useIsInterco()

	useEffect(() => {
		if (isPressed) {
			setOpenModalSearch(true)
		}
	}, [isPressed, textFieldRef])

	return (
		<>
			<Tooltip
				content={
					<>
						Raccourci <Kbd>Ctrl + F</Kbd>
					</>
				}
			>
				<Button
					$color="white"
					$textColor="white"
					variant="bordered"
					startContent={<SSearchIcon />}
					onClick={() => setOpenModalSearch(true)}
				>
					Rechercher
				</Button>
			</Tooltip>

			<ModalSearch
				isOpen={openModalSearch}
				onClose={() => setOpenModalSearch(false)}
			/>
		</>
	)
}

export default SearchBarMenu
