import { ColumnDef } from "@tanstack/react-table"
import useCooperationFilters from "@/hooks/useCooperationFilters"
import useJsonSchemasWithStatics from "@/hooks/useJsonSchemasWithStatics"
import { SearchMode } from "@/redux-toolkit/edit/cooperationFiltersRessources"
import { setCurrentReadOnlyPoint } from "@/redux-toolkit/edit/reducer"
import React, { useMemo } from "react"
import { isMobile } from "react-device-detect"
import { useDispatch } from "react-redux"
import CustomTabs from "@/utils/components/customTabs/CustomTabs"
import PointPropertyValue from "@/utils/components/jsonSchema/properties/PointPropertyValue"
import { Flex } from "@/utils/components/style/flex"
import SuperReactTable from "@/utils/components/tables/SuperReactTable/SuperReactTable"
import { width50 } from "@/utils/components/tables/widthProps"
import getImage from "@/utils/getImage"
import { JsonSchemaPropertiesType } from "@/utils/types/IJsonSchema"
import useCommuneFilter from "../../hooks/useCommuneFilter"
import useLayerFilter from "../../hooks/useLayerFilter"
import useSearchHasStarted from "../../hooks/useSearchHasStarted"
import useTagFilter from "../../hooks/useTagFilter"
import EmptyMessage from "../EmptyMessage"
import useCdcSharedJsonSchemas from "@/hooks/useCdcSharedJsonSchemas"
import useCdc from "@/hooks/useCdc"
import useJsonSchemaColumns from "@/hooks/useJsonSchemaColumns"
import IPoint from "@/utils/types/IPoint"
import _ from "lodash"
import JsonSchemaService from "@/pages/carto2/cartographie/service/JsonSchemaService"
import usePointPropertyToText from "@/utils/components/jsonSchema/properties/usePointPropertyToText"
import PointProperty from "@/utils/components/jsonSchema/properties/PointProperty"
import DisplayStocks from "@/utils/components/jsonSchema/properties/enhanceStatics/enhancers/DisplayStocks"
import DisplayVehicules from "@/utils/components/jsonSchema/properties/enhanceStatics/enhancers/DisplayVehicules"
import { useConvertToCsvSharedTable } from "./SharedTable.hook"
import { StaticFormType } from "@/utils/services/StaticFormsService"
import useAccessRights from "@/hooks/useAccessRights"
import {
	JSONSCHEMAS,
	PATH_DONNEES_COMMUNALES,
} from "@/redux-toolkit/userSettings/constants"
import { filterUsingPermissions } from "../CoopLayersSelection"
import { EnumToolPath } from "@/hooks/services/useAccessRightsServices"

const SharedTable = ({ allPointsWithShared }) => {
	const jsonSchemaWithStatics = useJsonSchemasWithStatics()
	const cdcSharedJsonSchemas = useCdcSharedJsonSchemas()
	const pointPropertyToText = usePointPropertyToText()
	const coopFilters = useCooperationFilters()
	const dispatch = useDispatch()
	const cdc = useCdc()
	const orderedCommunes = _.cloneDeep(cdc.communes).sort((a, b) => {
		const nameA = a.name.toUpperCase()
		const nameB = b.name.toUpperCase()
		return nameA.localeCompare(nameB)
	})
	const allJsonSchemas = { ...jsonSchemaWithStatics, ...cdcSharedJsonSchemas }
	const { readAll, WHITELIST, BLACKLIST } = useAccessRights(
		EnumToolPath.JSONSCHEMAS,
	)
	const jsonSchema = allJsonSchemas[coopFilters.layer.jsonschema_id]

	const communeColumn = {
		header: "Commune",
		id: "commune",
		accessorFn: (row) => {
			const commune = orderedCommunes.find((c) => c.id === row.commune_id)
			return commune.name
		},
	} as ColumnDef<any>

	const layerFilter = useLayerFilter()
	const tagFilter = useTagFilter()
	const communeFilter = useCommuneFilter()

	let displayableStoragesJsonSchemas = []
	if (readAll) {
		filterUsingPermissions(
			"BLACKLIST",
			[StaticFormType.STOCK_PLACE, StaticFormType.CARE],
			BLACKLIST,
			displayableStoragesJsonSchemas,
		)
	} else {
		filterUsingPermissions(
			"WHITELIST",
			[StaticFormType.STOCK_PLACE, StaticFormType.CARE],
			Object.keys(WHITELIST),
			displayableStoragesJsonSchemas,
		)
	}

	const splittedData = useMemo(() => {
		return allPointsWithShared.reduce((acc: any, val: any) => {
			const newArr = acc[val.commune_id] ?? []

			if (
				displayableStoragesJsonSchemas.includes(val.jsonschema_id) ||
				![StaticFormType.STOCK_PLACE, StaticFormType.CARE].includes(
					val.jsonschema_id,
				)
			) {
				newArr.push(val)
			}
			return { ...acc, [val.commune_id]: newArr }
		}, {})
	}, [allPointsWithShared, tagFilter, communeFilter, layerFilter]) as Record<
		string,
		IPoint[]
	>

	const pointsAllInterco = Object.values(splittedData).reduce(
		(prev, curr) => [...prev, ...curr],
		[],
	)

	const convertToCsv = useConvertToCsvSharedTable({
		coopFiltersTag: coopFilters.tag,
		splittedData,
	})

	// La recherche n'a pas commencée
	const searchHasStarted = useSearchHasStarted()

	// ---
	const res = splittedData[Object.keys(splittedData)?.[0]]?.[0]
	let columns = useJsonSchemaColumns(res?.jsonschema_id)
	if (!_.isEmpty(coopFilters.tag)) {
		columns = [
			{
				header: "Nom du lieu",
				accessorFn: (data) => {
					const idProperties = JsonSchemaService.getIdProperties(
						allJsonSchemas[data.jsonschema_id],
					)
					return idProperties
						.map((property) => data.geojson.properties[property.name] ?? "")
						.join(" ")
				},
			},
			{
				header: "Adresse",
				accessorKey: "adresse",
				cell: ({ row }) => {
					const geolocProperty = JsonSchemaService.getGeolocProperty(
						allJsonSchemas[row.original.jsonschema_id],
					)
					return row.original?.geojson?.properties?.[geolocProperty.name]
						?.Adresse
				},
			},
			{
				header: "Responsable(s)",
				accessorFn: (data) => {
					const respProperty = JsonSchemaService.getResponsableProperty(
						allJsonSchemas[data.jsonschema_id],
					)
					return pointPropertyToText(data, respProperty)
				},
				cell: ({ row }) => {
					const respProperty = JsonSchemaService.getResponsableProperty(
						allJsonSchemas[row.original.jsonschema_id],
					)
					return (
						<>
							{row && (
								<PointProperty
									point={row.original}
									property={respProperty}
									editable
								/>
							)}
						</>
					)
				},
			},
			{
				header: "Stocks disponibles",
				accessorKey: "stocks",
				cell: ({ row }) => (
					<DisplayStocks point={row.original} editable={false} align="right" />
				),
			},
			{
				header: "Véhicules disponibles",
				accessorKey: "vehicules",
				cell: ({ row }) => (
					<DisplayVehicules
						point={row.original}
						editable={false}
						align="right"
					/>
				),
			},
		] as ColumnDef<any>[]
	}
	if (!searchHasStarted) {
		return (
			<Flex fullHeight justifyCenter>
				<EmptyMessage />
			</Flex>
		)
	}

	// La recherche n'a pas de résultats
	if (!Object.keys(splittedData).length)
		return (
			<Flex fullHeight justifyCenter>
				<EmptyMessage message="La recherche n'a pas de résultats" />
			</Flex>
		)
	// ---

	const order = {}
	
	const orderedCommunesWithDataKeys = orderedCommunes.map((comm) => {
		if (splittedData[comm.id]) return comm.id
	}).filter((el) => el)

	const tabs = [
		{
			key: "Toute l'intercommunalité",
			render: (
				<SuperReactTable
					initialSortBy={[
						{
							desc: false,
							id: "commune",
						},
						...(jsonSchema?.sort_by ?? []).map((schema) => ({
							...schema,
							id: schema.property,
						})),
					]}
					selectable={false}
					data={pointsAllInterco}
					convertToCsv={convertToCsv(undefined)}
					columns={[communeColumn, ...columns]}
					writePermission={false}
					isEditable={true}
					onEditClick={(obj) => dispatch(setCurrentReadOnlyPoint(obj))}
					isDeletable={false}
					customEmptyMessage={"Aucun résultat pour ces filtres"}
				/>
			),
		},
		...orderedCommunesWithDataKeys.map((comm) => ({
			key: orderedCommunes.find((commune) => commune.id === comm)?.name,
			render: (
				<SuperReactTable
					orderConfig={{
						order: order[comm] ?? [],
						onOrder: undefined,
						preOrder: {
							order: coopFilters.availableLayers,
							accessorFn: (item) => item.jsonschema_id,
						},
					}}
					convertToCsv={convertToCsv(comm)}
					selectable={false}
					data={splittedData[comm] ?? []}
					columns={columns}
					writePermission={false}
					isEditable={true}
					onEditClick={(obj) => dispatch(setCurrentReadOnlyPoint(obj))}
					isDeletable={false}
					customEmptyMessage={"Aucun résultat pour ces filtres"}
				/>
			),
		})),
	]

	return <CustomTabs tabChildrens={tabs} />
}

export default SharedTable
