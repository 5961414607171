import ExpandLess from "@mui/icons-material/ExpandLess"
import ExpandMore from "@mui/icons-material/ExpandMore"
import { Chip } from "@mui/material"
import { ColumnDef } from "@tanstack/react-table"
import useHumans from "@/hooks/useHumans"
import React, { useMemo } from "react"
import colored from "@/utils/colored"
import { PrimaryTextButton } from "@/utils/components/style/button"
import { Flex } from "@/utils/components/style/flex"
import SuperReactTable from "@/utils/components/tables/SuperReactTable/SuperReactTable"
import { width130, width80 } from "@/utils/components/tables/widthProps"
import TitleHeader from "@/utils/components/TitleHeader"
import { ORGA_COLOR } from "@/utils/types/IOrga"
import TempOrgaSubRow from "./TempOrgaSubRow"
import useCrisisOrgaWithOrder from "./utils/useCrisisOrgaWithOrder"
import HumanChip from "@/styles/atoms/Chip/specialized/HumanChip"

const CChip = colored(Chip)

const TempOrga = () => {
	const { cellules, order } = useCrisisOrgaWithOrder()

	const humans = useHumans()

	const columns = useMemo(() => {
		return [
			{
				header: "Catégorie",
				accessorKey: "category",
				...width130,
				cell: ({ getValue }) => {
					const value = getValue()
					return (
						<CChip
							label={value}
							$textColor="white"
							$backgroundColor={ORGA_COLOR[value]}
						/>
					)
				},
				enableSorting: false,
			},
			{
				header: "Cellule",
				accessorKey: "idName",
				enableSorting: false,
			},
			{
				header: "Responsables actifs",
				accessorKey: "crisisResponsableIds",
				cell: ({ getValue }) => {
					const value = getValue() ?? []
					const responsables = value
						.map((responsable) => humans[responsable])
						.filter(Boolean)
					return (
						<Flex gap="0.5rem" $wrap>
							{responsables.map((responsable) => (
								<HumanChip
									key={responsable?.id}
									writePermission={false}
									human={responsable}
								/>
							))}
						</Flex>
					)
				},
				enableSorting: false,
			},
			{
				header: "Présence",
				accessorKey: "présence",
				cell: ({ row, table }) => {
					const isExpanded = row.getIsExpanded()
					return (
						<PrimaryTextButton
							endIcon={isExpanded ? <ExpandLess /> : <ExpandMore />}
							onClick={() => {
								if (isExpanded) {
									row.toggleExpanded()
									return
								}
								table.toggleAllRowsExpanded(false)
								row.toggleExpanded()
							}}
						>
							Voir
						</PrimaryTextButton>
					)
				},
				...width80,
				meta: {
					expandableCell: true,
				},
				enableSorting: false,
			},
		] as ColumnDef<any, any>[]
	}, [humans])

	const data = useMemo(() => order.map((id) => cellules[id]), [cellules, order])

	return (
		<>
			<TitleHeader title="Organigramme de crise" />
			<SuperReactTable
				infiniteSize
				selectable={false}
				columns={columns}
				data={data}
				simpleTable
				isDeletable={false}
				isEditable={false}
				showBottom={false}
				autoResetExpanded={false}
				RenderSubRow={TempOrgaSubRow}
			/>
		</>
	)
}

export default TempOrga
