import { Paper } from "@mui/material"
import Debug from "debug"
import React from "react"
import styled from "styled-components"
import DataPie from "utils/components/charts/DataPie"
import { Flex } from "utils/components/style/flex"
import IJsonSchema from "@/utils/types/IJsonSchema"
import IPoint from "utils/types/IPoint"
import CustomCardStatDons from "../CustomCardStatDons"
import CustomCardStatDons2 from "../CustomCardStatDons2"
import CustomCardStatDons3 from "../CustomCardStatDons3"
import MyFormSettings from "./MyFormSettings"
import { isMobile } from "react-device-detect"
// app:javascript:components:admin:formulaire_dynamique:MyForms:statPanel:MyFormsStatPanel.tsx
const debug = Debug(
	"app:javascript:components:admin:formulaire_dynamique:MyForms:statPanel:MyFormsStatPanel.tsx",
)
debug.log = console.log.bind(console)

const StyledSecteurDisplay = styled.div`
  display: grid;
  grid-template-columns: 300px 40px;
  grid-gap: 1rem;
`
const SPaper = styled(Paper)`
  width: fit-content !important;
`
const MyFormsStatPanel = ({
	customDataItems,
	jsonSchema,
}: {
	customDataItems: IPoint[]
	jsonSchema: IJsonSchema
}) => {
	return (
		<>
			<Flex gap="1rem" directionColumn={isMobile}>
				<DataPie data={customDataItems} isDashboard={false} />
				{jsonSchema.id === "8a795a52-79bc-4700-bb55-d570f8141c64" && (
					<CustomCardStatDons items={customDataItems} />
				)}
				{jsonSchema.id === "8982e24f-a68d-4395-b4a9-205370069917" && (
					<CustomCardStatDons2 items={customDataItems} />
				)}
				{jsonSchema.id === "77841d91-29e1-46eb-a6b2-745099e81aad" && (
					<CustomCardStatDons3 items={customDataItems} />
				)}
				<MyFormSettings jsonSchema={jsonSchema} />
			</Flex>
		</>
	)
}

export default MyFormsStatPanel
