import { updateFiche } from "@/redux-toolkit/data/fiche/resources"
import _ from "lodash"
import { useCallback } from "react"
import { useDispatch } from "react-redux"
import IFiche, { IAction } from "utils/types/IFiche"

const useDeleteAction = (fiche: IFiche) => {
	const dispatch = useDispatch()

	return useCallback(
		(action: IAction) => {
			const newFiche = _.cloneDeep(fiche)

			delete newFiche.actions[action.id]

			newFiche.actionsByCategory = newFiche.actionsByCategory.map((cat) => ({
				...cat,
				actionIds: cat.actionIds.filter((actionId) => actionId !== action.id),
			}))

			dispatch(updateFiche(newFiche))
		},
		[fiche],
	)
}

export default useDeleteAction
