import arrowUp from "../signal-icons/arrow-up.png"
import arrowDown from "../signal-icons/arrow-down.png"
import arrowLeft from "../signal-icons/arrow-left.png"
import arrowRight from "../signal-icons/arrow-right.png"
import arrowTopLeft from "../signal-icons/arrow-top-left.png"
import arrowBottomLeft from "../signal-icons/arrow-bottom-left.png"
import arrowTopRight from "../signal-icons/arrow-top-right.png"
import arrowBottomRight from "../signal-icons/arrow-bottom-right.png"
import alertIcon from "../signal-icons/alert.png"
import minusCircle from "../signal-icons/minus-circle.png"
import landRowsVertical from "../signal-icons/land-rows-vertical.png"
import parking from "../signal-icons/parking.png"
import evacuationZone from "../signal-icons/evacuation-zone.png"
import arrowCollapseAll from "../signal-icons/arrow-collapse-all.png"
import accountGroup from "../signal-icons/account-group.png"
import paw from "../signal-icons/paw.png"
import campaign from "../signal-icons/campaign.png"
import healthShield from "../signal-icons/health-shield.png"
import circleWrench from "../signal-icons/circle-wrench.png"
import flash from "../signal-icons/flash.png"
import flashOff from "../signal-icons/flash-off.png"
import fire from "../signal-icons/fire.png"
import waves from "../signal-icons/waves.png"
import weatherWindy from "../signal-icons/weather-windy.png"
import snowflake from "../signal-icons/snowflake.png"
import ambulance from "../signal-icons/ambulance.png"
import policeCar from "../signal-icons/police-car.png"
import police from "../signal-icons/police.png"
import fireFighter from "../signal-icons/fire-fighter.png"

export const existingSignalMarker = {
	Nord: { imgSrc: arrowDown },
	Sud: { imgSrc: arrowUp },
	Est: { imgSrc: arrowLeft },
	Ouest: { imgSrc: arrowRight },
	"Nord-est": { imgSrc: arrowBottomLeft },
	"Sud-est": { imgSrc: arrowTopLeft },
	"Nord-ouest": { imgSrc: arrowBottomRight },
	"Sud-ouest": { imgSrc: arrowTopRight },
	Danger: { imgSrc: alertIcon },
	"Circulation interdite": { imgSrc: minusCircle },
	Barrière: { imgSrc: landRowsVertical },
	Stationnement: { imgSrc: parking },
	"Zone évacuée": { imgSrc: evacuationZone },
	"Point de rassemblement": { imgSrc: arrowCollapseAll },
	"Groupe de personnes": { imgSrc: accountGroup },
	Animaux: { imgSrc: paw },
	Alerte: { imgSrc: campaign },
	"Service de secours": { imgSrc: healthShield },
	Réparation: { imgSrc: circleWrench },
	"Réseaux alimentés": { imgSrc: flash },
	"Réseaux coupés": { imgSrc: flashOff },
	Feu: { imgSrc: fire },
	Inondations: { imgSrc: waves },
	"Chûte de débris": { imgSrc: weatherWindy },
	Verglas: { imgSrc: snowflake },
	Ambulance: { imgSrc: ambulance },
	"Véhicule de police": { imgSrc: policeCar },
	Police: { imgSrc: police },
	"Camion de pompier": { imgSrc: fireFighter },
}
