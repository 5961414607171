import Button from "@/styles/atoms/Button"
import CommentIcon from "@mui/icons-material/Comment"
import Debug from "debug"
import Moment from "moment"
import React from "react"
import { useFieldArray, useFormContext, useWatch } from "react-hook-form"

// app:javascript:utils:form:FComments:FCommentsButtonAdd.tsx
const debug = Debug("app:javascript:utils:form:FComments:FCommentsButtonAdd")
debug.log = console.log.bind(console)

const FCommentsButtonAdd = ({
	name,
	buttonTitle = "Ajouter un commentaire",
	disabled = false,
}) => {
	const { control } = useFormContext()
	const { insert } = useFieldArray({
		control,
		// @ts-ignore
		name,
	})
	const value = useWatch({ name, control })
	return (
		<Button
			fullWidth
			color="primary"
			variant="bordered"
			startContent={<CommentIcon />}
			onClick={() =>
				insert(value?.length, {
					objet: "",
					date: Moment().format(),
				})
			}
			disabled={disabled}
		>
			{buttonTitle}
		</Button>
	)
}

export default FCommentsButtonAdd
