import GetApp from "@mui/icons-material/GetApp"
import { IconButton, Paper, Tab, Tabs } from "@mui/material"
import useUploadedFiles from "@/hooks/useUploadedFiles"
import moment from "moment"
import React, { useMemo, useState } from "react"
import styled from "styled-components"
import PageContainer from "utils/components/layout/PageContainer"
import { PrimaryButton } from "utils/components/style/button"
import CustomTooltip from "utils/components/style/CustomTooltip"
import { Flex } from "utils/components/style/flex"
import SuperReactTable from "utils/components/tables/SuperReactTable/SuperReactTable"
import { width50 } from "utils/components/tables/widthProps"
import TitleHeader from "utils/components/TitleHeader"
import { RUBY_DATE_FORMAT } from "@/utils/dateFormat"
import forceDownloadURL from "utils/forceDownloadURL"
import FileIcon from "utils/form/specific/fiche/links/utils/FileIcon"
import IUploadedFile, { IFileConfigCategory } from "utils/types/IUploadedFile"
import DownloadService from "../services/DownloadService"
import ModalUpload from "./ModalUpload"
import useUploadLocation from "./hooks/useUploadLocation"
import DeleteIcon from "@mui/icons-material/Delete"
import ModalSafeDelete from "./ModalSafeDelete"
import fileinfo from "utils/fileinfo"
import UpdateIcon from "@mui/icons-material/Update"
import Title from "@mui/icons-material/Title"
import ModalUpdateUpload from "./ModalUpdateUpload"
import DriveFileMoveIcon from "@mui/icons-material/DriveFileMove"
import ModalMoveUpload from "./ModalMoveUpload"
import useAccessRights from "@/hooks/useAccessRights"
import { PATH_UPLOAD } from "@/redux-toolkit/userSettings/constants"
import ErrorPermissions, {
	BACK_PATH_HOME,
	BACK_TITLE_HOME,
	FEATURE_ERROR_PERMISSION,
} from "@/hooks/ErrorPermissions"
import { ColumnDef } from "@tanstack/react-table"
import ModalModifyHeader from "./ModalModifyHeader"
import { EnumToolPath } from "@/hooks/services/useAccessRightsServices"
import Button from "@/styles/atoms/Button"

const STab = styled(Tab)`
    border-radius: 4px 4px 0 0 !important;
    transition: box-shadow 0.2s, background-color 0.2s;

    &.Mui-selected {
        box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%),
            0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
        background-color: white;
    }
`

const SPaper = styled(Paper)`
    flex: 1;
    padding: 1rem;
`

const Upload = () => {
	const [tab, setTab] = useState<IFileConfigCategory>("PROCEDURE")
	const [upload, setUpload] = useState(false)
	const [deleteFile, setDeleteFile] = useState({
		file: undefined,
		open: false,
	})
	const [updateFile, setUpdateFile] = useState({
		file: undefined,
		open: false,
	})
	const [moveFile, setMoveFile] = useState({
		file: undefined,
		open: false,
	})
	const [modifyHeader, setModifyHeader] = useState({
		file: undefined,
		open: false,
	})

	const uploadedFiles = useUploadedFiles()
	const { getUploadLocation, refresh } = useUploadLocation()

	const permissions = useAccessRights(EnumToolPath.UPLOAD)

	/* === DATA === */
	const tabUploadedFiles = useMemo(() => {
		return Object.values(uploadedFiles).filter(
			(file) => file.config.category === tab,
		)
	}, [tab, uploadedFiles])

	/* === COLUMNS === */
	const columns = useMemo(
		() =>
			[
				{
					accessorKey: "icon",
					cell: ({ row }) => {
						return (
							<Flex justifyCenter>
								<FileIcon filename={row.original.filename} />
							</Flex>
						)
					},
					...width50,
				},
				{
					accessorKey: "filename",
					header: "Nom du fichier",
					cell: ({ getValue }) => {
						const value = getValue() as any
						return <>{fileinfo(value).base}</>
					},
				},

				{
					accessorKey: "updated_at",
					header: "Dernière modification",
					cell: ({ getValue }) => {
						const value = getValue() as any
						return (
							<>{moment(value, RUBY_DATE_FORMAT).format("DD/MM/YYYY HH:mm")}</>
						)
					},
				},
				...(tab === "PROCEDURE" ||
				tab === "CONVENTION" ||
				tab === "PEDAGOGIQUE" ||
				tab === "CARTO"
					? [
							{
								accessorKey: "config",
								header: "Emplacement PCS",
								cell: ({ getValue }) => <>{getUploadLocation(getValue())}</>,
							},
					  ]
					: []),
				...(permissions.write
					? ([
							{
								header: "",
								accessorKey: "update",
								enableSorting: false,
								meta: {
									isAction: true,
								},
								...width50,
								cell: ({ row }) => {
									return (
										<CustomTooltip title="Mettre à jour">
											<IconButton
												onClick={() =>
													setUpdateFile({
														open: true,
														file: row.original,
													})
												}
											>
												<UpdateIcon />
											</IconButton>
										</CustomTooltip>
									)
								},
							},
							{
								header: "",
								accessorKey: "move",
								enableSorting: false,
								meta: {
									isAction: true,
								},
								...width50,
								cell: ({ row }) => {
									return (
										<CustomTooltip title="Déplacer">
											<IconButton
												onClick={() =>
													setMoveFile({
														open: true,
														file: row.original,
													})
												}
											>
												<DriveFileMoveIcon />
											</IconButton>
										</CustomTooltip>
									)
								},
							},
					  ] as ColumnDef<any>[])
					: []),
				...(permissions.write
					? ([
							{
								header: "",
								accessorKey: "header_update",
								enableSorting: false,
								meta: {
									isAction: true,
								},
								...width50,
								cell: (props) => {
									const id = props.row.original.id
									const filename = props.row.original.filename

									const onClickOpenModalModifyHeader = async () => {
										setModifyHeader({
											open: true,
											file: props.row.original,
										})
									}

									return (
										<CustomTooltip
											title={"Modifier la configuration du header"}
										>
											<IconButton onClick={onClickOpenModalModifyHeader}>
												<Title />
											</IconButton>
										</CustomTooltip>
									)
								},
							},
					  ] as ColumnDef<any>[])
					: []),
				{
					header: "",
					accessorKey: "download",
					enableSorting: false,
					...width50,
					isAction: true,
					cell: (props) => {
						const id = props.row.original.id
						const filename = props.row.original.filename

						const onClickDownload = async () => {
							const url = await DownloadService.getDownloadUrl(id)
							forceDownloadURL(url, filename)
						}

						return (
							<CustomTooltip title={"Télécharger"}>
								<IconButton onClick={onClickDownload}>
									<GetApp />
								</IconButton>
							</CustomTooltip>
						)
					},
				},
				...(permissions.write
					? ([
							{
								header: "",
								accessorKey: "deletable",
								enableSorting: false,
								meta: {
									isAction: true,
								},
								...width50,
								cell: ({ row }) => {
									return (
										<CustomTooltip title="Supprimer">
											<IconButton
												onClick={() =>
													setDeleteFile({
														open: true,
														file: row.original,
													})
												}
											>
												<DeleteIcon />
											</IconButton>
										</CustomTooltip>
									)
								},
							},
					  ] as ColumnDef<any>[])
					: []),
			] as ColumnDef<any>[],
		[tab, refresh],
	)

	if (!permissions.read)
		return (
			<ErrorPermissions
				errorMessage={FEATURE_ERROR_PERMISSION}
				backTitle={BACK_TITLE_HOME}
				backUrl={BACK_PATH_HOME}
			/>
		)

	return (
		<PageContainer>
			<TitleHeader
				title={"Téléversement"}
				rightElements={
					permissions.write && (
						<Button color="primary" onClick={() => setUpload(true)}>
							Téléverser un fichier
						</Button>
					)
				}
			/>
			<Flex directionColumn alignItemsStretch>
				<Tabs
					value={tab}
					onChange={(event, newTab) => setTab(newTab)}
					variant="scrollable"
					scrollButtons
					allowScrollButtonsMobile
				>
					<STab label="Procédures" value={"PROCEDURE" as IFileConfigCategory} />
					<STab
						label="Fiches pédagogiques"
						value={"PEDAGOGIQUE" as IFileConfigCategory}
					/>
					<STab
						label="Conventions"
						value={"CONVENTION" as IFileConfigCategory}
					/>
					<STab label="Cartographies" value={"CARTO" as IFileConfigCategory} />
					<STab
						label="Documents éditables"
						value={"EDITABLE" as IFileConfigCategory}
					/>
					<STab
						label="Images et autres"
						value={"IMAGE" as IFileConfigCategory}
					/>
				</Tabs>
				<SPaper>
					<SuperReactTable
						data={tabUploadedFiles}
						columns={columns}
						isEditable={false}
						isDeletable={false}
						selectable={false}
					/>
				</SPaper>
			</Flex>
			<ModalUpload
				isOpen={upload}
				onClose={(target: IUploadedFile) => {
					setUpload(false)
					if (target) setTab(target.config.category)
				}}
			/>
			<ModalSafeDelete
				isOpen={deleteFile.open}
				file={deleteFile.file}
				onClose={() => setDeleteFile({ ...deleteFile, open: false })}
			/>
			<ModalUpdateUpload
				isOpen={updateFile.open}
				file={updateFile.file}
				onClose={() => setUpdateFile({ ...updateFile, open: false })}
			/>
			<ModalMoveUpload
				isOpen={moveFile.open}
				file={moveFile.file}
				onClose={(target?: IFileConfigCategory) => {
					setMoveFile({ ...moveFile, open: false })
					if (target) setTab(target)
				}}
			/>
			<ModalModifyHeader
				isOpen={modifyHeader.open}
				file={modifyHeader.file}
				onClose={() => setModifyHeader({ ...modifyHeader, open: false })}
			/>
		</PageContainer>
	)
}

export default Upload
