import React, { useState, useEffect } from "react"
import { H4, H5 } from "utils/components/style/header"
import {
	PrimaryButton,
	PrimaryOutlinedButton,
} from "utils/components/style/button"
import styled from "styled-components"
import { Card, CardContent, Grid, IconButton, Tooltip } from "@mui/material"
import Axios from "axios"
import { updateCommune } from "@/redux-toolkit/commune/ressources"
import { useDispatch } from "react-redux"
import { toast } from "react-toastify"
import { Flex } from "utils/components/style/flex"
import { isDesktop, isMobile } from "react-device-detect"
import intercoNameAdapter from "utils/intercoNameAdapter"
import useIsInterco from "@/hooks/useIsInterco"
import InfoIcon from "@mui/icons-material/Info"
import useAccessRights from "@/hooks/useAccessRights"
import ErrorPermissions, {
	BACK_PATH_HOME,
	BACK_TITLE_HOME,
	FEATURE_ERROR_PERMISSION,
} from "@/hooks/ErrorPermissions"
import { EnumToolPath } from "@/hooks/services/useAccessRightsServices"
import FDropZone from "utils/form/utils/FDropZone"
import Yup from "utils/yup"
import { FormProvider, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import FText from "utils/form/FText"
import useCommune from "@/hooks/useCommune"
import _ from "lodash"
import { FormDependencies } from "utils/form/FormDependencies"
import DeleteIcon from "@mui/icons-material/Delete"
import ImageIcon from "@mui/icons-material/Image"

const TextCard = styled(Card)`
    margin: 0.5em;
    height: auto;
    min-height: calc(100% - 1em);
`
const ImgCard = styled(Card)`
    margin: 0.5em;
    height: auto;
`
const Header = styled(isMobile ? H5 : H4)`
	margin: 0;
`
const SInfoIcon = styled(InfoIcon)`
	color: #999999 !important;
`
const Simg = styled.img`
    max-width: 100%;
    max-height: 100%;
`
const SImageIcon = styled(ImageIcon)`
	color: var(--neutral400);
`

const CustomizedPcs = () => {
	const dispatch = useDispatch()
	const isInterco = useIsInterco()
	const commune = useCommune()
	const permissionMC = useAccessRights(EnumToolPath.SUIVI_CRISE)
	const readPermission = useAccessRights(EnumToolPath.CUSTOM_PCS).read
	const [defaultValues, setDefaultValues] = useState({
		mayorText: "",
		mayorSignature: null,
		mayorPicture: null,
		communePicture: null,
	})

	const schema = Yup.object().shape({
		mayorText: Yup.string().default(""),
		mayorSignature: Yup.string().nullable(),
		mayorPicture: Yup.string().nullable(),
		communePicture: Yup.string().nullable(),
	})

	const methods = useForm({
		resolver: yupResolver(schema),
		defaultValues: {
			mayorText: commune.mayorText,
			mayorSignature: commune.mayorSignature,
			mayorPicture: commune.mayorPicture,
			communePicture: commune.communePicture,
		},
		mode: "onChange",
	})

	const messageToltipMessage = `Le message du ${intercoNameAdapter(
		"maire",
		isInterco,
	)} pourra être retrouvé lors de la génération du ${
		isInterco ? "PICS" : "PCS"
	}`
	const logoToltipMessage = `Le logo de ${
		isInterco ? "l'intercommunalité" : "la commune"
	} pourra être retrouvé lors de la génération du ${
		isInterco ? "PICS" : "PCS"
	}${
		permissionMC.read
			? ", ainsi que dans la génération d'un rapport de crise"
			: ""
	}.`
	const imageToltipMessage = `L'image du ${intercoNameAdapter(
		"maire",
		isInterco,
	)} pourra être retrouvée lors de la génération du ${
		isInterco ? "PICS" : "PCS"
	}`
	const cachetToltipMessage = `Le cachet de ${
		isInterco ? "l'intercommunalité" : "la commune"
	} pourra être retrouvé lors de la génération du ${isInterco ? "PICS" : "PCS"}`

	// Initialisation du texte et images.
	useEffect(() => {
		const fetchFiles = async () => {
			const processImages = async (keys) => {
				const finalValues = {} as any

				const promises = keys.map(async (key) => {
					if (!_.isEmpty(commune[key])) {
						const res = await Axios.get(
							`/api/uploaded_files/url/${commune[key]}`,
						)
						return { id: commune[key], imgSrc: res.data }
					}
				})

				const allRes = await Promise.all(promises)
				keys.forEach((key, index) => {
					finalValues[key] = allRes[index]
				})
				return finalValues
			}

			const { mayorText, ...imagesKeys } = defaultValues // don't request mayorText
			const imagesValues = (await processImages(Object.keys(imagesKeys))) as any // get uploaded images

			const newDefaultValues = {
				mayorText: commune.mayorText,
				...imagesValues,
			}

			setDefaultValues({ ...defaultValues, ...newDefaultValues })
		}

		fetchFiles()
	}, [commune])

	const onSubmit = async (key, val) => {
		methods.setValue(key, val)

		const finalValues = methods.getValues()
		dispatch(updateCommune(finalValues))

		toast.success("Les informations saisies ont bien été enregistrées !")
	}

	const deletePicture = async (imageKey) => {
		if (imageKey) null //await UploadedFileApi.deleteFile(field.value)
		setDefaultValues({
			...defaultValues,
			[imageKey]: null,
		})
		onSubmit(imageKey, null)
	}

	if (!readPermission) {
		return (
			<ErrorPermissions
				errorMessage={FEATURE_ERROR_PERMISSION}
				backTitle={BACK_TITLE_HOME}
				backUrl={BACK_PATH_HOME}
			/>
		)
	}

	return (
		<FormProvider {...methods}>
			<Grid container>
				{/* =-=-=-=-=-=-=-=-=-= Mayor Text =-=-=-=-=-=-=-=-=-= */}
				<Grid item xs={12} md={12}>
					<TextCard>
						<CardContent>
							<Flex spaceBetween margin="0 0 1em 0">
								<Flex gap="8px">
									<Header>
										{intercoNameAdapter("Message du maire", isInterco)}
									</Header>
									<Tooltip title={messageToltipMessage} arrow>
										<SInfoIcon fontSize="small" />
									</Tooltip>
								</Flex>
								<FormDependencies targets={["mayorText"]}>
									{(targets) => (
										<>
											{!_.isEqual(targets.mayorText, commune.mayorText) && (
												<PrimaryButton
													onClick={() =>
														dispatch(updateCommune(methods.getValues()))
													}
												>
													Enregistrer la saisie
												</PrimaryButton>
											)}
										</>
									)}
								</FormDependencies>
							</Flex>
							<FText
								name="mayorText"
								label={"Saisissez le texte"}
								customInputProps={{
									rows: 10,
									multiline: true,
									inputComponent: "textarea",
								}}
							/>
						</CardContent>
					</TextCard>
				</Grid>
				{/* =-=-=-=-=-=-=-=-=-= Commune Cachet =-=-=-=-=-=-=-=-=-= */}
				<Grid item xs={12} md={6}>
					<ImgCard>
						<CardContent>
							<Flex fullWidth spaceBetween margin="0 0 .5em 0">
								<Flex gap="8px">
									<Header>
										Cachet de {isInterco ? "l'intercommunalité" : "la mairie"}
									</Header>
									<Tooltip title={cachetToltipMessage} arrow>
										<SInfoIcon fontSize="small" />
									</Tooltip>
								</Flex>
								{commune.mayorSignature && isMobile && (
									<IconButton
										onClick={() => {
											deletePicture("mayorSignature")
										}}
									>
										<DeleteIcon>Supprimer l&apos;image</DeleteIcon>
									</IconButton>
								)}
								{commune.mayorSignature && isDesktop && (
									<PrimaryOutlinedButton
										onClick={() => {
											deletePicture("mayorSignature")
										}}
									>
										Supprimer l&apos;image
									</PrimaryOutlinedButton>
								)}
							</Flex>

							<FDropZone
								category="IMAGE"
								onDropFinal={(res) => onSubmit("mayorSignature", res.id)}
								customUploadContent={{
									icon: <SImageIcon />,
									allowedFormatsText: "(png, jpeg, webp)",
								}}
							>
								{commune.mayorSignature && (
									<Simg src={defaultValues.mayorSignature?.imgSrc} />
								)}
							</FDropZone>
						</CardContent>
					</ImgCard>
				</Grid>
				{/* =-=-=-=-=-=-=-=-=-= Mayor Picture =-=-=-=-=-=-=-=-=-= */}
				<Grid item xs={12} md={6}>
					<ImgCard>
						<CardContent>
							<Flex fullWidth spaceBetween margin="0 0 .5em 0">
								<Flex gap="8px">
									<Header>
										{intercoNameAdapter("Image du maire", isInterco)}
									</Header>
									<Tooltip title={imageToltipMessage} arrow>
										<SInfoIcon fontSize="small" />
									</Tooltip>
								</Flex>{" "}
								{commune.mayorPicture && isMobile && (
									<IconButton
										onClick={() => {
											deletePicture("mayorPicture")
										}}
									>
										<DeleteIcon>Supprimer l&apos;image</DeleteIcon>
									</IconButton>
								)}
								{commune.mayorPicture && isDesktop && (
									<PrimaryOutlinedButton
										onClick={() => {
											deletePicture("mayorPicture")
										}}
									>
										Supprimer l&apos;image
									</PrimaryOutlinedButton>
								)}
							</Flex>
							<FDropZone
								category="IMAGE"
								onDropFinal={(res) => onSubmit("mayorPicture", res.id)}
								customUploadContent={{
									icon: <SImageIcon />,
									allowedFormatsText: "(png, jpeg, webp)",
								}}
							>
								{commune.mayorPicture && (
									<Simg src={defaultValues.mayorPicture?.imgSrc} />
								)}
							</FDropZone>
						</CardContent>
					</ImgCard>
				</Grid>
				{/* =-=-=-=-=-=-=-=-=-= Commune Logo =-=-=-=-=-=-=-=-=-= */}
				<Grid item xs={12} md={6}>
					<ImgCard>
						<CardContent>
							<Flex fullWidth spaceBetween margin="0 0 .5em 0">
								<Flex gap="8px">
									<Header>
										Logo de {isInterco ? "l'intercommunalité" : "la commune"}
									</Header>
									<Tooltip title={logoToltipMessage} arrow>
										<SInfoIcon fontSize="small" />
									</Tooltip>
								</Flex>{" "}
								{commune.communePicture && isMobile && (
									<IconButton
										onClick={() => {
											deletePicture("communePicture")
										}}
									>
										<DeleteIcon>Supprimer l&apos;image</DeleteIcon>
									</IconButton>
								)}
								{commune.communePicture && isDesktop && (
									<PrimaryOutlinedButton
										onClick={() => {
											deletePicture("communePicture")
										}}
									>
										Supprimer l&apos;image
									</PrimaryOutlinedButton>
								)}
							</Flex>
							<FDropZone
								category="IMAGE"
								onDropFinal={(res) => onSubmit("communePicture", res.id)}
								customUploadContent={{
									icon: <SImageIcon />,
									allowedFormatsText: "(png, jpeg, webp)",
								}}
							>
								{commune.communePicture && (
									<Simg src={defaultValues.communePicture?.imgSrc} />
								)}
							</FDropZone>
						</CardContent>
					</ImgCard>
				</Grid>
			</Grid>
		</FormProvider>
	)
}

export default CustomizedPcs
