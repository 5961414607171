import ExpandLessIcon from "@mui/icons-material/ExpandLess"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import { Avatar, Chip } from "@mui/material"
import useCareLeftPersonsByGroup from "@/hooks/care/useCareLeftPersonsByGroup"
import useAccessRights from "@/hooks/useAccessRights"
import usePoints from "@/hooks/usePoints"
import useSelectedEvent from "@/hooks/useSelectedEvent"
import { deletePointWithUpdateEvent } from "@/redux-toolkit/data/points/resources"
import {
	CARE_FAMILY,
	PATH_MAIN_COURANTE_CARE,
} from "@/redux-toolkit/userSettings/constants"
import Debug from "debug"
import Moment from "moment"
import React, { useMemo, useState } from "react"
import { useDispatch } from "react-redux"
import { useParams } from "react-router-dom"
import {
	PrimaryButton,
	PrimaryOutlinedButton,
	PrimaryTextButton,
} from "@/utils/components/style/button"
import { StyledChip } from "@/utils/components/style/chip"
import { Flex } from "@/utils/components/style/flex"
import { Padding4 } from "@/utils/components/style/padding"
import SuperReactTable from "@/utils/components/tables/SuperReactTable/SuperReactTable"
import SuperReactTableCell from "@/utils/components/tables/SuperReactTableCell"
import { width180, widthCustom } from "@/utils/components/tables/widthProps"
import { specificityOptions } from "@/utils/form/specific/care/FamilyMemberCard"
import AdultChip from "./chips/AdultChip"
import AnimalChip from "./chips/AnimalChip"
import BabyChip from "./chips/BabyChip"
import ChildChip from "./chips/ChildChip"
import ModalDaybookLeavingGroup from "./leaving/ModalDaybookLeavingGroup"
import ModalEnterFamily from "./modals/ModalEnterFamily"
import _ from "lodash"
import { sortTypeDateFactory } from "@/utils/sort"
import { isDesktop, isMobile } from "react-device-detect"
import styled, { css } from "styled-components"
import { IFamilyMemberType } from "@/utils/types/IFamilyMember"
import { ColumnDef } from "@tanstack/react-table"
import { EnumToolPath } from "@/hooks/services/useAccessRightsServices"
import Button from "@/styles/atoms/Button"

// app:javascript:components:maincourante:care:FamilyEnterTable.tsx
const debug = Debug(
	"app:javascript:components:maincourante:care:FamilyEnterTable",
)
debug.log = console.log.bind(console)

const SFlex = styled(Flex)<{ width: string }>`
    ${({ width }) =>
			css`
            max-width: ${width};
        `}
    justify-content: ${isMobile ? "flex-end" : null};
`

const FamilyEnterTable = () => {
	const [isOpenModalLeavingGroup, setIsOpenModalLeavingGroup] = useState(false)
	const [initialLeavingGroupData, setInitialLeavingGroupData] =
		useState(undefined)
	const [expandedRowId, setExpandedRowId] = useState("default value")
	const [currentFamily, setCurrentFamily] = useState(undefined)
	const [isOpen, setIsOpen] = useState(false)
	const dispatch = useDispatch()
	const { careId } = useParams<{ careId: string }>()
	const selectedEvent = useSelectedEvent()
	const permissions = useAccessRights(EnumToolPath.CARE)
	const points = usePoints()
	const leftPersonsByGroup = useCareLeftPersonsByGroup()
	const isLocked = selectedEvent?.locked

	const concernedPoints = Object.values(points).filter(
		(point) =>
			point.geojson?.properties?.["careId"] === careId &&
			point.event_id === selectedEvent?.id &&
			point.jsonschema_id === CARE_FAMILY,
	)

	const openLeavingGroup = (row) => {
		const initialValue = {
			geojson: {
				properties: {
					"Date de sortie": Moment().format("DD/MM/YYYY HH:mm"),
					"Lieu de sortie": "",
					"Points associés": [],
				},
			},
		}
		const leftPersonConcerned = leftPersonsByGroup[row.original.id]

		_.times(
			(row.original?.geojson?.properties?.family?.length ?? 0) + 1,
			(index) => {
				if (leftPersonConcerned?.includes(index - 1)) {
					return
				}
				initialValue.geojson.properties["Points associés"] = [
					...initialValue.geojson.properties["Points associés"],
					{
						"Identifiant du point associé": row.original.id,
						"Index du membre associé": index - 1,
					},
				]
			},
		)

		setInitialLeavingGroupData(initialValue)
		setIsOpenModalLeavingGroup(true)
	}

	const columns = useMemo(() => {
		const buildedColumns = [
			{
				header: "Heure d'arrivée",
				accessorKey: "geojson.properties.Heure arrivée",
				cell: ({ getValue }) => {
					const value = getValue()
					if (!value) {
						return <span>Heure non définie</span>
					}
					return <>{Moment(value).format("DD/MM HH:mm")}</>
				},
				sortType: sortTypeDateFactory("geojson.properties.Heure arrivée"),
				...widthCustom(130),
			},
			{
				header: "Référent",
				accessorKey: "geojson.properties.Identité",
				...width180,
			},
			{
				header: "Membres",
				accessorKey: "geojson.properties.family",
				enableSorting: false,
				cell: ({ row, cell, getValue, table }) => {
					const value = getValue() as any[]
					const getMemberCount = (type) => {
						const leavedMembersIndex = leftPersonsByGroup[row.original.id] ?? []
						let nbMember =
							leavedMembersIndex.includes(-1) &&
							type === IFamilyMemberType.ADULT
								? -1
								: 0
						value
							.filter(
								(familyMember, currentIndex) =>
									familyMember.type === type &&
									!leavedMembersIndex.includes(currentIndex),
							)
							.forEach(
								(memberObject) => (nbMember += memberObject.quantity ?? 1),
							)
						return nbMember
					}

					const childrenMember = value.filter(
						(familyMember) => familyMember.type === IFamilyMemberType.CHILD,
					)
					const babyMember = value.filter(
						(familyMember) => familyMember.type === IFamilyMemberType.BABY,
					)
					const animalMember = value.filter(
						(familyMember) => familyMember.type === IFamilyMemberType.ANIMAL,
					)

					if (
						!row.getIsExpanded() &&
						expandedRowId === row.original.id &&
						expandedRowId
					) {
						row.toggleExpanded()
					}
					const isExpanded = row.getIsExpanded()
					return (
						<>
							<Flex fullWidth={isDesktop} spaceBetween gap="0.5rem">
								<SFlex width={isMobile ? "50vw" : "200px"} gap="0.5rem" $wrap>
									<AdultChip
										size="small"
										number={getMemberCount(IFamilyMemberType.ADULT) + 1}
									/>
									{childrenMember.length > 0 && (
										<ChildChip
											size="small"
											number={getMemberCount(IFamilyMemberType.CHILD)}
										/>
									)}
									{babyMember.length > 0 && (
										<BabyChip
											size="small"
											number={getMemberCount(IFamilyMemberType.BABY)}
										/>
									)}
									{animalMember.length > 0 && (
										<AnimalChip
											size="small"
											number={getMemberCount(IFamilyMemberType.ANIMAL)}
										/>
									)}
								</SFlex>
								<PrimaryTextButton
									endIcon={isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
									onClick={() => {
										if (isExpanded) {
											setExpandedRowId(undefined)
											row.toggleExpanded()
											return
										}
										setExpandedRowId(row.original.id)
									}}
								>
									Détails
								</PrimaryTextButton>
							</Flex>
						</>
					)
				},
				meta: {
					expandableCell: true,
				},
			},
			{
				header: "Spécificités",
				accessorKey: "geojson.properties.Spécificités",
				enableSorting: false,
				cell: ({ cell, row, getValue }) => {
					const value = getValue() as string[]
					const specificities = specificityOptions
						.map((option) => {
							let count = 0
							if (value.includes(option.label)) {
								count++
							}
							row.original.geojson.properties.family.forEach((familyMember) => {
								if ((familyMember?.Spécificités || []).includes(option.label)) {
									count++
								}
							})
							return {
								label: option.label,
								count,
							}
						})
						.filter((specificities) => specificities.count > 0)
					return (
						<>
							<SFlex
								fullWidth={isDesktop}
								width={isMobile ? "70vw" : null}
								gap="0.5rem"
								$wrap
							>
								{specificities.map((specificity) => (
									<Chip
										variant="outlined"
										size="small"
										key={specificity.label}
										label={specificity.label}
										avatar={<Avatar>{specificity.count}</Avatar>}
									/>
								))}
							</SFlex>
						</>
					)
				},
			},
			{
				header: "Téléphone",
				accessorKey: "Téléphone",
				cell: ({
					cell: {
						row: { original },
					},
				}) => {
					return (
						<>
							<Flex>{original.geojson.properties.Téléphone}</Flex>
						</>
					)
				},
				...widthCustom(130),
			},
			{
				header: "Statut",
				accessorKey: "presence",
				enableSorting: false,
				cell: ({
					cell: {
						row: { original },
					},
				}) => {
					const familyNumber =
						_.sum(
							original.geojson.properties.family.map(
								(memberObj) => memberObj.quantity ?? 1,
							),
						) + 1 //* +1 to count the referer

					if (familyNumber === leftPersonsByGroup[original.id]?.length) {
						return (
							<>
								<StyledChip
									$colorProperty="var(--red500)"
									label="Sortie(s)"
									variant="outlined"
								/>
							</>
						)
					}
					if (!leftPersonsByGroup[original.id]?.length) {
						return (
							<>
								<StyledChip
									$colorProperty="var(--green500)"
									label="Présent(s)"
									variant="outlined"
								/>
							</>
						)
					}

					return (
						<>
							<StyledChip
								$colorProperty="var(--orange500)"
								label={`${
									familyNumber -
									_.sum(
										leftPersonsByGroup[original.id].map((leavedObjIndex) => {
											return (
												original.geojson.properties.family?.[leavedObjIndex]
													?.quantity ?? 1
											)
										}),
									)
								} / ${familyNumber} Présent(s)`}
								variant="outlined"
							/>
						</>
					)
				},
				...widthCustom(110),
			},
			{
				header: "Sortie",
				accessorKey: "leaving",
				enableSorting: false,
				cell: ({ row }) => {
					let disabled = false
					const familyNumber =
						(row.original.geojson.properties.family?.length ?? 0) + 1
					if (familyNumber === leftPersonsByGroup[row.original.id]?.length) {
						disabled = true
					}
					return (
						<>
							<Flex gap="0.5rem" $wrap>
								<Button
									color="primary"
									variant="bordered"
									disabled={isLocked || !permissions.write || disabled}
									onClick={() => openLeavingGroup(row)}
								>
									Sortie
								</Button>
							</Flex>
						</>
					)
				},
				...widthCustom(110),
			},
		] as ColumnDef<any>[]

		return buildedColumns
	}, [leftPersonsByGroup, expandedRowId])

	const columnsSubRow = useMemo(() => {
		return [
			{
				header: "Type",
				accessorKey: "type",
				cell: ({
					cell: {
						row: { original },
					},
				}) => {
					switch (original.type) {
						case IFamilyMemberType.ADULT:
							return <AdultChip />
						case IFamilyMemberType.CHILD:
							return <ChildChip />
						case IFamilyMemberType.BABY:
							return <BabyChip />
						case IFamilyMemberType.ANIMAL:
							return <AnimalChip />
						default:
							return null
					}
				},
				...widthCustom(120),
			},
			{
				header: "Présence",
				accessorKey: "presence",
				cell: ({ getValue }) => {
					if (getValue()) {
						return (
							<StyledChip
								$colorProperty="var(--green500)"
								label="Présent(e)"
								variant="outlined"
							/>
						)
					}
					return (
						<StyledChip
							$colorProperty="var(--red500)"
							label="Sorti(e)"
							variant="outlined"
						/>
					)
				},
				...widthCustom(120),
			},
			{
				header: "Identité",
				accessorKey: "Identité",
				cell: ({
					cell: {
						row: { original },
					},
				}) => {
					return <SuperReactTableCell>{original.Identité}</SuperReactTableCell>
				},
			},
			{
				header: "Spécificités",
				accessorKey: "Spécificités",
				cell: ({
					row,
					cell: {
						row: { original },
					},
					...other
				}) => {
					return (
						<SFlex width={isMobile ? "70vw" : null} gap="0.25rem" $wrap>
							{(original?.Spécificités ?? []).map((label) => (
								<Chip
									key={label}
									variant="outlined"
									size="small"
									label={label}
								/>
							))}
						</SFlex>
					)
				},
			},
			{
				header: "Commentaire",
				accessorKey: "Commentaire",
				cell: ({
					cell: {
						row: { original },
					},
				}) => {
					return (
						<SuperReactTableCell>
							<SFlex width={isMobile ? "70vw" : null}>
								{original?.Commentaire}
							</SFlex>
						</SuperReactTableCell>
					)
				},
			},
		] as ColumnDef<any>[]
	}, [])

	return (
		<>
			<SuperReactTable
				data={concernedPoints}
				columns={columns}
				initialSortBy={[
					{
						desc: true,
						id: "geojson.properties.Heure arrivée",
					},
				]}
				RenderSubRow={({ row }) => {
					let family = row.original.geojson.properties?.family ?? []
					family = [
						{
							Identité: row.original.geojson.properties?.Identité,
							type: IFamilyMemberType.ADULT,
							Commentaire: row.original.geojson.properties?.Commentaire,
							Spécificités: row.original.geojson.properties?.Spécificités,
						},
						...family,
					]
					family = family.map((member, index) => {
						if (leftPersonsByGroup[row.original.id]?.includes(index - 1)) {
							return {
								...member,
								presence: false,
							}
						}
						return {
							...member,
							presence: true,
						}
					})

					return (
						<Flex>
							<SuperReactTable
								columns={columnsSubRow}
								data={family}
								selectable={false}
								showBottom={false}
								isEditable={false}
								isDeletable={false}
								simpleTable
								$secondaryTableStyle
							/>
						</Flex>
					)
				}}
				writePermission={permissions.write && !isLocked}
				isEditable
				onEditClick={(row) => {
					setIsOpen(true)
					setCurrentFamily(row)
				}}
				isDeletable={permissions.write && !isLocked}
				onDeleteClick={(point) => {
					dispatch(
						deletePointWithUpdateEvent({
							point,
							event: {
								...selectedEvent,
								history: [
									...selectedEvent?.history,
									{
										type: CARE_FAMILY,
										title: "Suppression d'un groupe en centre d'accueil",
										date: Moment().format(),
										noAction: true,
									},
								],
							},
						}),
					)
				}}
				actionsButtons={
					<>
						{permissions.write && (
							<Button
								color="primary"
								onClick={() => {
									setCurrentFamily(undefined)
									setIsOpen(true)
								}}
								disabled={isLocked}
							>
								Ajouter une entrée
							</Button>
						)}
					</>
				}
				isLocked={isLocked}
			/>
			<ModalEnterFamily
				isOpen={isOpen}
				onClose={() => setIsOpen(!isOpen)}
				currentFamily={currentFamily}
			/>
			<ModalDaybookLeavingGroup
				isOpen={isOpenModalLeavingGroup}
				onClose={() => setIsOpenModalLeavingGroup(false)}
				careId={careId}
				initialValues={initialLeavingGroupData}
				leavingGroupId={undefined}
			/>
		</>
	)
}

export default FamilyEnterTable
