import ChevronLeftIcon from "@mui/icons-material/ChevronLeft"
import ChevronRightIcon from "@mui/icons-material/ChevronRight"
import { IconButton } from "@mui/material"
import { BlobProvider, PDFDownloadLink } from "@react-pdf/renderer"
import React, { useEffect, useState } from "react"
import { Document, Page, pdfjs } from "react-pdf"
import styled from "styled-components"
import {
	PrimaryButton,
	PrimaryOutlinedButton,
} from "utils/components/style/button"
import PrintIcon from "@mui/icons-material/Print"
import GetApp from "@mui/icons-material/GetApp"
import { Flex } from "utils/components/style/flex"
import useSelectedEvent from "@/hooks/useSelectedEvent"
import { useHistory, useLocation } from "react-router-dom"
import usePoints from "@/hooks/usePoints"
import useOrga from "@/hooks/useOrga"
import useHumans from "@/hooks/useHumans"
import _ from "lodash"
import useOpenAppBar from "@/hooks/useOpenAppBar"
import ReportService from "@/pages/carto2/cartographie/service/ReportService"
import ActionsPDF from "./ActionsPDF"
import useMultiPolygons from "@/hooks/useMultiPolygons"
import ArrowBackIcon from "@mui/icons-material/ArrowBack"

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`

const StyledEmptyDiv = styled.div`
    width: 160px;
`
const SFlex = styled.div`
    flex-grow: 10;
    display: flex;
    justify-content: center;
    align-items: center;
`
const SPage = styled(Page)`
    box-shadow: 0 30px 40px 0 rgb(16 36 94 / 20%);
`
const SPDFDownloadLink = styled(PDFDownloadLink)`
    margin: 1em;
`

const DaybookPrintActions = () => {
	const [numPages, setNumPages] = useState(null)
	const [_isOpenAppBar, setOpenAppBar] = useOpenAppBar()
	const points = usePoints()
	const selectedEvent = useSelectedEvent()
	const [isProcessingWisiwygExtraction, setIsProcessingWisiwygExtraction] =
		useState(true)
	const humans = useHumans()
	const orgas = useOrga()
	const [objectOfWisiwygBlobsArray, setObjectOfWisiwygBlobsArray] = useState({})
	const [pageNumber, setPageNumber] = useState(1) //setting 1 to show fisrt page
	const locationProps = useLocation() as any
	const [documentLoading, setDocumentLoading] = useState(false)
	const [pdfBlobUrl, setPdfBlobUrl] = useState(null)
	const multiPolygons = useMultiPolygons()
	const secteurs = Object.values(multiPolygons).filter((multiPolygon) =>
		multiPolygon.name.includes("Secteur"),
	)
	const history = useHistory()

	const eventsPoints = Object.values(points)
		.filter((point: any) => point.jsonschema_id === "INCIDENT")
		.filter((eventPoint: any) => eventPoint.event_id === selectedEvent.id)

	useEffect(() => {
		setOpenAppBar(false)
		ReportService.getWisiwigEntities({
			elements: eventsPoints,
			setIsProcessingWisiwygExtraction,
			setObjectOfWisiwygBlobsArray,
		})
	}, [])

	useEffect(() => {
		if (!isProcessingWisiwygExtraction) {
			setDocumentLoading(true)
		}
	}, [isProcessingWisiwygExtraction])

	const onDocumentLoadSuccess = ({ numPages }) => {
		setNumPages(numPages)
		setPageNumber(1)
	}

	const changePage = (offset) => {
		setPageNumber((prevPageNumber) => prevPageNumber + offset)
	}

	const previousPage = () => {
		changePage(-1)
	}

	const nextPage = () => {
		changePage(1)
	}

	const startPrint = () => {
		const iframe = document.createElement("iframe") //load content in an iframe to print later
		document.body.appendChild(iframe)

		iframe.style.display = "none"
		iframe.src = pdfBlobUrl
		iframe.onload = function () {
			setTimeout(function () {
				iframe.focus()
				iframe.contentWindow.print()
			}, 1)
		}
	}

	return (
		<div>
			<Flex spaceBetween>
				<PrimaryOutlinedButton
					$margin="1rem"
					onClick={() => history.push("/maincourante/donnees")}
					startIcon={<ArrowBackIcon />}
				>
					Retour
				</PrimaryOutlinedButton>
				<StyledEmptyDiv />
				<SFlex>
					<IconButton onClick={previousPage} disabled={pageNumber === 1}>
						<ChevronLeftIcon />
					</IconButton>
					<div>
						Feuille <b>{pageNumber}</b> sur {numPages}
					</div>
					<IconButton onClick={nextPage} disabled={pageNumber === numPages}>
						<ChevronRightIcon />
					</IconButton>
				</SFlex>
				{/* isProcessingWisiwygExtraction prevent disapearing button because documentLoading start at false */}
				{!documentLoading && !isProcessingWisiwygExtraction && (
					<PrimaryButton startIcon={<PrintIcon />} onClick={() => startPrint()}>
						Imprimer
					</PrimaryButton>
				)}
				<SPDFDownloadLink
					document={
						<ActionsPDF
							actions={locationProps.state.actions}
							eventsPoints={eventsPoints}
							objectOfWisiwygBlobsArray={objectOfWisiwygBlobsArray}
							humans={humans}
							orgas={orgas}
							secteurs={secteurs}
						/>
					}
					fileName={"Intervention(s) - " + selectedEvent?.name + ".pdf"}
				>
					{({ blob, url, loading, error }) => {
						if (loading) {
							return <div>Chargement...</div>
						}
						return (
							<PrimaryButton startIcon={<GetApp />}>Télécharger</PrimaryButton>
						)
					}}
				</SPDFDownloadLink>
			</Flex>
			<BlobProvider
				document={
					<ActionsPDF
						actions={locationProps.state.actions}
						eventsPoints={eventsPoints}
						objectOfWisiwygBlobsArray={objectOfWisiwygBlobsArray}
						humans={humans}
						orgas={orgas}
						secteurs={secteurs}
					/>
				}
			>
				{({ blob, url, loading, error }) => {
					// Do whatever you need with blob here
					if (loading) {
						if (!documentLoading) {
							setDocumentLoading(true)
						}
						return <div>Chargement du document...</div>
					}
					if (documentLoading) {
						//avoid multiple print at once cross origin error when providing "url" only
						const superBlob = URL.createObjectURL(blob)
						setPdfBlobUrl(superBlob)
						setDocumentLoading(false)
					}

					return (
						<div>
							<Document file={url} onLoadSuccess={onDocumentLoadSuccess}>
								<Flex gap={"50px"} justifyCenter>
									<SPage
										pageNumber={pageNumber}
										renderAnnotationLayer={false}
									/>
								</Flex>
							</Document>
						</div>
					)
				}}
			</BlobProvider>
		</div>
	)
}

export default DaybookPrintActions
