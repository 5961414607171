import PlaylistRemoveIcon from "@mui/icons-material/PlaylistRemove"
import Close from "@mui/icons-material/Close"
import DescriptionIcon from "@mui/icons-material/Description"
import FolderIcon from "@mui/icons-material/Folder"
import { Card, CardContent, IconButton, Tooltip } from "@mui/material"
import Debug from "debug"
import _, { isEqual } from "lodash"
import React, { useContext, useEffect, useState } from "react"
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd"
import styled from "styled-components"
import { Flex } from "utils/components/style/flex"
import { TreeContext } from "../../redux/page/context"
import { setSelection } from "../../redux/selection/action"
import { SelectionContext } from "../../redux/selection/context"
import SortIcon from "@mui/icons-material/Sort"
import { resetTree, toggleCheck } from "../../redux/tree/action"
import { getTreePathFromKey } from "../../redux/tree/utils"
import DragIndicatorIcon from "@mui/icons-material/DragIndicator"
import IconButtonCustomizePcsGeneration from "./IconButtonCustomizePcsGeneration"
import TreeService from "utils/services/TreeService"

const StyledTitleContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`

const StyledH5 = styled.h5`
    flex: 1;
    padding-left: 40px;
    margin: 0 !important;
    text-align: center;
`

const StyledCardContent = styled(CardContent)`
    padding: 10px !important;
`
const CardContentContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`
const StyledCard = styled(Card)`
    margin: 1px;
    flex-shrink: 0;
`

const ItemTitle = styled.div`
    margin-left: 1em;
`
const Scrollable = styled.div`
    overflow: auto;
`

const debug = Debug("components:telechargement:CartList")

const CartList = ({
	checkedTreeElement,
	keyOrder,
	setKeyOrder,
	updateSelectedKit = undefined,
	kitSelected = undefined,
	isEditing = false,
}) => {
	const { state: selectionState, dispatch: selectionDispatch } =
		useContext(SelectionContext)
	const { state: treeState, dispatch: treeDispatch } = useContext(TreeContext)
	const [currentSelectedKit, setCurrentSelectedKit] = useState(undefined)
	const [
		isOpenModalCustomizePcsGeneration,
		setIsOpenModalCustomizePcsGeneration,
	] = useState(false)

	// ? a little function to help us with reordering the result
	const reorder = (list, startIndex, endIndex) => {
		const result = Array.from(list)
		const [removed] = result.splice(startIndex, 1)
		result.splice(endIndex, 0, removed)
		return result
	}

	const onDragEnd = (result) => {
		if (!result.destination) {
			return
		}
		const newItems = reorder(
			selectionState,
			result.source.index,
			result.destination.index,
		)
		selectionDispatch(setSelection(newItems))
		const newKeyOrder = {}
		newItems.forEach((item: any, index) => (newKeyOrder[item.key] = index))
		setKeyOrder(newKeyOrder)
		{
			updateSelectedKit && !isEditing ? updateSelectedKit(undefined) : null
		}
	}

	// ? keyOrder allow to conserve the order of the elements dragged by user before adding an other element
	useEffect(() => {
		const selectionStateClone = { ...selectionState }
		if (kitSelected && selectionStateClone.length > 0 && !isEditing) {
			const reordenedSelection = []
			const kitOrder = []
			kitSelected.kit_content.forEach((kitElement: any) => {
				selectionStateClone.forEach((elementSelected, index) => {
					if (kitElement === elementSelected.key) {
						kitOrder[elementSelected.key] = index
						elementSelected.sortedRank = index
						reordenedSelection.push(elementSelected)
					}
				})
			})
			if (!_.isEqual(reordenedSelection, selectionStateClone)) {
				selectionDispatch(setSelection(reordenedSelection))
			}
		}
	}, [checkedTreeElement, selectionState, kitSelected, keyOrder])

	useEffect(() => {
		if (keyOrder) {
			const sortedElements = checkedTreeElement.map((element) => {
				return {
					...element,
					sortedRank: keyOrder[element.key],
				}
			})
			const newOrderItems = _.orderBy(sortedElements, "sortedRank")
			if (!_.isEqual(newOrderItems, selectionState)) {
				selectionDispatch(setSelection(newOrderItems))
			}
		} else {
			if (!_.isEqual(checkedTreeElement, selectionState)) {
				selectionDispatch(setSelection(checkedTreeElement))
			}
		}
	}, [checkedTreeElement, kitSelected, keyOrder, selectionState])

	useEffect(() => {
		if (kitSelected && !isEditing && kitSelected !== currentSelectedKit) {
			if (_.isEmpty(selectionState)) {
				return
			}
			setCurrentSelectedKit(kitSelected)
			const kitOrder = {}
			selectionState.forEach((elementSelected) => {
				kitSelected.kit_content.forEach((kitElement: any, index) => {
					if (kitElement === elementSelected.key) {
						kitOrder[elementSelected.key] = index
					}
				})
			})
			setKeyOrder(kitOrder)
		}
	}, [kitSelected, selectionState, currentSelectedKit])

	const deselect = (item) => {
		const path = getTreePathFromKey({ tree: treeState, key: item.key })
		treeDispatch(toggleCheck(path))
		const { [item.key]: index, ...newOrder } = keyOrder as any
		setKeyOrder(newOrder)
	}

	const deselectAll = () => {
		treeDispatch(resetTree())
		setKeyOrder({})
	}

	const sortKeysInTreeOrder = () => {
		setKeyOrder((keyOrder) => {
			const newOrder = {}
			const itemWithOrder = selectionState.map((item) => {
				const path = getTreePathFromKey({ tree: treeState, key: item.key })
				const order = TreeService.calculateOrder(treeState, item.key)
				return {
					key: item.key,
					order,
				}
			})
			itemWithOrder.sort((a, b) => a.order - b.order)
			itemWithOrder.forEach((item, index) => {
				newOrder[item.key] = index
			})
			return newOrder
		})
	}

	return (
		<>
			<StyledCard>
				<StyledCardContent>
					<StyledTitleContainer>
						<StyledH5>
							{kitSelected || isEditing
								? "Kit " + kitSelected?.kit_name
								: "Sélection"}
						</StyledH5>
						<Tooltip title="Trier dans l'ordre du PCS">
							<IconButton onClick={sortKeysInTreeOrder}>
								<SortIcon />
							</IconButton>
						</Tooltip>
						<IconButtonCustomizePcsGeneration />
						{!isEditing && (
							<Tooltip title="Désélectionner tout">
								<IconButton
									onClick={() => {
										deselectAll()
										updateSelectedKit(undefined)
									}}
									disabled={!selectionState.length}
								>
									<PlaylistRemoveIcon />
								</IconButton>
							</Tooltip>
						)}
					</StyledTitleContainer>
				</StyledCardContent>
			</StyledCard>
			<DragDropContext onDragEnd={onDragEnd}>
				<Droppable droppableId="droppable">
					{(provided) => (
						<Scrollable {...provided.droppableProps} ref={provided.innerRef}>
							{selectionState.map((item, index) => {
								return (
									<Draggable
										key={item.key}
										draggableId={item.key}
										index={index}
									>
										{(provided) => (
											<StyledCard
												ref={provided.innerRef}
												{...provided.draggableProps}
												{...provided.dragHandleProps}
											>
												<StyledCardContent>
													<CardContentContainer>
														<Flex>
															{item.children && <FolderIcon />}
															{!item.children && <DescriptionIcon />}
															<ItemTitle>
																{item?.code
																	? item?.code + " " + item?.title
																	: item.title}
															</ItemTitle>
														</Flex>
														{isEditing && (
															<IconButton>
																<DragIndicatorIcon />
															</IconButton>
														)}
														{!isEditing && (
															<IconButton
																onClick={() => {
																	deselect(item)
																	updateSelectedKit(undefined)
																}}
															>
																<Close />
															</IconButton>
														)}
													</CardContentContainer>
												</StyledCardContent>
											</StyledCard>
										)}
									</Draggable>
								)
							})}
							{provided.placeholder}
						</Scrollable>
					)}
				</Droppable>
			</DragDropContext>
		</>
	)
}
export default CartList
