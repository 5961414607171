import { View as RView } from "@react-pdf/renderer"
import _ from "lodash"
import React from "react"
import HeaderBanner from "utils/pdf/header/HeaderBanner"
import PH2 from "utils/pdf/header/PH2"
import PLegend from "utils/pdf/image/PLegend"
import PPage from "utils/pdf/PPage"
import SmallText from "utils/pdf/SmallText"
import PCell from "utils/pdf/table/PCell"
import PRow from "utils/pdf/table/PRow"
import PTable from "utils/pdf/table/PTable"

const View = RView as any

const DaybookCrisisOrga = ({ crisisOrgaWithOrder, humans, indexInSummary }) => {
	const { cellules, order, substitutions, absents } = crisisOrgaWithOrder
	const ordonnedOrga = order.map((celluleId) => cellules[celluleId])

	const categoryLegend = [
		{
			desc: "Décisionnelles",
			color: "#c34720",
		},
		{
			desc: "Opérationnelles",
			color: "#1879ce",
		},
		{
			desc: "Support",
			color: "#008000",
		},
	]
	const getCategoryColor = (category) =>
		category === "Décisionnelles"
			? "#c34720"
			: category === "Opérationnelles"
			? "#1879ce"
			: category === "Support"
			? "#008000"
			: "gray"
	const getCategorySoftColor = (category) =>
		category === "Décisionnelles"
			? "#f5b7b1"
			: category === "Opérationnelles"
			? "#aed6f1"
			: category === "Support"
			? "#a9dfbf"
			: "lightgray"

	const buildTableSubHeader = (humanColName, rowBgColor: string) => (
		<PRow>
			<PCell
				minHeight={0}
				width={120}
				head
				style={{ backgroundColor: rowBgColor }}
			>
				<SmallText
					style={{
						textAlign: "center",
						color: "black",
					}}
				>
					{humanColName}
				</SmallText>
			</PCell>
			<PCell minHeight={0} head style={{ backgroundColor: rowBgColor }}>
				<SmallText
					style={{
						textAlign: "center",
						color: "black",
					}}
				>
					Téléphones
				</SmallText>
			</PCell>
			<PCell minHeight={0} head style={{ backgroundColor: rowBgColor }}>
				<SmallText
					style={{
						textAlign: "center",
						color: "black",
					}}
				>
					Email
				</SmallText>
			</PCell>
		</PRow>
	)

	const displayHumanPhones = (human) =>
		(human?.Téléphones ?? []).map((phone) => (
			<SmallText>
				• {phone.Type} : {phone.Numéro}
			</SmallText>
		))

	const buildTableSubContent = (humanIds) =>
		humanIds.map((humanId, index) => {
			const human = humans[humanId]?.geojson?.properties
			if (!human) return

			return (
				<PRow key={index} style={{ height: "auto" }}>
					<PCell minHeight={0} width={120}>
						<SmallText>{human.Prénom + " " + human.Nom}</SmallText>
					</PCell>
					<PCell minHeight={0}>{displayHumanPhones(human)}</PCell>
					<PCell minHeight={0}>
						<SmallText>{human.Email}</SmallText>
					</PCell>
				</PRow>
			)
		})

	const filterActiveHumans = (humansIds): string[] => {
		if (!humansIds?.length) return []

		return humansIds
			.map((humanId) => substitutions?.[humanId] ?? humanId) //* replacing inactive humans ids by this one of his substituer
			.filter((humanId) => !absents.includes(humanId)) //* keeping non absents humans
	}

	return (
		<PPage>
			<HeaderBanner>
				<PH2 marginBottom="0px">{indexInSummary}. Organigramme de crise</PH2>
			</HeaderBanner>
			<PLegend legends={categoryLegend} rowDisplay />
			{ordonnedOrga.map((element: any) => {
				const { responsableIds, suppleantIds, membresIds } = element
				const activeResponsableIds = filterActiveHumans(responsableIds)
				const activeSuppleantIds = filterActiveHumans(suppleantIds)
				const activeMemberIds = filterActiveHumans(membresIds)
				const tableTitleHeaderBgColor = getCategoryColor(element.category)
				const tableSubHeaderBgColor = getCategorySoftColor(element.category)

				if (
					!activeResponsableIds.length &&
					!activeSuppleantIds.length &&
					!activeMemberIds.length
				) {
					return
				}

				return (
					<View key={element.id} $wrap={false}>
						<PTable style={{ borderColor: "black" }}>
							<PRow>
								<PCell
									minHeight={0}
									head
									style={{
										backgroundColor: tableTitleHeaderBgColor,
									}}
								>
									<SmallText style={{ textAlign: "center" }}>
										{element["idName"]}
									</SmallText>
								</PCell>
							</PRow>
							{activeResponsableIds.length && (
								<>
									{buildTableSubHeader("Responsables", tableSubHeaderBgColor)}
									{buildTableSubContent(activeResponsableIds)}
								</>
							)}
							{activeSuppleantIds.length && (
								<>
									{buildTableSubHeader("Suppléants", tableSubHeaderBgColor)}
									{buildTableSubContent(activeSuppleantIds)}
								</>
							)}
							{activeMemberIds.length && (
								<>
									{buildTableSubHeader("Membres", tableSubHeaderBgColor)}
									{buildTableSubContent(activeMemberIds)}
								</>
							)}
						</PTable>
					</View>
				)
			})}
		</PPage>
	)
}
export default DaybookCrisisOrga
