import React, { useState, useEffect } from "react"
import styled, { css } from "styled-components"
import { Divider } from "@mui/material"
import Axios from "axios"
import ErrorService from "@/services/ErrorService"
import { isDesktop, isMobile } from "react-device-detect"
import TitleHeader from "utils/components/TitleHeader"
import { useDispatch } from "react-redux"
import moment from "moment"
import ReactHtmlParser from "react-html-parser"
import _ from "lodash"

const Container = styled.div`
    margin: 1em;
    padding: 1em 2em;
    background-color: #607d8b0f;
    border-radius: 10px;
`
const ScrollBar = styled.div`
    ${
			isDesktop &&
			css`
        height: inherit;
        overflow: auto;
    `
		};
`
const DisplayContent = styled.div`
    margin: 10px;
`
const DateLabel = styled.div`
    font-size: 18px;
    font-weight: 600;
    color: var(--primary500);
    display: flex;
`
const Desc = styled.div`
    font-size: 15px;
    font-weight: 500;
`

const UpdateCard = () => {
	const [content, setContent] = useState([])
	const dispatch = useDispatch()

	const fetchMajs = async () => {
		try {
			const response = await Axios.get("/api/maj")
			const majs = _.orderBy(response.data, ["datetime"], ["desc"])
			setContent(majs)
		} catch (error) {
			const errorMessage = `Echec de l'actualisation des données : ${error}`
			ErrorService.error({
				component: "UpdateCard:onSubmit",
				message: errorMessage,
				dispatch,
			})
		}
	}

	useEffect(() => {
		fetchMajs()
	}, [])

	return (
		<Container>
			<TitleHeader title="Dernières mises à jour" />
			<ScrollBar>
				{content.map((contentObject, index) => {
					let lastDivider = false
					if (index === content.length - 1) {
						lastDivider = true
					}
					return (
						<div key={contentObject.id}>
							<DisplayContent>
								<DateLabel>
									{moment(contentObject.datetime).format("DD/MM/YYYY HH:mm")}
								</DateLabel>
								<Desc>{ReactHtmlParser(contentObject.description)}</Desc>
							</DisplayContent>
							{!lastDivider && <Divider />}
						</div>
					)
				})}
			</ScrollBar>
		</Container>
	)
}

export default UpdateCard
