import useIsInterco from "@/hooks/useIsInterco"
import { Flex } from "@/utils/components/style/flex"
import BoardWidget from "@/utils/components/widget/BoardWidget"
import intercoNameAdapter from "@/utils/intercoNameAdapter"
import MoreVertIcon from "@mui/icons-material/MoreVert"
import TimelineIcon from "@mui/icons-material/Timeline"
import { CardContent, IconButton, Menu, MenuItem } from "@mui/material"
import Moment from "moment"
import React, { useMemo, useState } from "react"
import {
	Area,
	AreaChart,
	ResponsiveContainer,
	Tooltip,
	XAxis,
	YAxis,
} from "recharts"
import styled from "styled-components"

const STimelineIcon = styled(TimelineIcon)`
    color: white;
` as any

const StyledResponsiveContainer = styled(ResponsiveContainer)`
    height: 200px !important;

    @media screen and (max-width: 1200px) {
        width: calc(85vw - var(--width-navbar)) !important;
    }
`
const StyledContent = styled(CardContent)`
    display: flex;
    justify-content: space-between;
    padding: 0px !important;
`

const ToolTipContainer = styled.div`
    background: rgba(255, 255, 255, 0.9); /* Increased opacity for better readability */
    backdrop-filter: blur(10px); /* Reduced blur for cleaner appearance */
    border-radius: 10px; /* Softened border radius for a modern look */
    border: 1px solid rgba(255, 255, 255, 0.3); /* Adjusted border to be less obtrusive */
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.1); /* Softened shadow for subtle depth */
    padding: 10px; /* Adjusted padding for compactness */
    display: flex;
    flex-direction: column;
    align-items: flex-start; /* Align to start for natural reading flow */
    justify-content: center;
    gap: 5px; /* Reduced gap for compactness */
    color: var(--primary500);
    width: auto; /* Ensure width is auto-adjusted to content */
    max-width: 90%; /* Max width to avoid overly wide tooltips */
	transition: all 0.3s ease;
	@keyframes blurToNormal {
		from {
			filter: blur(2px);
		}
		to {
			filter: blur(0px);
		}
	}

	animation: blurToNormal 0.3s ease;
			


    .tooltip-time, .tooltip-value, .tooltip-description {
        margin: 0;
        padding: 2px 5px; /* Padding for better touch targets */
        border-radius: 5px; /* Rounded corners for a friendly touch */
    }

    .tooltip-time {
        font-size: 14px; /* Adjusted for harmony between elements */
        font-weight: 500;
        color: var(--primary500);
    }

    .tooltip-value {
        font-size: 20px; /* Optimal size for emphasis without overwhelming */
        font-weight: bold;
        color: var(--primary);
    }

    .tooltip-description {
        font-size: 12px; /* Fine-tuned for auxiliary information */
        color: var(--primary600);
        font-weight: normal;
    }
`

const ALL_DATAS = "Général"
const COMMUNALS_DATAS = "Données Communales"
const ORGANIGRAM_DATAS = "Organigramme"
const ACTIONS_DATAS = "Fiches Actions"
const FICHES_DATAS = "Fiches"

const WelcomeChart = ({ allDatas, communalDatas, fichesArray }) => {
	const [isOpen, setIsOpen] = useState(null)
	const [currentChart, setCurrentChart] = useState(ALL_DATAS)
	const [currentDisplay, setCurrentDisplay] = useState(allDatas)

	const chartData = useMemo(() => {
		const results = {
			[Moment().subtract(0, "months").format("MMMM")]: 0,
			[Moment().subtract(1, "months").format("MMMM")]: 0,
			[Moment().subtract(2, "months").format("MMMM")]: 0,
			[Moment().subtract(3, "months").format("MMMM")]: 0,
			[Moment().subtract(4, "months").format("MMMM")]: 0,
			[Moment().subtract(5, "months").format("MMMM")]: 0,
			[Moment().subtract(6, "months").format("MMMM")]: 0,
			[Moment().subtract(7, "months").format("MMMM")]: 0,
			[Moment().subtract(8, "months").format("MMMM")]: 0,
			[Moment().subtract(9, "months").format("MMMM")]: 0,
			[Moment().subtract(10, "months").format("MMMM")]: 0,
			[Moment().subtract(11, "months").format("MMMM")]: 0,
		}

		currentDisplay.forEach((element) => {
			const monthDiff = Moment().diff(Moment(element.updated_at), "months")
			if (
				results[Moment().subtract(monthDiff, "months").format("MMMM")] ===
				undefined
			) {
				return
			}
			results[Moment().subtract(monthDiff, "months").format("MMMM")] =
				results[Moment().subtract(monthDiff, "months").format("MMMM")] + 1
		})
		return Object.entries(results)
			.map(([key, value]) => ({
				time: key,
				value,
			}))
			.reverse()
	}, [currentDisplay])

	const openMenu = (event) => {
		setIsOpen(event.currentTarget)
	}

	const changeChart = (chartSelected, displayType) => {
		setCurrentChart(chartSelected)
		setCurrentDisplay(displayType)
		setIsOpen(null)
	}

	const handleClose = () => {
		setIsOpen(null)
	}

	const isInterco = useIsInterco()

	return (
		<BoardWidget
			responsiveHeight
			icon={<STimelineIcon fontSize="large" />}
			title={
				<Flex>
					<div>{`Mise à jour des données : ${currentChart}`} </div>
					<IconButton style={{ padding: 5 }} onClick={openMenu}>
						<MoreVertIcon />
					</IconButton>
				</Flex>
			}
			colorLight="var(--purple500)"
			colorDark="var(--purple700)"
		>
			<>
				<StyledContent>
					<Menu
						id="chart-menu"
						keepMounted
						open={Boolean(isOpen)}
						anchorEl={isOpen}
						onClose={handleClose}
					>
						<MenuItem
							onClick={() => {
								changeChart(ALL_DATAS, allDatas)
							}}
						>
							Général
						</MenuItem>
						<MenuItem
							onClick={() => {
								changeChart(COMMUNALS_DATAS, communalDatas)
							}}
						>
							{intercoNameAdapter("Données communales", isInterco)}
						</MenuItem>
						<MenuItem
							onClick={() => {
								changeChart(ORGANIGRAM_DATAS, [])
							}}
						>
							Organigramme
						</MenuItem>
						<MenuItem
							onClick={() => {
								changeChart(FICHES_DATAS, fichesArray)
							}}
						>
							Fiches
						</MenuItem>
					</Menu>
				</StyledContent>
				<CardContent>
					<StyledResponsiveContainer>
						<AreaChart
							data={chartData}
							margin={{
								top: 10,
								right: 30,
								left: 0,
								bottom: 0,
							}}
						>
							<XAxis dataKey="time" />
							<YAxis dataKey="value" />
							<Tooltip
								content={({ active, payload, label }) => {
									if (active && payload && payload.length) {
										return (
											<ToolTipContainer>
												<p className="tooltip-time">{`${label}`}</p>
												<p className="tooltip-value">
													{`${payload[0].value}`}{" "}
													<div className="text-xs text-gray-500">donnée(s)</div>
												</p>
											</ToolTipContainer>
										)
									}

									return null
								}}
							/>
							<Area
								type="monotone"
								dataKey="value"
								stroke="#8884d8"
								fill="#8884d8"
							/>
						</AreaChart>
					</StyledResponsiveContainer>
				</CardContent>
			</>
		</BoardWidget>
	)
}
export default WelcomeChart
