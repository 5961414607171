import useSelectedEvent from "@/hooks/useSelectedEvent"
import { updatePointWithUpdateEvent } from "@/redux-toolkit/data/points/resources"
import Debug from "debug"
import _ from "lodash"
import Moment from "moment"
import React from "react"
import { useFormContext } from "react-hook-form"
import { useDispatch } from "react-redux"
import {
	PrimaryButton,
	PrimaryOutlinedButton,
} from "@/utils/components/style/button"
import { Flex } from "@/utils/components/style/flex"
import { DaybookAlertType } from "@/utils/types/daybook/IDaybookAlert"
import DaybookAlertRightContainer from "../../../../components/DaybookAlertRightContainer"
import DaybookAlertRightContentSMSVocalSection from "./DaybookAlertRightContentSMSVocalSection"
import useCommune from "@/hooks/useCommune"
import { useCampaignCost } from "../CampaignCostCard/CampaignCostCard.hook"
import { H5 } from "@/utils/components/style/header"
import styled from "styled-components"
import Button from "@/styles/atoms/Button"

// app:javascript:components:maincourante:alert:alertCreation:DaybookAlertPrepareMessage.tsx
const debug = Debug(
	"app:javascript:components:maincourante:alert:alertCreation:DaybookAlertPrepareMessage",
)
debug.log = console.log.bind(console)

const FlexContainer = styled(Flex)`
	padding-top: 0.5rem;
	overflow-y: auto;
`

const DaybookAlertPrepareMessage = () => {
	const { handleSubmit, setValue, watch } = useFormContext()
	const commune = useCommune()
	const { cost } = useCampaignCost()
	const freeAccount = commune?.free_credits === 0
	const disabled =
		cost["Nombre de crédits gratuits restants"] <
			cost["Cout de la campagne en crédits"] && freeAccount
	const selectedEvent = useSelectedEvent()
	const dispatch = useDispatch()
	const isLocked = selectedEvent?.locked
	const isTableMethod = watch("geojson.properties.method") === "table"
	const listeDiffusions = watch("geojson.properties.listeDiffusions")

	const onSubmit = (data: any) => {
		const properties = data.geojson.properties
		let modifiedData = { ...data }
		if (properties.method !== "cartography") {
			// Removing useless Yup's keys
			const {
				// (Use to restore correctly the selection on table method when back to previous step)
				listeDiffusionsPersistante,
				// (Prevent of editing an existing alert created using cartography method by table method)
				selectedMultiPolygonId,
				rangeValue,
				keepUngeolocDatas,
				...rest
			} = data.geojson.properties
			modifiedData.geojson.properties = rest
		}

		_.set(modifiedData, "geojson.properties.status", DaybookAlertType.READY)
		dispatch(
			updatePointWithUpdateEvent({
				point: modifiedData,
				event: {
					...selectedEvent,
					updated_at: Moment().format(),
				},
			}),
		)
	}

	return (
		<DaybookAlertRightContainer directionColumn gap="1rem">
			<Flex fullWidth>
				<H5>Message d'alerte</H5>
			</Flex>
			<FlexContainer fullWidth directionColumn gap="1rem">
				<DaybookAlertRightContentSMSVocalSection disabled={isLocked} />
			</FlexContainer>
			<Flex fullWidth directionReverse gap="1rem">
				<Button
					color="primary"
					onClick={handleSubmit(onSubmit)}
					disabled={disabled || isLocked}
				>
					Passer à la synthèse
				</Button>
				<Button
					color="primary"
					variant="bordered"
					onClick={() => {
						setValue(
							"geojson.properties.status",
							DaybookAlertType.PREPARE_DIFFUSE,
						)
						if (isTableMethod)
							setValue(
								"geojson.properties.listeDiffusionsPersistante",
								listeDiffusions,
							)
					}}
					disabled={isLocked}
				>
					Précédent
				</Button>
			</Flex>
		</DaybookAlertRightContainer>
	)
}

export default DaybookAlertPrepareMessage
