import React, { useState, useEffect, useMemo, useRef } from "react"
import { useDispatch, useSelector } from "react-redux"
import Debug from "debug"
import styled, { css } from "styled-components"
import useJsonSchemasWithStatics from "@/hooks/useJsonSchemasWithStatics"
import JsonSchemaService from "@/pages/carto2/cartographie/service/JsonSchemaService"
import { POINT } from "./ModalSearch"
import { ListItem } from "@mui/material"
import getImage from "@/utils/getImage"
import usePoints from "@/hooks/usePoints"
import { Flex } from "utils/components/style/flex"
import { Subtitle } from "utils/components/style/text"
import { setCurrentPoint } from "@/redux-toolkit/edit/reducer"

// app:javascript:components:app:search:SearchCardResult.tsx
const debug = Debug("app:javascript:components:app:search:SearchCardResult")
debug.log = console.log.bind(console)

const SearchCardResult = ({ item, setSearchValue, onClose }) => {
	const points = usePoints()

	const dispatch = useDispatch()
	const jsonSchemasWithStatics = useJsonSchemasWithStatics()
	const point = points[item.id]
	if (point.jsonschema_id === null) {
		return null
	}
	if (
		point.jsonschema_id === "INTERVENTION" ||
		point.jsonschema_id === "INCIDENT"
	) {
		return null
	}
	const jsonSchema = jsonSchemasWithStatics[point.jsonschema_id]
	const identifiants = JsonSchemaService.getIdentifiants(point, jsonSchema)
	if (item.type === POINT) {
		return (
			<ListItem
				button
				onClick={() => {
					setSearchValue("")
					dispatch(setCurrentPoint(point))
					onClose()
				}}
			>
				<Flex fullWidth spaceBetween>
					<Flex gap="1rem">
						<img src={getImage(jsonSchema?.imgId)} width={35} height={35} />

						<Flex directionColumn alignItemsInitial>
							{Object.values(identifiants).join(" ")}
							<Subtitle>{jsonSchema?.title}</Subtitle>
						</Flex>
					</Flex>
					<ul>
						{item.results.map((itemResult, index) => (
							<li key={index}>
								<Subtitle>{itemResult}</Subtitle>
							</li>
						))}
					</ul>
				</Flex>
			</ListItem>
		)
	}
	return null
}

export default SearchCardResult
