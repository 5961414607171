import React from "react"
import { StaticFormType } from "@/utils/services/StaticFormsService"
import IPoint from "../IPoint"
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined"
import FlagOutlinedIcon from "@mui/icons-material/FlagOutlined"
import AndroidOutlinedIcon from "@mui/icons-material/AndroidOutlined"
import InstagramIcon from "@mui/icons-material/Instagram"
import LaptopChromebookIcon from "@mui/icons-material/LaptopChromebook"
import TvOutlinedIcon from "@mui/icons-material/TvOutlined"
import BookOutlinedIcon from "@mui/icons-material/BookOutlined"
import MoreOutlinedIcon from "@mui/icons-material/MoreOutlined"
import CampaignIcon from "@mui/icons-material/Campaign"
import styled from "styled-components"
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive"
import SmsOutlinedIcon from "@mui/icons-material/SmsOutlined"
import GraphicEqOutlinedIcon from "@mui/icons-material/GraphicEqOutlined"
import CellTowerIcon from "@mui/icons-material/CellTower"
const SNotificationsActiveIcon = styled(NotificationsActiveIcon)`
	font-size: 2rem !important;
	color: var(--purple700);
`
const SSmsOutlinedIcon = styled(SmsOutlinedIcon)`
    font-size: 2rem !important;
    color: var(--primary500);
`
const SGraphicEqOutlinedIcon = styled(GraphicEqOutlinedIcon)`
    font-size: 2rem !important;
    color: var(--red500);
`

const SEmaiOutlinedIcon = styled(EmailOutlinedIcon)`
	font-size: 2rem !important;
	color: var(--orange500);
`

const SSirenOutlinedIcon = styled(CampaignIcon)`
	font-size: 2rem !important;
	color: var(--red500);
`

const SFlagOutlinedIcon = styled(FlagOutlinedIcon)`
	font-size: 2rem !important;
	color: var(--cyan500);
`
const SAndroidOutlinedIcon = styled(AndroidOutlinedIcon)`
	font-size: 2rem !important;
	color: var(--blue500);
`
const SInstagramOutlinedIcon = styled(InstagramIcon)`
	font-size: 2rem !important;
	color: var(--pink500);
`
const SChromeOutlinedIcon = styled(LaptopChromebookIcon)`
	font-size: 2rem !important;
	color: var(--yellow800);
`
const STvOutlinedIcon = styled(TvOutlinedIcon)`
	font-size: 2rem !important;
	color: var(--indigo500);
`
const SBookOutlinedIcon = styled(BookOutlinedIcon)`
	font-size: 2rem !important;
	color: var(--lime500);
`
const SMoreOutlinedIcon = styled(MoreOutlinedIcon)`
	font-size: 2rem !important;
	color: var(--brown);
`
const SCellTowerIcon = styled(CellTowerIcon)`
	font-size: 2rem !important;
	color: var(--red700);
`
export enum DaybookAlertType {
	PREPARE = "En préparation",
	PREPARE_DIFFUSE = "En préparation liste diffusion",
	PREPARE_MESSAGE = "En préparation message",
	SENDING = "En cours d'envoi",
	FINISHED = "Terminé",
	ERROR = "Erreur",
	READY = "Prêt à envoyer",
	ARCHIVED = "Archivé",
}
export enum DaybookAlertCanal {
	SMS = "SMS",
	VOCALE = "Messagerie vocale",
	EMAIL = "Email",
	SIRENE = "Sirène",
	APPLI = "Application mobile",
	HAUT_PARLEUR = "Équipement Mobile",
	PANNEAU = "Panneau lumineux",
	RESAUX_SOCIAUX = "Réseaux sociaux",
	SITE_INTERNET = "Site internet",
	MEDIAS = "Médias",
	AUTRE = "Autre",
	FR_ALERT = "FR-Alert",
}
export const risksIcons = [
	{
		icon: <SSmsOutlinedIcon />,
		value: DaybookAlertCanal.SMS,
	},
	{
		icon: <SGraphicEqOutlinedIcon />,
		value: DaybookAlertCanal.VOCALE,
	},
	{
		value: DaybookAlertCanal.EMAIL,
		icon: <SEmaiOutlinedIcon />,
	},
	{
		value: DaybookAlertCanal.SIRENE,
		icon: <SNotificationsActiveIcon />,
	},
	{
		value: DaybookAlertCanal.HAUT_PARLEUR,
		icon: <SSirenOutlinedIcon />,
	},
	{
		value: DaybookAlertCanal.PANNEAU,
		icon: <SFlagOutlinedIcon />,
	},
	{
		value: DaybookAlertCanal.APPLI,
		icon: <SAndroidOutlinedIcon />,
	},
	{
		value: DaybookAlertCanal.RESAUX_SOCIAUX,
		icon: <SInstagramOutlinedIcon />,
	},
	{
		value: DaybookAlertCanal.SITE_INTERNET,
		icon: <SChromeOutlinedIcon />,
	},
	{
		value: DaybookAlertCanal.MEDIAS,
		icon: <STvOutlinedIcon />,
	},
	{
		value: DaybookAlertCanal.FR_ALERT,
		icon: <SCellTowerIcon />,
	},
	{
		value: DaybookAlertCanal.AUTRE,
		icon: <SMoreOutlinedIcon />,
	},
]
export const rankDaybookAlertType = (type: DaybookAlertType): number => {
	switch (type) {
		case DaybookAlertType.PREPARE_DIFFUSE:
			return 0
		case DaybookAlertType.PREPARE_MESSAGE:
			return 1
		case DaybookAlertType.READY:
			return 2
		case DaybookAlertType.SENDING:
			return 3
		case DaybookAlertType.FINISHED:
			return 4
		case DaybookAlertType.ERROR:
			return 5
		case DaybookAlertType.ARCHIVED:
			return 6
	}
}
export type StatusResponse =
	| "0"
	| "queued"
	| "sended"
	| "200"
	| "2000"
	| "3120"
	| "3920"
	| "F121"
	| "F921"
	| "7122"
	| "7922"
	| "B123"
	| "B823"
	| "F124"
	| "F125"
	| "F825"
	| "7126"
	| "7826"
	| "3127"
	| "3D27"
	| "F128"
	| "7129"
	| "3130"
	| "F830"
	| "3930"
	| "3331"
	| "3132"
	| "3932"
	| "3133"
	| "3833"
	| "3933"
	| "3135"
	| "3835"
	| "3336"
	| "3138"
	| "3838"
	| "313B"
	| "313C"
	| "B13D"
	| "B33D"
	| "B83D"
	| "B33F"
	| "NO_PHONE"
	| "NO_MOBILE_PHONE"
	| "NO_VALID_PHONE"

interface IDaybookAlert extends IPoint {
	geojson: {
		properties: {
			name: string
			status: DaybookAlertType
			type: DaybookAlertCanal
			listeDiffusions: string[]
			listeDiffusionIds?: string[]
			smsText?: string
			voiceContent?: string
			sendingDate?: string
			citycId?: string
			includeFixedPhones?: boolean
			numberOfPhones?: number
			responses?: {
				[key: string]: {
					status: StatusResponse
					citycId: string
					received: {
						numero: string
						reponse: string
						date: string
					}[]
					sended: {
						reponse: string
						citycId: string
						date: string
					}[]
				}
			}
			usedService: "CITYCALERT" | "TWILIO"
		}
	}
	jsonschema_id: StaticFormType.ALERTE
}

export default IDaybookAlert
