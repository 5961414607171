import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import { AccordionSummary } from "@mui/material"
import Action from "@/pages/customdata/fiches/Action"
import {
	ActionBorder,
	SAccordion,
	SAccordionDetails,
	TitleWrapper,
} from "@/pages/customdata/fiches/Fiche"
import moment from "moment"
import React, { useState } from "react"
import { Flex } from "utils/components/style/flex"
import IEvent from "utils/types/IEvent"
import IFiche, { IActionCategory, Vigilance } from "utils/types/IFiche"
import { NO_FILTER } from "../following/filter/useFilterForm"
import { useDispatch } from "react-redux"
import { updateEvent } from "@/redux-toolkit/data/events/resources"
import useAccessRights from "@/hooks/useAccessRights"
import { PATH_MAIN_COURANTE_FICHE } from "@/redux-toolkit/userSettings/constants"
import _ from "lodash"
import { EnumToolPath } from "@/hooks/services/useAccessRightsServices"

const CategoryCheck = ({
	category,
	currentFiche,
	selectedEvent,
	filterVigilance,
	authorizedCells,
	expanded,
	setExpanded,
}: {
	currentFiche: IFiche
	category: IActionCategory
	selectedEvent: IEvent
	filterVigilance: string
	authorizedCells: string[]
	expanded: string[]
	setExpanded: (expanded: string[]) => void
}) => {
	const dispatch = useDispatch()
	const scenarioActionIds = selectedEvent?.scenarioActionIds ?? []
	const [lock, setLock] = useState(false)
	const permissions = useAccessRights(EnumToolPath.FICHE)

	const handleCheck = async ({ checked, actionId }) => {
		setLock(true)
		let newEvent: IEvent
		if (checked) {
			newEvent = {
				...selectedEvent,
				scenarioActionIds: [
					...scenarioActionIds,
					{
						actionId,
						date: moment(moment.now()).format("DD/MM/YYYY HH:MM"),
					},
				],
			}
		} else {
			newEvent = {
				...selectedEvent,
				scenarioActionIds: scenarioActionIds.filter(
					(action) => action.actionId !== actionId,
				),
			}
		}
		await dispatch(updateEvent(newEvent))
		setLock(false)
	}

	const vigilanceBelow = (vigilance1: Vigilance, vigilance2: Vigilance) =>
		Object.values(Vigilance).indexOf(vigilance1) >
		Object.values(Vigilance).indexOf(vigilance2)

	const concernedActions = category.actionIds.filter((actionId) => {
		return (
			(filterVigilance === NO_FILTER ||
				!vigilanceBelow(
					currentFiche.actions[actionId].Vigilance,
					selectedEvent?.vigilance,
				)) &&
			(authorizedCells.length === 0 ||
				(currentFiche.actions[actionId]?.Cellules ?? []).find((cell) =>
					authorizedCells.includes(cell),
				))
		)
	})

	if (concernedActions.length === 0) {
		return null
	}

	return (
		<SAccordion
			TransitionProps={{ unmountOnExit: true }}
			expanded={expanded.includes(category.categoryName)}
			onChange={() => {
				if (!expanded.includes(category.categoryName)) {
					setExpanded([...expanded, category.categoryName])
				} else {
					setExpanded(expanded.filter((name) => name !== category.categoryName))
				}
			}}
		>
			<AccordionSummary expandIcon={<ExpandMoreIcon />}>
				<TitleWrapper>
					<Flex flex={1}>{category.categoryName}</Flex>
					<div>
						{
							concernedActions.filter((actionId) =>
								scenarioActionIds
									.map((action) => action?.actionId)
									.includes(actionId),
							).length
						}{" "}
						/ {concernedActions.length}{" "}
					</div>
				</TitleWrapper>
			</AccordionSummary>
			<SAccordionDetails>
				{concernedActions.map((actionId) => (
					<ActionBorder $last={false} key={actionId}>
						<Action
							checkDisabled={
								lock || !permissions.write || selectedEvent?.locked
							}
							withCrisisOrga={true}
							action={currentFiche.actions[actionId]}
							editable={false}
							checkable={{
								checked: (scenarioActionIds ?? [])
									.map((action) => {
										return action?.actionId
									})
									.includes(actionId),
								onChange: (checked) =>
									handleCheck({
										checked,
										actionId,
									}),
							}}
						/>
					</ActionBorder>
				))}
			</SAccordionDetails>
		</SAccordion>
	)
}

export default CategoryCheck
