import React, { useEffect, useMemo, useState } from "react"
import { Flex } from "@/utils/components/style/flex"
import styled from "styled-components"
import { Card } from "@/utils/components/style/card"
import { IconButton, InputAdornment, TextField } from "@mui/material"
import SearchIcon from "@mui/icons-material/Search"
import ClearIcon from "@mui/icons-material/Clear"
import useAccessRights from "@/hooks/useAccessRights"
import { EnumToolPath } from "@/hooks/services/useAccessRightsServices"
import Button from "@/styles/atoms/Button"
import useSelectedEvent from "@/hooks/useSelectedEvent"
import { useBoolean } from "react-use"
import usePoints from "@/hooks/usePoints"
import IPoint from "@/utils/types/IPoint"
import CreateButton from "@/styles/atoms/Button/specialized/CreateButton"
import CancelButton from "@/styles/atoms/Button/specialized/CancelButton"
import Modal from "@/styles/organisms/Modal"
import { ModalContent } from "@nextui-org/react"
import ModalHeader from "@/styles/organisms/Modal/ModalHeader"
import ModalBody from "@/styles/organisms/Modal/ModalBody"
import ModalFooter from "@/styles/organisms/Modal/ModalFooter"
import { FormProvider, useForm } from "react-hook-form"
import Yup from "@/utils/yup"
import { yupResolver } from "@hookform/resolvers/yup"
import { useDispatch } from "react-redux"
import {
	createPointWithUpdateEvent,
	deletePointWithUpdateEvent,
	updatePoint,
	updatePointWithUpdateEvent,
} from "@/redux-toolkit/data/points/resources"
import FText from "@/utils/form/FText"
import FSelectHumans from "@/utils/form/FSelectHumans"
import SaveButton from "@/styles/atoms/Button/specialized/SaveButton"
import { DISPOSITIF, SUIVI_GROUP } from "@/redux-toolkit/userSettings/constants"
import moment from "moment"
import ConfirmationModal from "@/utils/components/ConfirmationModal"
import { toast } from "react-toastify"
import _ from "lodash"
import useJsonSchemasWithStatics from "@/hooks/useJsonSchemasWithStatics"
import useMultiPolygons from "@/hooks/useMultiPolygons"
import FDateTime from "@/utils/form/FDateTime"
import FacilityCard from "./FacilityCard"

const SCard = styled(Card)`
    padding: .5rem;
    width: 100%;
`
const STextField = styled(TextField)`
    width: -webkit-fill-available !important;
`

const schema = Yup.object().shape({
	geojson: Yup.object().shape({
		properties: Yup.object().shape({
			name: Yup.string().required(),
			responsables: Yup.array(Yup.string()).default([]),
			suiviJsonSchemaActivated: Yup.array().default([]),
			start_at: Yup.string()
				.default(() => moment().format())
				.required(),
		}),
	}),
}) as any

const SuiviCards = () => {
	const [currentSearch, setCurrentSearch] = useState("")
	const [selectedFacility, setSelectedFacility] = useState(undefined)
	const [isOpen, setIsOpen] = useBoolean(false)
	const [confirmationModalState, setConfirmationModalState] = useState({
		isShown: false,
		message: "",
		onConfirm: () => null,
	})
	const permissions = useAccessRights(EnumToolPath.SUIVI_PCS)
	const selectedEvent = useSelectedEvent()
	const isLocked = selectedEvent?.locked ?? false
	const points = usePoints()
	const dispatch = useDispatch()
	// const jsonSchemas = useJsonSchemasWithStatics()
	// const multiPolygons = useMultiPolygons()

	const toggleConfirmationModal = () =>
		setConfirmationModalState({
			isShown: false,
			message: "",
			onConfirm: () => null,
		})

	const methods = useForm({
		defaultValues: {
			geojson: {
				properties: {
					name: "",
					responsables: [],
					start_at: moment().format(),
				},
			},
		},
		resolver: yupResolver(schema),
	})

	const onSubmit = async (values) => {
		if (selectedFacility) {
			dispatch(
				updatePointWithUpdateEvent({
					point: values,
					event: {
						...selectedEvent,
						history: [
							...selectedEvent?.history,
							{
								type: DISPOSITIF,
								subTitle: "Édition d'un dispositif",
								title: values.geojson.properties.name,
								date: moment().format(),
							},
						],
					},
				}),
			)
			setSelectedFacility(undefined)
		} else {
			dispatch(
				createPointWithUpdateEvent({
					point: {
						...values,
						event_id: selectedEvent?.id,
						jsonschema_id: SUIVI_GROUP,
					},
					event: {
						...selectedEvent,
						history: [
							...selectedEvent?.history,
							{
								type: DISPOSITIF,
								subTitle: "Création d'un dispositif",
								title: values.geojson.properties.name,
								date: moment().format(),
							},
						],
					},
				}),
			)
		}
		setIsOpen(false)
		methods.reset(schema.getDefault())
	}

	useEffect(() => {
		if (!isOpen) {
			setSelectedFacility(undefined)
			methods.reset(schema.getDefault())
			return
		}
		if (selectedFacility) methods.reset(selectedFacility)
	}, [isOpen])

	const facilityPoints = useMemo(() => {
		const filteredPoints = Object.values(points).filter(
			(point) =>
				point.jsonschema_id == "SUIVI_GROUP" &&
				point.event_id == selectedEvent?.id,
		)
		const sorted = _.sortBy(filteredPoints, ["created_at"]).reverse()

		return sorted
	}, [points])

	// const getIdentityValue = (relatedObject) => {
	// 	const properties =
	// 		jsonSchemas?.[relatedObject?.categoryId]?.template?.properties ?? []
	// 	const identifiant = properties.filter(
	// 		(property) => property.isIdentifiant,
	// 	)?.[0]
	// 	const identifiant2 = properties.filter(
	// 		(property) => property.isIdentifiant,
	// 	)?.[1]
	// 	if (identifiant === undefined) {
	// 		return
	// 	}
	// 	const identifiantValue =
	// 		points[relatedObject?.objectId]?.geojson?.properties?.[
	// 		identifiant?.name
	// 		] ?? ""
	// 	const identifiantValue2 =
	// 		points[relatedObject?.objectId]?.geojson?.properties?.[
	// 		identifiant2?.name
	// 		] ?? ""
	// 	if (identifiantValue.secteur) {
	// 		return multiPolygons[identifiantValue.secteur]?.name
	// 	}
	// 	return `${identifiantValue} ${identifiantValue2}`
	// }

	// const backupSuivisAndGroup = (group, groupInfos) => {
	// 	const concerned_suivis = Object.values(points)
	// 		.filter((point: IPoint) => point.jsonschema_id == SUIVI_PCS)
	// 		.filter((suivi) => suivi.geojson.properties.suivi_group_id == group.id)

	// 	const backup = concerned_suivis.map((suivi) => {
	// 		const suiviPpts = suivi.geojson.properties as ISupport
	// 		return {
	// 			relatedObject: {
	// 				...points[suiviPpts.relatedObject.objectId]?.geojson?.properties,
	// 				jsonschema_name: suiviPpts.relatedObject.categoryId,
	// 				searchIdentity: getIdentityValue(suiviPpts.relatedObject),
	// 			},
	// 			responsables: suiviPpts.responsableId.map((respId) => {
	// 				const respPpts = points[respId]?.geojson?.properties
	// 				return { ...respPpts }
	// 			}),
	// 			suivi: suiviPpts.suivi.map((suivi) => {
	// 				const { responsableId, ...semented } = suivi
	// 				return {
	// 					...semented,
	// 					responsables: suiviPpts.responsableId.map((respId) => {
	// 						const respPpts = points[respId]?.geojson?.properties
	// 						return { ...respPpts }
	// 					})
	// 				}
	// 			})
	// 		}
	// 	})
	// 	dispatch(
	// 		updatePointWithUpdateEvent({
	// 			point: {
	// 				...group,
	// 				geojson: {
	// 					properties: {
	// 						...group.geojson.properties,
	// 						responsables: group.geojson.properties.responsables.map((respId) => points[respId]),
	// 						ended_at: moment().format(),
	// 						backup: backup,
	// 						groupInfos: {
	// 							responsables: groupInfos.responsables,
	// 							nbEndedSuivi: groupInfos.nbEndedSuivi,
	// 							nbUnfinishedSuivi: groupInfos.nbPendingSuivi
	// 						}
	// 					}
	// 				}
	// 			},
	// 			event: {
	// 				...selectedEvent,
	// 				updated_at: moment().format(),
	// 			},
	// 		}),
	// 	)
	// }

	return (
		<>
			<Flex directionColumn gap="1rem">
				<SCard>
					<Flex gap="1em">
						{/* <STextField
							InputProps={{
								startAdornment: (
									<InputAdornment position="start">
										<SearchIcon />
									</InputAdornment>
								),
								endAdornment: (
									<>
										{currentSearch && (
											<InputAdornment position="end">
												<IconButton
													onClick={() => {
														setCurrentSearch("")
													}}
												>
													<ClearIcon />
												</IconButton>
											</InputAdornment>
										)}
									</>
								),
							}}
							placeholder="Rechercher ....."
							value={currentSearch}
							onChange={(e) => {
								setCurrentSearch(e.target.value)
							}}
						/> */}
						{permissions.write && (
							<Flex fullWidth flexEnd>
								<Button
									color="primary"
									onClick={() => setIsOpen(true)}
									disabled={isLocked}
								>
									Créer un dispositif
								</Button>
							</Flex>
						)}
					</Flex>
				</SCard>
				{facilityPoints.map((facilityPoint: IPoint) => (
					<FacilityCard
						facilityPoint={facilityPoint}
						onEditClick={() => {
							setSelectedFacility(facilityPoint)
							setIsOpen(true)
						}}
						onDeleteClick={() => {
							setConfirmationModalState({
								isShown: true,
								message: `Voulez vous vraiment supprimer le dispositif "${facilityPoint?.geojson?.properties?.name}" ?`,
								onConfirm: () => {
									dispatch(
										deletePointWithUpdateEvent({
											point: facilityPoint,
											event: {
												...selectedEvent,
												updated_at: moment().format(),
											},
										}),
									)
									toast.info("Le dispositif à bien été supprimé")
									toggleConfirmationModal()
								},
							})
						}}
						onFinishClick={(groupInfos) => {
							// setConfirmationModalState({
							// 	isShown: true,
							// 	message: `Êtes-vous certain(e) de vouloir archiver le dispositif "${group?.geojson?.properties?.name}" ?\n
							// Cette action est irréversible et son contenu restera uniquement accessible en lecture seule.`,
							// 	onConfirm: () => {
							// 		backupSuivisAndGroup(group, groupInfos)
							// 		toast.info("Le dispositif à bien été archivé")
							// 		toggleConfirmationModal()
							// 	}
							// })
						}}
					/>
				))}
			</Flex>

			<Modal isOpen={isOpen} onClose={() => setIsOpen(false)} size="2xl">
				<ModalContent>
					<FormProvider {...methods}>
						<ModalHeader>
							{selectedFacility
								? "Édition du dispositif"
								: "Création d'un dispositif"}
						</ModalHeader>
						<ModalBody>
							<FText
								name={`geojson.properties.name`}
								label="Nom du dispositif"
							/>
							<FSelectHumans
								name={`geojson.properties.responsables`}
								label="Responsables"
							/>
							<FDateTime name={`geojson.properties.start_at`} label="Date" />
						</ModalBody>
						<ModalFooter>
							<CancelButton onClick={() => setIsOpen(false)} />
							{!selectedFacility && (
								<CreateButton onClick={methods.handleSubmit(onSubmit)} />
							)}
							{selectedFacility && (
								<SaveButton onClick={methods.handleSubmit(onSubmit)} />
							)}
						</ModalFooter>
					</FormProvider>
				</ModalContent>
			</Modal>
			<ConfirmationModal
				modalShown={confirmationModalState.isShown}
				onClose={() => toggleConfirmationModal()}
				onConfirm={confirmationModalState.onConfirm}
				message={confirmationModalState.message}
			/>
		</>
	)
}
export default SuiviCards
