import { updateCommune } from "@/redux-toolkit/commune/ressources"
import { useDispatch } from "react-redux"
import useCommune from "./useCommune"

function useOrderedAgents() {
	const dispatch = useDispatch()
	return {
		orderedAgents: useCommune().orderedAgents,
		setOrderedAgents: (order) =>
			dispatch(
				updateCommune({
					orderedAgents: order,
				}),
			),
	}
}

export default useOrderedAgents
