import { View } from "@react-pdf/renderer"
import React from "react"
import PImage from "./image/PImage"
import PLegend from "./image/PLegend"
import LargeText from "./LargeText"

const PPie = ({
	title,
	blobImage,
	alt = "Diagramme inaffichable.",
	legends = undefined,
	width = "auto",
}) => {
	return (
		<View
			style={{
				width: "45%",
				display: "flex",
				flexDirection: "column",
			}}
		>
			<LargeText>{title}</LargeText>
			<View
				style={{
					display: "flex",
					flexDirection: "row",
					alignItems: "center",
				}}
			>
				<PImage src={blobImage} alt={alt} width={width} />
				<PLegend legends={legends} />
			</View>
		</View>
	)
}

export default PPie
