import AnnouncementIcon from "@mui/icons-material/Announcement"
import GraphicEqOutlinedIcon from "@mui/icons-material/GraphicEqOutlined"
import SmsOutlinedIcon from "@mui/icons-material/SmsOutlined"
import useFetchAlertInfo from "@/hooks/api/useFetchAlertInfo"
import useJsonSchemasWithStatics from "@/hooks/useJsonSchemasWithStatics"
import usePoints from "@/hooks/usePoints"
import useSelectedEvent from "@/hooks/useSelectedEvent"
import { deletePointWithUpdateEvent } from "@/redux-toolkit/data/points/resources"
import Debug from "debug"
import Moment from "moment"
import React, { useEffect, useMemo, useState } from "react"
import { useDispatch } from "react-redux"
import { useHistory } from "react-router-dom"
import styled from "styled-components"
import {
	PrimaryButton,
	PrimaryOutlinedButton,
} from "@/utils/components/style/button"
import { Flex } from "@/utils/components/style/flex"
import SuperReactTable from "@/utils/components/tables/SuperReactTable/SuperReactTable"
import { widthCustom } from "@/utils/components/tables/widthProps"
import TitleHeader from "@/utils/components/TitleHeader"
import getImage from "@/utils/getImage"
import { StaticFormType } from "@/utils/services/StaticFormsService"
import IDaybookAlert, {
	DaybookAlertCanal,
	DaybookAlertType,
} from "@/utils/types/daybook/IDaybookAlert"
import IJsonSchema from "@/utils/types/IJsonSchema"
import ModalAlertCampaign from "./alertCreation/ModalAlertCampaign"
import DaybookAlertChip from "./components/DaybookAlertChip"
import { ColumnDef } from "@tanstack/react-table"
import { StyledChip } from "@/utils/components/style/chip"
import useAccessRights from "@/hooks/useAccessRights"
import { PATH_MAIN_COURANTE_ALERTE } from "@/redux-toolkit/userSettings/constants"
import ErrorPermissions, {
	BACK_PATH_HOME,
	BACK_TITLE_HOME,
	FEATURE_ERROR_PERMISSION,
} from "@/hooks/ErrorPermissions"
import { EnumToolPath } from "@/hooks/services/useAccessRightsServices"
import Button from "@/styles/atoms/Button"
const debug = Debug("app:javascript:components:maincourante:alert:DaybookAlert")
debug.log = console.log.bind(console)

const SSmsOutlinedIcon = styled(SmsOutlinedIcon)`
    font-size: 3rem !important;
    color: var(--primary500);
`
const SGraphicEqOutlinedIcon = styled(GraphicEqOutlinedIcon)`
    font-size: 3rem !important;
    color: var(--red500);
`

const BackgroundAlt = styled.div`
    background-color: #f9fafb;
    height: 100%;
    padding: 1.5rem;
`
const SAnnouncementIcon = styled(AnnouncementIcon)`
    color: var(--primary500) !important;
`

const DaybookAlert = () => {
	const history = useHistory()
	const points = usePoints()
	const selectedEvent = useSelectedEvent()
	const [isOpenModalAlert, setIsOpenModalAlert] = useState(false)
	const [currentAlert, setCurrentAlert] = useState<IDaybookAlert>(null)
	const { fetchAlertInfo } = useFetchAlertInfo()
	const dispatch = useDispatch()
	const jsonSchemas = useJsonSchemasWithStatics()
	const alertePoints = Object.values(points)
		.filter((point) => point.event_id === selectedEvent?.id)
		.filter(
			(point) => point.jsonschema_id === StaticFormType.ALERTE,
		) as IDaybookAlert[]
	const isLocked = selectedEvent?.locked
	const permissions = useAccessRights(EnumToolPath.ALERTE)

	const columns = useMemo(
		() =>
			[
				{
					header: "Statut",
					accessorKey: "geojson.properties.status",
					cell: ({ getValue }) => {
						return <DaybookAlertChip status={getValue()} />
					},
					...widthCustom(150),
				},
				{
					header: "Nom de la campagne",
					accessorKey: "geojson.properties.name",
				},
				{
					header: "Listes de diffusion",
					accessorFn: (props) =>
						(props.geojson.properties.listeDiffusions ?? []).map(
							(jsonSchemaId) => jsonSchemas[jsonSchemaId] ?? jsonSchemaId,
						),
					cell: ({ getValue }) => (
						<Flex gap="0.25rem" directionColumn alignItemsStart>
							{(getValue() ?? []).map((jsonSchema) => (
								<StyledChip
									variant="outlined"
									size="small"
									label={jsonSchema?.title ?? jsonSchema}
									key={jsonSchema?.id}
									{...(jsonSchema?.imgId
										? {
												avatar: (
													<img
														src={getImage(jsonSchema?.imgId)}
														alt={jsonSchema?.title}
														width="20"
														height="20"
													/>
												),
											}
										: {})}
								/>
							))}
						</Flex>
					),
					...widthCustom(300),
				},
				{
					header: "Date de création",
					accessorFn: (row: IDaybookAlert) => {
						return Moment(row.created_at).format("DD/MM/YYYY HH:mm")
					},
					...widthCustom(150),
				},
				{
					header: "Date de l'envoi",
					accessorKey: "geojson.properties.sendingDate",
					accessorFn: (row: IDaybookAlert) => {
						return Moment(row.geojson.properties.sendingDate).format(
							"DD/MM/YYYY HH:mm",
						)
					},
					...widthCustom(150),
				},
				{
					header: "Nb Destinataires",
					accessorKey: "geojson.properties.responses",
					cell: ({ row }) => {
						const alert = row.original.geojson.properties
						if (alert.type === DaybookAlertCanal.SMS) {
							return Object.values(alert.responses ?? {}).length
						} else {
							return alert.listeDiffusionIds.length
						}
					},
					...widthCustom(150),
				},
				{
					header: "Type",
					accessorKey: "geojson.properties.type",
					cell: ({
						row: { original: value },
					}: {
						row: { original: IDaybookAlert }
					}) => {
						if (value.geojson.properties.type === DaybookAlertCanal.SMS) {
							return (
								<Flex gap="0.5rem">
									<SSmsOutlinedIcon />
									SMS
								</Flex>
							)
						}
						return (
							<Flex gap="0.5rem">
								<SGraphicEqOutlinedIcon />
								Vocal
							</Flex>
						)
					},
					...widthCustom(100),
				},
			] as ColumnDef<IDaybookAlert, any>[],
		[],
	)

	useEffect(() => {
		alertePoints.forEach((alert) => {
			if (alert.geojson.properties.status === DaybookAlertType.SENDING) {
				fetchAlertInfo(alert.id)
			}
		})
	}, [])

	if (!permissions.read) {
		return (
			<ErrorPermissions
				errorMessage={FEATURE_ERROR_PERMISSION}
				backTitle={BACK_TITLE_HOME}
				backUrl={BACK_PATH_HOME}
			/>
		)
	}

	return (
		<BackgroundAlt>
			<TitleHeader title="Campagnes de télé-alertes" />
			<SuperReactTable
				actionsButtons={
					<Button
						color="primary"
						onClick={() => {
							setIsOpenModalAlert(true)
						}}
						disabled={isLocked}
					>
						Créer une campagne
					</Button>
				}
				columns={columns}
				customEmptyMessage={
					<Flex gap="1rem" directionColumn>
						<SAnnouncementIcon />
						<p>Aucune campagne en préparation. </p>
						<Button
							color="primary"
							onClick={() => {
								setIsOpenModalAlert(true)
							}}
						>
							Créer une campagne
						</Button>
					</Flex>
				}
				data={alertePoints}
				writePermission={!isLocked}
				isDeletable={!isLocked}
				onDeleteClick={(original) => {
					dispatch(
						deletePointWithUpdateEvent({
							point: original,
							event: {
								...selectedEvent,
								updated_at: Moment().format(),
							},
						}),
					)
				}}
				onEditClick={(original) => {
					history.push(`/maincourante/alerte/${original.id}`)
				}}
			/>
			<ModalAlertCampaign
				isOpen={isOpenModalAlert}
				setIsOpen={setIsOpenModalAlert}
				currentAlert={currentAlert}
			/>
		</BackgroundAlt>
	)
}

export default DaybookAlert
