import Icon from "@iconify/react"
import HomeIcon from "@mui/icons-material/Home"
import React, { useState, useEffect } from "react"
import { CardContent } from "@mui/material"
import DoneIcon from "@mui/icons-material/Done"
import ErrorIcon from "@mui/icons-material/Error"
import WarningIcon from "@mui/icons-material/Warning"
import styled from "styled-components"
import { GREEN_STATUS, ORANGE_STATUS, RED_STATUS } from "./NumeriskIndicators"
import CustomTooltip from "utils/components/style/CustomTooltip"

const StyledDoneIcon = styled(DoneIcon)`
    color: green !important;
`
const StyledErrorIcon = styled(ErrorIcon)`
    color: red !important;
`
const StyledWarningIcon = styled(WarningIcon)`
    color: orange !important;
`
const SpaceItems = styled(CardContent)`
    display: flex;
    justify-content: space-between;
`
const StyledContent = styled.div`
    display: flex;
`
const CenterIcon = styled.div`
    display: flex;
    align-items: center;
    padding-right: 8px;
`
const DataStatusIcon = styled(CenterIcon)`
    padding: 0;
`
const DataTitle = styled.div`
    font-size: 15px;
    padding: 0 10px;
`

const CommunalDataStatus = ({ status, dataTitle, imgName }) => {
	const [dataState, setDataState] = useState(undefined)

	useEffect(() => {
		let tempDataState = GREEN_STATUS
		Object.values(status).forEach((object) => {
			if (object === ORANGE_STATUS && tempDataState !== RED_STATUS) {
				tempDataState = ORANGE_STATUS
				return
			}
			if (object === RED_STATUS) {
				tempDataState === RED_STATUS
				return
			}
			return
		})
		setDataState(tempDataState)
	}, [status])

	return (
		<>
			<SpaceItems>
				<StyledContent>
					<HomeIcon />
					<DataTitle>{dataTitle}</DataTitle>
				</StyledContent>
				<DataStatusIcon>
					{dataState === GREEN_STATUS && (
						<CustomTooltip title="À jour">
							<StyledDoneIcon />
						</CustomTooltip>
					)}
					{dataState === ORANGE_STATUS && (
						<CustomTooltip title="À vérifier">
							<StyledWarningIcon />
						</CustomTooltip>
					)}
					{dataState === RED_STATUS && (
						<CustomTooltip title="Mettre à jour">
							<StyledErrorIcon />
						</CustomTooltip>
					)}
				</DataStatusIcon>
			</SpaceItems>
		</>
	)
}

export default CommunalDataStatus
