import { useMemo } from "react"
import usePoints from "../usePoints"
import formatNfdLowerCase from "utils/formatNfdLowerCase"
import {
	CARE_FAMILY,
	INCIDENT,
	INTERVENTION,
	TEMPORARY_CARE,
} from "@/redux-toolkit/userSettings/constants"
import useJsonSchemasWithStatics from "../useJsonSchemasWithStatics"

const useSearchPoints = (words: string[]) => {
	const points = usePoints()
	const jsonSchemasWithStatics = useJsonSchemasWithStatics()
	const searchedPoints = useMemo(() => {
		return Object.values(points)
			.filter(
				(point) =>
					point.jsonschema_id !== TEMPORARY_CARE &&
					point.jsonschema_id !== INCIDENT &&
					point.jsonschema_id !== INTERVENTION &&
					point.jsonschema_id !== CARE_FAMILY &&
					jsonSchemasWithStatics[point.jsonschema_id] !== undefined,
			)
			.map((point: any) => ({
				searchableContent: Object.entries(point.geojson?.properties ?? {})
					.filter(([key, value]) => value)
					.map(([key, value]) => {
						let formatedText = value as string
						if (Array.isArray(value)) {
							formatedText = value.join(", ")
						}
						if (typeof value === "object") {
							formatedText = JSON.stringify(value)
						}
						formatedText = formatNfdLowerCase(formatedText)

						return {
							text: formatedText,
							property: key,
						}
					}),
				type: "POINT",
				id: point.id,
			}))
	}, [points])

	const searchResultPoints = useMemo(() => {
		// @ts-ignore
		const matchPoints = []
		searchedPoints.forEach((point) => {
			const results = []
			const toPush = []
			const everyWordPresent = words.every((w) => {
				const wordIsPresent = point.searchableContent.some((searchContent) => {
					if (searchContent.text?.includes(w)) {
						toPush.push(`Le champ ${searchContent.property} contient ${w}`)
					}
					return searchContent.text?.includes(w)
				})
				return wordIsPresent
			})
			if (everyWordPresent) {
				results.push(...toPush)
			}

			if (results.length > 0) {
				matchPoints.push({
					...point,
					results,
				})
			}
		})
		return matchPoints
	}, [words, searchedPoints])

	return searchResultPoints
}

// Path: app/javascript/components/hooks/search/useSearchPoints.tsx
export default useSearchPoints
