import React, { useState, useEffect, useMemo, useRef } from "react"
import _ from "lodash"
import IPoint from "utils/types/IPoint"
import { Flex } from "utils/components/style/flex"
import styled from "styled-components"
import { Avatar } from "@mui/material"
import HumanService from "utils/services/HumanService"
import IPhones from "utils/types/IPhones"
import LocalPhone from "@mui/icons-material/LocalPhone"
import { StyledChip } from "utils/components/style/chip"
import { StaticFormType } from "utils/services/StaticFormsService"
import { Spacer } from "@nextui-org/react"
import { isDesktop, isMobile } from "react-device-detect"

const StyledAvatar = styled(Avatar)`
    width: 60px !important;
    height: 60px !important;
`
const Poste = styled.div`
	color: var(--neutral700);
	font-size: var(--font-size-small);
`
const RespName = styled.div`
    font-size: ${
			isMobile ? "calc(var(--font-size-base) - 2px)" : "var(--font-size-base)"
		};
    font-weight: 500;
`
const PhoneType = styled.div`
    font-size: var(--font-size-small);
    color: var(--neutral700);
`
const PhoneNumber = styled.div`
    font-size: var(--font-size-small);
    color: var(--neutral700);
`
const FlexHumanInfo = styled(Flex)`
	width: ${isMobile ? "150px" : "200px"};
`
const SFlex = styled(Flex)`
	max-height: 230px;
`
const ChipContainer = styled.div`
	width: max-content;
	min-width: 50px;
`

const DisplayBeautifulHumans = ({ human }: { human: IPoint }) => {
	if (!human) return null

	const phones = (human.geojson.properties?.Téléphones ?? []) as Array<IPhones>
	return (
		<SFlex gap="1rem">
			<Flex gap="1rem">
				<ChipContainer>
					{human.jsonschema_id === StaticFormType.ANNUAIRES_ELUS ? (
						<StyledChip
							variant="outlined"
							$colorProperty="var(--blue500)"
							label="Élu"
							size="small"
							$fitContent
						/>
					) : (
						<StyledChip
							label="Agent"
							size="small"
							variant="outlined"
							$colorProperty="var(--orange500)"
							$fitContent
						/>
					)}
				</ChipContainer>
				<FlexHumanInfo directionColumn alignItemsInitial gap="0.2rem">
					<RespName>
						{HumanService.displayFullName(human).toUpperCase()}
					</RespName>
					<Poste>{HumanService.displayPoste(human)}</Poste>
				</FlexHumanInfo>
			</Flex>
			<Flex directionColumn alignItemsInitial>
				{phones.slice(0, 3).map((phone) => (
					<Flex key={phone.Type} gap=".5rem">
						<LocalPhone fontSize="small" />
						<Flex directionColumn alignItemsInitial>
							<PhoneType>{phone.Type}</PhoneType>
							<PhoneNumber>{phone.Numéro}</PhoneNumber>
						</Flex>
					</Flex>
				))}
			</Flex>
		</SFlex>
	)
}

export default DisplayBeautifulHumans
