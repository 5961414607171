import staticFiles from "isoMorphicData/pcsStaticFiles/pcsStaticFiles.json"
import useStaticFiles from "../useStaticFiles"
import { useMemo } from "react"
import formatNfdLowerCase from "utils/formatNfdLowerCase"

const useSearchStaticFiches = (words) => {
	const staticFiles = useStaticFiles({ withUploaded: false })
	const searchStaticFiches = useMemo(() => {
		const matchStaticFiches = Object.values(staticFiles).filter(
			(staticFile) => {
				return words.every((word) => {
					const staticFileTitle = formatNfdLowerCase(staticFile.title)
					return staticFileTitle.includes(word)
				})
			},
		)
		return matchStaticFiches
	}, [words, staticFiles])

	return searchStaticFiches
}

export default useSearchStaticFiches
