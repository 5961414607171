import ErrorPermissions, {
	BACK_PATH_HOME,
	BACK_TITLE_HOME,
	FEATURE_ERROR_PERMISSION,
} from "@/hooks/ErrorPermissions"
import { EnumToolPath } from "@/hooks/services/useAccessRightsServices"
import useAccessRights from "@/hooks/useAccessRights"
import { useOrgaWithOrder } from "@/hooks/useOrga"
import { updateCommuneOrga } from "@/redux-toolkit/commune/ressources"
import { PATH_ORGA } from "@/redux-toolkit/userSettings/constants"
import _ from "lodash"
import React from "react"
import { useDispatch } from "react-redux"
import Organigramme from "utils/components/orga/Organigramme"
import TitleHeader from "utils/components/TitleHeader"

const CommuneOrga = () => {
	const dispatch = useDispatch()
	const permissions = useAccessRights(EnumToolPath.CD_ORGA)
	if (!permissions.read) {
		return (
			<ErrorPermissions
				errorMessage={FEATURE_ERROR_PERMISSION}
				backTitle={BACK_TITLE_HOME}
				backUrl={BACK_PATH_HOME}
				noContainer
			/>
		)
	}

	const orgaWithOrder = useOrgaWithOrder()

	return (
		<>
			<TitleHeader title="Organigramme" />

			<Organigramme
				orgaWithOrder={orgaWithOrder}
				cooperation={false}
				handlers={{
					deleteAll: (originals) => {
						const newOrgaWithOrder = _.cloneDeep(orgaWithOrder)
						originals.forEach(
							(original) => delete newOrgaWithOrder.cellules[original.id],
						)
						dispatch(updateCommuneOrga(newOrgaWithOrder))
					},
					delete: (original) => {
						const newOrgaWithOrder = _.cloneDeep(orgaWithOrder)
						delete newOrgaWithOrder.cellules[original.id]
						dispatch(updateCommuneOrga(newOrgaWithOrder))
					},
					order: (newOrder) =>
						dispatch(
							updateCommuneOrga({
								...orgaWithOrder,
								order: newOrder,
							}),
						),
					edit: (newOrga) => {
						dispatch(
							updateCommuneOrga({
								...orgaWithOrder,
								cellules: newOrga,
							}),
						)
					},
				}}
			/>
		</>
	)
}

export default CommuneOrga
