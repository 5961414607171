import useIsInterco from "@/hooks/useIsInterco"
import useIsOnline from "@/hooks/useIsOnline"
import useLivrets from "@/hooks/useLivrets"
import Modal from "@/styles/organisms/Modal"
import ModalBody from "@/styles/organisms/Modal/ModalBody"
import ModalHeader from "@/styles/organisms/Modal/ModalHeader"
import CheckCircleOutline from "@mui/icons-material/CheckCircleOutline"
import GetApp from "@mui/icons-material/GetApp"
import HourglassEmpty from "@mui/icons-material/HourglassEmpty"
import { LinearProgress } from "@mui/material"
import _ from "lodash"
import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { PrimaryOutlinedButton } from "utils/components/style/button"
import { Flex } from "utils/components/style/flex"
import intercoNameAdapter from "utils/intercoNameAdapter"
import Loading from "utils/Loading"
import { ModalContent } from "@nextui-org/react"
import Button from "@/styles/atoms/Button"

interface IModalWaitingDownloadPcs {
	modalWaitingOpened: boolean
	fileDownloadReady: string | undefined
	setProvidedSelection: (providedSelection: string | undefined) => void
	setModalWaitingOpened: (modalWaitingOpened: boolean) => void
	setFileDownloadReady: (fileDownloadReady: string | undefined) => void
	currentSelection: any
}

const GeneratingContainer = styled.div`
	overflow: auto;
	min-height: 100px;
	width: 100%;
	padding: 0 1em;
`
const SLinearProgress = styled(LinearProgress)`
	width: 100%;
	height: 6px;
	min-width: 600px;
`

const SFlex = styled(Flex)`
	width: 70px;
`
const PercentageDiv = styled.div`
	width: 30px;
`

const keySpeed = {
	preambule: 4,
}

const ModalWaitingDownloadPcs = ({
	modalWaitingOpened,
	fileDownloadReady,
	setProvidedSelection,
	setModalWaitingOpened,
	setFileDownloadReady,
	currentSelection,
}: IModalWaitingDownloadPcs) => {
	const isInterco = useIsInterco()
	const isOnline = useIsOnline()
	const [simulateLoading, setSimulateLoading] = useState({})
	const downloadFinished = fileDownloadReady !== undefined
	const livrets = useLivrets()
	const [simulatedDownloadFinished, setSimulatedDownloadFinished] =
		useState(false)
	const titleText = simulatedDownloadFinished
		? "Téléchargement terminé"
		: "Téléchargement en cours"
	const infoText = simulatedDownloadFinished
		? intercoNameAdapter(`Votre document est prêt`, isInterco)
		: intercoNameAdapter(
				`Cette opération peut durer plusieurs minutes, veuillez patienter`,
				isInterco,
			)
	let usedSelection = currentSelection
	if (_.isEmpty(usedSelection)) {
		usedSelection = Object.values(livrets)
	}
	let currentSelectionKey = usedSelection.map((selection: any) => selection.key)

	useEffect(() => {
		if (modalWaitingOpened) {
			setSimulateLoading({})
			setSimulatedDownloadFinished(false)
			setFileDownloadReady(undefined)
		}
	}, [modalWaitingOpened])

	useEffect(() => {
		const interval = setInterval(() => {
			if (modalWaitingOpened && !downloadFinished) {
				setTimeout(() => {
					setSimulateLoading((prevState) => {
						const newState = { ...prevState }
						currentSelectionKey.forEach((selection: any, index) => {
							if (prevState[selection] === undefined) {
								newState[selection] = 0
							} else {
								const isFirst = index === 0
								if (
									isFirst ||
									prevState[currentSelectionKey[index - 1]] === 100
								) {
									if (prevState[selection] < 100) {
										const speed = keySpeed[selection] || 1
										const newValue =
											prevState[selection] + Math.random() * 10 * speed
										newState[selection] = newValue > 100 ? 100 : newValue
									}
								}
							}
						})
						return newState
					}),
						Math.random() * 500
				})
			}
		}, 1000)
		return () => clearInterval(interval)
	}, [
		modalWaitingOpened,
		currentSelectionKey,
		setSimulateLoading,
		downloadFinished,
	])

	// if download finished emulate loading to 100%
	useEffect(() => {
		if (!modalWaitingOpened) {
			return
		}
		const interval = setInterval(() => {
			if (modalWaitingOpened && downloadFinished) {
				setTimeout(() => {
					setSimulateLoading((prevState) => {
						const newState = { ...prevState }
						if (
							currentSelectionKey.every(
								(selection: any) => newState[selection] === 100,
							)
						) {
							setSimulatedDownloadFinished(true)
						}
						currentSelectionKey.forEach((selection: any, index) => {
							if (prevState[selection] === undefined) {
								newState[selection] = 0
							} else {
								const isFirst = index === 0
								if (
									isFirst ||
									prevState[currentSelectionKey[index - 1]] === 100
								) {
									if (prevState[selection] < 100) {
										newState[selection] = 100
									}
								}
							}
						})
						return newState
					}),
						Math.random() * 500
				})
			}
		}, 800)
		return () => clearInterval(interval)
	}, [
		modalWaitingOpened,
		currentSelectionKey,
		setSimulateLoading,
		downloadFinished,
	])

	return (
		<Modal
			isOpen={modalWaitingOpened}
			size="3xl"
			onClose={() => {
				setProvidedSelection(undefined)
				setModalWaitingOpened(false)
			}}
		>
			<ModalContent>
				<ModalHeader>{titleText}</ModalHeader>
				<ModalBody>
					<div
						className={simulatedDownloadFinished ? "text-success" : "text-info"}
					>
						{infoText}
					</div>
					{fileDownloadReady && simulatedDownloadFinished ? (
						<>
							<Button
								color="primary"
								variant="bordered"
								startContent={<GetApp />}
								disabled={!isOnline}
								size="large"
								onClick={() => {
									// window.cookies.set(
									// 	"access-token",
									// 	localStorage.getItem("access-token"),
									// )
									// window.cookies.set("client", localStorage.getItem("client"))
									// window.cookies.set("uid", localStorage.getItem("uid"))
									window.open(fileDownloadReady)
									setProvidedSelection(undefined)
									setModalWaitingOpened(false)
									setFileDownloadReady(undefined)
								}}
							>
								Télécharger
							</Button>
						</>
					) : (
						<GeneratingContainer>
							<h5>Génération en cours</h5>
							<Flex directionColumn fullWidth alignItemsInitial gap="1rem">
								{currentSelectionKey.map((selection: any) => {
									return (
										<Flex
											key={selection}
											directionColumn
											fullWidth
											gap="0.25rem"
											alignItemsInitial
										>
											<div>
												{
													usedSelection.find(
														(item: any) => item.key === selection,
													)?.title
												}
												{usedSelection.find(
													(item: any) => item.key === selection,
												)?.subtitle && (
													<span>
														{" "}
														(
														{
															usedSelection.find(
																(item: any) => item.key === selection,
															)?.subtitle
														}
														)
													</span>
												)}
											</div>
											<Flex fullWidth gap="1.5rem">
												<SLinearProgress
													variant="determinate"
													value={simulateLoading[selection] ?? 0}
												/>
												<SFlex>
													{!simulateLoading[selection] && (
														<HourglassEmpty
															style={{ color: "orange", fontSize: "1.5rem" }}
														/>
													)}
													{simulateLoading[selection] === 100 && (
														<CheckCircleOutline
															style={{ color: "green", fontSize: "1.5rem" }}
														/>
													)}
													{!!simulateLoading[selection] &&
														simulateLoading[selection] < 100 && (
															<>
																<Loading />
																<PercentageDiv>
																	{Math.floor(simulateLoading[selection])}%
																</PercentageDiv>
															</>
														)}
												</SFlex>
											</Flex>
										</Flex>
									)
								})}
							</Flex>
						</GeneratingContainer>
					)}
				</ModalBody>
			</ModalContent>
		</Modal>
	)
}

export default ModalWaitingDownloadPcs
