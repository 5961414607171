import { yupResolver } from "@hookform/resolvers/yup"
import _ from "lodash"
import React, { useEffect } from "react"
import { FormProvider, useForm } from "react-hook-form"
import { Flex } from "@/utils/components/style/flex"
import FSelect from "@/utils/form/FSelect"
import FSelectHumans from "@/utils/form/FSelectHumans"
import FText from "@/utils/form/FText"
import SuperModal from "@/utils/modals/SuperModal"
import IOrga, { IOrgaCellule, ORGA_CATEGORIES } from "@/utils/types/IOrga"
import Yup from "@/utils/yup"
import { v4 as uuidv4 } from "uuid"

const schema = Yup.object().shape({
	id: Yup.string()
		.required()
		.default(() => uuidv4()),
	idName: Yup.string().required().default(""),
	category: Yup.string().nullable().required().default(null),
	responsableIds: Yup.array(Yup.string())
		.ensure()
		.min(1, "Le champs est requis"),
	suppleantIds: Yup.array(Yup.string()).default([]),
	membresIds: Yup.array(Yup.string()).default([]),
	description: Yup.string().default(""),
})

const EditCell = ({
	isOpen,
	setIsOpen,
	initialCellule,
	updateCommuneOrga,
	orga,
}: {
	orga: IOrga
	isOpen: boolean
	initialCellule: IOrgaCellule
	updateCommuneOrga: (orga: IOrga) => void
	setIsOpen: (isOpen: boolean) => void
}) => {
	const methods = useForm({
		defaultValues: initialCellule,
		resolver: yupResolver(schema),
	})

	const onSubmit = (values: IOrgaCellule) => {
		updateCommuneOrga({
			...orga,
			[values.id]: { ...values },
		})
		setIsOpen(false)
	}

	useEffect(() => {
		if (initialCellule)
			methods.reset(schema.cast(initialCellule) as IOrgaCellule)
		else methods.reset(schema.getDefault() as IOrgaCellule)
	}, [isOpen])

	return (
		<FormProvider {...methods}>
			<SuperModal
				isOpen={isOpen}
				onClose={() => setIsOpen(!isOpen)}
				title={initialCellule ? "Modifier une cellule" : "Créer une cellule"}
				isNew={!initialCellule}
				onClick={methods.handleSubmit(onSubmit)}
			>
				<Flex directionColumn gap="1rem">
					<FText name="idName" label="Nom de la cellule" />
					<FSelect
						name="category"
						label="Catégorie de la cellule"
						options={ORGA_CATEGORIES}
					/>
					<FSelectHumans name="responsableIds" label="Responsable" />
					<FSelectHumans
						name="suppleantIds"
						label="Suppléants"
						customModalTitle="Sélectionner des suppléants"
					/>
					<FSelectHumans
						name="membresIds"
						label="Membres"
						customModalTitle="Sélectionner des suppléants"
					/>
					<FText name="description" label="Description" multiline />
				</Flex>
			</SuperModal>
		</FormProvider>
	)
}

export default EditCell
