import AccessTimeIcon from "@mui/icons-material/AccessTime"
import Send from "@mui/icons-material/Send"
import Visibility from "@mui/icons-material/Visibility"
import VisibilityOff from "@mui/icons-material/VisibilityOff"
import useSendSmsAlert from "@/hooks/api/useSendSmsAlert"
import Debug from "debug"
import React, { useState } from "react"
import { isMobile } from "react-device-detect"
import { useFormContext } from "react-hook-form"
import { useHistory } from "react-router-dom"
import styled, { css } from "styled-components"
import ConfirmationModal from "@/utils/components/ConfirmationModal"
import {
	NeutralOutlinedButton,
	PrimaryButton,
	PrimaryOutlinedButton,
} from "@/utils/components/style/button"
import { Flex } from "@/utils/components/style/flex"
import { H5 } from "@/utils/components/style/header"
import FAdminJsonSchemas from "@/utils/form/FAdminJsonSchemas"
import { DaybookAlertType } from "@/utils/types/daybook/IDaybookAlert"
import DaybookAlertFIconRadios from "../../components/DaybookAlertFIconRadios"
import LeftContainer from "../../components/DaybookAlertLeftContainer"
import DaybookAlertRightContainer from "../../components/DaybookAlertRightContainer"
import DaybookAlertTest from "./DaybookAlertTest"
import CampaignCostCard from "./components/CampaignCostCard/CampaignCostCard"
import DaybookAlertContactTableReady from "./components/DaybookAlertContactTable/DaybookAlertContactTableReady"
import DaybookAlertRightContentSMSVocalSection from "./components/prepare/DaybookAlertRightContentSMSVocalSection"
import useSelectedEvent from "@/hooks/useSelectedEvent"
import CustomTooltip from "@/utils/components/style/CustomTooltip"
import { useDispatch, useSelector } from "react-redux"
import { Alert, IconButton } from "@mui/material"
import { useBoolean } from "react-use"
import ModalTwilioSwitch from "@/utils/modals/ModalTwilioSwitch"
import { toast } from "react-toastify"
import Button from "@/styles/atoms/Button"

// app:javascript:components:maincourante:alert:alertCreation:daybookAlertCampaignStep:DaybookAlertCampaignReady.tsx
const debug = Debug(
	"app:javascript:components:maincourante:alert:alertCreation:daybookAlertCampaignStep:DaybookAlertCampaignReady",
)
debug.log = console.log.bind(console)

const AdminJsonSchemaContainer = styled.div`
    width: 100%;
    ${
			isMobile &&
			css`
        max-width: 70% !important;
    `
		}
`
const FlexContainer = styled(Flex)`
	padding-top: 0.5rem;
	overflow-y: auto;
`

const DaybookAlertCampaignReady = () => {
	const [isModalConfirmationOpen, setIsModalConfirmationOpen] = useState(false)
	const history = useHistory()
	const [isLoading, setIsLoading] = useState(false)
	const [isOpenSwitchModal, setIsOpenSwitchModal] = useBoolean(false)
	const { sendAlertCampaign, sendTwilioAlertCampaign } = useSendSmsAlert()
	const { setValue, getValues } = useFormContext()
	const [displayList, setDisplayList] = useState(false)
	const selectedEvent = useSelectedEvent()
	const isLocked = selectedEvent?.locked
	const isOnline = useSelector((state: any) => state.common.isOnline)

	const isTableMethod = getValues("geojson.properties.method") === "table"
	const listeDiffusions = getValues("geojson.properties.listeDiffusions")

	const sendingCampaignProcess = async () => {
		setIsLoading(true)
		setIsModalConfirmationOpen(false)

		const res = await sendAlertCampaign()

		if (res.error) return setIsOpenSwitchModal(true)

		setIsLoading(false)
	}

	return (
		<>
			<Flex gap="2rem" alignItemsStart directionColumn={isMobile}>
				<Flex gap="2rem" alignItemsStart directionColumn>
					<LeftContainer directionColumn gap="1rem" alignItemsInitial>
						<H5>Canal de diffusion</H5>
						<Flex fullWidth gap="2rem" directionColumn>
							<DaybookAlertFIconRadios disabled />
						</Flex>
					</LeftContainer>
					<CampaignCostCard />
				</Flex>
				<DaybookAlertRightContainer
					directionColumn
					fullWidth
					gap="1rem"
					alignItemsInitial
				>
					<Flex fullWidth>
						<H5>Détails de la campagne</H5>
					</Flex>
					{!isOnline && (
						<Alert severity="error">
							Connexion internet requise pour l'envoi de message d'alerte.
						</Alert>
					)}
					<FlexContainer directionColumn gap=".5em">
						<Flex fullWidth gap="1rem">
							<AdminJsonSchemaContainer>
								<FAdminJsonSchemas
									multiple
									disabled
									additionalList={["Organigramme"]}
									label="Liste de diffusion"
									name="geojson.properties.listeDiffusions"
									endAdornment={
										<CustomTooltip
											title={
												displayList ? "Cacher la liste" : "Afficher la liste"
											}
										>
											<IconButton
												aria-label="toggle-displayList"
												onClick={() => setDisplayList(!displayList)}
												style={{
													fontSize: 24,
													color: displayList ? "#1976d2" : null,
												}}
											>
												{displayList ? <VisibilityOff /> : <Visibility />}
											</IconButton>
										</CustomTooltip>
									}
								/>
							</AdminJsonSchemaContainer>
						</Flex>
						{displayList && <DaybookAlertContactTableReady />}
						<Flex fullWidth directionColumn gap="1rem">
							<DaybookAlertRightContentSMSVocalSection disabled />
							<Flex fullWidth directionReverse gap="1rem">
								<PrimaryOutlinedButton
									onClick={() => {
										if (isTableMethod)
											setValue(
												"geojson.properties.listeDiffusionsPersistante",
												listeDiffusions,
											)
										setValue(
											"geojson.properties.status",
											DaybookAlertType.PREPARE_DIFFUSE,
										)
									}}
									disabled={isLocked}
								>
									Modifier la campagne
								</PrimaryOutlinedButton>
							</Flex>
						</Flex>
						<DaybookAlertTest />
					</FlexContainer>
					<Flex fullWidth spaceBetween gap="1rem">
						<Button
							color="primary"
							variant="bordered"
							onClick={() => {
								setValue(
									"geojson.properties.status",
									DaybookAlertType.PREPARE_DIFFUSE,
								)
							}}
							disabled={isLocked}
						>
							Précédent
						</Button>
						<Flex gap="1rem">
							<Button
								color="primary"
								variant="bordered"
								onClick={() => {
									history.push("/maincourante/alerte")
								}}
								startContent={<AccessTimeIcon />}
								disabled={isLocked}
							>
								Lancer plus tard
							</Button>
							<Button
								color="primary"
								onClick={() => {
									setIsModalConfirmationOpen(true)
								}}
								isLoading={isLoading}
								disabled={isLocked || !isOnline}
							>
								Lancer la campagne
							</Button>
						</Flex>
					</Flex>
				</DaybookAlertRightContainer>
				<ConfirmationModal
					modalShown={isModalConfirmationOpen}
					onClose={() => setIsModalConfirmationOpen(false)}
					onConfirm={() => sendingCampaignProcess()}
					message="Êtes-vous sur de vouloir lancer la campagne ?"
				/>
			</Flex>
			<ModalTwilioSwitch
				isOpen={isOpenSwitchModal}
				onClose={() => {
					setIsOpenSwitchModal(false)
				}}
				onConfirm={() => {
					setIsOpenSwitchModal(false)
					sendTwilioAlertCampaign()
				}}
			/>
		</>
	)
}

export default DaybookAlertCampaignReady
