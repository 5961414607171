import React, { useState, useEffect, useRef, useMemo } from "react"
import SuperReactTable from "utils/components/tables/SuperReactTable/SuperReactTable"
import { ColumnDef } from "@tanstack/react-table"
import ApiOverpassTurbo from "utils/api_externe/ApiOverpassTurbo"
import { useDispatch } from "react-redux"
import _ from "lodash"
import styled from "styled-components"
import { Flex } from "utils/components/style/flex"
import { useBoolean } from "react-use"
import axios from "axios"
import useSelectedEvent from "@/hooks/useSelectedEvent"
import { useWatch } from "react-hook-form"
import Loading from "utils/Loading"

const SFlex = styled(Flex)`
	font-size: 14px !important;
`

interface Way {
	id: number
	type: "way"
	nodes: number[]
	tags: Street
}

//* Exiting many properties for Way, all have'nt been defined here because useless.
interface Street {
	name: string
	ref: string
	source: string
	access: string
	note: string
	highway: string
	maxspeed: string
	oneway: string
	service: string
	surface: string
	cycleway: string
}

const DaybookAlertConcernedStreets = ({ multiPolygonBounds }) => {
	const [datas, setDatas] = useState<Street[]>([])
	const [isPending, setIsPending] = useBoolean(false)
	const [pendingResponse, setPendingResponse] =
		useState<Promise<any>>(undefined)
	const [resolvedResponse, setResolvedResponse] = useState<Way[] | Error>(
		undefined,
	)
	const [customErrorMessage, setCustomErrorMessage] = useState<string>(null)
	const dispatch = useDispatch()
	const abortController = useRef<AbortController>()
	const selectedEvent = useSelectedEvent()
	const campaignName = useWatch({ name: "geojson.properties.name" })

	const resetStatements = () => {
		if (customErrorMessage) setCustomErrorMessage(null)
		setIsPending(true)
	}

	const convertToCsv = useMemo(() => {
		return (selected: string[]) => {
			let csvData = []
			datas.forEach((data) => {
				csvData = [
					...csvData,
					[
						`=""${data.name ?? ""}""`,
						`=""${data.ref ?? ""}""`,
						`=""${data.source ?? ""}""`,
					],
				]
			})

			return {
				data: csvData,
				headers: ["Nom de rue", "Type de voie", "Source d'information"],
				filename:
					selectedEvent.name +
					"_" +
					campaignName?.replace(" ", "-") +
					"_RuesConcernées.csv",
			}
		}
	}, [datas])

	//* Calling API
	useEffect(() => {
		if (!multiPolygonBounds) return
		if (!isPending) resetStatements()
		if (abortController.current) abortController.current.abort()
		abortController.current = new AbortController()
		const { signal } = abortController.current
		const request = ApiOverpassTurbo.fetchStreetsInPolygon(
			multiPolygonBounds,
			signal,
			dispatch,
		)
		setPendingResponse(request)
	}, [multiPolygonBounds])

	//*	Awaiting to resolve API response
	useEffect(() => {
		if (!pendingResponse) return
		pendingResponse.then((resolved) => {
			setResolvedResponse(resolved)
		})
	}, [pendingResponse])

	//*	Updating datas state with resolved response
	useEffect(() => {
		if (!resolvedResponse) return
		abortController.current = undefined

		if (axios.isCancel(resolvedResponse)) return //* New API request has been made, aborting the current one
		if (resolvedResponse instanceof Error) {
			setCustomErrorMessage(
				"Erreur, la zone sélectionné ne permet pas la récupération des rues.",
			)
			setIsPending(false)
			return
		}

		const streets = {}

		resolvedResponse.map((way) => {
			if (streets[way.tags.name]) {
				streets[way.tags.name] = {
					...streets[way.tags.name],
					...way.tags,
				}
			} else {
				streets[way.tags.name] = way.tags
			}
		})

		setIsPending(false)
		setDatas(Object.values(streets))
	}, [resolvedResponse])

	const columns = [
		{
			header: "Nom de rue",
			accessorKey: "name",
		},
		{
			header: "Type de voie",
			accessorKey: "ref",
			cell: ({ row }) => row.original.ref ?? "",
		},
	] as ColumnDef<any>[]

	return (
		<SuperReactTable
			columns={columns}
			data={isPending ? [] : datas}
			selectable={false}
			isEditable={false}
			isDeletable={false}
			customEmptyMessage={
				customErrorMessage ? (
					customErrorMessage
				) : isPending ? (
					<SFlex gap="1em">
						Récupération des rues en cours...
						<Loading />
					</SFlex>
				) : (
					"Aucune rue n'a été trouvée."
				)
			}
			convertToCsv={convertToCsv}
		/>
	)
}
export default DaybookAlertConcernedStreets
