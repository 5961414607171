import { useState } from "react"
import { IExpandStrategyComponent } from "./expandStrategy"

const OneByOne: IExpandStrategyComponent<Record<string, never>> = ({
	children,
}) => {
	const [opened, setOpened] = useState(undefined)

	const isOpen = (item: string) => {
		return opened === item
	}

	const toggle = (item: string) => {
		if (isOpen(item)) setOpened(undefined)
		else setOpened(item)
	}

	return children({ isOpen, toggle })
}

export default OneByOne
