import axios from "axios"
import ErrorPermissions, {
	BACK_PATH_HOME,
	BACK_TITLE_HOME,
	TOOL_ERROR_PERMISSION,
} from "@/hooks/ErrorPermissions"
import useAccessRights from "@/hooks/useAccessRights"
import usePdfGenerators from "@/hooks/usePdfGenerators"
import useSelectedEvent from "@/hooks/useSelectedEvent"
import PdfApi from "@/redux-toolkit/data/pdf/PdfApi"
import { createPdf } from "@/redux-toolkit/data/pdf/resources"
import {
	ARRETE,
	PATH_MAIN_COURANTE_ARRETE,
} from "@/redux-toolkit/userSettings/constants"
import React, { useEffect, useState } from "react"
import Moment from "moment"
import { useDispatch } from "react-redux"
import { useHistory, useParams } from "react-router"
import HTMLEditor from "@/utils/components/HTMLEditor/HTMLEditor"
import TitleHeader from "@/utils/components/TitleHeader"
import ErrorService from "@/services/ErrorService"
import ModalSelectModel from "./ModalSelectModel"
import { updateEvent } from "@/redux-toolkit/data/events/resources"
import useIsInterco from "@/hooks/useIsInterco"
import { EnumToolPath } from "@/hooks/services/useAccessRightsServices"

const CreateArrete = () => {
	const permissions = useAccessRights(EnumToolPath.ARRETE)
	const isInterco = useIsInterco()

	if (!permissions.read || !permissions.write || isInterco) {
		return (
			<ErrorPermissions
				errorMessage={TOOL_ERROR_PERMISSION}
				backTitle={BACK_TITLE_HOME}
				backUrl={BACK_PATH_HOME}
			/>
		)
	}
	// @ts-ignore
	const { id } = useParams()
	const history = useHistory()
	const selectedEvent = useSelectedEvent()
	const dispatch = useDispatch()
	const pdfGenerators = Object.values(usePdfGenerators())

	const [html, setHtml] = useState("")
	const [title, setTitle] = useState("")

	useEffect(() => {
		if (id) {
			setTitle(
				(pdfGenerators.find((pdfg: any) => pdfg.id === id) as any)?.title ?? "",
			)

			const loadGenerator = async () => {
				const response = await axios.get(`/api/arrete/pdfGenerators/${id}`)
				setHtml(response.data)
			}
			loadGenerator()
		}
	}, [id])

	const save = async (title, html) => {
		try {
			const { payload: id } = (await dispatch(
				createPdf({ data: html, title }),
			)) as any

			const uuid = await PdfApi.generate(id)
			const uri = await PdfApi.waitArreteGeneration(uuid)
			dispatch(
				updateEvent({
					...selectedEvent,
					history: [
						...selectedEvent.history,
						{
							type: ARRETE,
							title,
							subTitle: "Création d'un arrêté",
							objectId: id,
							date: Moment().format(),
						},
					],
				}),
			)
			window.open(uri)
			history.push("/maincourante/donnees")
		} catch (e) {
			ErrorService.error({
				component: "TableArrete:download",
				message: "L'arrêté n'a pas pu être téléchargé",
				dispatch,
			})
		}
	}
	const cancel = () => history.goBack()
	if (!id) {
		return (
			<ModalSelectModel
				setId={(newId) => history.push(`/maincourante/arretes/create/${newId}`)}
			/>
		)
	}
	return (
		<>
			<TitleHeader
				title="Créer un arrêté"
				backArrowUrl="/maincourante/donnees"
				backArrowTitle="Retour"
			/>
			<HTMLEditor
				title={title}
				htmlData={html}
				onValidate={save}
				onCancel={cancel}
				titleLabel="Nom de l'arrêté"
				validateMessage="Créer"
			/>
		</>
	)
}

export default CreateArrete
