import React from "react"
import { Image as ImagePdf, View } from "@react-pdf/renderer"
const Image = ImagePdf as any
const PImage = ({ src, alt, width = "auto", height = "auto", style = {} }) => {
	return (
		<Image
			src={src}
			source={alt}
			style={{
				width: width,
				height: height,
				...style,
			}}
		/>
	)
}
export default PImage
