import { updateFiche } from "@/redux-toolkit/data/fiche/resources"
import { useCallback } from "react"
import { useDispatch } from "react-redux"
import IFiche from "utils/types/IFiche"

const useRenameFiche = (fiche: IFiche) => {
	const dispatch = useDispatch()

	return useCallback(
		(categoryId: string, newName: string) => {
			const newActionsByCategory = fiche.actionsByCategory.map((category) => {
				if (category.id !== categoryId) return category
				return {
					...category,
					categoryName: newName,
				}
			})
			dispatch(
				updateFiche({
					...fiche,
					actionsByCategory: newActionsByCategory,
				}),
			)
		},
		[fiche],
	)
}

export default useRenameFiche
