import React, { useMemo } from "react"
import SuperReactTable from "utils/components/tables/SuperReactTable/SuperReactTable"
import usePdfGenerators from "@/hooks/usePdfGenerators"
import { Radio } from "@mui/material"
import { width60 } from "utils/components/tables/widthProps"
import TitleHeader from "utils/components/TitleHeader"
import useAccessRights from "@/hooks/useAccessRights"
import { PATH_MAIN_COURANTE_ARRETE } from "@/redux-toolkit/userSettings/constants"
import useIsInterco from "@/hooks/useIsInterco"
import ErrorPermissions, {
	BACK_PATH_HOME,
	BACK_TITLE_HOME,
	FEATURE_ERROR_PERMISSION,
} from "@/hooks/ErrorPermissions"
import { EnumToolPath } from "@/hooks/services/useAccessRightsServices"

const ModalSelectModel = ({ setId }) => {
	const permissions = useAccessRights(EnumToolPath.ARRETE)
	const isInterco = useIsInterco()
	const pdfGenerators = Object.values(usePdfGenerators())

	const columns = useMemo(
		() => [
			{
				header: "",
				accessorKey: "select",
				cell: (props) => {
					const original = props.row.original
					return <Radio onClick={() => setId(original.id)} />
				},
				enableSorting: false,
				...width60,
			},
			{
				header: "Modèle",
				accessorKey: "title",
			},
		],
		[],
	)

	if (!permissions.read || isInterco) {
		return (
			<ErrorPermissions
				errorMessage={FEATURE_ERROR_PERMISSION}
				backTitle={BACK_TITLE_HOME}
				backUrl={BACK_PATH_HOME}
			/>
		)
	}

	return (
		<>
			<TitleHeader
				title="Choisir un modèle d'arrêté"
				backArrowUrl="/maincourante/donnees"
				backArrowTitle="Retour"
			/>
			<SuperReactTable
				onRowClick={(row) => setId(row.original.id)}
				isEditable={false}
				isDeletable={false}
				autoResetPage={false}
				selectable={false}
				data={pdfGenerators}
				columns={columns}
			/>
		</>
	)
}

export default ModalSelectModel
