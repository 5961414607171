import useAllPoints from "@/hooks/useAllPoints"
import useFiches from "@/hooks/useFiches"
import useJsonSchemas from "@/hooks/useJsonSchemas"
import useMultiPolygons from "@/hooks/useMultiPolygons"
import usePoints from "@/hooks/usePoints"
import DatabaseService from "@/services/DatabaseService"
import { Alert } from "@mui/material"
import React, { useEffect, useState } from "react"
import styled, { keyframes } from "styled-components"
import AnimatedNumber from "react-animated-number"
import { Listbox, ListboxItem, ListboxSection } from "@nextui-org/react"

const LoadingAnimation = styled.div`
  animation: ${keyframes`
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  `} 2s linear infinite;
`

const LoadingPage = () => {
	const points = useAllPoints()
	const fiches = useFiches()
	const multiPolygons = useMultiPolygons()
	const jsonSchemas = useJsonSchemas()

	const [multiPolygonCountourLoaded, setMultiPolygonCountourLoaded] =
		useState(0)

	useEffect(() => {
		const calculateMultiPolygonCountourLoaded = async () => {
			const keys = await DatabaseService.keys()
			setMultiPolygonCountourLoaded(keys.length)
		}

		const interval = setInterval(calculateMultiPolygonCountourLoaded, 300)

		return () => clearInterval(interval)
	}, [])

	return (
		<div className="flex justify-center items-center h-screen flex-col bg-gray-100 text-gray-800">
			<Alert
				severity="info"
				className="max-w-md mx-auto my-8 p-4 rounded-lg shadow-md"
			>
				La synchronisation est en cours. Veuillez patienter, les données se
				mettent en cache afin d'être accessibles hors ligne.
			</Alert>
			<Listbox variant="flat" aria-label="Listbox menu with sections">
				{[
					{
						title: "Les formulaires de données",
						value: Object.keys(jsonSchemas).length,
						description:
							"La structure de vos formulaires, chaque formulaire correspond à un type de données.",
					},
					{
						title: "Les données de votre plan",
						value: Object.keys(points).length,
						description: "Les données intégrées dans les formulaires.",
					},
					{
						title: "Les fiches actions et scénarios",
						value: Object.keys(fiches).length,
						description:
							"La liste des actions à mettre en place en temps de crise, regroupées par cellules et par type de risques.",
					},
					{
						title: "Couches SIG",
						value: Object.keys(multiPolygons).length,
						description:
							"Les couches cartographiques de référence et aléas de votre commune.",
					},
					{
						title: "Détail des couches cartographiques",
						value: multiPolygonCountourLoaded,
						after: " / " + Object.keys(multiPolygons).length,
						description: "Les limites géographiques des couches SIG.",
					},
				].map((item, index) => (
					<ListboxItem
						key={index}
						className="flex justify-between items-center max-w-4xl w-full mx-auto px-4 py-2 border-b border-gray-200"
						description={item.description}
						endContent={
							<>
								<span
									className={`text-sm text-right ${
										item.value === 0 ? "text-red-500" : "text-green-800"
									} w-48`}
								>
									{item.value === 0 ? (
										<div>Chargement en cours</div>
									) : (
										<AnimatedNumber
											component="text"
											value={item.value}
											className="font-semibold text-md"
											frameStyle={(perc) => (perc === 100 ? {} : {})}
											duration={300}
											stepPrecision={0}
										/>
									)}
									{item.value !== 0 && <span>{item.after}</span>}
								</span>
							</>
						}
					>
						{item.title}
					</ListboxItem>
				))}
			</Listbox>
		</div>
	)
}

export default LoadingPage
