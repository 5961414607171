import { yupResolver } from "@hookform/resolvers/yup"
import { Alert, Stepper } from "@mui/material"
import { updateUploadedFile } from "@/redux-toolkit/data/uploadedFile/resources"
import React, { useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { useDispatch } from "react-redux"
import { toast } from "react-toastify"
import { Flex } from "@/utils/components/style/flex"
import IUploadedFile, { IFileConfigCategory } from "@/utils/types/IUploadedFile"
import Yup from "@/utils/yup"
import StepFormProvider from "./component/StepFormProvider"
import StepLocation from "./steps/initialUpload/StepLocation"
import StepMoveCategory from "./steps/moveUpload/StepMoveCategory"
import { ModalContent } from "@nextui-org/react"
import Modal from "@/styles/organisms/Modal"
import ModalHeader from "@/styles/organisms/Modal/ModalHeader"
import ModalBody from "@/styles/organisms/Modal/ModalBody"
import ModalFooter from "@/styles/organisms/Modal/ModalFooter"
import CancelButton from "@/styles/atoms/Button/specialized/CancelButton"
import Button from "@/styles/atoms/Button"
const schema = Yup.object().shape({
	category: Yup.string().required().default(null),
	location: Yup.object().required().default(null),
	generateHeader: Yup.boolean().default(false),
	orientation: Yup.string().default("Portrait"),
})

const ModalMoveUpload = ({
	isOpen,
	onClose,
	file,
}: {
	isOpen: boolean
	onClose: (target?: IFileConfigCategory) => void
	file: IUploadedFile
}) => {
	const [step, setStep] = useState(0)
	const dispatch = useDispatch()

	const methods = useForm({
		resolver: yupResolver(schema),
	})

	useEffect(() => {
		if (isOpen) {
			methods.reset({
				...schema.getDefault(),
				orientation: file.config.orientation,
				generateHeader: file.config.generateHeader,
			})
			setStep(0)
		}
	}, [isOpen])

	const steps = ["category", "location"]

	const onSumbit = async (values) => {
		await dispatch(
			updateUploadedFile({
				id: file.id,
				config: {
					...file.config,
					category: values.category,
					pcs: values.location,
					generateHeader: values.generateHeader,
					orientation: values.orientation,
				},
			}),
		)
		onClose(values.category)
		toast.success("Le fichier a été correctement déplacé")
	}

	return (
		<StepFormProvider
			methods={methods}
			setActiveStep={setStep}
			activeStep={step}
			steps={steps}
		>
			<Modal isOpen={isOpen} onClose={() => onClose()} $baseWidth={1000}>
				<ModalContent>
					<ModalHeader>Déplacer un fichier</ModalHeader>
					<ModalBody>
						<Flex gap directionColumn alignItemsStretch>
							<Stepper activeStep={step} orientation="vertical">
								<StepMoveCategory file={file} />
								<StepLocation file={file} />
							</Stepper>
							{step === steps.length && (
								<Alert>
									Le déplacement du fichier est prêt. Cliquez sur
									&quot;Terminer&quot; pour l&apos;effectuer
								</Alert>
							)}
						</Flex>
					</ModalBody>
					<ModalFooter>
						<CancelButton onClick={() => onClose()} />
						{step === steps.length && (
							<Button
								color="primary"
								onClick={methods.handleSubmit(onSumbit, console.error)}
							>
								Terminer
							</Button>
						)}
					</ModalFooter>
				</ModalContent>
			</Modal>
		</StepFormProvider>
	)
}

export default ModalMoveUpload
