import useCdcSharedJsonSchemas from "@/hooks/useCdcSharedJsonSchemas"
import useCooperationFilters from "@/hooks/useCooperationFilters"
import { SearchMode } from "@/redux-toolkit/edit/cooperationFiltersRessources"
import { StaticFormType } from "utils/services/StaticFormsService"
import _ from "lodash"
import IPoint from "utils/types/IPoint"
const useLayerFilter = () => {
	const coopFilters = useCooperationFilters()
	const coopFiltersKey = coopFilters.layer?.key
	const cdcSharedJsonSchemas = useCdcSharedJsonSchemas()
	const sharedJsonSchemasKeys = Object.keys(cdcSharedJsonSchemas)


	return (item: IPoint) => {
		if (_.isEmpty(coopFilters.layer)) return false
		if (coopFilters.search === SearchMode.DONNEES_PARTAGEES) {
			// Filter disabled

			// Filter enabled
			if ([StaticFormType.PCC, StaticFormType.CARE, StaticFormType.STOCK_PLACE].includes(item.jsonschema_id as StaticFormType)) {
				return true
			} else {
				if (coopFilters.communes.length) {
					return [StaticFormType.ANNUAIRES_ELUS, StaticFormType.ANNUAIRE_PERSONNEL_COMMUNAL, StaticFormType.RESERVE_COMMUNAL_SECURITE_CIVILE, ...sharedJsonSchemasKeys].includes(item.jsonschema_id)
				} else {
					return coopFiltersKey === item.jsonschema_id
				}
			}
		} else {

			// Filter enabled
			return coopFilters.layer.jsonschema_id === item.jsonschema_id
		}
	}
}

export default useLayerFilter
