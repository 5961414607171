// import Button from "@/styles/atoms/Button"
import useOpenAppBar from "@/hooks/useOpenAppBar"
import { setActiveTool, setPanel } from "@/redux-toolkit/common/reducer"
import { resetEdit } from "@/redux-toolkit/edit/reducer"
import { DAYBOOK_LEGEND } from "@/utils/panels/PanelCarto"
import { Kbd, Listbox, ListboxItem } from "@nextui-org/react"
import React from "react"
import { isMobile } from "react-device-detect"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router-dom"
import { ITool } from "../../SideBar.hook"

const SideBarContent = ({
	tool,
	setOpenIndex,
}: {
	tool: ITool
	openIndex: number
	index: number
	setOpenIndex: (number) => void
}) => {
	const dispatch = useDispatch()
	const history = useHistory()
	const [isOpen, setIsOpen] = useOpenAppBar()
	const activeTool = useSelector((state: any) => state?.common?.activeTool)
	return (
		<React.Fragment key={tool.name}>
			<Listbox
				key={tool.name}
				variant="faded"
				disabledKeys={tool.subMenus
					?.filter((subMenu) => subMenu.disabled)
					?.map((subMenu) => subMenu.name)}
			>
				{(tool?.subMenus ?? []).map((subMenu) => {
					const isActive = activeTool === subMenu.route
					return (
						<ListboxItem
							key={subMenu.name}
							description={subMenu.description}
							startContent={subMenu.icon}
							endContent={
								subMenu.shortcut &&
								!isMobile && (
									<Kbd>
										<div className="w-max">{subMenu.shortcut}</div>
									</Kbd>
								)
							}
							classNames={
								isActive && {
									base: "text-primary",
									description: "text-primary",
									selectedIcon: "text-primary",
									shortcut: "text-primary",
									title: "font-semibold",
									wrapper: "",
								}
							}
							color={subMenu.color}
							variant="bordered"
							onClick={() => {
								dispatch(setActiveTool(subMenu.route))
								if (subMenu.route === "/maincourante/carto" && !isMobile) {
									dispatch(
										setPanel({
											type: DAYBOOK_LEGEND,
										}),
									)
								} else {
									dispatch(setPanel(null))
								}
								setOpenIndex(-1)
								history.push(subMenu.route)
								dispatch(resetEdit())
								if (isMobile) {
									setIsOpen(false)
								}
							}}
						>
							{subMenu.name}
						</ListboxItem>
					)
				})}
			</Listbox>
		</React.Fragment>
	)
}

export default SideBarContent
