import React from "react"
import { Route } from "react-router-dom"
import PageContainer from "utils/components/layout/PageContainer"
import Home2 from "./Home2"

const Home = () => {
	return (
		<PageContainer>
			<Route path="/home" component={Home2} />
		</PageContainer>
	)
}
export default Home
