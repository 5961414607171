import { yupResolver } from "@hookform/resolvers/yup"
import { Alert } from "@mui/material"
import useRiskFiles from "@/hooks/useRiskFiles"
import React, { useEffect, useMemo } from "react"
import { FormProvider, useForm } from "react-hook-form"
import FList from "utils/form/FList"
import { LinkType } from "utils/types/IFiche"
import Yup from "utils/yup"
import useParentSubmit from "./utils/parentSubmit/useParentSubmit"

const schema = Yup.object().shape({
	type: Yup.string().default(LinkType.RISK_FILE),
	id: Yup.string().required("Aucune fiche séléctionnée"),
})

const RiskFileLink = ({ links, requestSelect }) => {
	const methods = useForm({
		resolver: yupResolver(schema),
		defaultValues: schema.cast({}),
	})

	useParentSubmit(methods.handleSubmit)

	const disabledItems = useMemo(() => {
		return links
			.filter((link) => link.type === LinkType.RISK_FILE)
			.map((link) => link.id)
	}, [links])

	const riskFiles = useRiskFiles()
	const items = useMemo(() => Object.values(riskFiles), [riskFiles])

	useEffect(() => {
		//@ts-ignore
		if (requestSelect) methods.setValue("id", requestSelect)
	}, [requestSelect])

	return (
		<FormProvider {...methods}>
			{requestSelect && (
				<Alert severity="success">
					Le fichier a été téléversé et sélectionné, cliquez sur
					&quot;Ajouter&quot; pour créer le lien.
				</Alert>
			)}
			<FList
				name="id"
				disabledItems={disabledItems}
				items={items}
				idAccessor={(riskFile) => riskFile.key}
				titleAccessor={(riskFile) => `Fiche pédagogique ${riskFile.risk}`}
			/>
		</FormProvider>
	)
}

export default RiskFileLink
