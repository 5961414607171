import React, { useState, useEffect, useMemo, useRef } from "react"
import { useDispatch, useSelector } from "react-redux"
import Debug from "debug"
import styled, { css } from "styled-components"
import { FormProvider, useForm } from "react-hook-form"
import FPhone from "@/utils/form/FPhone"
import FText from "@/utils/form/FText"
import _ from "lodash"
import Button from "@/styles/atoms/Button"
import { Flex } from "../components/style/flex"

const StyledFlex = styled(Flex)`
    padding: 1rem;
`

// app:javascript:utils:popover:ContactPopover.tsx
const debug = Debug("app:javascript:utils:popover:ContactPopover")
debug.log = console.log.bind(console)

const ContactPopoverContent = ({
	open,
	onClose,
	onSubmit,
	initialValues = {
		Identité: "",
		Téléphone: "",
		Courriel: "",
	},
}) => {
	const handleClose = () => {
		onClose()
		methods.reset(initialValues)
	}
	useEffect(() => {
		if (open) {
			methods.reset(initialValues)
		}
	}, [open])
	const methods = useForm({ defaultValues: initialValues })
	methods.watch()
	const isIdenticValues = _.isEqual(methods.getValues(), initialValues)
	return (
		<>
			<FormProvider {...methods}>
				<StyledFlex gap="1rem" directionColumn>
					<FText autoFocus name="Identité" label="Identité" />
					<FText name="Téléphone" label="Téléphone" />
					<FText name="Courriel" label="Courriel" />
					<Flex gap="1rem" flexEnd>
						<Button color="primary" variant="text" onClick={handleClose}>
							Annuler
						</Button>
						<Button
							color="primary"
							disabled={!methods.formState.isDirty}
							onClick={() => {
								onSubmit(methods.getValues())
								handleClose()
							}}
						>
							Éditer le contact
						</Button>
					</Flex>
				</StyledFlex>
			</FormProvider>
		</>
	)
}

export default ContactPopoverContent
