import CloseIcon from "@mui/icons-material/Close"
import { IconButton } from "@mui/material"
import useIsMenuOpen from "@/hooks/useIsMenuOpen"
import useResponsive from "@/hooks/useResponsive"
import React from "react"
import { isMobile } from "react-device-detect"
import styled, { css } from "styled-components"
import { H4 } from "@/utils/components/style/header"
import SaveButton from "@/styles/atoms/Button/specialized/SaveButton"
import CancelButton from "@/styles/atoms/Button/specialized/CancelButton"

const PANEL_WIDTH = 400

const Wrapper = styled.div<{ $isOpen: boolean; $isSmall: boolean }>`
    margin-top: var(--height-top-bar);
    transition: 0.5s;
    background-color: white;
    overflow: ${({ $isOpen }) => ($isOpen ? "auto" : "hidden")};
    box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%),
        0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);

    flex-direction: column;
    align-items: stretch;

    ${({ $isSmall, $isOpen }) =>
			$isSmall
				? css`
                  position: absolute;
                  top: 0;
                  right: 0;
                  width: var(--modal-width);
                  height: var(--modal-height);
                  z-index: 1000;
                  display: ${$isOpen ? "flex" : "none"};
              `
				: css`
                  display: flex;
                  width: ${$isOpen ? PANEL_WIDTH : 0}px;
              `}
`

const SH4 = styled(H4)`
    margin-bottom: 0;
    line-height: unset;
`

const TopContainer = styled.div`
    display: flex;
    flex-direction: row;
    gap: 1rem;
    justify-content: space-between;
    padding: ${isMobile ? "0.4em" : "1rem"};
    border-bottom: 1px solid var(--neutral150) !important;
    align-items: center;
`
const PanelContent = styled.div`
    flex-grow: 1;
    overflow: auto;
`
const BottomContainer = styled.div`
    padding: ${isMobile ? "0.4em" : "1rem"};
    display: flex;
    justify-content: flex-end;
    align-items: center;
    border-top: 1px solid var(--neutral150) !important;
    box-shadow: none !important;
    gap: 10px;
`

const SuperPanel = ({
	isOpen,
	children,
	onSave,
	onClose,
	onCancel,
	title = "",
}) => {
	const isSmall = useResponsive(1300)

	return (
		<Wrapper $isOpen={isOpen} $isSmall={isSmall}>
			<TopContainer>
				<SH4>{title}</SH4>
				<IconButton onClick={onClose}>
					<CloseIcon />
				</IconButton>
			</TopContainer>
			<PanelContent>{children}</PanelContent>
			<BottomContainer>
				<CancelButton onClick={onCancel} />
				<SaveButton onClick={onSave} />
			</BottomContainer>
		</Wrapper>
	)
}

export default SuperPanel
