import useCooperationFilters from "@/hooks/useCooperationFilters"
import searchConfig from "../utils/searchConfig"

function useSearchHasStarted() {
	const coopFilters = useCooperationFilters()

	const currentSearch = searchConfig.find(
		(sc) => sc.mode === coopFilters.search,
	)

	return currentSearch?.hasStarted(coopFilters)
}

export default useSearchHasStarted
