import { useSelector } from "react-redux"
import createDeepEqualSelector from "@/utils/createDeepEqualSelector"

const selectAdmin = createDeepEqualSelector(
    (state: any) => state.userSettings.role,
    (role) => ['super-admin', 'admin'].includes(role)
)


const useIsAdminNumerisk = () => {
    return useSelector(selectAdmin) ?? {}
}

export default useIsAdminNumerisk