import Import from "@/pages/admin/formulaire_dynamique/MyForms/components/Import"
import Cartography from "@/pages/carto2/cartographie/Cartography"
import ErrorPermissions, {
	BACK_PATH_HOME,
	BACK_TITLE_HOME,
	TOOL_ERROR_PERMISSION,
} from "@/hooks/ErrorPermissions"
import useAccessRights from "@/hooks/useAccessRights"
import { CUSTOMDATA } from "@/redux-toolkit/userSettings/constants"
import React from "react"
import { Route } from "react-router-dom"
import PageContainer from "utils/components/layout/PageContainer"
import MyForms from "../admin/formulaire_dynamique/MyForms/MyForms"
import AlertMessage from "./alertMessage/AlertMessage"
import EditArretesTemplates from "./arretes/EditArretesTemplates"
import TableArretesTemplates from "./arretes/TableArretesTemplates"
import CustomizedPcs from "./CustomizedPcs"
import FichePage from "./fiches/FichePage"
import TableFiches from "./fiches/TableFiches"
import CommuneOrga from "./orga/CommuneOrga"
import PcsData from "./pcsData/PcsData"
import CustomDataDashboard from "./CustomDataDashboard"
import { EnumToolPath } from "@/hooks/services/useAccessRightsServices"

const Customdata = () => {
	const permissions = useAccessRights(EnumToolPath.CUSTOMDATA)
	if (!permissions.read) {
		return (
			<ErrorPermissions
				errorMessage={TOOL_ERROR_PERMISSION}
				backTitle={BACK_TITLE_HOME}
				backUrl={BACK_PATH_HOME}
			/>
		)
	}

	return (
		<PageContainer>
			<Route exact strict path="/customdata/board">
				<CustomDataDashboard />
			</Route>
			<Route exact strict path="/customdata/my_forms">
				<PcsData />
			</Route>
			<Route exact strict path="/customdata/my_forms/:id">
				<MyForms />
			</Route>
			<Route exact strict path="/customdata/my_forms/:id/import">
				<Import />
			</Route>
			<Route exact strict path="/customdata/cartographie">
				<Cartography />
			</Route>
			<Route exact strict path="/customdata/organigramme">
				<CommuneOrga />
			</Route>
			<Route exact strict path="/customdata/fiche">
				<TableFiches />
			</Route>
			<Route exact strict path="/customdata/fiche/:id">
				<FichePage />
			</Route>
			<Route exact strict path="/customdata/arretes">
				<TableArretesTemplates />
			</Route>
			<Route exact strict path="/customdata/arretes/:id">
				<EditArretesTemplates />
			</Route>
			<Route exact path="/customdata/customize">
				<CustomizedPcs />
			</Route>
			<Route exact path="/customdata/message_alerte">
				<AlertMessage />
			</Route>
		</PageContainer>
	)
}

export default Customdata
