import React from "react"
import useCommune from "@/hooks/useCommune"
import { updateCommune } from "@/redux-toolkit/commune/ressources"
import { FormProvider, useForm } from "react-hook-form"
import { useDispatch } from "react-redux"
import { PrimaryButton } from "utils/components/style/button"
import FCheckboxes from "utils/form/FCheckboxes"
import styled from "styled-components"
import { Flex } from "utils/components/style/flex"
import { toast } from "react-toastify"
import {
	DaybookAlertCanal,
	risksIcons,
} from "@/utils/types/daybook/IDaybookAlert"
import FIconRadios from "utils/form/FIconRadios"

const CheckBoxContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0 auto;
    margin-top: 2rem;
    margin-bottom: 2rem;
    `

const ConfigAlert = () => {
	const commune = useCommune()
	const methods = useForm({ defaultValues: commune })
	const dispatch = useDispatch()
	const onSubmit = (data) => {
		dispatch(updateCommune({ alertSystems: data.alertSystems }))
		methods.reset(data)
		toast.success("Les moyens d'alertes ont bien été mis à jour")
	}
	return (
		<FormProvider {...methods}>
			<h1>Configuration des moyens d'alerte</h1>
			<Flex fullWidth flexEnd>
				<PrimaryButton
					disabled={!methods.formState.isDirty}
					onClick={methods.handleSubmit(onSubmit)}
				>
					Sauvegarder les changements
				</PrimaryButton>
			</Flex>
			<CheckBoxContainer>
				<FIconRadios
					multi
					name="alertSystems"
					label="Moyens d'alerte"
					iconOptions={risksIcons}
				/>
			</CheckBoxContainer>
		</FormProvider>
	)
}

export default ConfigAlert
