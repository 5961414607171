import TableIncident from "@/pages/maincourante/following/TableIncident"
import Button from "@/styles/atoms/Button"
import Modal from "@/styles/organisms/Modal"
import ModalBody from "@/styles/organisms/Modal/ModalBody"
import ModalFooter from "@/styles/organisms/Modal/ModalFooter"
import ModalHeader from "@/styles/organisms/Modal/ModalHeader"
import { ModalContent } from "@nextui-org/react"
import Debug from "debug"
import React from "react"
// app:javascript:utils:form:specific:daybook:FModalSelectIncident.tsx
const debug = Debug(
	"app:javascript:utils:form:specific:daybook:FModalSelectIncident",
)
debug.log = console.log.bind(console)

const FModalSelectIncident = ({ onClick, isOpen, onClose }) => {
	return (
		<Modal isOpen={isOpen} onClose={onClose} size="5xl">
			<ModalContent>
				<ModalHeader>Choisir un évènement </ModalHeader>
				<ModalBody>
					<TableIncident
						customFilter={(point) =>
							point.geojson.properties.Statut !== "Terminé"
						}
						tableProps={{
							onRowClick: (row) => {
								onClick(row.original.id)
								onClose()
							},
							actionsButtons: null,
						}}
					/>
				</ModalBody>
				<ModalFooter>
					<Button variant="light" color="primary" onClick={onClose}>
						Annuler
					</Button>
				</ModalFooter>
			</ModalContent>
		</Modal>
	)
}

export default FModalSelectIncident
