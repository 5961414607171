import { EnumToolPath } from "@/hooks/services/useAccessRightsServices"
import useAccessRights from "@/hooks/useAccessRights"
import useAllHumans from "@/hooks/useAllHumans"
import useHumans from "@/hooks/useHumans"
import useJsonSchemasWithStatics from "@/hooks/useJsonSchemasWithStatics"
import EditCell from "@/pages/customdata/orga/EditCell"
import Button from "@/styles/atoms/Button"
import HumanChip from "@/styles/atoms/Chip/specialized/HumanChip"
import colored from "@/utils/colored"
import { StaticFormType } from "@/utils/services/StaticFormsService"
import { IOrgaCellule, ORGA_CATEGORIES, ORGA_COLOR } from "@/utils/types/IOrga"
import AddIcon from "@mui/icons-material/Add"
import ExpandLess from "@mui/icons-material/ExpandLess"
import ExpandMore from "@mui/icons-material/ExpandMore"
import { Chip } from "@mui/material"
import { ColumnDef } from "@tanstack/react-table"
import React, { useMemo, useState } from "react"
import { isMobile } from "react-device-detect"
import styled from "styled-components"
import { PrimaryTextButton } from "../style/button"
import { Flex } from "../style/flex"
import SuperReactTable from "../tables/SuperReactTable/SuperReactTable"
import { StyledCell } from "../tables/SuperReactTable/SuperReactTable.styled"
import { width180, widthCustom } from "../tables/widthProps"
import OrgaSubRow from "./OrgaSubRow"

const CChip = colored(Chip)

const SStyledCell = styled(StyledCell)<{ $isExpanded: boolean }>``

const Organigramme = ({
	orgaWithOrder,
	cooperation = true,
	handlers = undefined,
}) => {
	if (!orgaWithOrder?.cellules) return null

	const humans = cooperation ? useAllHumans() : useHumans()

	const [isOpenModalCell, setIsOpenModalCell] = useState(false)
	const [initialCellule, setInitialCellule] = useState(undefined)

	const { cellules: orga, order } = orgaWithOrder
	const orgaArray: IOrgaCellule[] = useMemo(() => Object.values(orga), [orga])
	const permissions = useAccessRights(EnumToolPath.CD_ORGA)
	const jsonSchemas = useJsonSchemasWithStatics()
	const writePermissions = {
		[StaticFormType.ANNUAIRES_ELUS]:
			jsonSchemas[StaticFormType.ANNUAIRES_ELUS]?.write,
		[StaticFormType.ANNUAIRE_PERSONNEL_COMMUNAL]:
			jsonSchemas[StaticFormType.ANNUAIRE_PERSONNEL_COMMUNAL]?.write,
	}

	const columns = useMemo(() => {
		return [
			{
				header: "Catégorie",
				accessorKey: "category",
				cell: ({ getValue }) => {
					const value = getValue() as any
					return (
						<CChip
							label={value}
							$backgroundColor={ORGA_COLOR[value]}
							$textColor="white"
						/>
					)
				},
				...widthCustom(170),
				enableSorting: false,
			},
			{
				header: "Cellule",
				accessorKey: "idName",
				enableSorting: false,
			},
			{
				header: "Responsables",
				accessorKey: "responsableIds",
				cell: ({ getValue }) => {
					const value = (getValue() ?? []) as any
					const responsables = value
						.map((responsable) => humans[responsable])
						.filter(Boolean)
					return (
						<Flex gap="0.5rem" wrap>
							{responsables.map((responsable) => (
								<HumanChip
									key={responsable?.id}
									writePermission={
										!cooperation && writePermissions[responsable.jsonschema_id]
									}
									human={responsable}
								/>
							))}
						</Flex>
					)
				},
				enableSorting: false,
			},
			{
				header: "Membres",
				accessorKey: "membres",
				cell: ({ row, table }) => {
					const isExpanded = row.getIsExpanded()
					return (
						<SStyledCell $isExpanded={isExpanded}>
							<PrimaryTextButton
								endIcon={isExpanded ? <ExpandLess /> : <ExpandMore />}
								fullWidth
								onClick={() => {
									if (isExpanded) {
										row.toggleExpanded()
										return
									}
									table.toggleAllRowsExpanded(false)
									row.toggleExpanded()
								}}
							>
								Voir {isExpanded ? "moins" : "plus"}
							</PrimaryTextButton>
						</SStyledCell>
					)
				},
				...width180,
				enableSorting: false,
				meta: {
					expandableCell: true,
				},
			},
		] as ColumnDef<any>[]
	}, [])
	return (
		<>
			<SuperReactTable
				columns={columns}
				data={orgaArray}
				actionsButtons={
					!cooperation &&
					permissions.write && (
						<Button
							color="primary"
							onClick={() => {
								setInitialCellule(undefined)
								setIsOpenModalCell(true)
							}}
							startContent={<AddIcon />}
						>
							Ajouter une cellule
						</Button>
					)
				}
				writePermission={permissions.write}
				isEditable={!cooperation && permissions.write}
				onEditClick={(original) => {
					setInitialCellule(original)
					setIsOpenModalCell(true)
				}}
				isDeletable={permissions.write && !cooperation}
				onDeleteClick={cooperation ? undefined : handlers.delete}
				onDeleteAllClick={cooperation ? undefined : handlers.deleteAll}
				orderConfig={{
					order: order,
					onOrder: cooperation ? undefined : handlers.order,
					preOrder: {
						order: ORGA_CATEGORIES,
						accessorFn: (item) => item.category,
					},
				}}
				RenderSubRow={OrgaSubRow}
				infiniteSize={isMobile}
			/>
			<EditCell
				isOpen={isOpenModalCell}
				setIsOpen={setIsOpenModalCell}
				orga={orga}
				initialCellule={initialCellule}
				updateCommuneOrga={cooperation ? undefined : handlers.edit}
			/>
		</>
	)
}

export default Organigramme
