import { Checkbox, FormControlLabel, Paper } from "@mui/material"
import Warning from "@mui/icons-material/Warning"
import Alert from "@mui/material/Alert"
import { setRisks, updateCommune } from "@/redux-toolkit/commune/ressources"
import React, { useMemo, useState, useEffect } from "react"
import { connect } from "react-redux"
import styled from "styled-components"
import CustomTabs from "utils/components/customTabs/CustomTabs"
import { GreenButton, PrimaryButton } from "utils/components/style/button"
import { Flex } from "utils/components/style/flex"
import RiskService from "utils/services/RiskService"
import _ from "lodash"
import ErrorService from "@/services/ErrorService"
import useJsonSchemas from "@/hooks/useJsonSchemas"
import { RISQUES } from "utils/menuTab"
import { useDispatch } from "react-redux"
import FSelect from "utils/form/FSelect"
import { FormProvider, useForm } from "react-hook-form"
import useCommune from "@/hooks/useCommune"
import useIsAdminNumerisk from "@/hooks/useIsAdminNumerisk"
import { Spacer } from "@nextui-org/react"
const Container = styled.div`
    padding-top: 1em;
    height: 100%;
    overflow: auto;
`
const StyledContent = styled.div`
    margin-left: 2rem;
`
const SPaper = styled(Paper)`
`
const ConfigRisk = ({ risks, setRisks }) => {
	const commune = useCommune()
	const dispatch = useDispatch()
	const isAdminNumerisk = useIsAdminNumerisk()
	const methods = useForm({
		defaultValues: {
			licence: commune.coda["licence"],
		},
	})

	const onSumbit = (data) => {
		dispatch(
			updateCommune({
				coda: {
					...commune.coda,
					licence: data.licence,
				},
			}),
		)
	}

	const [tmpRisks, setTmpRisks] = useState(risks)
	const jsonSchemas = useJsonSchemas()

	useEffect(() => {
		setTmpRisks(risks)
	}, [risks])

	useEffect(() => {
		methods.reset({
			licence: commune.coda["licence"],
		})
	}, [commune.coda])

	const saveRisks = () => {
		const risksList = RiskService.getRisquesList()
		let riskIncludedInJsonSchema = false
		Object.values(jsonSchemas).forEach((schema) => {
			if (
				schema.main_category === RISQUES &&
				risksList.includes(schema.sub_category) &&
				!tmpRisks.includes(schema.sub_category)
			) {
				riskIncludedInJsonSchema = true
			}
		})
		if (riskIncludedInJsonSchema) {
			ErrorService.error({
				component: "configuration:Welcome",
				message:
					"Un formulaire est affilié à un risque que vous souhaitez retirer, veuillez contacter un administrateur",
				dispatch,
			})
			setTmpRisks(risks)
			return
		}
		setRisks(tmpRisks)
	}

	const allRisks = RiskService.getRisques()
	return (
		<Container>
			<h1>Configuration des risques</h1>
			{isAdminNumerisk && (
				<Flex>
					<FormProvider {...methods}>
						<FSelect
							name="licence"
							options={[
								"Licence DECOUVERTE",
								"Licence STANDARD",
								"Licence PREMIUM",
								"Licence PICS",
							]}
						/>
					</FormProvider>
					<PrimaryButton
						onClick={methods.handleSubmit(onSumbit)}
						disabled={!methods.formState.isDirty}
					>
						Enregistrer le changement de licence
					</PrimaryButton>
				</Flex>
			)}
			<Flex flexEnd>
				<GreenButton disabled={_.isEqual(risks, tmpRisks)} onClick={saveRisks}>
					Sauvegarder les changements
				</GreenButton>
			</Flex>
			<SPaper>
				<CustomTabs
					tabChildrens={Object.keys(allRisks).map((riskCategory) => {
						return {
							key: riskCategory,
							render: (
								<>
									{allRisks[riskCategory].map((risk) => (
										<StyledContent key={risk}>
											<FormControlLabel
												control={
													<Checkbox
														onChange={() => {
															if (tmpRisks.includes(risk)) {
																setTmpRisks(tmpRisks.filter((r) => r !== risk))
															} else {
																setTmpRisks([...tmpRisks, risk])
															}
														}}
														checked={tmpRisks.includes(risk)}
														color="primary"
													/>
												}
												label={risk}
											/>
										</StyledContent>
									))}
								</>
							),
						}
					})}
				/>
			</SPaper>
		</Container>
	)
}
const mapStateToProps = (state) => {
	return {
		risks: state.commune.risks,
	}
}
const mapDispatchToProps = (dispatch) => {
	return {
		setRisks: (risks) => {
			return dispatch(setRisks(risks))
		},
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(ConfigRisk)
