import ModalBody from "@/styles/organisms/Modal/ModalBody"
import { Alert } from "@mui/material"
import React, { useContext, useMemo } from "react"
import SuperModal from "utils/modals/SuperModal"
import TreeService from "utils/services/TreeService"
import { TreeContext } from "../redux/page/context"
import { ModalContent } from "@nextui-org/react"

const ModalKitContent = ({ isOpen, onClose, kit }) => {
	const { state: treeState, dispatch: treeDispatch } = useContext(TreeContext)
	const flatTree = useMemo(() => TreeService.treeToFlat(treeState), [treeState])

	return (
		<SuperModal
			isOpen={isOpen}
			onClose={onClose}
			title={`Contenu du Kit : ${kit.kit_name}`}
		>
			<ModalContent>
				<ModalBody>
					<ul>
						{kit.kit_content?.map((element, index) => {
							const title = flatTree[element]?.title ?? "rien"
							if (title === "rien") {
								return (
									<Alert severity="error">
										{title} - {element}
									</Alert>
								)
							}
							return <li key={title}>{title}</li>
						})}
					</ul>
				</ModalBody>
			</ModalContent>
		</SuperModal>
	)
}

export default ModalKitContent
