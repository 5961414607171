import React, { useMemo, useState } from "react"
import { useFieldArray, useFormContext } from "react-hook-form"
import { Flex } from "utils/components/style/flex"
import moment from "moment"
import SuperReactTable from "utils/components/tables/SuperReactTable/SuperReactTable"
import { width60, widthCustom } from "utils/components/tables/widthProps"
import CustomTooltip from "utils/components/style/CustomTooltip"
import GetAppIcon from "@mui/icons-material/GetApp"
import Delete from "@mui/icons-material/Delete"
import QrCode2Icon from "@mui/icons-material/QrCode2"
import useUploadedFileBuilder from "@/hooks/useUploadedFileBuilder"
import DownloadService from "@/pages/telechargement/services/DownloadService"
import ConfirmationModal from "utils/components/ConfirmationModal"
import FText from "../FText"
import { RowIconButton } from "utils/components/tables/SuperReactTable/SuperReactTable.styled"
import { ColumnDef } from "@tanstack/react-table"
import FDropZone from "../utils/FDropZone"
import { IconButton, TableContainer } from "@mui/material"
import QrCodeModal from "utils/modals/QrCodeModal"
import UploadedFileApi from "@/redux-toolkit/data/uploadedFile/UploadedFileApi"
import ErrorService from "@/services/ErrorService"
import { useDispatch } from "react-redux"

export interface IEventLinkedFile {
	title: string
	commune_id: string
	config: { category: "GESTIONDECRISE" }
	extension: string
	filename: string
	id: string
	updated_at: string
}

const FUpload = ({
	values,
	name,
	disabled = false,
}: {
	values: IEventLinkedFile[]
	name: string
	disabled?: boolean
}) => {
	const { control, setValue, watch } = useFormContext()
	const { insert, remove } = useFieldArray({
		control,
		name,
	})
	watch(name)

	const [qrcodeUrl, setQrcodeUrl] = useState("")
	const [indexToDelete, setIndexToDelete] = useState(undefined)

	const getDownloadURL = async (file) => {
		const url = await DownloadService.getDownloadUrl(file.id)
		try {
			await Promise.all(url)
		} catch (e) {
			console.error("An error as occured :" + e)
		}
		return url
	}

	const columns = useMemo(() => {
		return [
			{
				header: "Format",
				accessorKey: "format",
				cell: ({ cell: { row: { index } } }) => {
					return <>{useUploadedFileBuilder(values[index]).icon}</>
				},
				...widthCustom(120),
			},
			{
				header: "Nom",
				accessorKey: "name",
				cell: ({ cell: { row: { index } } }) => {
					return <>{values[index].filename}</>
				},
			},
			{
				header: "Date",
				accessorKey: "date",
				cell: ({ cell: { row: { index } } }) => {
					return (
						<>{moment(values[index].updated_at).format("DD/MM/YYYY HH:mm")}</>
					)
				},
			},
			{
				header: "Titre",
				accessorKey: "title",
				cell: ({ cell: { row: { index } } }) => {
					return (
						<FText
							size="small"
							name={`${name}.${index}.title`}
							label="Titre de la pièce jointe"
							displayNumberCharacter
							disabled={disabled}
						/>
					)
				},
			},
			{
				header: "",
				accessorKey: "qrcode",
				enableSorting: false,
				isAction: true,
				cell: ({ cell: { row: { index } } }) => {
					return (
						<RowIconButton
							onClick={() => {
								getDownloadURL(values[index]).then((url) => {
									setQrcodeUrl(url)
								})
							}}
						>
							<CustomTooltip title="Afficher QR-Code">
								<QrCode2Icon />
							</CustomTooltip>
						</RowIconButton>
					)
				},
				...width60,
			},
			{
				header: "",
				accessorKey: "download",
				enableSorting: false,
				isAction: true,
				cell: ({ cell: { row: { index } } }) => {
					return (
						<RowIconButton
							onClick={() => {
								// Linked file download process
								getDownloadURL(values[index]).then((url) => {
									window.open(url, "_blank").focus()
								})
							}}
						>
							<CustomTooltip title="Télécharger">
								<GetAppIcon />
							</CustomTooltip>
						</RowIconButton>
					)
				},
				...width60,
			},
			{
				header: "",
				accessorKey: "delete2",
				enableSorting: false,
				isAction: true,
				cell: ({ row: { index } }) => {
					return (
						<>
							{!disabled && (
								<IconButton
									onClick={() => {
										setIndexToDelete(index)
									}}
								>
									<CustomTooltip title="Supprimer">
										<Delete />
									</CustomTooltip>
								</IconButton>
							)}
						</>
					)
				},
				...width60,
			},
		] as ColumnDef<any>[]
	}, [values])

	const toggleOff = (type: "qrcode" | "delete") => {
		if (type === "qrcode") {
			setQrcodeUrl("")
		} else if (type === "delete") {
			setIndexToDelete(undefined)
		} else return
	}

	return (
		<Flex fullWidth directionColumn alignItemsStart gap=".5em">
			<FDropZone
				category="GESTIONDECRISE"
				onDropFinal={(res) => {
					return insert(values?.length, {
						title: "",
						...res,
					})
				}}
			/>
			{values?.length > 0 && (
				<TableContainer>
					<SuperReactTable
						noDefer
						$secondaryTableStyle
						data={values}
						columns={columns}
						infiniteSize
						showBottom={false}
						isEditable={false}
						isDeletable={false}
						simpleTable
						selectable={false}
					/>
				</TableContainer>
			)}
			<QrCodeModal
				isOpen={!!qrcodeUrl}
				url={qrcodeUrl}
				toggleOff={() => toggleOff("qrcode")}
			/>
			<ConfirmationModal
				modalShown={indexToDelete !== undefined}
				onClose={() => toggleOff("delete")}
				onConfirm={() => {
					toggleOff("delete")
					remove(indexToDelete)
					UploadedFileApi.deleteUploadedFile(values[indexToDelete].id)
				}}
				message={"Voulez-vous vraiment supprimer cette pièce jointe ?"}
			/>
		</Flex>
	)
}

export default FUpload
