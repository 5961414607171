import Comment from "@mui/icons-material/Comment"
import Cyclone from "@mui/icons-material/Cyclone"
import FormatListNumbered from "@mui/icons-material/FormatListNumbered"
import KeyboardCommandKey from "@mui/icons-material/KeyboardCommandKey"
import Lightbulb from "@mui/icons-material/Lightbulb"
import PsychologyAlt from "@mui/icons-material/PsychologyAlt"
import Warning from "@mui/icons-material/Warning"
import { Avatar, Divider, Paper } from "@mui/material"
import { Progress as SProgress } from "@nextui-org/react"
import { setCurrentIntervention } from "@/redux-toolkit/edit/reducer"
import React, { useEffect, useState } from "react"
import Axios from "axios"
import { useFormContext } from "react-hook-form"
import { useDispatch } from "react-redux"
import _ from "lodash"
import styled, { css } from "styled-components"
import {
	OrangeOutlinedButton,
	RedOutlinedButton,
} from "utils/components/style/button"
import { Flex } from "utils/components/style/flex"
import { H5, H6 } from "utils/components/style/header"
import useSelectedEvent from "@/hooks/useSelectedEvent"
import Loading from "utils/Loading"

const Progress = SProgress as any

const SPaper = styled(Paper)<{ $expanded: boolean }>`
    transition: all 0.3s ease-in-out !important;
    background-color: var(--neutral50) !important;
    border-radius: 0.5rem;
    flex-grow: 1;
    max-height: 73vh;
	min-height: 73vh;
    overflow-y: auto;
    width: 0px;
    padding: 0px;
    margin: 0px;
    overflow-x: hidden;
    ${({ $expanded }) =>
			$expanded &&
			css`
        width: 400px;
        overflow-x: auto;
        padding: 1rem;
        margin: 0.5rem;
    `}
`
const Line = styled.div`
    height: 1px;
    background-color: var(--neutral500);
    margin: 0.5rem 0;
    width: 100%;
`
const Content = styled.div`
    padding: 0.5rem;
    border: 1px solid var(--neutral500);
    border-radius: 0.5rem;
    margin: 0.5rem;
    flex-grow: 1;
    font-size: 0.8rem;
	background-color: white;
    * {
        font-size: 0.8rem;
    }
`
const SAvatar = styled(Avatar)<{ $color: string }>`
    background-color: ${(props) => props.$color};
    width: 1.5rem;
    height: 1.5rem;
    font-size: 0.8rem;
    width: 30px !important;
    height: 30px !important;
    svg {
        width: 0.9rem;
        height: 0.9rem;
    }
`

const sleep = (ms: number) => {
	return new Promise((resolve) => setTimeout(resolve, ms))
}

const OpenAiIncident = ({
	shouldAskGpt,
	setShouldAskGpt,
	setIsLoading,
	isLoading,
}) => {
	const [progress, setProgress] = useState(0)
	const [openAiResponse, setOpenAiResponse] = useState<any>(null)
	const dispatch = useDispatch()
	const { getValues } = useFormContext()
	const selectedEvent = useSelectedEvent()
	const askGpt = async () => {
		setIsLoading(true)
		setOpenAiResponse({})
		const response = await Axios.get("/api/openai", {
			params: {
				content: getValues()["Objet"] + getValues()["Détails"],
				type: shouldAskGpt,
				eventId: selectedEvent.id,
			},
			timeout: 120000 * 2,
		})
		// const response = {
		// 	data: {
		// 		response: {
		// 			"Cellule Communication à la population": {
		// 				raisonnement:
		// 					"La population doit être informée de l'ouverture des gymnases et des disponibilités pour les personnes en besoin.",
		// 				proposition:
		// 					"Diffuser un message d'information sur l'ouverture des gymnases Brizeux à Vannes et Lorient, et préciser la capacité d'accueil.",
		// 				impact:
		// 					"Permettre aux personnes concernées de se rendre dans les gymnases pour s'y abriter.",
		// 				risque:
		// 					"Manque de communication et faible fréquentation des gymnases.",
		// 				commentaire:
		// 					"Le message doit être clair et concis et indiquer les disponibilités des gymnases.",
		// 				exemple:
		// 					"Les gymnases Brizeux à Vannes et Lorient sont ouverts pour accueillir les personnes affectées par la tempête. Capacité d'accueil : 8 personnes par nuit environ. Merci de partager l'information.",
		// 				type: "message",
		// 				confidence: 95,
		// 				command: {
		// 					name: "SEND_SMS_ALERT",
		// 					args: {
		// 						message:
		// 							"Les gymnases Brizeux à Vannes et Lorient sont ouverts pour accueillir les personnes affectées par la tempête. Capacité d'accueil : 8 personnes par nuit environ. Merci de partager l'information.",
		// 					},
		// 				},
		// 			},
		// 		},
		// 	},
		// }
		// await sleep(5000)

		setOpenAiResponse(response.data.response)
		setIsLoading(false)
	}

	useEffect(() => {
		if (shouldAskGpt) {
			askGpt()
			setShouldAskGpt(false)
		}
	}, [shouldAskGpt])
	// simulate progress augmenting for 2 minutes
	useEffect(() => {
		if (isLoading) {
			const interval = setInterval(() => {
				setProgress((progress) => {
					if (progress >= 100) {
						clearInterval(interval)
						return 100
					}
					return progress + 1
				})
			}, 120000 / 100)
			return () => clearInterval(interval)
		}
	}, [isLoading])
	return (
		<SPaper $expanded={!!openAiResponse}>
			{_.isEmpty(openAiResponse) && isLoading && (
				<Flex directionColumn fullHeight justifyCenter>
					<Loading />
					Patientez pendant que nous interrogeons chatGPT...
					<Progress color="success" size="md" value={progress} />
				</Flex>
			)}
			{openAiResponse && (
				<>
					{Object.keys(openAiResponse).map((cellule) => (
						<Flex directionColumn gap="0.5rem" alignItemsInitial>
							<H6>{cellule}</H6>
							{[
								{
									key: "raisonnement",
									label: "Raisonnement",
									icon: <PsychologyAlt />,
									color: "var(--primary500)",
								},
								{
									key: "proposition",
									label: "Proposition",
									icon: <Lightbulb />,
									color: "var(--primary500)",
								},
								{
									key: "impact",
									label: "Impact",
									icon: <Cyclone />,
									color: "var(--primary500)",
								},
								{
									key: "risque",
									label: "Risque",
									icon: <Warning />,
									color: "var(--primary500)",
								},
								{
									key: "commentaire",
									label: "Commentaire",
									icon: <Comment />,
									color: "var(--primary500)",
								},
								{
									key: "exemple",
									label: "Exemple",
									icon: <FormatListNumbered />,
									color: "var(--primary500)",
								},
								{
									key: "command",
									label: "Action",
									icon: <KeyboardCommandKey />,
									color: "var(--primary500)",
								},
							].map(({ key, label, icon, color }) => {
								if (typeof openAiResponse[cellule][key] === "object") {
									if (key === "command") {
										const args = openAiResponse[cellule]?.[key]?.["args"]
										if (
											openAiResponse?.[cellule]?.[key]?.["name"] ===
											"CREATE_INTERVENTION"
										) {
											return (
												<Flex alignItemsInitial gap={"1rem"}>
													<SAvatar $color={color}>{icon}</SAvatar>
													<Flex
														directionColumn
														gap={"0.5rem"}
														alignItemsInitial
													>
														<Content>
															<H6>{label}</H6>
															<Line />
															<OrangeOutlinedButton
																onClick={() => {
																	dispatch(
																		setCurrentIntervention({
																			Objet: args["type"],
																			Détails: args["description"],
																		}),
																	)
																}}
															>
																Créer une intervention : {args["type"]}
															</OrangeOutlinedButton>
														</Content>
													</Flex>
												</Flex>
											)
										} else {
											return (
												<Flex alignItemsInitial gap={"1rem"}>
													<SAvatar $color={color}>{icon}</SAvatar>
													<Flex
														directionColumn
														gap={"0.5rem"}
														alignItemsInitial
													>
														<Content>
															<H6>{label}</H6>
															<Line />
															<RedOutlinedButton
																size="small"
																onClick={() => {
																	dispatch(
																		setCurrentIntervention({
																			Objet: args?.["type"],
																			Détails: args?.["message"],
																		}),
																	)
																}}
															>
																Alerter la population :{" "}
																{openAiResponse[cellule]?.[key]?.["name"] ===
																"SEND_SMS_ALERT"
																	? "par SMS"
																	: "sur les réseaux sociaux"}
															</RedOutlinedButton>
														</Content>
													</Flex>
												</Flex>
											)
										}
									} else {
										return null
									}
								}
								return (
									<Flex alignItemsInitial gap={"1rem"}>
										<SAvatar $color={color}>{icon}</SAvatar>
										<Flex directionColumn gap={"0.5rem"} alignItemsInitial>
											<Content>
												<Flex directionColumn>
													<H6>{label}</H6>
													<Line />
													{openAiResponse[cellule][key]}
												</Flex>
											</Content>
										</Flex>
									</Flex>
								)
							})}
						</Flex>
					))}
				</>
			)}
		</SPaper>
	)
}

export default OpenAiIncident
