import ErrorPermissions, {
	BACK_PATH_HOME,
	BACK_TITLE_HOME,
	TOOL_ERROR_PERMISSION,
} from "@/hooks/ErrorPermissions"
import { EnumToolPath } from "@/hooks/services/useAccessRightsServices"
import useAccessRights from "@/hooks/useAccessRights"
import useIsInterco from "@/hooks/useIsInterco"
import PersistentCustomTabs from "@/utils/components/customTabs/PersistentCustomTabs"
import useKeyboardJs from "@/utils/keyboard/useKeyboardJs"
import React, { useEffect } from "react"
import { useSelector } from "react-redux"
import { useHistory } from "react-router"
import styled from "styled-components"
import TableCare from "./TableCare"
import TableArretes from "./arretes/TableArretes"
import DaybookFiche from "./fiche/DaybookFiche"
import TableIncident from "./following/TableIncident"
import TableIntervention from "./following/TableIntervention"
import SuiviCards from "./suivi/SuiviCards"

const Wrapper = styled.div``

const DaybookFollowing = () => {
	const permissions = useAccessRights(EnumToolPath.SUIVI_CRISE)
	const permission_incident = useAccessRights(EnumToolPath.INCIDENT)
	const permission_intervention = useAccessRights(EnumToolPath.INTERVENTION)
	const permission_arretes = useAccessRights(EnumToolPath.ARRETE)
	const permission_care = useAccessRights(EnumToolPath.CARE)
	const permission_fiche = useAccessRights(EnumToolPath.FICHE)
	const permissionSuiviPcs = useAccessRights(EnumToolPath.SUIVI_PCS)
	const isInterco = useIsInterco()
	const history = useHistory()
	const currentIntervention = useSelector(
		(state: any) => state.edit.currentIntervention,
	)
	const currentIncident = useSelector(
		(state: any) => state.edit.currentIncident,
	)
	if (!permissions.read) {
		return (
			<ErrorPermissions
				errorMessage={TOOL_ERROR_PERMISSION}
				backTitle={BACK_TITLE_HOME}
				backUrl={BACK_PATH_HOME}
			/>
		)
	}

	const [isPressedTab] = useKeyboardJs("space + tab", true)
	useEffect(() => {
		if (isPressedTab && !currentIntervention && !currentIncident) {
			history.push("/maincourante/carto")
		}
	}, [isPressedTab])

	return (
		<Wrapper>
			<PersistentCustomTabs
				localStorageKey="maincourante:DaybookFollowing"
				tabChildrens={[
					...(permission_fiche?.read
						? [
							{
								key: "Fiche scénario",
								render: <DaybookFiche />,
							},
						]
						: []),
					...(permission_incident?.read
						? [
							{
								key: "Évènements",
								render: <TableIncident />,
							},
						]
						: []),
					...(permission_intervention?.read
						? [
							{
								key: "Interventions",
								render: <TableIntervention />,
							},
						]
						: []),
					...(permission_arretes?.read && !isInterco
						? [
							{
								key: "Arrêtés municipaux",
								render: <TableArretes />,
							},
						]
						: []),
					...(permission_care?.read
						? [
							{
								key: "Centre d’accueil",
								render: <TableCare />,
							},
						]
						: []),
					...(permissionSuiviPcs?.read
						? [
							{
								key: "Aide aux populations",
								render: <SuiviCards />,
							},
						]
						: []),
				]}
			/>
		</Wrapper>
	)
}

export default DaybookFollowing
