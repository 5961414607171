import useCooperationFilters from "@/hooks/useCooperationFilters"
import {
	SearchMode,
	SearchView,
} from "@/redux-toolkit/edit/cooperationFiltersRessources"
import React, { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import styled from "styled-components"
import PageContainer from "@/utils/components/layout/PageContainer"
import SharedMap from "./components/SharedMap"
import SharedOrga from "./components/SharedOrga"
import SharedTable from "./components/SharedTable"
import CoopToolBar from "./components/CoopToolBar"
import PointsApi from "@/redux-toolkit/data/points/PointsApi"
import useAllPoints from "@/hooks/useAllPoints"
import _ from "lodash"
import useCdcSharedJsonSchemas from "@/hooks/useCdcSharedJsonSchemas"
import useJsonSchemasWithStatics from "@/hooks/useJsonSchemasWithStatics"
import JsonSchemaService from "@/pages/carto2/cartographie/service/JsonSchemaService"
import IPoint from "@/utils/types/IPoint"
import { setCooperationFilters } from "@/redux-toolkit/edit/reducer"
import { useDispatch } from "react-redux"
import useLayerFilter from "./hooks/useLayerFilter"
import useTagFilter from "./hooks/useTagFilter"
import useCommuneFilter from "./hooks/useCommuneFilter"
import useStartSearch from "./hooks/useStartSearch"

const MapContainer = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    overflow: hidden;
`

const ToolbarContainer = styled.div`
    position: sticky;
    z-index: 49;
    top: 0;
    padding-bottom: 1rem;
`

const SharedData = () => {
	const coopFilters = useCooperationFilters()
	const { page } = useParams<{ page: SearchMode }>()
	const startSearch = useStartSearch()
	const allPoints = useAllPoints()
	const layerFilter = useLayerFilter()
	const tagFilter = useTagFilter()
	const communeFilter = useCommuneFilter()
	const [asSwitch, setAsSwitch] = useState(false)
	const cdcJsonSchemas = useCdcSharedJsonSchemas()
	const jsonSchemasWithStatics = useJsonSchemasWithStatics()
	const allJsonSchemas = { ...jsonSchemasWithStatics, ...cdcJsonSchemas }
	const dispatch = useDispatch()
	const [allPointsWithShared, setAllPointsWithShared] = useState<
		Record<string, IPoint>
	>({})
	const cdcSharedJsonSchemas = useCdcSharedJsonSchemas()

	const allPointsWithSharedFiltered = Object.values(allPointsWithShared)
		.filter(layerFilter)
		.filter(tagFilter)
		.filter(communeFilter)

	useEffect(() => {
		startSearch(page)
	}, [page])

	useEffect(() => {
		if (!coopFilters.search) {
			startSearch(page)
		}
	}, [coopFilters])

	useEffect(() => {
		const getCdcSharedPoints = async () => {
			const promise = await PointsApi.getCdcSharedPoints(
				Object.keys(cdcSharedJsonSchemas),
			)
			Promise.resolve(promise).then((res) =>
				setAllPointsWithShared({ ...allPoints, ...res.shared_points }),
			)
		}

		if (
			!_.isEmpty(cdcSharedJsonSchemas) &&
			coopFilters.search !== SearchMode.DONNEES_PARTAGEES
		) {
			getCdcSharedPoints()
		} else {
			setAllPointsWithShared(allPoints)
		}
	}, [coopFilters.search, cdcSharedJsonSchemas])

	useEffect(() => {
		// if there is marker and no geoloc, then redirect to table view
		const shouldSwitch =
			allPointsWithSharedFiltered.length > 0 &&
			allPointsWithSharedFiltered.every((point) => {
				const jsonSchema = allJsonSchemas[point.jsonschema_id]
				if (!jsonSchema) {
					return false
				} else {
					const geolocProperty = JsonSchemaService.getGeolocProperty(jsonSchema)
					return !point.geojson.properties[geolocProperty?.name]
				}
			})
		if (allPointsWithSharedFiltered.length > 0 && shouldSwitch && !asSwitch) {
			setAsSwitch(true)
			dispatch(
				setCooperationFilters({ ...coopFilters, view: SearchView.TABLEAU }),
			)
		}
	}, [coopFilters, allPointsWithSharedFiltered, allJsonSchemas, asSwitch])

	useEffect(() => {
		if (asSwitch) {
			setAsSwitch(false)
		}
	}, [coopFilters.layer])

	return (
		<PageContainer
			relative
			flex
			reducePadding={coopFilters.view === SearchView.CARTE}
		>
			<ToolbarContainer>
				<CoopToolBar />
			</ToolbarContainer>
			{coopFilters.view === SearchView.CARTE ? (
				<MapContainer>
					<SharedMap allPointsWithShared={allPointsWithSharedFiltered} />
				</MapContainer>
			) : (
				<>
					{coopFilters.search == SearchMode.ORGANIGRAMMES ? (
						<SharedOrga />
					) : (
						<SharedTable allPointsWithShared={allPointsWithSharedFiltered} />
					)}
				</>
			)}
		</PageContainer>
	)
}

export default SharedData
