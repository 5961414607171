import MapService from "@/pages/carto2/cartographie/service/MapService"
import ActionEditor from "@/pages/customdata/fiches/ActionEditor"
import useSelectedEvent from "@/hooks/useSelectedEvent"
import React, { useEffect } from "react"
import { isMobile } from "react-device-detect"
import { useSelector } from "react-redux"
import { useLocation } from "react-router"
import PanelCarto, { DAYBOOK_VORTEX_STATION } from "./PanelCarto"

const Panels = () => {
	const currentAction = useSelector((state: any) => state.edit.currentAction)
	const panel = useSelector((state: any) => state.common.panel)
	const selectedEvent = useSelectedEvent()
	const location = useLocation()
	useEffect(() => {
		if (
			panel &&
			(selectedEvent || location.pathname.includes("/cartographie"))
		) {
			const wantedWidth = panel.type === DAYBOOK_VORTEX_STATION ? 600 : 400
			document.documentElement.style.setProperty(
				"--panel-width",
				isMobile ? "100%" : `${wantedWidth}px`,
			)
			return
		}
		document.documentElement.style.setProperty("--panel-width", "0px")
	}, [currentAction, panel, selectedEvent])
	return (
		<>
			<PanelCarto
				isOpen={
					panel &&
					(location.pathname.includes("/maincourante/carto") ||
						location.pathname.includes("/cartographie"))
				}
			/>
			<ActionEditor />
		</>
	)
}
export default Panels
