import React from "react"
import { Route } from "react-router-dom"
import SharedData from "./customdata/SharedData"
import useAccessRights from "@/hooks/useAccessRights"
import { COOPERATION } from "@/redux-toolkit/userSettings/constants"
import ErrorPermissions, {
	BACK_PATH_HOME,
	BACK_TITLE_HOME,
	TOOL_ERROR_PERMISSION,
} from "@/hooks/ErrorPermissions"
import { EnumToolPath } from "@/hooks/services/useAccessRightsServices"

const Cooperation = () => {
	const readPermission = useAccessRights(EnumToolPath.COOPERATION).read

	if (!readPermission) {
		return (
			<ErrorPermissions
				errorMessage={TOOL_ERROR_PERMISSION}
				backTitle={BACK_TITLE_HOME}
				backUrl={BACK_PATH_HOME}
			/>
		)
	}

	return (
		<Route path="/cooperation/:page">
			<SharedData />
		</Route>
	)
}
export default Cooperation
