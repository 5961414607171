import React, { useState, useEffect, useMemo } from "react"
import Debug from "debug"
import { useSelector, useDispatch } from "react-redux"
import styled from "styled-components"
import { Card, ListItem } from "@mui/material"
import List from "@mui/icons-material/List"
import { Flex } from "utils/components/style/flex"

// app:javascript:components:admin:formulaire_dynamique:MyForms:CustomCardStatDons.tsx
const debug = Debug(
	"app:javascript:components:admin:formulaire_dynamique:MyForms:CustomCardStatDons",
)
debug.log = console.log.bind(console)

const CustomCardStatDons2 = ({ items }) => {
	const categories = {}
	items.forEach((item) => {
		const currentType = item.geojson.properties["Type"]
		if (!categories[currentType]) {
			categories[currentType] = 0
		}
		let quantites = item.geojson.properties?.["Quantité(s)"] ?? 0
		let boites = item.geojson.properties?.["Boite(s)"] ?? 1
		if (quantites === "") {
			quantites = "0"
		}
		if (boites === "") {
			boites = "1"
		}
		if (!parseInt(quantites) || !parseInt(boites)) {
			categories[currentType] = 0
		}
		categories[currentType] += parseInt(quantites) * parseInt(boites)
	})
	if (Object.keys(categories).length === 0) {
		return null
	}
	return (
		<Card>
			{Object.keys(categories)
				.sort(function (a, b) {
					return a > b ? 1 : a === b ? 0 : -1
				})
				.map((category) => (
					<ListItem key={category}>
						<Flex spaceBetween fullWidth gap="2rem">
							<div>{category}</div>
							<div>{categories[category]}</div>
						</Flex>
					</ListItem>
				))}
		</Card>
	)
}

export default CustomCardStatDons2
