import { Flex } from "@/utils/components/style/flex"
import Drawer from "@mui/material/Drawer"
import { default as React, useEffect, useState } from "react"
import { isMobile } from "react-device-detect"
import styled, { css } from "styled-components"
import EventsStatusBar from "../EventsStatusBar"
import SynchronisationStatusBar from "../SynchronisationStatusBar"
import SideBarDesktopContent from "./SideBarDesktopContent"
import SideBarMobileContent from "./SideBarMobileContent"
import { useSideBarTools } from "./SideBar.hook"
import { useDispatch, useSelector } from "react-redux"
import { setActiveTool } from "@/redux-toolkit/common/reducer"
import { useHistory, useLocation } from "react-router-dom"
import useOpenAppBar from "@/hooks/useOpenAppBar"
import useEvents from "@/hooks/useEvents"
import IEvent from "@/utils/types/IEvent"
import {
	Card,
	CardBody,
	CardFooter,
	CardHeader,
	Divider,
	Image,
	Link,
	User,
} from "@nextui-org/react"
import useSelectCrisis from "@/hooks/useSelectCrisis"
import useSelectedEvent from "@/hooks/useSelectedEvent"
import Moment from "moment"
import { toast } from "react-toastify"
import VigilanceTag from "@/pages/picrim/dicrim/vigilances/VigilanceTag"
import { VigilanceColor } from "@/utils/types/IFiche"

const SDrawer = styled(Drawer)`
    transition: all 0.3s ease;
    flex-shrink: 0;
    white-space: nowrap;
	z-index: 48 !important;
    width: var(--width-navbar);
    && .MuiPaper-root {
		transition: all 0.3s ease;
        width: calc(var(--width-navbar)) !important;
    }
    box-shadow: var(--cool-shadow) !important;
    .MuiDrawer-paperAnchorDockedLeft {
        border: none !important;
    }
` as any

const StyledFlex = styled(Flex)`
    height: 100%;
`
const SCard = styled(Card)<{ $isActive: boolean }>`
	${({ $isActive }) => {
		if ($isActive) {
			return css`
				/* background-image: linear-gradient(45deg, var(--primary300) 0%, var(--primary500) 150%); */
				border: 2px solid var(--primary500);
				& * {
					/* color: white; */
				}
			`
		}
	}}
`
const MobileContainer = styled.div<{ $isOpen: boolean }>`
	transition: all 0.3s ease;
	overflow: hidden;
	margin-top: var(--height-top-bar);
	max-height: calc(100vh - var(--height-top-bar));
	overflow-y: auto;
	width: 100%;
	height: 100%;
	background-color: white;
	width: ${({ $isOpen }) => ($isOpen ? "calc(var(--width-navbar))" : "0")};
	/* ${({ $isOpen }) => ($isOpen ? "" : "position: absolute;")}; */
	// opacity
	${({ $isOpen }) => ($isOpen ? "opacity: 1;" : "opacity: 0;")};
`
const VigilanceSquare = styled.div<{ $color: string }>`
	background-color: ${({ $color }) => VigilanceColor[$color]};
	width: 1.5rem;
	height: 1rem;
	border-radius: 0.25rem;
	box-shadow: var(--cool-shadow);
`
const SideBar = () => {
	const [openIndex, setOpenIndex] = useState(undefined)
	const activeTool = useSelector((state: any) => state?.common?.activeTool)
	const [isOpenAppBar, setIsOpenAppBar] = useOpenAppBar()
	const dispatch = useDispatch()
	const location = useLocation()
	const events = useEvents()
	const selectCrisis = useSelectCrisis()
	const history = useHistory()
	const selectedEvent = useSelectedEvent()

	const toolsList = useSideBarTools()

	useEffect(() => {
		toolsList.forEach((tool, index) => {
			if (tool.route) {
				if (
					tool.route.includes(location.pathname) &&
					activeTool !== location.pathname
				) {
					dispatch(setActiveTool(tool.route))
				}
			}
			if (tool.subMenus) {
				tool.subMenus.forEach((subMenu, subMenuIndex) => {
					if (
						location.pathname.includes(subMenu.route) &&
						activeTool !== location.pathname
					) {
						dispatch(setActiveTool(subMenu.route))
					}
				})
			}
		})
	}, [location])

	return (
		<>
			{!isMobile && !isOpenAppBar && (
				<SDrawer variant={isMobile ? null : "permanent"} elevation={3}>
					<StyledFlex directionColumn spaceBetween fullWidth>
						<SideBarDesktopContent
							setOpenIndex={setOpenIndex}
							openIndex={openIndex}
							toolsList={toolsList}
						/>
						<Flex fullWidth gap="0.5rem" directionColumn>
							<EventsStatusBar />
							<SynchronisationStatusBar />
						</Flex>
					</StyledFlex>
				</SDrawer>
			)}
			<MobileContainer $isOpen={isOpenAppBar}>
				<Flex directionColumn spaceBetween fullHeight>
					{isOpenAppBar && (
						<SideBarMobileContent
							toolsList={toolsList}
							openIndex={openIndex}
							setOpenIndex={setOpenIndex}
						/>
					)}
					<Flex directionColumn fullWidth>
						{Object.values(events)
							.filter((crisisEvent: IEvent) => crisisEvent.active)
							.map((crisisEvent: IEvent) => (
								<SCard
									className="w-full m-2 w-[calc(100%-1rem)]"
									isPressable
									shadow="sm"
									$isActive={selectedEvent?.id === crisisEvent.id}
									onClick={() => {
										if (
											window.location.pathname.includes("alerte/") &&
											crisisEvent.id !== selectedEvent?.id
										) {
											history.goBack()
										}
										selectCrisis(crisisEvent)
										history.push("/maincourante/dashboard")
										if (isMobile) {
											setIsOpenAppBar(false)
										}
									}}
								>
									<CardBody>
										<p>{crisisEvent.name}</p>
										<Flex gap="0.5rem" className="text-neutral-500 text-xs">
											<VigilanceSquare $color={crisisEvent.vigilance} />
											<div>-</div>
											{Moment(crisisEvent.updated_at).fromNow()}
										</Flex>
									</CardBody>
								</SCard>
							))}
					</Flex>
				</Flex>
			</MobileContainer>
		</>
	)
}

export default SideBar
