import NavigateNextIcon from "@mui/icons-material/NavigateNext"
import {
	Alert,
	DialogActions,
	DialogContent,
	DialogTitle,
	LinearProgress,
	Paper,
} from "@mui/material"
import usePoints from "@/hooks/usePoints"
import _ from "lodash"
import React, { useEffect, useMemo, useState } from "react"
import { PrimaryButton } from "utils/components/style/button"
import buildImportDiff, { IImportDiff } from "../utils/buildImportDiff"
import ConflictsSolverItem from "./ConflictsSolverItem"
import useWhyDidYouUpdate from "@/hooks/useWhyDidYouUpdate"
import { ParentSubmitContext } from "utils/form/specific/fiche/links/utils/parentSubmit/ParentSubmitContext"
import ParentSubmitButtonWrapper from "utils/form/specific/fiche/links/utils/parentSubmit/ParentSubmitButtonWrapper"

const getErroredRows = (diff: IImportDiff) => {
	return Object.values(diff)
		.filter((item) => item.dateWarning && item.status === "changed")
		.map((item) => item.id)
}

const ConflictsSolver = ({
	mapping,
	mappingResults,
	setMappingResults,
	setCurrentStep,
	currentStep,
	jsonSchema,
}) => {
	const points = usePoints()
	const concerned = Object.values(points).filter(
		(point) => point.jsonschema_id === mapping.jsonschema_id,
	)
	const [shouldSubmit, setShouldSubmit] = useState(false)

	const diff = useMemo(() => {
		return buildImportDiff(
			concerned,
			mappingResults,
			mapping,
			jsonSchema.template.properties,
		)
	}, [jsonSchema, mapping, mappingResults])

	const jsonSchemaProperties = jsonSchema.template.properties
	const [tmpDiff, setTmpDiff] = useState<IImportDiff>({})
	const [initalErrorNumber, setInitialErrorNumber] = useState<number>()

	useEffect(() => {
		if (diff) {
			setTmpDiff(diff)
			setInitialErrorNumber(getErroredRows(diff).length)
		}
	}, [diff])

	const solveRowResult =
		(indexToSolve: string) =>
		(values: {
			[key: string]: boolean
		}) => {
			setShouldSubmit(false)
			const newValue = {
				...tmpDiff[indexToSolve].new,
				..._.mapValues(values, (val, key) =>
					val ? tmpDiff[indexToSolve].old[key] : tmpDiff[indexToSolve].new[key],
				),
			}

			setTmpDiff({
				...tmpDiff,
				[indexToSolve]: {
					...tmpDiff[indexToSolve],
					new: newValue,
					dateWarning: false,
					status: _.isEqual(newValue, tmpDiff[indexToSolve].old)
						? "unchanged"
						: "changed",
				},
			})
		}

	useEffect(() => {
		if (!tmpDiff || !Object.values(tmpDiff).length) return

		const nErroredRows = getErroredRows(tmpDiff).length
		if (!nErroredRows) {
			setMappingResults(tmpDiff)
			setCurrentStep(currentStep + 1)
		}
	}, [tmpDiff])

	const erroredRows = getErroredRows(tmpDiff)
	const id = erroredRows[0]

	return (
		<ParentSubmitContext.Provider
			value={{
				onSubmit: solveRowResult(id),
				shouldSubmit,
				setShouldSubmit,
			}}
		>
			<Paper>
				<DialogTitle>
					Résolution des conflits{" "}
					{erroredRows.length ? (
						<>
							({initalErrorNumber - erroredRows.length + 1}/{initalErrorNumber})
						</>
					) : (
						<>(terminée)</>
					)}
				</DialogTitle>
				<LinearProgress
					variant="determinate"
					value={
						((initalErrorNumber - erroredRows.length) / initalErrorNumber) * 100
					}
				/>
				<DialogContent dividers>
					{erroredRows.length ? (
						<ConflictsSolverItem
							key={id}
							erroredItem={diff[id]}
							jsonSchemaProperties={jsonSchemaProperties}
						/>
					) : (
						<Alert severity="success">
							Toutes les données sont a présent valides
						</Alert>
					)}
				</DialogContent>
				<DialogActions>
					<ParentSubmitButtonWrapper>
						{({ onClick }) => (
							<PrimaryButton
								endIcon={<NavigateNextIcon />}
								onClick={() => {
									onClick()
								}}
								disabled={!erroredRows.length}
							>
								Corriger et{" "}
								{erroredRows.length <= 1 ? (
									<>terminer</>
								) : (
									<>passer au suivant</>
								)}
							</PrimaryButton>
						)}
					</ParentSubmitButtonWrapper>
				</DialogActions>
			</Paper>
		</ParentSubmitContext.Provider>
	)
}

export default ConflictsSolver
