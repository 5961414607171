import InsertLinkIcon from "@mui/icons-material/InsertLink"
import React from "react"
import LinkChip from "utils/components/links/LinkChip"
import { IActionLink } from "utils/types/IFiche"
import ActionItemsWrapper from "../components/ActionItemsWrapper"

function ActionLiens({ liens }: { liens: IActionLink[] }) {
	if (!liens?.length) return null

	return (
		<ActionItemsWrapper Icon={InsertLinkIcon}>
			{liens.map((lien) => (
				<LinkChip link={lien} key={JSON.stringify(lien)} />
			))}
		</ActionItemsWrapper>
	)
}

export default ActionLiens
