import { Autocomplete, MenuItem, TextField } from "@mui/material"
import Debug from "debug"
import React from "react"
import { useController, useFormContext, useWatch } from "react-hook-form"
import FSelectWithImg from "utils/form/FSelectWithImg"

// app:javascript:utils:modals:ModalIncident:FModalPriority.tsx
const debug = Debug("app:javascript:utils:modals:ModalIncident:FModalPriority")
debug.log = console.log.bind(console)

const FModalIncidentPriority = ({ disabled = false }) => {
	const { control } = useFormContext()

	const statut = useWatch({ control, name: "Statut" })

	const options = [
		{
			label: "Normale",
			value: "Normale",
			img:
				statut === "En cours"
					? "/img/maincourante/evenement_encours_normale.png"
					: "/img/maincourante/evenement_termine_normale.png",
		},
		{
			label: "Urgente",
			value: "Urgente",
			img:
				statut === "Terminé"
					? "/img/maincourante/evenement_termine_urgente.png"
					: "/img/maincourante/evenement_encours_urgente.png",
		},
	]
	return (
		<FSelectWithImg options={options} name="Priorité" disabled={disabled} />
	)
}

export default FModalIncidentPriority
