import createDeepEqualSelector from "@/utils/createDeepEqualSelector"
import { useSelector } from "react-redux"

const selectCurrentAction = createDeepEqualSelector(
	(state: any) => state.edit.currentAction.current,
	(current) => current,
)

const useCurrentAction = () => {
	return useSelector(selectCurrentAction)
}

export default useCurrentAction
