import React from "react"
import { StyledModal } from "utils/components/style/modal"
import { PrimaryTextButton } from "utils/components/style/button"
import { QRCodeSVG } from "qrcode.react"
import styled from "styled-components"
import { isMobile } from "react-device-detect"
import { Flex } from "utils/components/style/flex"
import Modal from "@/styles/organisms/Modal"
import ModalHeader from "@/styles/organisms/Modal/ModalHeader"
import ModalBody from "@/styles/organisms/Modal/ModalBody"
import ModalFooter from "@/styles/organisms/Modal/ModalFooter"
import { ModalContent } from "@nextui-org/react"

const MODAL_SIDE = isMobile ? 256 : 512

const SFlex = styled(Flex)`
    justify-content: center;
`

const QrCodeModal = ({ isOpen, url, toggleOff }) => {
	return (
		<Modal isOpen={isOpen} onClose={toggleOff}>
			<ModalContent>
				<ModalHeader>Téléchargement via QR-Code</ModalHeader>
				<ModalBody>
					<SFlex>
						<QRCodeSVG value={url} size={MODAL_SIDE / 2} />
					</SFlex>
				</ModalBody>
				<ModalFooter>
					<PrimaryTextButton onClick={toggleOff}>Fermer</PrimaryTextButton>
				</ModalFooter>
			</ModalContent>
		</Modal>
	)
}

export default QrCodeModal
