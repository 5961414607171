import { yupResolver } from "@hookform/resolvers/yup"
import useCurrentAction from "@/hooks/useCurrentAction"
import useFiches from "@/hooks/useFiches"
import {
	forceCurrentAction,
	requestCurrentAction,
} from "@/redux-toolkit/edit/reducer"
import React, { useEffect } from "react"
import { FormProvider, useForm } from "react-hook-form"
import { useDispatch } from "react-redux"
import styled from "styled-components"
import FCellules from "utils/form/FCellules"
import FDraft, { emptyHTML } from "utils/form/specific/fiche/FDraft"
import FFlag from "utils/form/specific/fiche/FFlag"
import FLinks from "utils/form/specific/fiche/FLinks"
import SuperPanel from "utils/panels/SuperPanel"
import { IAction, Vigilance } from "utils/types/IFiche"
import Yup from "utils/yup"
import { v4 as uuidv4 } from "uuid"
import SafeForm from "./components/SafeForm"
import useAddAction from "./utils/useAddAction"
import useUpdateAction from "./utils/useUpdateAction"

const schema = Yup.object().shape({
	id: Yup.string().default(() => uuidv4()),
	Contenu: Yup.string().test(
		"not-empty-html",
		"Le contenu de l'action doit être défini",
		(val) => !emptyHTML(val),
	),
	Vigilance: Yup.string().required().default(Vigilance.RED),
	Cellules: Yup.array(Yup.string()).ensure(),
	Liens: Yup.array(Yup.object()).ensure(),
})

const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 1rem;
`

const ActionEditor = () => {
	const dispatch = useDispatch()
	const currentAction = useCurrentAction()
	const fiches = useFiches()

	const fiche = fiches[currentAction?.ficheId]
	const action = fiche?.actions[currentAction.id]

	const methods = useForm({
		resolver: yupResolver(schema),
	})

	const updateAction = useUpdateAction(fiche)
	const addAction = useAddAction(fiche)

	useEffect(() => {
		if (currentAction) {
			if (currentAction.newFrom) methods.reset(schema.cast({}))
			else methods.reset(schema.cast(action))
		}
	}, [currentAction])

	const onSubmit = (values: IAction) => {
		if (!values.Liens?.length) delete values.Liens
		if (!values.Cellules?.length) delete values.Cellules

		if (currentAction.newFrom) {
			addAction(values, currentAction.newFrom)
		} else {
			updateAction(values)
		}
	}

	const onSave = () => {
		methods.handleSubmit((values: IAction) => {
			onSubmit(values)
			dispatch(forceCurrentAction(undefined))
		})()
	}

	return (
		<FormProvider {...methods}>
			<SuperPanel
				title={"Editer une action"}
				isOpen={Boolean(currentAction)}
				onSave={onSave}
				onClose={() => dispatch(requestCurrentAction(undefined))}
				onCancel={() => dispatch(requestCurrentAction(undefined))}
			>
				<Container>
					<FDraft name="Contenu" label="Contenu de l'action" />
					<FFlag name="Vigilance" />
					<FCellules name="Cellules" label="Cellules" />
					<FLinks name="Liens" label="Liens" disabled={!currentAction} />
				</Container>
			</SuperPanel>

			<SafeForm onSubmit={onSubmit} />
		</FormProvider>
	)
}

export default ActionEditor
