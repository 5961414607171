import AddIcon from "@mui/icons-material/Add"
import GetApp from "@mui/icons-material/GetApp"
import { Box, Paper, Typography } from "@mui/material"
import Axios from "axios"
import useAccessRights from "@/hooks/useAccessRights"
import useIsInterco from "@/hooks/useIsInterco"
import { PATH_DOWNLOAD_PCS } from "@/redux-toolkit/userSettings/constants"
import Debug from "debug"
import PropTypes from "prop-types"
import React, { useContext, useEffect, useState } from "react"
import { isDesktop, isMobile } from "react-device-detect"
import { toast } from "react-toastify"
import { useBoolean, useEffectOnce } from "react-use"
import styled from "styled-components"
import CustomTabs from "utils/components/customTabs/CustomTabs"
import PageContainer from "utils/components/layout/PageContainer"
import { useDispatch } from "react-redux"
import {
	PrimaryButton,
	PrimaryOutlinedButton,
	PrimaryTextButton,
} from "utils/components/style/button"
import { Flex } from "utils/components/style/flex"
import TitleHeader from "utils/components/TitleHeader"
import intercoNameAdapter from "utils/intercoNameAdapter"
import ErrorService from "@/services/ErrorService"
import ReorderIcon from "@mui/icons-material/Reorder"
import { TreeContext } from "../redux/page/context"
import { SelectionContext } from "../redux/selection/context"
import { setTree, toggleCheck } from "../redux/tree/action"
import { PageContext } from "../redux/tree/context"
import DownloadService from "../services/DownloadService"
import Pcskits from "../TreeSelection/Pcskits"
import TreeSelection from "../TreeSelection/TreeSelection"
import Cart from "./Cart/Cart"
import ModalKitPCS from "./ModalKitPCS"
import ConfirmationModal from "utils/components/ConfirmationModal"
import ModalWaiting from "utils/modals/ModalWaiting"
import _ from "lodash"
import useIsOnline from "@/hooks/useIsOnline"
import CartographyTemplate from "../services/CartographyTemplate"
import { ScreenShotContext } from "@/pages/maincourante/DaybookDashboard"
import useJsonSchemasWithStatics from "@/hooks/useJsonSchemasWithStatics"
import useMultiPolygons from "@/hooks/useMultiPolygons"
import getImage from "@/utils/getImage"
import ModalWaitingDownloadPcs from "./ModalWaitingDownloadPcs/ModalWaitingDownloadPcs"
import { config } from "process"
import PcsConfigContext from "./context/PcsConfigContext"
import ErrorPermissions, {
	BACK_PATH_HOME,
	BACK_TITLE_HOME,
	FEATURE_ERROR_PERMISSION,
} from "@/hooks/ErrorPermissions"
import { EnumToolPath } from "@/hooks/services/useAccessRightsServices"
import Button from "@/styles/atoms/Button"

const debug = Debug("components:telechargement:Welcome:Welcome")

const SBox = styled(Box)`
  padding: 0 !important;
`
const SPageContainer = styled(PageContainer)`
  z-index: 9999999999999999 !important;
`

function TabPanel(props) {
	const { children, value, index, ...other } = props

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`full-width-tabpanel-${index}`}
			aria-labelledby={`full-width-tab-${index}`}
			{...other}
		>
			{value === index && (
				<SBox p={3}>
					<Typography>{children}</Typography>
				</SBox>
			)}
		</div>
	)
}

const TwoColumns = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: flex-start;
  margin-top: 1rem;
  min-height: 0;
`
const FirstColumn = styled(Paper)`
  flex: 2 2 0;
  padding: 1rem;
  max-height: 100%;
  display: flex;
  flex-direction: column;
`
const SecondColumn = styled(Paper)`
  flex: 1 1 0;
  max-height: 100%;
  display: flex;
`

function resizeBase64Image(
	base64String,
	scale,
	format = "image/jpeg",
	quality = 0.95,
) {
	return new Promise((resolve, reject) => {
		const img = new Image()
		img.src = base64String // Modify the MIME type accordingly

		img.onload = () => {
			let newWidth = img.width / scale
			let newHeight = img.height / scale

			// Create a canvas element and draw the resized image
			const canvas = document.createElement("canvas")
			const ctx = canvas.getContext("2d")
			canvas.width = newWidth
			canvas.height = newHeight
			ctx.drawImage(img, 0, 0, newWidth, newHeight)

			// Convert the canvas content to a base64 string
			const resizedBase64 = canvas.toDataURL(format, quality)
			resolve(resizedBase64)
		}

		img.onerror = () => {
			reject(new Error("Failed to load the image."))
		}
	})
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.any.isRequired,
	value: PropTypes.any.isRequired,
}

const SELECT_PANEL_TITLE = "Sommaire"
const KITS_PANEL_TITLE = "Kits"
const SELECTED_PANEL_TITLE = "Sélection"

const DownloadPCS = (props) => {
	const permissions = useAccessRights(EnumToolPath.DOWNLOAD_PCS)
	const NO_KIT_SELECTED = ""
	const { state: pageState } = useContext(PageContext)
	const { state: treeState, dispatch: treeDispatch } = useContext(TreeContext)
	const [initialTree, setInitialTree] = useState(undefined)
	const { state: selectionState } = useContext(SelectionContext)
	const [isEditing, setIsEditing] = useBoolean(false)
	const [isOpen, setIsOpen] = useBoolean(false)
	const [isOpenModalConfirmation, setIsOpenModalConfirmation] =
		useBoolean(false)
	const [isTakingScreenshotMap, setIsTakingScreenshotMap] = useState(false)
	const [fetchedKits, setFetchedKits] = useState([])
	const [kitSelected, setKitSelected] = useState(undefined as any)
	const [checkKitId, setCheckKitId] = useState(undefined)
	const [oldSelection, setOldSelection] = useState(undefined)
	const [forceTab, setForceTab] = useState(undefined)
	const isInterco = useIsInterco()
	const dispatch = useDispatch()
	const isOnline = useIsOnline()
	const [cartographicSelections, setCartographicSelections] =
		useState(undefined)
	const [providedSelection, setProvidedSelection] = useState(undefined)
	const [selectionsToUpdates, setSelectionsToUpdates] = useState([])
	const [mapScreenBlob, setMapScreenBlob] = useState(null)
	const [modalWaitingOpened, setModalWaitingOpened] = useState(false)
	const jsonSchemas = useJsonSchemasWithStatics()
	const multiPolygons = useMultiPolygons()
	const [keyOrder, setKeyOrder] = useState({})
	const [fileDownloadReady, setFileDownloadReady] = useState(undefined)
	const { config, setConfig } = useContext(PcsConfigContext)

	const updateSelectedKit = (kit) => {
		if (kit || checkKitId) {
			setCheckKitId(kit?.id)
			setKitSelected(kit)
			if (kit?.config) {
				setConfig(kit.config)
			}
		}
	}

	const stopEditing = () => {
		setIsEditing(false)
		setOldSelection(undefined)
	}

	useEffectOnce(() => {
		const getTree = async () => {
			const result = await DownloadService.downloadTree()
			setInitialTree(_.cloneDeep(result.data.tree))
			treeDispatch(setTree(result.data.tree))
		}
		getTree()
	})

	debug(
		"render",
		"props=",
		props,
		"pageState=",
		pageState,
		"treeState=",
		treeState,
	)

	const handleCheck = (keys: string[]) => {
		treeDispatch(toggleCheck(keys))
		const lastKey = _.last(keys)
		if (!_.isUndefined(keyOrder[lastKey])) {
			const { [lastKey]: index, ...newOrder } = keyOrder as any
			setKeyOrder(newOrder)
		}
	}

	const downloadFullPcs = async (currentSelection, kit_name) => {
		const res = await DownloadService.generatePcs(
			Object.values(currentSelection),
			kit_name,
			config,
		)
		const uuid = res.data.uuid
		setTimeout(() => checkStatusPcs({ uuid }), 10000)
	}

	//Awaiting selectionState update when isMobile due to the fact that the cart is maybe displayed in a unloaded tab
	useEffect(() => {
		if (!modalWaitingOpened || _.isEmpty(selectionState)) return
		processBeforeDownload(selectionState)
	}, [modalWaitingOpened, selectionState])

	//STEP 1 : Default process on main download button click
	const processBeforeDownload = (currentSelection) => {
		const tempCartographicSelections = []

		const getCartographicSelections = (currentSelection) => {
			const parseSelectionChildrens = (childSelections, parentKey) => {
				Object.values(childSelections).forEach((selection: any) => {
					if (selection.children) {
						parseSelectionChildrens(
							selection.children,
							`${parentKey}.${selection.key}.children`,
						)
						return
					}
					if (selection.type === "CARTO") {
						//* id must be find if's an uploaded element, else key is used (static element)
						const finalKey = selection.id ? selection.id : selection.key
						tempCartographicSelections.push({
							...selection,
							path: `${parentKey}.${finalKey}`,
						})
					}
				})
			}

			currentSelection.forEach((selection, index) => {
				//* if hasOneSelected, currentSelection array's first parents elements will have index
				//* as key instead of selection.key
				const firstKey = hasOneSelected ? index : selection.key
				if (selection.children) {
					parseSelectionChildrens(selection.children, `${firstKey}.children`)
					return
				}
				if (selection.type === "CARTO") {
					tempCartographicSelections.push({
						...selection,
						path: `${firstKey}`,
					})
				}
			})
		}

		//* Extract into 'cartographicSelections' existing cartographic selections from currentSelection
		getCartographicSelections(currentSelection)

		//* Checking if no cartographic element is selected, else downlaod pcs now
		if (_.isEmpty(tempCartographicSelections)) {
			if (!hasOneSelected) {
				return downloadFullPcs(currentSelection, NO_KIT_SELECTED)
			}
			return isEditing
				? setIsOpenModalConfirmation(true)
				: downloadPCS(
						currentSelection,
						kitSelected ? kitSelected.kit_name : NO_KIT_SELECTED,
					)
		}

		setCartographicSelections(tempCartographicSelections)
	}

	//STEP 2 : Updating state for each cartographic selection to provide his points for screenshot
	useEffect(() => {
		if (!cartographicSelections) return
		if (selectionsToUpdates.length === cartographicSelections.length) return
		//* At least one cartographic element is selected, we start the cartographic process

		const currentIndex = selectionsToUpdates.length
		let selection = _.cloneDeep(cartographicSelections[currentIndex])
		selection.pointsKeys?.forEach((obj) => {
			if (!obj.style) {
				obj.style = {}
			}
			obj.style.iconSize = (obj.style.iconSize ?? 25) * 2
		})
		selection.couchesKeys?.forEach((obj) => {
			if (!obj.style) {
				obj.style = {}
			}
			obj.style.weight = (obj.style.weight ?? 1) * 2
		})

		setProvidedSelection(selection)
	}, [cartographicSelections, selectionsToUpdates])

	//STEP 3 : Start screenshot process
	useEffect(() => {
		if (!providedSelection) return
		setIsTakingScreenshotMap(true)
	}, [providedSelection])

	//STEP 4 : Once the screenshot is taken, we add it to the cartographic element
	useEffect(() => {
		if (!mapScreenBlob) return
		const processBlob = async () => {
			const providedSelectionPointsKeys = providedSelection?.pointsKeys ?? []
			const jsonSchemasLegend = providedSelectionPointsKeys.map(
				(jsonSchema) => {
					if (!jsonSchemas[jsonSchema.id]) return
					return {
						title: jsonSchemas[jsonSchema.id].title,
						src: getImage(jsonSchemas[jsonSchema.id].imgId),
					}
				},
			)
			const couchesKeys = providedSelection?.couchesKeys ?? []
			const multiPolygonsLegend = couchesKeys.map((multiPolygon) => {
				if (!multiPolygons[multiPolygon.id]) return
				return {
					title: multiPolygons[multiPolygon.id].name,
					style: multiPolygon.style,
				}
			})

			let newMapScreenBlob = mapScreenBlob
			if (cartographicSelections.length > 5) {
				newMapScreenBlob = await resizeBase64Image(mapScreenBlob, 2)
			}

			setSelectionsToUpdates([
				...selectionsToUpdates,
				{
					selectionPath: providedSelection?.path
						? `${providedSelection.path}` //? `${providedSelection.path}.${providedSelection?.title}`
						: providedSelection?.title,
					blob: newMapScreenBlob,
					legend: {
						jsonSchemas: jsonSchemasLegend,
						multiPolygons: multiPolygonsLegend,
					},
				},
			])

			setIsTakingScreenshotMap(false)
			setMapScreenBlob(null)
		}
		processBlob()
	}, [mapScreenBlob, cartographicSelections])

	//STEP 5 : Once all the screenshots are taken, we can start to update each cartographic selection, and then download the PCS
	useEffect(() => {
		if (_.isEmpty(selectionsToUpdates)) return
		if (selectionsToUpdates.length < cartographicSelections.length) {
			return
		}

		//* Reminder: selectionState is empty when we download the full PCS, initialTree may be used instead
		const finalSelectionState = _.cloneDeep(
			hasOneSelected ? selectionState : initialTree,
		)
		selectionsToUpdates.map((selection) => {
			//* Adding blob attrubute
			_.set(
				finalSelectionState,
				`${selection.selectionPath}.blob`,
				selection.blob,
			)
			//* Adding legend attribute
			_.set(
				finalSelectionState,
				`${selection.selectionPath}.legend`,
				selection.legend,
			)
		})

		if (!hasOneSelected) {
			downloadFullPcs(finalSelectionState, NO_KIT_SELECTED)
		} else {
			isEditing
				? setIsOpenModalConfirmation(true)
				: downloadPCS(
						finalSelectionState,
						kitSelected ? kitSelected.kit_name : NO_KIT_SELECTED,
					)
		}

		//* Resetting all selections to prevent another download
		setCartographicSelections(undefined)
		setSelectionsToUpdates([])
	}, [selectionsToUpdates])

	const downloadPCS = async (currentSelection, kit_name) => {
		const removeParentKey = (selection) => {
			const { parent, ...rest } = selection
			if (rest.children) {
				const restChildren = {}
				Object.keys(rest.children).forEach((key) => {
					restChildren[key] = removeParentKey(rest.children[key])
				})
				rest.children = restChildren
			}
			return rest
		}
		const res = await DownloadService.generatePcs(
			currentSelection.map((selection) => removeParentKey(selection)),
			kit_name,
			config,
		)

		const uuid = res.data.uuid
		setTimeout(() => checkStatusPcs({ uuid }), 10000)
	}

	const checkStatusPcs = async ({ uuid }) => {
		const res = await DownloadService.getStatus({ uuid })
		if (!res.data.finished) {
			if (res.data.error) {
				ErrorService.error({
					component: "Welcome:checkStatusPcs",
					message: res.data.error,
					dispatch,
				})
				setProvidedSelection(undefined)
				setModalWaitingOpened(false)
				return
			}
			setTimeout(() => checkStatusPcs({ uuid }), 10000)
			return
		}
		setFileDownloadReady(`/pcs_download/${uuid}`)
	}

	const updateKitContentOrder = async () => {
		if (_.isEqual(selectionState, oldSelection)) {
			return
		}
		const newKitContent = []
		selectionState.forEach((selection) => {
			const selectionId = selection?.id
			const selectionKey = selection?.key
			kitSelected.kit_content.forEach((element) => {
				if (element === selectionId || element === selectionKey) {
					newKitContent.push(element)
					return
				}
			})
		})
		try {
			await Axios.put(`api/kit_pcs/${kitSelected.id}`, {
				data: {
					commune_id: kitSelected.commune_id,
					kit_name: kitSelected.kit_name,
					kit_content: newKitContent,
				},
			})
			stopEditing()
			setKitSelected({ ...kitSelected, kit_content: newKitContent })
			toast.info(
				"L'interclassement du kit : [" +
					kitSelected.kit_name +
					"] à bien été changé !",
			)
		} catch (error) {
			const errorMessage = `Une erreur est survenue : ${error}`
			ErrorService.error({
				component: "DownloadPCS:updateKitContentOrder",
				message: errorMessage,
				dispatch,
			})
		}
		fetchKits()
	}

	const regroupAllChecked = []

	useEffect(() => {
		fetchKits()
	}, [])

	const fetchKits = async () => {
		try {
			const res = await Axios.get("/api/kit_pcs")
			setFetchedKits(res.data)
		} catch (error) {
			const errorMessage = `Erreur lors de la récupération des kits : ${error}`
			ErrorService.error({
				component: "Pcskits:fetchKits",
				message: errorMessage,
				dispatch,
			})
		}
	}

	const getAllChecked = (element = {}, key) => {
		Object.values(element).forEach((subelement: any) => {
			if (_.isEmpty(subelement)) {
				debugger
			}
			if (subelement.checked) {
				regroupAllChecked.push({
					title: subelement.title,
					path: [...key, subelement.key],
					checked: subelement.checked,
					subtitle: subelement.subtitle,
				})
			} else {
				getAllChecked(subelement.children ?? {}, [
					...key,
					subelement.key,
					"children",
				])
			}
		})
	}

	getAllChecked(treeState, [])

	const openKitModal = () => {
		let hasOneElement = false
		regroupAllChecked.forEach((element) => {
			if (element.checked === true) {
				hasOneElement = true
				setIsOpen(true)
				return
			}
		})
		if (hasOneElement === false) {
			toast.error(
				"La création d'un kit nécessite la sélection d'au moins un élément.",
			)
		}
	}

	const onClose = () => {
		setIsOpen(!isOpen)
	}

	const hasOneSelected = regroupAllChecked.length > 0

	const defaultTabs = [
		{
			key: SELECT_PANEL_TITLE,
			render: (
				<Flex alignItemsInitial>
					<TreeSelection
						handleCheck={handleCheck}
						updateSelectedKit={updateSelectedKit}
						stopEditing={stopEditing}
						setKeyOrder={setKeyOrder}
					/>
				</Flex>
			),
		},
		{
			key: intercoNameAdapter(KITS_PANEL_TITLE, isInterco),
			render: (
				<Pcskits
					fetchedKits={fetchedKits}
					fetchKits={fetchKits}
					setModalWaitingOpened={setModalWaitingOpened}
					kitSelected={kitSelected}
					setKitSelected={setKitSelected}
					checkKitId={checkKitId}
					updateSelectedKit={updateSelectedKit}
					stopEditing={stopEditing}
					setForceTab={setForceTab}
					setKeyOrder={setKeyOrder}
				/>
			),
		},
	]

	const renderTabs = () => {
		if (isMobile) {
			defaultTabs.push({
				key: SELECTED_PANEL_TITLE,
				render: (
					<Cart
						updateSelectedKit={updateSelectedKit}
						kitSelected={kitSelected}
						isEditing={isEditing}
						keyOrder={keyOrder}
						setKeyOrder={setKeyOrder}
					/>
				),
			})
		}
		return defaultTabs
	}

	if (!permissions.read) {
		return (
			<ErrorPermissions
				errorMessage={FEATURE_ERROR_PERMISSION}
				backTitle={BACK_TITLE_HOME}
				backUrl={BACK_PATH_HOME}
			/>
		)
	}

	return (
		<ScreenShotContext.Provider
			value={{
				isTakingScreenshotDashboard: false,
				setIsTakingScreenshotDashboard: () => {},
				isTakingScreenshotMap,
				setIsTakingScreenshotMap,
				scaleMultiplicator: 2,
			}}
		>
			<SPageContainer flex>
				<TitleHeader
					title={intercoNameAdapter("Téléchargement du PCS", isInterco)}
				/>
				<Flex flexEnd gap="1rem">
					{hasOneSelected && (
						<>
							{permissions.write && !checkKitId && (
								<PrimaryOutlinedButton
									onClick={openKitModal}
									startIcon={<AddIcon />}
								>
									{intercoNameAdapter("Enregistrer comme Kit PCS", isInterco)}
								</PrimaryOutlinedButton>
							)}
							{permissions.write && checkKitId && (
								<>
									{!isEditing && (
										<PrimaryOutlinedButton
											onClick={() => {
												setIsEditing(true)
												setOldSelection(selectionState)
											}}
											startIcon={<ReorderIcon />}
										>
											{intercoNameAdapter(
												"Modifier l'interclassement du kit",
												isInterco,
											)}
										</PrimaryOutlinedButton>
									)}
									{isEditing && (
										<>
											<PrimaryTextButton
												onClick={() => {
													stopEditing()
												}}
											>
												{intercoNameAdapter(
													"Annuler les changements",
													isInterco,
												)}
											</PrimaryTextButton>
											<PrimaryOutlinedButton
												onClick={() => updateKitContentOrder()}
											>
												{intercoNameAdapter(
													"Enregistrer les changements",
													isInterco,
												)}
											</PrimaryOutlinedButton>
										</>
									)}
								</>
							)}
							<Button
								color="primary"
								startContent={<GetApp />}
								disabled={!isOnline}
								onClick={() => {
									if (isMobile) setForceTab(2)
									setModalWaitingOpened(true)
								}}
							>
								{kitSelected
									? "Télécharger le kit sélectionné"
									: "Télécharger la sélection"}{" "}
								({regroupAllChecked.length})
							</Button>
						</>
					)}
					{!hasOneSelected && (
						<Button
							color="primary"
							startContent={<GetApp />}
							disabled={!isOnline}
							onClick={() => {
								setModalWaitingOpened(true)
								processBeforeDownload(Object.values(initialTree))
							}}
						>
							{intercoNameAdapter("Télécharger le PCS complet", isInterco)}
						</Button>
					)}
				</Flex>
				<TwoColumns>
					<CustomTabs
						tabChildrens={renderTabs()}
						Wrapper={FirstColumn}
						scrollable
						forceTab={forceTab}
					/>
					{isDesktop && (
						<SecondColumn>
							<Cart
								updateSelectedKit={updateSelectedKit}
								kitSelected={kitSelected}
								isEditing={isEditing}
								keyOrder={keyOrder}
								setKeyOrder={setKeyOrder}
							/>
						</SecondColumn>
					)}
				</TwoColumns>
				<ModalWaitingDownloadPcs
					fileDownloadReady={fileDownloadReady}
					modalWaitingOpened={modalWaitingOpened}
					setProvidedSelection={setProvidedSelection}
					setFileDownloadReady={setFileDownloadReady}
					setModalWaitingOpened={setModalWaitingOpened}
					currentSelection={selectionState}
				/>
				<ModalKitPCS
					isOpen={isOpen}
					onClose={onClose}
					fetchKits={fetchKits}
					regroupAllChecked={regroupAllChecked}
				/>
				<ConfirmationModal
					modalShown={isOpenModalConfirmation}
					onClose={() => setIsOpenModalConfirmation(false)}
					message="L'interclassement du contenu du kit sur le point d'être télécharger n'a pas été enregistré !"
					onConfirm={() => {
						setIsEditing(false)
						setIsOpenModalConfirmation(!isOpenModalConfirmation)
						downloadPCS(oldSelection, kitSelected.kit_name)
						setOldSelection(undefined)
					}}
					onConfirmName="Télécharger sans enregistrer"
					secondConfirmChoice={() => {
						updateKitContentOrder()
						setIsOpenModalConfirmation(!isOpenModalConfirmation)
						downloadPCS(selectionState, kitSelected.kit_name)
					}}
					secondConfirmChoiceName="Enregistrer et télécharger"
				/>
			</SPageContainer>
			<CartographyTemplate
				providedSelection={providedSelection}
				setMapScreenBlob={setMapScreenBlob}
			/>
		</ScreenShotContext.Provider>
	)
}
export default DownloadPCS
