import { Autocomplete, TextField } from "@mui/material"
import React from "react"
import { useController, useFormContext } from "react-hook-form"

function FAutocomplete({ name, label, autoFocus = false, ...props }) {
	const { control } = useFormContext()

	const {
		field: { value, onChange },
		fieldState: { error },
	} = useController({
		name,
		control,
		rules: { required: true },
		defaultValue: "",
	})

	return (
		<Autocomplete
			fullWidth
			renderInput={(params) => (
				<TextField
					{...params}
					label={label}
					helperText={error?.message}
					error={Boolean(error)}
					autoFocus={autoFocus}
				/>
			)}
			options={[]}
			{...props}
			value={value}
			{...(props.freeSolo
				? {
						onInputChange: (event, newValue) => {
							// if freeSolo, onInputChange is called once with event null causing isDirty to be true
							if (!event) {
								return
							}
							onChange(newValue)
						},
				  }
				: {
						onChange: (event, newValue) => {
							onChange(newValue)
						},
				  })}
		/>
	)
}

export default FAutocomplete
