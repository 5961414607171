import React, { useState, useEffect, useMemo } from "react"
import Debug from "debug"
import styled from "styled-components"
import { setCurrentPoint } from "@/redux-toolkit/edit/reducer"
import { connect } from "react-redux"
import JsonSchemaForm from "@/pages/admin/formulaire_dynamique/MyForms/JsonSchemaForm"
import useJsonSchemasWithStatics from "@/hooks/useJsonSchemasWithStatics"
import useCdcSharedJsonSchemas from "@/hooks/useCdcSharedJsonSchemas"

// app:javascript:utils:modals:ModalPoint.tsx
const debug = Debug("app:javascript:utils:modals:ModalPoint")
debug.log = console.log.bind(console)

const ModalPoint = ({
	currentPoint,
	setCurrentPoint,
	currentPointIsReadOnly,
}) => {
	const isNew = currentPoint?.id === undefined
	const jsonSchemaWithStatics = useJsonSchemasWithStatics()
	const cdcSharedJsonSchemas = useCdcSharedJsonSchemas()
	const writePermissions =
		jsonSchemaWithStatics[currentPoint?.jsonschema_id]?.write

	const jsonSchemas = { ...jsonSchemaWithStatics }

	if (!currentPoint) {
		return null
	}

	if (window.location.pathname.includes("cooperation")) {
		Object.keys(cdcSharedJsonSchemas).forEach((key) => {
			jsonSchemas[key] = cdcSharedJsonSchemas[key]
		})
	}

	return (
		<>
			<JsonSchemaForm
				isNew={isNew}
				isOpen={currentPoint !== undefined}
				onClose={() => setCurrentPoint(undefined)}
				jsonSchema={jsonSchemas?.[currentPoint?.jsonschema_id]}
				selectedValues={currentPoint}
				writePermissions={writePermissions && !currentPointIsReadOnly}
			/>
		</>
	)
}

const mapStateToProps = (state: any) => ({
	currentPoint: state.edit.currentPoint,
	currentPointIsReadOnly: state.edit.currentPointIsReadOnly,
})
const mapDispatchToProps = (dispatch: any) => ({
	setCurrentPoint: (point: any) => dispatch(setCurrentPoint(point)),
})
export default connect(mapStateToProps, mapDispatchToProps)(ModalPoint)
