import { updateFiche } from "@/redux-toolkit/data/fiche/resources"
import _ from "lodash"
import { DropResult } from "react-beautiful-dnd"
import { useDispatch } from "react-redux"
import { useCallback } from "react"
import IFiche from "utils/types/IFiche"

export const CATEGORY = "CATEGORY"
export const ACTION = "ACTION"

const useReorderFiche = (fiche: IFiche) => {
	const dispatch = useDispatch()

	return useCallback(
		(result: DropResult) => {
			if (!result.destination) return

			const newActionsByCategory = _.cloneDeep(fiche.actionsByCategory)

			if (result.type === CATEGORY) {
				const [removed] = newActionsByCategory.splice(result.source.index, 1)
				newActionsByCategory.splice(result.destination.index, 0, removed)
			}
			if (result.type === ACTION) {
				const sourceCat = newActionsByCategory.findIndex(
					(item) => item.id === result.source.droppableId,
				)
				const destCat = newActionsByCategory.findIndex(
					(item) => item.id === result.destination.droppableId,
				)

				const [removed] = newActionsByCategory[sourceCat].actionIds.splice(
					result.source.index,
					1,
				)
				newActionsByCategory[destCat].actionIds.splice(
					result.destination.index,
					0,
					removed,
				)
			}

			dispatch(
				updateFiche({
					...fiche,
					actionsByCategory: newActionsByCategory,
				}),
			)
		},
		[fiche],
	)
}

export default useReorderFiche
