import { useWatch } from "react-hook-form"
import FText from "../FText"
import React from "react"
import styled from "styled-components"
import { useDebounce } from "react-use"
import _ from "lodash"

export const SMS_CHUNK_SIZE = 160

const Blue = styled.span`
    color: var(--blue500);
`
const SmallText = styled.div`
    font-size: 0.8rem !important;
    & * {
        font-size: 0.8rem !important;
    }
`
const FTextAlert = (props) => {
	const value = useWatch({
		name: props.name,
		defaultValue: "",
	})

	let smsChunk = 1

	smsChunk = _.ceil(value.length / SMS_CHUNK_SIZE)

	const helperTextMsg = (
		<SmallText>
			Votre message contient{" "}
			<Blue>
				<b>{value.length}</b>
			</Blue>{" "}
			caractères chaque SMS envoyé coutera{" "}
			<Blue>
				<b>{smsChunk} crédits</b>
			</Blue>{" "}
			(1 crédit par tranche de {SMS_CHUNK_SIZE} caractères)
		</SmallText>
	)

	return <FText {...props} helperText={helperTextMsg} minRows={5} multiline />
}

export default FTextAlert
