import React, { useState } from "react"
import styled from "styled-components"
import { Tabs, Tab, Box } from "@mui/material"
import SwipeableViews from "react-swipeable-views"
import { Timeline } from "react-twitter-widgets"
import { useTheme } from "@mui/material/styles"
import { connect } from "react-redux"
import { isMobile } from "react-device-detect"
import TitleHeader from "@/utils/components/TitleHeader"
const StyledTabs = styled(Tabs)`
    && .Mui-selected {
        border: 1px solid var(--neutral500);
        font-weight: 700;
        color: var(--primary800);
        border-bottom: 7px solid var(--primary500);
        border-radius: 5px 5px 0px 0px;
    }
    && .MuiTabs-indicator {
        visibility: hidden !important;
    }
    margin-top: 1em;
`
const StyledBox = styled(Box)`
    border: 1px solid var(--neutral500);
`
const StyledTab = styled(Tab)`
    && {
        color: var(--neutral700);
        text-transform: none;
    }
`

const TweetContainer = styled.div`
    max-width: 800px;
    max-height: calc(100vh - 280px);
    overflow: auto;
`
const Container = styled.div`
    padding-bottom: 0;
    height: 100%;
`
function TabPanel(props) {
	const { children, value, index, ...other } = props

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`full-width-tabpanel-${index}`}
			aria-labelledby={`full-width-tab-${index}`}
			{...other}
		>
			{<StyledBox p={3}>{children}</StyledBox>}
		</div>
	)
}
function a11yProps(index) {
	return {
		id: `full-width-tab-${index}`,
		"aria-controls": `full-width-tabpanel-${index}`,
	}
}
const TwitterCard = ({ twitter_tags = [] }) => {
	const [currentTab, setCurrentTab] = useState(0)
	const theme = useTheme()
	return (
		<Container>
			<TitleHeader title="Médias Sociaux de Gestion d'Urgence" />
			<StyledTabs
				value={currentTab}
				onChange={(event, index) => setCurrentTab(index)}
				aria-label="tabs"
			>
				{twitter_tags.map((element, index) => {
					return (
						<StyledTab label={element} {...a11yProps(index)} key={element} />
					)
				})}
			</StyledTabs>

			<SwipeableViews
				axis={theme.direction === "rtl" ? "x-reverse" : "x"}
				index={currentTab}
				onChangeIndex={(index) => setCurrentTab(index)}
			>
				{twitter_tags.map((element, index) => {
					return (
						<TabPanel
							value={currentTab}
							index={index}
							dir={theme.direction}
							key={element}
						>
							<TweetContainer>
								<Timeline
									dataSource={{
										sourceType: "profile",
										screenName: element,
									}}
									options={{
										width: 600,
									}}
								/>
							</TweetContainer>
						</TabPanel>
					)
				})}
			</SwipeableViews>
		</Container>
	)
}

const mapStateToProps = (state) => ({
	twitter_tags: state.commune.twitter_tags,
})

export default connect(mapStateToProps)(TwitterCard)
