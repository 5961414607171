import { IconButton } from "@mui/material"
import FormatIndentDecreaseIcon from "@mui/icons-material/FormatIndentDecrease"
import FormatIndentIncreaseIcon from "@mui/icons-material/FormatIndentIncrease"
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted"
import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered"
import React from "react"
import ToolbarPart from "./ToolbarPart"

function ListToolbar({ currentState, onChange }) {
	const { listType } = currentState

	return (
		<ToolbarPart>
			<IconButton onClick={() => onChange("unordered")}>
				<FormatListBulletedIcon
					color={listType === "unordered" ? "primary" : "action"}
				/>
			</IconButton>
			<IconButton onClick={() => onChange("ordered")}>
				<FormatListNumberedIcon
					color={listType === "ordered" ? "primary" : "action"}
				/>
			</IconButton>
			<IconButton onClick={() => onChange("indent")}>
				<FormatIndentIncreaseIcon color={"action"} />
			</IconButton>
			<IconButton onClick={() => onChange("unindent")}>
				<FormatIndentDecreaseIcon color={"action"} />
			</IconButton>
		</ToolbarPart>
	)
}

export default ListToolbar
