import { SET_TREE, TOGGLE_CHECK, RESET_TREE } from "./action"
import Debug from "debug"
const debug = Debug("components:telechargement:redux:tree:reducer")
import _ from "lodash"

export const initialTreeState = {}

const setAllCheck = (tree, arrayOfKeys = [], forced = undefined) => {
	debug(
		"setAllCheck",
		"tree=",
		tree,
		"arrayOfKeys=",
		arrayOfKeys,
		"forced=",
		forced,
	)
	const checkedPath = [...arrayOfKeys, "checked"]
	const subTree = _.get(tree, arrayOfKeys)
	if (!subTree) {
		return
	}
	const newChecked = forced !== undefined ? forced : !subTree.checked

	// sub items
	if (forced === undefined && arrayOfKeys.length > 1 && newChecked === false) {
		if (arrayOfKeys.length === 3) {
			_.set(tree, [arrayOfKeys[0], "checked"], newChecked)
		}
		if (arrayOfKeys.length === 5) {
			_.set(tree, [arrayOfKeys[0], "checked"], newChecked)
			_.set(
				tree,
				[arrayOfKeys[0], "children", arrayOfKeys[2], "checked"],
				newChecked,
			)
		}
	}

	_.set(tree, checkedPath, newChecked)
	const subTreeChildren = _.get(subTree, ["children"], {})
	const childrenKeys = Object.keys(subTreeChildren)
	childrenKeys.forEach((childrenKey) => {
		setAllCheck(tree, [...arrayOfKeys, "children", childrenKey], newChecked)
	})
}

export const treeReducer = (state, action) => {
	const modifiedState = _.cloneDeep(state) as any
	switch (action.type) {
		case TOGGLE_CHECK:
			setAllCheck(modifiedState, action.arrayOfKeys)
			return modifiedState
		case SET_TREE:
			// @ts-ignore
			treeReducer.defaultTree = action.tree
			return action.tree
		case RESET_TREE:
			// @ts-ignore
			return treeReducer.defaultTree
		default:
			return state
	}
}
