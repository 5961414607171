import React from "react"
import { Flex } from "utils/components/style/flex"
import { H5 } from "utils/components/style/header"
import DaybookAlertFIconRadios from "../../components/DaybookAlertFIconRadios"
import LeftContainer from "../../components/DaybookAlertLeftContainer"
import { isMobile } from "react-device-detect"
import CampaignCostCard from "./components/CampaignCostCard/CampaignCostCard"
import useSelectedEvent from "@/hooks/useSelectedEvent"
import styled from "styled-components"
const SFlex = styled(Flex)`
    min-width: fit-content;
    max-height: calc(100vh - 20rem);
    overflow-y: auto;
	`
const DaybookAlertCampaignPrepare = ({ children }) => {
	const selectedEvent = useSelectedEvent()
	const isLocked = selectedEvent?.locked

	return (
		<Flex gap="1rem" alignItemsStart directionColumn={isMobile}>
			<SFlex gap="1rem" alignItemsStart directionColumn>
				<LeftContainer directionColumn gap="2rem" alignItemsInitial>
					<H5>Canal de diffusion</H5>
					<DaybookAlertFIconRadios disabled={isLocked} />
				</LeftContainer>
				<CampaignCostCard />
			</SFlex>
			{children}
		</Flex>
	)
}

export default DaybookAlertCampaignPrepare
