import { useMediaQuery } from "@mui/material"
import { DESKTOP_NAVBAR_WIDTH } from "@/pages/app/GlobalStyle"
import useIsMenuOpen from "@/hooks/useIsMenuOpen"
import { isMobile } from "react-device-detect"

const useResponsive = (wrapWidth: number) => {
	const isMenuOpen = useIsMenuOpen()
	const isSmall = useMediaQuery(
		`(max-width:${isMenuOpen ? wrapWidth : wrapWidth - DESKTOP_NAVBAR_WIDTH
		}px)`,
	)

	return isSmall || isMobile
}

export default useResponsive
