import Button from "@/styles/atoms/Button"
import {
	ListboxItem,
	ListboxSection,
	Popover,
	PopoverContent,
	PopoverTrigger,
	Listbox,
	Kbd,
} from "@nextui-org/react"
import { setActiveTool, setPanel } from "@/redux-toolkit/common/reducer"
import { resetEdit } from "@/redux-toolkit/edit/reducer"
import React from "react"
import { isMobile } from "react-device-detect"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router-dom"
import { DAYBOOK_LEGEND } from "@/utils/panels/PanelCarto"
import { ITool } from "../../SideBar.hook"

const SideBarContentGrouped = ({
	tool,
	setOpenIndex,
}: {
	tool: ITool
	openIndex: number
	index: number
	setOpenIndex: (number) => void
}) => {
	const dispatch = useDispatch()
	const history = useHistory()

	const toolsGroup = []
	tool.subMenus.forEach((subMenu) => {
		if (subMenu.group && !toolsGroup.includes(subMenu.group)) {
			toolsGroup.push(subMenu.group)
		}
	})
	return (
		<React.Fragment key={tool.name}>
			<Listbox
				key={tool.name}
				variant="faded"
				disabledKeys={tool.subMenus
					.filter((subMenu) => subMenu.disabled)
					.map((subMenu) => subMenu.name)}
			>
				{toolsGroup.map((group) => {
					return (
						<ListboxSection
							key={group}
							title={group}
							showDivider={toolsGroup.indexOf(group) !== toolsGroup.length - 1}
						>
							{(tool?.subMenus ?? []).map((subMenu) => {
								if (subMenu.group === group) {
									return (
										<ListboxItem
											key={subMenu.name}
											description={subMenu.description}
											startContent={subMenu.icon}
											color={subMenu.color}
											variant="bordered"
											endContent={
												subMenu.shortcut &&
												!isMobile && (
													<Kbd>
														<div className="w-max">{subMenu.shortcut}</div>
													</Kbd>
												)
											}
											onClick={() => {
												setActiveTool(subMenu.route)
												if (
													subMenu.route === "/maincourante/carto" &&
													!isMobile
												) {
													dispatch(
														setPanel({
															type: DAYBOOK_LEGEND,
														}),
													)
												} else {
													dispatch(setPanel(null))
												}
												setOpenIndex(-1)
												history.push(subMenu.route)
												dispatch(resetEdit())
											}}
										>
											{subMenu.name}
										</ListboxItem>
									)
								}
							})}
						</ListboxSection>
					)
				})}
			</Listbox>
		</React.Fragment>
	)
}

export default SideBarContentGrouped
