import {
	Card,
	IconButton,
	InputAdornment,
	List,
	Paper,
	TextField,
} from "@mui/material"
import ClearIcon from "@mui/icons-material/Clear"
import useAccessRights from "@/hooks/useAccessRights"
import usePoints from "@/hooks/usePoints"
import useSelectedEvent from "@/hooks/useSelectedEvent"
import {
	CARE_FAMILY,
	PATH_MAIN_COURANTE_CARE,
} from "@/redux-toolkit/userSettings/constants"
import Debug from "debug"
import React, { useState, useMemo } from "react"
import styled from "styled-components"
import {
	PrimaryButton,
	PrimaryOutlinedButton,
} from "@/utils/components/style/button"
import { Flex } from "@/utils/components/style/flex"
import CareResumeCard from "./care/CareResumeCard"
import SearchIcon from "@mui/icons-material/Search"
import ModalCAREOpening from "./care/modals/ModalCAREOpening"
import TmpModalCare from "./care/modals/TmpModalCare"
import formatNfdLowerCase from "@/utils/formatNfdLowerCase"
import CareSearchPersonResult from "./care/search/CareSearchPersonResult"
import ModalFamily from "./care/modals/ModalEnterFamily"
import { isMobile } from "react-device-detect"
import { EnumToolPath } from "@/hooks/services/useAccessRightsServices"
import Button from "@/styles/atoms/Button"
// app:javascript:components:maincourante:TableCare.tsx
const debug = Debug("app:javascript:components:maincourante:TableCare")
debug.log = console.log.bind(console)

const SPaper = styled(Paper)`
    width: 100%;
`
const SCard = styled(Card)`
    padding: 0.5rem;
    width: 100%;
`
const STextField = styled(TextField)`
    width: -webkit-fill-available !important;
`
const SPrimaryOutlinedButton = styled(PrimaryOutlinedButton)`
    width: ${isMobile ? "200px" : "auto"} !important;
`
const SPrimaryButton = styled(PrimaryButton)`
    width: ${isMobile ? "200px" : "auto"} !important;
`

const TableCare = () => {
	const [isOpenModalCAREOpening, setIsOpenModalCAREOpening] = useState(false)
	const [currentTmpCare, setCurrentTmpCare] = useState(undefined)
	const [searchPersonValue, setSearchPersonValue] = useState("")
	const [currentFamily, setCurrentFamily] = useState(undefined)
	const points = usePoints()
	const [isOpen, setIsOpen] = useState(false)
	const selectedEvent = useSelectedEvent()
	const permissions = useAccessRights(EnumToolPath.CARE)
	const isLocked = selectedEvent?.locked

	const concernedCare = Object.values(points).filter(
		(point) =>
			point.jsonschema_id === "TEMPORARY_CARE" &&
			point.event_id === selectedEvent?.id,
	)

	const matchingSearchPersons = useMemo(() => {
		if (searchPersonValue === "") {
			return []
		}
		const careFamilyPoints = Object.values(points).filter(
			(point) =>
				point.jsonschema_id === CARE_FAMILY &&
				point.event_id === selectedEvent?.id,
		)
		const resSearch = []
		careFamilyPoints.forEach((careFamilyPoint) => {
			const searchPersonValueLowerCase = formatNfdLowerCase(searchPersonValue)
			const matchingKeys = []
			Object.entries(careFamilyPoint.geojson.properties).forEach(
				([key, value]) => {
					if (formatNfdLowerCase(value).includes(searchPersonValueLowerCase)) {
						matchingKeys.push(key)
					}
				},
			)
			if (matchingKeys.length > 0) {
				resSearch.push({
					careFamilyPoint,
					matchingKeys,
				})
			}
		})
		return resSearch
	}, [searchPersonValue, points, selectedEvent])

	return (
		<>
			<ModalCAREOpening
				isOpen={isOpenModalCAREOpening}
				onClose={() => setIsOpenModalCAREOpening(!isOpenModalCAREOpening)}
			/>
			<TmpModalCare
				isOpen={isOpen}
				onClose={() => {
					setIsOpen(false)
				}}
				currentTmpCare={currentTmpCare}
				setCurrentTmpCare={(x) => {
					setCurrentTmpCare(x)
				}}
			/>

			<Flex directionColumn gap="1rem">
				<SCard>
					<Flex directionColumn={isMobile} gap=".5em">
						<STextField
							InputProps={{
								startAdornment: (
									<InputAdornment position="start">
										<SearchIcon />
									</InputAdornment>
								),
								endAdornment: (
									<>
										{searchPersonValue && (
											<InputAdornment position="end">
												<IconButton
													onClick={() => {
														setSearchPersonValue("")
													}}
												>
													<ClearIcon />
												</IconButton>
											</InputAdornment>
										)}
									</>
								),
							}}
							placeholder="Rechercher une personne en centre d'accueil"
							value={searchPersonValue}
							onChange={(e) => {
								setSearchPersonValue(e.target.value)
							}}
						/>
						{permissions.write && (
							<Flex fullWidth flexEnd gap="0.5rem">
								<Button
									color="primary"
									variant="bordered"
									onClick={() => {
										setCurrentTmpCare({
											geojson: {
												properties: {
													temporary: true,
												},
											},
										})
										setIsOpen(true)
									}}
									disabled={isLocked}
								>
									Ouvrir un centre d&lsquo;accueil temporaire
								</Button>
								<Button
									color="primary"
									onClick={() => setIsOpenModalCAREOpening(true)}
									disabled={isLocked}
								>
									Ouvrir un centre d&lsquo;accueil
								</Button>
							</Flex>
						)}
					</Flex>
				</SCard>
				{searchPersonValue.length > 0 && matchingSearchPersons.length === 0 && (
					<SCard>
						<p>Aucun résultat</p>
					</SCard>
				)}
				{matchingSearchPersons.length > 0 && (
					<SPaper>
						<List>
							{matchingSearchPersons.map((matchingSearchPerson) => (
								<CareSearchPersonResult
									key={matchingSearchPerson.careFamilyPoint.id}
									searchPersonValue={searchPersonValue}
									matchingSearchPerson={matchingSearchPerson}
									setCurrentFamily={setCurrentFamily}
								/>
							))}
						</List>
					</SPaper>
				)}
				{searchPersonValue.length === 0 && (
					<>
						{concernedCare.map((care) => (
							<CareResumeCard
								care={care}
								key={care.id}
								setCurrentTmpCare={setCurrentTmpCare}
								setIsOpen={setIsOpen}
								canDeleteDatas={permissions.canDeleteDatas}
							/>
						))}
					</>
				)}
			</Flex>
			<ModalFamily
				isOpen={currentFamily}
				onClose={() => setCurrentFamily(undefined)}
				currentFamily={currentFamily}
			/>
		</>
	)
}

export default TableCare
