import useCooperationFilters from "@/hooks/useCooperationFilters"
import React from "react"
import searchConfig from "../utils/searchConfig"
import HelpCenterIcon from "@mui/icons-material/HelpCenter"
import styled, { css } from "styled-components"

const Wrapper = styled.div<{ box: boolean }>`
    display: flex;
    flex-direction: row;
    gap: 8px;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 1.2em;
    ${({ box }) =>
			box
				? css`
                  color: white;
                  background: grey;
                  border-radius: 5px;
                  padding: 4px 8px 4px 4px;
              `
				: css`
                  color: grey;
              `}
`

function EmptyMessage({ box = false, message = undefined }) {
	const coopFilters = useCooperationFilters()
	const currentSearch = searchConfig.find(
		(sc) => sc.mode === coopFilters.search,
	)

	return (
		<Wrapper box={box}>
			<HelpCenterIcon fontSize="large" />
			{message ?? currentSearch?.emptyMessage}
		</Wrapper>
	)
}

export default EmptyMessage
