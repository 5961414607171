import axios from "axios"
import ErrorPermissions, {
	BACK_PATH_HOME,
	BACK_TITLE_HOME,
	FEATURE_ERROR_PERMISSION,
} from "@/hooks/ErrorPermissions"
import { EnumToolPath } from "@/hooks/services/useAccessRightsServices"
import useAccessRights from "@/hooks/useAccessRights"
import useIsInterco from "@/hooks/useIsInterco"
import usePdfGenerators from "@/hooks/usePdfGenerators"
import {
	createPdfsGenerator,
	updatePdfsGenerator,
} from "@/redux-toolkit/data/pdfGenerator/resources"
import { PATH_ARRETES_TEMPLATES } from "@/redux-toolkit/userSettings/constants"
import React, { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { useHistory, useParams } from "react-router"
import HTMLEditor from "utils/components/HTMLEditor/HTMLEditor"
import TitleHeader from "utils/components/TitleHeader"

const EditArretesTemplates = () => {
	const permissions = useAccessRights(EnumToolPath.ARRETES_TEMPLATES)
	const isInterco = useIsInterco()

	if (!permissions.read || isInterco) {
		return (
			<ErrorPermissions
				errorMessage={FEATURE_ERROR_PERMISSION}
				backTitle={BACK_TITLE_HOME}
				backUrl={BACK_PATH_HOME}
			/>
		)
	}

	const { id } = useParams() as any
	const pdfGenerators = Object.values(usePdfGenerators())
	const dispatch = useDispatch()
	const history = useHistory()

	const title =
		(pdfGenerators.find((pdfg: any) => pdfg.id === id) as any)?.title ?? ""

	const [html, setHtml] = useState("")

	useEffect(() => {
		if (id !== "new") {
			const func = async () => {
				const response = await axios.get(`/api/arrete/pdfGenerators/${id}`)
				setHtml(response.data)
			}

			func()
		}
	}, [])

	const save = async (title, html) => {
		if (id !== "new") {
			dispatch(updatePdfsGenerator({ data: html, title, id }))
		} else {
			dispatch(createPdfsGenerator({ data: html, title }))
		}
		history.push("/customdata/arretes")
	}

	const cancel = () => history.push("/customdata/arretes")

	return (
		<>
			<TitleHeader
				backArrowUrl="/customdata/arretes"
				backArrowTitle="Retour aux modèles d'arrêtés"
				title={
					id == "new"
						? "Création d'un modèle"
						: permissions.write
						? "Editer le modèle"
						: "Voir le modèle"
				}
			/>
			<HTMLEditor
				title={title}
				htmlData={html}
				onValidate={save}
				onCancel={cancel}
				titleLabel="Nom du modèle"
				writePermission={permissions.write}
			/>
		</>
	)
}

export default EditArretesTemplates
