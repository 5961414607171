import { Autocomplete, MenuItem, TextField } from "@mui/material"
import Debug from "debug"
import React from "react"
import { useController, useFormContext, useWatch } from "react-hook-form"

// app:javascript:utils:modals:ModalIncident:FModalPriority.tsx
const debug = Debug("app:javascript:utils:modals:ModalIncident:FModalPriority")
debug.log = console.log.bind(console)

const FSelectWithImg = ({
	label = undefined,
	name,
	options,
	disabled = false,
	helperText = "",
}) => {
	const { control } = useFormContext()

	const {
		field: { ref, value, onChange },
		fieldState: { error },
	} = useController({
		name,
		control,
		rules: { required: true },
	})

	return (
		<Autocomplete
			noOptionsText={"Aucune option"}
			fullWidth
			disabled={disabled}
			disableClearable
			renderInput={(params) => {
				const foundOption = options.find((option) => option.value === value)
				return (
					<TextField
						{...params}
						inputRef={ref}
						label={label ?? name}
						error={Boolean(error?.message)}
						helperText={error?.message ?? helperText}
						InputProps={{
							...params.InputProps,
							startAdornment: foundOption && (
								<img src={foundOption.img} width={30} />
							),
						}}
					/>
				)
			}}
			renderOption={(props, option) => (
				<MenuItem {...props}>
					<img src={option.img} width={30} style={{ marginRight: "1rem" }} />
					{option.label}
				</MenuItem>
			)}
			options={options}
			value={options.find((option) => option.value === value)}
			getOptionLabel={(option) => option.label}
			onChange={(event, newValue) => {
				// @ts-ignore
				onChange(newValue?.value)
			}}
		/>
	)
}

export default FSelectWithImg
