import { updateFiche } from "@/redux-toolkit/data/fiche/resources"
import { useCallback } from "react"
import { useDispatch } from "react-redux"
import IFiche, { IAction } from "utils/types/IFiche"

const useUpdateAction = (fiche: IFiche) => {
	const dispatch = useDispatch()

	return useCallback(
		(action: IAction) => {
			const newFiche: IFiche = {
				...fiche,
				actions: {
					...fiche.actions,
					[action.id]: action,
				},
			}

			dispatch(updateFiche(newFiche))
		},
		[fiche],
	)
}

export default useUpdateAction
