import React from "react"
import IEvent from "utils/types/IEvent"
import { useDispatch } from "react-redux"
import {
	resetDaybookReducer,
	setLastSelectedEvent,
} from "@/redux-toolkit/daybook/reducer"
import { setPanel, setSelectedEvent } from "@/redux-toolkit/common/reducer"
import useMultiPolygons from "./useMultiPolygons"
import { addCoucheMultiPolygons } from "@/redux-toolkit/carto/reducer"
import SynchroEventService from "@/services/synchro/SynchroEventService"
import SynchroPointService from "@/services/synchro/SynchroPointService"
import { useHistory } from "react-router-dom"
import SynchroMultiPolygonService from "@/services/synchro/SynchroMultiPolygonService"
import IMultiPolygon from "utils/types/IMultiPolygon"
import { getPdfs } from "@/redux-toolkit/data/pdf/resources"
import useSelectedEvent from "./useSelectedEvent"

const useSelectCrisis = () => {
	const dispatch = useDispatch()
	const history = useHistory()
	const currentSelectedEvent = useSelectedEvent()

	return (eventToSelect: IEvent) => {
		if (!eventToSelect?.id) return null
		if (currentSelectedEvent?.id === eventToSelect.id) return null
		// reset daybook reducer to clear previous info
		dispatch(resetDaybookReducer())
		// set selected event
		dispatch(setSelectedEvent(eventToSelect.id))

		// fetch last potentially missed data
		dispatch(SynchroEventService.synchronizeAction())
		dispatch(SynchroPointService.synchronizePointAction())
		dispatch(SynchroMultiPolygonService.synchronizeAction())
		dispatch(setPanel(null))
		getPdfs(dispatch)
		dispatch(setLastSelectedEvent(eventToSelect.id))

		if (
			!window.location.pathname.includes("maincourante") ||
			window.location.pathname.includes("rapport")
		) {
			history.push("/maincourante/dashboard")
		}
	}
}

export default useSelectCrisis
