import React, { useMemo } from "react"
import Debug from "debug"
import styled from "styled-components"
import _ from "lodash"
import { useFieldArray, useFormContext } from "react-hook-form"
import { specificityOptions } from "./FamilyMemberCard"
import { Flex } from "utils/components/style/flex"
import SuperReactTable from "utils/components/tables/SuperReactTable/SuperReactTable"
import AdultChip from "@/pages/maincourante/care/chips/AdultChip"
import ChildChip from "@/pages/maincourante/care/chips/ChildChip"
import BabyChip from "@/pages/maincourante/care/chips/BabyChip"
import AnimalChip from "@/pages/maincourante/care/chips/AnimalChip"
import FText from "utils/form/FText"
import FSelect from "utils/form/FSelect"
import { width60, widthCustom } from "utils/components/tables/widthProps"
import { H6 } from "utils/components/style/header"
import SuperDeleteButton from "utils/components/SuperDeleteButton"
import { StyledChip } from "utils/components/style/chip"
import { isMobile } from "react-device-detect"
import { IFamilyMemberType } from "utils/types/IFamilyMember"

// app:javascript:utils:form:specific:care:FFamilyRenderMembers.tsx
const debug = Debug(
	"app:javascript:utils:form:specific:care:FFamilyRenderMembers.tsx",
)
debug.log = console.log.bind(console)
const TableContainer = styled.div`
    border: 1px solid var(--neutral200);
    border-radius: 4px;
    width: 100%;
`
const CellContainer = styled.div`
    padding: 0.35rem;
    width: ${isMobile ? "200px" : null};
`
const FFamilyRenderMembers = ({
	name,
	currentLeftPersons,
	value,
	disabled = false,
}) => {
	const disabledByProps = disabled //* created to override an existing "disabled" without change prop's usage name
	const { control, getValues, watch } = useFormContext()
	const { fields, append, remove } = useFieldArray({
		control,
		// @ts-ignore
		name,
	})

	watch(name)
	const columns = useMemo(() => {
		return [
			{
				header: "Type",
				accessorKey: "type",
				cell: ({ cell: { row: { index } } }) => {
					switch (value[index].type) {
						case IFamilyMemberType.ADULT:
							return <AdultChip />
						case IFamilyMemberType.CHILD:
							return <ChildChip />
						case IFamilyMemberType.BABY:
							return <BabyChip />
						case IFamilyMemberType.ANIMAL:
							return <AnimalChip />
						default:
							return null
					}
				},
				...widthCustom(120),
			},
			{
				header: "Statut",
				accessorKey: "presence",
				cell: ({ cell: { row: { index } } }) => {
					if (currentLeftPersons?.includes(index)) {
						return (
							<StyledChip
								$colorProperty="var(--red500)"
								label="Sorti(e)"
								variant="outlined"
							/>
						)
					}
					return (
						<StyledChip
							$colorProperty="var(--green500)"
							label="Présent(e)"
							variant="outlined"
						/>
					)
				},
			},
			{
				header: "Identité",
				accessorKey: "Identité",
				cell: ({ cell: { row: { index } } }) => {
					return (
						<FText
							size="small"
							name={`${name}.${index}.Identité`}
							label="Identité"
							disabled={disabledByProps || value[index]?.quantity}
						/>
					)
				},
			},
			{
				header: "Spécificités",
				accessorKey: "Spécificités",
				cell: ({ row, cell: { row: { index } }, ...other }) => {
					return (
						<FSelect
							size="small"
							isMulti
							label="Spécificités"
							options={specificityOptions
								.filter((option) => option.types.includes(value[index].type))
								.map((option) => option.label)}
							name={`${name}.${index}.Spécificités`}
							disabled={disabledByProps}
						/>
					)
				},
			},
			{
				header: "Commentaire",
				accessorKey: "Commentaire",
				cell: ({ row, cell: { row: { index } }, ...other }) => {
					return (
						<FText
							size="small"
							name={`${name}.${index}.Commentaire`}
							label="Commentaire"
							multiline
							disabled={disabledByProps}
						/>
					)
				},
			},
			{
				header: "",
				Filter: (props) => <div />,
				accessorKey: "delete2",
				...width60,
				enableSorting: false,
				isAction: true,
				cell: ({ row: { index } }) => {
					let disabled = false
					if (_.max(currentLeftPersons) >= index) {
						disabled = true
					}
					return (
						<SuperDeleteButton
							disabled={disabledByProps ? disabledByProps : disabled}
							onClick={() => {
								remove(index)
							}}
						/>
					)
				},
			},
		]
	}, [value])
	return (
		<>
			{value.length > 0 && (
				<Flex directionColumn alignItemsInitial fullWidth gap="0.5rem">
					<H6>Membres du groupe</H6>
					<TableContainer>
						<SuperReactTable
							noDefer
							$secondaryTableStyle
							data={value}
							columns={columns}
							infiniteSize
							showBottom={false}
							isEditable={false}
							isDeletable={false}
							simpleTable
							selectable={false}
						/>
					</TableContainer>
				</Flex>
			)}
		</>
	)
}

export default FFamilyRenderMembers
