import { yupResolver } from "@hookform/resolvers/yup"
import GraphicEqOutlinedIcon from "@mui/icons-material/GraphicEqOutlined"
import SmsOutlinedIcon from "@mui/icons-material/SmsOutlined"
import useSelectedEvent from "@/hooks/useSelectedEvent"
import {
	createPointWithUpdateEvent,
	updatePointWithUpdateEvent,
} from "@/redux-toolkit/data/points/resources"
import Debug from "debug"
import _ from "lodash"
import Moment from "moment"
import React, { useEffect } from "react"
import { FormProvider, useForm } from "react-hook-form"
import { useDispatch } from "react-redux"
import { useHistory } from "react-router-dom"
import styled from "styled-components"
import { Flex } from "@/utils/components/style/flex"
import FIconRadios from "@/utils/form/FIconRadios"
import { FormDependencies } from "@/utils/form/FormDependencies"
import FRadios from "@/utils/form/FRadios"
import FText from "@/utils/form/FText"
import SuperModal from "@/utils/modals/SuperModal"
import { StaticFormType } from "@/utils/services/StaticFormsService"
import IDaybookAlert, {
	DaybookAlertCanal,
	DaybookAlertType,
} from "@/utils/types/daybook/IDaybookAlert"
import { v4 as uuidv4 } from "uuid"
import Yup from "@/utils/yup"

const SSmsOutlinedIcon = styled(SmsOutlinedIcon)`
    font-size: 3rem !important;
    color: var(--primary500);
`
const SGraphicEqOutlinedIcon = styled(GraphicEqOutlinedIcon)`
    font-size: 3rem !important;
    color: var(--red500);
`
// app:javascript:components:maincourante:alert:alertCreation:ModalAlert.tsx
const debug = Debug(
	"app:javascript:components:maincourante:alert:alertCreation:ModalAlert",
)
debug.log = console.log.bind(console)

const schema = Yup.object().shape({
	geojson: Yup.object().shape({
		properties: Yup.object().shape({
			type: Yup.string()
				.required("Le type est obligatoire")
				.default(DaybookAlertCanal.SMS),
			name: Yup.string().required("Le nom est obligatoire").default(""),
			listeDiffusions: Yup.array().of(Yup.string()).default([]),
			includeFixedPhones: Yup.boolean().default(true),
			listeDiffusionIds: Yup.array().of(Yup.string()).default([]),
		}),
	}),
})

const ModalAlertCampaign = ({
	isOpen,
	setIsOpen,
	currentAlert,
}: {
	isOpen: boolean
	setIsOpen: (isOpen: boolean) => void
	currentAlert: IDaybookAlert
}) => {
	const dispatch = useDispatch()
	const selectedEvent = useSelectedEvent()
	const history = useHistory()
	const methods = useForm({
		resolver: yupResolver(schema),
	})

	useEffect(() => {
		if (_.isEmpty(currentAlert)) {
			methods.reset(schema.getDefault())
			return
		}
		methods.reset(currentAlert)
	}, [currentAlert])

	const onSubmit = (values: IDaybookAlert) => {
		const id = uuidv4()
		dispatch(
			createPointWithUpdateEvent({
				point: {
					id,
					jsonschema_id: StaticFormType.ALERTE,
					event_id: selectedEvent.id,
					geojson: {
						properties: {
							...values.geojson.properties,
							status: DaybookAlertType.PREPARE_DIFFUSE,
						},
					},
				},
				event: {
					...selectedEvent,
					history: [
						...selectedEvent.history,
						{
							type: StaticFormType.ALERTE,
							subTitle: "Création d'une campagne d'alerte",
							title: values.geojson.properties.name,
							date: Moment().format(),
						},
					],
				},
			}),
		)
		setIsOpen(false)
		history.push(`/maincourante/alerte/${id}`)
	}
	return (
		<FormProvider {...methods}>
			<SuperModal
				isOpen={isOpen}
				onClick={methods.handleSubmit(onSubmit)}
				onClose={() => setIsOpen(!isOpen)}
				title="Création d'une campagne"
				size="lg"
			>
				<Flex fullWidth gap="1rem" directionColumn>
					<FText name="geojson.properties.name" label="Nom de la campagne" />

					<FIconRadios
						name="geojson.properties.type"
						label="Type de campagne"
						iconOptions={[
							{
								icon: <SSmsOutlinedIcon />,
								value: DaybookAlertCanal.SMS,
							},
							{
								icon: <SGraphicEqOutlinedIcon />,
								value: DaybookAlertCanal.VOCALE,
							},
						]}
					/>
				</Flex>
			</SuperModal>
		</FormProvider>
	)
}

export default ModalAlertCampaign
