import SendIcon from "@mui/icons-material/Send"
import { CircularProgress, IconButton } from "@mui/material"
import Axios from "axios"
import JsonSchemaService from "@/pages/carto2/cartographie/service/JsonSchemaService"
import useFetchAlertInfo from "@/hooks/api/useFetchAlertInfo"
import useJsonSchemasWithStatics from "@/hooks/useJsonSchemasWithStatics"
import usePoints from "@/hooks/usePoints"
import { updatePoint } from "@/redux-toolkit/data/points/resources"
import _ from "lodash"
import Moment from "moment"
import React, { useEffect, useMemo } from "react"
import { FormProvider, useForm } from "react-hook-form"
import { Marker, TileLayer } from "react-leaflet"
import { useDispatch } from "react-redux"
import { useHistory, useParams } from "react-router-dom"
import TitleHeader from "utils/components/TitleHeader"
import PointCardContent from "utils/components/jsonSchema/PointCardContent"
import { PrimaryOutlinedButton } from "utils/components/style/button"
import FText from "utils/form/FText"
import ErrorService from "@/services/ErrorService"
import IDaybookAlert from "@/utils/types/daybook/IDaybookAlert"
import {
	ChatContainer,
	Container,
	Grid,
	Hour,
	LeftContent,
	Response,
	ResponseText,
	SFlex,
	SIconButton,
	SMapContainer,
	WhiteBackground,
} from "./DaybookAlertChat.styled"
import { addPoint } from "@/redux-toolkit/data/points/reducer"
import ErrorPermissions, {
	BACK_PATH_HOME,
	BACK_TITLE_HOME,
	FEATURE_ERROR_PERMISSION,
} from "@/hooks/ErrorPermissions"
import useAccessRights from "@/hooks/useAccessRights"
import { PATH_MAIN_COURANTE_ALERTE } from "@/redux-toolkit/userSettings/constants"
import { EnumToolPath } from "@/hooks/services/useAccessRightsServices"
import Loading from "utils/Loading"

const DaybookAlertChat = () => {
	const { alertId, answerId } = useParams<{
		alertId: string
		answerId: string
	}>()
	const points = usePoints()
	const history = useHistory()
	const [isLoading, setIsLoading] = React.useState(false)
	const [nextUpdate, setNextUpdate] = React.useState(30)
	const dispatch = useDispatch()
	const jsonSchemaWithStatics = useJsonSchemasWithStatics()
	const methods = useForm({
		defaultValues: {
			Réponse: "",
		},
	})
	const { fetchAlertInfo } = useFetchAlertInfo()
	const readPermission = useAccessRights(EnumToolPath.ALERTE).read

	useEffect(() => {
		const timer = setTimeout(async () => {
			setNextUpdate(nextUpdate - 1)
			if (nextUpdate === 0) {
				await fetchAlertInfo()
				setNextUpdate(30)
			}
		}, 1_000)
		return () => {
			clearTimeout(timer)
		}
	}, [nextUpdate])

	if (!readPermission) {
		return (
			<ErrorPermissions
				errorMessage={FEATURE_ERROR_PERMISSION}
				backTitle={BACK_TITLE_HOME}
				backUrl={BACK_PATH_HOME}
			/>
		)
	}

	const alert = points[alertId] as IDaybookAlert
	const responses = alert?.geojson?.properties?.responses?.[answerId]
	const received = responses?.received ?? []
	const sended = responses?.sended ?? []
	const allMessages = [
		{
			reponse: alert.geojson.properties.smsText,
			date: null,
			citycId: "123",
		},
		...received,
		...sended,
	].sort((a, b) => {
		return (
			Moment(a.date, "DD/MM/YYYY HH:mm:ss").unix() -
			Moment(b.date, "DD/MM/YYYY HH:mm:ss").unix()
		)
	})
	const point = useMemo(() => {
		const listeDiffusionIds =
			alert?.geojson?.properties?.listeDiffusionIds ?? []
		let finalPoint = null
		listeDiffusionIds.forEach((id) => {
			const point = points[id]
			const jsonSchema = jsonSchemaWithStatics[point.jsonschema_id]
			const phoneProperty = JsonSchemaService.getPhoneProperty(jsonSchema)
			const phones = point.geojson.properties[phoneProperty.name]
			if (phones.filter((phone) => phone.Numéro === answerId).length > 0) {
				finalPoint = point
			}
		})
		return finalPoint
	}, [points, alertId])

	const onSubmit = async (data: any) => {
		setIsLoading(true)

		try {
			await Axios.post("/api/citycalert_direct_sms", {
				phone_number: answerId,
				message: data.Réponse,
				alertId: alertId,
			})
			await fetchAlertInfo()
			setNextUpdate(30)
			methods.reset()
		} catch (e) {
			ErrorService.error({
				component: "DaybookAlertChat",
				message: "Erreur lors de l'envoi du sms",
				dispatch,
			})
		} finally {
			setIsLoading(false)
		}

		setIsLoading(false)
	}

	const coo =
		point.geojson.properties[
			JsonSchemaService.getGeolocProperty(
				jsonSchemaWithStatics[point.jsonschema_id],
			).name
		]?.coo
	const identifiants = JsonSchemaService.getIdProperties(
		jsonSchemaWithStatics[point.jsonschema_id],
	)
	const identifiant = identifiants
		.map((id) => {
			return point.geojson.properties[id.name]
		})
		.join(" ")

	return (
		<Container>
			<TitleHeader
				title={`Historique de la conversation avec : ${identifiant} - ${answerId}`}
				backArrowTitle="Retour à l'alerte"
				backArrowAction={() => history.push(`/maincourante/alerte/${alertId}`)}
				rightElements={
					<PrimaryOutlinedButton
						disabled={isLoading}
						startIcon={isLoading && <Loading />}
						onClick={async () => {
							await fetchAlertInfo()
							setNextUpdate(30)
						}}
					>
						Mettre à jour les données (mise à jour auto dans {nextUpdate})
					</PrimaryOutlinedButton>
				}
			/>
			<Grid>
				<LeftContent>
					<PointCardContent showJsonSchema point={point} />
					{!_.isEmpty(coo) && (
						<SMapContainer center={coo} zoom={13}>
							<TileLayer
								attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
								url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
							/>
							<Marker position={coo} />
						</SMapContainer>
					)}
				</LeftContent>
				<ChatContainer>
					{allMessages.map((response: any, index) => {
						return (
							<Response
								key={index}
								$limitedWidth
								$sender={sended.includes(response) || index === 0}
							>
								<Hour>{response.date}</Hour>
								<ResponseText>{response.reponse}</ResponseText>
							</Response>
						)
					})}
					<FormProvider {...methods}>
						<SFlex gap="1rem" fullWidth>
							<WhiteBackground>
								<FText disabled={isLoading} name="Réponse" multiline />
							</WhiteBackground>
							{!isLoading && (
								<SIconButton onClick={methods.handleSubmit(onSubmit)}>
									<SendIcon fontSize="small" />
								</SIconButton>
							)}
							{isLoading && (
								<IconButton disabled>
									<CircularProgress size={24} />
								</IconButton>
							)}
						</SFlex>
					</FormProvider>
				</ChatContainer>
			</Grid>
		</Container>
	)
}

export default DaybookAlertChat
