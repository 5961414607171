import { yupResolver } from "@hookform/resolvers/yup"
import LocalPolice from "@mui/icons-material/LocalPolice"
import Psychology from "@mui/icons-material/Psychology"
import useAccessRights from "@/hooks/useAccessRights"
import usePoints from "@/hooks/usePoints"
import useSelectedEvent from "@/hooks/useSelectedEvent"
import useTaskQueue from "@/hooks/useTaskQueue"
import { updateEvent } from "@/redux-toolkit/data/events/resources"
import {
	createPointWithUpdateEvent,
	updatePointWithUpdateEvent,
} from "@/redux-toolkit/data/points/resources"
import {
	setCurrentIncident,
	setCurrentIntervention,
} from "@/redux-toolkit/edit/reducer"
import {
	INCIDENT,
	INTERVENTION,
	PATH_MAIN_COURANTE_INCIDENT,
} from "@/redux-toolkit/userSettings/constants"
import Debug from "debug"
import { diff } from "deep-object-diff"
import _ from "lodash"
import Moment from "moment"
import React, { useEffect, useMemo, useState } from "react"
import { isDesktop, isMobile } from "react-device-detect"
import { FormProvider, useForm } from "react-hook-form"
import { useDispatch, useSelector } from "react-redux"
import styled from "styled-components"
import {
	GreenOutlinedButton,
	PrimaryOutlinedButton,
} from "@/utils/components/style/button"
import { Flex } from "@/utils/components/style/flex"
import { H6 } from "@/utils/components/style/header"
import { Subtitle } from "@/utils/components/style/text"
import FCellules from "@/utils/form/FCellules"
import FComments from "@/utils/form/FComments/FComments"
import FCommentsButtonAdd from "@/utils/form/FComments/FCommentsButtonAdd"
import FDateTime from "@/utils/form/FDateTime"
import FGeoloc from "@/utils/form/FGeoloc"
import FRadios from "@/utils/form/FRadios"
import FSelectHumans from "@/utils/form/FSelectHumans"
import FText from "@/utils/form/FText"
import { FormDependencies } from "@/utils/form/FormDependencies"
import FContact from "@/utils/form/specific/daybook/FContact"
import FInterventionsLinkedToIncident from "@/utils/form/specific/daybook/FInterventionsLinkedToIncident"
import {
	LINK,
	ORDERED,
	UNORDERED,
} from "@/utils/form/specific/fiche/EditorToolbar"
import FDraft from "@/utils/form/specific/fiche/FDraft"
import FUpload from "@/utils/form/upload/FUpload"
import SuperModal from "@/utils/modals/SuperModal"
import { getYupGeoloc } from "@/utils/types/IGeoloc"
import Yup from "@/utils/yup"
import { v4 as uuidv4 } from "uuid"
import FModalIncidentPriority from "./FModalIncidentPriority"
import FModalIncidentStatut from "./FModalIncidentStatut"
import OpenAiIncident from "./OpenAiIncident"
import { CircularProgress } from "@mui/material"
import { EnumToolPath } from "@/hooks/services/useAccessRightsServices"
import Button from "@/styles/atoms/Button"
// app:javascript:components:maincourante:intervention:ModalIntervention.tsx
const debug = Debug(
	"app:javascript:components:maincourante:intervention:ModalIntervention",
)
debug.log = console.log.bind(console)

const SSubtitle = styled(Subtitle)`
    inline-size: 220px;
    overflow-wrap: break-word;
`
const StyledUl = styled.ul`
    margin-bottom: 0;
`
const StyledFlex = styled(Flex)`
    flex-grow: 1;
    align-items: baseline;
`

const schema = Yup.object().shape({
	Statut: Yup.string().required().default("En cours"),
	Priorité: Yup.string().required().default("Normale"),
	Type: Yup.string().required().default("Information"),
	Objet: Yup.string().required().default(""),
	"Date de début": Yup.string()
		.required()
		.default(() => Moment().format()),
	"Date de fin": Yup.string().nullable().default(null),
	tmpId: Yup.string().required().default(uuidv4()),
	Détails: Yup.string().default(""),
	Géolocalisation: getYupGeoloc({ isRequired: false }),
	Cellules: Yup.array().of(Yup.string()).default([]),
	Responsable: Yup.array().of(Yup.string()).default([]),
	"Pièces jointes": Yup.array()
		.of(
			Yup.object().shape({
				config: Yup.object(),
				title: Yup.string().test(
					"title",
					({ value }) => {
						return `Votre saisie comporte ${
							value.length - 50
						} caractères de trop. (Limite: 50)`
					},
					(value) => {
						return value.length <= 50
					},
				),
				extension: Yup.string(),
				filename: Yup.string(),
			}),
		)
		.default([]),
	Contact: Yup.object().default({}),
	Réponses: Yup.array().of(Yup.object()).default([]),
})

const interceptedKeys = ["Cellules", "Responsable", "Géolocalisation"]

const keyInterceptor = (key) => {
	switch (key) {
		case "Cellules":
		case "Responsable":
		case "Géolocalisation":
		case "Contact":
		case "Détails":
			return "Donnée modifiée."
		default:
	}
}
const keyModifier = (value, key) => {
	switch (key) {
		case "Réponses":
			return `${value?.length ?? 0} point(s) de situation`
		case "Date de début":
			return Moment(value).format("DD/MM/YYYY HH:mm")
		case "Date de fin":
			return Moment(value).format("DD/MM/YYYY HH:mm")
		default:
			return value
	}
}
const ModalIncident = () => {
	const dispatch = useDispatch()
	const { addTask, clearTasks, setShouldProcess } = useTaskQueue()
	const points = usePoints()
	const [isIALoading, setIsIALoading] = useState(false)
	const [openAiResponse, setOpenAiResponse] = useState(undefined)
	const currentIncident = useSelector(
		(state: any) => state.edit.currentIncident,
	)
	const email = useSelector((state: any) => state.userSettings.email)
	// ✨ experimental !
	const canUseAI = email === "contact@numerisk.fr"

	const selectedEvent = useSelectedEvent()
	const isLocked = selectedEvent?.locked
	const permissions = useAccessRights(EnumToolPath.INCIDENT)

	const isNew = currentIncident?.id === undefined

	const generateDefaultValues = () => {
		return {
			...schema.getDefault(),
			tmpId: uuidv4(),
			...currentIncident,
		}
	}

	const buildCurrentValues = (values) => {
		return {
			"Heure de début": Moment(values["Heure de début"], "HH:mm"),
			...values,
		}
	}

	const methods = useForm({
		resolver: yupResolver(schema),
		defaultValues: isNew
			? generateDefaultValues()
			: buildCurrentValues(currentIncident),
		mode: "onChange",
	})

	useEffect(() => {
		if (currentIncident?.id) {
			methods.reset({
				...currentIncident.geojson.properties,
				// fix some pieces jointes being '' instead of []
				"Pièces jointes": _.isEmpty(
					currentIncident.geojson.properties["Pièces jointes"],
				)
					? []
					: currentIncident.geojson.properties["Pièces jointes"],
				tmpId: currentIncident.id,
			})
		} else {
			methods.reset(generateDefaultValues())
		}
	}, [currentIncident])

	const buildIncidentHistoryUpdate = (values) => {
		const diffValue = diff(currentIncident.geojson.properties, values)
		const textDetails = {}
		Object.keys(diffValue).forEach((key) => {
			if (diffValue[key]) {
				if (interceptedKeys.includes(key)) {
					textDetails[key] = keyInterceptor(key)
				} else {
					textDetails[key] = {
						oldValue: keyModifier(currentIncident.geojson.properties[key], key),
						newValue: keyModifier(values[key], key),
					}
				}
			}
		})

		return [
			...selectedEvent.history,
			{
				type: INCIDENT,
				subTitle: "Modification d'Evènement",
				title: values.Objet,
				objectId: currentIncident.id,
				date: Moment().format(),
				description: {
					Objet: "",
					details: textDetails,
				},
				status: values.Statut,
			},
		]
	}

	const onSubmit = (values) => {
		dispatch(setCurrentIncident(undefined))
		setTimeout(() => {
			clearTasks()
			if (isNew) {
				dispatch(
					createPointWithUpdateEvent({
						point: {
							id: values.tmpId,
							geojson: {
								properties: values,
							},
							jsonschema_id: INCIDENT,
							event_id: selectedEvent.id,
						},
						event: {
							...selectedEvent,
							history: [
								...selectedEvent.history,
								{
									type: INCIDENT,
									subTitle: "Création d'Evènement",
									title: values.Objet,
									objectId: values.tmpId,
									date: Moment().format(),
									status: values.Statut,
								},
							],
						},
					}),
				)
			} else {
				const newHistory = buildIncidentHistoryUpdate(values)
				dispatch(
					updatePointWithUpdateEvent({
						point: {
							...currentIncident,
							geojson: {
								properties: {
									...currentIncident.geojson.properties,
									...values,
								},
							},
						},
						event: {
							...selectedEvent,
							updated_at: Moment().format(),
							history: newHistory,
						},
					}),
				)
			}
		}, 100)
	}

	const statut = methods.watch("Statut")

	methods.watch("Réponses")

	const statutValue = methods.getValues()?.["Statut"]

	useEffect(() => {
		if (methods.getValues()["Statut"] === "Terminé") {
			if (!methods.getValues()["Date de fin"]) {
				methods.setValue("Date de fin", Moment().format())
			}
		}
	}, [statutValue])

	const tmpId = isNew ? methods.getValues()["tmpId"] : currentIncident.id

	const statusDisabled = useMemo(() => {
		const linkedInterventions = Object.values(points).filter(
			(point) =>
				point.event_id === selectedEvent?.id &&
				point.jsonschema_id === INTERVENTION &&
				point?.geojson?.properties?.["En lien avec"] === tmpId,
		)
		if (!linkedInterventions) {
			return false
		}
		if (
			linkedInterventions.some((intervention) => {
				return intervention.geojson.properties["Statut"] !== "Terminé"
			})
		) {
			return true
		}
		return false
	}, [points, tmpId])

	console.log("openAiResponse", openAiResponse)

	return (
		<FormProvider {...methods}>
			<SuperModal
				disableSave={!methods.formState.isDirty}
				size="5xl"
				toggleOnClickOutside={!methods.formState.isDirty}
				isOpen={!!currentIncident}
				fade={false}
				rightContent={
					<Flex alignItemsInitial gap="1rem">
						<Flex gap="1rem" directionColumn>
							<FModalIncidentPriority
								disabled={!permissions.write || isLocked}
							/>
							<FModalIncidentStatut
								disabled={statusDisabled || !permissions.write || isLocked}
							/>

							<Flex directionColumn={isDesktop} gap=".5em" fullWidth>
								<FDateTime
									name="Date de début"
									disabled={!permissions.write || isLocked}
								/>
								<FDateTime
									name="Date de fin"
									disabled={!permissions.write || isLocked}
								/>
							</Flex>

							<H6>Ajouter à l&#8217;évènement</H6>
							<FContact disabled={!permissions.write || isLocked} />
							<Flex fullWidth directionColumn>
								<Button
									color="primary"
									variant="bordered"
									fullWidth
									startContent={<LocalPolice />}
									disabled={
										statut === "Terminé" || !permissions.write || isLocked
									}
									onClick={() => {
										dispatch(
											setCurrentIntervention({
												"En lien avec": methods.getValues()["tmpId"],
											}),
										)
									}}
								>
									Intervention
								</Button>
								{statut === "Terminé" && (
									<SSubtitle>
										L&apos;évènement est terminé, vous ne pouvez donc plus
										ajouter de nouvelles interventions
									</SSubtitle>
								)}
							</Flex>

							<FCommentsButtonAdd
								name="Réponses"
								buttonTitle="Point de situation"
								disabled={isLocked || !permissions.write}
							/>
							{canUseAI && (
								<GreenOutlinedButton
									startIcon={
										isIALoading ? (
											<CircularProgress size={20} />
										) : (
											<Psychology />
										)
									}
									disabled={isIALoading}
									fullWidth
									onClick={() => setOpenAiResponse("3.5")}
								>
									Conseil de l'IA 3.5
								</GreenOutlinedButton>
							)}
							{canUseAI && (
								<GreenOutlinedButton
									startIcon={
										isIALoading ? (
											<CircularProgress size={20} />
										) : (
											<Psychology />
										)
									}
									disabled={isIALoading}
									fullWidth
									onClick={() => setOpenAiResponse("4")}
								>
									Conseil de l'IA 4
								</GreenOutlinedButton>
							)}
						</Flex>
						{canUseAI && (
							<OpenAiIncident
								shouldAskGpt={openAiResponse}
								setShouldAskGpt={setOpenAiResponse}
								isLoading={isIALoading}
								setIsLoading={setIsIALoading}
							/>
						)}
					</Flex>
				}
				onClose={() => {
					setShouldProcess(true)
					dispatch(setCurrentIncident(undefined))
				}}
				title={isNew ? "Créer un évènement" : "Modifier l'évènement"}
				isNew={isNew}
				writePermissions={!isLocked}
				onClick={methods.handleSubmit(onSubmit)}
			>
				<StyledFlex gap="1rem" directionColumn fullWidth={isMobile}>
					<FRadios
						name="Type"
						options={["Décision", "Information", "Accueil mairie"]}
						helpText={
							<StyledUl>
								<li>Décision : Action décidée par le Poste de Commandement</li>
								<li>Information : Evènement à vérifier et/ou traiter</li>
								<li>
									Accueil Mairie : Demande de renseignement reçu à l&#39;accueil
								</li>
							</StyledUl>
						}
						disabled={!permissions.write || isLocked}
					/>
					<FText
						name="Objet"
						autoFocus={!isMobile}
						disabled={!permissions.write || isLocked}
					/>
					<FDraft
						name="Détails"
						label="Détails"
						extraToolbarButtons={[LINK]}
						hideToolbarButtons={[ORDERED]}
						isImportable={false}
						disabled={!permissions.write || isLocked}
					/>
					<FComments
						name="Réponses"
						messageConfirmation="Voulez-vous vraiment supprimer le point de situation"
						disabled={!permissions.write || isLocked}
					/>
					<FGeoloc
						name="Géolocalisation"
						disabled={!permissions.write || isLocked}
					/>
					<FCellules
						name="Cellules"
						label="Cellules"
						disabled={!permissions.write || isLocked}
					/>
					<FSelectHumans
						name="Responsable"
						label="Responsable"
						disabled={!permissions.write || isLocked}
					/>
					{/* He will disabling itself if missing permissions */}
					<FInterventionsLinkedToIncident
						addTask={addTask}
						isNew={isNew}
						tmpId={tmpId}
					/>
					<H6>Pièces jointes</H6>
					<Flex fullWidth alignItemsStart>
						<FormDependencies targets={["Pièces jointes"]}>
							{({ "Pièces jointes": linkedFilesValue }) => (
								<FUpload
									values={linkedFilesValue}
									name="Pièces jointes"
									disabled={!permissions.write || isLocked}
								/>
							)}
						</FormDependencies>
					</Flex>
				</StyledFlex>
			</SuperModal>
		</FormProvider>
	)
}

export default ModalIncident
