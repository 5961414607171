import ContactsIcon from "@mui/icons-material/Contacts"
import NoAccountsIcon from "@mui/icons-material/NoAccounts"
import {
	Chip,
	ListSubheader,
	MenuItem,
	Select,
	ToggleButton,
	ToggleButtonGroup,
} from "@mui/material"
import { ColumnDef } from "@tanstack/react-table"
import useAccessRights from "@/hooks/useAccessRights"
import useHumans from "@/hooks/useHumans"
import useSelectedEvent from "@/hooks/useSelectedEvent"
import { PATH_MAIN_COURANTE_ORGA } from "@/redux-toolkit/userSettings/constants"
import _ from "lodash"
import React, { useMemo, useState } from "react"
import styled from "styled-components"
import { Flex } from "utils/components/style/flex"
import SuperReactTable from "utils/components/tables/SuperReactTable/SuperReactTable"
import { widthCustom } from "utils/components/tables/widthProps"
import FModalHumans from "utils/form/modal/FModalHumans"
import HumanService from "utils/services/HumanService"
import { ABSENT, NOBODY, PRESENT } from "utils/types/IEvent"
import useCrisisOrgaWithOrder from "./utils/useCrisisOrgaWithOrder"
import useSetOrgaMemberAbsent from "./utils/useSetOrgaMemberAbsent"
import useSubstituteOrgaMember from "./utils/useSubstituteOrgaMember"
import { EnumToolPath } from "@/hooks/services/useAccessRightsServices"

const RedToggleButton = styled(ToggleButton)`
    &.Mui-selected {
        color: var(--red500) !important;
        background-color: var(--red100t42) !important;

        :hover {
            background-color: var(--red200t42) !important;
        }
    }
`

const TempOrgaSubRow = ({ row }) => {
	const { substitutions, cellules, absents } = useCrisisOrgaWithOrder()
	const cellule = cellules[row.original.id]
	const humans = useHumans()
	const substituteOrgaMember = useSubstituteOrgaMember()
	const setOrgaMemberAbsent = useSetOrgaMemberAbsent()
	const selectedEvent = useSelectedEvent()
	const permissions = useAccessRights(EnumToolPath.GC_ORGA)

	const [lock, setLock] = useState(false)

	const [externalHumanSelect, setExternalHumanSelect] = useState({
		open: false,
		id: undefined,
	})

	const open = (id) =>
		setExternalHumanSelect({
			open: true,
			id,
		})

	const onClose = () =>
		setExternalHumanSelect({
			...externalHumanSelect,
			open: false,
		})

	const { responsableIds, suppleantIds, membresIds } = row.original

	//* >>> Display in priority => Managers, Substitutions, Members
	// if substitution is a manager too, keeped it as manager
	const newSuppleantIds = suppleantIds?.filter(
		(id) => !responsableIds.includes(id),
	)
	// if member is a manager, keeped it as it, else if is a substitution, keeped it as it too
	const newMembresIds = membresIds
		?.filter((id) => !responsableIds.includes(id))
		?.filter((id) => !newSuppleantIds.includes(id))
	//* >>> End

	const responsables = (responsableIds ?? [])
		.map((responsableId) => humans[responsableId])
		.filter(Boolean)
	const suppleants = (newSuppleantIds ?? [])
		.map((suppleantId) => humans[suppleantId])
		.filter(Boolean)
	const membres = (newMembresIds ?? [])
		.map((membreId) => humans[membreId])
		.filter(Boolean)

	const columns = useMemo(
		() =>
			[
				{
					header: "",
					accessorKey: "role",
					cell: ({ row: { original } }) => {
						if ((responsableIds ?? []).includes(original.id)) {
							return <Chip label="Responsable" />
						}
						if ((suppleantIds ?? []).includes(original.id)) {
							return (
								<Chip
									variant="outlined"
									label={
										"Suppléant " +
										(suppleantIds.indexOf(original.id) + 1).toString()
									}
								/>
							)
						}
						return (
							<Chip
								variant="outlined"
								label={
									"Membre " + (membresIds.indexOf(original.id) + 1).toString()
								}
							/>
						)
					},
					enableSorting: false,
					...widthCustom(130),
				},
				{
					header: "Responsable",
					accessorFn: (value) =>
						value.geojson.properties.Nom +
						" " +
						value.geojson.properties.Prénom,
					enableSorting: false,
				},
				{
					header: "Statut",
					accessorFn: _.identity,
					cell: ({ getValue }) => {
						const cell = getValue() as any
						return (
							<ToggleButtonGroup
								disabled={lock || !permissions.write || selectedEvent?.locked}
								color="primary"
								value={absents?.includes(cell.id) ? "absent" : "present"}
								onChange={async (event, newValue) => {
									setLock(true)

									if (newValue === "present") {
										if (responsableIds.includes(cell.id))
											setOrgaMemberAbsent(cell.id, PRESENT, true)
										else await setOrgaMemberAbsent(cell.id, PRESENT)
									} else await setOrgaMemberAbsent(cell.id, ABSENT)

									setLock(false)
								}}
								exclusive
								size="small"
							>
								<ToggleButton value={"present"}>Présent</ToggleButton>
								<RedToggleButton value={"absent"}>Absent</RedToggleButton>
							</ToggleButtonGroup>
						)
					},
					enableSorting: false,
				},

				{
					header: "Remplaçant",
					accessorFn: _.identity,
					cell: ({ getValue }) => {
						const cell = getValue() as any
						return (
							<>
								{(responsableIds.includes(cell.id) ||
									absents?.includes(cell.id)) && (
									<Select
										disabled={lock || selectedEvent?.locked}
										fullWidth
										size="small"
										value={substitutions?.[cell.id] ?? ""}
										onChange={async (event) => {
											if (event.target.value === "add") return open(cell.id)
											setLock(true)
											if (event.target.value === NOBODY) {
												await substituteOrgaMember(cell.id, undefined)
											} else
												await substituteOrgaMember(cell.id, event.target.value)

											setLock(false)
										}}
									>
										<MenuItem value={NOBODY}>
											<Flex gap={10}>
												<NoAccountsIcon fontSize="small" />
												<div>Aucun remplacant</div>
											</Flex>
										</MenuItem>
										<ListSubheader>Suppléants</ListSubheader>
										{cellule.suppleantIds
											?.filter(
												(suppleantId) => !_.includes(absents, suppleantId),
											)
											.map((humanId) => (
												<MenuItem key={humanId} value={humanId}>
													{HumanService.displayFullName(humans[humanId])}
												</MenuItem>
											))}
										<ListSubheader>Autres</ListSubheader>
										{substitutions?.[cell.id] &&
											substitutions?.[cell.id] !== NOBODY &&
											!cellule.suppleantIds?.includes(
												substitutions[cell.id],
											) && (
												<MenuItem value={substitutions[cell.id]}>
													{HumanService.displayFullName(
														humans[substitutions[cell.id]],
													)}
												</MenuItem>
											)}
										<MenuItem value="add">
											<Flex gap={10}>
												<ContactsIcon fontSize="small" />
												<div>Choisir un remplaçant depuis un annuaire</div>
											</Flex>
										</MenuItem>
									</Select>
								)}
							</>
						)
					},
					enableSorting: false,
				},
			] as ColumnDef<any>[],
		[lock, substitutions, absents],
	)

	return (
		<React.Fragment>
			<SuperReactTable
				$secondaryTableStyle
				columns={columns}
				data={[...responsables, ...suppleants, ...membres]}
				simpleTable
				infiniteSize
				selectable={false}
				isDeletable={false}
				isEditable={false}
				showBottom={false}
				noDefer
			/>
			<FModalHumans
				exclude={absents}
				value={undefined}
				onChange={(value) => {
					substituteOrgaMember(externalHumanSelect.id, value)
				}}
				isOpen={externalHumanSelect.open}
				onClose={onClose}
				multiple={false}
			/>
		</React.Fragment>
	)
}

export default TempOrgaSubRow
