import { Flex } from "utils/components/style/flex"
import React from "react"
import styled from "styled-components"

export const CardContainer = styled(Flex)`
    width: 400px;
    background-color: #fff;
    padding: 1rem;
    border-radius: 0.5rem;
    box-shadow: var(--cool-shadow);
`
