import { Alert } from "@mui/material"
import React, { useMemo } from "react"
import {
	PrimaryButton,
	PrimaryOutlinedButton,
} from "utils/components/style/button"
import { Flex } from "utils/components/style/flex"
import IUploadedFile, { IFileConfigCategory } from "utils/types/IUploadedFile"
import FormStep from "../../component/FormStep"
import useStep from "../../hooks/useStep"

const choices: {
	[key in IFileConfigCategory]: string
} = {
	PROCEDURE: "Procédure",
	PEDAGOGIQUE: "Fiche pédagogique",
	CONVENTION: "Convention",
	CARTO: "Cartographie",
	EDITABLE: "Document éditable",
	IMAGE: "Image ou autre",
}

const StepMoveCategory = ({
	file,
	...props
}: { file: IUploadedFile } & Record<string, any>) => {
	const { value, setStepValue, onBack, onSkip } = useStep("category")

	const allowedMoves: IFileConfigCategory[] = useMemo(() => {
		if (!file) return []

		if ("pcs" in file.config && "replace" in file.config.pcs) return []

		if ("pcs" in file.config) {
			if ("replace" in file.config.pcs) return []
			if ("after" in file.config.pcs)
				return ["PROCEDURE", "PEDAGOGIQUE", "CARTO"]

			return ["PROCEDURE", "PEDAGOGIQUE", "CONVENTION", "CARTO"]
		}
		return []
	}, [file])

	return (
		<FormStep
			question="Dans quelle catégorie voulez-vous déplacer le fichier ?"
			formatedValue={choices[value]}
			onBack={onBack}
			{...props}
		>
			{allowedMoves.length > 0 ? (
				<Flex gap wrap>
					{allowedMoves
						.filter((item) => item !== file.config.category)
						.map((key) => (
							<PrimaryButton key={key} onClick={() => setStepValue(key)}>
								{choices[key]}
							</PrimaryButton>
						))}
					<PrimaryOutlinedButton onClick={() => onSkip(file.config)}>
						Passer
					</PrimaryOutlinedButton>
				</Flex>
			) : (
				<Alert severity="warning">Ce fichier ne peut pas être déplacé</Alert>
			)}
		</FormStep>
	)
}

export default StepMoveCategory
