import ErrorPermissions, {
	BACK_PATH_HOME,
	BACK_TITLE_HOME,
	FEATURE_ERROR_PERMISSION,
} from "@/hooks/ErrorPermissions"
import { EnumToolPath } from "@/hooks/services/useAccessRightsServices"
import useAccessRights from "@/hooks/useAccessRights"
import useCommune from "@/hooks/useCommune"
import useHumans from "@/hooks/useHumans"
import useJsonSchemasWithStatics from "@/hooks/useJsonSchemasWithStatics"
import useMappings from "@/hooks/useMappings"
import useMultiPolygons from "@/hooks/useMultiPolygons"
import useNotValidatedPoints from "@/hooks/useNotValidatedPoints"
import usePoints from "@/hooks/usePoints"
import useStockPlaces from "@/hooks/useStockPlaces"
import JsonSchemaService from "@/pages/carto2/cartographie/service/JsonSchemaService"
import Filter from "@/pages/maincourante/following/filter/Filter"
import useFilterForm from "@/pages/maincourante/following/filter/useFilterForm"
import { setProcessingSomething } from "@/redux-toolkit/common/reducer"
import { updateLocalPoint } from "@/redux-toolkit/data/points/reducer"
import {
	deleteAllPoints,
	deletePoint,
} from "@/redux-toolkit/data/points/resources"
import SynchroPointService from "@/services/synchro/SynchroPointService"
import Button from "@/styles/atoms/Button"
import TitleHeader from "@/utils/components/TitleHeader"
import CustomTabs from "@/utils/components/customTabs/CustomTabs"
import { PrimaryOutlinedButton } from "@/utils/components/style/button"
import { Flex } from "@/utils/components/style/flex"
import { H3 } from "@/utils/components/style/header"
import SuperReactTable from "@/utils/components/tables/SuperReactTable/SuperReactTable"
import { RUBY_DATE_FORMAT } from "@/utils/dateFormat"
import FSelectWithImage from "@/utils/form/FSelectWithImage"
import getImage from "@/utils/getImage"
import useKeyboardJs from "@/utils/keyboard/useKeyboardJs"
import CsvService from "@/utils/services/CsvService"
import IPoint from "@/utils/types/IPoint"
import AccessTime from "@mui/icons-material/AccessTime"
import AddIcon from "@mui/icons-material/Add"
import Announcement from "@mui/icons-material/Announcement"
import PublishIcon from "@mui/icons-material/Publish"
import { Image, Kbd } from "@nextui-org/react"
import Debug from "debug"
import _ from "lodash"
import Moment from "moment"
import React, { useEffect, useMemo, useState } from "react"
import { isDesktop, isMobile } from "react-device-detect"
import { useDispatch } from "react-redux"
import { useHistory, useParams } from "react-router-dom"
import styled from "styled-components"
import JsonSchemaForm from "./JsonSchemaForm"
import MyFormsMap from "./MyFormsMap"
import MyFormsSector from "./sectorPanel/MyFormsSector"
import MyFormsStatPanel from "./statPanel/MyFormsStatPanel"
import MyFormsNotValidatedPanel from "./temporaryDataPanel/MyFormsNotValidatedPanel"
import useJsonSchemaColumns from "./useJsonSchemaColumns"
import useOrderConfig from "./useOrderConfig"
import LightTooltip from "@/styles/atoms/Tooltip/LightTooltip/LightTooltip"
import { RowSelectionState } from "@tanstack/react-table"
import IButtonMultiOption from "@/utils/components/button/ButtonMulti/IButtonMultiOption"

const debug = Debug(
	"app:javascript:components:admin:formulaire_dynamique:MyForms:MyForms",
)

const SAnnouncementIcon = styled(Announcement)`
  color: var(--primary500) !important;
`
const ImageWrapper = styled.div`
  margin-right: 16px;
`
const SH3 = styled(H3)`
  max-width: 80vw;
`

const UPDATE_PANEL_TITLE = "Mises à jour"

const MyForms = ({
	forcedJsonSchemaId = undefined,
}: {
	forcedJsonSchemaId?: string
}) => {
	const { id: tmpJsonSchemaId } = useParams<{ id: string }>()
	let jsonSchemaId = tmpJsonSchemaId
	if (forcedJsonSchemaId) {
		jsonSchemaId = forcedJsonSchemaId
	}
	const [selectedValues, setSelectedValues] = useState(undefined)
	const [isNew, setIsNew] = useState(false)
	const [isFormulaireOpen, setIsFormulaireOpen] = useState(false)
	const points = usePoints()
	const jsonSchemas = useJsonSchemasWithStatics()
	const jsonSchema = jsonSchemas[jsonSchemaId]
	const writePermission = jsonSchemas[jsonSchemaId]?.write
	const humans = useHumans()
	const multiPolygons = useMultiPolygons()
	const stockPlaces = useStockPlaces()
	const history = useHistory()
	const dispatch = useDispatch()
	const commune = useCommune()
	const unvalidatedPoints = useNotValidatedPoints()
	const [isPressed] = useKeyboardJs("ctrl + space")
	const unusedConcernedPoints = useMemo(() => {
		return Object.values(unvalidatedPoints).filter(
			(point) => point.jsonschema_id === jsonSchemaId,
		)
	}, [unvalidatedPoints, jsonSchemaId])
	useEffect(() => {
		if (isPressed && !isFormulaireOpen) {
			createForm()
		}
	}, [isPressed])

	const readPermission = useAccessRights(EnumToolPath.DONNEES_COMMUNALES).read

	const customDataItems = useMemo(() => {
		return Object.values(points).filter(
			(point) => point.jsonschema_id === jsonSchemaId,
		)
	}, [points, jsonSchema])

	const filterOption = useMemo(() => {
		const tmpFilterOptionProperty =
			JsonSchemaService.getFilterProperty(jsonSchema)

		if (_.isEmpty(tmpFilterOptionProperty)) {
			return undefined
		}

		return [
			{
				name: tmpFilterOptionProperty.name,
				component: (props) => {
					return (
						<FSelectWithImage
							{...props}
							options={tmpFilterOptionProperty.itemsImage}
							placeholder="Ajouter un filtre"
						/>
					)
				},
				default: [],
			},
		] as any
	}, [jsonSchema])

	const { filteredData, ...filterConfig } = useFilterForm({
		options: filterOption,
		data: customDataItems,
	})

	const toggleFormulaire = () => {
		setIsFormulaireOpen(!isFormulaireOpen)
	}

	const convertToCsv = useMemo(() => {
		return CsvService.convertToCsvFactory({
			data: customDataItems,
			humans,
			stockPlaces,
			properties: jsonSchema?.template?.properties ?? [],
			title: jsonSchema?.title,
			multiPolygons,
		})
	}, [customDataItems, jsonSchema, humans, stockPlaces])

	const toggleUpdate = (item) => {
		setIsNew(false)
		setSelectedValues(item)
		setIsFormulaireOpen(true)
	}

	const createForm = () => {
		setIsNew(true)
		setIsFormulaireOpen(true)
	}

	const columns = useJsonSchemaColumns(jsonSchema)
	const orderConfig = useOrderConfig(jsonSchemaId)

	const mappings = useMappings()
	const concernedMapping = Object.values(mappings).find(
		(mapping) => mapping.jsonschema_id === jsonSchemaId,
	)

	const declareAllAsUpdated = [
		{
			renderCondition: writePermission,
			requireValidation: true,
			label: "Déclarer la sélection comme à jour",
			icon: <AccessTime />,
			description:
				"Modifie la date de mise à jour de chaque donnée à la date du jour.",

			confirmMessage:
				"Voulez-vous vraiment déclarer la sélection comme à jour ? La date de mise à jour de chaque donnée sera remplacée par la date du jour.",
			onClick: async (selectedFlatRows) => {
				dispatch(setProcessingSomething("Mise à jour des données en cours..."))
				Object.keys(selectedFlatRows).forEach((selectedValueId) => {
					const selectedValue = customDataItems.find(
						(data) => data.id === selectedValueId,
					)
					dispatch(
						updateLocalPoint({
							...selectedValue,
							updated_at: Moment().format(RUBY_DATE_FORMAT),
						}),
					)
				})
				await dispatch(
					SynchroPointService.synchronizePointAction({
						withoutDelete: true,
						callBack: () => {
							dispatch(setProcessingSomething(undefined))
						},
					}),
				)
			},
			key: "update",
		},
	] as IButtonMultiOption[]

	if (!readPermission) {
		return (
			<ErrorPermissions
				errorMessage={FEATURE_ERROR_PERMISSION}
				backTitle={BACK_TITLE_HOME}
				backUrl={BACK_PATH_HOME}
			/>
		)
	}

	return (
		<>
			<TitleHeader
				title={
					<Flex gap="1rem">
						<Image
							isBlurred
							src={getImage(jsonSchema?.imgId)}
							width={isMobile ? 40 : 60}
						/>
						<SH3
							className={
								isMobile ? "text-2xl font-semibold" : "text-4xl font-semibold"
							}
						>
							{jsonSchema?.title ?? "Mes Formulaires"}
						</SH3>
					</Flex>
				}
				backArrowUrl={"/customdata/my_forms"}
				backArrowTitle="Retour"
			/>
			<CustomTabs
				tabChildrens={[
					{
						key: "Tableau de données",
						render: (
							<SuperReactTable
								actionsButtons={
									<>
										{concernedMapping && writePermission && (
											<Button
												color="primary"
												variant="bordered"
												startContent={<PublishIcon />}
												onClick={() =>
													history.push(
														`/customdata/my_forms/${jsonSchema.id}/import`,
													)
												}
											>
												Importer des données
											</Button>
										)}
										{filterOption && <Filter {...filterConfig} />}
										{writePermission && (
											<LightTooltip
												placement="top"
												title={
													<>
														Raccourci : <Kbd>Ctrl + Espace</Kbd>
													</>
												}
											>
												<Button
													className={isMobile ? "w-full" : ""}
													color="primary"
													startContent={<AddIcon />}
													onClick={createForm}
												>
													Ajouter{" "}
													{isDesktop && jsonSchema?.title?.toLowerCase()}
												</Button>
											</LightTooltip>
										)}
									</>
								}
								additionalMultiOptions={declareAllAsUpdated}
								initialSortBy={(jsonSchema?.sort_by ?? []).map((schema) => ({
									...schema,
									id: schema.property,
								}))}
								writePermission={writePermission}
								// ? On Myforms delete and write are same right
								isDeletable={writePermission}
								haveStatus
								data={filterOption ? filteredData : customDataItems}
								customEmptyMessage={
									<Flex gap="1rem" directionColumn>
										<SAnnouncementIcon />
										<p>Aucune donnée</p>
										{writePermission && (
											<Button
												color="primary"
												variant="bordered"
												onClick={createForm}
											>
												Ajouter {isDesktop && jsonSchema?.title?.toLowerCase()}
											</Button>
										)}
									</Flex>
								}
								columns={columns}
								onEditClick={toggleUpdate}
								onDeleteClick={(original) => {
									dispatch(deletePoint(original))
								}}
								onDeleteAllClick={(originals) => {
									dispatch(deleteAllPoints(originals))
								}}
								orderConfig={orderConfig}
								convertToCsv={convertToCsv}
								resetId={jsonSchema?.id}
								autoResetSearch={false}
							/>
						),
					},
					...(JsonSchemaService.isGeolocalisable(jsonSchema)
						? [
								{
									key: "Secteurs",
									render: (
										<MyFormsSector
											customDataItems={customDataItems}
											jsonSchema={jsonSchema}
										/>
									),
								},
							]
						: []),
					...(JsonSchemaService.isGeolocalisable(jsonSchema)
						? [
								{
									key: "Cartographie",
									render: (
										<MyFormsMap
											customDataItems={customDataItems}
											jsonSchema={jsonSchema}
										/>
									),
								},
							]
						: []),
					{
						key: UPDATE_PANEL_TITLE,
						render: (
							<MyFormsStatPanel
								customDataItems={customDataItems}
								jsonSchema={jsonSchema}
							/>
						),
					},
					...(Object.keys(commune.publicJsonSchemaIds)?.includes(jsonSchemaId)
						? [
								{
									key: "Inscriptions",
									badge: unusedConcernedPoints.length,
									render: (
										<MyFormsNotValidatedPanel
											onEditClick={toggleUpdate}
											jsonSchemaId={jsonSchemaId}
										/>
									),
								},
							]
						: []),
				]}
			/>

			{isNew && (
				<JsonSchemaForm
					isNew={isNew}
					isOpen={isFormulaireOpen}
					onClose={toggleFormulaire}
					jsonSchema={jsonSchema}
					writePermissions={writePermission}
				/>
			)}
			{!isNew && (
				<JsonSchemaForm
					isNew={isNew}
					isOpen={isFormulaireOpen}
					onClose={toggleFormulaire}
					jsonSchema={jsonSchema}
					selectedValues={selectedValues}
					writePermissions={writePermission}
				/>
			)}
		</>
	)
}

export default MyForms
