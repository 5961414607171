import React, { useMemo } from "react"
import _ from "lodash"
import JsonSchemaService from "@/pages/carto2/cartographie/service/JsonSchemaService"
import useAllPoints from "@/hooks/useAllPoints"
import useJsonSchemasWithStatics from "@/hooks/useJsonSchemasWithStatics"
import useCdcSharedJsonSchemas from "@/hooks/useCdcSharedJsonSchemas"
import usePointPropertyToText from "utils/components/jsonSchema/properties/usePointPropertyToText"
import useCooperationFilters from "@/hooks/useCooperationFilters"
import useCdc from "@/hooks/useCdc"
import useAllStocks from "@/hooks/useAllStocks"
import useAllVehicules from "@/hooks/useAllVehicules"
import CsvService from "utils/services/CsvService"
import useAllHumans from "@/hooks/useAllHumans"
import useAllStockPlaces from "@/hooks/useAllStockPlaces"
import useMultiPolygons from "@/hooks/useMultiPolygons"

export const useConvertToCsvSharedTable = ({
	coopFiltersTag,
	splittedData,
}) => {
	const jsonSchemaWithStatics = useJsonSchemasWithStatics()
	const cdcSharedJsonSchemas = useCdcSharedJsonSchemas()
	const pointPropertyToText = usePointPropertyToText()
	const coopFilters = useCooperationFilters()
	const allStocks = useAllStocks()
	const allVehicules = useAllVehicules()
	const cdc = useCdc()
	const allJsonSchemas = {
		...jsonSchemaWithStatics,
		...cdcSharedJsonSchemas,
	}
	const humans = useAllHumans()
	const stockPlaces = useAllStockPlaces()
	const multiPolygons = useMultiPolygons()

	const pointsAllInterco = Object.values(splittedData).reduce(
		(prev, curr) => [...prev, ...curr],
		[],
	)

	const convertToCsv = useMemo(() => {
		return (comm: string | undefined) => {
			const communeName = cdc.communes.find(
				(commune) => commune.id === comm,
			)?.name
			if (!_.isEmpty(coopFiltersTag)) {
				let dataToExtract = comm ? splittedData[comm] : pointsAllInterco
				let csvData = []
				dataToExtract.forEach((data) => {
					const commune = cdc.communes.find(
						(commune) => commune.id === data.commune_id,
					)?.name
					const placeName = JsonSchemaService.getIdProperties(
						allJsonSchemas[data.jsonschema_id],
					)
						.map((property) => data.geojson.properties[property.name] ?? "")
						.join(" ")
					const adresse = pointPropertyToText(
						data,
						JsonSchemaService.getGeolocProperty(
							allJsonSchemas[data.jsonschema_id],
						),
					)
					const stocks = Object.values(allStocks).filter((stock) => {
						if (!allJsonSchemas[stock.jsonschema_id]) return false
						const res =
							stock.geojson.properties[
								JsonSchemaService.getStockPlaceProperty(
									allJsonSchemas[stock.jsonschema_id],
								).name
							] === data.id &&
							stock.geojson.properties[
								JsonSchemaService.getTagProperty(
									allJsonSchemas[stock.jsonschema_id],
								).name
							]?.includes(coopFiltersTag.key)
						return res
					})
					const vehicules = Object.values(allVehicules).filter((vehicule) => {
						if (!allJsonSchemas[vehicule.jsonschema_id]) return false
						const res =
							vehicule.geojson.properties[
								JsonSchemaService.getStockPlaceProperty(
									allJsonSchemas[vehicule.jsonschema_id],
								).name
							] === data.id &&
							vehicule.geojson.properties["Tags"]?.includes(coopFiltersTag.key)
						return res
					})
					const respProperty = JsonSchemaService.getResponsableProperty(
						allJsonSchemas[data.jsonschema_id],
					)
					const responsable = pointPropertyToText(data, respProperty)
					const stocksDispo = stocks
						.map(
							(stock) =>
								stock.geojson.properties["Quantité"] +
								" " +
								stock.geojson.properties["Nature"],
						)
						.join(" / ")
					const vehiculesDispo = vehicules
						.map((vehicule) => vehicule.geojson.properties["Modèle"])
						.join(" / ")
					csvData = [
						...csvData,
						[
							`=""${commune ?? ""}""`,
							`=""${placeName ?? ""}""`,
							`=""${adresse ?? ""}""`,
							`=""${responsable ?? ""}""`,
							`=""${stocksDispo ?? ""}""`,
							`=""${vehiculesDispo ?? ""}""`,
						],
					]
				})

				return (selected: string[]) => ({
					data: csvData,
					headers: [
						"Commune",
						"Nom du lieu",
						"Adresse",
						"Responsable(s)",
						"Stocks disponibles",
						"Véhicules disponibles",
					],
					filename:
						coopFilters.layer.key +
						(comm ? `_${communeName}.csv` : "_TouteLInterco.csv"),
				})
			} else {
				const jsonSchema = allJsonSchemas[coopFilters.layer.jsonschema_id]
				return (selected: string[]) => {
					let dataToExtract = comm ? splittedData[comm] : pointsAllInterco
					const csv = CsvService.convertToCsvFactory({
						data: dataToExtract,
						humans,
						stockPlaces,
						properties: jsonSchema?.template?.properties ?? [],
						title:
							jsonSchema?.title + (comm ? `_${communeName}` : "_TouteLInterco"),
						multiPolygons,
					})(selected)
					csv.headers = [{ key: "Commune", label: "Commune" }, ...csv.headers]
					csv.data = csv.data.map((row, index) => {
						const correspondingData = dataToExtract[index]
						const commune = cdc.communes.find(
							(commune) => commune.id === correspondingData.commune_id,
						)?.name
						return {
							Commune: commune,
							...row,
						}
					})
					return csv
				}
			}
		}
	}, [pointsAllInterco, coopFiltersTag])

	return convertToCsv
}
