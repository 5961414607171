import ChevronLeftIcon from "@mui/icons-material/ChevronLeft"
import ChevronRightIcon from "@mui/icons-material/ChevronRight"
import GetApp from "@mui/icons-material/GetApp"
import { IconButton } from "@mui/material"
import { BlobProvider, PDFDownloadLink } from "@react-pdf/renderer"
import useHumans from "@/hooks/useHumans"
import React, { useState } from "react"
import { Document, Page, pdfjs } from "react-pdf"
import { connect } from "react-redux"
import styled from "styled-components"
import OverlayLoading from "utils/components/OverlayLoading"
import { PrimaryButton } from "utils/components/style/button"
import { Flex } from "utils/components/style/flex"
import RgpdPDF from "./pdf/RgpdPDF"
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`

const SPDFDownloadLink = styled(PDFDownloadLink)`
    margin: 1em;
`

const StyledEmptyDiv = styled.div`
    width: 160px;
`

const SFlex = styled.div`
    flex-grow: 10;
    display: flex;
    justify-content: center;
    align-items: center;
`

const SPage = styled(Page)`
    box-shadow: 0 30px 40px 0 rgb(16 36 94 / 20%);
`
const Rgpd = ({ commune, jsonSchemas }) => {
	const [numPages, setNumPages] = useState(null)
	const [pageNumber, setPageNumber] = useState(1) //setting 1 to show fisrt page
	const humans = useHumans()
	function onDocumentLoadSuccess({ numPages }) {
		setNumPages(numPages)
		setPageNumber(1)
	}

	function changePage(offset) {
		setPageNumber((prevPageNumber) => prevPageNumber + offset)
	}

	function previousPage() {
		changePage(-1)
	}

	function nextPage() {
		changePage(1)
	}

	const displayNext = pageNumber !== numPages
	const responsableRgpd = humans?.[commune?.responsables?.rgpd] ?? {}
	return (
		<>
			<Flex spaceBetween>
				<StyledEmptyDiv />
				<SFlex>
					<IconButton onClick={previousPage} disabled={pageNumber === 1}>
						<ChevronLeftIcon />
					</IconButton>
					<div>
						Page{" "}
						<b>
							{pageNumber}-{displayNext && pageNumber + 1}
						</b>{" "}
						of {numPages}
					</div>
					<IconButton onClick={nextPage} disabled={pageNumber + 1 === numPages}>
						<ChevronRightIcon />
					</IconButton>
				</SFlex>
				<SPDFDownloadLink
					document={
						<RgpdPDF
							commune={commune}
							jsonSchemas={jsonSchemas}
							responsableRgpd={responsableRgpd}
						/>
					}
					fileName={commune.name + "_RGPD.pdf"}
				>
					{({ blob, url, loading, error }) =>
						loading ? (
							"Loading document..."
						) : (
							<PrimaryButton startIcon={<GetApp />}>Télécharger</PrimaryButton>
						)
					}
				</SPDFDownloadLink>
			</Flex>
			<BlobProvider
				document={
					<RgpdPDF
						commune={commune}
						jsonSchemas={jsonSchemas}
						responsableRgpd={responsableRgpd}
					/>
				}
			>
				{({ blob, url, loading, error }) => {
					// Do whatever you need with blob here
					if (loading) {
						return <OverlayLoading />
					}
					return (
						<div>
							<Document file={url} onLoadSuccess={onDocumentLoadSuccess}>
								<Flex gap={"50px"} justifyCenter>
									<SPage
										pageNumber={pageNumber}
										renderAnnotationLayer={false}
									/>
									{displayNext && (
										<SPage
											pageNumber={pageNumber + 1}
											renderAnnotationLayer={false}
										/>
									)}
								</Flex>
							</Document>
						</div>
					)
				}}
			</BlobProvider>
		</>
	)
}
const mapStateToProps = (state) => ({
	commune: state.commune,
	jsonSchemas: state.data.jsonSchemas,
})
export default connect(mapStateToProps)(Rgpd)
