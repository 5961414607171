import ExpandLess from "@mui/icons-material/ExpandLess"
import ExpandMore from "@mui/icons-material/ExpandMore"
import {
	Avatar,
	Collapse,
	List,
	ListItemAvatar,
	ListItemButton,
	ListItemIcon,
	ListItemText,
} from "@mui/material"
import React, { createContext, useContext } from "react"
import styled, { css } from "styled-components"
import colored from "utils/colored"
import { IExpandStrategy } from "utils/expandStartegy/expandStrategy"
import OneByOne from "utils/expandStartegy/OneByOne"

const NestedListContext = createContext({
	level: 0,
	flat: false,
})

const SListItemButton = styled(ListItemButton)<{
	$compact: boolean
	$reducePaddingLeft: boolean
}>`
    ${({ $compact }) =>
			$compact &&
			css`
            padding-top: 2px !important;
            padding-bottom: 2px !important;
        `}

    ${({ $reducePaddingLeft }) =>
			$reducePaddingLeft &&
			css`
            padding-left: 8px !important;
        `}

    &.Mui-selected {
        outline: solid var(--primary) 1px;
    }
`

const SList = styled(List)<{ $level: number; $flat: boolean }>`
    ${({ $level }) =>
			$level > 0 &&
			css`
            padding-top: 0 !important;
            padding-bottom: 0 !important;
        `}
    ${({ $level, $flat }) =>
			$level > 0 &&
			!$flat &&
			css`
            padding-left: 4px !important;
            border-left: solid var(--neutral500) 2px;
        `}
    ${({ $level, $flat }) =>
			$level > 1 &&
			!$flat &&
			css`
            margin-left: 10px !important;
        `}
`

const LengthIndicator = styled.div<{ $color: string }>`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 20px;
    padding: 0 5px;
    min-width: 20px;
    font-size: var(--font-size-small);
    color: white;
    font-weight: 500;
    border-radius: 10px;
    margin: 0 2px;
    background-color: ${({ $color }) => ($color ? $color : "var(--neutral500)")};
`

const CAvatar = colored(Avatar)
const CListItemIcon = colored(ListItemIcon)

function SuperList<T, O>({
	items,
	children = undefined,
	getItemIcon = undefined,
	getItemTitle,
	getItemSubtitle,
	getItemColor = undefined,
	getItemLength = undefined,
	isItemSelected = undefined,
	isItemDisabled = undefined,
	onItemClick = undefined,
	showItem = undefined,
	flat = undefined,
	compact = false,
	iconVariant = "simple",
	ExpandStrategy = {
		Component: OneByOne,
		props: {},
	} as any,
	StyledList = SList,
}: {
	items: T[]
	children?: (item: T) => JSX.Element
	getItemIcon?: (item: T) => JSX.Element
	getItemTitle: (item: T) => string
	getItemSubtitle?: (item: T) => string
	getItemColor?: (item: T) => string
	getItemLength?: (item: T) => number
	isItemSelected?: (item: T) => boolean
	isItemDisabled?: (item: T) => boolean
	showItem?: (item: T) => boolean
	onItemClick?: (item: T) => void
	flat?: boolean
	compact?: boolean
	iconVariant?: "simple" | "avatar"
	ExpandStrategy?: IExpandStrategy<O>
	StyledList?: typeof SList
}) {
	const nestedListContext = useContext(NestedListContext)
	const computedFlat = flat ?? (nestedListContext.flat || flat)

	return (
		<ExpandStrategy.Component {...ExpandStrategy.props}>
			{({ isOpen, toggle }) => (
				<StyledList $flat={computedFlat} $level={nestedListContext.level}>
					<NestedListContext.Provider
						value={{
							level: nestedListContext.level + 1,
							flat: computedFlat,
						}}
					>
						{items.map((item) => {
							const show = showItem?.(item) ?? true
							if (!show) return null

							const title = getItemTitle(item)
							const subtitle = getItemSubtitle?.(item)
							const icon = getItemIcon?.(item)
							const selected = isItemSelected?.(item) ?? false
							const disabled = isItemDisabled?.(item) ?? false
							const open = isOpen(title)
							const length = getItemLength?.(item)
							const color = getItemColor?.(item)

							return (
								<React.Fragment key={title}>
									{(!computedFlat || !children) && (
										<SListItemButton
											$reducePaddingLeft={
												children && nestedListContext.level === 0
											}
											$compact={compact}
											onClick={() => {
												toggle(title)
												onItemClick?.(item)
											}}
											selected={selected}
											disabled={disabled}
										>
											{icon &&
												(iconVariant === "avatar" ? (
													<ListItemAvatar>
														<CAvatar $backgroundColor={color}>{icon}</CAvatar>
													</ListItemAvatar>
												) : (
													<CListItemIcon $textColor={color}>
														{icon}
													</CListItemIcon>
												))}
											<ListItemText primary={title} secondary={subtitle} />
											{length && (
												<LengthIndicator $color={color}>
													{length}
												</LengthIndicator>
											)}
											{children && (open ? <ExpandLess /> : <ExpandMore />)}
										</SListItemButton>
									)}
									{children && (
										<Collapse
											in={computedFlat || open}
											timeout="auto"
											unmountOnExit
										>
											{children(item)}
										</Collapse>
									)}
								</React.Fragment>
							)
						})}
					</NestedListContext.Provider>
				</StyledList>
			)}
		</ExpandStrategy.Component>
	)
}

export default SuperList
