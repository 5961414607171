import { useSelector } from "react-redux"
import { createSelector } from "@reduxjs/toolkit"

const selectCoucheEvent = createSelector(
	(state: any) => state.daybook.coucheEvents,
	(coucheEvents) => coucheEvents,
)

const useCoucheEvents = () => {
	return useSelector(selectCoucheEvent)
}

export default useCoucheEvents
