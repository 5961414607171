import Send from "@mui/icons-material/Send"
import LoadingButton from "@mui/lab/LoadingButton"
import useSendSmsAlert from "@/hooks/api/useSendSmsAlert"
import Debug from "debug"
import React, { useState } from "react"
import ReactInputMask from "react-input-mask"
import { toast } from "react-toastify"
import DoneOutlinedIcon from "@mui/icons-material/DoneOutlined"
import { H5 } from "utils/components/style/header"
import {
	Explanation,
	SLoadingButton,
	STextField,
	TestContainer,
} from "./DaybookAlertTest.styled"
import useSelectedEvent from "@/hooks/useSelectedEvent"
import { Flex } from "utils/components/style/flex"
import ModalTwilioSwitch from "utils/modals/ModalTwilioSwitch"
import { useBoolean } from "react-use"
import { useSelector } from "react-redux"

const DaybookAlertTest = ({}) => {
	const [phoneTest, setPhoneTest] = useState("")
	const [isLoadingTestCampaign, setIsLoadingTestCampaign] = useState(false)
	const [hasSended, setHasSended] = useState(false)
	const [isOpenSwitchModal, setIsOpenSwitchModal] = useBoolean(false)
	const { sendAlertTest, sendTwilioAlertTest } = useSendSmsAlert()
	const selectedEvent = useSelectedEvent()
	const isLocked = selectedEvent?.locked
	const isOnline = useSelector((state: any) => state.common.isOnline)

	const testingCampaignProcess = async () => {
		if (!hasSended) {
			setIsLoadingTestCampaign(true)
			const res = await sendAlertTest({
				person: {
					name: "Téléphone de test",
					phones: [phoneTest],
				},
			})

			if (res.error) return setIsOpenSwitchModal(true)

			setTimeout(() => {
				toast.success("Envoie du test d'alerte réussi !")
				setIsLoadingTestCampaign(false)
				setHasSended(true)
			}, 3000)
		} else {
			setIsLoadingTestCampaign(false)
			setHasSended(false)
		}
	}

	return (
		<>
			<Flex fullWidth directionColumn alignItemsStart>
				<H5>Testez votre campagne avant de la lancer</H5>
				<TestContainer directionColumn alignItemsStart gap="1rem">
					<Explanation>
						Vous pouvez tester votre campagne avant de la lancer. Pour cela,
						vous devez renseigner un numéro de téléphone portable. Vous recevrez
						un SMS avec le contenu de votre campagne ou un appel téléphonique.
					</Explanation>
					<ReactInputMask
						mask="99 99 99 99 99"
						value={phoneTest}
						onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
							setPhoneTest(event.target.value)
						}
						disabled={isLocked}
					>
						{/** @ts-ignore */}
						{(inputPropsMasked) => (
							<STextField
								label="Numéro de téléphone de test"
								helperText="Utilise des crédits pour l'envoie"
								disabled={isLocked}
								{...inputPropsMasked}
							/>
						)}
					</ReactInputMask>

					<SLoadingButton
						loadingPosition="start"
						startIcon={hasSended ? <DoneOutlinedIcon /> : <Send />}
						$hasSended={hasSended}
						variant="outlined"
						onClick={() => testingCampaignProcess()}
						loading={isLoadingTestCampaign}
						// disabled if regex not match french phone number
						disabled={
							!phoneTest.match(
								/^[0-9]{2} [0-9]{2} [0-9]{2} [0-9]{2} [0-9]{2}$/,
							) || !isOnline
						}
					>
						{isLoadingTestCampaign && "Envoi en cours"}
						{!isLoadingTestCampaign && !hasSended && "Tester la campagne"}
						{!isLoadingTestCampaign &&
							hasSended &&
							"Message envoyé, cliquez pour envoyer un autre message"}
					</SLoadingButton>
				</TestContainer>
			</Flex>
			<ModalTwilioSwitch
				isOpen={isOpenSwitchModal}
				onClose={() => {
					setIsOpenSwitchModal(false)
					setTimeout(() => {
						toast.error("L'envoie du test d'alerte à échoué.")
						setIsLoadingTestCampaign(false)
					}, 3000)
				}}
				onConfirm={() => {
					setIsOpenSwitchModal(false)
					sendTwilioAlertTest({
						person: {
							name: "Téléphone de test",
							phones: [phoneTest],
						},
					})
					setTimeout(() => {
						toast.success("Envoie du test d'alerte réussi !")
						setIsLoadingTestCampaign(false)
						setHasSended(true)
					}, 3000)
				}}
			/>
		</>
	)
}

export default DaybookAlertTest
