import {
	Alert,
	DialogActions,
	DialogContent,
	DialogTitle,
	LinearProgress,
	Paper,
	Radio,
	TableCell,
} from "@mui/material"
import { ColumnDef } from "@tanstack/react-table"
import { MAPPING_TOOL_KEY } from "@/pages/admin/mapping/mappingTools/text/key"
import _ from "lodash"
import React, { useEffect, useState } from "react"
import styled from "styled-components"
import usePointPropertyToText from "utils/components/jsonSchema/properties/usePointPropertyToText"
import { PrimaryButton } from "utils/components/style/button"
import { Flex } from "utils/components/style/flex"
import SuperReactTable from "utils/components/tables/SuperReactTable/SuperReactTable"
import { width50, width80 } from "utils/components/tables/widthProps"

const SolveDetails = styled.div`
				color: var(--orange700);
				padding-bottom: 10px;
				font-size: 0.94em;
`

const UnicityErrorSolver = ({
	mappingSchema,
	csvData,
	setCsvData,
	setCurrentStep,
	currentStep,
	jsonSchema,
}) => {
	const [csvDataWithErrors, setCsvDataWithErrors] = useState(undefined)
	const [newCsvData, setNewCsvData] = useState([])
	const [nbErrors, setNbErrors] = useState(undefined)
	const [itemSelected, setItemSelected] = useState(undefined)
	const pointPropertyToText = usePointPropertyToText()

	const relationKeys = Object.keys(mappingSchema).filter(
		(k) => mappingSchema[k].converter === MAPPING_TOOL_KEY,
	)

	//* Check unicity errors
	useEffect(() => {
		const dataDuplicatedStore = {}
		debugger
		csvData.forEach((item, index) => {
			item.properties = { lignePos: index + 2 }

			let key = ""
			Object.values(mappingSchema).forEach((k: any) => {
				if (k.converter === MAPPING_TOOL_KEY) {
					key += item[k.singleInputs.field]
				}
			})

			if (!dataDuplicatedStore[key]) dataDuplicatedStore[key] = []
			dataDuplicatedStore[key].push(item)
		})

		const tempCsvDataWithErrors = Object.values(dataDuplicatedStore).filter(
			(items: any[]) => items.length > 1,
		)

		setNewCsvData(csvData)
		setCsvDataWithErrors(tempCsvDataWithErrors)
		setNbErrors(tempCsvDataWithErrors.length)
	}, [])

	const erroredDatas = csvDataWithErrors?.[0] ?? []
	const errorMessage = () => {
		const fieldsWords = relationKeys.length > 1 ? "les champs" : "le champ"
		const shouldWord = relationKeys.length > 1 ? "doivent" : "doit"
		return (
			"Erreur d'unicité, " +
			fieldsWords +
			" " +
			relationKeys.join(" & ") +
			" " +
			shouldWord +
			" être unique, hors, plusieurs lignes ont la même valeur."
		)
	}

	//* Solve unicity errors
	const solveDuplicatedUniqValues = () => {
		const tempNewCsvData = _.cloneDeep(newCsvData)
		erroredDatas.forEach((item) => {
			if (item.properties.toImport === false) {
				delete item.properties.toImport
				_.remove(tempNewCsvData, item)
			}
		})

		setItemSelected(undefined)
		setNewCsvData(tempNewCsvData)
		const remainingErrors = _.drop(csvDataWithErrors)

		setCsvDataWithErrors(remainingErrors)
	}

	//* Exit when all resolved
	useEffect(() => {
		if (!_.isEqual(csvDataWithErrors?.length, 0)) {
			return
		}

		setCsvData(newCsvData)
		setCurrentStep(currentStep + 1)
	}, [csvDataWithErrors])

	//* Applying properties param to manage which item will be keeped
	useEffect(() => {
		erroredDatas.forEach(
			(item, i) =>
				(item.properties = {
					...item.properties,
					toImport: i === itemSelected,
				}),
		)
	}, [itemSelected])

	const columns = [
		{
			header: "Choisir",
			accessorKey: "id",
			...width50,
			enableSorting: false,
			cell: ({ row }) => (
				<Radio
					checked={row.index === itemSelected}
					onChange={() => setItemSelected(row.index)}
				/>
			),
		},
		{
			header: "Ligne",
			accessorKey: "properties.lignePos",
			...width80,
			enableSorting: false,
			cell: ({ row }) => {
				return <TableCell>Ligne {row.original.properties.lignePos}</TableCell>
			},
		},
		{
			header: "Identifiant",
			accessorKey: "properties.identifiant",
			...width80,
			enableSorting: false,
			cell: ({ row }) => {
				let key = ""
				Object.values(mappingSchema).forEach((k: any) => {
					if (k.converter === MAPPING_TOOL_KEY) {
						key += row.original[k.singleInputs.field]
					}
				})
				return <TableCell>{key}</TableCell>
			},
		},
		// ...jsonSchema.template.properties
		// 	.filter((prop) => prop.isColumn || prop.isIdentifiant)
		// 	.map(
		// 		(property) =>
		// 			({
		// 				header: property.label,
		// 				accessorFn: (data) => pointPropertyToText(data, property),

		// 				cell: ({ row }) => {
		// 					debugger
		// 					return <div>{row.original[property.name]}</div>
		// 				},
		// 			}) as ColumnDef<any>,
		// 	),
	]

	return (
		<Paper>
			<DialogTitle>
				Résolution des erreurs{" "}
				{erroredDatas.length ? (
					<>
						({nbErrors - erroredDatas.length + 1}/{nbErrors})
					</>
				) : (
					<>(terminée)</>
				)}
			</DialogTitle>
			<LinearProgress
				variant="determinate"
				value={((nbErrors - erroredDatas.length) / nbErrors) * 100}
			/>
			<DialogContent dividers>
				{erroredDatas.length ? (
					<Flex alignItemsStart directionColumn gap>
						<SolveDetails>{errorMessage()}</SolveDetails>
						<Alert severity="info">
							Choisissez la donnée à garder pour l'import, les autres ne seront
							pas comptabilisées. (Pour importer plus de données, veuillez
							rectifier vôtre fichier CSV aux lignes indiqués puis réimporter
							le.)
						</Alert>
						<SuperReactTable
							data={erroredDatas}
							columns={columns}
							isEditable={false}
							isDeletable={false}
							selectable={false}
							onRowClick={(row) => setItemSelected(row.index)}
						/>
					</Flex>
				) : (
					<Alert severity="success">
						Toutes les données sont a présent valides
					</Alert>
				)}
			</DialogContent>
			<DialogActions>
				<Flex alignItemsEnd>
					<PrimaryButton
						onClick={() => solveDuplicatedUniqValues()}
						disabled={!itemSelected && itemSelected !== 0}
					>
						Conserver la donnée sélectionnée et{" "}
						{erroredDatas.length <= 1 ? <>terminer</> : <>passer au suivant</>}
					</PrimaryButton>
				</Flex>
			</DialogActions>
		</Paper>
	)
}

export default UnicityErrorSolver
