import ChildFriendlyIcon from "@mui/icons-material/ChildFriendly"
import EmojiPeopleIcon from "@mui/icons-material/EmojiPeople"
import PersonIcon from "@mui/icons-material/Person"
import PetsIcon from "@mui/icons-material/Pets"
import { Badge, TextField } from "@mui/material"
import _ from "lodash"
import React, { useState } from "react"
import styled, { css } from "styled-components"
import {
	BrownOutlinedButton,
	GreenOutlinedButton,
	OrangeOutlinedButton,
	PurpleOutlinedButton,
} from "@/utils/components/style/button"
import { Flex } from "@/utils/components/style/flex"
import IFamilyMember, { IFamilyMemberType } from "@/utils/types/IFamilyMember"

const SBadge = styled(Badge)`
    height: 40px; //* to be equal with right TextField
`
const STextField = styled(TextField)`
    width: 4em;
    margin-right: -1px; //* to superpose TextField and Button borders
    transition: all 0.2s ease-in;
    & fieldset {
        height: 47px !important;
        top: -7px !important;
        border-top-right-radius: 0px !important;
        border-bottom-right-radius: 0px !important;
    }
    & .Mui-focused > fieldset {
        //height: 45px !important;
        //top: -5px !important;
    }
`

interface IMemberControl {
	family: IFamilyMember[]
	memberType: IFamilyMemberType
	disabled?: boolean
	appendMembers: (type: string, quantity: number | "") => void
}

const commonButtonStyle = (color) => css`
    min-width: 200px !important;
    color: ${color};
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
`
const SGreenOutlinedButton = styled(GreenOutlinedButton)`
    ${commonButtonStyle("var(--green500)")}
`
const SOrangeOutlinedButton = styled(OrangeOutlinedButton)`
    ${commonButtonStyle("var(--orange500)")}
`
const SPurpleOutlinedButton = styled(PurpleOutlinedButton)`
    ${commonButtonStyle("var(--purple500)")}
`
const SBrownOutlinedButton = styled(BrownOutlinedButton)`
    ${commonButtonStyle("#513915")}
`

const familyMemberControlProps = {
	[IFamilyMemberType.ADULT]: {
		Button: (buttonProps) => (
			<SGreenOutlinedButton {...buttonProps} startIcon={<PersonIcon />} />
		),
	},
	[IFamilyMemberType.CHILD]: {
		Button: (buttonProps) => (
			<SOrangeOutlinedButton {...buttonProps} startIcon={<EmojiPeopleIcon />} />
		),
	},
	[IFamilyMemberType.BABY]: {
		Button: (buttonProps) => (
			<SPurpleOutlinedButton
				{...buttonProps}
				startIcon={<ChildFriendlyIcon />}
			/>
		),
	},
	[IFamilyMemberType.ANIMAL]: {
		Button: (buttonProps) => (
			<SBrownOutlinedButton {...buttonProps} startIcon={<PetsIcon />} />
		),
	},
}

const MembersControl = ({
	family,
	memberType,
	disabled = false,
	appendMembers,
}: IMemberControl) => {
	const [nbSelectedMembers, setNbSelectedMember] = useState<number | "">(1)
	const { Button } = familyMemberControlProps[memberType]

	const getMemberCount = (type) => {
		let nbMember = 0
		family
			.filter((familyMember) => familyMember.type === type)
			.forEach((memberObject) => (nbMember += memberObject.quantity ?? 1))
		return nbMember
	}

	const displayTypeName = (type) => {
		let returnedName = ""
		if (type === IFamilyMemberType.ADULT) {
			returnedName = "Adulte" + (nbSelectedMembers > 1 ? "s" : "")
		} else if (type === IFamilyMemberType.CHILD) {
			returnedName = "Enfant" + (nbSelectedMembers > 1 ? "s" : "")
		} else if (type === IFamilyMemberType.BABY) {
			returnedName = "Bébé" + (nbSelectedMembers > 1 ? "s" : "")
		} else if (type === IFamilyMemberType.ANIMAL) {
			returnedName = nbSelectedMembers > 1 ? "Animaux" : "Animal"
		}
		return returnedName
	}

	return (
		<Flex gap="1em" fullWidth>
			<Flex alignItemsEnd>
				<STextField
					type="text"
					size="small"
					disabled={disabled}
					value={nbSelectedMembers}
					onChange={(e) => {
						const newValue = parseInt(e.currentTarget.value) //* Keep only pressed number keys
						if (newValue === nbSelectedMembers) return //* New pressed key isn't a number
						if (_.isNaN(newValue)) return setNbSelectedMember("") //* TextField is empty by deleting
						setNbSelectedMember(newValue)
					}}
				/>
				<SBadge
					badgeContent={getMemberCount(memberType)}
					color="primary"
					max={999}
				>
					<Button
						onClick={() => {
							appendMembers(memberType, nbSelectedMembers)
							setNbSelectedMember(1) //* Reset
						}}
						disabled={disabled}
					>
						Ajouter {nbSelectedMembers} {displayTypeName(memberType)}
					</Button>
				</SBadge>
			</Flex>
		</Flex>
	)
}
export default MembersControl
