import React from "react"
import HeaderBanner from "utils/pdf/header/HeaderBanner"
import PPage from "utils/pdf/PPage"
import PTitle from "utils/pdf/PTitle"
import SmallText from "utils/pdf/SmallText"
import PCell from "utils/pdf/table/PCell"
import PRow from "utils/pdf/table/PRow"
import PTable from "utils/pdf/table/PTable"
import PointLinkedFilePdf from "./PointLinkedFilePdf"
import PH2 from "utils/pdf/header/PH2"

const DaybookArretesTable = ({
	selectedEvent,
	arretes,
	objectOfArretesBlobsObject,
	indexInSummary,
	childIndexInSummary,
}) => {
	const arretesPdfs = Object.values(arretes).filter(
		(arrete: any) => arrete.event_id === selectedEvent.id,
	)

	return (
		<>
			<PPage orientation="landscape">
				<HeaderBanner>
					<PH2 marginBottom="0px">{indexInSummary}. Main courante</PH2>
				</HeaderBanner>
				<PTitle color="#2e74b5" marginBottom={10}>
					{indexInSummary}.{childIndexInSummary}. Arrêtés municipaux
				</PTitle>
				<PTable>
					<PRow>
						<PCell minHeight={0} width={70} head>
							<SmallText>Date</SmallText>
						</PCell>
						<PCell minHeight={0} head>
							<SmallText>Type</SmallText>
						</PCell>
					</PRow>
					{arretesPdfs.map((arrete: any) => {
						return (
							<PRow key={arrete.id}>
								<PCell
									width={70}
									style={{
										display: "flex",
										flexDirection: "column",
									}}
								>
									<SmallText>{arrete.date.slice(0, 10)}</SmallText>
									<SmallText>à {arrete.date.slice(11, 16)}h</SmallText>
								</PCell>
								<PCell>
									<SmallText>{arrete.title}</SmallText>
								</PCell>
							</PRow>
						)
					})}
				</PTable>
			</PPage>
			{Object.values(objectOfArretesBlobsObject)
				.reverse()
				.map((blobsObject: any) => {
					return Object.values(blobsObject).map((blob, index) => {
						return (
							<PPage key={index + 1}>
								<PointLinkedFilePdf fileBlobs={[{ blob }]} mustBreak={false} />
							</PPage>
						)
					})
				})}
		</>
	)
}

export default DaybookArretesTable
