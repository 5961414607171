import { Card, CardContent, CardHeader, Slider } from "@mui/material"
import Debug from "debug"
import React, { useEffect } from "react"
import { useLocalStorage } from "react-use"
import styled from "styled-components"
import { PrimaryOutlinedButton } from "utils/components/style/button"

// app:javascript:components:configuration:Accessibility.tsx
const debug = Debug("app:javascript:components:configuration:Accessibility.tsx")
debug.log = console.log.bind(console)

const StyledCard = styled(Card)`
    width: 50%;
    margin: 1rem;
`

const Accessibility = () => {
	const [fontSize, setFontSize] = useLocalStorage("accessibility:fontSize", 16)
	useEffect(() => {
		document.documentElement.style.setProperty(
			"--font-size-base",
			`${fontSize}px`,
		)
	}, [fontSize])

	return (
		<>
			<StyledCard>
				<CardHeader
					title="Taille de la police"
					action={
						<PrimaryOutlinedButton onClick={() => setFontSize(16)}>
							Réinitialiser
						</PrimaryOutlinedButton>
					}
				/>
				<CardContent>
					<div>Taille de la police : {fontSize}</div>
					<Slider
						value={fontSize}
						onChange={(e, value: number) => setFontSize(value)}
						step={1}
						marks
						min={12}
						max={20}
					/>
				</CardContent>
			</StyledCard>
		</>
	)
}

export default Accessibility
