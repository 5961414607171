import React from "react"
import { Text, View } from "@react-pdf/renderer"
import PH2 from "utils/pdf/header/PH2"
import PPage from "utils/pdf/PPage"
import PImage from "utils/pdf/image/PImage"
import getImage from "@/utils/getImage"
import {
	INCIDENT,
	INTERVENTION,
	TEMPORARY_CARE,
} from "@/redux-toolkit/userSettings/constants"
import styled from "styled-components"
import HeaderBanner from "utils/pdf/header/HeaderBanner"
import NormalText from "utils/pdf/NormalText"
import PTitle from "utils/pdf/PTitle"

const Spacer = styled(Text)``

const DaybookMapPage = ({
	staticPoints,
	coucheEvents,
	jsonSchemas,
	multiPolygons,
	mapSignalMarkers,
	coucheJsonSchemas,
	coucheMultiPolygons,
	screenshotBlobs,
	existingSignalMarker,
	indexInSummary,
}) => {
	const couchesEventPoints = []
	const notedLegendLabels = []
	Object.values(staticPoints).forEach((points: any) => {
		if (!points.length) return
		points.forEach((point) => {
			let type = ""
			let label = ""
			let imageSrc = ""

			if (point.jsonschema_id === INCIDENT) {
				if (point.geojson.properties.Statut === "En cours") {
					type = INCIDENT
					label = "Événements en cours"
					imageSrc = "/img/maincourante/evenement_encours_normale.png"
				} else {
					type = INCIDENT
					label = "Événements terminés"
					imageSrc = "/img/maincourante/evenement_termine_normale.png"
				}
			}
			if (point.jsonschema_id === INTERVENTION) {
				if (point.geojson.properties.Statut === "A faire") {
					type = INTERVENTION
					label = "Interventions à faire"
					imageSrc = "/img/maincourante/intervention_afaire_normale.png"
				} else if (point.geojson.properties.Statut === "En cours") {
					type = INTERVENTION
					label = "Interventions en cours"
					imageSrc = "/img/maincourante/intervention_encours_normale.png"
				} else {
					type = INTERVENTION
					label = "Interventions terminés"
					imageSrc = "/img/maincourante/intervention_termine_normale.png"
				}
			}
			if (point.jsonschema_id === TEMPORARY_CARE) {
				type = TEMPORARY_CARE
				label = "Centres d'accueil"
				imageSrc = "/img/maincourante/care.png"
			}

			if (notedLegendLabels.includes(label)) return

			notedLegendLabels.push(label)
			couchesEventPoints.push({
				type: type,
				label: label,
				imageSrc: imageSrc,
			})
		})
	})

	const couchesPoints = Object.values(coucheJsonSchemas).filter(
		(couche: any) =>
			couche.id !== INCIDENT &&
			couche.id !== INTERVENTION &&
			couche.id !== TEMPORARY_CARE,
	)

	const legendCouchesMultiPolygons = []
	Object.values(coucheMultiPolygons).forEach((couche: any) => {
		legendCouchesMultiPolygons.push({
			id: couche.id,
			name: multiPolygons[couche.id].name,
			color: multiPolygons[couche.id].custom_props.style.color,
			fillColor: multiPolygons[couche.id].custom_props.style.fillColor,
		})
	})

	const isAlreadyInclude = []
	const legendSignalMarkers = []
	Object.values(mapSignalMarkers).forEach((marker: any) => {
		if (!isAlreadyInclude.includes(marker.type)) {
			isAlreadyInclude.push(marker.type)
			legendSignalMarkers.push({ name: marker.type, icon: null })
		}
	})

	const filterActives = (type: string, array) => {
		if (type === "static_points" || type === "signals") {
			return type === "static_points"
				? array.filter(
						(staticPoint: any) => coucheEvents[staticPoint.type] !== false,
				  )
				: array.filter((signal: any) => coucheEvents[signal.name] !== false)
		}
		if (type === "jsonschema_points") {
			return array.filter(
				(jsonschemaPoint: any) => jsonschemaPoint.visibility !== 0,
			)
		}
		if (type === "multipolygons") {
			return array.filter(
				(multipoly: any) => coucheMultiPolygons[multipoly.id].visibility !== 0,
			)
		}
	}

	const activesEventPoints = filterActives("static_points", couchesEventPoints)
	const activesJsonschemaPoints = filterActives(
		"jsonschema_points",
		couchesPoints,
	)
	const activesMultipolygons = filterActives(
		"multipolygons",
		legendCouchesMultiPolygons,
	)
	const activesSignals = filterActives("signals", legendSignalMarkers)

	return (
		<>
			<PPage orientation="landscape">
				<HeaderBanner>
					<PH2 marginBottom="0px">{indexInSummary}. Cartographie</PH2>
				</HeaderBanner>
				<PImage
					src={screenshotBlobs.mapScreenBlob}
					alt="Capture d'écran de la cartographie"
				/>
			</PPage>
			<PPage>
				<HeaderBanner>
					<PH2 marginBottom="0px">Légende cartographie</PH2>
				</HeaderBanner>
				<PTitle color="#2e74b5" marginBottom={10}>
					Données iconographiques :
				</PTitle>
				<View
					style={{
						width: "100%",
						display: "flex",
						flexDirection: "row",
						flexWrap: "wrap",
					}}
				>
					{activesEventPoints.map((couche: any, index) => (
						<View
							key={index}
							style={{
								width: "40%",
								display: "flex",
								flexDirection: "row",
								marginBottom: "10px",
								alignItems: "center",
								marginRight: "10%",
							}}
						>
							<PImage
								src={couche.imageSrc}
								alt="[Icône]"
								style={{
									width: "30px",
									height: "30px",
									minWidth: "30px",
									minHeight: "30px",
								}}
							/>
							<NormalText
								style={{
									paddingLeft: "10px",
								}}
							>
								{couche.label}
							</NormalText>
						</View>
					))}
					{activesJsonschemaPoints.map((couche: any, index) => {
						const src = getImage(jsonSchemas[couche.id].imgId)
						return (
							<View
								key={index}
								style={{
									width: "40%",
									display: "flex",
									flexDirection: "row",
									marginBottom: "10px",
									alignItems: "center",
									marginRight: "10%",
								}}
							>
								<PImage
									src={{
										uri: src,
										cache: false,
										method: "GET",
										headers: {
											"Cache-Control": "no-cache",
											"Allow-Control-Allow-Origin": "*",
										},
									}}
									alt="[Icône]"
									style={{
										width: "30px",
										height: "30px",
										minWidth: "30px",
										minHeight: "30px",
									}}
								/>
								<NormalText
									style={{
										paddingLeft: "10px",
									}}
								>
									{jsonSchemas[couche.id].title}
								</NormalText>
							</View>
						)
					})}
				</View>

				<Spacer style={{ marginBottom: "14px" }} />

				<PTitle color="#2e74b5" marginBottom={10}>
					Données surfaciques :
				</PTitle>
				<View
					style={{
						display: "flex",
						flexDirection: "row",
						justifyContent: "space-between",
						flexWrap: "wrap",
					}}
				>
					{activesMultipolygons.map((legend: any, index) => (
						<View
							key={index}
							style={{
								width: "40%",
								display: "flex",
								flexDirection: "row",
								marginBottom: "10px",
								alignItems: "center",
								marginRight: "10%",
							}}
						>
							<NormalText
								style={{
									minWidth: "35px",
									minHeight: "20px",
									backgroundColor: legend.fillColor,
									marginRight: "10px",
								}}
							/>
							<NormalText style={{ flexWrap: "wrap" }}>
								{legend.name}
							</NormalText>
						</View>
					))}
				</View>

				<Spacer style={{ marginBottom: "14px" }} />

				<PTitle color="#2e74b5" marginBottom={10}>
					Signalétiques :
				</PTitle>
				<View
					style={{
						display: "flex",
						flexDirection: "row",
						justifyContent: "space-between",
						flexWrap: "wrap",
					}}
				>
					{activesSignals.map((legend: any, index) => {
						return (
							<View
								key={index}
								style={{
									width: "40%",
									display: "flex",
									flexDirection: "row",
									marginBottom: "10px",
									alignItems: "center",
									marginRight: "10%",
								}}
							>
								<PImage
									src={existingSignalMarker[legend.name]?.imgSrc}
									alt="PNG"
									width="24px"
									height="24px"
								/>
								<NormalText
									style={{
										paddingLeft: "10px",
									}}
								>
									{legend.name}
								</NormalText>
							</View>
						)
					})}
				</View>
			</PPage>
		</>
	)
}
export default DaybookMapPage
