import styled from "styled-components"
import { EnumToolPath } from "@/hooks/services/useAccessRightsServices"
import { Listbox } from "@nextui-org/react"
import { Flex } from "@/utils/components/style/flex"
import { ReactElement } from "react"

export const SFlex = styled(Flex)`
	margin-top: calc(var(--height-top-bar) + 1rem);
`



export const SListbox = styled(Listbox as any)`
	max-width: 400px;
` as any as typeof Listbox
