import React from "react"
import styled from "styled-components"
import { Flex } from "./style/flex"
import _ from "lodash"
const MyList = styled.ul`
    margin-bottom: 0 !important;
`

const NoWrap = styled.span`
    white-space: nowrap;
`

function PhonesCell({ phonesList = [] }) {
	if (_.isEmpty(phonesList)) {
		return <span>Non renseigné</span>
	}
	return (
		<Flex fullHeight>
			<MyList>
				{phonesList.map((phone) => (
					<li key={phone?.Numéro}>
						{phone?.Type} : <NoWrap>{phone?.Numéro}</NoWrap>
					</li>
				))}
			</MyList>
		</Flex>
	)
}

export default PhonesCell
