import { Alert } from "@mui/material"
import useFiches from "@/hooks/useFiches"
import { useOrgaWithOrder } from "@/hooks/useOrga"
import useUploadedFiles from "@/hooks/useUploadedFiles"
import { deleteUploadedFile } from "@/redux-toolkit/data/uploadedFile/resources"
import React, { useMemo } from "react"
import { useDispatch } from "react-redux"
import styled from "styled-components"
import { Flex } from "@/utils/components/style/flex"
import Modal from "@/styles/organisms/Modal"
import ModalHeader from "@/styles/organisms/Modal/ModalHeader"
import ModalBody from "@/styles/organisms/Modal/ModalBody"
import ModalFooter from "@/styles/organisms/Modal/ModalFooter"
import { ModalContent } from "@nextui-org/react"
import CancelButton from "@/styles/atoms/Button/specialized/CancelButton"
import Button from "@/styles/atoms/Button"
const SAlert = styled(Alert)`
    & ul {
        margin-bottom: 0;
    }

    & p {
        margin-bottom: 0.5em;
    }
`

const ModalSafeDelete = ({ isOpen, onClose, file }) => {
	if (!file) return null
	const dispatch = useDispatch()

	const uploadedFiles = useUploadedFiles()
	const fiches = useFiches()
	const { cellules } = useOrgaWithOrder()

	const { linkedFiles, linkedFiches } = useMemo(() => {
		const linkedFiles = Object.values(uploadedFiles).filter((uploadedFile) => {
			return (
				"pcs" in uploadedFile.config &&
				"dependence" in uploadedFile.config.pcs &&
				uploadedFile.config.pcs.dependence === file.id
			)
		})
		const linkedFiches = Object.values(fiches).filter((fiche) =>
			Object.values(fiche.actions).find((action) =>
				action["Liens"]?.find((lien) => {
					return lien.id === file.id || lien.key === file.id
				}),
			),
		)

		return { linkedFiles, linkedFiches }
	}, [uploadedFiles, fiches, file])

	const deleteFile = async () => {
		await dispatch(deleteUploadedFile(file.id))
		onClose()
	}

	return (
		<Modal isOpen={isOpen} onClose={onClose}>
			<ModalContent>
				{linkedFiles.length > 0 ? (
					<>
						<ModalHeader>Fichier non supprimable</ModalHeader>
						<ModalBody>
							<SAlert severity="warning">
								<p>
									Le fichier ne peut pas être supprimé car les fichier suivants
									en dépendent :
								</p>
								<ul>
									{linkedFiles.map((file) => (
										<li key={file.filename}>{file.filename}</li>
									))}
								</ul>
							</SAlert>
						</ModalBody>
						<ModalFooter>
							<CancelButton onClick={onClose} />
						</ModalFooter>
					</>
				) : (
					<>
						<ModalHeader>Confirmer</ModalHeader>
						<ModalBody>
							<Flex directionColumn alignItemsStretch gap>
								<div>Voulez vous vraiment supprimer ce fichier ?</div>
								{linkedFiches.length > 0 && (
									<SAlert severity="warning">
										<p>
											Des fiches contiennent un ou plusieurs liens vers ce
											fichier. Si vous le supprimez, les liens seront
											automatiquement retirés :
										</p>
										<ul>
											{linkedFiches.map((fiche) => (
												<li key={fiche.id}>
													{cellules[fiche.nom]?.idName ?? fiche.nom}
												</li>
											))}
										</ul>
									</SAlert>
								)}
							</Flex>
						</ModalBody>
						<ModalFooter>
							<CancelButton onClick={onClose} />
							<Button color="primary" onClick={deleteFile}>
								Confirmer
							</Button>
						</ModalFooter>
					</>
				)}
			</ModalContent>
		</Modal>
	)
}

export default ModalSafeDelete
