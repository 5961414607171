import useJsonSchemasWithStatics from "@/hooks/useJsonSchemasWithStatics"
import usePoints from "@/hooks/usePoints"
import useSelectedEvent from "@/hooks/useSelectedEvent"
import useJsonSchemaColumns from "@/pages/admin/formulaire_dynamique/MyForms/useJsonSchemaColumns"
import { TEMPORARY_CARE } from "@/redux-toolkit/userSettings/constants"
import CancelButton from "@/styles/atoms/Button/specialized/CancelButton"
import Modal from "@/styles/organisms/Modal"
import ModalFooter from "@/styles/organisms/Modal/ModalFooter"
import ModalHeader from "@/styles/organisms/Modal/ModalHeader"
import { H4 } from "@/utils/components/style/header"
import SuperReactTable from "@/utils/components/tables/SuperReactTable/SuperReactTable"
import { width80 } from "@/utils/components/tables/widthProps"
import ModalBodyForTable from "@/utils/modals/ModalBodyForTable"
import { StaticFormType } from "@/utils/services/StaticFormsService"
import { Radio } from "@mui/material"
import { ModalContent } from "@nextui-org/react"
import Debug from "debug"
import _ from "lodash"
import React, { useMemo, useState } from "react"
import FormTmpCare from "../form/FormTmpCare"
import { ColumnDef } from "@tanstack/react-table"
// app:javascript:components:maincourante:care:modals:ModalCAREOpening.tsx
const debug = Debug(
	"app:javascript:components:maincourante:care:modals:ModalCAREOpening",
)
debug.log = console.log.bind(console)

const ModalCAREOpening = ({ isOpen, onClose }) => {
	const [currentTmpCare, setCurrentTmpCare] = useState(undefined)
	const points = usePoints()
	const jsonSchemasWithStatics = useJsonSchemasWithStatics()
	const event = useSelectedEvent()
	const concernedPoints = Object.values(points).filter(
		(point) => point.jsonschema_id === StaticFormType.CARE,
	)
	const alreadyOpenedCare = useMemo(() => {
		return Object.values(points).filter(
			(point) =>
				point.jsonschema_id === TEMPORARY_CARE && point.event_id === event?.id,
		)
	}, [event, points])
	const sortedConcernedCare = _.sortBy(concernedPoints, (point) => {
		if (point.geojson.properties["Ouverture"] === "Prioritaire") {
			return 0
		} else {
			return 1
		}
	}).filter((care) => {
		if (
			alreadyOpenedCare.some(
				(tmpCare) =>
					tmpCare?.geojson?.properties?.Lieu ===
						care?.geojson?.properties?.Lieu &&
					_.isEqual(
						tmpCare?.geojson?.properties?.Adresse,
						care?.geojson?.properties?.Adresse,
					),
			)
		) {
			return false
		}
		return true
	})
	const columns = useJsonSchemaColumns(
		jsonSchemasWithStatics[StaticFormType.CARE],
	)
	const augmentedColumns = [
		{
			header: "Choisir",
			accessorKey: "id",
			cell: ({ getValue }) => {
				return <Radio />
			},
			...width80,
		},
		...columns,
	] as ColumnDef<any>[]

	return (
		<>
			<Modal
				size="5xl"
				isOpen={isOpen}
				onClose={() => {
					onClose()
					setCurrentTmpCare(undefined)
				}}
			>
				<ModalContent>
					<ModalHeader>
						<H4>Ouvrir un centre d&apos;accueil</H4>
					</ModalHeader>
					{!currentTmpCare && (
						<ModalBodyForTable>
							<SuperReactTable
								selectable={false}
								onRowClick={(row) =>
									setCurrentTmpCare({
										geojson: {
											properties: {
												...row.original.geojson.properties,
												Responsable: (
													row.original.geojson.properties["Responsable"] ?? []
												).map((resp) => ({
													label: resp,
													value: resp,
												})),
												Capacité:
													row.original.geojson.properties["Capacité debout"] ??
													row.original.geojson.properties["Capacité couché"],
											},
											geometry: row.original.geojson.geometry,
										},
									})
								}
								data={sortedConcernedCare}
								columns={augmentedColumns}
								isEditable={false}
								isDeletable={false}
							/>
						</ModalBodyForTable>
					)}
					{currentTmpCare && (
						<FormTmpCare
							currentTmpCare={currentTmpCare}
							setCurrentTmpCare={setCurrentTmpCare}
							onClose={onClose}
						/>
					)}
					{!currentTmpCare && (
						<ModalFooter>
							<CancelButton onClick={onClose} />
						</ModalFooter>
					)}
				</ModalContent>
			</Modal>
		</>
	)
}

export default ModalCAREOpening
