import { H5 } from "utils/components/style/header"
import { CardContainer } from "./CampaignCostCard.styled"
import React from "react"
import {
	Alert,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
} from "@mui/material"
import { useCampaignCost } from "./CampaignCostCard.hook"
import useCommune from "@/hooks/useCommune"
import useSelectedEvent from "@/hooks/useSelectedEvent"

const CampaignCostCard = () => {
	const { cost, campaignRemainingCost } = useCampaignCost()
	const commune = useCommune()
	const selectedEvent = useSelectedEvent()
	const isLocked = selectedEvent?.locked
	const freeAccount = commune?.free_credits === 0

	const displayEuros = (value: number) => {
		// 16,49 must be "16€ et 49 centimes"
		// 1302,17 must be "1 302€ et 17 centimes"
		const euros = Math.floor(value)
		const cents = Math.round((value - euros) * 100)
		return `${euros}€ et ${cents} centimes`
	}

	return (
		<CardContainer directionColumn gap="2rem" alignItemsInitial>
			<H5>Coût de la campagne</H5>
			<TableContainer>
				<Table>
					<TableHead>
						<TableRow>
							<TableCell>Intitulé</TableCell>
							<TableCell>Coût</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{Object.entries(cost).map(([key, value]) => (
							<TableRow key={key}>
								<TableCell>{key}</TableCell>
								<TableCell>{Math.round(value * 10) / 10}</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
			{!isLocked && (
				<>
					{cost["Nombre de crédits gratuits restants"] <
						cost["Cout de la campagne en crédits"] &&
						!freeAccount && (
							<Alert severity="warning">
								Vous n&apos;avez pas suffisamment de crédits gratuits pour
								lancer cette campagne. Celle-ci sera facturée à votre prochaine
								facturation : 8 centimes par crédit manquant, soit{" "}
								{displayEuros(Math.round(campaignRemainingCost * 1000) / 1000)}
							</Alert>
						)}
					{cost["Nombre de crédits gratuits restants"] <
						cost["Cout de la campagne en crédits"] &&
						freeAccount && (
							<Alert severity="error">
								Vous n&apos;avez pas suffisamment de crédits gratuits pour
								lancer cette campagne. Si vous souhaitez continuer à utiliser le
								service, veuillez contacter votre référent Numérisk. Cette
								campagne aurait coté{" "}
								{displayEuros(Math.round(campaignRemainingCost * 1000) / 1000)}
							</Alert>
						)}
					{cost["Nombre de crédits gratuits restants"] >=
						cost["Cout de la campagne en crédits"] && (
						<Alert severity="success">
							Vous avez suffisamment de crédits gratuits pour lancer cette
							campagne.
						</Alert>
					)}
				</>
			)}
		</CardContainer>
	)
}

export default CampaignCostCard
