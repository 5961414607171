import React, { useMemo } from "react"
import { Button, Container } from "@mui/material"
import usePoints from "@/hooks/usePoints"
import Moment from "moment"
import { useBoolean } from "react-use"
import AssessmentIcon from "@mui/icons-material/Assessment"

import styled from "styled-components"
import { Flex } from "utils/components/style/flex"
import BoardWidget from "utils/components/widget/BoardWidget"
import CommuneDataStatus from "./CommuneDataStatus"
import useJsonSchemas from "@/hooks/useJsonSchemas"
import { H6 } from "utils/components/style/header"

export const RED_STATUS = "RED_STATUS"
export const ORANGE_STATUS = "ORANGE_STATUS"
export const GREEN_STATUS = "GREEN_STATUS"

const SContainer = styled(Container)`
    padding: 2em;
    text-align: center;
`

import DoneIcon from "@mui/icons-material/Done"
import useIsInterco from "@/hooks/useIsInterco"
import intercoNameAdapter from "utils/intercoNameAdapter"
import { PrimaryTextButton } from "utils/components/style/button"
import _ from "lodash"

const SFlex = styled(Flex)`
    height: 100%;
    justify-content: center;
    align-items: center;
    padding-bottom: 24px;
` as any
const StyledDoneIcon = styled(DoneIcon)`
    color: green !important;
`
const SAssessmentIcon = styled(AssessmentIcon)`
    color: white;
` as any

const NumeriskIndicatorsPcsData = () => {
	const isInterco = useIsInterco()
	const points = Object.values(usePoints())
	const jsonSchemas = useJsonSchemas()
	const dataJsonSchemas = Object.values(jsonSchemas)
	const [seeAllJsonSchemas, toogleSeeAllJsonSchema] = useBoolean(false)

	//* SET THE OLDEST STATUS OF THE JSONSCHEMA DATA TO HIM
	const getStatusFromArrayOfData = (data) => {
		let tempDataState = GREEN_STATUS

		data.forEach((children: any) => {
			const now = Moment()
			const dataDate = Moment(children.updated_at)
			now.subtract(12, "months")
			if (dataDate.isAfter(now)) {
				tempDataState = GREEN_STATUS
				return
			}
			now.subtract(24, "months")
			if (dataDate.isAfter(now) && tempDataState !== RED_STATUS) {
				tempDataState = ORANGE_STATUS
				return
			}
			tempDataState = RED_STATUS
		})
		return tempDataState
	}

	//* GET JSONSCHEMA DATAS WITH HIS ID TO DEFINED HIS STATUS
	const getJSchemaDatas = (JsonSchemaId) => {
		const datas = Object.values(points).filter(
			(point) => point.jsonschema_id === JsonSchemaId,
		)
		return datas
	}

	//* GET ALL JSONSCHEMAS WITH A STATUS AND AS JSX ELEMENT
	const jsonSchemasWithStatus = []

	dataJsonSchemas.forEach((jsonSchema: any) => {
		jsonSchemasWithStatus.push({
			status: getStatusFromArrayOfData(getJSchemaDatas(jsonSchema.id)),
			jsxElement: (
				<CommuneDataStatus
					status={getStatusFromArrayOfData(getJSchemaDatas(jsonSchema.id))}
					jsonSchemaId={jsonSchema.id}
					dataPath={"/customdata/my_forms/" + jsonSchema.id}
					dataTitle={jsonSchema.title}
					isStocks={false}
				/>
			),
		})
	})

	//* FILTER NOT GREEN JSONSCHEMAS BEFORE ORDERING TO DISPLAY 3 FIRST
	const filtredJsx = []

	jsonSchemasWithStatus.forEach((element) => {
		if (element.status !== GREEN_STATUS) {
			filtredJsx.push(element)
		}
	})

	const { tempNumber: nbDatasToUpdate } = useMemo(() => {
		const jsonSchemaIds = Object.keys(jsonSchemas)
		const result = {}
		let tempNumber = 0

		jsonSchemaIds.forEach((jsonSchemaId) => {
			const filteredPoints = points.filter(
				(point) => jsonSchemaId === point.jsonschema_id,
			)
			result[jsonSchemaId] = getStatusFromArrayOfData(filteredPoints)
			if (result[jsonSchemaId] === (ORANGE_STATUS || RED_STATUS)) {
				tempNumber = tempNumber + 1
			}
		})

		return { tempNumber }
	}, [points, jsonSchemas])

	const displayOverflowingDatas = () => {
		if (nbDatasToUpdate > 3) {
			const nbDatasOverflowable = nbDatasToUpdate - 3
			return (
				<Flex justifyCenter>
					{!seeAllJsonSchemas && nbDatasOverflowable > 1 && (
						<PrimaryTextButton onClick={() => toogleSeeAllJsonSchema()}>
							Voir les {nbDatasOverflowable} données restantes
						</PrimaryTextButton>
					)}
					{!seeAllJsonSchemas && nbDatasOverflowable === 1 && (
						<PrimaryTextButton onClick={() => toogleSeeAllJsonSchema()}>
							Voir la donnée restante
						</PrimaryTextButton>
					)}
					{seeAllJsonSchemas && nbDatasOverflowable > 1 && (
						<PrimaryTextButton onClick={() => toogleSeeAllJsonSchema()}>
							Cacher les {nbDatasOverflowable} données
						</PrimaryTextButton>
					)}
					{seeAllJsonSchemas && nbDatasOverflowable === 1 && (
						<PrimaryTextButton onClick={() => toogleSeeAllJsonSchema()}>
							Cacher la donnée
						</PrimaryTextButton>
					)}
				</Flex>
			)
		}
	}

	if (_.isEmpty(filtredJsx)) {
		return <></>
	}

	return (
		<BoardWidget
			title="Indicateurs Numérisk par données"
			colorLight="var(--orange400)"
			colorDark="var(--orange700)"
			icon={<SAssessmentIcon fontSize="large" />}
		>
			<>
				{_.isEmpty(filtredJsx) && (
					<SFlex directionColumn gap="1rem" spaceBetween>
						<Flex gap="1rem">
							<StyledDoneIcon />
							{intercoNameAdapter(
								"Toutes les données communales sont à jour.",
								isInterco,
							)}
						</Flex>
					</SFlex>
				)}
				{!seeAllJsonSchemas &&
					_.take(filtredJsx, 3).map((obj) => obj.jsxElement)}
				{seeAllJsonSchemas && filtredJsx.map((obj) => obj.jsxElement)}

				{displayOverflowingDatas()}
			</>
		</BoardWidget>
	)
}

export default NumeriskIndicatorsPcsData
