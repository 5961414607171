import React from "react"
import PImage from "utils/pdf/image/PImage"
import _ from "lodash"
import BoldText from "utils/pdf/BoldText"
import { View } from "@react-pdf/renderer"

const imageMaxWidth = 200
const imageMaxHeight = 300

const PointLinkedFileImage = ({ pointImageArray, fileBlobs }) => {
	return (
		<>
			{fileBlobs.map((element, index) => {
				let arrayOfBlobObject = element
				if (!_.isArray(element)) {
					arrayOfBlobObject = [element]
				}

				return arrayOfBlobObject.map((object) => {
					const linkedFileTitle =
						pointImageArray?.[index]?.title?.length > 0
							? `Titre : ${pointImageArray?.[index]?.title}`
							: "Pièce jointe sans titre."
					let objectWidth = object.width
					let objectHeight = object.height
					if (objectWidth > imageMaxWidth) {
						objectWidth = imageMaxWidth
						objectHeight = (object.height * imageMaxWidth) / object.width
					}
					if (objectHeight > imageMaxHeight) {
						objectHeight = imageMaxHeight
						objectWidth = (object.width * 600) / object.height
					}
					return (
						<View key={object.blob} wrap={false}>
							<BoldText style={{ margin: "4px 0" }}>{linkedFileTitle}</BoldText>
							<PImage
								src={{
									uri: object.blob,
									cache: false,
									method: "GET",
									headers: {
										"Cache-Control": "no-cache",
										"Allow-Control-Allow-Origin": "*",
									},
								}}
								alt="[Échec du chargement du fichier téléversé]"
								width={objectWidth ?? "auto"}
								height={objectHeight ?? "auto"}
								style={{
									maxWidth: imageMaxWidth + "px",
									maxHeight: imageMaxHeight + "px",
									marginBottom: "10px",
								}}
							/>
						</View>
					)
				})
			})}
		</>
	)
}

export default PointLinkedFileImage
