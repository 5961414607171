import { Alert, Button, Chip } from "@mui/material"
import { IRow } from "@/pages/admin/mapping/core/MappingSchemaRunner"
import FileDropzone from "@/pages/telechargement/Upload/component/FileDropzone"
import _ from "lodash"
import Papa from "papaparse"
import React, { useState } from "react"
import { PrimaryTextButton } from "@/utils/components/style/button"
import { Flex } from "@/utils/components/style/flex"

const CsvLoader = ({ setCsvData, setCurrentStep, currentStep, mapping }) => {
	const [fieldError, setFieldError] = useState<string[]>(undefined)
	const [emptyError, setEmptyError] = useState(false)
	const [showDetail, setShowDetail] = useState(false)

	const processImport = (file) => {
		Papa.parse(file, {
			encoding: 'ISO-8859-1',
			header: true,
			transform: (value) =>
				value.replace(/^="((.|\n)*)"$/gm, "$1").replace(/\n/g, ""),
			complete: async (results) => {
				const diff = _.difference(mapping.fields, results.meta.fields)
				if (diff.length === 0) {
					const erroredRows = results.errors.map((err) => err.row)

					const validData = results.data.filter(
						(val, index) => !erroredRows.includes(index),
					)

					if (!validData.length) {
						setEmptyError(true)
					} else {
						setEmptyError(false)
						// Complete
						setCsvData(validData as IRow[])
						setCurrentStep(currentStep + 1)
					}
					setFieldError(undefined)
				} else {
					setEmptyError(false)
					setFieldError(diff)
				}
			},
		})
	}

	return (
		<Flex directionColumn alignItemsStretch gap zeroMinHeight>
			{fieldError && (
				<Alert
					severity="error"
					action={
						<PrimaryTextButton
							color="inherit"
							onClick={() => setShowDetail(!showDetail)}
							size="small"
						>
							{showDetail ? "Cacher le détail" : "Voir le détail"}
						</PrimaryTextButton>
					}
				>
					<Flex directionColumn alignItemsStretch gap={8}>
						<div>
							Des en-têtes manquent dans le fichier CSV importé. Veuillez
							contacter les administrateurs.{" "}
							{showDetail && "Les en-têtes manquantes sont :"}
						</div>
						{showDetail && (
							<Flex $wrap gap={5}>
								{fieldError.map((field) => (
									<Chip
										variant="outlined"
										color="error"
										size="small"
										key={field}
										label={field}
									/>
								))}
							</Flex>
						)}
					</Flex>
				</Alert>
			)}
			{emptyError && (
				<Alert severity="error">Le fichier ne contient aucune données</Alert>
			)}
			{!emptyError && !fieldError && (
				<Alert
					severity="info"
					action={
						<PrimaryTextButton
							color="inherit"
							onClick={() => setShowDetail(!showDetail)}
							size="small"
						>
							{showDetail ? "Cacher le détail" : "Voir le détail"}
						</PrimaryTextButton>
					}
				>
					<Flex directionColumn alignItemsStretch gap={8}>
						<div>
							Importez un fichier CSV.
							{showDetail && " Les en-têtes suivantes sont requises :"}
						</div>
						{showDetail && (
							<Flex $wrap gap={5}>
								{mapping.fields.map((field) => (
									<Chip
										variant="outlined"
										size="small"
										key={field}
										label={field}
									/>
								))}
							</Flex>
						)}
					</Flex>
				</Alert>
			)}
			<FileDropzone allowedExtension={[".csv"]} onFileUpload={processImport} />
		</Flex>
	)
}

export default CsvLoader
