import React from "react"
import { CommonLoading } from "react-loadingg"
import styled from "styled-components"
import { Alert } from "@mui/material"
import { ModalContent } from "@nextui-org/react"
import ModalHeader from "@/styles/organisms/Modal/ModalHeader"
import ModalBody from "@/styles/organisms/Modal/ModalBody"
import Modal from "@/styles/organisms/Modal"

const StyledLoadingContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 8em;
    padding-bottom: 4em;
`

const ModalWaiting = ({
	isOpen,
	titleText,
	infoText,
	bodyText,
}: {
	isOpen: boolean
	titleText: string
	infoText: string
	bodyText: string
}) => {
	return (
		<Modal isOpen={isOpen} small>
			<ModalContent>
				<ModalHeader>{titleText}</ModalHeader>
				<ModalBody>
					<Alert severity="info">{infoText}</Alert>
					<StyledLoadingContainer>
						<CommonLoading />
						<div>{bodyText}</div>
					</StyledLoadingContainer>
				</ModalBody>
			</ModalContent>
		</Modal>
	)
}

export default ModalWaiting
