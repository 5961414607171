import { Autocomplete } from "@mui/material"
import React, { useEffect, useState } from "react"
import getImage from "@/utils/getImage"
import SearchBarTextField from "../../../../utils/components/SearchBarTextField"
import { v4 as uuidv4 } from "uuid"
import useAllPoints from "@/hooks/useAllPoints"
import { StaticFormType } from "utils/services/StaticFormsService"
import _ from "lodash"
import IJsonSchema from "@/utils/types/IJsonSchema"
import { sortNfdLowerCase } from "utils/formatNfdLowerCase"
import useCdcSharedJsonSchemas from "@/hooks/useCdcSharedJsonSchemas"
import { SearchMode } from "@/redux-toolkit/edit/cooperationFiltersRessources"
import useCooperationFilters from "@/hooks/useCooperationFilters"
import { layersRessources } from "@/pages/cooperation/customdata/utils/layers"
import useJsonSchemasWithStatics from "@/hooks/useJsonSchemasWithStatics"
import useCdc from "@/hooks/useCdc"
import { isMobile } from "react-device-detect"
import { setCooperationFilters } from "@/redux-toolkit/edit/reducer"
import { useDispatch } from "react-redux"
import { Spacer } from "utils/components/style/NSpacer/NSpacer.styled"
import useAccessRights from "@/hooks/useAccessRights"
import { EnumToolPath } from "@/hooks/services/useAccessRightsServices"

export const filterUsingPermissions = (
	listType: "WHITELIST" | "BLACKLIST",
	jsonSchemasIds: string[],
	idsToFilter: string[],
	resultArray: string[],
) =>
	jsonSchemasIds.forEach((jsonschema_id) => {
		if (listType === "WHITELIST") {
			if (!idsToFilter.includes(jsonschema_id)) return
		} else {
			if (idsToFilter.includes(jsonschema_id)) return
		}
		resultArray.push(jsonschema_id)
	})

const CoopLayersSelection = ({ layers, label }) => {
	const coopFilters = useCooperationFilters()
	const allPoints = useAllPoints()
	const jsonSchemas = useJsonSchemasWithStatics()
	const cdcSharedJsonSchemas = useCdcSharedJsonSchemas()
	const cdc = useCdc()
	const [mixedOptions, setMixedOptions] = useState([])
	const dispatch = useDispatch()
	const { readAll, WHITELIST, BLACKLIST } = useAccessRights(
		EnumToolPath.JSONSCHEMAS,
	)

	const jsonSchemasWithShared = { ...jsonSchemas, ...cdcSharedJsonSchemas }
	const shareableJsonSchemas = []

	if (readAll) {
		cdc.infos.shared_between_communes.forEach((jsonschema_id) => {
			// filter blacklisted forms if they are
			if (BLACKLIST.includes(jsonschema_id)) return
			shareableJsonSchemas.push(jsonSchemasWithShared[jsonschema_id])
		})
	} else {
		const whitelistedJsonSchemaIds = Object.keys(WHITELIST)
		if (whitelistedJsonSchemaIds.length) {
			whitelistedJsonSchemaIds.forEach((key) => {
				// filter if doesn't have read permission even if whitelisted
				if (
					!WHITELIST[key]?.read ||
					!cdc.infos.shared_between_communes.includes(key)
				)
					return
				shareableJsonSchemas.push(jsonSchemasWithShared[key])
			})
		}
	}

	const tempFinalTags = {}
	useEffect(() => {
		// Formating shareableJsonSchemas
		const sharedForms = Object.values(shareableJsonSchemas)
			.map((form: IJsonSchema) => {
				if (_.isEmpty(form)) return
				if (!["Stock", "Véhicules"].includes(form.id))
					return {
						key: form.id,
						label: form.title,
						jsonschema_id: form.id,
					}
			})
			.filter((el) => el)

		// Start to formating jsonSchemas using tags
		const displayableJsonSchemas = [] // stocks or vehicules
		const displayableStoragesJsonSchemas = [] // stockplaces or cares
		let optionsTags = []

		if (readAll) {
			filterUsingPermissions(
				"BLACKLIST",
				[StaticFormType.STOCK, StaticFormType.VEHICULES],
				BLACKLIST,
				displayableJsonSchemas,
			)
			filterUsingPermissions(
				"BLACKLIST",
				[StaticFormType.STOCK_PLACE, StaticFormType.CARE],
				BLACKLIST,
				displayableStoragesJsonSchemas,
			)
		} else {
			filterUsingPermissions(
				"WHITELIST",
				[StaticFormType.STOCK, StaticFormType.VEHICULES],
				Object.keys(WHITELIST),
				displayableJsonSchemas,
			)
			filterUsingPermissions(
				"WHITELIST",
				[StaticFormType.STOCK_PLACE, StaticFormType.CARE],
				Object.keys(WHITELIST),
				displayableStoragesJsonSchemas,
			)
		}

		if (displayableStoragesJsonSchemas.length) {
			const allowedStoragesIds = Object.values(allPoints)
				.filter((point) =>
					displayableStoragesJsonSchemas.includes(point.jsonschema_id),
				)
				.map((point) => point.id)

			const allFilteredPoints = Object.values(allPoints).filter((point) => {
				const storageId =
					point.geojson?.properties?.careId ??
					point.geojson?.properties?.["Lieu de stockage"]
				return (
					displayableJsonSchemas.includes(point.jsonschema_id) &&
					allowedStoragesIds.includes(storageId)
				)
			})

			allFilteredPoints.forEach((point) => {
				point.geojson.properties.Tags.forEach((tag, index) => {
					if (!tempFinalTags[tag]) {
						tempFinalTags[tag] = {
							key: tag,
							quantity: 0,
							jsonschema_id: point.jsonschema_id,
						}
					}
					let quantityToAdd = parseInt(point.geojson.properties?.Quantité ?? 0)
					if (point.jsonschema_id === StaticFormType.VEHICULES) {
						quantityToAdd = 1
					}
					if (quantityToAdd > 0) {
						tempFinalTags[tag]["quantity"] += quantityToAdd
					}
				})
			})

			optionsTags = Object.values(tempFinalTags)
				.map((option: any) => {
					if (
						cdc.infos.shared_between_communes.includes(option.jsonschema_id)
					) {
						const { quantity, ...rest } = option
						rest["label"] = `${option.key} (${quantity})`
						return rest
					}
				})
				.filter((el) => el)
		}

		const finalOptions = [...sharedForms, ...optionsTags.sort(sortNfdLowerCase)]

		setMixedOptions(finalOptions)
	}, [cdcSharedJsonSchemas, allPoints, layersRessources])

	return (
		<Autocomplete
			size={isMobile ? "small" : undefined}
			fullWidth
			limitTags={isMobile ? 1 : 2}
			options={
				coopFilters.search === SearchMode.DONNEES_PARTAGEES
					? mixedOptions
					: layers
			}
			value={coopFilters.layer || coopFilters.tag}
			onChange={(event, newValue) => {
				//* Cases : 1. adding new selection, 2. removing selection
				//* force clearing tags when autocomplete clear is pressed
				if (!newValue) {
					dispatch(
						setCooperationFilters({
							tag: undefined,
							layer: undefined,
						}),
					)
				}
				if (
					[StaticFormType.STOCK, StaticFormType.VEHICULES].includes(
						newValue?.jsonschema_id as StaticFormType,
					)
				) {
					dispatch(
						setCooperationFilters({
							tag: newValue,
							layer: newValue,
						}),
					)
					return
				}
				dispatch(
					setCooperationFilters({
						layer: newValue,
						tag: undefined,
					}),
				)
			}}
			renderOption={(props, option, { selected }) => {
				const imgId =
					cdcSharedJsonSchemas[option.key]?.imgId ?? option.jsonschema_id
				return (
					<li {...props} key={uuidv4()}>
						<img src={getImage(imgId)} width={25} />
						<Spacer size={0.5} direction="horizontal" />
						{option.label ?? option}
					</li>
				)
			}}
			renderInput={(params) => (
				<SearchBarTextField
					{...params}
					placeholder={label}
					InputProps={{
						...params.InputProps,
						startAdornment: coopFilters.layer?.key && (
							<img
								src={
									Object.keys(cdcSharedJsonSchemas).includes(
										coopFilters.layer?.key,
									)
										? getImage(
												jsonSchemasWithShared[coopFilters.layer?.key]?.imgId,
											)
										: getImage(coopFilters.layer?.jsonschema_id)
								}
								width={25}
							/>
						),
					}}
				/>
			)}
		/>
	)
}

export default CoopLayersSelection
