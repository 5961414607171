import React from "react"
import Debug from "debug"
import _ from "lodash"
import FormTmpCare from "../form/FormTmpCare"
import { H4 } from "@/utils/components/style/header"
import Modal from "@/styles/organisms/Modal"
import { ModalContent } from "@nextui-org/react"
import ModalHeader from "@/styles/organisms/Modal/ModalHeader"

// app:javascript:components:maincourante:care:modals:TmpModalCare.tsx
const debug = Debug(
	"app:javascript:components:maincourante:care:modals:TmpModalCare",
)
debug.log = console.log.bind(console)

const TmpModalCare = ({
	isOpen,
	onClose,
	currentTmpCare,
	setCurrentTmpCare,
}) => {
	return (
		<Modal isOpen={isOpen} onClose={onClose} size="3xl">
			<ModalContent>
				<ModalHeader>
					<H4>
						{!!currentTmpCare
							? "Modifier le centre d'accueil temporaire"
							: "Ouvrir un centre d'accueil temporaire"}
					</H4>
				</ModalHeader>
				<FormTmpCare
					onClose={onClose}
					currentTmpCare={currentTmpCare}
					setCurrentTmpCare={setCurrentTmpCare}
				/>
			</ModalContent>
		</Modal>
	)
}

export default TmpModalCare
