import { Autocomplete, MenuItem, TextField } from "@mui/material"
import Debug from "debug"
import React from "react"
import { useController, useFormContext, useWatch } from "react-hook-form"
import FSelectWithImg from "utils/form/FSelectWithImg"

// app:javascript:utils:modals:ModalIncident:FModalPriority.tsx
const debug = Debug("app:javascript:utils:modals:ModalIncident:FModalPriority")
debug.log = console.log.bind(console)

const FModalIncidentStatut = ({ disabled }) => {
	const { control } = useFormContext()

	const priority = useWatch({ control, name: "Priorité" })

	const options = [
		{
			label: "En cours",
			value: "En cours",
			img:
				priority === "Normale"
					? "/img/maincourante/evenement_encours_normale.png"
					: "/img/maincourante/evenement_encours_urgente.png",
		},
		{
			label: "Terminé",
			value: "Terminé",
			img:
				priority === "Urgente"
					? "/img/maincourante/evenement_termine_urgente.png"
					: "/img/maincourante/evenement_termine_normale.png",
		},
	]
	return (
		<FSelectWithImg
			options={options}
			disabled={disabled}
			helperText={
				disabled &&
				"Vous ne pouvez pas modifier le statut car une intervention liée est en cours"
			}
			name="Statut"
		/>
	)
}

export default FModalIncidentStatut
