import { Chip } from "@mui/material"
import { ColumnDef } from "@tanstack/react-table"
import useAllHumans from "@/hooks/useAllHumans"
import React from "react"
import { isMobile } from "react-device-detect"
import SuperReactTable from "utils/components/tables/SuperReactTable/SuperReactTable"
import { JsonSchemaPropertiesType } from "@/utils/types/IJsonSchema"
import PointPropertyValue from "../jsonSchema/properties/PointPropertyValue"
import { widthCustom } from "../tables/widthProps"

const OrgaSubRow = ({ row }) => {
	const humans = useAllHumans()

	const { responsableIds, suppleantIds, membresIds } = row.original

	const suppleants = (suppleantIds ?? [])
		.map((suppleantId) => humans[suppleantId])
		.filter(Boolean)
	const responsables = (responsableIds ?? [])
		.map((responsableId) => humans[responsableId])
		.filter(Boolean)
	const membres = (membresIds ?? [])
		.map((membreId) => humans[membreId])
		.filter(Boolean)
	const columns = [
		{
			header: "",
			accessorKey: "role",
			cell: ({ row: { original } }) => {
				if ((responsableIds ?? []).includes(original.id)) {
					return <Chip label="Responsable" />
				}
				if ((suppleantIds ?? []).includes(original.id)) {
					return (
						<Chip
							variant="outlined"
							label={
								"Suppléant " +
								(suppleantIds.indexOf(original.id) + 1).toString()
							}
						/>
					)
				}
				return (
					<Chip
						variant="outlined"
						label={"Membre " + (membresIds.indexOf(original.id) + 1).toString()}
					/>
				)
			},
			enableSorting: false,
			...widthCustom(130),
		},
		{
			header: "Type",
			accessorKey: "jsonschema_id",
			enableSorting: false,
		},
		{
			header: "Poste",
			accessorKey: "geojson.properties.Poste",
			enableSorting: false,
		},
		{
			header: "Nom",
			accessorKey: "geojson.properties.Nom",
			enableSorting: false,
		},
		{
			header: "Prénom",
			accessorKey: "geojson.properties.Prénom",
			enableSorting: false,
		},
		{
			header: "Contacts",
			accessorKey: "geojson.properties.Téléphones",
			enableSorting: false,
			cell: ({ getValue }) => (
				<PointPropertyValue
					value={getValue()}
					// @ts-ignore
					property={{ type: JsonSchemaPropertiesType.PHONE }}
					editable={false}
					align={isMobile ? "right" : "left"}
				/>
			),
			...widthCustom(300),
		},
	] as ColumnDef<any>[]

	return (
		<SuperReactTable
			$secondaryTableStyle
			columns={columns}
			data={[...responsables, ...suppleants, ...membres]}
			simpleTable={true}
			showBottom={false}
			infiniteSize={true}
			selectable={false}
			isDeletable={false}
			isEditable={false}
			isSubrows
			noDefer
		/>
	)
}

export default OrgaSubRow
