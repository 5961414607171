import {
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
} from "@mui/material"
import JsonSchemaService from "@/pages/carto2/cartographie/service/JsonSchemaService"
import useMultiPolygons from "@/hooks/useMultiPolygons"
import Debug from "debug"
import _ from "lodash"
import React, { useMemo } from "react"
import { Pie, PieChart, Tooltip } from "recharts"
import styled from "styled-components"
import MultiPolygonColorSquare from "@/utils/components/map/polygon/MultiPolygonColorSquare"
import { Flex } from "@/utils/components/style/flex"
import { H6 } from "@/utils/components/style/header"
import IGeoloc from "@/utils/types/IGeoloc"
import { isMobile } from "react-device-detect"

// app:javascript:components:admin:formulaire_dynamique:MyForms:sectorPanel:MyFormsSector.tsx
const debug = Debug(
	"app:javascript:components:admin:formulaire_dynamique:MyForms:sectorPanel:MyFormsSector",
)
debug.log = console.log.bind(console)

const SPaper = styled(Paper)`
    padding: 1rem;
`
const SPaper2 = styled(Paper)`
    width: fit-content !important;
`

const MyFormsSector = ({ customDataItems, jsonSchema }) => {
	const multiPolygons = useMultiPolygons()
	const numberOfDataBySector = useMemo(() => {
		const geolocProperty = JsonSchemaService.getGeolocProperty(jsonSchema)
		if (!geolocProperty) {
			return undefined
		}
		const results = {}
		customDataItems.forEach((customDataItem) => {
			const geoloc: IGeoloc =
				customDataItem.geojson.properties?.[geolocProperty?.name] ?? {}
			const secteurName =
				multiPolygons[geoloc?.secteur]?.name ??
				geoloc?.secteur ??
				"Sans " + geolocProperty.name
			if (!results[secteurName]) {
				results[secteurName] = 0
			}
			results[secteurName]++
		})
		const finalResults = {}
		if (results["Sans " + geolocProperty.name]) {
			finalResults["Sans " + geolocProperty.name] =
				results["Sans " + geolocProperty.name]
		}
		if (results["Hors secteur"]) {
			finalResults["Hors secteur"] = results["Hors secteur"]
		}
		Object.keys(results)
			.sort((a, b) => {
				if (a > b) {
					return 1
				}
				if (a < b) {
					return -1
				}
			})
			.forEach((secteurName) => {
				if (
					secteurName !== "Sans " + geolocProperty.name &&
					secteurName !== "Hors secteur"
				) {
					const multipolygon = Object.values(multiPolygons).find(
						(multiPolygon) => {
							if (multiPolygon.name === secteurName) {
								return true
							}
						},
					)
					finalResults[secteurName] = {
						results: results[secteurName],
						// @ts-ignore
						color: multipolygon.custom_props.style.fillColor,
					}
				}
			})

		return finalResults
	}, [customDataItems, jsonSchema, multiPolygons])

	if (_.isEmpty(numberOfDataBySector)) {
		return null
	}
	const pieData = Object.entries(numberOfDataBySector).map(
		([name, value]: any) => ({
			name,
			value: value.results,
			fill: value.color,
		}),
	)
	return (
		<Flex gap="2rem" directionColumn={isMobile}>
			<TableContainer component={SPaper2}>
				<Table>
					<TableHead>
						<TableRow>
							<TableCell></TableCell>
							<TableCell>Secteur</TableCell>
							<TableCell align="right">Nombre de données</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{Object.keys(numberOfDataBySector).map((secteurName) => (
							<TableRow key={secteurName}>
								<TableCell>
									<MultiPolygonColorSquare
										multiPolygon={Object.values(multiPolygons).find(
											(multi) => multi.name === secteurName,
										)}
									/>
								</TableCell>
								<TableCell component="th" scope="row">
									{secteurName}
								</TableCell>
								<TableCell align="right">
									{numberOfDataBySector[secteurName].results}
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
			<SPaper>
				<H6>Données par secteur</H6>
				<PieChart width={300} height={300}>
					<Pie
						data={pieData}
						dataKey="value"
						innerRadius={80}
						outerRadius={100}
						label
					/>
				</PieChart>
			</SPaper>
		</Flex>
	)
}

export default MyFormsSector
