import { IconButton } from "@mui/material"
import FormatAlignCenterIcon from "@mui/icons-material/FormatAlignCenter"
import FormatAlignJustifyIcon from "@mui/icons-material/FormatAlignJustify"
import FormatAlignLeftIcon from "@mui/icons-material/FormatAlignLeft"
import FormatAlignRightIcon from "@mui/icons-material/FormatAlignRight"
import React from "react"
import ToolbarPart from "./ToolbarPart"

function TextAlignToolbar({ currentState, onChange }) {
	const { textAlignment } = currentState

	return (
		<ToolbarPart>
			<IconButton onClick={() => onChange("left")}>
				<FormatAlignLeftIcon
					color={
						!textAlignment || textAlignment === "left" ? "primary" : "action"
					}
				/>
			</IconButton>
			<IconButton onClick={() => onChange("center")}>
				<FormatAlignCenterIcon
					color={textAlignment === "center" ? "primary" : "action"}
				/>
			</IconButton>
			<IconButton onClick={() => onChange("right")}>
				<FormatAlignRightIcon
					color={textAlignment === "right" ? "primary" : "action"}
				/>
			</IconButton>
			<IconButton onClick={() => onChange("justify")}>
				<FormatAlignJustifyIcon
					color={textAlignment === "justify" ? "primary" : "action"}
				/>
			</IconButton>
		</ToolbarPart>
	)
}

export default TextAlignToolbar
