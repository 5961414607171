// thanks to https://gist.github.com/tomhicks/95d455186fadda2f7d99e6163aa9d360
import React, { useState, useEffect, useCallback } from "react"
import ErrorService from "@/services/ErrorService"
import { v4 as uuidv4 } from "uuid"
const useTaskQueue = (): {
	tasks: ReadonlyArray<Task>
	isProcessing: boolean
	addTask: (task: () => Promise<void> | void) => void
	clearTasks: () => void
	setShouldProcess: (shouldProcess: boolean) => void
	shouldProcess: boolean
	processTaskUUID: (uuid: string) => void
} => {
	const [queue, setQueue] = useState<{
		isProcessing: boolean
		tasks: Array<Task>
	}>({ isProcessing: false, tasks: [] })
	const [shouldProcess, setShouldProcess] = useState(false)

	useEffect(() => {
		if (!shouldProcess) return
		if (queue.tasks.length === 0) {
			setShouldProcess(false)
			return
		}
		if (queue.isProcessing) return

		const task = queue.tasks[0]
		setQueue((prev) => ({
			isProcessing: true,
			tasks: prev.tasks.slice(1),
		}))

		Promise.resolve(task.job()).finally(() => {
			setQueue((prev) => ({
				isProcessing: false,
				tasks: prev.tasks,
			}))
		})
	}, [queue, shouldProcess])

	const processTaskUUID = async (uuid: string) => {
		const task = queue.tasks.find((t) => t.uuid === uuid)
		if (!task) return
		setQueue((prev) => ({
			isProcessing: true,
			tasks: prev.tasks.filter((t) => t.uuid !== uuid),
		}))
		try {
			await task.job()
		} catch (err) {
			console.error(err.message)
			// ErrorService.error({
			//     component: 'useTaskQueue',
			//     message: err.message,
			// })
		} finally {
			setQueue((prev) => ({
				isProcessing: false,
				tasks: prev.tasks,
			}))
		}
	}

	return {
		tasks: queue.tasks,
		isProcessing: queue.isProcessing,
		addTask: useCallback((task) => {
			setQueue((prev) => ({
				isProcessing: prev.isProcessing,
				tasks: [...prev.tasks, { job: task, uuid: uuidv4() } as Task],
			}))
		}, []),
		clearTasks: useCallback(() => {
			setQueue((prev) => ({
				isProcessing: prev.isProcessing,
				tasks: [],
			}))
		}, []),
		setShouldProcess,
		shouldProcess,
		processTaskUUID,
	}
}

type Task = {
	job: () => Promise<void> | void
	uuid: string
}
export default useTaskQueue
