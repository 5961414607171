import React, { useState, useEffect, useMemo } from "react"
import Debug from "debug"
import styled from "styled-components"

// app:javascript:components:maincourante:ficheAction:DaybookFicheAction.tsx
const debug = Debug(
	"app:javascript:components:maincourante:ficheAction:DaybookFicheAction",
)
debug.log = console.log.bind(console)

const DaybookFicheAction = ({}) => {
	return <>coucou</>
}

export default DaybookFicheAction
