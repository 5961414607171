import React, { useState, useEffect, useMemo, useRef } from "react"
import { useDispatch, useSelector } from "react-redux"
import Debug from "debug"
import styled, { css } from "styled-components"
import Moment from "moment"
import { useHistory } from "react-router-dom"
import _ from "lodash"
import IPoint from "utils/types/IPoint"
import { ListItemButton } from "@mui/material"
import formatNfdLowerCase from "utils/formatNfdLowerCase"
import { Flex } from "utils/components/style/flex"
import { PrimaryChip, StyledChip } from "utils/components/style/chip"
import usePoints from "@/hooks/usePoints"

// app:javascript:components:maincourante:care:search:CareSearchPersonResult.tsx
const debug = Debug(
	"app:javascript:components:maincourante:care:search:CareSearchPersonResult",
)
debug.log = console.log.bind(console)

const SListItemButton = styled(ListItemButton)`
    width: 100%;
`
const translate = (x: string) => {
	const translation = {
		family: "Membres",
	}
	if (translation[x]) {
		return translation[x]
	}
	return x
}
const CareSearchPersonResult = ({
	matchingSearchPerson,
	searchPersonValue,
	setCurrentFamily,
}: {
	matchingSearchPerson: { careFamilyPoint: IPoint; matchingKeys: string[] }
	searchPersonValue: string
	setCurrentFamily: (careFamilyPoint: IPoint) => void
}) => {
	const points = usePoints()
	const { careFamilyPoint, matchingKeys } = matchingSearchPerson

	const careName =
		points[careFamilyPoint.geojson.properties?.careId]?.geojson?.properties
			?.Lieu ?? ""

	return (
		<>
			<SListItemButton onClick={() => setCurrentFamily(careFamilyPoint)}>
				<Flex fullWidth spaceBetween>
					<Flex directionColumn alignItemsStart>
						<div>Référent : {careFamilyPoint.geojson.properties.Identité}</div>
						<StyledChip
							$colorProperty="var(--primary500)"
							label={careName}
							variant="outlined"
							size="small"
						/>
					</Flex>
					<ul>
						{matchingKeys.map((key) => (
							<li key={key}>
								{translate(key)} : contient {searchPersonValue}
							</li>
						))}
					</ul>
				</Flex>
			</SListItemButton>
		</>
	)
}

export default CareSearchPersonResult
