import { SET_SELECTION, RESET_SELECTION } from "./action"

export const initialSelectionState = []
export const selectionReducer = (state = initialSelectionState, action) => {
	switch (action.type) {
		case SET_SELECTION:
			return action.selection
		case RESET_SELECTION:
			return initialSelectionState
	}
}
