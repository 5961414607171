import SearchIcon from "@mui/icons-material/Search"
import { FormHelperText, InputAdornment, TextField } from "@mui/material"
import { TreeContext } from "@/pages/telechargement/redux/page/context"
import _ from "lodash"
import React, { useContext, useMemo, useState } from "react"
import { useController, useFormContext } from "react-hook-form"
import SuperList from "utils/components/SuperList"

const FList = <Item extends {}>({
	name,
	items,
	disabledItems,
	searchPlaceholder = "Rechercher",
	Icon = undefined,
	idAccessor,
	titleAccessor,
	subTitleAccessor,
	groupBy = undefined,
	renderGroup = undefined,
}: {
	name: string
	items: Item[]
	disabledItems: string[]
	searchPlaceholder?: string
	Icon?: ({ item }: { item: Item }) => JSX.Element
	idAccessor: (item: Item) => string
	titleAccessor: (item: Item) => string
	subTitleAccessor?: (item: Item) => string
	groupBy?: (item: Item) => string
	renderGroup?: (group: string) => {
		title: string
		subtitle?: string
	}
}) => {
	const { state: treeState, dispatch: treeDispatch } = useContext(TreeContext)
	const treeStateKeys = Object.keys(treeState)
	const { control } = useFormContext()

	const {
		field: { value, onChange },
		fieldState: { error },
	} = useController({
		name,
		control,
		rules: { required: true },
		defaultValue: "",
	})

	const [filter, setFilter] = useState("")

	const grouped = useMemo(() => {
		const res = []
		const grouped = {}
		items.forEach((item: any) => {
			const group = groupBy?.(item) ?? item.risk
			if (!grouped[group]) grouped[group] = []
			grouped[group].push(item)
		})
		Object.entries(grouped).forEach(([id, childs]) => res.push({ id, childs }))

		return res
	}, [items])

	return (
		<div>
			<FormHelperText error={Boolean(error)}>{error?.message}</FormHelperText>
			<TextField
				size="small"
				fullWidth
				variant="outlined"
				value={filter}
				placeholder={searchPlaceholder}
				onChange={(e) => setFilter(e.target.value)}
				InputProps={{
					endAdornment: (
						<InputAdornment position="end">
							<SearchIcon />
						</InputAdornment>
					),
				}}
			/>
			<SuperList
				items={grouped}
				getItemTitle={(group) => renderGroup?.(group.id)?.title ?? group.id}
				getItemSubtitle={(group) => renderGroup?.(group.id)?.subtitle}
				flat={groupBy === undefined || Boolean(filter)}
				getItemLength={(group) => group.childs.length}
				compact
			>
				{(group) => (
					<SuperList
						items={group.childs}
						getItemTitle={titleAccessor}
						getItemSubtitle={subTitleAccessor}
						getItemIcon={Icon ? (item) => <Icon item={item} /> : undefined}
						onItemClick={(item) => onChange(idAccessor(item))}
						isItemSelected={(item) => idAccessor(item) === value}
						isItemDisabled={(item) => disabledItems.includes(idAccessor(item))}
						compact
						showItem={(item) =>
							titleAccessor(item).toLowerCase().includes(filter.toLowerCase())
						}
					/>
				)}
			</SuperList>
		</div>
	)
}

export default FList
