import { Autocomplete, TextField } from "@mui/material"
import usePoints from "@/hooks/usePoints"
import useSelectedEvent from "@/hooks/useSelectedEvent"
import React, { ComponentProps, useEffect, useMemo, useState } from "react"
import { useFormContext } from "react-hook-form"
import { Flex } from "utils/components/style/flex"
import { StaticFormType } from "utils/services/StaticFormsService"
import { DaybookAlertCanal } from "@/utils/types/daybook/IDaybookAlert"

const DaybookAlertTemplateSelect = () => {
	const [acMenuOpen, setAcMenuOpen] = useState(false)
	const [selectedTemplate, setSelectedTemplate] = useState(null)
	const points = usePoints()
	const selectedEvent = useSelectedEvent()
	const { setValue, watch } = useFormContext()

	const type = watch("geojson.properties.type") as DaybookAlertCanal
	const smsText = watch("geojson.properties.smsText")
	const voiceContent = watch("geojson.properties.voiceContent")

	const buildedOptions = useMemo(() => {
		const concernedPoints = Object.values(points)
			.filter((point) => point.jsonschema_id === StaticFormType.ALERTE_MESSAGE)
			.filter((point) => point.geojson.properties.type.includes(type))
		const options = concernedPoints
			.sort((a, b) => {
				const aRisk = a.geojson.properties.riskType
				const bRisk = b.geojson.properties.riskType
				const selectedEventRisk = selectedEvent?.risk_type
				if (aRisk === selectedEventRisk) {
					return -1
				}
				if (bRisk === selectedEventRisk) {
					return 1
				}
				return 0
			})
			.map((point) => ({
				value: point.id,
				label: point.geojson.properties.title,
			}))
		return options
	}, [points, selectedEvent, type])
	useEffect(() => {
		if (selectedTemplate) {
			const template = points[selectedTemplate]
			if (template) {
				if (type === DaybookAlertCanal.SMS) {
					setValue(
						"geojson.properties.smsText",
						template.geojson.properties.message,
					)
				}
				if (type === DaybookAlertCanal.VOCALE) {
					setValue(
						"geojson.properties.voiceContent",
						template.geojson.properties.message,
					)
				}
			}
		} else {
			const templates = Object.values(points).filter(
				(point) => point.jsonschema_id === StaticFormType.ALERTE_MESSAGE,
			)
			if (
				smsText &&
				templates.some(
					(template) => template.geojson.properties.message === smsText,
				)
			) {
				const selectedTemplate = templates.find(
					(template) => template.geojson.properties.message === smsText,
				)
				setSelectedTemplate(selectedTemplate.id)
			}
			if (
				voiceContent &&
				templates.some(
					(template) => template.geojson.properties.message === voiceContent,
				)
			) {
				const selectedTemplate = templates.find(
					(template) => template.geojson.properties.message === voiceContent,
				)
				setSelectedTemplate(selectedTemplate.id)
			}
		}
	}, [selectedTemplate, type])
	return (
		<Autocomplete
			open={acMenuOpen}
			onOpen={() => setAcMenuOpen(true)}
			onClose={() => setAcMenuOpen(false)}
			noOptionsText={"Aucune option"}
			fullWidth
			renderInput={(params) => (
				<Flex fullWidth>
					<TextField
						{...params}
						label="Modèle de message à appliquer"
						inputProps={
							{
								...params.inputProps,
								value:
									points[selectedTemplate]?.geojson?.properties?.title ??
									"Aucun modèle sélectionné",
							} as ComponentProps<typeof TextField>["inputProps"]
						}
					/>
				</Flex>
			)}
			options={
				buildedOptions.length > 0
					? buildedOptions
					: [{ value: null, label: "" }]
			}
			value={selectedTemplate}
			getOptionLabel={(option) => option?.label ?? ""}
			onChange={(event, newValue) => {
				if (!newValue) {
					setValue("geojson.properties.smsText", "")
					setValue("geojson.properties.voiceContent", "")
					setSelectedTemplate(null)
					return
				}
				setSelectedTemplate(newValue.value)
			}}
			renderOption={(props, option) => (
				<li {...props} key={option.label}>
					{option?.label ?? "Aucun modèle sélectionné"}
				</li>
			)}
		/>
	)
}

export default DaybookAlertTemplateSelect
