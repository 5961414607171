import React, { useState, useEffect, useMemo, useRef } from "react"
import { useDispatch, useSelector } from "react-redux"
import Debug from "debug"
import styled, { css } from "styled-components"
import Moment from "moment"
import { useHistory } from "react-router-dom"
import _ from "lodash"
import { useFormContext } from "react-hook-form"
import { Avatar } from "@mui/material"
import Check from "@mui/icons-material/Check"
import {
	DaybookAlertType,
	rankDaybookAlertType,
} from "@/utils/types/daybook/IDaybookAlert"
enum DaybookAlertStatusStep {
	FINISHED = "FINISHED",
	IN_PROGRESS = "IN_PROGRESS",
	NOT_STARTED = "NOT_STARTED",
}
const SAvatar = styled(Avatar)<{ $status: DaybookAlertStatusStep }>`
    padding: 1rem !important;
    align-items: center;
    ${({ $status }) => {
			switch ($status) {
				case DaybookAlertStatusStep.FINISHED:
					return css`
                    background-color: var(--primary500) !important;
                    color: var(--neutral50) !important;
                `
				case DaybookAlertStatusStep.IN_PROGRESS:
					return css`
                    color: var(--primary800) !important;
                    border-color: var(--primary800) !important;
                    border: 2px solid !important;
                    background-color: #f9fafb !important;
                    /* background-color: var(--primary100) !important;
                    color: var(--primary800) !important; */
                `
				case DaybookAlertStatusStep.NOT_STARTED:
					return css`
                    background-color: #f9fafb !important;
                    color: var(--neutral800) !important;
                    border: 2px solid var(--neutral800) !important;
                    border-style: dashed !important;
                `
			}
		}}
`

const DaybookAlertAvatar = ({
	children,
	alertStatus,
	final = false,
}: {
	children: React.ReactNode
	alertStatus: DaybookAlertType | undefined
	final?: boolean
}) => {
	const { watch } = useFormContext()
	const currentStatus = watch("geojson.properties.status")

	const rankedAlertStatus = rankDaybookAlertType(alertStatus)
	const rankedCurrentStatus = rankDaybookAlertType(currentStatus)
	if (!alertStatus || rankedCurrentStatus === 6) {
		return (
			<SAvatar $status={DaybookAlertStatusStep.FINISHED}>
				<Check />
			</SAvatar>
		)
	}

	if (rankedAlertStatus < rankedCurrentStatus) {
		return (
			<SAvatar $status={DaybookAlertStatusStep.FINISHED}>
				<Check />
			</SAvatar>
		)
	}
	if (rankedAlertStatus === rankedCurrentStatus) {
		return (
			<SAvatar $status={DaybookAlertStatusStep.IN_PROGRESS}>{children}</SAvatar>
		)
	}

	return (
		<SAvatar $status={DaybookAlertStatusStep.NOT_STARTED}>{children}</SAvatar>
	)
}

export default DaybookAlertAvatar
