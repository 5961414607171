import GlobalStyle from "@/pages/app/GlobalStyle"
import React, { useState, useEffect } from "react"
import { isMobile } from "react-device-detect"
import { connect, useSelector } from "react-redux"
import { useRef } from "react"
import styled from "styled-components"
import { useLocalStorage } from "react-use"
import {
	CircularProgress,
	Dialog,
	DialogContent,
	DialogTitle,
} from "@mui/material"
import { Flex } from "./style/flex"
import useIsOpenMenu from "@/hooks/useIsMenuOpen"

export const BGCOLOR = "var(--primary50)"

const StyledContainer = styled.div`
    height: ${(props: any) => props.heightRequested}px;
    width: ${(props: any) => props.width}px;
    background-color: var(--background-app-color);
    display: flex;
` as any

const AppContainer = ({ children }) => {
	const isOpenMenu = useIsOpenMenu()
	const [height, setHeight] = useState(0)
	const [width, setWidth] = useState(0)
	const [fontSize] = useLocalStorage("accessibility:fontSize", 16)
	const processingSomething = useSelector(
		(state: any) => state.common.processingSomething,
	)
	useEffect(() => {
		document.documentElement.style.setProperty(
			"--font-size-base",
			`${fontSize}px`,
		)
	}, [])

	const [keyboardOpen, setKeyboardOpen] = useState(false)
	const keyboardHeightRef = useRef<number>(undefined)

	const changeDimension = () => {
		if (!keyboardHeightRef.current) {
			keyboardHeightRef.current = window.visualViewport.height
		} else if (isMobile) {
			if (window.visualViewport.height < keyboardHeightRef.current) {
				if (!keyboardOpen) setKeyboardOpen(true)
			} else {
				if (keyboardOpen) setKeyboardOpen(false)
			}
		}
		setHeight(window.visualViewport.height)
		setWidth(window.visualViewport.width)
	}

	useEffect(() => {
		window.addEventListener("resize", changeDimension)
		changeDimension()
		return () => {
			window.removeEventListener("resize", changeDimension)
		}
	})

	const heightRequested = height
	const isSmall = height < 481 || width < 1200
	return (
		<>
			<GlobalStyle
				openMenu={isOpenMenu}
				isSmall={isSmall}
				height={height}
				keyboard={keyboardOpen}
			/>
			<StyledContainer heightRequested={heightRequested} width={width}>
				{processingSomething && (
					<Dialog open={processingSomething}>
						<DialogTitle>{processingSomething}</DialogTitle>
						<DialogContent>
							<Flex justifyCenter fullWidth>
								<CircularProgress />
							</Flex>
						</DialogContent>
					</Dialog>
				)}
				{children}
			</StyledContainer>
		</>
	)
}

export default AppContainer
