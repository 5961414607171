import {
	IconButton,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	Popover,
} from "@mui/material"
import ImageIcon from "@mui/icons-material/Image"
import LinkIcon from "@mui/icons-material/Link"
import PublishIcon from "@mui/icons-material/Publish"
import React, { useState } from "react"
import UploadDialog from "./subcomponents/UploadDialog"
import URLDialog from "./subcomponents/URLDialog"
import ToolbarPart from "./ToolbarPart"

function ImageToolbar({ onChange, config }) {
	const [anchorEl, setAnchorEl] = useState(null)
	const [isUploadDialogOpened, setIsUploadDialogOpened] = useState(false)
	const [isUrlDialogOpened, setIsUrlDialogOpened] = useState(false)

	const handleFileUpload = async (file) => {
		const { data } = await config.uploadCallback(file)
		if (!data) return

		const src = data.link || data.url
		onChange(src, config.defaultSize.height, config.defaultSize.width, "")
		setIsUploadDialogOpened(false)
	}

	const handleURL = (url) => {
		onChange(url, config.defaultSize.height, config.defaultSize.width, "")
		setIsUploadDialogOpened(false)
	}

	return (
		<ToolbarPart>
			<IconButton onClick={(ev) => setAnchorEl(ev.currentTarget)}>
				<ImageIcon color={"action"} />
			</IconButton>
			<Popover
				anchorEl={anchorEl}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "left",
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: "left",
				}}
				keepMounted
				open={Boolean(anchorEl)}
				onClose={() => setAnchorEl(null)}
			>
				<List component="nav">
					<ListItem
						button
						onClick={() => {
							setIsUploadDialogOpened(true)
							setAnchorEl(null)
						}}
					>
						<ListItemIcon>
							<PublishIcon />
						</ListItemIcon>
						<ListItemText primary={"Téléverser une image"} />
					</ListItem>
					<ListItem
						button
						onClick={() => {
							setIsUrlDialogOpened(true)
							setAnchorEl(null)
						}}
					>
						<ListItemIcon>
							<LinkIcon />
						</ListItemIcon>
						<ListItemText primary={"Indiquer une URL"} />
					</ListItem>
				</List>
			</Popover>

			<UploadDialog
				open={isUploadDialogOpened}
				onClose={() => setIsUploadDialogOpened(false)}
				onChange={handleFileUpload}
				inputAccept={config.inputAccept}
			/>

			<URLDialog
				open={isUrlDialogOpened}
				onClose={() => setIsUrlDialogOpened(false)}
				onChange={handleURL}
			/>
		</ToolbarPart>
	)
}

export default ImageToolbar
