import React, { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import useSelectedEvent from "./useSelectedEvent"
import { createConsumer } from "@rails/actioncable"
import { addEvent } from "@/redux-toolkit/data/events/reducer"
import { addEventWebSocket } from "@/redux-toolkit/data/events/resources"
import useIsOnline from "./useIsOnline"
import { useUnmount } from "react-use"
let cable
if (window.document.location.host.includes("localhost")) {
	cable = createConsumer("ws://localhost:3000/cable")
} else {
	cable = createConsumer("wss://" + window.document.location.host + "/cable")
}

const useWebSocketEventSubscription = () => {
	const [wsEventId, setWsEventId] = useState(undefined)
	const selectedEvent = useSelectedEvent()
	const dispatch = useDispatch()
	const isOnline = useIsOnline()
	useEffect(() => {
		// if connection lost or no event selected, unsubscribe
		if (!isOnline || !selectedEvent?.id) {
			if (wsEventId?.identifier) {
				cable.subscriptions.remove(wsEventId)
				setWsEventId(undefined)
			}
			return
		}
		// if online and event selected, subscribe
		if (!wsEventId?.identifier) {
			const subscription = cable.subscriptions.create(
				{ channel: "ActionsChannel", id: selectedEvent?.id },
				{
					received: (data) => {
						dispatch(addEventWebSocket(data))
					},
				},
			)
			setWsEventId(subscription)
			return
		}

		// if already subscribed, do nothing
		if (wsEventId && wsEventId?.identifier.includes(selectedEvent.id)) {
			return
		}

		// if already subscribed to another event, unsubscribe
		if (wsEventId?.identifier) {
			cable.subscriptions.remove(wsEventId)
		}

		const subscription = cable.subscriptions.create(
			{ channel: "ActionsChannel", id: selectedEvent?.id },
			{
				received: (data) => {
					dispatch(addEventWebSocket(data))
				},
			},
		)
		setWsEventId(subscription)
	}, [selectedEvent?.id, wsEventId?.identifier, isOnline])

	useUnmount(() => {
		if (wsEventId?.identifier) {
			cable.subscriptions.remove(wsEventId)
			setWsEventId(undefined)
		}
	})
}
export default useWebSocketEventSubscription
