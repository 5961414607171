import PieChartIcon from "@mui/icons-material/PieChart"
import ErrorPermissions, {
	BACK_PATH_HOME,
	BACK_TITLE_HOME,
	FEATURE_ERROR_PERMISSION,
} from "@/hooks/ErrorPermissions"
import { EnumToolPath } from "@/hooks/services/useAccessRightsServices"
import useAccessRights from "@/hooks/useAccessRights"
import useIsInterco from "@/hooks/useIsInterco"
import usePoints from "@/hooks/usePoints"
import { setCurrentPoint } from "@/redux-toolkit/edit/reducer"
import React, { useMemo } from "react"
import { isDesktop, isMobile } from "react-device-detect"
import { connect } from "react-redux"
import styled, { css } from "styled-components"
import DataPieWidget from "@/utils/components/charts/DataPieWidget"
import { Flex } from "@/utils/components/style/flex"
import TitleHeader from "@/utils/components/TitleHeader"
import BoardWidget from "@/utils/components/widget/BoardWidget"
import intercoNameAdapter from "@/utils/intercoNameAdapter"
import NumeriskIndicatorsPcsData from "./indicators/NumeriskIndicatorsPcsData"
import WelcomeChart from "./WelcomeChart"
import ResponsableWidget from "./widgets/ResponsableWidget"
import useJsonSchemasWithStatics from "@/hooks/useJsonSchemasWithStatics"
import IPoint from "@/utils/types/IPoint"

const Container = styled.div`
    width: 100%;
`
const SPieChartIcon = styled(PieChartIcon)`
    color: white;
`

const CustomDataDashboard = ({ fiches }) => {
	const isInterco = useIsInterco()
	const points = Object.values(usePoints())
	const fichesArray = Object.values(fiches)
	const jsonSchemas = useJsonSchemasWithStatics()

	const allDatas = useMemo(() => {
		const jsonSchemaPoints = Object.values(points).filter((point: IPoint) =>
			Object.values(jsonSchemas)
				.map((schema) => schema.id)
				.includes(point.jsonschema_id),
		)
		return [...jsonSchemaPoints, ...fichesArray]
	}, [points, fichesArray])

	const permissions = useAccessRights(EnumToolPath.CD_DASHBOARD)
	if (!permissions.read) {
		return (
			<ErrorPermissions
				errorMessage={FEATURE_ERROR_PERMISSION}
				backTitle={BACK_TITLE_HOME}
				backUrl={BACK_PATH_HOME}
			/>
		)
	}

	return (
		<>
			<TitleHeader title="Tableau de bord" />
			<Container className="grid grid-cols-1 md:grid-cols-2 gap-4">
				<div className="md:col-span-1">
					<ResponsableWidget
						responsableType="pcc"
						label={intercoNameAdapter("Responsable PCS", isInterco)}
					/>
					<ResponsableWidget responsableType="rgpd" label="Responsable RGPD" />
					<ResponsableWidget
						responsableType="astreinte"
						label="Réception de l’alerte (heures ouvrées)"
					/>
					<ResponsableWidget
						responsableType="astreinteClosed"
						label="Réception de l’alerte (heures non ouvrées)"
					/>
				</div>
				<div className="md:col-span-1">
					<WelcomeChart
						allDatas={allDatas}
						communalDatas={points}
						fichesArray={fichesArray}
					/>
					<BoardWidget
						title="Mise à jour des données"
						responsiveHeight
						icon={<SPieChartIcon fontSize="large" />}
					>
						<DataPieWidget data={allDatas} isDashboard={true} />
					</BoardWidget>
					<NumeriskIndicatorsPcsData />
				</div>
			</Container>
		</>
	)
}

const mapStateToProps = (state) => ({
	fiches: state.data.fiches,
	jsonSchemas: state.data.jsonSchemas,
	userSettings: state.userSettings,
})
const mapDispatchToProps = (dispatch) => {
	return {
		setCurrentPoint: (human) => dispatch(setCurrentPoint(human)),
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomDataDashboard)
