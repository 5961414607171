import { Flex } from "@/utils/components/style/flex"
import {
	Card,
	CardBody,
	CardHeader,
	Image,
	Kbd,
	Spacer,
} from "@nextui-org/react"
import { isMobile, isSafari } from "react-device-detect"
import { FormProvider, useForm } from "react-hook-form"
import styled from "styled-components"
import React, { useEffect, useState } from "react"
import FText from "@/utils/form/FText"
import Button from "@/styles/atoms/Button"
import FCheckbox from "@/utils/form/FCheckbox"
import VisibilityOff from "@mui/icons-material/VisibilityOff"
import Visibility from "@mui/icons-material/Visibility"
import axios from "axios"
import useKeyboardJs from "@/utils/keyboard/useKeyboardJs"
import { useHasDoubleAuth } from "./LoginForm.hook"
import { yupResolver } from "@hookform/resolvers/yup"
import { loginSchema } from "./LoginForm.schema"
import LightTooltip from "@/styles/atoms/Tooltip/LightTooltip/LightTooltip"
import Tooltip from "@/styles/atoms/Tooltip"

const GlassMorphismCard = styled(Card)<{ $turned: boolean }>`
	z-index: 2;
	transition: all 0.5s;
	padding: 1rem;
	border-radius: 1rem;
	backdrop-filter: blur(10px);
	border: 1px solid rgba(255, 255, 255, 0.18);
	transition: all 0.5s ease;
	${(props) =>
		props.$turned &&
		`
		transform: rotateY(180deg);
		${CardFront} {
			transform: rotateY(180deg);
		}
		${CardBack} {
			transform: rotateY(0deg);
		}
	`}
`

const CardFront = styled.div`
	backface-visibility: hidden;
`

const CardBack = styled.div`
	transform: rotateY(180deg);
	backface-visibility: hidden;
	background-color: rgba(255, 255, 255, 0.1);
	width: 100%;
	height: 100%;
`

const CardHeaderContent = styled.div<{ $turned: boolean }>`
	${(props) =>
		props.$turned &&
		`
		transform: rotateY(180deg);
		width: 100%;
		z-index: 2;
	`}
`

const CardBackContent = styled.div`
	transform: rotateY(180deg);
`

const LoginCard = ({ setIsLoggingIn, isLoggingIn }) => {
	const [showPassword, setShowPassword] = useState(false)
	const [isPressed] = useKeyboardJs("enter")
	const [ssoLogin, setSsoLogin] = useState(false)
	const { hasDoubleAuth, setHasDoubleAuth, checkUserDoubleAuth } =
		useHasDoubleAuth()

	const methods = useForm({
		defaultValues: {
			email: "",
			password: "",
			otp_attempt: "",
			remember_me: true,
		},
		resolver: yupResolver(loginSchema),
	})

	useEffect(() => {
		if (isPressed) {
			if (hasDoubleAuth) {
				methods.handleSubmit(onSubmit2)()
			} else {
				methods.handleSubmit(onSubmit)()
			}
		}
	}, [isPressed, hasDoubleAuth])

	const onSubmit2 = async (e) => {
		const formData = methods.getValues()
		try {
			const response = await axios.post(
				"/api/v1/auth/sign_in",
				{ ...formData, turbo: false },
				{
					headers: {
						Accept: "application/json", // Specify JSON format
					},
				},
			)
			// devise token auth returns the following headers
			localStorage.setItem("access-token", response.headers["access-token"])
			localStorage.setItem("client", response.headers["client"])
			localStorage.setItem("uid", response.headers["uid"])
			axios.defaults.headers.common["access-token"] =
				response.headers["access-token"]
			axios.defaults.headers.common["client"] = response.headers["client"]
			axios.defaults.headers.common["uid"] = response.headers["uid"]
			document.cookie = `access-token=${response.headers["access-token"]}`
			document.cookie = `client=${response.headers["client"]}`
			document.cookie = `uid=${response.headers["uid"]}`
			setIsLoggingIn(true)
		} catch (error) {
			console.error("Error:", error)

			if (error.response.data?.error?.includes("Invalid OTP")) {
				setHasDoubleAuth(true)
				methods.setValue("otp_attempt", "")
				methods.setError("otp_attempt", {
					type: "manual",
					message: "Code de vérification invalide",
				})
			} else {
				setHasDoubleAuth(false)
				methods.setError("email", {
					type: "manual",
					message: "Email ou mot de passe invalide",
				})
				methods.setError("password", {
					type: "manual",
					message: "Email ou mot de passe invalide",
				})
			}
			// Handle error, display message, etc.
		}
	}

	const onSubmit = async (data) => {
		try {
			const doubleAuth = await checkUserDoubleAuth(data.email)
			if (!doubleAuth) {
				onSubmit2(data)
			}
		} catch (error) {
			alert("Error handling double authentication:" + error?.message)
		}
	}

	const onSSOClick = () => {
		setSsoLogin(true)
	}

	const handleSSORedirection = async () => {
		setSsoLogin(true)
		try {
			const response = await axios.get("/saml/sso")
			// Handle successful SSO redirection
			// For example, you can redirect the user to the SSO page
			window.location.href = response.data.redirectUrl
		} catch (error) {
			console.error("Error:", error)
			// Handle error during SSO redirection
			// For example, display an error message to the user
		}
	}

	// if (isSafari) {
	// 	return (
	// 		<div>
	// 			Vous êtes sur Safari, veuillez vous reconnectez avec un autre navigateur
	// 		</div>
	// 	)
	// }

	return (
		<FormProvider {...methods}>
			<GlassMorphismCard
				className={!isMobile && "min-w-[500px]"}
				$turned={hasDoubleAuth || ssoLogin}
			>
				<CardHeader>
					<CardHeaderContent $turned={hasDoubleAuth || ssoLogin}>
						<Flex gap="1rem" alignItemsInitial fullWidth>
							<Image src="/logo.png" alt="logo" width={35} height={35} />
							<div className="text-4xl">
								Numé
								<span className="text-blue-500  text-4xl">risk</span>
							</div>
						</Flex>
					</CardHeaderContent>
				</CardHeader>
				<CardBody>
					<CardFront>
						{!(hasDoubleAuth || ssoLogin) && (
							<Flex directionColumn gap="0.5rem" className="max-w-md">
								<FText
									name="email"
									label="Email"
									autoFocus
									className="custom-input"
								/>
								<FText
									customInputProps={{
										type: showPassword ? "text" : "password",
										endAdornment: (
											<Button
												isIconOnly
												variant="light"
												onPress={() => setShowPassword(!showPassword)}
											>
												{showPassword ? <VisibilityOff /> : <Visibility />}
											</Button>
										),
									}}
									name="password"
									label="Mot de passe"
									className="custom-input password-input"
								/>
								<FCheckbox
									name="remember_me"
									label="Rester connecté(e)"
									disabled={isLoggingIn}
									noBorder
								/>
								<Tooltip
									content={
										<>
											Raccourci : <Kbd>Entrée</Kbd>
										</>
									}
									placement="bottom"
								>
									<Button
										fullWidth
										color="primary"
										onPress={methods.handleSubmit(onSubmit)}
										kbd="enter"
									>
										Connexion
									</Button>
								</Tooltip>
								ou
								<Button
									fullWidth
									color="primary"
									variant="bordered"
									onPress={() => onSSOClick()}
								>
									Connexion SSO
								</Button>
							</Flex>
						)}
					</CardFront>
					<CardBack>
						{hasDoubleAuth && (
							<CardBackContent>
								<Flex gap="0.5rem" justifyCenter directionColumn>
									<div className="text-2xl text-center">
										Vous avez activé la double authentification
									</div>
									<FText
										name="otp_attempt"
										label="Code de vérification"
										autoFocus
									/>
									<div className="actions container-login100-form-btn">
										<Button
											color="primary"
											onPress={methods.handleSubmit(onSubmit2)}
										>
											Connexion
										</Button>
									</div>
								</Flex>
							</CardBackContent>
						)}
						{ssoLogin && (
							<CardBackContent>
								<Flex gap="0.5rem" directionColumn alignItemsInitial>
									<div className="text-2xl">Connexion SSO</div>
									<div className="text-sm text-gray-500">
										Entrez votre adresse mail, le système vous redirigera vers
										le SSO associé à votre mail.
									</div>
									<FText name="sso_mail" label="Email" />
									<Button
										fullWidth
										color="primary"
										onClick={handleSSORedirection}
									>
										Me connecter par SSO
									</Button>
									<Button
										fullWidth
										variant="light"
										color="primary"
										onClick={() => setSsoLogin(false)}
									>
										Revenir à la page de connexion
									</Button>
								</Flex>
							</CardBackContent>
						)}
					</CardBack>
				</CardBody>
			</GlassMorphismCard>
		</FormProvider>
	)
}

export default LoginCard
