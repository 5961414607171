import styled from "styled-components"

const ToolbarPart = styled.div`
    display: flex;
    padding: 5px;
    position: relative;

    :not(:last-child):after {
        content: '';
        display: block;
        width: 1px;
        background-color: lightgrey;
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
    }
`

export default ToolbarPart
