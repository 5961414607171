import { yupResolver } from "@hookform/resolvers/yup"
import useCommune from "@/hooks/useCommune"
import { createFiche, updateFiche } from "@/redux-toolkit/data/fiche/resources"
import React, { useEffect } from "react"
import { FormProvider, useForm } from "react-hook-form"
import { connect, useDispatch } from "react-redux"
import styled from "styled-components"
import FSelect from "utils/form/FSelect"
import FText from "utils/form/FText"
import SuperModal from "utils/modals/SuperModal"
import IFiche, { TypeFiche } from "utils/types/IFiche"
import Yup from "utils/yup"
import { v4 as uuidv4 } from "uuid"

const schema = Yup.object().shape({
	//'Type de risque': Yup.object().required(),
	//'Nom de la fiche': Yup.string().required(),
})

const Container = styled.div`
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 20px;
`

const ModalFicheScenario = ({
	toggleModal,
	isOpen,
	initialValues,
}: {
	toggleModal: () => void
	isOpen: boolean
	initialValues: IFiche | null
}) => {
	const commune = useCommune()
	const risks = commune.risks
	const dispatch = useDispatch()
	const defaultValues = {
		"Type de risque": initialValues?.risk || "",
		"Nom de la fiche": initialValues?.nom || "",
	}
	const methods = useForm({
		resolver: yupResolver(schema),
		defaultValues,
	})

	const onSubmit = (values: any) => {
		if (!initialValues) {
			dispatch(
				createFiche({
					nom: values["Nom de la fiche"],
					risk: values["Type de risque"],
					actions: {},
					actionsByCategory: [],
					id: uuidv4(),
					classification: TypeFiche.FICHE_SCENARIO,
				}),
			)
		} else {
			dispatch(
				updateFiche({
					...initialValues,
					nom: values["Nom de la fiche"],
					risk: values["Type de risque"],
				}),
			)
		}
		methods.reset(defaultValues)
		toggleModal()
	}
	useEffect(() => {
		if (initialValues) {
			methods.reset({
				"Nom de la fiche": initialValues.nom,
				"Type de risque": initialValues.risk,
			})
		}
	}, [initialValues])
	return (
		<>
			<SuperModal
				small
				isOpen={isOpen}
				onClose={() => {
					methods.reset(defaultValues)
					toggleModal()
				}}
				isNew={initialValues === null}
				title="Créer une nouvelle fiche Fiche"
				onClick={methods.handleSubmit(onSubmit)}
			>
				<Container>
					<FormProvider {...methods}>
						<FSelect
							name="Type de risque"
							label="Type de risque"
							options={risks}
						/>
						<FText name="Nom de la fiche" />
					</FormProvider>
				</Container>
			</SuperModal>
		</>
	)
}

export default ModalFicheScenario
