import IDaybookAlert, {
	StatusResponse,
} from "@/utils/types/daybook/IDaybookAlert"
import _ from "lodash"

const getStatusToMessage = (status: StatusResponse) => {
	switch (status) {
		case "sended":
			return "Message envoyé"
		case "200":
			return "Message envoyé"
		case "2000":
			return "Message envoyé"
		case "0":
			return "Impossible de recevoir le message sur ce numéro"
		case "3120":
			return "Code 3120 : Erreur inconnue de l'opérateur"
		case "3920":
			return "Code 3920 : Erreur inconnue reçue de l'application"
		case "F121":
			return "Code F121 : Erreur de protocole opérateur"
		case "F921":
			return "Code F921 : Erreur de protocole application"
		case "7122":
			return "Code 7122 : Données corrompues lors de l'envoi à l'opérateur"
		case "7922":
			return "Code 7922 : Données corrompues lors de l'envoi à l'application"
		case "B123":
			return "Code B123 : Limité par l'opérateur"
		case "B823":
			return "Code B823 : Limité par le routeur"
		case "F124":
			return "Code F124 : Erreur d'encodage lors de la communication avec l'opérateur"
		case "F125":
			return "Code F125 : L'opérateur a détecté un message en double"
		case "F825":
			return "Code F825 : Le routeur a détecté un message en double"
		case "7126":
			return "Code 7126 : Message expiré dans le tampon de l'opérateur"
		case "7826":
			return "Code 7826 : Message expiré dans le tampon du routeur"
		case "3127":
			return "Code 3127 : Texte trop long pour cet opérateur"
		case "3D27":
			return "Code 3D27 : Champ de session de l'application trop long"
		case "F128":
			return "Code F128 : Accès non autorisé au réseau de l'opérateur"
		case "7129":
			return "Code 7129 : Erreur réseau de l'opérateur"
		case "3130":
			return "Code 3130 : Identifiant d'opérateur non valide"
		case "F830":
			return "Code F830 : Aucun itinéraire trouvé"
		case "3930":
			return "Code 3930 : Identifiant d'application non valide"
		case "3331":
			return "Code 3331 : Numéro de téléphone non valide"
		case "3132":
			return "Code 3132 : Shortcode inconnu de l'opérateur"
		case "3932":
			return "Code 3932 : Shortcode inconnu de l'application"
		case "3133":
			return "Code 3133 : ID d'expéditeur refusé par le connecteur de l'opérateur"
		case "3833":
			return "Code 3833 : ID d'expéditeur refusé par le routeur"
		case "3933":
			return "Code 3933 : ID d'expéditeur non valide envoyé par le connecteur de l'application"
		case "3135":
			return "Code 3135 : Blacklisté par l'opérateur"
		case "3835":
			return "Code 3835 : Blacklisté dans le routeur"
		case "3336":
			return "Code 3336 : MSISDN porté"
		case "3138":
			return "Code 3138 : Cappé par l'opérateur"
		case "3838":
			return "Code 3838 : Cappé par le routeur"
		case "313B":
			return "Code 313B : L'opérateur a rejeté le message"
		case "313C":
			return "Code 313C : Opération non prise en charge par l'opérateur"
		case "B13D":
			return "Code B13D : Tampon de l'opérateur plein"
		case "B33D":
			return "Code B33D : Mémoire SIM pleine"
		case "B83D":
			return "Code B83D : Tampon plein"
		case "B33F":
			return "Code B33F : Téléphone mobile inaccessible"
		case "NO_PHONE":
			return "Pas de numéro de téléphone"
		case "NO_MOBILE_PHONE":
			return "Pas de numéro de téléphone mobile"
		case "NO_VALID_PHONE":
			return "Pas de numéro de téléphone valide"
		default:
			return status
	}
}
const getStatusColor = (
	response: IDaybookAlert["geojson"]["properties"]["responses"][string],
) => {
	if (response.status === "2000" && !_.isEmpty(response.received)) {
		return "var(--green500)"
	}
	switch (response.status) {
		case "200":
			return "var(--blue500)"
		case "2000":
			return "var(--blue500)"
		case "sended":
			return "var(--blue500)"
		case "NO_PHONE":
			return "var(--purple500)"
		case "NO_MOBILE_PHONE":
			return "var(--brown)"
		case "NO_VALID_PHONE":
			return "var(--red800)"
		default:
			return "var(--red600)"
	}
}

const getStatusToMessageSimple = (
	response: IDaybookAlert["geojson"]["properties"]["responses"][string],
) => {
	if ((response.status === "2000" || response.status == "200" || response.status == "sended") && !_.isEmpty(response.received)) {
		return "Réponse reçue"
	}
	switch (response.status) {
		case "sended":
			return "Message envoyé"
		case "200":
			return "Message envoyé"
		case "2000":
			return "Message envoyé"
		case "0":
			return "Impossible de recevoir le message sur ce numéro"
		case "NO_PHONE":
			return "Problème de numéro de téléphone"
		case "NO_MOBILE_PHONE":
			return "Problème de numéro de téléphone"
		case "NO_VALID_PHONE":
			return "Problème de numéro de téléphone"
		default:
			return "Erreur"
	}
}
const getMessageToStatus = (
	status:
		| "Réponse reçue"
		| "Envoyé"
		| "Attente de prise en compte par l’opérateur"
		| "Message envoyé"
		| "Erreur"
		| "Impossible de recevoir le message sur ce numéro",
	response: IDaybookAlert["geojson"]["properties"]["responses"][string],
) => {
	if (status === "Réponse reçue") {
		return "2000"
	}
	if (status === "Impossible de recevoir le message sur ce numéro") {
		return "0"
	}
	if (status === "Envoyé") {
		return "sended"
	}
	if (status === "Attente de prise en compte par l’opérateur") {
		return "200"
	}
	if (status === "Message envoyé") {
		return "2000"
	}
	if (status === "Erreur") {
		return response.status
	}
	return response.status
}

export default {
	getStatusToMessage,
	getStatusColor,
	getStatusToMessageSimple,
	getMessageToStatus,
}
