import React from "react"
import FIconRadios from "utils/form/FIconRadios"
import styled from "styled-components"
import SmsOutlined from "@mui/icons-material/SmsOutlined"
import GraphicEqOutlined from "@mui/icons-material/GraphicEqOutlined"
import { DaybookAlertCanal } from "@/utils/types/daybook/IDaybookAlert"

const SSmsOutlinedIcon = styled(SmsOutlined)`
    font-size: 2rem !important;
    color: var(--primary500);
`
const SGraphicEqOutlinedIcon = styled(GraphicEqOutlined)`
    font-size: 2rem !important;
    color: var(--red500);
`

const DaybookAlertFIconRadios = ({ disabled }: { disabled?: boolean }) => {
	return (
		<FIconRadios
			disabled={disabled}
			name="geojson.properties.type"
			label="Type de campagne"
			padding="0.5rem"
			iconOptions={[
				{
					icon: <SSmsOutlinedIcon />,
					value: DaybookAlertCanal.SMS,
				},
				{
					icon: <SGraphicEqOutlinedIcon />,
					value: DaybookAlertCanal.VOCALE,
				},
			]}
		/>
	)
}

export default DaybookAlertFIconRadios
