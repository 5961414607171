import { Paper } from "@mui/material"
import useCooperationFilters, {
	ICooperationFilters,
} from "@/hooks/useCooperationFilters"
import { setCooperationFilters } from "@/redux-toolkit/edit/reducer"
import React from "react"
import { isMobile } from "react-device-detect"
import { useDispatch } from "react-redux"
import styled from "styled-components"
import SearchCommune from "utils/components/SearchCommune"
import searchConfig from "../utils/searchConfig"
import SearchBarOptions from "./SearchBarOptions"
import { SearchMode } from "@/redux-toolkit/edit/cooperationFiltersRessources"
import _ from "lodash"
import useCdc from "@/hooks/useCdc"
import CoopLayersSelection from "./CoopLayersSelection"

const Container = styled.div`
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: center;
`

const MyPaper = styled(Paper)`
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex: 1;
    width: 100%;

    & > *,
    & > * > * {
        position: relative;
    }

    & > *:not(:last-child):after,
    & > * > *:not(:last-child):after {
        content: '';
        display: block;
        width: 1px;
        background-color: lightgrey;
        position: absolute;
        top: 8px;
        bottom: 8px;
        right: 0;
    }
`

const SearchItem = styled.div`
    display: flex;
    flex: 1;
`

const InputsContainer = styled.div`
    display: flex;
    flex: 1;
    min-width: 0;
    flex-direction: ${isMobile ? "column" : "row"};
`

const CoopSearchBar = () => {
	const coopFilters = useCooperationFilters()
	const cdc = useCdc()
	const communes = _.cloneDeep(cdc.communes)
	const dispatch = useDispatch()

	const currentSearch = searchConfig.find(
		(item) => item.mode === coopFilters.search,
	)

	const orderedCommunes = communes.sort((a, b) => {
		const nameA = a.name.toUpperCase()
		const nameB = b.name.toUpperCase()
		return nameA.localeCompare(nameB)
	})

	return (
		<Container>
			<MyPaper>
				<InputsContainer>
					{currentSearch?.fields.layers &&
						!currentSearch?.fields.layers.inFilterMenu && (
							<SearchItem>
								<CoopLayersSelection
									layers={coopFilters.availableLayers}
									label={currentSearch?.fields?.layers?.label}
								/>
							</SearchItem>
						)}
					{currentSearch?.fields.communes && (
						<SearchItem>
							<SearchCommune
								options={orderedCommunes.map((c) => c.id)}
								communes={Object.values(cdc.communes)}
								setFilter={(communes) =>
									dispatch(
										setCooperationFilters({
											communes,
										}),
									)
								}
								placeholder="Où ? (toute la communauté de communes par défaut)"
								filter={coopFilters.communes}
								exclude={coopFilters.excludeCommunes}
								small={isMobile}
							/>
						</SearchItem>
					)}
				</InputsContainer>
				<SearchBarOptions
					excludeCommunes={currentSearch?.fields.communes}
					layers={
						currentSearch?.fields.layers?.inFilterMenu
							? currentSearch?.fields.layers
							: undefined
					}
				/>
			</MyPaper>
		</Container>
	)
}

export default CoopSearchBar
