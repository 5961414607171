import { Accordion, AccordionItem, User } from "@nextui-org/react"
import React, { useMemo } from "react"
import SideBarContent from "../SideBarDesktopContent/content/SideBarContent"
import { ITool } from "../SideBar.hook"
import { useDispatch, useSelector } from "react-redux"
import { toast } from "react-toastify"
import { isMobile } from "react-device-detect"
import { DAYBOOK_LEGEND } from "@/utils/panels/PanelCarto"
import { setActiveTool, setPanel } from "@/redux-toolkit/common/reducer"
import { resetEdit } from "@/redux-toolkit/edit/reducer"
import useOpenAppBar from "@/hooks/useOpenAppBar"
import { useHistory } from "react-router-dom"
import styled from "styled-components"
import LaunchIcon from "@mui/icons-material/Launch"
import { Flex } from "@/utils/components/style/flex"

const SFlex = styled(Flex)`
	.nodisplaychildren section {
		border: 1px solid red;
		visibility: hidden;
		height: 0 !important;
		border: none !important;
	}
`
const SideBarMobileContent = ({
	openIndex,
	setOpenIndex,
	toolsList,
}: {
	openIndex: number
	setOpenIndex: (index: number) => void
	toolsList: ITool[]
}) => {
	const dispatch = useDispatch()
	const [isOpen, setIsOpen] = useOpenAppBar()
	const history = useHistory()
	const activeTool = useSelector((state: any) => state?.common?.activeTool)
	const defaultExpanded = useMemo(() => {
		let toolsToReturn = undefined
		toolsList.forEach((tool, index) => {
			if (tool.route) {
				if (tool.route === activeTool) {
					toolsToReturn = tool.name
				}
			}
			if (tool.subMenus) {
				tool.subMenus.forEach((subMenu, subMenuIndex) => {
					if (
						location.pathname.includes(subMenu.route) &&
						activeTool !== location.pathname
					) {
						toolsToReturn = tool.name
					}
				})
			}
		})
		return toolsToReturn
	}, [activeTool, toolsList])

	return (
		<SFlex directionColumn spaceBetween fullHeight fullWidth>
			<Accordion className="px-4" defaultExpandedKeys={[defaultExpanded]}>
				{toolsList.map((tool, index) => {
					const isActiveSubMenu = tool.subMenus.find(
						(subMenu) => subMenu.route === activeTool,
					)
					const addedClassNames =
						tool.subMenus.length === 1 ? "nodisplaychildren" : ""

					return (
						<AccordionItem
							key={tool.name}
							aria-label={tool.name}
							startContent={tool.icon}
							subtitle={tool.description}
							title={tool.name}
							indicator={tool.subMenus.length === 1 ? <LaunchIcon /> : null}
							onPress={(e) => {
								if (tool.subMenus.length === 1) {
									dispatch(setActiveTool(tool.subMenus[0].route))
									if (
										tool.subMenus[0].route === "/maincourante/carto" &&
										!isMobile
									) {
										dispatch(
											setPanel({
												type: DAYBOOK_LEGEND,
											}),
										)
									} else {
										dispatch(setPanel(null))
									}
									setOpenIndex(-1)
									history.push(tool.subMenus[0].route)
									dispatch(resetEdit())
									if (isMobile) {
										setIsOpen(false)
									}
								}
							}}
							classNames={
								isActiveSubMenu && {
									base: addedClassNames,
									title: "text-primary",
									indicator: "text-primary !rotate-0",
									startContent: "text-primary",
									subtitle: "text-primary",
								}
							}
						>
							{tool.subMenus.length > 1 && (
								<SideBarContent
									index={index}
									tool={tool}
									setOpenIndex={setOpenIndex}
									openIndex={openIndex}
								/>
							)}
						</AccordionItem>
					)
				})}
			</Accordion>
		</SFlex>
	)
}

export default SideBarMobileContent
