import React, { useMemo, useState, useContext } from "react"
import staticFiles from "isoMorphicData/pcsStaticFiles/pcsStaticFiles.json"
import SuperReactTable from "utils/components/tables/SuperReactTable/SuperReactTable"
import ErrorService from "@/services/ErrorService"
import Axios from "axios"
import ModalEditKit from "./ModalEditKit"
import ModalKitContent from "./ModalKitContent"
import { Link, Radio } from "@mui/material"
import { width80 } from "utils/components/tables/widthProps"
import { toggleCheck, resetTree } from "../redux/tree/action"
import { KitContext } from "../redux/kit/context"
import { TreeContext } from "../redux/page/context"
import { resetSelection } from "../redux/selection/action"
import { SelectionContext } from "../redux/selection/context"
import DownloadService from "../services/DownloadService"
import useAccessRights from "@/hooks/useAccessRights"
import { PATH_DOWNLOAD_PCS } from "@/redux-toolkit/userSettings/constants"
import styled from "styled-components"
import { useDispatch } from "react-redux"
import useStaticFiles from "@/hooks/useStaticFiles"
import _ from "lodash"
import useUploadedFiles from "@/hooks/useUploadedFiles"
import TreeService from "utils/services/TreeService"
import Warning from "@mui/icons-material/Warning"
import { isMobile } from "react-device-detect"
import { ColumnDef } from "@tanstack/react-table"
import { EnumToolPath } from "@/hooks/services/useAccessRightsServices"
const SUl = styled.ul`
    :first-child {
        // used to elevate priority so that important take effect
        margin-bottom: 0 !important;
        padding-left: 20px !important;
    }
`
const RedLi = styled.li`
    color: red;
    display: flex;
    align-items: center;
    & > .MuiTableCell-root {
        font-size: 1rem !important;
    }
`
const SWarning = styled(Warning)`
    color: red;
    font-size: 1rem !important;
`
const Pcskits = ({
	setModalWaitingOpened,
	fetchKits,
	fetchedKits,
	kitSelected,
	setKitSelected,
	checkKitId,
	updateSelectedKit,
	stopEditing,
	setForceTab,
	setKeyOrder,
}) => {
	const permissions = useAccessRights(EnumToolPath.DOWNLOAD_PCS)
	const [editModalIsOpen, setEditModalIsOpen] = useState(false)
	const [titlesModalIsOpen, setTitlesModalIsOpen] = useState(false)
	const [kitInfos, setKitInfos] = useState({})
	const uploadedFiles = useUploadedFiles()
	const { state: treeState, dispatch: treeDispatch } = useContext(TreeContext)
	const flatTree = useMemo(() => TreeService.treeToFlat(treeState), [treeState])
	const { state: kitNameState, dispatch: kitDispatch } = useContext(KitContext)
	const { state: selectionState, dispatch: selectionDispatch } =
		useContext(SelectionContext)
	const staticFiles = useStaticFiles({ withUploaded: true })

	const dispatch = useDispatch()

	const selectKit = (kit) => {
		treeDispatch(resetTree())
		selectionDispatch(resetSelection())
		kit.kit_content.forEach((element: any) => {
			let treeElement = flatTree[element]
			if (!treeElement?.key) {
			} else {
				let path = [treeElement.key]
				let i = 0
				while (treeElement.parent) {
					path.push("children", treeElement.parent.key)
					treeElement = treeElement.parent
					i++
					if (i > 4) {
						break
					}
				}
				treeDispatch(toggleCheck(path.reverse()))
			}
		})
		kitDispatch(kit.kit_name)
	}

	const columns = useMemo(() => {
		return [
			{
				header: "Choisir",
				accessorKey: "id", // String-based value accessors!
				...width80,
				enableSorting: false,
				isAction: true,
				cell: (props) => {
					return (
						<Radio
							checked={checkKitId === props.row.original.id}
							onChange={() => {
								stopEditing()
								selectKit(props.row.original)
								updateSelectedKit(props.row.original)
								if (isMobile) {
									setForceTab(2)
								}
							}}
						/>
					)
				},
			},
			{
				header: "Mes Kits",
				accessorKey: "kit_name",
			},
			{
				header: "Contenu du kit",
				accessorKey: "kit_content",
				cell: ({ getValue }) => {
					const value = (getValue() ?? []) as any
					const elementsTitle = value.map((contentKitElement) => {
						if (!flatTree[contentKitElement]?.title) {
							return (
								<RedLi>
									<SWarning />
									{contentKitElement}
								</RedLi>
							)
						} else {
							return (
								<li key={contentKitElement}>
									{flatTree[contentKitElement]?.title}
								</li>
							)
						}
					})
					return (
						<SUl>
							{elementsTitle.map((title) => {
								return <li key={title}>{title}</li>
							})}
						</SUl>
					)
				},
			},
		] as ColumnDef<any>[]
	}, [checkKitId])

	const toggleEditModal = () => {
		setEditModalIsOpen(!editModalIsOpen)
	}

	const toggleContentModal = () => {
		setTitlesModalIsOpen(!titlesModalIsOpen)
	}

	const deleteKit = async (kit) => {
		try {
			await Axios.delete(`/api/kit_pcs/${kit.id}`)
			if (kitSelected?.id === kit.id) {
				stopEditing(), setKitSelected(undefined), treeDispatch(resetTree())
				setKeyOrder({})
			}
			fetchKits()
		} catch (error) {
			const errorMessage = `La suppréssion du kit à échouée : ${error}`
			ErrorService.error({
				component: "Pcskits:deleteKit",
				message: errorMessage,
				dispatch,
			})
		}
	}

	return (
		<>
			<SuperReactTable
				columns={columns}
				data={fetchedKits}
				onRowClick={(row) => {
					stopEditing()
					selectKit(row.original)
					updateSelectedKit(row.original)
				}}
				onEditClick={(original) => {
					stopEditing()
					setKitInfos(original)
					selectKit(original)
					updateSelectedKit(original)
					setEditModalIsOpen(true)
				}}
				onDeleteClick={(original) => {
					deleteKit(original)
				}}
				selectable={false}
				isEditable={permissions.write}
				isDeletable={permissions.write}
			/>
			<ModalEditKit
				kitEdited={kitInfos}
				setKitSelected={setKitSelected}
				updateSelectedKit={updateSelectedKit}
				kitSelected={kitSelected}
				isOpen={editModalIsOpen}
				onClose={toggleEditModal}
				fetchKits={fetchKits}
			/>
			<ModalKitContent
				isOpen={titlesModalIsOpen}
				onClose={toggleContentModal}
				kit={kitInfos}
			/>
		</>
	)
}

export default Pcskits
