import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	TextField,
} from "@mui/material"
import React, { useState } from "react"

function URLDialog({ open, onClose, onChange }) {
	const [url, setUrl] = useState("")

	return (
		<Dialog open={open} onClose={onClose}>
			<DialogTitle>Choisir une URL</DialogTitle>
			<DialogContent>
				<TextField
					margin="normal"
					style={{ width: "500px" }}
					value={url}
					onChange={(e) => setUrl(e.target.value)}
					type="url"
					label="URL"
				/>
			</DialogContent>
			<DialogActions>
				<Button onClick={onClose}>Annuler</Button>
				<Button onClick={() => onChange(url)} disabled={!url}>
					Insérer
				</Button>
			</DialogActions>
		</Dialog>
	)
}

export default URLDialog
