import CustomTooltip from "./style/CustomTooltip"
import IconButton from "@mui/material/IconButton"
import LockIcon from "@mui/icons-material/Lock"
import LockOpenIcon from "@mui/icons-material/LockOpen"
import React, { useState } from "react"
import ConfirmationModal from "./ConfirmationModal"
import { toast } from "react-toastify"
import styled from "styled-components"

const Div = styled.div``

const SuperLockButton = ({
	onClick,
	rowInfos,
	wrapper = undefined,
	small = false,
	disabled = false,
}) => {
	const [isOpen, setIsOpen] = useState(false)
	const Wrapper = wrapper ?? Div

	const RenderedLockIcon = rowInfos?.locked ? LockIcon : LockOpenIcon
	const toastMessage = rowInfos?.locked
		? "La crise a bien été déverrouillée."
		: "La crise est désormais verrouillée."
	const modalMessage = rowInfos?.locked ? (
		<>
			Êtes-vous certain(e) de vouloir déverrouiller la crise {rowInfos?.name} ?
			Vous ne pourrez plus la modifier ensuite. Cette action est réversible en
			cliquant sur le bouton "Déverrouiller".
		</>
	) : (
		<>
			Êtes-vous certain(e) de vouloir verrouiller la crise {rowInfos?.name} ?
			<br />
			<br />
			Vous ne pourrez plus la modifier ensuite. Cette action est réversible en
			cliquant sur le bouton "Déverrouiller".
		</>
	)

	return (
		<>
			<Wrapper
				onClick={(event) => {
					if (disabled) {
						return
					}
					setIsOpen(true)
					event.stopPropagation()
				}}
			>
				<CustomTooltip
					title={rowInfos?.locked ? "Déverrouiller" : "Verrouiller"}
				>
					<IconButton
						size={small ? "small" : "medium"}
						style={{ fontSize: 24 }}
						aria-label="Lock"
						disabled={disabled}
					>
						<RenderedLockIcon style={{ fontSize: 24 }} />
					</IconButton>
				</CustomTooltip>
			</Wrapper>
			<ConfirmationModal
				modalShown={isOpen}
				onClose={() => setIsOpen(false)}
				onConfirm={() => {
					onClick()
					toast.info(toastMessage)
					setIsOpen(false)
				}}
				message={modalMessage}
			/>
		</>
	)
}

export default SuperLockButton
