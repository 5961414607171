import { IconButton } from "@mui/material"
import FormatBoldIcon from "@mui/icons-material/FormatBold"
import FormatItalicIcon from "@mui/icons-material/FormatItalic"
import FormatUnderlinedIcon from "@mui/icons-material/FormatUnderlined"
import React from "react"
import ToolbarPart from "./ToolbarPart"

import FormatStrikethroughIcon from "@mui/icons-material/FormatStrikethrough"
import SubscriptIcon from "@mui/icons-material/Subscript"
import SuperscriptIcon from "@mui/icons-material/Superscript"

function InlineToolbar({ currentState, onChange, config }) {
	const { underline, bold, italic, strikethrough, superscript, subscript } =
		currentState

	if (!config.options.length) return null

	return (
		<ToolbarPart>
			{config.options.includes("bold") && (
				<IconButton onClick={() => onChange("bold")}>
					<FormatBoldIcon color={bold ? "primary" : "action"} />
				</IconButton>
			)}
			{config.options.includes("italic") && (
				<IconButton onClick={() => onChange("italic")}>
					<FormatItalicIcon color={italic ? "primary" : "action"} />
				</IconButton>
			)}
			{config.options.includes("underline") && (
				<IconButton onClick={() => onChange("underline")}>
					<FormatUnderlinedIcon color={underline ? "primary" : "action"} />
				</IconButton>
			)}
			{config.options.includes("strikethrough") && (
				<IconButton onClick={() => onChange("strikethrough")}>
					<FormatStrikethroughIcon
						color={strikethrough ? "primary" : "action"}
					/>
				</IconButton>
			)}
			{config.options.includes("superscript") && (
				<IconButton onClick={() => onChange("superscript")}>
					<SuperscriptIcon color={superscript ? "primary" : "action"} />
				</IconButton>
			)}
			{config.options.includes("subscript") && (
				<IconButton onClick={() => onChange("subscript")}>
					<SubscriptIcon color={subscript ? "primary" : "action"} />
				</IconButton>
			)}
		</ToolbarPart>
	)
}

export default InlineToolbar
