import {
	FormControlLabel,
	IconButton,
	Radio,
	RadioGroup,
	Tooltip,
} from "@mui/material"
import React from "react"
import { isMobile } from "react-device-detect"
import { useController, useFormContext } from "react-hook-form"
import styled, { css } from "styled-components"
import HelpIcon from "@mui/icons-material/Help"
import { Flex } from "utils/components/style/flex"
import { SFormControlLabel } from "./FCheckboxes"
import NewStyledInputLayout from "./formUtils/NewStyledInputLayout"
const StyledHelpIcon = styled(HelpIcon)`
    margin-right: 1rem;
    color: #767676;
`

const IconContainer = styled(Flex)<{
	$active: boolean
	$padding: string
	disabled: boolean
	multi: boolean
}>`
    border: 1px solid var(--neutral500);
	box-sizing: border-box;
    border-radius: 0.5rem;
	${({ multi }) => (multi ? "width: 230px;" : "width: 100%;")}
    padding: ${({ $padding }) => $padding ?? "0.5rem"};
    ${({ $active }) =>
			$active &&
			css`
            border: 2px solid var(--primary500);
            background-color: var(--primary50);
        `}

    ${({ disabled, $active }) => {
			if (disabled && !$active) {
				return css`
                border: 1px solid var(--neutral300);
                background-color: white;
            `
			}

			if (disabled && $active) {
				return css`
                border: 2px solid var(--neutral300);
                background-color: var(--neutral50);
            `
			}
		}}

    cursor: pointer !important;
    &:hover {
        border: 2px solid var(--primary500);
        cursor: pointer;
    }
`
const FIconRadios = ({
	name,
	label = undefined,
	iconOptions,
	directionColumn = false,
	disabled = false,
	readOnly = false,
	helpText = null,
	padding = "2rem",
	multi = false, // Add the multi prop with a default value of false
}) => {
	const { control } = useFormContext()

	const {
		field: { ref, value, onChange },
		fieldState: { error },
	} = useController({
		name,
		control,
		rules: { required: true },
	})

	const handleOptionChange = (optionValue) => {
		if (!multi) {
			// If multi is false, clear the previous selection before setting the new value
			onChange(optionValue)
		} else {
			const selectedOptions = Array.isArray(value) ? value : []
			if (selectedOptions.includes(optionValue)) {
				const updatedOptions = selectedOptions.filter(
					(option) => option !== optionValue,
				)
				onChange(updatedOptions)
			} else {
				const updatedOptions = [...selectedOptions, optionValue]
				onChange(updatedOptions)
			}
		}
	}

	return (
		<NewStyledInputLayout
			error={Boolean(error)}
			helperText={error?.message}
			label={label}
			empty={false}
			disabled={disabled}
			noBorder
		>
			<RadioGroup
				ref={ref}
				value={value}
				onChange={(e) => {
					handleOptionChange(e.target.value)
				}}
				tabIndex={0}
			>
				<Flex fullWidth $wrap={multi} gap="0.5rem">
					{iconOptions.map((iconOption) => (
						<IconContainer
							multi={multi}
							directionColumn
							gap="1rem"
							disabled={disabled}
							onClick={() => handleOptionChange(iconOption.value)}
							$active={value?.includes(iconOption.value)}
							$padding={padding}
							key={iconOption.value}
						>
							{iconOption.icon}
							{iconOption.label ?? iconOption.value}
						</IconContainer>
					))}
					{helpText && (
						<Tooltip title={helpText}>
							<StyledHelpIcon />
						</Tooltip>
					)}
				</Flex>
			</RadioGroup>
		</NewStyledInputLayout>
	)
}

export default FIconRadios
