import { updateFiche } from "@/redux-toolkit/data/fiche/resources"
import _ from "lodash"
import { useCallback } from "react"
import { useDispatch } from "react-redux"
import IFiche, { IActionCategory } from "utils/types/IFiche"

const useDeleteFiche = (fiche: IFiche) => {
	const dispatch = useDispatch()

	return useCallback(
		(category: IActionCategory) => {
			const newFiche = _.cloneDeep(fiche)

			category.actionIds.forEach(
				(actionId) => delete newFiche.actions[actionId],
			)

			newFiche.actionsByCategory = fiche.actionsByCategory.filter(
				(cat) => cat.id !== category.id,
			)

			dispatch(updateFiche(newFiche))
		},
		[fiche],
	)
}

export default useDeleteFiche
