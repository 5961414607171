import React, { createContext, Dispatch, SetStateAction, useState } from "react"

interface IPcsConfigContext {
	config: { anonymize: boolean; generate_livret: boolean }
	setConfig: Dispatch<
		SetStateAction<{ anonymize: boolean; generate_livret: boolean }>
	>
}

const PcsConfigContext = createContext<IPcsConfigContext>({
	config: undefined,
	setConfig: undefined,
})

export default PcsConfigContext
