import useSelectedEvent from "@/hooks/useSelectedEvent"
import { updateEvent } from "@/redux-toolkit/data/events/resources"
import _ from "lodash"
import { useCallback } from "react"
import { useDispatch } from "react-redux"
import { ABSENT, PRESENT } from "utils/types/IEvent"

const useSetOrgaMemberAbsent = () => {
	const selectedEvent = useSelectedEvent()
	const dispatch = useDispatch()
	const absents = selectedEvent.orga.absents
	const substitutions = selectedEvent.orga.substitutions

	return useCallback(
		async (
			id: string,
			absence: typeof PRESENT | typeof ABSENT,
			isResponsable = false,
		) => {
			if (absence === ABSENT) {
				const newSubs = _.cloneDeep(substitutions)
				for (const key in newSubs) {
					if (newSubs[key] === id) delete newSubs[key]
				}
				await dispatch(
					updateEvent({
						...selectedEvent,
						orga: {
							substitutions: newSubs,
							absents: [...absents, id],
						},
					}),
				)
			} else if (absence === PRESENT) {
				const newAbsents = _.cloneDeep(absents)
				newAbsents.splice(absents.indexOf(id), 1)
				if (!isResponsable) {
					const { [id]: toDelete, ...rest } = substitutions
					await dispatch(
						updateEvent({
							...selectedEvent,
							orga: {
								substitutions: rest,
								absents: newAbsents,
							},
						}),
					)
				} else {
					await dispatch(
						updateEvent({
							...selectedEvent,
							orga: {
								...selectedEvent.orga,
								absents: newAbsents,
							},
						}),
					)
				}
			}
		},
		[selectedEvent],
	)
}

export default useSetOrgaMemberAbsent
