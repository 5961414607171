import Grid from "@mui/material/Grid"
import IconButton from "@mui/material/IconButton"
import TextField from "@mui/material/TextField"
import LockIcon from "@mui/icons-material/Lock"
import LockOpenIcon from "@mui/icons-material/LockOpen"
import React, { useState } from "react"

function SizeInputs({
	originalWidth,
	originalHeight,
	width,
	height,
	onChangeWidth,
	onChangeHeight,
}) {
	const [maintainAspectRatio, setMaintainAspectRatio] = useState(true)
	const aspectRatio = originalWidth / originalHeight

	const handleChangeWidth = (ev) => {
		ev.stopPropagation()
		const value = ev.currentTarget.value
		const w = value === "" || isNaN(value) ? 0 : parseInt(value)
		onChangeWidth(w)
		if (maintainAspectRatio) {
			onChangeHeight(Math.round(w / aspectRatio))
		}
	}

	const handleChangeHeight = (ev) => {
		ev.stopPropagation()
		const value = ev.currentTarget.value
		const h = value === "" || isNaN(value) ? 0 : parseInt(value)
		onChangeHeight(h)
		if (maintainAspectRatio) {
			onChangeWidth(Math.round(h * aspectRatio))
		}
	}

	const handleClickAspectRatio = () => {
		const newMaintainAspectRatio = !maintainAspectRatio
		setMaintainAspectRatio(newMaintainAspectRatio)
		if (newMaintainAspectRatio) {
			onChangeHeight(Math.round(width / aspectRatio))
		}
	}

	return (
		<Grid
			container
			direction="row"
			alignItems="center"
			spacing={2}
			component="div"
		>
			<Grid item>
				<TextField
					type="number"
					label={"Largeur"}
					size="small"
					value={width}
					onChange={handleChangeWidth}
					onClick={(ev) => ev.stopPropagation()}
					style={{ maxWidth: 90 }}
				/>
			</Grid>
			<Grid item>
				<IconButton onClick={handleClickAspectRatio} size="small">
					{maintainAspectRatio ? <LockIcon /> : <LockOpenIcon />}
				</IconButton>
			</Grid>
			<Grid item>
				<TextField
					type="number"
					label={"Hauteur"}
					size="small"
					value={height}
					onChange={handleChangeHeight}
					onClick={(ev) => ev.stopPropagation()}
					style={{ maxWidth: 90 }}
				/>
			</Grid>
		</Grid>
	)
}

export default SizeInputs
