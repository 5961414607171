import { Checkbox, FormControlLabel } from "@mui/material"
import React from "react"
import { isMobile } from "react-device-detect"
import { useController, useFormContext } from "react-hook-form"
import styled, { css } from "styled-components"
import NewStyledInputLayout from "./formUtils/NewStyledInputLayout"

export const SFormControlLabel = styled(FormControlLabel)<{
	$readOnly: boolean
}>`
    ${({ $readOnly }) =>
			$readOnly &&
			css`
            pointer-events: none;
        `}
`

const FCheckbox = ({
	name,
	label = undefined,
	disabled = false,
	readOnly = false,
	noBorder = false,
}) => {
	const { control } = useFormContext()

	const {
		field: { ref, value, onChange },
		fieldState: { error },
	} = useController({
		name,
		control,
		rules: { required: true },
		defaultValue: false,
	})

	return (
		<NewStyledInputLayout
			error={Boolean(error)}
			helperText={error?.message}
			empty={false}
			disabled={disabled}
			noBorder={noBorder}
		>
			<SFormControlLabel
				$readOnly={readOnly}
				label={label}
				value={value}
				control={
					<Checkbox readOnly={readOnly} checked={value} onChange={onChange} />
				}
			/>
		</NewStyledInputLayout>
	)
}

export default FCheckbox
