import Debug from "debug"
import React from "react"
import { useFormContext } from "react-hook-form"
import { Flex } from "utils/components/style/flex"
import FText from "utils/form/FText"
import { FormDependencies } from "utils/form/FormDependencies"
import FTextAlert from "utils/form/alert/FTextAlert"
import { DaybookAlertCanal } from "@/utils/types/daybook/IDaybookAlert"
import DaybookAlertTemplateSelect from "./DaybookAlertTemplateSelect"

// app:javascript:components:maincourante:alert:alertCreation:alertStep:components:prepare:DaybookAlertRightContentSMSVocalSection.tsx
const debug = Debug(
	"app:javascript:components:maincourante:alert:alertCreation:alertStep:components:prepare:DaybookAlertRightContentSMSVocalSection",
)
debug.log = console.log.bind(console)

const DaybookAlertRightContentSMSVocalSection = ({ disabled = false }) => {
	const { watch } = useFormContext()

	watch("geojson.properties.smsText")
	watch("geojson.properties.voiceContent")

	return (
		<FormDependencies targets={["geojson.properties.type"]}>
			{(dependencies) => {
				const type = dependencies["geojson.properties.type"]

				return (
					<>
						{!disabled && <DaybookAlertTemplateSelect />}
						{type === DaybookAlertCanal.SMS && (
							<Flex directionColumn gap="1rem" fullWidth>
								<FTextAlert
									name="geojson.properties.smsText"
									label="Contenu du SMS"
									disabled={disabled}
								/>
							</Flex>
						)}
						{type === DaybookAlertCanal.VOCALE && (
							<Flex directionColumn gap="1rem" fullWidth>
								<FText
									multiline
									disabled={disabled}
									name="geojson.properties.voiceContent"
									label="Contenu du message vocal"
									minRows={5}
								/>
							</Flex>
						)}
					</>
				)
			}}
		</FormDependencies>
	)
}

export default DaybookAlertRightContentSMSVocalSection
