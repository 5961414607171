import { useMemo } from "react"
import formatNfdLowerCase from "utils/formatNfdLowerCase"
import useOrga from "../useOrga"
import useFiches from "../useFiches"

const useSearchFiches = (words: string[]) => {
	const fiches = useFiches()
	const orga = useOrga()

	const searchFiches = useMemo(() => {
		const matchFiches = Object.values(fiches).filter((fiche) => {
			return words.every((word) => {
				const ficheNom = orga[fiche.nom] ? orga[fiche.nom]?.idName : fiche.nom
				const ficheTitle = formatNfdLowerCase(ficheNom)
				return ficheTitle.includes(word)
			})
		})
		return matchFiches
	}, [words, fiches, orga])

	return searchFiches
}

export default useSearchFiches
