import ReportService from "@/pages/carto2/cartographie/service/ReportService"
import _ from "lodash"
import React from "react"
import HeaderBanner from "utils/pdf/header/HeaderBanner"
import PH2 from "utils/pdf/header/PH2"
import PPage from "utils/pdf/PPage"
import PTitle from "utils/pdf/PTitle"
import SmallText from "utils/pdf/SmallText"
import PCell from "utils/pdf/table/PCell"
import PRow from "utils/pdf/table/PRow"
import PTable from "utils/pdf/table/PTable"
import { IFamilyMemberType } from "utils/types/IFamilyMember"

const DaybookCareTable = ({
	selectedEvent,
	points,
	humans,
	indexInSummary,
	childIndexInSummary,
}) => {
	const carePoints = Object.values(points)
		.filter((point: any) => point.jsonschema_id === "TEMPORARY_CARE")
		.filter((eventPoint: any) => eventPoint.event_id === selectedEvent.id)

	const sortedCarePoints = _.sortBy(
		carePoints,
		(point: any) => point.created_at,
	).reverse()

	const careFamilies = Object.values(points)
		.filter((point: any) => point.jsonschema_id === "CARE_FAMILY")
		.filter((eventPoint: any) => eventPoint.event_id === selectedEvent.id)

	const displayGlobalTypes = (currentCareFamilies) => {
		let nbAdults = 0
		let nbChilds = 0
		let nbBabies = 0
		let nbAnimals = 0
		currentCareFamilies.forEach((family: any) => {
			// Familly referer isn't automatically included
			nbAdults++
			family.geojson.properties["family"].forEach((type) => {
				if (type.type === IFamilyMemberType.ADULT) {
					type.quantity ? (nbAdults += type.quantity) : nbAdults++
				} else if (type.type === IFamilyMemberType.CHILD) {
					type.quantity ? (nbChilds += type.quantity) : nbChilds++
				} else if (type.type === IFamilyMemberType.BABY) {
					type.quantity ? (nbBabies += type.quantity) : nbBabies++
				} else if (type.type === IFamilyMemberType.ANIMAL) {
					type.quantity ? (nbAnimals += type.quantity) : nbAnimals++
				}
			})
		})
		return (
			<>
				<SmallText>Adultes : {nbAdults}</SmallText>
				<SmallText>Enfants : {nbChilds}</SmallText>
				<SmallText>Bébés : {nbBabies}</SmallText>
				<SmallText>Animaux : {nbAnimals}</SmallText>
			</>
		)
	}

	const displayGlobalSpecificities = (currentCareFamilies) => {
		const objectOfSpecificity = {}
		currentCareFamilies.forEach((family: any) => {
			const refererProperties = family.geojson.properties
			const familyMembers = refererProperties.family
			refererProperties["Spécificités"].forEach((specificity) => {
				if (!objectOfSpecificity[specificity]) {
					objectOfSpecificity[specificity] = 0
				}
				objectOfSpecificity[specificity] = objectOfSpecificity[specificity] + 1
			})
			familyMembers.forEach((member) => {
				if (member["Spécificités"]) {
					member["Spécificités"].forEach((specificity) => {
						if (!objectOfSpecificity[specificity]) {
							objectOfSpecificity[specificity] = 0
						}
						objectOfSpecificity[specificity] =
							objectOfSpecificity[specificity] + 1
					})
				}
			})
		})

		return objectOfSpecificity
	}

	return (
		<PPage orientation="landscape">
			<HeaderBanner>
				<PH2 marginBottom="0px">{indexInSummary}. Main courante</PH2>
			</HeaderBanner>
			<PTitle color="#2e74b5" marginBottom={10}>
				{indexInSummary}.{childIndexInSummary}. Centres d&apos;accueil
			</PTitle>
			<PTable>
				<PRow>
					<PCell minHeight={0} head>
						<SmallText>Lieu</SmallText>
					</PCell>
					<PCell minHeight={0} width={70} head>
						<SmallText>Capacité</SmallText>
					</PCell>
					<PCell minHeight={0} head>
						<SmallText>Responsable</SmallText>
					</PCell>
					<PCell minHeight={0} width={80} head>
						<SmallText>Type</SmallText>
					</PCell>
					<PCell minHeight={0} head>
						<SmallText>Spécificités</SmallText>
					</PCell>
				</PRow>
				{sortedCarePoints.map((point: any) => {
					const currentCareFamilies = careFamilies.filter(
						(family: any) => family.geojson.properties.careId === point.id,
					)
					const currentSpecificity =
						displayGlobalSpecificities(currentCareFamilies)

					return (
						<PRow key={point.id}>
							<PCell>
								<SmallText>{point.geojson.properties["Lieu"]}</SmallText>
							</PCell>
							<PCell width={70}>
								<SmallText>{point.geojson.properties["Capacité"]}</SmallText>
							</PCell>
							<PCell
								style={{
									display: "flex",
									flexDirection: "column",
								}}
							>
								{point.geojson.properties["Responsable"]?.length > 0 &&
									ReportService.displayResps(
										humans,
										point.geojson.properties["Responsable"],
									)}
								{!(point.geojson.properties["Responsable"]?.length > 0) && (
									<SmallText>Aucun</SmallText>
								)}
							</PCell>
							<PCell
								width={80}
								style={{
									display: "flex",
									flexDirection: "column",
								}}
							>
								{currentCareFamilies.length > 0 &&
									displayGlobalTypes(currentCareFamilies)}
								{currentCareFamilies.length === 0 && (
									<SmallText>Aucun</SmallText>
								)}
							</PCell>
							<PCell
								style={{
									display: "flex",
									flexDirection: "column",
								}}
							>
								{currentCareFamilies.length > 0 &&
									Object.keys(currentSpecificity).map((specificity, index) => (
										<SmallText key={index}>
											{specificity} : {currentSpecificity[specificity]}
										</SmallText>
									))}
								{currentCareFamilies.length === 0 && (
									<SmallText>Aucune</SmallText>
								)}
							</PCell>
						</PRow>
					)
				})}
			</PTable>
		</PPage>
	)
}

export default DaybookCareTable
