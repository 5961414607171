import useCooperationFilters from "@/hooks/useCooperationFilters"
import { SearchMode } from "@/redux-toolkit/edit/cooperationFiltersRessources"
import React from "react"
import { isMobile } from "react-device-detect"
import styled from "styled-components"
import SwitchView from "./SwitchView"
import CoopSearchBar from "./CoopSearchBar"

const Container = styled.div`
    transition: 0.3s !important;
    display: flex;
    flex-direction: ${isMobile ? "column" : "row"} !important;
    align-items: stretch;
    transition: padding 0.4s;
    gap: ${isMobile ? "0.5rem" : "1rem"};
`

const CoopToolBar = () => {
	const coopFilters = useCooperationFilters()

	return (
		<Container>
			<CoopSearchBar />
			{coopFilters.search !== SearchMode.ORGANIGRAMMES && <SwitchView />}
		</Container>
	)
}

export default CoopToolBar
