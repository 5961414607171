const treeToFlat = (tree2) => {
	const tree = { ...tree2 }
	const flat = {}
	const recurse = (node, parent) => {
		if (node.children) {
			Object.keys(node.children).forEach((key) => {
				const child = node.children[key]
				child.parent = parent
				recurse(child, child)
			})
			if (flat[node.key] === undefined) {
				flat[node.key] = node
			}
		} else {
			flat[node.key] = node
		}
	}
	Object.keys(tree).forEach((key) => {
		const node = tree[key]
		node.parent = null
		recurse(node, node)
	})
	return flat
}

const calculateOrder = (data, targetKey, currentPath = []) => {
	// @ts-ignore
	for (const [index, [key, value]] of Object.entries(data).entries()) {
		// Check if the current key is the target key
		if (key === targetKey) {
			let order = currentPath.concat(index + 1) // +1 because indices are 0-based and you want them 1-based
			const first = order.shift()
			return parseFloat([first, order.join()].join("."))
		}

		// If this entry has children, recurse into them
		if (value.children && typeof value.children === "object") {
			const result = calculateOrder(
				value.children,
				targetKey,
				currentPath.concat(index + 1),
			)
			if (result) {
				return result
			}
		}
	}

	// If we reach here, we didn't find the targetKey
	return null
}
export default {
	treeToFlat,
	calculateOrder,
}
