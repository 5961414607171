import styled from "styled-components"

export const Padding16 = styled.div`
    padding: 2rem;
`
export const Padding8 = styled.div`
    padding: 1rem;
`
export const Padding4 = styled.div`
    padding: 0.5rem;
`
