import useAccessRights from "@/hooks/useAccessRights"
import useFiches from "@/hooks/useFiches"
import { useOrgaWithOrder } from "@/hooks/useOrga"
import { requestPage } from "@/redux-toolkit/edit/reducer"
import {
	PATH_FICHES_ACTIONS,
	PATH_FICHES_SCENARIOS,
} from "@/redux-toolkit/userSettings/constants"
import React, { useEffect, useRef, useState } from "react"
import { useDispatch } from "react-redux"
import { useParams } from "react-router-dom"
import styled from "styled-components"
import { BGCOLOR } from "@/utils/components/AppContainer"
import {
	PrimaryButton,
	PrimaryTextButton,
} from "@/utils/components/style/button"
import TitleHeader from "@/utils/components/TitleHeader"
import { TypeFiche } from "@/utils/types/IFiche"
import TitleDialog from "./components/TitleDialog"
import Fiche from "./Fiche"
import useAddCategory from "./utils/useAddCategory"
import useRenameFiche from "./utils/useRenameFiche"
import { EnumToolPath } from "@/hooks/services/useAccessRightsServices"
import { Chip } from "@mui/material"
import ModalFicheScenario from "./ModalFicheScenario"
import Button from "@/styles/atoms/Button"

export const Sticky = styled.div`
    position: sticky;
    top: 0;
    z-index: 49;
    margin-bottom: 14px;
    padding-bottom: 4px;

    :before {
        display: block;
        position: absolute;
        content: '';
        z-index: -1;
        background-color: ${BGCOLOR};
        top: -2rem;
        bottom: 0;
        left: -2rem;
        right: -2rem;
        box-shadow: 0 0 10px 10px ${BGCOLOR};
    }
`

function FichePage() {
	const params = useParams<{ id: string }>()
	const fiches = useFiches()
	const [opened, setOpened] = useState<Record<string, boolean>>({})
	const fiche = fiches[params.id]
	const { cellules } = useOrgaWithOrder()

	const permissions = useAccessRights(
		fiche.classification == TypeFiche.FICHE_ACTION
			? EnumToolPath.FICHES_ACTIONS
			: EnumToolPath.FICHES_SCENARIOS,
	)

	const [addCategoryOpen, setAddCategoryOpen] = useState(false)
	const [renameFicheOpen, setRenameFicheOpen] = useState(false)

	const renameFiche = useRenameFiche(fiche)
	const addCategory = useAddCategory(fiche)

	const categoryCount = useRef(fiche?.actionsByCategory.length)
	const elementRef = useRef<HTMLDivElement>(undefined)

	useEffect(() => {
		const newCategoryCount = fiche?.actionsByCategory.length
		if (categoryCount.current < newCategoryCount) {
			elementRef.current.parentElement.scrollTo({
				top: elementRef.current.parentElement.scrollHeight,
				behavior: "smooth",
			})
		}
		categoryCount.current = newCategoryCount
	}, [fiche])

	const handleSave = (title) => {
		setAddCategoryOpen(false)
		addCategory(title)
	}
	const dispatch = useDispatch()

	if (!permissions.read) return null

	return (
		<>
			<Sticky ref={elementRef}>
				<TitleHeader
					title={
						fiche.classification === TypeFiche.FICHE_ACTION
							? cellules[fiche.nom]?.idName
							: fiche.nom
					}
					subtitle={
						fiche.classification === TypeFiche.FICHE_SCENARIO && (
							<Chip
								label={
									fiche.risk ? "Fiche " + fiche.risk : "Pas de risque associé"
								}
								color="primary"
								size="small"
							/>
						)
					}
					backArrowTitle="Retour à la liste des fiches"
					backArrowAction={() => dispatch(requestPage("/customdata/fiche"))}
					handleRenameClick={
						permissions.write &&
						fiche.classification === TypeFiche.FICHE_SCENARIO
							? () => setRenameFicheOpen(true)
							: undefined
					}
					rightElements={
						<>
							{permissions.write && (
								<div>
									<Button
										variant="light"
										onClick={() => {
											setOpened(
												Object.fromEntries(
													fiche.actionsByCategory.map((category) => [
														category.id,
														true,
													]),
												),
											)
										}}
									>
										Tout ouvrir
									</Button>
									<Button
										variant="light"
										onClick={() => {
											setOpened({})
										}}
									>
										Tout fermer
									</Button>
									<Button
										color="primary"
										onClick={() => setAddCategoryOpen(true)}
									>
										Ajouter une catégorie
									</Button>
								</div>
							)}
						</>
					}
				/>
			</Sticky>
			<Fiche
				fiche={fiche}
				editable={permissions.write}
				opened={opened}
				setOpened={setOpened}
			/>
			<TitleDialog
				open={addCategoryOpen}
				onSave={handleSave}
				onClose={() => setAddCategoryOpen(false)}
				headerText={"Créer une catégorie"}
				saveLabel={"Créer"}
			/>
			<ModalFicheScenario
				isOpen={renameFicheOpen}
				toggleModal={() => setRenameFicheOpen(false)}
				initialValues={fiche}
			/>
		</>
	)
}

export default FichePage
