import React from "react"
import styled from "styled-components"
import DoneIcon from "@mui/icons-material/Done"
import ErrorIcon from "@mui/icons-material/Error"
import WarningIcon from "@mui/icons-material/Warning"
import { Link, CardContent } from "@mui/material"
import getImage from "@/utils/getImage"
import { useHistory } from "react-router-dom"
import { STOCK } from "utils/services/StaticFormsService"
import { GREEN_STATUS, ORANGE_STATUS, RED_STATUS } from "./NumeriskIndicators"
import CustomTooltip from "utils/components/style/CustomTooltip"
import useJsonSchemasWithStatics from "@/hooks/useJsonSchemasWithStatics"

const StyledDoneIcon = styled(DoneIcon)`
    color: var(--green) !important;
`
const StyledErrorIcon = styled(ErrorIcon)`
    color: var(--red) !important;
`
const StyledWarningIcon = styled(WarningIcon)`
    color: var(--orange) !important;
`
const SpaceItems = styled(CardContent)`
    display: flex;
    justify-content: space-between;
`
const StyledContent = styled.div`
    display: flex;
`
const CenterIcon = styled.div`
    display: flex;
    align-items: center;
    padding-right: 8px;
`
const DataStatusIcon = styled(CenterIcon)`
    padding: 0;
`
const StyledLink = styled(Link)`
    display: contents !important;
    padding-left: 8px !important;
` as any

const CommuneDataStatus = ({
	status,
	jsonSchemaId,
	dataPath,
	dataTitle,
	isStocks,
}) => {
	const history = useHistory()
	const jsonSchemaWithStatics = useJsonSchemasWithStatics()

	if (status === GREEN_STATUS) {
		return null
	}

	return (
		<>
			<SpaceItems>
				<StyledContent>
					<CenterIcon>
						<img
							src={getImage(jsonSchemaWithStatics[jsonSchemaId]?.imgId)}
							width="35"
						/>
					</CenterIcon>
					<StyledLink
						component="button"
						onClick={() => {
							history.push(dataPath)
						}}
					>
						{dataTitle}
					</StyledLink>
				</StyledContent>
				<DataStatusIcon>
					{status === GREEN_STATUS && (
						<CustomTooltip title="À jour">
							<StyledDoneIcon />
						</CustomTooltip>
					)}
					{status === ORANGE_STATUS && (
						<CustomTooltip title="À vérifier">
							<StyledWarningIcon />
						</CustomTooltip>
					)}
					{status === RED_STATUS && (
						<CustomTooltip title="Mettre à jour">
							<StyledErrorIcon />
						</CustomTooltip>
					)}
				</DataStatusIcon>
			</SpaceItems>
		</>
	)
}

export default CommuneDataStatus
