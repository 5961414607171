import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import useJsonSchemasWithStatics from "@/hooks/useJsonSchemasWithStatics"
import useNotValidatedPoints from "@/hooks/useNotValidatedPoints"
import { deletePoint, updatePoint } from "@/redux-toolkit/data/points/resources"
import React, { useMemo } from "react"
import { useDispatch } from "react-redux"
import SuperReactTable from "@/utils/components/tables/SuperReactTable"
import { width60 } from "@/utils/components/tables/widthProps"
import useJsonSchemaColumns from "../useJsonSchemaColumns"
import ValidateButton from "./ValidateButton"
import { ColumnDef } from "@tanstack/react-table"
import IButtonMultiOption from "@/utils/components/button/ButtonMulti/IButtonMultiOption"

const MyFormsNotValidatedPanel = ({ jsonSchemaId, onEditClick }) => {
	const jsonSchemas = useJsonSchemasWithStatics()
	const jsonSchema = jsonSchemas[jsonSchemaId]
	const writePermission = jsonSchemas[jsonSchemaId]?.write
	const unvalidatedPoints = useNotValidatedPoints()
	const dispatch = useDispatch()

	const onValidateClick = (point) => {
		dispatch(
			updatePoint({
				id: point.id,
				jsonschema_id: point.jsonschema_id,
				geojson: point.geojson,
				is_validated: true,
			}),
		)
	}

	const validateColumn = [
		{
			header: "",
			accessorKey: "validate",
			...width60,
			enableSorting: false,
			meta: {
				isAction: true,
			},
			cell: (props) => (
				<ValidateButton
					onClick={() => {
						onValidateClick(props.row.original)
					}}
				/>
			),
		},
	] as ColumnDef<any>[]

	const validateAllOption = [
		{
			renderCondition: true,
			icon: <CheckCircleIcon />,
			requireValidation: true,
			confirmMessage: "Voulez vous vraiment valider toute la sélection ?",
			label: "Valider toute la séléction",
			description: "Valide toute la sélection de données",
			onClick: (selectedFlatRows) => {
				debugger
				Object.keys(selectedFlatRows)
					.map((id) => unvalidatedDataItems.find((point) => point.id === id))
					.forEach((original) => {
						dispatch(
							updatePoint({
								id: original.id,
								jsonschema_id: original.jsonschema_id,
								geojson: original.geojson,
								is_validated: true,
							}),
						)
					})
			},
			color: "success",
			key: "validateAll",
		},
	] as Array<IButtonMultiOption>

	const columns = useMemo(
		() => [...useJsonSchemaColumns(jsonSchema), ...validateColumn],
		[jsonSchema],
	)

	const unvalidatedDataItems = useMemo(() => {
		return Object.values(unvalidatedPoints).filter(
			(point) => point.jsonschema_id === jsonSchemaId,
		)
	}, [unvalidatedPoints, jsonSchema])

	return (
		<>
			<SuperReactTable
				columns={columns}
				data={unvalidatedDataItems}
				onEditClick={onEditClick}
				onDeleteClick={(original) => {
					dispatch(deletePoint(original))
				}}
				additionalMultiOptions={validateAllOption}
			/>
		</>
	)
}

export default MyFormsNotValidatedPanel
