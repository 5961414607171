import { useSelector } from "react-redux"
import SynchroService from "@/services/SynchroService"
import createDeepEqualSelector from "@/utils/createDeepEqualSelector"
import { STOCK } from "utils/services/StaticFormsService"
import IPoint from "utils/types/IPoint"

const selectPoints = createDeepEqualSelector(
	(state: any) => state.data.points,
	(state: any) => state.userSettings.commune_id,
	(points, commune_id) =>
		SynchroService.notDeletedWithCurrentCommune(points, commune_id),
)

const useStocks: () => { [key: string]: IPoint } = () => {
	const points = useSelector(selectPoints) as IPoint[]
	const stockPlaces = {}
	Object.values(points)
		.filter((point) => point["jsonschema_id"] === STOCK.id)
		.forEach((point) => {
			stockPlaces[point.id] = point
		})
	return stockPlaces
}

export default useStocks
