import React from "react"
import { Document, View } from "@react-pdf/renderer"
import { Font } from "@react-pdf/renderer"
import BoldText from "utils/pdf/BoldText"
import HeaderBanner from "utils/pdf/header/HeaderBanner"
import PPage from "utils/pdf/PPage"
import NormalText from "utils/pdf/NormalText"
import _ from "lodash"
import ReportService from "@/pages/carto2/cartographie/service/ReportService"
import { Html } from "react-pdf-html"
import { stylesheet } from "@/pages/telechargement/reports/pdf/components/DaybookEventsList"
import PH2 from "utils/pdf/header/PH2"

Font.register({
	family: "Roboto",
	src: "https://fonts.gstatic.com/s/roboto/v20/KFOmCnqEu92Fr1Me5Q.ttf",
})
Font.registerEmojiSource({
	format: "png",
	url: "https://twemoji.maxcdn.com/2/72x72/",
})

interface IIntervention {
	id: string
	commune_id: string
	event_id: string
	jsonschema_id: string
	geojson: {
		properties: {
			Adresse: {
				coo: {
					lat: number
					lng: number
				}
				secteur: string
			}
			Cellules: string[]
			"Date de début": string
			"Date de fin": string
			Détails: string
			"En lien avec": string
			Membres: string[]
			Objet: string
			Réponses: string[]
			Statut: "A faire" | "En cours" | "Terminé"
		}
	}
	couche: any
	is_validated: boolean
	deleted: boolean
	created_at: string
	updated_at: string
}

const ActionsPDF = ({
	actions,
	eventsPoints,
	objectOfWisiwygBlobsArray,
	humans,
	orgas,
	secteurs,
}) => {
	const displayEvent = (actionId: string) => {
		const incident = _.find(eventsPoints, (point) => point.id === actionId)
		if (!incident)
			return <BoldText>L'intervention n'est lié à aucun évènement</BoldText>

		const secteurName =
			_.find(
				secteurs,
				(secteur) =>
					secteur.id ===
					incident.geojson.properties["Géolocalisation"]?.secteur,
			)?.name ??
			incident.geojson.properties["Géolocalisation"]?.secteur ??
			"Évènement non géolocalisé"

		return (
			<View
				style={{
					padding: "3px 7px",
					margin: "4px",
					border: "1px solid #bfbfbf",
					borderRadius: "8px",
					backgroundColor: "#f5f5f5",
				}}
			>
				<View
					style={{
						display: "flex",
						flexDirection: "row",
						alignItems: "center",
						flexWrap: "wrap",
					}}
				>
					<BoldText>Lié à l'évènement : </BoldText>
					<NormalText>{incident.geojson.properties["Objet"]}</NormalText>
				</View>
				<View
					style={{
						margin: "3px 0",
						borderBottom: "0.5px solid #bfbfbf",
					}}
				/>
				<View
					style={{
						display: "flex",
						flexDirection: "column",
						gap: "8px",
					}}
				>
					<View
						style={{
							width: "100%",
							display: "flex",
							flexDirection: "row",
						}}
					>
						<NormalText
							style={{
								width: "150px",
							}}
						>
							<BoldText>Type : </BoldText>
							{incident.geojson.properties["Type"]}
						</NormalText>
						<NormalText
							style={{
								width: "150px",
							}}
						>
							<BoldText>Priorité : </BoldText>
							{incident.geojson.properties["Priorité"]}
						</NormalText>
					</View>
					<View
						style={{
							display: "flex",
							flexDirection: "row",
							alignItems: "center",
							flexWrap: "wrap",
						}}
					>
						<BoldText>Secteur : </BoldText>
						<NormalText>{secteurName}</NormalText>
					</View>
					<View
						style={{
							display: "flex",
							flexDirection: "row",
							alignItems: "center",
							flexWrap: "wrap",
						}}
					>
						<BoldText>Détails : </BoldText>
						<NormalText>
							<Html
								stylesheet={stylesheet}
								style={{
									fontSize: 11,
									alignItems: "flex-start",
								}}
							>
								{ReportService.displayDetails(
									incident.geojson.properties["Détails"],
								)}
							</Html>
						</NormalText>
					</View>
					<View
						style={{
							display: "flex",
							flexDirection: "row",
							alignItems: "center",
							flexWrap: "wrap",
						}}
					>
						<BoldText>Responsable(s) : </BoldText>
						{incident.geojson.properties["Responsable"]?.length > 0 &&
							ReportService.displayResps(
								humans,
								incident.geojson.properties["Responsable"],
								undefined,
								true,
							)}
						{!(incident.geojson.properties["Responsable"]?.length > 0) && (
							<NormalText>Aucun</NormalText>
						)}
					</View>
				</View>
			</View>
		)
	}

	return (
		<Document>
			{actions.map((action: IIntervention) => {
				const properties = action.geojson.properties
				const secteurName =
					_.find(
						secteurs,
						(secteur) => secteur.id === properties["Adresse"]?.secteur,
					)?.name ??
					properties["Adresse"]?.secteur ??
					"Intervention non géolocalisée"
				return (
					<PPage>
						<View>
							<HeaderBanner>
								<PH2 marginBottom="0px">Fiche Intervention</PH2>
							</HeaderBanner>
							<View
								style={{
									borderBottom: "1px solid #bfbfbf",
								}}
							/>
						</View>
						<View
							key={action.id}
							style={{
								display: "flex",
								flexDirection: "column",
								gap: "8px",
							}}
						>
							<View
								style={{
									width: "100%",
									display: "flex",
									alignItems: "center",
									flexDirection: "row",
									justifyContent: "space-between",
									backgroundColor: "#ff5d5d66",
									marginTop: "8px",
									padding: "5px",
								}}
							>
								<BoldText
									style={{
										width: "400px",
										fontSize: "12px",
									}}
								>
									<BoldText
										style={{
											fontSize: "12px",
										}}
									>
										{properties["Objet"]}{" "}
									</BoldText>
								</BoldText>
								<BoldText
									style={{
										fontSize: "12px",
									}}
								>
									{ReportService.displayDatetime(properties["Date de début"])}
								</BoldText>
							</View>
							<View
								style={{
									width: "100%",
									display: "flex",
									flexDirection: "row",
								}}
							>
								<NormalText
									style={{
										width: "120px",
									}}
								>
									<BoldText>Statut : </BoldText>
									{properties["Statut"]}
								</NormalText>
								{properties["Statut"] === "Terminé" && (
									<NormalText>
										<BoldText>Date de fin : </BoldText>
										{ReportService.displayDatetime(properties["Date de fin"])}
									</NormalText>
								)}
							</View>
							<View>
								<BoldText>Détails : </BoldText>
								<NormalText>
									<Html
										stylesheet={stylesheet}
										style={{
											fontSize: 11,
											alignItems: "flex-start",
										}}
									>
										{ReportService.displayDetails(properties["Détails"])}
									</Html>
								</NormalText>
							</View>
							<View
								style={{
									display: "flex",
									flexDirection: "row",
									alignItems: "center",
									flexWrap: "wrap",
								}}
							>
								<BoldText>Secteur : </BoldText>
								<NormalText>{secteurName}</NormalText>
							</View>
							<View
								style={{
									display: "flex",
									flexDirection: properties["Réponses"] ? "column" : "row",
									margin: "2px 0",
								}}
							>
								{!_.isEmpty(properties["Réponses"]) && (
									<>
										<BoldText>
											Point(s) de situation(s) : {properties["Réponses"].length}
										</BoldText>
										{ReportService.displayAnswers(
											properties["Réponses"],
											objectOfWisiwygBlobsArray[action.id]?.reponsesQrCodeBlobs,
										)}
									</>
								)}
								{_.isEmpty(properties["Réponses"]) && (
									<BoldText>
										Aucun point de situation pour cette intervention.
									</BoldText>
								)}
							</View>
							<View
								style={{
									display: "flex",
									flexDirection: "row",
									alignItems: "center",
									flexWrap: "wrap",
								}}
							>
								<BoldText>Cellule(s) : </BoldText>
								{properties["Cellules"]?.length > 0 &&
									ReportService.displayCells(orgas, properties["Cellules"])}
								{!(properties["Cellules"]?.length > 0) && (
									<NormalText>Aucune</NormalText>
								)}
							</View>
							<View
								style={{
									display: "flex",
									flexDirection: "row",
									alignItems: "center",
									flexWrap: "wrap",
								}}
							>
								<BoldText>Membre(s) : </BoldText>
								{properties["Membres"]?.length > 0 &&
									ReportService.displayResps(
										humans,
										properties["Membres"],
										undefined,
										true,
									)}
								{!(properties["Membres"]?.length > 0) && (
									<NormalText>Aucun</NormalText>
								)}
							</View>
							{displayEvent(properties["En lien avec"])}
						</View>
					</PPage>
				)
			})}
		</Document>
	)
}
export default ActionsPDF
