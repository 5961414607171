import { IconButton } from "@mui/material"
import styled, { css } from "styled-components"
import { Flex } from "utils/components/style/flex"
import { MapContainer } from "react-leaflet"
export const Response = styled.div<{
	$sender?: boolean
	$limitedWidth?: boolean
}>`
    background-color: var(--neutral50);
    padding 0.5rem 1rem;
    border-radius: 0.5rem;
    width: fit-content !important;
    max-width: 100%;
    ${({ $limitedWidth }) =>
			$limitedWidth &&
			css`
            max-width: 70%;
        `}
        
    align-self: flex-start;
    ${({ $sender }) =>
			$sender &&
			css`
            background-color: var(--blue500);
            color: white;
            align-self: flex-end;
        `}
`
export const Hour = styled.div`
    font-size: 0.625rem !important;
`
export const ResponseText = styled.div``

export const ChatContainer = styled.div`
    display flex;
    flex-direction: column;
    gap: 1rem;
    align-self: flex-end;
`
export const WhiteBackground = styled.div`
    background-color: white;
    width: inherit;
`
export const SIconButton = styled(IconButton)`
    background-color: var(--primary500) !important;
    color: white !important;
`
export const SFlex = styled(Flex)`
    align-self: flex-end;
`
export const Grid = styled.div`
    display: grid;
    grid-template-columns: 600px 1fr;
    grid-gap: 1rem;
    height: calc(100vh - 11rem);
    overflow: auto;
`
export const SMapContainer = styled(MapContainer)`
    width: 100%;
    height: 300px;
`
export const LeftContent = styled.div`
    display: flex;
    flex-direction: column;
    background-color: white;
    padding: 1rem;
    box-shadow: var(--cool-shadow);
`
export const Container = styled.div`
    padding: 1rem;
`
