import React, { useReducer, useState } from "react"
import { treeReducer, initialTreeState } from "./redux/tree/reducer"
import { pageReducer, initialPageState } from "./redux/page/reducer"
import { TreeContext } from "./redux/page/context"
import { PageContext } from "./redux/tree/context"
import { SelectionContext } from "./redux/selection/context"
import { KitContext } from "./redux/kit/context"
import {
	initialSelectionState,
	selectionReducer,
} from "./redux/selection/reducer"
import useWhyDidYouUpdate from "@/hooks/useWhyDidYouUpdate"
import PcsConfigContext from "./DownloadPCS/context/PcsConfigContext"

const Contexts = (props) => {
	/** reducers */
	const [treeState, treeDispatch] = useReducer(treeReducer, initialTreeState)
	const [pageState, pageDispatch] = useReducer(pageReducer, initialPageState)
	const [selectionState, selectionDispatch] = useReducer(
		selectionReducer,
		initialSelectionState,
	)
	const [pcsConfig, setPcsConfig] = useState({
		anonymize: false,
		generate_livret: false,
	})
	const [kitNameState, setKitNameState] = useState("")

	return (
		<KitContext.Provider
			value={{ state: kitNameState, dispatch: setKitNameState }}
		>
			<TreeContext.Provider
				value={{ state: treeState, dispatch: treeDispatch }}
			>
				<PageContext.Provider
					value={{ state: pageState, dispatch: pageDispatch }}
				>
					<SelectionContext.Provider
						value={{
							state: selectionState,
							dispatch: selectionDispatch,
						}}
					>
						<PcsConfigContext.Provider
							value={{
								config: pcsConfig,
								setConfig: setPcsConfig,
							}}
						>
							{props.children}
						</PcsConfigContext.Provider>
					</SelectionContext.Provider>
				</PageContext.Provider>
			</TreeContext.Provider>
		</KitContext.Provider>
	)
}
export default Contexts
