import { yupResolver } from "@hookform/resolvers/yup"
import { Alert, Stepper } from "@mui/material"
import { uploadFile } from "@/redux-toolkit/data/uploadedFile/resources"
import React, { useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import {
	PrimaryButton,
	PrimaryLoadingButton,
} from "@/utils/components/style/button"
import { Flex } from "@/utils/components/style/flex"
import Yup from "@/utils/yup"
import StepFormProvider from "./component/StepFormProvider"
import StepLocation from "./steps/initialUpload/StepLocation"
import StepName from "./steps/initialUpload/StepName"
import StepCategory from "./steps/initialUpload/StepCategory"
import StepUpload from "./steps/initialUpload/StepUpload"
import { useDispatch } from "react-redux"
import fileinfo from "@/utils/fileinfo"
import { toast } from "react-toastify"
import IsolatedTreeProvider from "./component/IsolatedTreeProvider"
import Publish from "@mui/icons-material/Publish"
import ModalHeader from "@/styles/organisms/Modal/ModalHeader"
import ModalFooter from "@/styles/organisms/Modal/ModalFooter"
import Modal from "@/styles/organisms/Modal"
import ModalBody from "@/styles/organisms/Modal/ModalBody"
import { ModalContent } from "@nextui-org/react"
import CancelButton from "@/styles/atoms/Button/specialized/CancelButton"

const schema = Yup.object().shape({
	category: Yup.string().required().default(null),
	file: Yup.mixed().required().default(null),
	name: Yup.string().required().default(null),
	location: Yup.object().nullable().default(null),
})

const ModalUpload = ({ isOpen, onClose }) => {
	const [step, setStep] = useState(0)
	const dispatch = useDispatch()
	const [isLoading, setIsLoading] = useState(false)

	const methods = useForm({
		resolver: yupResolver(schema),
	})

	useEffect(() => {
		if (isOpen) {
			methods.reset(schema.getDefault())
			setStep(0)
		}
	}, [isOpen])

	const steps = ["category", "file", "name", "location"]

	const onSubmit = async (values) => {
		setIsLoading(true)
		const extension = fileinfo(values.file.name).ext
		const fileToUpload = new File([values.file], `${values.name}${extension}`)

		const result = (await dispatch(
			uploadFile({
				file: fileToUpload,
				config: {
					category: values.category,
					...(values.location && {
						pcs: values.location,
					}),
				},
			}),
		)) as any
		setIsLoading(false)
		onClose(result.payload)
	}

	return (
		<StepFormProvider
			methods={methods}
			setActiveStep={setStep}
			activeStep={step}
			steps={steps}
		>
			<Modal isOpen={isOpen} onClose={() => onClose()} $baseWidth={1000}>
				<ModalContent>
					<ModalHeader>Téléverser un fichier</ModalHeader>
					<ModalBody>
						<Flex gap directionColumn alignItemsStretch>
							<IsolatedTreeProvider>
								<Stepper activeStep={step} orientation="vertical">
									<StepCategory />
									<StepUpload />
									<StepName />
									<StepLocation />
								</Stepper>
							</IsolatedTreeProvider>
							{step === steps.length && (
								<Alert>
									Le télévesement est prêt, cliquez sur &quot;Terminer&quot;
									pour l&apos;effectuer
								</Alert>
							)}
						</Flex>
					</ModalBody>
					<ModalFooter>
						<CancelButton onClick={() => onClose()} />
						{step === steps.length && (
							<PrimaryLoadingButton
								variant="contained"
								onClick={methods.handleSubmit(onSubmit, console.error)}
								loading={isLoading}
								loadingPosition="end"
								endIcon={<Publish />}
								disabled={isLoading}
							>
								{isLoading ? "Téléversement en cours" : "Téléverser"}
							</PrimaryLoadingButton>
						)}
					</ModalFooter>
				</ModalContent>
			</Modal>
		</StepFormProvider>
	)
}

export default ModalUpload
