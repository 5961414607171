import { View as ViewReactPdf } from "@react-pdf/renderer"
import React from "react"
import BoldText from "utils/pdf/BoldText"
import HeaderBanner from "utils/pdf/header/HeaderBanner"
import PPage from "utils/pdf/PPage"
import NormalText from "utils/pdf/NormalText"
import DaybookActions from "./DaybookActions"
import PTitle from "utils/pdf/PTitle"
import _ from "lodash"
import PointLinkedFileImage from "./PointLinkedFileImage"
import PointLinkedFilePdf from "./PointLinkedFilePdf"
import ReportService from "@/pages/carto2/cartographie/service/ReportService"
import { Html } from "react-pdf-html"
import PImage from "utils/pdf/image/PImage"
import PH2 from "utils/pdf/header/PH2"

const View = ViewReactPdf as any
export const eventsBannerColor = "#ceedff"
export const actionsBannerColor = "#ff5d5d66"

export const stylesheet = {
	strong: {
		fontFamily: "Helvetica-Bold",
	},
	em: {
		fontFamily: "Helvetica-Oblique",
	},
	p: {
		margin: 0,
	},
}

const DaybookEventsList = ({
	selectedEvent,
	points,
	pointsWithBlobs,
	orgas,
	humans,
	indexInSummary,
	childIndexInSummary,
	objectOfWisiwygBlobsArray,
}) => {
	const sortedPoints = _.sortBy(
		pointsWithBlobs,
		(point: any) => point.geojson.properties["Date de début"],
	)
	const sortedEventsPoints = sortedPoints.filter(
		(point) => point.jsonschema_id === "INCIDENT",
	)
	const sortedActionsPoints = sortedPoints.filter(
		(point) => point.jsonschema_id === "INTERVENTION",
	)
	const unlinkedActionsPoints = sortedActionsPoints.filter(
		(point: any) => !point.geojson.properties["En lien avec"],
	)

	if (_.isEmpty(Object.values(pointsWithBlobs))) return null

	return (
		<PPage>
			<View>
				<HeaderBanner>
					<PH2 marginBottom="0px">{indexInSummary}. Main courante</PH2>
				</HeaderBanner>
				<PTitle color="#2e74b5" marginBottom={10}>
					{indexInSummary}.{childIndexInSummary}. Événements et Interventions
				</PTitle>
				<View
					style={{
						borderBottom: "1px solid #cecece",
					}}
				/>
			</View>
			{sortedEventsPoints.map((point: any) => {
				const currentLinkedActions = sortedActionsPoints.filter(
					(action: any) =>
						action.geojson.properties["En lien avec"] === point.id,
				)
				const eventLinkedFiles = point?.srcBlob ?? []
				const eventLinkedFilesPdf = eventLinkedFiles.filter(
					(file) => file?.type !== "image",
				)
				const eventLinkedFilesImg = eventLinkedFiles.filter(
					(file) => file.type === "image",
				)
				const pointImageArray =
					point.geojson.properties["Pièces jointes"]?.filter?.(
						(object) => object.extension !== ".pdf",
					) ?? []
				const pointPdfArray =
					point.geojson.properties["Pièces jointes"]?.filter?.(
						(object) => object.extension === ".pdf",
					) ?? []

				return (
					<>
						<View
							key={point.id}
							style={{
								display: "flex",
								flexDirection: "column",
								paddingBottom: "8px",
							}}
						>
							<View
								style={{
									width: "100%",
									display: "flex",
									alignItems: "center",
									flexDirection: "row",
									justifyContent: "space-between",
									backgroundColor: eventsBannerColor,
									padding: "5px",
									marginBottom: "5px",
								}}
							>
								<BoldText
									style={{
										width: "400px",
										fontSize: "12px",
									}}
								>
									<BoldText
										style={{
											fontSize: "12px",
										}}
									>
										{point.geojson.properties["Objet"]}{" "}
									</BoldText>
								</BoldText>
								<BoldText
									style={{
										fontSize: "12px",
									}}
								>
									{ReportService.displayDatetime(
										point.geojson.properties["Date de début"],
									)}
								</BoldText>
							</View>
							<View
								style={{
									display: "flex",
									flexDirection: "row",
								}}
							>
								<NormalText
									style={{
										width: "120px",
									}}
								>
									<BoldText>Prioritée : </BoldText>
									{point.geojson.properties["Priorité"]}
								</NormalText>
								<NormalText
									style={{
										width: "120px",
									}}
								>
									<BoldText>Statut : </BoldText>
									{point.geojson.properties["Statut"]}
								</NormalText>
								<NormalText
									style={{
										width: "120px",
									}}
								>
									<BoldText>Type : </BoldText>
									{point.geojson.properties["Type"]}
								</NormalText>
								{point.geojson.properties["Statut"] === "Terminé" && (
									<NormalText>
										<BoldText>Date de fin : </BoldText>
										{ReportService.displayDatetime(
											point.geojson.properties["Date de fin"],
										)}
									</NormalText>
								)}
							</View>
							<View>
								<BoldText>Détails : </BoldText>
								<NormalText>
									<Html
										stylesheet={stylesheet}
										style={{
											fontSize: 11,
										}}
									>
										{ReportService.displayDetails(
											point.geojson.properties["Détails"],
										)}
									</Html>
								</NormalText>
								{objectOfWisiwygBlobsArray[point.id]?.detailsQrCodeBlobs && (
									<View
										style={{
											display: "flex",
											flexDirection: "row",
										}}
									>
										{objectOfWisiwygBlobsArray[
											point.id
										]?.detailsQrCodeBlobs.map((blobUrl, index) => (
											<View
												key={index}
												style={{
													display: "flex",
													alignItems: "center",
												}}
											>
												<PImage
													src={blobUrl}
													alt="[QR-Code]"
													width="76px"
													height="76px"
												/>
												<BoldText>
													Lien{" "}
													{_.indexOf(
														objectOfWisiwygBlobsArray[point.id]
															.detailsQrCodeBlobs,
														blobUrl,
													) + 1}
												</BoldText>
											</View>
										))}
									</View>
								)}
							</View>
							<View
								style={{
									display: "flex",
									flexDirection: point.geojson.properties["Réponses"]
										? "column"
										: "row",
									margin: "2px 0",
								}}
							>
								{!_.isEmpty(point.geojson.properties["Réponses"]) && (
									<>
										<BoldText>
											Point(s) de situation(s) :{" "}
											{point.geojson.properties["Réponses"].length}
										</BoldText>
										{ReportService.displayAnswers(
											point.geojson.properties["Réponses"],
											objectOfWisiwygBlobsArray[point.id]?.reponsesQrCodeBlobs,
										)}
									</>
								)}
								{_.isEmpty(point.geojson.properties["Réponses"]) && (
									<BoldText>
										Aucun point de situation pour cet événement.
									</BoldText>
								)}
							</View>
							<View
								style={{
									display: "flex",
									flexDirection: "row",
									alignItems: "center",
									flexWrap: "wrap",
									margin: "2px 0",
								}}
							>
								<BoldText>Cellule(s) : </BoldText>
								{point.geojson.properties["Cellules"]?.length > 0 &&
									ReportService.displayCells(
										orgas,
										point.geojson.properties["Cellules"],
									)}
								{!(point.geojson.properties["Cellules"]?.length > 0) && (
									<NormalText>Aucune</NormalText>
								)}
							</View>
							<View
								style={{
									display: "flex",
									flexDirection: "row",
									alignItems: "center",
									flexWrap: "wrap",
									margin: "2px 0",
								}}
							>
								<BoldText>Responsable(s) : </BoldText>
								{point.geojson.properties["Responsable"]?.length > 0 &&
									ReportService.displayResps(
										humans,
										point.geojson.properties["Responsable"],
										undefined,
										true,
									)}
								{!(point.geojson.properties["Responsable"]?.length > 0) && (
									<NormalText>Aucun</NormalText>
								)}
							</View>
							{currentLinkedActions.length > 0 && (
								<View>
									<BoldText>Intervention(s) liée(s)</BoldText>
									<DaybookActions
										actionsPoints={currentLinkedActions}
										orgas={orgas}
										objectOfWisiwygBlobsArray={objectOfWisiwygBlobsArray}
									/>
								</View>
							)}
							{currentLinkedActions.length === 0 && (
								<View>
									<BoldText>Aucune intervention liée</BoldText>
								</View>
							)}
							{eventLinkedFilesImg.length >= 1 && (
								<View>
									<BoldText
										style={{
											margin: "4px 0",
										}}
									>
										Pièce(s) jointe(s) :{" "}
									</BoldText>
									<PointLinkedFileImage
										pointImageArray={pointImageArray}
										fileBlobs={eventLinkedFilesImg}
									/>
								</View>
							)}
						</View>
						{eventLinkedFilesPdf.length >= 1 && (
							<PointLinkedFilePdf
								pointPdfArray={pointPdfArray}
								fileBlobs={eventLinkedFilesPdf}
							/>
						)}
					</>
				)
			})}
			{unlinkedActionsPoints.length > 0 && (
				<View>
					<View
						style={{
							width: "100%",
							display: "flex",
							alignItems: "center",
							flexDirection: "row",
							justifyContent: "space-between",
							backgroundColor: actionsBannerColor,
							padding: "5px",
							margin: "5px 0",
						}}
					>
						<BoldText
							style={{
								width: "400px",
								fontSize: "13px",
							}}
						>
							Interventions (non liée(s) à un événement)
						</BoldText>
					</View>
					<DaybookActions
						actionsPoints={unlinkedActionsPoints}
						orgas={orgas}
						objectOfWisiwygBlobsArray={objectOfWisiwygBlobsArray}
					/>
				</View>
			)}
		</PPage>
	)
}

export default DaybookEventsList
