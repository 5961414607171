import { yupResolver } from "@hookform/resolvers/yup"
import {
	Alert,
	Paper,
	Radio,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
} from "@mui/material"
import _ from "lodash"
import React, { useMemo } from "react"
import {
	FormProvider,
	useController,
	useForm,
	useFormContext,
} from "react-hook-form"
import styled from "styled-components"
import colored from "utils/colored"
import PointPropertyValue from "utils/components/jsonSchema/properties/PointPropertyValue"
import { getPointTitle } from "utils/components/jsonSchema/useGetPointSummary"
import { Flex } from "utils/components/style/flex"
import useParentSubmit from "utils/form/specific/fiche/links/utils/parentSubmit/useParentSubmit"
import { IJsonSchemaProperty } from "@/utils/types/IJsonSchema"
import Yup from "utils/yup"
import { IImportDiffItem } from "../utils/buildImportDiff"
import { Title } from "./ErrorsSolverItem"

const CTableCell = colored(TableCell)

const ClickableTableCell = styled(TableCell)`
    cursor: pointer;
`

const FCustom = ({ name, oldValue, newValue, property }) => {
	const { control } = useFormContext()

	const {
		field: { value, onChange },
		fieldState: { error },
	} = useController({
		name,
		control,
	})

	return (
		<TableRow>
			<CTableCell $textColor={error ? "error" : undefined}>{name}</CTableCell>
			<ClickableTableCell onClick={() => onChange(true)}>
				<Flex>
					<Radio checked={value === true} />
					<PointPropertyValue property={property} value={oldValue} />
				</Flex>
			</ClickableTableCell>
			<ClickableTableCell onClick={() => onChange(false)}>
				<Flex>
					<Radio checked={value === false} />
					<PointPropertyValue property={property} value={newValue} />
				</Flex>
			</ClickableTableCell>
		</TableRow>
	)
}

const ConflictsSolverItem = ({
	erroredItem,
	jsonSchemaProperties,
}: {
	erroredItem: IImportDiffItem
	jsonSchemaProperties: IJsonSchemaProperty[]
}) => {
	const concernedFields = useMemo(() => {
		return jsonSchemaProperties
			.filter(
				(prop) =>
					!_.isEqual(erroredItem.new[prop.name], erroredItem.old[prop.name]),
			)
			.map((prop) => prop.name)
	}, [erroredItem, jsonSchemaProperties])

	const schema = useMemo(
		() =>
			Yup.object(
				Object.fromEntries(
					concernedFields.map((field) => [field, Yup.boolean().required()]),
				),
			),
		[concernedFields],
	)

	const methods = useForm({
		resolver: yupResolver(schema),
	})
	useParentSubmit(methods.handleSubmit)

	const title = getPointTitle(jsonSchemaProperties, erroredItem.new)

	return (
		<FormProvider {...methods}>
			<Flex alignItemsStretch directionColumn gap>
				<Title>
					Ligne {erroredItem.from + 1} : {title}
				</Title>
				<Alert severity="info">
					Cette donnée a été modifiée depuis le dernier import. Vous pouvez
					choisir de conserver la donnée manuellement modifiée ou
					d&apos;importer la nouvelle donnée.
				</Alert>
				<TableContainer component={Paper}>
					<Table>
						<TableHead>
							<TableRow>
								<TableCell size="small"></TableCell>
								<TableCell size="small">Donnée modifiée</TableCell>
								<TableCell size="small">Donnée importée</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{concernedFields.map((field) => {
								const property = jsonSchemaProperties.find(
									(prop) => prop.name === field,
								)

								return (
									<FCustom
										key={field}
										name={field}
										property={property}
										oldValue={erroredItem.old[field]}
										newValue={erroredItem.new[field]}
									/>
								)
							})}
						</TableBody>
					</Table>
				</TableContainer>
			</Flex>
		</FormProvider>
	)
}

export default ConflictsSolverItem
