import { Text, View } from "@react-pdf/renderer"
import moment from "moment"
import React from "react"
import HeaderBanner from "utils/pdf/header/HeaderBanner"
import PH2 from "utils/pdf/header/PH2"
import PImage from "utils/pdf/image/PImage"
import LargeText from "utils/pdf/LargeText"
import PPage from "utils/pdf/PPage"
import PTitle from "utils/pdf/PTitle"

const DaybookCoverPage = ({
	selectedEvent,
	commune,
	communeLogoSrc,
	summaryCategories,
}) => {
	const getDate = (date) => {
		const dateDay = date?.slice(8, 10)
		const dateMonth = date?.slice(5, 7)
		const dateYear = date?.slice(0, 4)
		if (!dateDay || !dateMonth || !dateYear) return ""
		return `${dateDay}/${dateMonth}/${dateYear}`
	}

	const displayCategoryWithChildrens = (categoryObject, categoryIndex) => (
		<>
			<LargeText marginBottom={5}>
				{categoryIndex + 1}. {categoryObject.title}
			</LargeText>
			<View style={{ marginLeft: "16px" }}>
				{categoryObject.childrens.map((children: string, childrenIndex) => (
					<LargeText key={childrenIndex + 1} marginBottom={5}>
						{childrenIndex + 1}. {children}
					</LargeText>
				))}
			</View>
		</>
	)

	return (
		<>
			{/* =-=-=-=-= Page de couverture =-=-=-=-= */}
			<PPage>
				<PImage src="/img/daybookReportCover.webp" alt="Image de couverture" />
				<View
					style={{
						width: "503px", // (595 - 60 - 32 => preview format width - page left and right margin - margin from blue background sides )
						margin: "335px 0 0 46px",
						position: "absolute",
						display: "flex",
						alignItems: "center",
					}}
				>
					<PTitle
						fontSize={16}
						color="white"
						style={{
							display: "flex",
							alignItems: "center",
							marginBottom: "16px",
						}}
					>
						RAPPORT DE CRISE
					</PTitle>
					<PTitle
						fontSize={14}
						color="white"
						style={{
							marginRight: "4px",
							marginBottom: "16px",
							display: "flex",
							alignItems: "center",
							textAlign: "center",
						}}
					>
						{selectedEvent.name}
					</PTitle>
					<PTitle
						fontSize={14}
						color="white"
						style={{
							marginRight: "4px",
							marginBottom: "28px",
							display: "flex",
							justifyContent: "center",
							textAlign: "center",
						}}
					>
						Déclarée le {getDate(selectedEvent.startDate)}
						{!selectedEvent.active &&
							` | Terminée le ${getDate(selectedEvent.endDate)}`}
					</PTitle>
					<PTitle
						fontSize={16}
						color="white"
						style={{
							display: "flex",
							alignItems: "center",
						}}
					>
						{commune.name}
					</PTitle>
				</View>
				<View
					style={{
						marginTop: "32px",
						display: "flex",
						alignItems: "center",
					}}
				>
					<PImage
						src={communeLogoSrc}
						alt="[Logo de la commune]"
						width="256px"
					/>
				</View>
				<View
					style={{
						width: "503px", // (595 - 60 - 32 => preview format width - page left and right margin - margin from blue background sides )
						margin: "790px 0 0 46px",
						position: "absolute",
						display: "flex",
						alignItems: "center",
					}}
				>
					<PTitle fontSize={12}>
						Date de dernière édition du rapport : {moment().format("L")}
					</PTitle>
				</View>
			</PPage>
			{/* =-=-=-=-= Sommaire =-=-=-=-= */}
			<PPage>
				<HeaderBanner>
					<PH2 marginBottom="0px">Table des matières</PH2>
				</HeaderBanner>
				<View>
					{/* Object.values will sort himself actually reversed summary */}
					{Object.values(summaryCategories).map(
						(categoryObject: any, categoryIndex) => {
							if (categoryObject.childrens) {
								return displayCategoryWithChildrens(
									categoryObject,
									categoryIndex,
								)
							} else {
								return (
									<LargeText key={categoryIndex + 1} marginBottom={5}>
										{categoryIndex + 1}. {categoryObject.title}
									</LargeText>
								)
							}
						},
					)}
				</View>
			</PPage>
		</>
	)
}
export default DaybookCoverPage
