import useResponsive from "@/hooks/useResponsive"
import ModalUpload from "@/pages/telechargement/Upload/ModalUpload"
import ArrowBackIcon from "@mui/icons-material/ArrowBack"
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos"
import {
	Divider,
	IconButton,
	ListItemIcon,
	ListItemText,
	MenuItem,
	MenuList,
} from "@mui/material"
import React, { useEffect, useMemo, useState } from "react"
import styled from "styled-components"
import getGenericLinkIcon from "@/utils/components/links/getGenericLinkIcon"

import { Flex } from "@/utils/components/style/flex"
import { LinkType } from "@/utils/types/IFiche"
import IUploadedFile from "@/utils/types/IUploadedFile"
//import Annotation from './links/Annotation'
import Modal from "@/styles/organisms/Modal"
import ModalBody from "@/styles/organisms/Modal/ModalBody"
import ModalFooter from "@/styles/organisms/Modal/ModalFooter"
import ModalHeader from "@/styles/organisms/Modal/ModalHeader"
import { ModalContent } from "@nextui-org/react"
import ExternalLink from "./links/ExternalLink"
import InternalLink from "./links/InternalLink"
import JsonSchemaLink from "./links/JsonSchemaLink"
import RiskFileLink from "./links/RiskFileLink"
import StaticFileLink from "./links/StaticFileLink"
import uploadedFileLink, {
	uploadedToLinkMapping,
} from "./links/uploadedFileLink"
import ParentSubmitButtonWrapper from "./links/utils/parentSubmit/ParentSubmitButtonWrapper"
import { ParentSubmitContextProvider } from "./links/utils/parentSubmit/ParentSubmitContext"
import CancelButton from "@/styles/atoms/Button/specialized/CancelButton"
import Button from "@/styles/atoms/Button"

const Wrapper = styled.div`
    display: flex;
    flex: 1;
    min-width: 0;
`

const IconWrapper = styled.div`
    padding-left: 10px;
    color: grey;
    display: flex;
    align-items: center;
`

const SMenuItem = styled(MenuItem)`
    white-space: normal !important;
`

const FormWrapper = styled.div<{ show: boolean }>`
    padding: 10px;
    gap: 10px;
    display: flex;
    flex-direction: column;
    overflow: auto;
    flex-basis: 0;
    flex-grow: ${({ show }) => (show ? 4 : 0)};
`
const MenuWrapper = styled.div<{ show: boolean }>`
    overflow: auto;
    flex-basis: 0;
    flex-grow: ${({ show }) => (show ? 3 : 0)};
`

const SIconButton = styled(IconButton)`
    margin: -5px 0;
`

const config = {
	[LinkType.EXTERNAL]: {
		label: "Ajouter un lien externe",
		component: ExternalLink,
	},
	[LinkType.INTERNAL]: {
		label: "Ajouter un lien interne",
		component: InternalLink,
	},
	[LinkType.JSON_SCHEMA]: {
		label: "Lier un tableau de données",
		component: JsonSchemaLink,
	},
	[LinkType.RISK_FILE]: {
		label: "Lier une fiche pédagogique",
		component: RiskFileLink,
	},
	[LinkType.STATIC_FILE]: {
		label: "Lier une fiche procédure",
		component: StaticFileLink,
	},
	[LinkType.UPLOADED_CARTO]: {
		label: "Lier une cartographie",
		component: uploadedFileLink("CARTO"),
	},
	[LinkType.UPLOADED_CONVENTION]: {
		label: "Lier une convention",
		component: uploadedFileLink("CONVENTION"),
	},
	[LinkType.UPLOADED_EDITABLE]: {
		label: "Lier un document éditable",
		component: uploadedFileLink("EDITABLE"),
	},
	[LinkType.UPLOADED_IMAGE]: {
		label: "Lier une image ou autre",
		component: uploadedFileLink("IMAGE"),
	},
	/*[LinkType.NO_LINK]: {
        label: 'Ajouter une annotation',
        component: Annotation,
    },*/
}

const FLinksModal = ({ open, onClose, value, setValue }) => {
	const [linkTypeToAdd, hiddenSetLinkTypeToAdd] = useState<LinkType>(undefined)
	const [requestSelect, setRequestSelect] = useState(undefined)

	const setLinkTypeToAdd = (val: LinkType) => {
		hiddenSetLinkTypeToAdd(val)
		setRequestSelect(undefined)
	}

	const [upload, setUpload] = useState(false)

	useEffect(() => {
		if (open) setLinkTypeToAdd(undefined)
	}, [open])

	const onAddLink = (link) => {
		setValue([...value, link])
		onClose()
	}

	const FormComponent = useMemo(
		() => config[linkTypeToAdd]?.component,
		[linkTypeToAdd],
	)

	const isSmall = useResponsive(1000)

	return (
		<ParentSubmitContextProvider onSubmit={onAddLink}>
			<Modal
				isOpen={open}
				onClose={onClose}
				$baseHeight={600}
				$baseWidth={900}
				size="4xl"
			>
				<ModalContent>
					<ModalHeader>
						{isSmall && config[linkTypeToAdd] ? (
							<Flex gap="5px">
								<SIconButton
									size="small"
									onClick={() => setLinkTypeToAdd(undefined)}
								>
									<ArrowBackIcon />
								</SIconButton>
								{config[linkTypeToAdd].label}
							</Flex>
						) : (
							"Ajouter un lien"
						)}
					</ModalHeader>
					<ModalBody>
						<Wrapper>
							<MenuWrapper show={!isSmall || !linkTypeToAdd}>
								<MenuList>
									{Object.entries(config).map(([key, params]) => {
										const Icon = getGenericLinkIcon(key as LinkType)
										return (
											<SMenuItem
												key={key}
												onClick={() => setLinkTypeToAdd(key as LinkType)}
												selected={key === linkTypeToAdd}
											>
												<ListItemIcon>
													<Icon
														fontSize="small"
														htmlColor="var(--primary800)"
													/>
												</ListItemIcon>
												<ListItemText>{params.label}</ListItemText>
												<IconWrapper>
													<ArrowForwardIosIcon fontSize="small" />
												</IconWrapper>
											</SMenuItem>
										)
									})}

									<Flex directionColumn alignItemsStretch>
										<Button
											variant="bordered"
											color="primary"
											size="small"
											onClick={() => setUpload(true)}
										>
											Téléverser un nouveau document
										</Button>
									</Flex>
								</MenuList>
							</MenuWrapper>
							<Divider orientation="vertical" flexItem />
							<FormWrapper show={!isSmall || Boolean(linkTypeToAdd)}>
								{FormComponent && (
									<FormComponent links={value} requestSelect={requestSelect} />
								)}
							</FormWrapper>
						</Wrapper>
					</ModalBody>
					<ModalFooter>
						<CancelButton onClick={onClose} />
						{linkTypeToAdd && (
							<ParentSubmitButtonWrapper>
								{({ onClick }) => (
									<Button color="primary" onClick={onClick}>
										Ajouter
									</Button>
								)}
							</ParentSubmitButtonWrapper>
						)}
					</ModalFooter>
				</ModalContent>
			</Modal>

			<ModalUpload
				isOpen={upload}
				onClose={(target: IUploadedFile) => {
					setUpload(false)
					if (target) {
						setLinkTypeToAdd(uploadedToLinkMapping[target.config.category])
						setRequestSelect(target.id)
					}
				}}
			/>
		</ParentSubmitContextProvider>
	)
}

export default FLinksModal
