import ErrorPermissions, {
	BACK_PATH_HOME,
	BACK_TITLE_HOME,
	FEATURE_ERROR_PERMISSION,
} from "@/hooks/ErrorPermissions"
import useAccessRights from "@/hooks/useAccessRights"
import { CONFIGURATION } from "@/redux-toolkit/userSettings/constants"
import React from "react"
import { Route } from "react-router-dom"
import ConfigRisk from "./ConfigRisk"
import CustomTabs from "utils/components/customTabs/CustomTabs"
import ConfigAlert from "./ConfigAlert"
import styled from "styled-components"
import { EnumToolPath } from "@/hooks/services/useAccessRightsServices"
const ConfigContainer = styled.div`
	padding: 3rem;
	max-height: 90vh;
	overflow-y: auto;
`
const Configuration = () => {
	const permissions = useAccessRights(EnumToolPath.CONFIGURATION)
	if (!permissions.read) {
		return (
			<ErrorPermissions
				errorMessage={FEATURE_ERROR_PERMISSION}
				backTitle={BACK_TITLE_HOME}
				backUrl={BACK_PATH_HOME}
			/>
		)
	}

	return (
		<>
			<Route exact path="/configuration">
				<ConfigContainer>
					<CustomTabs
						tabChildrens={[
							{
								key: "Risques",
								render: <ConfigRisk />,
							},
							{
								key: "Moyens d'alertes",
								render: <ConfigAlert />,
							},
						]}
					/>
				</ConfigContainer>
			</Route>
		</>
	)
}
export default (props) => <Configuration {...props} />
