import {
	SearchMode,
	SearchView,
} from "@/redux-toolkit/edit/cooperationFiltersRessources"
import { setCooperationFilters } from "@/redux-toolkit/edit/reducer"
import { useDispatch } from "react-redux"
import { StaticFormType } from "utils/services/StaticFormsService"
import useCooperationConfig from "./useCooperationConfig"

function useStartSearch() {
	const dispatch = useDispatch()
	const { layers, modesEnabled } = useCooperationConfig()

	return (mode: SearchMode, changeView = true, others = {}) => {
		if (!modesEnabled[mode])
			return dispatch(
				setCooperationFilters({
					search: "",
				}),
			)

		switch (mode) {
			case SearchMode.ORGANIGRAMMES:
				return dispatch(
					setCooperationFilters({
						search: SearchMode.ORGANIGRAMMES,
						layers: [StaticFormType.PCC],
						tag: undefined,
						...(changeView ? { view: SearchView.TABLEAU } : undefined),
						...others,
					}),
				)
			case SearchMode.DONNEES_PARTAGEES:
				return dispatch(
					setCooperationFilters({
						search: SearchMode.DONNEES_PARTAGEES,
						layers: [],
						availableLayers: [],
						tag: undefined,
						...(changeView ? { view: SearchView.CARTE } : undefined),
						...others,
					}),
				)
			default:
				return null
		}
	}
}
export default useStartSearch
