export const SET_SELECTION = "SET_SELECTION"
export const RESET_SELECTION = "RESET_SELECTION"

export const setSelection = (selection) => ({
	type: SET_SELECTION,
	selection,
})

export const resetSelection = () => ({
	type: RESET_SELECTION,
})
