import React from "react"
import { useDropzone } from "react-dropzone"
import styled, { css } from "styled-components"
import CloudUploadIcon from "@mui/icons-material/CloudUpload"
import { Flex } from "@/utils/components/style/flex"
import ErrorService from "@/services/ErrorService"
import { useDispatch } from "react-redux"
import UploadedFileApi from "@/redux-toolkit/data/uploadedFile/UploadedFileApi"

const SCloudUploadIcon = styled(CloudUploadIcon)`
    color: var(--neutral400);
`
const F12 = styled.div<{ disabled: boolean }>`
    font-size: var(--font-size-small);
    ${({ disabled }) =>
			css`
            color: ${disabled ? "#d2d2d2" : "var(--primary800)"};
        `}
`
const SFlex = styled(Flex)<{ disabled: boolean }>`
    border-style: dashed;
	border: 1px solid;
    ${({ disabled }) =>
			css`
            border-color: ${disabled ? "#d2d2d2" : "var(--primary800)"};
        `}
    justify-content: center;
    align-items: center;
    gap: 1rem;
    padding: 2rem;
`

const FDropZone = ({
	children = undefined,
	category,
	onDropFinal,
	disabled = false,
	customUploadContent = {
		icon: <SCloudUploadIcon />,
		allowedFormatsText: "(png, pdf, jpeg ...)",
	},
}: {
	children?: any
	category: string
	onDropFinal: (res: any) => void
	disabled?: boolean
	customUploadContent?: { icon: any; allowedFormatsText: string }
}) => {
	const dispatch = useDispatch()

	const onDrop = async (files: any) => {
		if (files.length > 1) {
			ErrorService.error({
				component: "FDropZone:onDrop",
				message: "un seul fichier autorisé",
				dispatch,
			})
			return
		}

		// if files larger
		const res = await UploadedFileApi.uploadFile({
			file: files[0],
			config: {
				category: category,
			},
		})

		onDropFinal(res)
	}

	const { getRootProps, getInputProps } = useDropzone({
		onDrop,
	})

	return (
		<SFlex
			fullWidth
			{...(disabled ? null : getRootProps())}
			disabled={disabled}
		>
			{!children && (
				<>
					{customUploadContent.icon}
					<input {...getInputProps()} />
					<F12 disabled={disabled}>
						Faire glisser les fichiers à téléverser ou "Parcourir vos fichiers"{" "}
						{customUploadContent.allowedFormatsText}
					</F12>
				</>
			)}
			{children}
		</SFlex>
	)
}
export default FDropZone
