import MappingsApi from "@/redux-toolkit/data/mappings/MappingsApi"
import PointsApi from "@/redux-toolkit/data/points/PointsApi"
import SynchroPointService from "@/services/synchro/SynchroPointService"
import _ from "lodash"
import { toast } from "react-toastify"
import { wait } from "utils/wait"
import ErrorService from "@/services/ErrorService"
import IPoint from "utils/types/IPoint"
import { IImportDiff } from "./buildImportDiff"

const WAIT_MS = 50

const buildPoint = (
	id,
	jsonschema_id,
	properties,
	commune_id,
): Partial<IPoint> => ({
	id,
	jsonschema_id,
	commune_id,
	geojson: {
		properties,
	},
})

const processImportDiff = async (
	dataDiff: IImportDiff,
	dispatch,
	jsonschemaId,
	mappingId: string,
	commune_id: string,
	callback,
) => {
	const grouped = _.groupBy(
		Object.values(dataDiff),
		(diffItem) => diffItem.status,
	)

	try {
		await Promise.all(
			[
				...(grouped.added ?? []).map(
					(diffItem) => () =>
						PointsApi.createPoint(
							buildPoint(diffItem.id, jsonschemaId, diffItem.new, commune_id),
						),
				),
				...(grouped.changed ?? []).map(
					(diffItem) => () =>
						PointsApi.updatePoint(
							buildPoint(diffItem.id, jsonschemaId, diffItem.new, commune_id),
						),
				),
				...(grouped.removed ?? []).map(
					(diffItem) => () => PointsApi.deletePoint(diffItem.id),
				),
			].map((func, index) =>
				wait(WAIT_MS * index)
					.then(func)
					.then(callback),
			),
		)
	} catch (e) {
		ErrorService.error({
			component: "processImportDiff",
			message: `Une erreur est survenue durant l'import : ${e}`,
			silence: true,
			dispatch,
		})
		toast.error("Une erreur est survenue durant l'import")
	}

	await MappingsApi.updateMappingLastImport(mappingId)

	return dispatch(SynchroPointService.synchronize)
}

export default processImportDiff
