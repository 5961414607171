import Axios from "axios"
import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { useParams } from "react-router-dom"
import ErrorService from "@/services/ErrorService"
import IDaybookAlert from "@/utils/types/daybook/IDaybookAlert"
import usePoints from "../usePoints"
import { addPoint } from "@/redux-toolkit/data/points/reducer"

const useFetchAlertInfo = () => {
	const { alertId } = useParams<{ alertId: string }>()
	const [isLoading, setIsLoading] = useState(false)
	const dispatch = useDispatch()
	const points = usePoints()
	const alert = points[alertId] as IDaybookAlert

	const responses = alert?.geojson?.properties?.responses
	const data = Object.entries(responses ?? {}).map(
		([recipientId, response]) => {
			return {
				recipientId: recipientId,
				...response,
			}
		},
	)

	const checkUsedService = (id: string) => {
		const alert = points[id] as IDaybookAlert
		return alert.geojson.properties.usedService
	}

	const fetchAlertInfo = async (forcedAlertId = undefined) => {
		if (isLoading) return
		setIsLoading(true)
		const concernedAlertId = forcedAlertId || alertId
		const usedService = checkUsedService(concernedAlertId)
		let res

		try {
			if (usedService === "CITYCALERT") {
				res = await Axios.get(`/api/citycalert/${concernedAlertId}`)
			} else {
				res = await Axios.get(`/api/twilio/${concernedAlertId}`)
			}
			dispatch(addPoint(res.data.alert))
		} catch (error) {
			ErrorService.error({
				component: "useFetchAlertInfo",
				message: "Erreur lors de la récupération des informations de l'alerte",
				silence: true,
				dispatch,
			})
			return { error: error }
		} finally {
			setIsLoading(false)
		}

		return res
	}

	useEffect(() => {
		if (alertId) {
			setTimeout(() => {
				fetchAlertInfo()
			}, 1000)
		}
	}, [])

	return {
		data,
		fetchAlertInfo,
		isLoading,
	}
}

export default useFetchAlertInfo
