import PreviewIcon from "@mui/icons-material/Preview"
import PublishIcon from "@mui/icons-material/Publish"
import { IconButton } from "@mui/material"
import { ColumnDef } from "@tanstack/react-table"
import useCommune from "@/hooks/useCommune"
import React, { useMemo, useState } from "react"
import { useDispatch } from "react-redux"
import { useHistory } from "react-router-dom"
import PointPropertyValue from "utils/components/jsonSchema/properties/PointPropertyValue"
import usePointPropertyToText from "utils/components/jsonSchema/properties/usePointPropertyToText"
import { PrimaryLoadingButton } from "utils/components/style/button"
import SuperReactTable from "utils/components/tables/SuperReactTable/SuperReactTable"
import { width100, width60 } from "utils/components/tables/widthProps"
import processImportDiff from "../utils/processImportDiff"
import DiffDisplayItemModal, { DiffIcon } from "./DiffDisplayItemModal"

const DiffDisplay = ({ mappingResults, mapping, jsonSchema }) => {
	const jsonSchemaProperties = jsonSchema.template.properties
	const [loading, setLoading] = useState<number>()
	const dispatch = useDispatch()
	const commune = useCommune()
	const history = useHistory()

	const submit = async () => {
		setLoading(0)
		await processImportDiff(
			mappingResults,
			dispatch,
			jsonSchema.id,
			mapping.id,
			commune.id,
			() => setLoading((i) => i + 1),
		)
		alert("Import terminé avec succès !")
		history.goBack()
	}

	const data = useMemo(
		() =>
			Object.values(mappingResults).filter(
				(mappingResult: any) => mappingResult.status !== "unchanged",
			),
		[mappingResults],
	)

	const pointPropertyToText = usePointPropertyToText()

	const [detail, setDetail] = useState({
		id: undefined,
		open: false,
	})

	const columns = useMemo(() => {
		return [
			{
				accessorKey: "status",
				cell: ({ getValue }) => {
					return (
						<>
							<DiffIcon status={getValue()} />
						</>
					)
				},
				...width100,
			},
			...jsonSchemaProperties
				.filter((prop) => prop.isColumn)
				.map((property) => {
					return {
						header: property.label,
						accessorFn: (data) => pointPropertyToText(data, property),
						cell: ({ row }) => (
							<>
								<PointPropertyValue
									value={(row.original.new ?? row.original.old)[property.name]}
									property={property}
									diffWith={
										(row.original.new ? row.original.old : undefined)?.[
											property.name
										]
									}
								/>
							</>
						),
					} as ColumnDef<any>
				}),
			{
				header: "",
				accessorKey: "id",
				cell: ({ getValue }) => {
					const id = getValue()
					return (
						<>
							<IconButton
								onClick={() =>
									setDetail({
										id,
										open: true,
									})
								}
							>
								<PreviewIcon />
							</IconButton>
						</>
					)
				},
				...width60,
				enableSorting: false,
			},
		] as ColumnDef<any, any>[]
	}, [jsonSchemaProperties])

	return (
		<>
			<SuperReactTable
				data={data}
				columns={columns}
				selectable={false}
				isEditable={false}
				isDeletable={false}
				actionsButtons={
					<PrimaryLoadingButton
						variant="contained"
						onClick={submit}
						loadingPosition="end"
						loading={loading !== undefined}
						startIcon={<PublishIcon />}
					>
						{loading === undefined ? (
							<>Procéder à l&apos;import</>
						) : (
							<>
								Import en cours ({loading}/{Object.keys(data).length})
							</>
						)}
					</PrimaryLoadingButton>
				}
			/>

			{detail.id && (
				<DiffDisplayItemModal
					jsonSchemaProperties={jsonSchemaProperties}
					open={detail.open}
					diffItem={mappingResults[detail.id]}
					onClose={() =>
						setDetail({
							...detail,
							open: false,
						})
					}
					mapping={mapping}
				/>
			)}
		</>
	)
}

export default DiffDisplay
