import useCooperationFilters from "@/hooks/useCooperationFilters"
import { SearchMode } from "@/redux-toolkit/edit/cooperationFiltersRessources"
import _ from 'lodash'
function useCommuneFilter() {
	const coopFilters = useCooperationFilters()

	return (item) => {
		// Filter disabled
		if (!coopFilters.communes.length) return (
			_.isEmpty(coopFilters.tag) || SearchMode.DONNEES_PARTAGEES === coopFilters.search
				? true
				: false
		)

		// Filter enabled
		if (coopFilters.excludeCommunes)
			return !coopFilters.communes.includes(item.commune_id)
		return coopFilters.communes.includes(item.commune_id)
	}
}

export default useCommuneFilter
