import React, { useState, useRef } from "react"
import { useEffectOnce } from "react-use"
import Debug from "debug"
import SuperMap from "utils/components/map/SuperMap"
import { isDesktop } from "react-device-detect"
import MinimapControl from "@/pages/maincourante/MinimapControl"
import MarkerClusterGroup from "@changey/react-leaflet-markercluster"
import TileLayerService from "utils/services/TileLayerService"
import MarkerDaybook from "@/pages/maincourante/subComponents/carto/MarkerDaybook"
import PointCard from "utils/components/jsonSchema/PointCard"
import { Drawer } from "@/pages/cooperation/customdata/components/SharedMap"
import { GeoJSON, Pane } from "react-leaflet"
import getPolygonStyle from "utils/components/map/polygon/getPolygonStyle"
import DatabaseService from "@/services/DatabaseService"
import useNavigationTree from "utils/components/categoryNavigation/useNavigationTree"
import { DISPOSITIF_GESTION_CRISE } from "utils/menuTab"
import useMultiPolygons from "@/hooks/useMultiPolygons"

// app:javascript:components:admin:formulaire_dynamique:MyForms:MyFormsMap.tsx
const debug = Debug(
	"app:javascript:components:admin:formulaire_dynamique:MyForms:MyFormsMap",
)
debug.log = console.log.bind(console)

const MyFormsMap = ({ customDataItems, jsonSchema }) => {
	const [selectedMarker, setSelectedMarker] = useState(null)
	const multiPolygons = useMultiPolygons()
	const [sectorToDisplay, setSectorsToDisplay] = useState([])
	const [selectedLayer, setSelectedLayer] = useState("Osm")
	const [openInfos, setOpenInfos] = useState(false)
	const clusterRef = useRef(null)

	const { tree } = useNavigationTree({ includeGeolocalisable: false })
	const secteurs =
		tree
			?.filter((obj) => obj.title === DISPOSITIF_GESTION_CRISE)?.[0]
			?.subCategories?.filter((obj) => obj.title === "Secteurs")?.[0]?.items ??
		[]

	useEffectOnce(() => {
		const processMultiPolygon = async () => {
			const geo = []
			await Promise.all(
				secteurs.map(async (couche: any) => {
					const data = await DatabaseService.get(couche.id)
					if (data) {
						geo.push({
							...couche,
							geojson: data,
						})
					}
					return
				}),
			)
			setSectorsToDisplay(geo)
		}

		processMultiPolygon()
	})

	return (
		<div>
			<SuperMap
				hasSearchControl={false}
				mapContainerHeight={`calc(${window.innerHeight}px - 16rem) !important`}
				mapContainerMarginTop={`16rem !important`}
				displayOptions={{
					displayBottomControl: false,
					displayRightControl: false,
					hasMargin: true,
				}}
			>
				{isDesktop && <MinimapControl selectedLayer={selectedLayer} />}
				<MarkerClusterGroup ref={clusterRef} key={Date.now()}>
					{customDataItems.map((point) => {
						const isSelected = point === selectedMarker && openInfos
						if (isSelected) return null

						return (
							<MarkerDaybook
								key={point.id}
								point={point}
								handleClick={() => {
									setSelectedMarker(point)
									setOpenInfos(true)
								}}
							/>
						)
					})}
				</MarkerClusterGroup>
				{selectedMarker && openInfos && (
					<MarkerDaybook
						point={selectedMarker}
						handleClick={() => null}
						isSelected
					/>
				)}
				{sectorToDisplay.map((secteur) => {
					const zIndex = 500 - secteurs.indexOf(secteur)
					return (
						<Pane
							name={secteur.id}
							key={`${secteur.id}:${zIndex.toString()}`}
							style={{
								zIndex: zIndex,
							}}
						>
							<GeoJSON
								key={secteur.id}
								//@ts-ignore
								data={JSON.parse(secteur.geojson)}
								style={() => getPolygonStyle(multiPolygons[secteur.id])}
							/>
						</Pane>
					)
				})}
				{TileLayerService.getAllLayers()[selectedLayer]}
			</SuperMap>
			<Drawer $isOpen={openInfos} $spaceTop={250}>
				<PointCard
					key={selectedMarker?.id}
					point={selectedMarker}
					onClose={() => setOpenInfos(false)}
				/>
			</Drawer>
		</div>
	)
}

export default MyFormsMap
