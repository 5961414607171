import usePoints from "@/hooks/usePoints"
import Debug from "debug"
import React, { useMemo } from "react"
import { useFormContext } from "react-hook-form"
import { Flex } from "utils/components/style/flex"
import SuperReactTable from "utils/components/tables/SuperReactTable/SuperReactTable"
import { B } from "utils/components/tables/SuperReactTable/SuperReactTable.styled"
import {
	useColumnsDaybookAlertContactTable,
	useTransformedAndSortedDataDaybookAlertContactTable,
} from "./DaybookAlertContactTable.hook"

// app:javascript:components:maincourante:alert:alertCreation:DaybookAlertContactTable.tsx
const debug = Debug(
	"app:javascript:components:maincourante:alert:alertCreation:DaybookAlertContactTable",
)
debug.log = console.log.bind(console)

const DaybookAlertContactTableReady = () => {
	const points = usePoints()
	const { watch } = useFormContext()

	const listeDiffusionIds = watch("geojson.properties.listeDiffusionIds")
	const listeDiffusions = watch("geojson.properties.listeDiffusions")
	const jsonSchemaId = watch("geojson.properties.listeDiffusions")

	const columns = useColumnsDaybookAlertContactTable()
	const data = useMemo(() => {
		return Object.values(points).filter((point) =>
			listeDiffusionIds?.includes(point.id),
		)
	}, [jsonSchemaId, points])

	const transformedData = useTransformedAndSortedDataDaybookAlertContactTable(
		data,
		listeDiffusions,
	)

	return (
		<Flex fullWidth directionColumn alignItemsInitial>
			<span>
				<B>{listeDiffusionIds.length}</B> personne(s) sélectionné(s)
			</span>
			<SuperReactTable
				isDeletable={false}
				isEditable={false}
				simpleTable
				selectable={false}
				columns={columns}
				data={transformedData}
			/>
		</Flex>
	)
}

export default DaybookAlertContactTableReady
