import { updateUploadedFile } from "@/redux-toolkit/data/uploadedFile/resources"
import Modal from "@/styles/organisms/Modal"
import ModalBody from "@/styles/organisms/Modal/ModalBody"
import ModalFooter from "@/styles/organisms/Modal/ModalFooter"
import ModalHeader from "@/styles/organisms/Modal/ModalHeader"
import FSelect from "@/utils/form/FSelect"
import FSwitch from "@/utils/form/FSwitch"
import IUploadedFile, { IFileConfigCategory } from "@/utils/types/IUploadedFile"
import Yup from "@/utils/yup"
import { yupResolver } from "@hookform/resolvers/yup"
import { ModalContent } from "@nextui-org/react"
import React, { useEffect } from "react"
import { FormProvider, useForm } from "react-hook-form"
import { useDispatch } from "react-redux"
import { toast } from "react-toastify"
import CancelButton from "@/styles/atoms/Button/specialized/CancelButton"
import Button from "@/styles/atoms/Button"
import { Flex } from "@/utils/components/style/flex"
const schema = Yup.object().shape({
	generateHeader: Yup.boolean().default(false),
	orientation: Yup.string().default("Portrait"),
})

const ModalModifyHeader = ({
	isOpen,
	onClose,
	file,
}: {
	isOpen: boolean
	onClose: (target?: IFileConfigCategory) => void
	file: IUploadedFile
}) => {
	const dispatch = useDispatch()

	const methods = useForm({
		resolver: yupResolver(schema),
	})

	useEffect(() => {
		if (isOpen) {
			methods.reset({
				...schema.getDefault(),
				generateHeader: file?.config?.generateHeader ?? false,
				orientation: file?.config?.orientation ?? "Portrait",
			})
		}
	}, [isOpen])

	const onSumbit = async (values) => {
		await dispatch(
			updateUploadedFile({
				id: file.id,
				config: {
					...(file?.config ?? {}),
					generateHeader: values.generateHeader,
					orientation: values.orientation,
				},
			}),
		)
		onClose(values.category)
		toast.success("l'en-tête du fichier a été modifié avec succès")
	}

	return (
		<FormProvider {...methods}>
			<Modal isOpen={isOpen} onClose={() => onClose()} $baseWidth={1000}>
				<ModalContent>
					<ModalHeader>Modifier l'en-tête du fichier</ModalHeader>
					<ModalBody>
						<Flex gap="1rem" fullWidth>
							<FSwitch name="generateHeader" label="Générer un en-tête" />
							<FSelect
								name="orientation"
								label="Orientation"
								options={["Portrait", "Paysage"]}
							/>
						</Flex>
					</ModalBody>
					<ModalFooter>
						<CancelButton onClick={() => onClose()} />
						<Button
							color="primary"
							onClick={methods.handleSubmit(onSumbit, console.error)}
						>
							Terminer
						</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>
		</FormProvider>
	)
}

export default ModalModifyHeader
