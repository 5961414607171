import useSearchFiches from "@/hooks/search/useSearchFiches"
import useSearchJsonSchemas from "@/hooks/search/useSearchJsonSchemas"
import useSearchPdfGenerators from "@/hooks/search/useSearchPdfGenerators"
import useSearchPoints from "@/hooks/search/useSearchPoints"
import useSearchStaticFiches from "@/hooks/search/useSearchStaticFiches"
import useSearchUploadedFiles from "@/hooks/search/useSearchUploadedFiles"
import useOrga from "@/hooks/useOrga"
import DownloadService from "@/pages/telechargement/services/DownloadService"
import Modal from "@/styles/organisms/Modal"
import ModalBody from "@/styles/organisms/Modal/ModalBody"
import ModalHeader from "@/styles/organisms/Modal/ModalHeader"
import CustomTooltip from "@/utils/components/style/CustomTooltip"
import { Flex, FlexColumn } from "@/utils/components/style/flex"
import forceDownloadURL from "@/utils/forceDownloadURL"
import FileIcon from "@/utils/form/specific/fiche/links/utils/FileIcon"
import formatNfdLowerCase from "@/utils/formatNfdLowerCase"
import getImage from "@/utils/getImage"
import { TypeFiche } from "@/utils/types/IFiche"
import GetApp from "@mui/icons-material/GetApp"
import HistoryEduIcon from "@mui/icons-material/HistoryEdu"
import InsertDriveFile from "@mui/icons-material/InsertDriveFile"
import {
	IconButton,
	InputAdornment,
	List,
	ListItem,
	ListSubheader,
	TextField,
} from "@mui/material"
import { Input, ModalContent } from "@nextui-org/react"
import Debug from "debug"
import React, { useState } from "react"
import { useHistory } from "react-router"
import styled from "styled-components"
import SearchCardResult from "./SearchCardResult"
import intercoNameAdapter from "@/utils/intercoNameAdapter"
import { isMobile } from "react-device-detect"
import SearchIcon from "@mui/icons-material/Search"
import useIsInterco from "@/hooks/useIsInterco"
import Defer from "@/utils/Defer"
// app:javascript:components:app:ModalSearch.tsx
const debug = Debug("app:javascript:components:app:ModalSearch")
debug.log = console.log.bind(console)
const Container = styled.div`
    * {
        //color: black;
    }
`
export const linkMap = {
	JSON_SCHEMA: (id) => `/customdata/my_forms/${id}`,
	HUMAN: () => "/customdata/hum",
	STOCK: () => "/customdata/stocks",
	STOCK_PLACE: () => "/customdata/lieu_stock",
}

export const POINT = "POINT"
const ModalSearch = ({ isOpen, onClose }) => {
	const history = useHistory()
	const [searchValue, setSearchValue] = useState("")
	const normalizedSearchValue = formatNfdLowerCase(searchValue)
	const orga = useOrga()
	const isInterco = useIsInterco()

	const words = normalizedSearchValue
		.split(" ")
		.filter((word) => word.length > 1)

	const searchablePoints = useSearchPoints(words)

	const searchResultJsonSchemas = useSearchJsonSchemas(words)

	const searchUploadedFiles = useSearchUploadedFiles(words)

	const searchFiches = useSearchFiches(words)

	const searchPdfGenerators = useSearchPdfGenerators(words)

	const searchStaticFiches = useSearchStaticFiches(words)

	const searchFichesActions = searchFiches.filter(
		(fiche) => fiche.classification === TypeFiche.FICHE_ACTION,
	)
	const searchFichesScenario = searchFiches.filter(
		(fiche) => fiche.classification === TypeFiche.FICHE_SCENARIO,
	)

	return (
		<Modal
			isOpen={isOpen}
			onClose={onClose}
			autoFocus={false}
			fade={false}
			size={isMobile ? "full" : "2xl"}
		>
			<ModalContent>
				<ModalHeader>Résultat de la recherche</ModalHeader>
				<ModalBody>
					<FlexColumn>
						<Input
							value={searchValue}
							placeholder={intercoNameAdapter(
								"Rechercher une donnée ou un formulaire du PCS",
								isInterco,
							)}
							onChange={(e) => setSearchValue(e.target.value)}
							startContent={<SearchIcon />}
							autoFocus
							variant="bordered"
							errorMessage={
								searchValue.length < 2 &&
								"Veuillez entrer au moins 2 caractères"
							}
						/>
						{searchValue.length >= 2 && (
							<Container>
								<List>
									{searchResultJsonSchemas.length > 0 && (
										<ListSubheader>Tableaux données</ListSubheader>
									)}
									<Defer>
										{searchResultJsonSchemas.map((jsonSchema) => {
											let jsonSchemaTitle = formatNfdLowerCase(jsonSchema.title)
											words.forEach((word) => {
												const strRegExp = new RegExp(word, "g")
												jsonSchemaTitle = jsonSchemaTitle.replaceAll(
													strRegExp,
													"<b>" + word + "</b>",
												)
											})
											return (
												<ListItem
													key={jsonSchema.id}
													button
													onClick={() => {
														history.push(
															`/customdata/my_forms/${jsonSchema.id}`,
														)
														setSearchValue("")
														onClose()
													}}
												>
													<Flex gap="1rem">
														<img
															src={getImage(jsonSchema.imgId)}
															width={35}
															height={35}
														/>
														<div
															dangerouslySetInnerHTML={{
																__html: jsonSchemaTitle,
															}}
														></div>
													</Flex>
												</ListItem>
											)
										})}
									</Defer>
									{searchUploadedFiles.length > 0 && (
										<ListSubheader>Fichiers uploadés</ListSubheader>
									)}
									<Defer>
										{searchUploadedFiles.map((uploadedFile) => {
											let uploadedFileTitle = formatNfdLowerCase(
												uploadedFile.filename,
											)
											words.forEach((word) => {
												const strRegExp = new RegExp(word, "g")
												uploadedFileTitle = uploadedFileTitle.replaceAll(
													strRegExp,
													"<b>" + word + "</b>",
												)
											})
											return (
												<ListItem key={uploadedFile.id}>
													<Flex gap="1rem" spaceBetween>
														<Flex gap="1rem">
															<FileIcon filename={uploadedFile.filename} />
															<div
																dangerouslySetInnerHTML={{
																	__html: uploadedFileTitle,
																}}
															></div>
														</Flex>
														<CustomTooltip title={"Télécharger"}>
															<IconButton
																onClick={async () => {
																	const url =
																		await DownloadService.getDownloadUrl(
																			uploadedFile.id,
																		)
																	forceDownloadURL(url, uploadedFile.filename)
																}}
															>
																<GetApp />
															</IconButton>
														</CustomTooltip>
													</Flex>
												</ListItem>
											)
										})}
									</Defer>

									{searchPdfGenerators.length > 0 && (
										<ListSubheader>Arrêtés</ListSubheader>
									)}

									<Defer>
										{searchPdfGenerators.map((pdfGenerator: any) => {
											let pdfGeneratorTitle = formatNfdLowerCase(
												pdfGenerator.title,
											)
											words.forEach((word) => {
												const strRegExp = new RegExp(word, "g")
												pdfGeneratorTitle = pdfGeneratorTitle.replaceAll(
													strRegExp,
													"<b>" + word + "</b>",
												)
											})
											return (
												<ListItem
													key={pdfGenerator.id}
													button
													onClick={() => {
														history.push(
															`/customdata/arretes/${pdfGenerator.id}`,
														)
														setSearchValue("")
														onClose()
													}}
												>
													<Flex gap="1rem">
														<HistoryEduIcon />
														<div
															dangerouslySetInnerHTML={{
																__html: pdfGeneratorTitle,
															}}
														></div>
													</Flex>
												</ListItem>
											)
										})}
									</Defer>

									{searchFichesScenario.length > 0 && (
										<ListSubheader>Fiches scénarios</ListSubheader>
									)}

									<Defer>
										{searchFichesScenario.map((fiche) => {
											let ficheTitle = formatNfdLowerCase(fiche.nom)
											words.forEach((word) => {
												const strRegExp = new RegExp(word, "g")
												ficheTitle = ficheTitle.replaceAll(
													strRegExp,
													"<b>" + word + "</b>",
												)
											})
											return (
												<ListItem
													key={fiche.id}
													button
													onClick={() => {
														history.push(`/customdata/fiche/${fiche.id}`)
														setSearchValue("")
														onClose()
													}}
												>
													<Flex gap="1rem">
														<InsertDriveFile />
														<div
															dangerouslySetInnerHTML={{
																__html: ficheTitle,
															}}
														></div>
													</Flex>
												</ListItem>
											)
										})}
									</Defer>

									{searchFichesActions.length > 0 && (
										<ListSubheader>Fiches actions</ListSubheader>
									)}

									<Defer>
										{searchFichesActions.map((fiche) => {
											let ficheTitle = formatNfdLowerCase(
												orga[fiche.nom]?.idName,
											)
											words.forEach((word) => {
												const strRegExp = new RegExp(word, "g")
												ficheTitle = ficheTitle.replaceAll(
													strRegExp,
													"<b>" + word + "</b>",
												)
											})
											return (
												<ListItem
													key={fiche.id}
													button
													onClick={() => {
														history.push(`/customdata/fiche/${fiche.id}`)
														setSearchValue("")
														onClose()
													}}
												>
													<Flex gap="1rem">
														<InsertDriveFile />
														<div
															dangerouslySetInnerHTML={{
																__html: ficheTitle,
															}}
														></div>
													</Flex>
												</ListItem>
											)
										})}
									</Defer>

									{searchablePoints.length > 0 && (
										<ListSubheader>Données formulaires</ListSubheader>
									)}
									<Defer>
										{searchablePoints.map((item: any) => (
											<SearchCardResult
												item={item}
												key={item.id}
												setSearchValue={setSearchValue}
												onClose={onClose}
											/>
										))}
									</Defer>
									{searchStaticFiches.length > 0 && (
										<ListSubheader>Fiches statiques</ListSubheader>
									)}
									<Defer>
										{searchStaticFiches.map((staticFiche) => {
											let staticFicheTitle = formatNfdLowerCase(
												staticFiche.title,
											)
											words.forEach((word) => {
												const strRegExp = new RegExp(word, "g")
												staticFicheTitle = staticFicheTitle.replaceAll(
													strRegExp,
													"<b>" + word + "</b>",
												)
											})
											return (
												<ListItem
													key={staticFiche.id}
													button
													onClick={() => {
														const openTab = (url) => () =>
															window.open(url, "_blank").focus()
														openTab(
															window.location.origin +
																`/generate_pcs_uniq_fiche/${staticFiche.code}`,
														)()
													}}
												>
													<Flex gap="1rem">
														<FileIcon filename={staticFiche.title + ".pdf"} />
														<div
															dangerouslySetInnerHTML={{
																__html: staticFicheTitle,
															}}
														></div>
														<CustomTooltip title={"Télécharger"}>
															<IconButton
																onClick={async () => {
																	const openTab = (url) => () =>
																		window.open(url, "_blank").focus()
																	console.log("opening fiche", staticFiche)
																	openTab(
																		window.location.origin +
																			`/generate_pcs_uniq_fiche/${staticFiche.code}`,
																	)()
																}}
															>
																<GetApp />
															</IconButton>
														</CustomTooltip>
													</Flex>
												</ListItem>
											)
										})}
									</Defer>
								</List>
							</Container>
						)}
					</FlexColumn>
				</ModalBody>
			</ModalContent>
		</Modal>
	)
}

export default ModalSearch
