import { TextField } from "@mui/material"
import React from "react"
import { useController, useFormContext } from "react-hook-form"
import styled from "styled-components"

const STextField = styled(TextField)`
    flex-basis: 50%;
    flex-shrink: 0;
    flex-grow: 1;
`

function TitleInput({ name, label }) {
	const { control } = useFormContext()

	const {
		field: { ref, ...inputProps },
		fieldState: { error },
	} = useController({
		name,
		control,
		rules: { required: true },
		defaultValue: "",
	})

	return (
		<STextField
			inputRef={ref}
			error={Boolean(error?.message)}
			label={label}
			size="small"
			{...inputProps}
		/>
	)
}

export default TitleInput
