import { ListItemIcon, TextField } from "@mui/material"
import ListItem from "@mui/material/ListItem"
import ListItemText from "@mui/material/ListItemText"
import ListSubheader from "@mui/material/ListSubheader"
import useJsonSchemasWithStatics from "@/hooks/useJsonSchemasWithStatics"
import useRiskFiles from "@/hooks/useRiskFiles"
import useStaticFiles from "@/hooks/useStaticFiles"
import useUploadedFiles from "@/hooks/useUploadedFiles"
import _ from "lodash"
import React, { useEffect, useMemo, useState } from "react"
import { DragDropContext } from "react-beautiful-dnd"
import { useController, useFormContext } from "react-hook-form"
import styled from "styled-components"
import LinkChip from "utils/components/links/LinkChip"
import useGetLinkDetails from "utils/components/links/useGetLinkDetails"
import { PrimaryOutlinedButton } from "utils/components/style/button"
import { Flex } from "utils/components/style/flex"
import VerticalAutocomplete from "utils/components/VerticalAutocomplete"
import SuperDraggable from "utils/dnd/SuperDraggable"
import SuperDroppable from "utils/dnd/SuperDroppable"
import { IActionLink, LinkType } from "utils/types/IFiche"
import FLinksModal from "./FLinksModal"
import pcsDynamicFiles from "../../../../isoMorphicData/pcsDynamicFiles/pcsDynamicFiles.json"
import picsDynamicFiles from "../../../../isoMorphicData/pcsDynamicFiles/picsDynamicFiles.json"
import useIsInterco from "@/hooks/useIsInterco"

const SListSubheader = styled(ListSubheader)`
    padding-top: 10px !important;
    padding-bottom: 10px !important;
    line-height: initial !important;
    top: -8px !important;
`

const SListItem = styled(ListItem)`
    padding-left: 22px !important;
    padding-top: 2px !important;
    padding-bottom: 2px !important;
`

const SListItemIcon = styled(ListItemIcon)`
    flex-grow: 0 !important;
    flex-shrink: 0 !important;
    min-width: 38px !important;
`

const ChipsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    max-width: 100%;
`

const ChipWrapper = styled.div`
    max-width: 100%;
`

const linkTypeLabels = {
	[LinkType.RISK_FILE]: "Fiches pédagogiques",
	[LinkType.STATIC_FILE]: "Fiches procédure",
	[LinkType.DYNAMIC_FILE]: "Fiches procédure",
	[LinkType.JSON_SCHEMA]: "Formulaires de données",
	[LinkType.UPLOADED_IMAGE]: "Images et autre",
	[LinkType.UPLOADED_CARTO]: "Cartographies",
	[LinkType.UPLOADED_CONVENTION]: "Conventions",
	[LinkType.UPLOADED_EDITABLE]: "Documents éditables",
}

function FLinks({ name, label, disabled = false }) {
	const { control } = useFormContext()

	const {
		field: { value, onChange },
		fieldState: { error },
	} = useController({
		name,
		control,
		rules: { required: true },
		defaultValue: [],
	})

	const [isOpen, setIsOpen] = useState(false)

	const jsonSchemasWithStatics = useJsonSchemasWithStatics()
	const uploadedFiles = useUploadedFiles()
	const riskFiles = useRiskFiles()
	const staticFiles = useStaticFiles({ withUploaded: true, withDynamics: true })
	const isInterco = useIsInterco()

	const getGroup = (option: IActionLink) => {
		return linkTypeLabels[option.type]
	}

	const getLinkDetails = useGetLinkDetails()

	const options = useMemo(() => {
		return [
			...Object.values(jsonSchemasWithStatics).map((jsonSchema) => ({
				type: LinkType.JSON_SCHEMA,
				id: jsonSchema.id,
			})),
			...Object.values(uploadedFiles)
				.filter((file) => file.config.category === "IMAGE")
				.map((file) => ({
					type: LinkType.UPLOADED_IMAGE,
					id: file.id,
				})),
			...Object.values(uploadedFiles)
				.filter((file) => file.config.category === "CONVENTION")
				.map((file) => ({
					type: LinkType.UPLOADED_CONVENTION,
					id: file.id,
				})),

			...Object.values(uploadedFiles)
				.filter((file) => file.config.category === "CARTO")
				.map((file) => ({
					type: LinkType.UPLOADED_CARTO,
					id: file.id,
				})),

			...Object.values(uploadedFiles)
				.filter((file) => file.config.category === "EDITABLE")
				.map((file) => ({
					type: LinkType.UPLOADED_EDITABLE,
					id: file.id,
				})),
			...Object.keys(riskFiles).map((id) => ({
				type: LinkType.RISK_FILE,
				id,
			})),
			...Object.keys(staticFiles).map((id) => ({
				type: isInterco
					? !!picsDynamicFiles[id]
						? LinkType.DYNAMIC_FILE
						: LinkType.STATIC_FILE
					: !!pcsDynamicFiles[id]
					? LinkType.DYNAMIC_FILE
					: LinkType.STATIC_FILE,
				id,
			})),
		]
	}, [jsonSchemasWithStatics, uploadedFiles, riskFiles, staticFiles])

	const onDragEnd = (result) => {
		if (!result.destination) return

		const reordered = [...value]
		const [removed] = reordered.splice(result.source.index, 1)
		reordered.splice(result.destination.index, 0, removed)

		onChange(reordered)
	}

	useEffect(() => {
		if (disabled) setIsOpen(false)
	}, [disabled])

	return (
		<>
			<VerticalAutocomplete
				disabled={disabled}
				getOptionDisabled={(option) =>
					Boolean(value.find((selected) => _.isEqual(selected, option)))
				}
				disableClearable
				multiple
				freeSolo
				fullWidth
				value={(value ?? []).filter((item: IActionLink) =>
					Boolean(getLinkDetails(item)),
				)}
				groupBy={(option: IActionLink) => getGroup(option)}
				options={options}
				onChange={(event, newValue) => onChange(newValue)}
				getOptionLabel={(option: IActionLink) => getLinkDetails(option).label}
				renderInput={(params) => (
					<TextField
						{...params}
						placeholder="Recherche rapide"
						label={label}
						error={Boolean(error?.message)}
						helperText={error?.message}
						InputProps={{
							...params.InputProps,
							endAdornment: (
								<React.Fragment>
									{params.InputProps.endAdornment}
									<PrimaryOutlinedButton
										size="small"
										fullWidth
										onClick={() => {
											setIsOpen(true)
										}}
									>
										Ajouter un lien
									</PrimaryOutlinedButton>
								</React.Fragment>
							),
						}}
					/>
				)}
				renderTags={(value, getTagProps) => (
					<DragDropContext onDragEnd={onDragEnd}>
						<SuperDroppable component={ChipsWrapper}>
							{value.map((option: IActionLink, index) => (
								<SuperDraggable
									key={JSON.stringify(option)}
									id={JSON.stringify(option)}
									index={index}
									component={ChipWrapper}
								>
									<LinkChip
										draggable
										link={option}
										{...getTagProps({ index })}
										{...{ onClick: undefined }}
									/>
								</SuperDraggable>
							))}
						</SuperDroppable>
					</DragDropContext>
				)}
				renderOption={(props, option: IActionLink) => {
					const { label, Icon } = getLinkDetails(option)
					return (
						<SListItem {...props} key={option.id}>
							{Icon && <SListItemIcon>{Icon}</SListItemIcon>}
							<ListItemText primary={label} />
						</SListItem>
					)
				}}
				renderGroup={(params) => {
					return (
						<>
							<SListSubheader>
								<Flex gap="1rem">{params.group}</Flex>
							</SListSubheader>
							{params.children}
						</>
					)
				}}
			/>

			<FLinksModal
				open={isOpen}
				onClose={() => setIsOpen(false)}
				value={value}
				setValue={onChange}
			/>
		</>
	)
}

export default FLinks
