import { toast } from "react-toastify"

const forceDownloadURL = (url, filename) => {
	fetch(url, {
		method: "GET",
		headers: new Headers({
			Origin: location.origin,
		}),
	})
		.then((response) => response.blob())
		.then((blob) => {
			const downloadable = URL.createObjectURL(blob)
			const a = document.createElement("a")
			a.style.display = "none"
			document.body.appendChild(a)
			a.setAttribute("download", filename)
			a.setAttribute("href", downloadable)
			a.click()
			document.body.removeChild(a)
		})
		.catch(() => toast.error("Téléchargement impossible"))
}

export default forceDownloadURL
