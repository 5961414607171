import ModalBody from "@/styles/organisms/Modal/ModalBody"
import ModalFooter from "@/styles/organisms/Modal/ModalFooter"
import { yupResolver } from "@hookform/resolvers/yup"
import { ModalContent } from "@nextui-org/react"
import { EnumToolPath } from "@/hooks/services/useAccessRightsServices"
import useAccessRights from "@/hooks/useAccessRights"
import useSelectedEvent from "@/hooks/useSelectedEvent"
import {
	createPointWithUpdateEvent,
	updatePointWithUpdateEvent,
} from "@/redux-toolkit/data/points/resources"
import { TEMPORARY_CARE } from "@/redux-toolkit/userSettings/constants"
import Debug from "debug"
import Moment from "moment"
import React, { useEffect } from "react"
import { FormProvider, useForm } from "react-hook-form"
import { useDispatch } from "react-redux"
import {
	PrimaryButton,
	PrimaryTextButton,
} from "@/utils//components/style/button"
import { Flex } from "@/utils//components/style/flex"
import FGeoloc from "@/utils//form/FGeoloc"
import FPhone from "@/utils//form/FPhone"
import FSelectHumans from "@/utils//form/FSelectHumans"
import FText from "@/utils//form/FText"
import Yup from "@/utils//yup"
import Button from "@/styles/atoms/Button"
// app:javascript:components:maincourante:care:form:FormTmpCare.tsx
const debug = Debug(
	"app:javascript:components:maincourante:care:form:FormTmpCare",
)
debug.log = console.log.bind(console)

const DEFAULT_VALUES = {
	geojson: {
		properties: {
			Lieu: "",
			Responsable: [],
			Téléphones: [],
			Capacité: 0,
			Adresse: undefined,
		},
	},
}

const schema = Yup.object().shape({
	geojson: Yup.object().shape({
		properties: Yup.object().shape({
			Lieu: Yup.string().required("Le lieu est obligatoire"),
			Responsable: Yup.array(),
			Téléphones: Yup.array(),
			Capacité: Yup.number().required("La capacité est obligatoire"),
			Adresse: Yup.object().required("L'adresse est obligatoire"),
			Commentaire: Yup.string(),
			Mail: Yup.string(),
		}),
	}),
})

const FormTmpCare = ({
	currentTmpCare = {},
	onClose,
	setCurrentTmpCare = (_value) => {},
}: {
	currentTmpCare?: any
	onClose: any
	setCurrentTmpCare?: any
}) => {
	const methods = useForm({
		defaultValues: {
			...DEFAULT_VALUES,
			...currentTmpCare,
		},
		resolver: yupResolver(schema),
	})

	const dispatch = useDispatch()
	const selectedEvent = useSelectedEvent()
	const permissions = useAccessRights(EnumToolPath.CARE)
	const isLocked = selectedEvent?.locked

	useEffect(() => {
		//methods.reset({ ...DEFAULT_VALUES, ...currentTmpCare })
	}, [currentTmpCare])

	const onSubmit = (data) => {
		if (!currentTmpCare?.id) {
			dispatch(
				createPointWithUpdateEvent({
					point: {
						jsonschema_id: TEMPORARY_CARE,
						geojson: {
							properties: {
								...data.geojson.properties,
							},
						},
						event_id: selectedEvent?.id,
					},
					event: {
						...selectedEvent,
						history: [
							...selectedEvent?.history,
							{
								type: TEMPORARY_CARE,
								title: "Ouverture centre d'accueil",
								date: Moment().format(),
								noAction: true,
							},
						],
					},
				}),
			)
		} else {
			dispatch(
				updatePointWithUpdateEvent({
					point: {
						id: currentTmpCare?.id,
						geojson: {
							properties: {
								...data.geojson.properties,
							},
						},
						event_id: selectedEvent?.id,
					},
					event: {
						...selectedEvent,
						history: [
							...selectedEvent?.history,
							{
								type: TEMPORARY_CARE,
								title: "Modification centre d'accueil",
								date: Moment().format(),
								noAction: true,
							},
						],
					},
				}),
			)
		}
		setCurrentTmpCare(undefined)
		onClose()
	}

	return (
		<FormProvider {...methods}>
			<ModalBody>
				<Flex gap="1rem" directionColumn>
					<FText
						name="geojson.properties.Lieu"
						label="Lieu"
						disabled={!permissions.write || isLocked}
					/>
					<FPhone
						name="geojson.properties.Téléphones"
						label="Téléphones"
						disabled={!permissions.write || isLocked}
					/>
					<FSelectHumans
						name="geojson.properties.Responsable"
						label="Responsable"
						disabled={!permissions.write || isLocked}
					/>
					<FText
						name="geojson.properties.Capacité"
						label="Capacité"
						disabled={!permissions.write || isLocked}
					/>
					<FGeoloc
						name="geojson.properties.Adresse"
						label="Adresse"
						disabled={!permissions.write || isLocked}
					/>
					<FText
						name="geojson.properties.Commentaire"
						label="Commentaire"
						multiline
						disabled={!permissions.write || isLocked}
					/>
					<FText
						name="geojson.properties.Mail"
						label="Mail"
						disabled={!permissions.write || isLocked}
					/>
				</Flex>
			</ModalBody>
			<ModalFooter>
				<Button
					color="primary"
					variant="light"
					onClick={() => {
						onClose()
						setCurrentTmpCare(undefined)
					}}
				>
					Annuler
				</Button>
				<Button
					color="primary"
					onClick={methods.handleSubmit(onSubmit)}
					disabled={isLocked || !permissions.write}
				>
					Valider
				</Button>
			</ModalFooter>
		</FormProvider>
	)
}

export default FormTmpCare
