import React from 'react'
import usePoints from '@/hooks/usePoints'
import { useParams } from 'react-router-dom'
import ArchiveTableSupport from './ArchiveTableSupport'
import TableSupport from './TableSupport'

const TableSelectorSupport = () => {
    const points = usePoints()
    const { facilityId: facilityIdParams } = useParams<{
        facilityId: string
    }>()
    const facilityId = facilityIdParams === "undefined" ? undefined : facilityIdParams
    const facility = Object.values(points).find((point) => point.id === facilityId)
    const isArchive = !!facility.geojson.properties.ended_at

    return isArchive
        ? <ArchiveTableSupport />
        : <TableSupport />
}

export default TableSelectorSupport