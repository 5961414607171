import React from "react"
import PImage from "utils/pdf/image/PImage"
import _ from "lodash"
import { View as ViewPDF } from "@react-pdf/renderer"
import BoldText from "utils/pdf/BoldText"
import ReportService from "@/pages/carto2/cartographie/service/ReportService"

const View = ViewPDF as any

const PointLinkedFilePdf = ({
	fileBlobs,
	pointPdfArray = undefined,
	mustBreak = true,
}) => {
	return (
		<>
			{/* Looping on each pdf */}
			{fileBlobs.map((element, index) => {
				let arrayOfBlobObject = element
				if (!_.isArray(element)) {
					arrayOfBlobObject = [element]
				}
				//* boldText can be linked file comment date, or message if he've empty comment
				const linkedFileTitle =
					pointPdfArray?.[index]?.title?.length > 0
						? `Titre : ${pointPdfArray?.[index]?.title}`
						: "Pièce jointe sans titre."

				return (
					<>
						<View
							break={mustBreak}
							style={{
								position: "relative",
							}}
						>
							{pointPdfArray && (
								//* Display file comment only if is an event's linked file and on the first page
								<View
									style={{
										position: "absolute",
										marginTop: -16,
									}}
								>
									<BoldText>{linkedFileTitle}</BoldText>
								</View>
							)}
							{/* Looping on each pdf's pages */}
							{arrayOfBlobObject.map((object) => {
								const isArrete = !object.height && !object.width
								const isPortrait = object.height > object.width || isArrete

								const imageWidth = isPortrait ? "550pt" : "778pt"
								const imageHeight = isPortrait ? "778pt" : "550pt"

								return (
									<View
										key={object.blob}
										style={{
											marginLeft: -10,
											alignItems: "center",
											justifyContent: "center",
											width: "550pt",
											height: "778pt",
										}}
									>
										<PImage
											src={{
												uri: object.blob,
												cache: false,
												method: "GET",
												headers: {
													"Cache-Control": "no-cache",
													"Allow-Control-Allow-Origin": "*",
												},
											}}
											alt="[Échec du chargement du fichier téléversé]"
											width={imageWidth}
											height={imageHeight}
											style={{
												maxWidth: imageWidth,
												maxHeight: imageHeight,
												transform: `rotate(${isPortrait ? "0deg" : "-90deg"})`,
											}}
										/>
									</View>
								)
							})}
						</View>
					</>
				)
			})}
			{mustBreak && <View break />}
		</>
	)
}

export default PointLinkedFilePdf
