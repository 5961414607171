import { createTheme, ThemeProvider } from "@mui/material/styles"
import Axios from "axios"
import { createBrowserHistory } from "history"
import React, { createContext, useEffect } from "react"
import { Provider } from "react-redux"
import { Redirect, Route, Router, Switch } from "react-router-dom"
import { ToastContainer } from "react-toastify"
import { PersistGate } from "redux-persist/integration/react"
import styled from "styled-components"
import AppContainer from "@/utils/components/AppContainer"
import Modals from "@/utils/modals/Modals"
import Panels from "@/utils/panels/Panels"
import NumeriskTopBar from "./app/NumeriskTopBar"
import Accessibility from "./configuration/Accessibility"
import Configuration from "./configuration/Configuration"
import Cooperation from "./cooperation/Cooperation"
import Customdata from "./customdata/Customdata"
import Home from "./home/Home"
import Daybook from "./maincourante/Daybook"
import { persistor, store } from "../redux-toolkit/store"
import Rgpd from "./rgpd/Rgpd"
import AxiosService from "../services/AxiosService"
import SynchronisationWrapper from "./loading/SynchronisationWrapper"
import Download from "./telechargement/Download"
import Moment from "moment"
import { NextUIProvider } from "@nextui-org/react"
import { frFR } from "@mui/x-date-pickers"
import SideBar from "./app/SideBar"

const csrfToken = document
	.querySelector("meta[name=csrf-token]")
	.getAttribute("content")
console.log("csrfToken", csrfToken)
Axios.defaults.headers.common["X-CSRF-Token"] = csrfToken
const accessToken = localStorage.getItem("access-token")
if (accessToken) {
	Axios.defaults.headers.common["access-token"] = accessToken
}
const AppliWrapper = styled.div`
    height: calc(100% - var(--height-top-bar));
    width: 100%;
    margin-top: var(--height-top-bar) !important;
    min-width: 0;
    flex-basis: 0;
    flex: 1;
`

Moment.locale("fr")

const theme = createTheme({}, frFR)

const history = createBrowserHistory()
const App = ({ env }) => {
	useEffect(() => {
		if (location.protocol !== "https:") {
			if (env !== "development") {
				location.replace(
					`https:${location.href.substring(location.protocol.length)}`,
				)
			}
		}
		try {
			caches.open(":sw-cache-v6").then((cache) => {
				cache.addAll(["/home"])
			})
		} finally {
			console.log("")
		}
	}, [])
	useEffect(() => {
		AxiosService.initializeInterceptors()
	}, [])

	return (
		<ThemeProvider theme={theme}>
			<NextUIProvider>
				<Provider store={store}>
					{/* @ts-ignore */}
					<PersistGate persistor={persistor}>
						<Router history={history}>
							<SynchronisationWrapper>
								<AppContainer>
									{/** toast container for react toastify */}
									<ToastContainer
										position="bottom-left"
										newestOnTop
										autoClose={8000}
										pauseOnFocusLoss={false}
										theme="colored"
									/>
									{/** Modals to interact with Numerisk items in every component */}
									<Modals />
									<NumeriskTopBar />
									<SideBar />
									<AppliWrapper>
										<Switch>
											<Route path="/home">
												<Home />
											</Route>
											<Route path="/maincourante">
												<Daybook />
											</Route>
											<Route path="/cooperation">
												<Cooperation />
											</Route>
											<Route path="/customdata">
												<Customdata />
											</Route>
											<Route path="/configuration">
												<Configuration />
											</Route>
											<Route path="/download">
												<Download />
											</Route>
											<Route path="/rgpd">
												<Rgpd />
											</Route>
											<Route path="/accessibility">
												<Accessibility />
											</Route>
											<Redirect to="/home" />
										</Switch>
									</AppliWrapper>
									<Panels />
								</AppContainer>
							</SynchronisationWrapper>
						</Router>
					</PersistGate>
				</Provider>
			</NextUIProvider>
		</ThemeProvider>
	)
}

export default App
