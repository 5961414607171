import Button from "@mui/material/Button"
import ButtonGroup from "@mui/material/ButtonGroup"
import Popover from "@mui/material/Popover"
import Typography from "@mui/material/Typography"
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft"
import ArrowRightIcon from "@mui/icons-material/ArrowRight"
import ImageIcon from "@mui/icons-material/Image"
import PhotoSizeSelectLargeIcon from "@mui/icons-material/PhotoSizeSelectLarge"
import classNames from "classnames"
import { EditorState } from "draft-js"
import React, { useContext, useState } from "react"
import { EditorContext } from "../HTMLEditor"
import ResizeImageDialog from "./subcomponents/ResizeImageDialog"

const EditorImage = ({ contentState, block }) => {
	if (!block.getEntityAt(0)) return null
	const entity = contentState.getEntity(block.getEntityAt(0))
	const type = entity.getType()

	if (type !== "IMAGE") return null

	const {
		src,
		width = "auto",
		height = "auto",
		alignment = null,
	} = entity.getData()

	const editor = useContext(EditorContext)

	const [anchorEl, setAnchorEl] = useState(null)
	const [infoAnchorEl, setInfoAnchorEl] = useState(null)

	const showOptions = (ev) => {
		setAnchorEl(ev.currentTarget)
		setInfoAnchorEl(ev.currentTarget)
		ev.preventDefault()
		ev.stopPropagation()
		editor.setReadOnly(true)
	}

	const hideOptions = () => {
		setAnchorEl(null)
		setInfoAnchorEl(null)
		editor.setReadOnly(false)
	}

	const forceEditorUpdate = () => {
		const content = editor.editorState.getCurrentContent()
		const newEditorState = EditorState.createWithContent(content)
		editor.setEditorState(newEditorState)
	}

	const imageAlign = (ev, alignment) => {
		ev.preventDefault()
		ev.stopPropagation()

		const entityKey = block.getEntityAt(0)
		contentState.mergeEntityData(entityKey, { alignment })
		editor.setEditorState(
			EditorState.push(editor.editorState, contentState, "change-block-data"),
		)

		forceEditorUpdate()
	}

	const [modal, setModal] = useState(false)

	const resize = (width, height) => {
		const entityKey = block.getEntityAt(0)
		contentState.mergeEntityData(entityKey, {
			height: height ? height + "px" : "auto",
			width: width ? width + "px" : "auto",
		})
		editor.setEditorState(
			EditorState.push(editor.editorState, contentState, "change-block-data"),
		)

		setModal(false)
		forceEditorUpdate()
	}

	if (!src) return null

	const correctedHeight = height.replace("px", "")
	const correctedWidth = width.replace("px", "")

	return (
		<>
			<span
				className={classNames("rdw-image-alignment", {
					"rdw-image-left": alignment === "left",
					"rdw-image-right": alignment === "right",
					"rdw-image-center": !alignment || alignment === "none",
				})}
			>
				<span className="rdw-image-imagewrapper">
					{" "}
					<img
						src={src}
						style={{
							height,
							width,
						}}
						onClick={showOptions}
					/>
					<Popover
						open={Boolean(infoAnchorEl)}
						onClose={hideOptions}
						anchorEl={infoAnchorEl}
						anchorOrigin={{
							vertical: "top",
							horizontal: "right",
						}}
						transformOrigin={{
							vertical: "bottom",
							horizontal: "right",
						}}
					>
						<Typography color="textSecondary" variant="body1">
							{correctedWidth}&nbsp;x&nbsp;{correctedHeight}
						</Typography>
					</Popover>
					<Popover
						style={{
							lineHeight: 0,
						}}
						open={Boolean(anchorEl)}
						onClose={hideOptions}
						anchorEl={anchorEl}
						anchorOrigin={{
							vertical: "bottom",
							horizontal: "center",
						}}
						transformOrigin={{
							vertical: "top",
							horizontal: "center",
						}}
					>
						<ButtonGroup size="small">
							<Button
								onClick={(ev) => imageAlign(ev, "left")}
								title={"Aligner à gauche"}
							>
								<ArrowLeftIcon />
								<ImageIcon />
							</Button>
							<Button
								onClick={(ev) => imageAlign(ev, "none")}
								title={"Aligner au centre"}
							>
								<ArrowLeftIcon />
								<ImageIcon />
								<ArrowRightIcon />
							</Button>
							<Button
								onClick={(ev) => imageAlign(ev, "right")}
								title={"Aligner à droite"}
							>
								<ImageIcon />
								<ArrowRightIcon />
							</Button>
							<Button onClick={() => setModal(true)} title={"Redimensionner"}>
								<PhotoSizeSelectLargeIcon />
							</Button>
						</ButtonGroup>
					</Popover>
				</span>
			</span>

			<ResizeImageDialog
				open={modal}
				onClose={() => setModal(false)}
				src={src}
				originalWidth={correctedWidth}
				originalHeight={correctedHeight}
				onSave={resize}
			/>
		</>
	)
}

const imageRendererFn = (block) => {
	if (block.getType() === "atomic") {
		return {
			component: EditorImage,
			editable: true,
		}
	}
}

export default imageRendererFn
