import { useMemo } from "react"
import usePdfGenerators from "../usePdfGenerators"
import formatNfdLowerCase from "utils/formatNfdLowerCase"

const useSearchPdfGenerators = (words: string[]) => {
	const pdfGenerators = usePdfGenerators()
	const searchPdfGenerators = useMemo(() => {
		const matchPdfGenerators = Object.values(pdfGenerators).filter(
			(pdfGenerator: any) => {
				return words.every((word) => {
					const pdfGeneratorTitle = formatNfdLowerCase(pdfGenerator.title)
					return pdfGeneratorTitle.includes(word)
				})
			},
		)
		return matchPdfGenerators
	}, [words, pdfGenerators])

	return searchPdfGenerators
}

export default useSearchPdfGenerators
