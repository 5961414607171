import React, { useState, useEffect } from "react"
import Moment from "moment"
import styled from "styled-components"
import DoneIcon from "@mui/icons-material/Done"
import ErrorIcon from "@mui/icons-material/Error"
import WarningIcon from "@mui/icons-material/Warning"
import { CardContent } from "@mui/material"
import { Icon } from "@iconify/react"
import { GREEN_STATUS, ORANGE_STATUS, RED_STATUS } from "./NumeriskIndicators"
import CustomTooltip from "utils/components/style/CustomTooltip"

const StyledDoneIcon = styled(DoneIcon)`
    color: var(--green) !important;
`
const StyledErrorIcon = styled(ErrorIcon)`
    color: var(--red) !important;
`
const StyledWarningIcon = styled(WarningIcon)`
    color: var(--orange) !important;
`
const SpaceItems = styled(CardContent)`
    display: flex;
    justify-content: space-between;
`
const StyledContent = styled.div`
    display: flex;
`
const CenterIcon = styled.div`
    display: flex;
    align-items: center;
    padding-right: 8px;
`
const DataStatusIcon = styled(CenterIcon)`
    padding: 0;
`
const DataTitle = styled.div`
    font-size: 15px;
    padding: 0 10px;
`

const DataStatus = ({ dataType, imgName, dataTitle }) => {
	const [dataState, setDataState] = useState(undefined)

	useEffect(() => {
		let tempDataState = GREEN_STATUS
		dataType.forEach((data: any) => {
			const now = Moment()
			const stockDate = Moment(data.updated_at)
			now.subtract(12, "months")
			if (stockDate.isAfter(now)) {
				tempDataState = GREEN_STATUS
				return
			}
			now.subtract(24, "months")
			if (stockDate.isAfter(now) && tempDataState !== RED_STATUS) {
				tempDataState = ORANGE_STATUS
				return
			}
			tempDataState = RED_STATUS
		})
		setDataState(tempDataState)
	}, [dataType])

	return (
		<>
			<SpaceItems>
				<StyledContent>
					<Icon icon={imgName} width={20} height={20} />
					<DataTitle>{dataTitle}</DataTitle>
				</StyledContent>
				<DataStatusIcon>
					{dataState === GREEN_STATUS && (
						<CustomTooltip title="À jour">
							<StyledDoneIcon />
						</CustomTooltip>
					)}
					{dataState === ORANGE_STATUS && (
						<CustomTooltip title="À vérifier">
							<StyledWarningIcon />
						</CustomTooltip>
					)}
					{dataState === RED_STATUS && (
						<CustomTooltip title="Mettre à jour">
							<StyledErrorIcon />
						</CustomTooltip>
					)}
				</DataStatusIcon>
			</SpaceItems>
		</>
	)
}

export default DataStatus
