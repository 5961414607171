import { useMemo } from "react"
import formatNfdLowerCase from "utils/formatNfdLowerCase"
import useUploadedFiles from "../useUploadedFiles"

const useSearchUploadedFiles = (words: string[]) => {
	const uploadedFiles = useUploadedFiles()

	const searchUploadedFiles = useMemo(() => {
		const matchUploadedFiles = Object.values(uploadedFiles).filter(
			(uploadedFile) => {
				return words.every((word) => {
					const uploadedFileTitle = formatNfdLowerCase(uploadedFile.filename)
					return uploadedFileTitle.includes(word)
				})
			},
		)
		return matchUploadedFiles
	}, [words, uploadedFiles])

	return searchUploadedFiles
}

export default useSearchUploadedFiles
