import { Paper } from "@mui/material"
import useFiches from "@/hooks/useFiches"
import useSelectedEvent from "@/hooks/useSelectedEvent"
import React, { useMemo, useState } from "react"
import { Flex } from "@/utils/components/style/flex"
import FCellules from "@/utils/form/FCellules"
import IFiche, { VigilanceLabel } from "@/utils/types/IFiche"
import Filter from "../following/filter/Filter"
import useFilterForm, {
	IFilterFormOptions,
} from "../following/filter/useFilterForm"
import CategoryCheck from "./CategoryCheck"
import styled from "styled-components"
import { PrimaryTextButton } from "@/utils/components/style/button"
import Defer from "@/utils/Defer"

const Spacer = styled.div`
    height: 1rem;
`
const SPaper = styled(Paper)`
    padding: 1rem;
    box-shadow: var(--shadow-scroll) !important;
`
const Sticky = styled.div`
    position: sticky;
    top: 0;
    z-index: 49;
`
const DaybookFiche = () => {
	const fiches = useFiches()
	const selectedEvent = useSelectedEvent()
	const [expanded, setExpanded] = useState<string[]>([])

	const filterOptions = useMemo(() => {
		return [
			{
				name: "Cellules",
				component: FCellules,
				default: [],
			},
			{
				name: "Vigilances",
				items: ["current"],
				renderItem: () =>
					`${VigilanceLabel[selectedEvent.vigilance]} et inférieur`,
				default: "current",
				noFilterLabel: "Toutes",
				persistent: "DaybookFiche:Vigilances",
			},
		] as IFilterFormOptions
	}, [])

	const { values, ...filterConfig } = useFilterForm({
		options: filterOptions,
		data: Object.values(fiches),
	})

	if (!selectedEvent) {
		return null
	}
	const currentFiches: IFiche[] = (selectedEvent?.scenarioIds ?? []).map(
		(id) => fiches[id],
	)

	return (
		<>
			<Sticky>
				<SPaper>
					<Flex fullWidth spaceBetween gap="0.5rem">
						<div>{currentFiches[0]?.nom}</div>
						<div>
							<PrimaryTextButton
								onClick={() => {
									setExpanded(
										(currentFiches[0]?.actionsByCategory ?? []).map(
											(category) => category.categoryName,
										),
									)
								}}
							>
								Tout ouvrir
							</PrimaryTextButton>
							<PrimaryTextButton
								onClick={() => {
									setExpanded([])
								}}
							>
								Tout fermer
							</PrimaryTextButton>
							<Filter {...filterConfig} />
						</div>
					</Flex>
				</SPaper>
			</Sticky>
			<Spacer />
			<Defer>
				{(currentFiches[0]?.actionsByCategory ?? []).map((category) => (
					<CategoryCheck
						expanded={expanded}
						setExpanded={setExpanded}
						key={category.id}
						category={category}
						currentFiche={currentFiches[0]}
						filterVigilance={values["Vigilances"]}
						selectedEvent={selectedEvent}
						authorizedCells={values["Cellules"]}
					/>
				))}
			</Defer>
		</>
	)
}

export default DaybookFiche
