import { Paper } from "@mui/material"
import useJsonSchemasWithStatics from "@/hooks/useJsonSchemasWithStatics"
import useMappings from "@/hooks/useMappings"
import React, { useMemo, useState } from "react"
import { useParams } from "react-router-dom"
import styled from "styled-components"
import { Flex } from "utils/components/style/flex"
import TitleHeader from "utils/components/TitleHeader"
import getImage from "@/utils/getImage"
import AutoErrorSolver from "./components/AutoErrorSolver"
import ConflictsSolver from "./components/ConflictsSolver"
import CsvLoader from "./components/CsvLoader"
import DiffDisplay from "./components/DiffDisplay"
import ErrorsSolver from "./components/ErrorsSolver"
import MappingRunner from "./components/MappingRunner"
import UnicityErrorSolver from "./components/UnicityErrorSolver"

export const SPaper = styled(Paper)`
    padding: 10px;
`

const Import = () => {
	const { id } = useParams<{ id: string }>()
	const jsonSchemas = useJsonSchemasWithStatics()
	const [currentStep, setCurrentStep] = useState(0)
	const [csvData, setCsvData] = useState(undefined)
	const [mappingResults, setMappingResults] = useState(undefined)
	const mappings = useMappings()

	const jsonSchema = useMemo(() => jsonSchemas[id], [id])
	const mapping = useMemo(
		() =>
			Object.values(mappings).find((mapping) => mapping.jsonschema_id === id),
		[id],
	)

	return (
		<Flex alignItemsStretch directionColumn gap={10}>
			<div>
				<TitleHeader
					title={
						<Flex gap={10}>
							<img width={50} src={getImage(jsonSchema?.imgId)} />
							Import : {jsonSchema?.title ?? "Mes Formulaires"}
						</Flex>
					}
					backArrowUrl={`/customdata/my_forms/${id}`}
					backArrowTitle="Quitter l'import"
				/>
			</div>

			{
				{
					0: (
						<CsvLoader
							mapping={mapping}
							setCsvData={setCsvData}
							setCurrentStep={setCurrentStep}
							currentStep={currentStep}
						/>
					),
					1: (
						<UnicityErrorSolver
							mappingSchema={mapping.schema}
							csvData={csvData}
							setCsvData={setCsvData}
							setCurrentStep={setCurrentStep}
							currentStep={currentStep}
							jsonSchema={jsonSchema}
						/>
					),
					2: (
						<MappingRunner
							csvData={csvData}
							mappingSchema={mapping.schema}
							setMappingResults={setMappingResults}
							setCurrentStep={setCurrentStep}
							currentStep={currentStep}
							jsonSchema={jsonSchema}
						/>
					),
					// currentStep === 3 used to do only one effect with useEffectOnce and correct mappingResults to modify
					3: currentStep === 3 && (
						<AutoErrorSolver
							mapping={mapping}
							mappingResults={mappingResults}
							jsonSchema={jsonSchema}
							setMappingResults={setMappingResults}
							setCurrentStep={setCurrentStep}
							currentStep={currentStep}
						/>
					),
					4: (
						<ErrorsSolver
							mappingResults={mappingResults}
							setMappingResults={setMappingResults}
							setCurrentStep={setCurrentStep}
							currentStep={currentStep}
							jsonSchema={jsonSchema}
						/>
					),
					5: (
						<ConflictsSolver
							mapping={mapping}
							mappingResults={mappingResults}
							setMappingResults={setMappingResults}
							setCurrentStep={setCurrentStep}
							currentStep={currentStep}
							jsonSchema={jsonSchema}
						/>
					),
					6: (
						<DiffDisplay
							mappingResults={mappingResults}
							mapping={mapping}
							jsonSchema={jsonSchema}
						/>
					),
				}[currentStep]
			}
		</Flex>
	)
}

export default Import
