import React, { useState, useMemo } from "react"
import { useDispatch } from "react-redux"
import Debug from "debug"
import Moment from "moment"
import { useParams } from "react-router-dom"
import ModalDaybookLeavingGroup, {
	LEAVING_FAMILY,
} from "./leaving/ModalDaybookLeavingGroup"
import SuperReactTable from "utils/components/tables/SuperReactTable/SuperReactTable"
import { width180, widthCustom } from "utils/components/tables/widthProps"
import usePoints from "@/hooks/usePoints"
import { Flex } from "utils/components/style/flex"
import { Chip } from "@mui/material"
import useSelectedEvent from "@/hooks/useSelectedEvent"
import useAccessRights from "@/hooks/useAccessRights"
import { PrimaryButton } from "utils/components/style/button"
import { PATH_MAIN_COURANTE_CARE } from "@/redux-toolkit/userSettings/constants"
import { sortTypeDateFactory } from "utils/sort"
import { deletePointWithUpdateEvent } from "@/redux-toolkit/data/points/resources"
import styled, { css } from "styled-components"
import { isMobile } from "react-device-detect"
import { ColumnDef } from "@tanstack/react-table"
import { EnumToolPath } from "@/hooks/services/useAccessRightsServices"
import Button from "@/styles/atoms/Button"

// app:javascript:components:maincourante:care:FamilyLeavingTable.tsx
const debug = Debug(
	"app:javascript:components:maincourante:care:FamilyLeavingTable",
)
debug.log = console.log.bind(console)

const SFlex = styled(Flex)<{ mobileWidth: string }>`
    ${({ mobileWidth }) =>
			css`
            max-width: ${isMobile ? mobileWidth : null};
        `}
    justify-content: ${isMobile ? "flex-end" : null};
`

const FamilyLeavingTable = () => {
	const points = usePoints()
	const [isOpenModalLeavingGroup, setIsOpenModalLeavingGroup] = useState(false)
	const dispatch = useDispatch()
	const [leavingGroupId, setLeavingGroupId] = useState(undefined)
	const selectedEvent = useSelectedEvent()
	const permissions = useAccessRights(EnumToolPath.CARE)
	const { careId } = useParams<{ careId: string }>()

	const isLocked = selectedEvent?.locked

	const concernedPointsLeaving = Object.values(points).filter(
		(point) =>
			point.geojson?.properties?.["careId"] === careId &&
			point.event_id === selectedEvent?.id &&
			point.jsonschema_id === LEAVING_FAMILY,
	)

	const columnsLeaving = useMemo(() => {
		return [
			{
				header: "Sortie",
				accessorKey: "geojson.properties.Date de sortie",
				cell: ({ getValue }) => {
					const value = getValue()
					if (!value) {
						return <span>Heure non définie</span>
					}
					return <span>{Moment(value).format("DD/MM HH:mm")}</span>
				},
				sortType: sortTypeDateFactory("geojson.properties.Date de sortie"),
				...width180,
			},
			{
				header: "Membres",
				accessorKey: "geojson.properties.Points associés",
				cell: ({ getValue }) => {
					const value = (getValue() ?? []) as any[]
					const identifiants = []
					value.forEach((valueElement = {}) => {
						if (valueElement["Index du membre associé"] === -1) {
							console.log(points[valueElement["Identifiant du point associé"]])
							identifiants.push(
								points[valueElement["Identifiant du point associé"]]?.geojson
									?.properties?.Identité || "Personne sans nom renseigné",
							)
						} else {
							const isAnimal =
								points[valueElement["Identifiant du point associé"]]?.geojson
									?.properties?.family?.[
									valueElement["Index du membre associé"]
								]?.type === "ANIMAL"
							identifiants.push(
								points[valueElement["Identifiant du point associé"]]?.geojson
									?.properties?.["family"][
									valueElement["Index du membre associé"]
								]?.Identité ||
									(isAnimal
										? "Animal sans nom"
										: "Personne sans nom renseigné"),
							)
						}
					})

					return (
						<SFlex mobileWidth={"70vw"} $wrap gap="0.5rem">
							{identifiants.map((id) => {
								return <Chip key={id} label={id} />
							})}
						</SFlex>
					)
				},
			},
			{
				header: "Lieu de sortie",
				accessorKey: "geojson.properties.Lieu de sortie",
				cell: ({ getValue }) => {
					return (
						<SFlex mobileWidth={"70vw"} $wrap gap="0.5rem">
							{getValue()}
						</SFlex>
					)
				},
				...widthCustom(250),
			},
		] as ColumnDef<any>[]
	}, [points])

	return (
		<>
			<SuperReactTable
				data={concernedPointsLeaving}
				columns={columnsLeaving}
				isLocked={isLocked}
				writePermission={permissions.write && !isLocked}
				isEditable
				onEditClick={(original) => {
					setLeavingGroupId(original.id)
					setIsOpenModalLeavingGroup(true)
				}}
				isDeletable={permissions.write && !isLocked}
				onDeleteClick={(original) => {
					dispatch(
						deletePointWithUpdateEvent({
							point: original,
							event: {
								...selectedEvent,
								updated_at: Moment().format(),
							},
						}),
					)
				}}
				initialSortBy={[
					{
						desc: true,
						id: "geojson.properties.Date de sortie",
					},
				]}
				actionsButtons={
					<>
						{permissions.write && (
							<Button
								color="primary"
								onClick={() => {
									setLeavingGroupId(undefined)
									setIsOpenModalLeavingGroup(true)
								}}
								disabled={isLocked}
							>
								Ajouter une sortie
							</Button>
						)}
					</>
				}
			/>
			<ModalDaybookLeavingGroup
				isOpen={isOpenModalLeavingGroup}
				onClose={() => setIsOpenModalLeavingGroup(false)}
				careId={careId}
				leavingGroupId={leavingGroupId}
			/>
		</>
	)
}

export default FamilyLeavingTable
