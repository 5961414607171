import useOrderedAgents from "@/hooks/useOrderedAgents"
import useOrderedCARE from "@/hooks/useOrderedCARE"
import useOrderedElus from "@/hooks/useOrderedElus"
import useOrderedPCC from "@/hooks/useOrderedPCC"
import { StaticFormType } from "utils/services/StaticFormsService"

function useOrderConfig(jsonSchemaId) {
	const { orderedElus, setOrderedElus } = useOrderedElus()
	const { orderedAgents, setOrderedAgents } = useOrderedAgents()
	const { orderedCARE, setOrderedCARE } = useOrderedCARE()
	const { orderedPCC, setOrderedPCC } = useOrderedPCC()

	switch (jsonSchemaId) {
		case StaticFormType.ANNUAIRE_PERSONNEL_COMMUNAL:
			return {
				order: orderedAgents,
				onOrder: setOrderedAgents,
			}
		case StaticFormType.ANNUAIRES_ELUS:
			return {
				order: orderedElus,
				onOrder: setOrderedElus,
			}
		case StaticFormType.PCC:
			return {
				order: orderedPCC,
				onOrder: setOrderedPCC,
			}
		case StaticFormType.CARE:
			return {
				order: orderedCARE,
				onOrder: setOrderedCARE,
			}
		default:
			return undefined
	}
}

export default useOrderConfig
