import React, { useState, useEffect, useMemo } from 'react'
import { PrimaryTextButton } from '@/utils/components/style/button'
import ArrowBackIcon from "@mui/icons-material/ArrowBack"
import { H2 } from '@/utils/components/style/header'
import ArchiveIcon from "@mui/icons-material/Archive"
import { Flex } from '@/utils/components/style/flex'
import SuperReactTable from '@/utils/components/tables/SuperReactTable'
import TypeResponsables from '@/utils/components/jsonSchema/properties/propertiesType/TypeResponsables'
import { width100 } from '@/utils/components/tables/widthProps'
import { ColumnDef } from '@tanstack/react-table'
import usePoints from '@/hooks/usePoints'
import { useHistory, useParams } from 'react-router-dom'
import useJsonSchemasWithStatics from '@/hooks/useJsonSchemasWithStatics'
import IJsonSchema from '@/utils/types/IJsonSchema'
import useMultiPolygons from '@/hooks/useMultiPolygons'
import formatNfdLowerCase from '@/utils/formatNfdLowerCase'
import ExpandLess from "@mui/icons-material/ExpandLess"
import ExpandMore from "@mui/icons-material/ExpandMore"
import Filter from '../following/filter/Filter'
import TableSuiviContactSubRow from '../subComponents/TableSuiviContactSubRow'
import useSelectedEvent from '@/hooks/useSelectedEvent'
import FSelectHumans from '@/utils/form/FSelectHumans'
import FSelect from '@/utils/form/FSelect'
import getImage from '@/utils/getImage'
import { SUIVI_PCS } from '@/redux-toolkit/userSettings/constants'
import _ from 'lodash'
import useFilterForm from '../following/filter/useFilterForm'
import HumanService from '@/utils/services/HumanService'
import usePointPropertyToText from '@/utils/components/jsonSchema/properties/usePointPropertyToText'
import moment from 'moment'
import { IFacilityBackup } from '@/utils/types/IFacility'

const ArchiveTableSupport = () => {
    const points = usePoints()
    const { facilityId: facilityIdParams } = useParams<{
        facilityId: string
    }>()
    const facilityId = facilityIdParams === "undefined" ? undefined : facilityIdParams
    const facility = Object.values(points).find((point) => point.id === facilityId)
    const backups = facility.geojson.properties.backup as IFacilityBackup[]
    const jsonSchemas = useJsonSchemasWithStatics()
    const history = useHistory()
    const selectedEvent = useSelectedEvent()
    const pointToText = usePointPropertyToText()
    const [expandedRowId, setExpandedRowId] = useState(undefined)

    let restrainedHumanIds = []
    backups.forEach((backup) =>
        backup.responsables.forEach((resp) =>
            restrainedHumanIds.push(resp.geojson.properties.Nom + " " + resp.geojson.properties.Prénom)
        )
    )
    restrainedHumanIds = _.uniq(restrainedHumanIds)

    const columns = useMemo(
        () =>
            [
                {
                    header: "Liste du PCS",
                    accessor: "jsonschema_name",
                    cell: ({ row }) => {
                        return <Flex>{row.original.relatedObject.jsonschema_name}</Flex>
                    },
                },
                {
                    header: "Identité",
                    accessor: "searchIdentity",
                    cell: ({ row }) => {
                        return <Flex>{row.original.relatedObject.searchIdentity}</Flex>
                    },
                },
                {
                    header: "Référents",
                    id: "geojson.properties.responsableId",
                    accessorKey: "geojson.properties.responsableId",
                    cell: ({ row }) => (
                        row.original.responsables.map((resp, index) => {
                            return <Flex>{resp.Nom + " " + resp.Prénom + (row.original.responsables.length != index + 1 ? "; " : "")}</Flex>
                        })
                    ),
                },
                {
                    header: "Suite à donner",
                    accessorFn: (props) => {
                        return (
                            props.suivi?.length ??
                            "Aucune suite recensée"
                        )
                    },
                    cell: ({ row, table: { toggleAllRowsExpanded } }) => {
                        const isExpanded = row.getIsExpanded()
                        if (
                            !isExpanded &&
                            expandedRowId === row.id &&
                            expandedRowId
                        ) {
                            row.toggleExpanded()
                        }

                        const numberOfSuivis = row.original.suivi?.length
                        if (!numberOfSuivis) {
                            return <div />
                        }
                        return (
                            <PrimaryTextButton
                                endIcon={isExpanded ? <ExpandLess /> : <ExpandMore />}
                                onClick={() => {
                                    if (isExpanded) {
                                        setExpandedRowId(undefined)
                                        row.toggleExpanded()
                                        return
                                    }
                                    toggleAllRowsExpanded(false)
                                    setExpandedRowId(row.id)
                                    row.toggleExpanded()
                                }}
                            >
                                {numberOfSuivis}
                            </PrimaryTextButton>
                        )
                    },
                    enableSorting: false,
                    meta: {
                        expandableCell: true,
                    },
                },
            ] as ColumnDef<any, any>[],
        [],
    )

    const filterSelectOptions = useMemo(() => {
        const actionOptions = []
        const categoryPcsOptions = []
        Object.values(points)
            .filter((point) => point.jsonschema_id === SUIVI_PCS && point.geojson.properties.suivi_group_id === facilityId)
            .forEach((point) => {
                const categoryId = point.geojson.properties.relatedObject["categoryId"]
                point.geojson.properties.suivi.forEach((suivi) => {
                    const currentOption = suivi?.["Suites à donner"]
                    if (currentOption && !actionOptions.includes(currentOption)) {
                        actionOptions.push(currentOption)
                    }
                    if (categoryId && !categoryPcsOptions.includes(categoryId)) {
                        categoryPcsOptions.push(categoryId)
                    }
                })
            })
        return {
            actionOptions: actionOptions,
            categoryPcsOptions: categoryPcsOptions
        }
    }, [points])

    const filterOptions = [
        {
            name: "Référent",
            component: FSelectHumans,
            componentOptionalProps: {
                restrainedHumanIds,
            },
            default: [],
        },
        {
            name: "Actions",
            component: FSelect,
            componentOptionalProps: {
                label: "Actions",
                options: filterSelectOptions.actionOptions
            },
            default: null,
        },
        {
            name: "Liste du PCS",
            component: FSelect,
            componentOptionalProps: {
                label: "Liste",
                options: filterSelectOptions.categoryPcsOptions,
                renderOption: (props, jsonschema_id) => {
                    return (
                        <li {...props} key={jsonschema_id}>
                            <Flex gap={5}>
                                <img
                                    src={getImage(
                                        jsonSchemas[jsonschema_id]?.imgId,
                                    )}
                                    width={25}
                                    height={25}
                                />
                                {jsonSchemas[jsonschema_id]?.title}
                            </Flex>
                        </li>
                    )
                },
                getOptionLabel: (jsonschema_id) => jsonSchemas[jsonschema_id]?.title ?? ""
            },
            default: null,
        },
    ]

    const { values, ...filterConfig } = useFilterForm({
        // @ts-ignore
        options: filterOptions,
    })

    //* CSV EXTRACTION part
    const enhancedData = useMemo(() => {
        const data = [].map((element) => {
            const headers = []
            jsonSchemas?.[
                element?.geojson?.properties?.relatedObject?.categoryId
            ]?.template?.properties?.forEach((property) => {
                if (property.type === "responsables") {
                    return
                }
                headers.push({
                    title: property.label,
                    accessorKey: property.label,
                })
            })
            const values = {}
            headers.forEach((header) => {
                if (header.title === "Coordonnées") {
                    const coords = _.get(
                        points[element?.geojson?.properties?.relatedObject?.objectId],
                        `geojson.properties.${header.accessorKey}`,
                        "",
                    )
                    if (coords) {
                        const coordsArray = []
                        coords.forEach((coord) =>
                            coordsArray.push(`${coord.Type} : ${coord.Numéro}`),
                        )
                        values[header.title] = coordsArray.join(" / ")
                    }

                    return
                }
                const currentPoint =
                    points[element?.geojson?.properties?.relatedObject?.objectId]
                const currentJsonSchema = jsonSchemas?.[currentPoint?.jsonschema_id]
                try {
                    const text = pointToText(
                        currentPoint,
                        currentJsonSchema.template.properties.find(
                            (property) => property.name === header.title,
                        ),
                    )
                    values[header.title] = text
                } catch (e) {
                    debugger
                }
            })

            return {
                "Détails suivi": element?.geojson?.properties?.suivi,
                "Suites à donner": element?.geojson?.properties?.suivi?.map(
                    (suivi) => suivi["Suites à donner"],
                ),
                Responsable: element?.geojson?.properties?.responsableId?.map((id) =>
                    HumanService.displayFullName(points[id]),
                ),
                headers: headers,
                "Catégorie PCS":
                    jsonSchemas?.[element?.geojson?.properties?.relatedObject?.categoryId]
                        ?.title,
                ...values,
            }
        })
        return data
    }, [])

    const csvData = useMemo(() => {
        const csvRows = []
        if (enhancedData === undefined || enhancedData.length === 0) {
            return csvRows
        }

        enhancedData.forEach((element) => {
            csvRows.push([
                ...enhancedData[0].headers.map((h) => `=""${element[h.title] ?? ""}""`),
                `=""${element["Responsable"]}""`,
                `=""${element["Catégorie PCS"]}""`,
                "",
                "",
            ])
            const details = element["Détails suivi"] ?? []
            details.forEach((detail) => {
                const newElement = [
                    "",
                    "",
                    ...enhancedData[0].headers.map(() => ""),
                ]
                csvRows.push([
                    ...newElement,
                    `=""${moment(detail["Date prise de contact"]).format("L") + ' - ' + moment(detail["Date prise de contact"]).format('LT')}""`,
                    detail["Suites à donner"],
                    detail["Observation"],
                ])
            })
        })
        return csvRows
    }, [enhancedData])

    const convertToCsv = useMemo(() => () => {
        const enhancedHeader = enhancedData[0]?.headers?.map((h) => h.title) ?? []
        return {
            headers: [
                ...enhancedHeader,
                "Référent",
                "Catégorie PCS",
                "Suivi : Date",
                "Suivi : Action",
                "Suivi : Observations",
            ],
            data: csvData,
            filename: selectedEvent?.name + "_Aide aux populations_Suivi " + "XXXXX" + ".csv",
        }
    }, [])

    return (
        <>
            <PrimaryTextButton
                startIcon={<ArrowBackIcon />}
                onClick={() => {
                    history.push("/maincourante/donnees")
                }}
            >
                Retour
            </PrimaryTextButton>
            <Flex gap=".5rem">
                <ArchiveIcon />
                <H2>{facility.geojson.properties?.name}</H2>
            </Flex>
            <SuperReactTable
                data={backups}
                columns={columns}
                initialSortBy={[
                    {
                        desc: true,
                        id: "searchIdentity",
                    },
                ]}
                isEditable={false}
                isDeletable={false}
                isLocked
                writePermission={false}
                actionsButtons={
                    <Filter {...filterConfig} size="big" />
                }
                RenderSubRow={({ row }) => (
                    <TableSuiviContactSubRow suivis={row.original.suivi} isArchive />
                )}
                convertToCsv={convertToCsv}
            />
        </>
    )
}

export default ArchiveTableSupport