import React, { useMemo } from "react"
import { Radio } from "@mui/material"
import { ColumnDef } from "@tanstack/react-table"
import useMultiPolygons from "@/hooks/useMultiPolygons"
import { width60 } from "utils/components/tables/widthProps"
import ModalBodyForTable from "./ModalBodyForTable"
import SuperReactTable from "utils/components/tables/SuperReactTable/SuperReactTable"
import { Flex } from "utils/components/style/flex"
import MultiPolygonColorSquare from "utils/components/map/polygon/MultiPolygonColorSquare"
import _ from "lodash"
import { PrimaryTextButton } from "utils/components/style/button"
import Modal from "@/styles/organisms/Modal"
import ModalHeader from "@/styles/organisms/Modal/ModalHeader"
import ModalFooter from "@/styles/organisms/Modal/ModalFooter"
import { ModalContent } from "@nextui-org/react"

const ModalSelectMultipolygon = ({
	isOpen,
	onClose,
	value,
	onSelect,
}: {
	isOpen: boolean
	onClose: () => void
	value: string
	onSelect: (id: string) => void
}) => {
	const multipolygons = useMultiPolygons()

	const data = useMemo(() => {
		return Object.values(multipolygons).filter(
			(multipolygon) => !multipolygon.event_id,
		)
	}, [multipolygons])

	const columns = useMemo(() => {
		return [
			{
				header: "Choisir",
				accessorKey: "choose",
				cell: ({ row }) => <Radio checked={row.original.id === value} />,
				enableSorting: false,
				...width60,
			},
			{
				header: "Surface cartographique",
				accessorKey: "name",
				cell: ({ row }) => (
					<Flex gap={10}>
						<MultiPolygonColorSquare
							multiPolygon={multipolygons[row.original.id]}
							size={"small"}
						/>
						{multipolygons[row.original.id]?.name}
					</Flex>
				),
			},
		] as ColumnDef<any>[]
	}, [])

	return (
		<Modal isOpen={isOpen} onClose={onClose}>
			<ModalContent>
				<ModalHeader>Choisissez une zone</ModalHeader>
				<ModalBodyForTable>
					<SuperReactTable
						columns={columns}
						data={data}
						onRowClick={(row) => onSelect(row.original.id)}
						autoResetPage={false}
						selectable={false}
						isEditable={false}
						isDeletable={false}
						infiniteSize
					/>
				</ModalBodyForTable>
				<ModalFooter>
					<PrimaryTextButton onClick={() => onClose()}>
						Annuler
					</PrimaryTextButton>
				</ModalFooter>
			</ModalContent>
		</Modal>
	)
}

export default ModalSelectMultipolygon
