import React, { useState } from "react"
import Debug from "debug"
import { useController, useFormContext } from "react-hook-form"
import EventNoteIcon from "@mui/icons-material/EventNote"
import FModalSelectIncident from "./FModalSelectIncident"
import LinkedInterventionRenderedValue from "./LinkedInterventionRenderedValue"
import Button from "@/styles/atoms/Button"

// app:javascript:utils:form:specific:daybook:FLinkIntervention.tsx
const debug = Debug(
	"app:javascript:utils:form:specific:daybook:FLinkIntervention",
)
debug.log = console.log.bind(console)

const FLinkIntervention = ({ name, currentIntervention, disabled = false }) => {
	const [isSelecting, setIsSelecting] = useState(false)

	const { control } = useFormContext()
	const {
		field: { value, onChange },
	} = useController({
		name,
		control,
		rules: { required: true },
		defaultValue: undefined,
	})

	return (
		<>
			{value && (
				<LinkedInterventionRenderedValue
					name={name}
					currentIntervention={currentIntervention}
					disabled={disabled}
				/>
			)}
			{!value && (
				<>
					<Button
						color="primary"
						variant="bordered"
						onClick={() => setIsSelecting(true)}
						startIcon={<EventNoteIcon />}
						disabled={disabled}
					>
						Lier à un évènement
					</Button>
					<FModalSelectIncident
						onClick={onChange}
						isOpen={isSelecting}
						onClose={() => setIsSelecting(false)}
					/>
				</>
			)}
		</>
	)
}

export default FLinkIntervention
