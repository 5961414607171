/* eslint-disable @typescript-eslint/no-explicit-any */
import { MAPPING_TOOL_KEY } from "@/pages/admin/mapping/mappingTools/text/key"
import _ from "lodash"
import moment from "moment"
import yupPointProperty from "utils/components/jsonSchema/form/yupPointProperty"
import { RUBY_DATE_FORMAT } from "@/utils/dateFormat"
import { IJsonSchemaProperty } from "@/utils/types/IJsonSchema"
import IMapping from "utils/types/IMapping/IMapping"
import IPoint from "utils/types/IPoint"
import Yup from "utils/yup"
import { v4 as uuidv4 } from "uuid"

export type IImportDiffItem = {
	id: string
	status: "changed" | "unchanged" | "added" | "removed"
	new?: any
	old?: any
	from?: number
	dateWarning?: boolean
}

export type IImportDiff = { [key: string]: IImportDiffItem }

const buildImportDiff = (
	points: IPoint[],
	data: any[],
	mapping: IMapping,
	schemaProperties: IJsonSchemaProperty[],
) => {
	const relationKeys = Object.keys(mapping.schema).filter(
		(k) => mapping.schema[k].converter === MAPPING_TOOL_KEY,
	)

	const fullYupSchema = Yup.object(
		Object.fromEntries(
			schemaProperties.map((property) => [
				property.name,
				yupPointProperty({ property }),
			]),
		),
	).noUnknown()

	const partialYupSchema = Yup.object(
		Object.fromEntries(
			schemaProperties
				.filter((property) =>
					Object.keys(mapping.schema).includes(property.name),
				)
				.map((property) => [property.name, yupPointProperty({ property })]),
		),
	).noUnknown()

	// ignore created points in app
	const notNullKeyPoints = points.filter((point) => {
		const keys = relationKeys
			.map((k) => point.geojson.properties[k])
			.filter(Boolean)
		return !!keys.length
	})

	//
	const diff: IImportDiff = {}

	const lastImport = moment(mapping.last_import, RUBY_DATE_FORMAT)

	notNullKeyPoints.forEach((point) => {
		const lastUpdate = moment(point.updated_at, RUBY_DATE_FORMAT)

		diff[point.id] = {
			id: point.id,
			old: fullYupSchema.cast(point.geojson.properties),
			status: "removed",
			dateWarning: lastUpdate.isAfter(lastImport),
		}
	})

	data.forEach((item, index) => {
		const relatedPoint = notNullKeyPoints.find((point) =>
			relationKeys.every(key => {
				return point.geojson.properties[key] === item[key]?.split("\n")?.join("")
			}),
		)

		const partialNormalizedItem = partialYupSchema.cast(item)
		const fullNormalizedItem = fullYupSchema.cast(item)

		if (relatedPoint) {
			const partialNormalizedPoint = partialYupSchema.cast(
				relatedPoint.geojson.properties,
			)
			const fullNormalizesPoint = fullYupSchema.cast(
				relatedPoint.geojson.properties,
			)
			const isSame = _.isEqual(partialNormalizedPoint, partialNormalizedItem)

			diff[relatedPoint.id] = {
				...diff[relatedPoint.id],
				new: {
					...fullNormalizesPoint,
					...partialNormalizedItem,
				},
				from: index,
				status: isSame ? "unchanged" : "changed",
				dateWarning: diff[relatedPoint.id].dateWarning && !isSame,
			}
		} else {
			const newId = uuidv4()
			diff[newId] = {
				id: newId,
				status: "added",
				new: fullNormalizedItem,
				from: index,
			}
		}
	})

	return diff
}

export default buildImportDiff
