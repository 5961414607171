import MapIcon from "@mui/icons-material/Map"
import TableChartIcon from "@mui/icons-material/TableChart"
import { IconButton, Paper } from "@mui/material"
import useCooperationFilters from "@/hooks/useCooperationFilters"
import { SearchView } from "@/redux-toolkit/edit/cooperationFiltersRessources"
import { setCooperationFilters } from "@/redux-toolkit/edit/reducer"
import React from "react"
import { isMobile } from "react-device-detect"
import { useDispatch } from "react-redux"
import styled, { css } from "styled-components"
import { Flex } from "utils/components/style/flex"

const MyPaper = styled(Paper)`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: 2rem !important;
    ${
			isMobile &&
			css`
            width: min-content;
            align-self: flex-end;
        `
		};
`

const MyIconButtonWrapper = styled.div<{ $selected: boolean }>`
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0.5rem;
    &:hover {
        cursor: pointer;
        background-color: var(--primary100);
    }
    :first-child {
        border-top-left-radius: 2rem;
        border-bottom-left-radius: 2rem;
        padding-left: 1rem;
    }
    :last-child {
        border-top-right-radius: 2rem;
        border-bottom-right-radius: 2rem;
        padding-right: 1rem;
    }

    ${({ $selected }) =>
			$selected &&
			css`
            background-color: var(--primary500) !important;
            color: white !important;
        `};

    :not(:last-child):after {
        content: '';
        display: block;
        width: 2px;
        background-color: lightgrey;
        position: absolute;
        top: 100%;
        bottom: 8px;
        right: 0;
    }
`
const SmallText = styled.div`
    font-size: 0.8rem;
`
function SwitchView() {
	const coopFilters = useCooperationFilters()
	const dispatch = useDispatch()

	const choose = (val: SearchView) =>
		dispatch(setCooperationFilters({ view: val }))

	return (
		<MyPaper>
			<MyIconButtonWrapper
				$selected={coopFilters.view === SearchView.CARTE}
				onClick={() => choose(SearchView.CARTE)}
			>
				<Flex gap="0.5rem">
					<MapIcon fontSize="small" />
					<SmallText>Cartographie</SmallText>
				</Flex>
			</MyIconButtonWrapper>
			<MyIconButtonWrapper
				$selected={coopFilters.view === SearchView.TABLEAU}
				onClick={() => choose(SearchView.TABLEAU)}
			>
				<Flex gap="0.5rem">
					<TableChartIcon fontSize="small" />
					<SmallText>Tableau</SmallText>
				</Flex>
			</MyIconButtonWrapper>
		</MyPaper>
	)
}

export default SwitchView
