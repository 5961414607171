import { Chip } from "@mui/material"
import React, { useMemo } from "react"
import colored from "@/utils/colored"
import { IActionLink } from "@/utils/types/IFiche"
import useGetLinkDetails from "./useGetLinkDetails"
import styled, { css } from "styled-components"

const CChip = styled(colored(Chip))<{ $draggable: boolean }>`
    max-width: 100%;

    ${({ $draggable }) =>
			$draggable &&
			css`
            cursor: grab !important;
        `}
`

function LinkChip({
	link,
	draggable = false,
	...props
}: {
	link: IActionLink
	draggable?: boolean
}) {
	const getLinkDetails = useGetLinkDetails()
	const linkDetails = useMemo(
		() => getLinkDetails(link),
		[getLinkDetails, link],
	)

	if (!linkDetails) return null

	return (
		<CChip
			$draggable={draggable}
			$textColor="black"
			$backgroundColor="white"
			size="small"
			variant="outlined"
			icon={linkDetails.Icon}
			label={linkDetails.label}
			onClick={() => linkDetails.action?.()}
			{...props}
		/>
	)
}

export default LinkChip
