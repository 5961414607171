import React from "react"
import useCooperationFilters from "@/hooks/useCooperationFilters"
import CustomTabs from "@/utils/components/customTabs/CustomTabs"
import Organigramme from "@/utils/components/orga/Organigramme"
import useCdc from "@/hooks/useCdc"
import _ from "lodash"
import useCommune from "@/hooks/useCommune"

const SharedOrga = () => {
	const coopFilters = useCooperationFilters()
	const userCommune = useCommune()
	const cdc = useCdc()

	const sortedCdcCommunes = _.cloneDeep(cdc.communes)
		.sort((a, b) => {
			// force user commune to be first
			if (a.name === userCommune.name) return -1
			return a.name.localeCompare(b.name)
		})

	const selectedCommunes = (
		coopFilters.excludeCommunes || !coopFilters.communes.length
			? sortedCdcCommunes
				.filter((id) => !coopFilters.communes.includes(id))
				.map((comm) => comm.id)
			: sortedCdcCommunes
				.filter((comm) => coopFilters.communes.includes(comm.id))
				.map((comm) => comm.id)
	)

	if (selectedCommunes.length === 1 && !coopFilters.excludeCommunes) {
		const orga = sortedCdcCommunes.find(
			(commune) => commune.id === selectedCommunes[0],
		)?.orga

		return <Organigramme orgaWithOrder={orga} />
	}

	const tabs = selectedCommunes
		.map((communeId) =>
			sortedCdcCommunes.find((commune) => commune.id === communeId),
		)
		.map((commune) => ({
			key: commune.name,
			render: <Organigramme orgaWithOrder={commune.orga} />,
		}))

	return <CustomTabs tabChildrens={tabs} />
}

export default SharedOrga
