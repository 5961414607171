import ChatOutlinedIcon from "@mui/icons-material/ChatOutlined"
import CheckIcon from "@mui/icons-material/Check"
import SendIcon from "@mui/icons-material/Send"
import SyncIcon from "@mui/icons-material/Sync"
import Debug from "debug"
import React from "react"
import { useFormContext } from "react-hook-form"
import styled, { css } from "styled-components"
import { Flex } from "utils/components/style/flex"
import { DaybookAlertType } from "@/utils/types/daybook/IDaybookAlert"
import DaybookAlertAvatar from "./DaybookAlertAvatar"

// app:javascript:components:maincourante:alert:alertCreation:DaybookAlertStatusStepper.tsx
const debug = Debug(
	"app:javascript:components:maincourante:alert:alertCreation:DaybookAlertStatusStepper",
)
debug.log = console.log.bind(console)

const Sdiv = styled.div`
    width: 100px;
    text-align: center;
    font-size: 0.75rem;
`
const AvatarContainer = styled.div`
    width: 100px;
    display: flex;
    justify-content: center;
    min-width: 100px;
`

const SLine = styled.div<{ $finished?: boolean }>`
    width: 100%;
    height: 2px;
    border: 1px solid var(--neutral800);
    ${({ $finished }) =>
			!$finished &&
			css`
            // dashed
            border-style: dashed;
        `}
`
const DaybookAlertStatusStepper = ({}) => {
	const { watch } = useFormContext()

	const status = watch("geojson.properties.status")

	return (
		<>
			<Flex spaceBetween fullWidth>
				<AvatarContainer>
					<DaybookAlertAvatar alertStatus={undefined}>
						<CheckIcon />
					</DaybookAlertAvatar>
				</AvatarContainer>
				<SLine $finished />
				<AvatarContainer>
					<DaybookAlertAvatar alertStatus={DaybookAlertType.PREPARE_DIFFUSE}>
						<SyncIcon />
					</DaybookAlertAvatar>
				</AvatarContainer>
				<SLine />
				<AvatarContainer>
					<DaybookAlertAvatar alertStatus={DaybookAlertType.PREPARE_MESSAGE}>
						<SyncIcon />
					</DaybookAlertAvatar>
				</AvatarContainer>
				<SLine />
				<AvatarContainer>
					<DaybookAlertAvatar alertStatus={DaybookAlertType.READY}>
						<ChatOutlinedIcon />
					</DaybookAlertAvatar>
				</AvatarContainer>
				<SLine />
				<AvatarContainer>
					<DaybookAlertAvatar alertStatus={DaybookAlertType.SENDING}>
						<SendIcon />
					</DaybookAlertAvatar>
				</AvatarContainer>
				<SLine />
				<AvatarContainer>
					<DaybookAlertAvatar alertStatus={DaybookAlertType.FINISHED}>
						<CheckIcon />
					</DaybookAlertAvatar>
				</AvatarContainer>
				<SLine />
				<AvatarContainer>
					<DaybookAlertAvatar alertStatus={DaybookAlertType.ARCHIVED} final>
						<CheckIcon />
					</DaybookAlertAvatar>
				</AvatarContainer>
			</Flex>
			<Flex spaceBetween fullWidth>
				<Sdiv>Création</Sdiv>
				<Sdiv>Liste de diffusion</Sdiv>
				<Sdiv>Message</Sdiv>
				<Sdiv>Synthèse</Sdiv>
				<Sdiv>En cours d'envoi</Sdiv>
				<Sdiv>Terminé</Sdiv>
				<Sdiv>Archivé</Sdiv>
			</Flex>
		</>
	)
}

export default DaybookAlertStatusStepper
