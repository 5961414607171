import useCurrentActionRequest from "@/hooks/useCurrentActionRequest"
import Button from "@/styles/atoms/Button"
import CancelButton from "@/styles/atoms/Button/specialized/CancelButton"
import SaveButton from "@/styles/atoms/Button/specialized/SaveButton"
import SDialog from "@/utils/components/SDialog"
import {
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
} from "@mui/material"
import React, { useEffect, useState } from "react"
import { useFormContext } from "react-hook-form"
import styled from "styled-components"

const SDialogContentText = styled(DialogContentText)`
    color: var(--neutral900) !important;
`

function SafeForm({ onSubmit }) {
	const methods = useFormContext()
	const [isValid, setIsValid] = useState(true)

	const { request, accept, reject, current } = useCurrentActionRequest(
		methods.formState.isDirty,
	)

	const saveAndAccept = () => {
		methods.handleSubmit(
			(values) => {
				onSubmit(values)
				accept()
			},
			() => {
				reject()
			},
		)()
	}

	useEffect(() => {
		if (request && current) methods.trigger().then((valid) => setIsValid(valid))
	}, [request])

	return (
		<SDialog
			open={!!(request && current !== undefined && methods.formState.isDirty)}
			onClose={reject}
		>
			<DialogTitle>Confirmer</DialogTitle>
			<DialogContent>
				{isValid ? (
					<SDialogContentText>
						Vous avez effectué des modifications sur l&apos;action en cours
						d&apos;édition. Que voulez-vous faire ?
					</SDialogContentText>
				) : (
					<SDialogContentText>
						Vous avez effectué des modifications sur l&apos;action en cours
						d&apos;édition, mais ceux-ci ne peuvent pas être enregistrés car des
						champs sont incorrectement remplis. Que voulez-vous faire ?
					</SDialogContentText>
				)}
			</DialogContent>
			<DialogActions>
				{isValid && <CancelButton onClick={reject} />}
				<Button variant="bordered" color="primary" onClick={accept}>
					Ne pas enregistrer
				</Button>
				{isValid ? (
					<SaveButton onClick={saveAndAccept} />
				) : (
					<Button color="primary" onClick={reject}>
						Continuer l&apos;édition
					</Button>
				)}
			</DialogActions>
		</SDialog>
	)
}

export default SafeForm
