import { View } from "@react-pdf/renderer"
import React from "react"

const HeaderBanner = ({ children, ...props }) => {
	return (
		<View
			style={{
				// All CSS shadow effect are still not implemented on react-pdf/renderer.
				// boxShadow: '3px 3px 7px 0px rgba(21, 21, 21, 0.7)',
				...{
					border: "1px solid #2e74b5",
					backgroundColor: "#ffffff",
					borderRadius: "4px",
					display: "flex",
					alignItems: "center",
					padding: "10px",
					marginBottom: "10px",
				},
				...props.style,
			}}
		>
			{children}
		</View>
	)
}

export default HeaderBanner
