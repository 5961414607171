import React from "react"
import { InputAdornment, TextField } from "@mui/material"
import _ from "lodash"

const NumberField = ({
	name,
	label,
	initialValue,
	onChange,
	endAdornment = "",
}: {
	name: string
	label: string
	initialValue: number
	onChange: (x) => void
	endAdornment?: string | JSX.Element
}) => {
	const isValidFloat = (finalValue: string) => {
		let isValid = true
		finalValue.split("").forEach((char) => {
			if (_.isNaN(parseInt(char))) return (isValid = false)
		})

		return isValid
	}

	const process = (value) => {
		let finalValue = value

		//* Field is deleted entierly
		if (_.isEmpty(finalValue)) return onChange(0)

		//* Do nothing if finalValue isn't a valid float number
		if (!isValidFloat(finalValue)) return

		//* Prevent useless render, do nothing
		if (_.isEqual(finalValue, initialValue)) return

		finalValue = parseFloat(finalValue)
		onChange(finalValue)
	}

	return (
		<TextField
			name={name}
			label={label}
			value={initialValue}
			onChange={(e) => process(e.target.value)}
			InputProps={{
				inputMode: "numeric",
				endAdornment: (
					<InputAdornment position="end">{endAdornment}</InputAdornment>
				),
			}}
		/>
	)
}

export default NumberField
