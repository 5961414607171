import React from "react"
import useJsonSchemasWithStatics from "@/hooks/useJsonSchemasWithStatics"
import FAdminJsonSchemas from "@/utils/form/FAdminJsonSchemas"
import { FormDependencies } from "@/utils/form/FormDependencies"
import DaybookAlertContactTable from "../DaybookAlertContactTable/DaybookAlertContactTable"
import useSelectedEvent from "@/hooks/useSelectedEvent"
import _ from "lodash"
import FSwitch from "@/utils/form/FSwitch"
import { useWatch } from "react-hook-form"

const DaybookAlertPrepareUsingTable = () => {
	const jsonSchemas = useJsonSchemasWithStatics()
	const selectedEvent = useSelectedEvent()
	const isLocked = selectedEvent?.locked

	return (
		<>
			<FSwitch
				label="Inclure les numéros de téléphones fixes"
				name="geojson.properties.includeFixedPhones"
			/>
			<FAdminJsonSchemas
				multiple
				label="Liste de diffusion"
				name="geojson.properties.listeDiffusions"
				additionalFilter={(jsonSchemaId: string) => {
					return jsonSchemas[jsonSchemaId].is_telealert
				}}
				additionalList={["Organigramme"]}
				disabled={isLocked}
			/>
			<FormDependencies targets={["geojson.properties.listeDiffusions"]}>
				{(formDependencies) => {
					const listeDiffusions =
						formDependencies["geojson.properties.listeDiffusions"]
					return (
						<DaybookAlertContactTable
							jsonSchemaIds={listeDiffusions}
							disabled={isLocked}
						/>
					)
				}}
			</FormDependencies>
		</>
	)
}

export default DaybookAlertPrepareUsingTable
