import React, { useState, useEffect, useMemo, useRef } from "react"
import { useDispatch, useSelector } from "react-redux"
import Debug from "debug"
import styled, { css } from "styled-components"
import Moment from "moment"
import { useHistory } from "react-router-dom"
import _ from "lodash"
import usePoints from "@/hooks/usePoints"
import SuperReactTable from "utils/components/tables/SuperReactTable/SuperReactTable"
import MyForms from "@/pages/admin/formulaire_dynamique/MyForms/MyForms"
import useAccessRights from "@/hooks/useAccessRights"
import { PATH_MESSAGE_ALERTE_TEMPLATE } from "@/redux-toolkit/userSettings/constants"
import { StaticFormType } from "utils/services/StaticFormsService"
import AlertMessageModal from "./AlertMessageModal"
import { PrimaryButton } from "utils/components/style/button"
import { deletePoint } from "@/redux-toolkit/data/points/resources"
import SmsOutlined from "@mui/icons-material/SmsOutlined"
import GraphicEqOutlined from "@mui/icons-material/GraphicEqOutlined"
import IDaybookAlert, {
	DaybookAlertCanal,
	risksIcons,
} from "@/utils/types/daybook/IDaybookAlert"
import { Flex } from "utils/components/style/flex"
import { widthCustom } from "utils/components/tables/widthProps"
import SuperReactTableCell from "utils/components/tables/SuperReactTableCell"
import IPoint from "utils/types/IPoint"
import { StyledChip } from "utils/components/style/chip"
import { ColumnDef } from "@tanstack/react-table"
import ErrorPermissions, {
	BACK_PATH_HOME,
	BACK_TITLE_HOME,
	FEATURE_ERROR_PERMISSION,
} from "@/hooks/ErrorPermissions"
import { EnumToolPath } from "@/hooks/services/useAccessRightsServices"

// app:javascript:components:customdata:alertMessage:AlertMessage.tsx
const debug = Debug(
	"app:javascript:components:customdata:alertMessage:AlertMessage",
)
debug.log = console.log.bind(console)

const SFlex = styled(Flex)`
	border: 1px solid var(--neutral300);
	border-radius: 5px;
	padding: 0.5rem;
`

const AlertMessage = ({}) => {
	const points = usePoints()
	const readPermission = useAccessRights(
		EnumToolPath.MESSAGE_ALERTE_TEMPLATE,
	).read
	const [isOpenAlertMessage, setIsOpenAlertMessage] = useState(false)
	const [currentPoint, setCurrentPoint] = useState(null)
	const dispatch = useDispatch()
	const concernedPoints = useMemo(() => {
		return Object.values(points).filter(
			(point) => point.jsonschema_id === StaticFormType.ALERTE_MESSAGE,
		)
	}, [points])

	const columns = useMemo(() => {
		return [
			{
				header: "Nom du message",
				accessorKey: "geojson.properties.title",
			},
			{
				header: "Type",
				accessorKey: "geojson.properties.type",
				cell: ({
					row: { original: value },
				}: {
					row: { original: IDaybookAlert }
				}) => {
					const filteredRisks = risksIcons.filter((risk) =>
						value.geojson.properties.type.includes(risk.value),
					)
					if (filteredRisks.length > 0) {
						return (
							<Flex gap="0.25rem" $wrap>
								{filteredRisks.map((risk) => (
									<SFlex justifyCenter gap="0.5rem">
										{risk.icon}
										{risk.value}
									</SFlex>
								))}
							</Flex>
						)
					}
					return <Flex gap="0.5rem">Aucun</Flex>
				},
				...widthCustom(350),
			},
			{
				header: "Risques",
				accessorKey: "geojson.properties.riskType",
				cell: ({
					row: { original: value },
				}: {
					row: { original: IPoint }
				}) => {
					return (
						<>
							<Flex gap="0.5rem" $wrap>
								{value.geojson.properties.riskType.map((type) => (
									<StyledChip
										key={type}
										label={type}
										$colorProperty="var(--primary500)"
										variant="outlined"
									/>
								))}
							</Flex>
						</>
					)
				},
				...widthCustom(200),
			},
		] as ColumnDef<any>[]
	}, [])

	if (!readPermission) {
		return (
			<ErrorPermissions
				errorMessage={FEATURE_ERROR_PERMISSION}
				backTitle={BACK_TITLE_HOME}
				backUrl={BACK_PATH_HOME}
			/>
		)
	}

	return (
		<>
			<SuperReactTable
				data={concernedPoints}
				columns={columns}
				actionsButtons={
					<>
						<PrimaryButton
							onClick={() => {
								setIsOpenAlertMessage(true)
								setCurrentPoint(null)
							}}
						>
							Ajouter un message d'alerte
						</PrimaryButton>
					</>
				}
				onEditClick={(point) => {
					setCurrentPoint(point)
					setIsOpenAlertMessage(true)
				}}
				onDeleteClick={(point) => {
					dispatch(deletePoint(point))
				}}
			/>
			<AlertMessageModal
				isOpen={isOpenAlertMessage}
				onClose={() => {
					setIsOpenAlertMessage(!isOpenAlertMessage)
				}}
				currentPoint={currentPoint}
			/>
		</>
	)
}

export default AlertMessage
