import React, { useState, useEffect, useMemo } from "react"
import Debug from "debug"
import { useSelector, useDispatch } from "react-redux"
import styled from "styled-components"
import { Card, ListItem } from "@mui/material"
import List from "@mui/icons-material/List"
import { Flex } from "utils/components/style/flex"

// app:javascript:components:admin:formulaire_dynamique:MyForms:CustomCardStatDons.tsx
const debug = Debug(
	"app:javascript:components:admin:formulaire_dynamique:MyForms:CustomCardStatDons",
)
debug.log = console.log.bind(console)

const CustomCardStatDons = ({ items }) => {
	const categories = {}
	items.forEach((item) => {
		const currentType = item.geojson.properties["Type"]
		if (!categories[currentType]) {
			categories[currentType] = 0
		}
		if (parseInt(item.geojson.properties?.["Quantité"])) {
			categories[currentType] += parseInt(
				item.geojson.properties?.["Quantité"] ?? 0,
			)
		}
	})
	if (Object.keys(categories).length === 0) {
		return null
	}
	return (
		<Card>
			{Object.keys(categories)
				.sort(function (a, b) {
					return a > b ? 1 : a === b ? 0 : -1
				})
				.map((category) => (
					<ListItem key={category}>
						<Flex spaceBetween fullWidth gap="2rem">
							<div>{category}</div>
							<div>{categories[category]}</div>
						</Flex>
					</ListItem>
				))}
		</Card>
	)
}

export default CustomCardStatDons
