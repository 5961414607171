import React from "react"
import { Alert, AlertTitle } from "@mui/material"
import useJsonSchemasWithStatics from "@/hooks/useJsonSchemasWithStatics"
import usePoints from "@/hooks/usePoints"
import { INCIDENT } from "@/redux-toolkit/userSettings/constants"
import { useDispatch, useSelector } from "react-redux"
import { PrimaryTextButton } from "utils/components/style/button"
import {
	setCurrentIncident,
	setCurrentPoint,
} from "@/redux-toolkit/edit/reducer"
import JsonSchemaService from "@/pages/carto2/cartographie/service/JsonSchemaService"
import { Flex } from "utils/components/style/flex"
import { IJsonSchemaProperty } from "@/utils/types/IJsonSchema"
import { useController, useFormContext } from "react-hook-form"
import _ from "lodash"

const LinkedInterventionRenderedValue = ({
	name,
	currentIntervention,
	disabled = false,
}) => {
	const points = usePoints()
	const dispatch = useDispatch()
	const jsonSchemas = useJsonSchemasWithStatics()
	const currentIncident = useSelector(
		(state: any) => state.edit.currentIncident,
	)

	const { control } = useFormContext()
	const {
		field: { value, onChange },
	} = useController({
		name,
		control,
		rules: { required: true },
		defaultValue: undefined,
	})

	// case intervention
	const point = points[value]
	if (point) {
		if (point.jsonschema_id === INCIDENT) {
			if (_.isEmpty(currentIncident)) {
				return (
					<Alert
						severity="info"
						onClose={disabled ? null : () => onChange(undefined)}
					>
						<AlertTitle>En lien avec</AlertTitle>
						Evènement lié :{" "}
						<PrimaryTextButton
							onClick={() => {
								dispatch(setCurrentIncident(points[value]))
							}}
						>
							<b>{points[value].geojson.properties.Objet}</b>
						</PrimaryTextButton>
					</Alert>
				)
			}
			return (
				<Alert severity="info">
					<AlertTitle>En lien avec</AlertTitle>
					Evènement lié : {points[value].geojson.properties.Objet}
				</Alert>
			)
		}

		const jsonSchema = jsonSchemas[point.jsonschema_id]

		if (jsonSchema) {
			const idProperties = JsonSchemaService.getIdProperties(jsonSchema)
			return (
				<Alert severity="info" onClose={() => onChange(undefined)}>
					<AlertTitle>En lien avec</AlertTitle>
					<PrimaryTextButton onClick={() => dispatch(setCurrentPoint(point))}>
						<Flex gap="0.25rem">
							<div>Donnée :</div>
							{idProperties.map((property: IJsonSchemaProperty) => {
								return (
									<div key={property.name}>
										{point.geojson.properties[property.name]}
									</div>
								)
							})}
						</Flex>
					</PrimaryTextButton>
				</Alert>
			)
		}
		jsonSchema
	}

	// if intervention created during an event creation which has been canceled, unlink the action with uncreated event.
	if (!_.isEmpty(currentIntervention) && !currentIncident) {
		onChange(undefined)
	}

	return (
		<Alert severity="info">
			<AlertTitle>En lien avec</AlertTitle>
			Evènement lié : en cours de création
		</Alert>
	)
}

export default LinkedInterventionRenderedValue
