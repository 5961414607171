import AccountTreeIcon from "@mui/icons-material/AccountTree"
import MapIcon from "@mui/icons-material/Map"
import MenuOpenIcon from "@mui/icons-material/MenuOpen"
import SimCardDownloadIcon from "@mui/icons-material/SimCardDownload"
import useJsonSchemasWithStatics from "@/hooks/useJsonSchemasWithStatics"
import useRiskFiles from "@/hooks/useRiskFiles"
import useStaticFiles from "@/hooks/useStaticFiles"
import useUploadedFiles from "@/hooks/useUploadedFiles"
import React, { useCallback } from "react"
import buildUploadedFileLink from "utils/buildUploadedFileLink"
import fileinfo from "utils/fileinfo"
import { getFilenameConfig } from "utils/form/specific/fiche/links/utils/FileIcon"
import getImage from "@/utils/getImage"
import { IActionLink, LinkType } from "utils/types/IFiche"
import getGenericLinkIcon from "./getGenericLinkIcon"
import { Image } from "@nextui-org/react"

const getInternalIcon = (url) => {
	switch (url) {
		case "/customdata/organigramme":
			return AccountTreeIcon
		case "/download":
			return SimCardDownloadIcon
		case "/cartographie":
			return MapIcon
		default:
			return MenuOpenIcon
	}
}

const useGetLinkDetails = () => {
	const openTab = (url) => () => window.open(url, "_blank").focus()

	const jsonSchemas = useJsonSchemasWithStatics()
	const uploadedFiles = useUploadedFiles()
	const riskFiles = useRiskFiles()
	const staticFiles = useStaticFiles({ withUploaded: true, withDynamics: true })

	return useCallback(
		(link: IActionLink) => {
			const IconComponent = getGenericLinkIcon(link.type) as any
			const Icon = <IconComponent fontSize="small" />

			switch (link.type) {
				case LinkType.EXTERNAL:
					return {
						label: link.text,
						Icon,
						action: openTab(link.url),
					}
				case LinkType.INTERNAL:
					const InternalIcon = getInternalIcon(link.url)
					return {
						label: link.text,
						Icon: <InternalIcon fontSize="small" />,
						action: openTab(link.url),
					}
				case LinkType.JSON_SCHEMA:
					if (!jsonSchemas[link.id]) return
					return {
						Icon: (
							<Image width={20} src={getImage(jsonSchemas[link.id].imgId)} />
						),
						label: jsonSchemas[link.id].title,
						action: openTab(`/customdata/my_forms/${link.id}`),
					}
				case LinkType.STATIC_FILE:
				case LinkType.DYNAMIC_FILE:
					if (!staticFiles[link.id]) return
					return {
						Icon,
						label: staticFiles[link.id].title,
						action: openTab(
							`/generate_pcs_uniq_fiche/${staticFiles[link.id].id}`,
						),
					}
				case LinkType.RISK_FILE:
					if (!riskFiles[link.id]) return
					return {
						Icon,
						label: `Fiche pédagogique ${riskFiles[link.id]?.risk}`,
						action: openTab(`/${riskFiles[link.id]?.pdf}`),
					}
				case LinkType.UPLOADED_CARTO:
				case LinkType.UPLOADED_IMAGE:
				case LinkType.UPLOADED_CONVENTION:
				case LinkType.UPLOADED_EDITABLE:
					const carto = uploadedFiles[link.id]
					if (!carto) return
					const { color } = getFilenameConfig(carto.filename)
					return {
						label: fileinfo(carto.filename).base,
						Icon: <IconComponent $textColor={color} />,
						action: openTab(buildUploadedFileLink(link.id)),
					}
				case LinkType.NO_LINK:
					return {
						label: link.text,
						Icon: undefined,
						action: undefined,
					}
				default:
					return {
						label: JSON.stringify(link),
						Icon: undefined,
						action: undefined,
					}
			}
		},
		[jsonSchemas, uploadedFiles, riskFiles],
	)
}

export default useGetLinkDetails
