import React, { useState, useEffect, useMemo, useRef } from "react"
import { useDispatch, useSelector } from "react-redux"
import Debug from "debug"
import styled, { css } from "styled-components"
import Moment from "moment"
import { useHistory } from "react-router-dom"
import _ from "lodash"
import {
	Alert,
	AlertTitle,
	Collapse,
	List,
	ListItemButton,
	ListItemText,
} from "@mui/material"
import ExpandLess from "@mui/icons-material/ExpandLess"
import ExpandMore from "@mui/icons-material/ExpandMore"
import { TIME_BETWEEN_SYNCHRO } from "@/pages/loading/SynchronisationWrapper"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline"
import SyncIcon from "@mui/icons-material/Sync"
import { Flex } from "utils/components/style/flex"
import { useLocalStorage } from "react-use"
// app:javascript:components:app:SynchronisationStatusBar.tsx
const debug = Debug("app:javascript:components:app:SynchronisationStatusBar")
debug.log = console.log.bind(console)
const StyledList = styled(List)`
    width: 100%;
    background-color: var(--green50);
    color: var(--green999);
    padding: 0px !important;
`
const StyledCheckCircleOutlineIcon = styled(CheckCircleOutlineIcon)<{
	$margin?: boolean
}>`
    color: var(--green500);
    ${({ $margin }) => $margin && "margin-right: 0.5rem"};
`

const StyledCollapseContainer = styled.div`
    padding: 1rem;
    width: 100%;
`

// rotatating at 2s 360°
const StyledSyncIcon = styled(SyncIcon)<{ $margin?: boolean }>`
    color: var(--primary500);
    animation: rotate 2s linear infinite;
    ${({ $margin }) => $margin && "margin-right: 0.5rem"};
    @keyframes rotate {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(360deg);
        }
    }
`

const StyledSyncContainer = styled(List)`
    width: 100%;
    background-color: var(--primary50);
    color: var(--primary800);
    padding: 0px !important;
`
const Container = styled.div<{ $isSynchronising: boolean }>`
    background-color: ${({ $isSynchronising }) =>
			$isSynchronising ? "var(--primary50)" : "var(--green50)"};
    width: 100%;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
`
const SynchronisationStatusBar = () => {
	const synchro = useSelector((state: any) => state.common.SYNCHRO)
	const synchroPoint = useSelector((state: any) => state.common.SYNCHRO_POINT)
	const synchroEvent = useSelector((state: any) => state.common.SYNCHRO_EVENT)
	const synchroMultiPolygon = useSelector(
		(state: any) => state.common.SYNCHRO_MULTIPOLYGON,
	)
	const isOnline = useSelector((state: any) => state.common.isOnline)
	const isSynchronising =
		synchro === 1 ||
		synchroPoint === 1 ||
		synchroEvent === 1 ||
		synchroMultiPolygon === 1
	const [nextSynchroSecond, setNextSynchroSecond] = useState(
		TIME_BETWEEN_SYNCHRO / 1_000,
	)
	const [isOpen, setIsOpen] = useLocalStorage(
		"SynchronisationStatusBar:isOpen",
		true,
	)
	useEffect(() => {
		if (synchro) {
			setNextSynchroSecond(TIME_BETWEEN_SYNCHRO / 1_000)
		}
	}, [synchro])

	useEffect(() => {
		const interval = setInterval(() => {
			setNextSynchroSecond(nextSynchroSecond - 1)
		}, 1_000)
		return () => clearInterval(interval)
	}, [nextSynchroSecond])

	if (true) {
		return (
			<Container $isSynchronising={isSynchronising}>
				{isSynchronising ? (
					<StyledSyncIcon />
				) : (
					<StyledCheckCircleOutlineIcon />
				)}
			</Container>
		)
	}
	if (!isOnline) {
		return <Alert severity="info">Mode hors-ligne</Alert>
	}
	return (
		<>
			{isSynchronising && (
				<StyledSyncContainer>
					<ListItemButton onClick={() => setIsOpen(!isOpen)}>
						<StyledSyncIcon $margin />
						<ListItemText primary="Synchronisation en cours" />
						{isOpen ? <ExpandLess /> : <ExpandMore />}
					</ListItemButton>
					<Collapse in={isOpen} timeout="auto" unmountOnExit>
						<StyledCollapseContainer>
							<ul>
								<Flex gap="0.5rem">
									{synchroPoint === 0 ? (
										<StyledCheckCircleOutlineIcon $margin />
									) : (
										<StyledSyncIcon $margin />
									)}
									<div>Données PCS</div>
								</Flex>
								<Flex gap="0.5rem">
									{synchroMultiPolygon === 0 ? (
										<StyledCheckCircleOutlineIcon $margin />
									) : (
										<StyledSyncIcon $margin />
									)}
									<div>Couches cartographiques</div>
								</Flex>
								<Flex gap="0.5rem">
									{synchroEvent === 0 ? (
										<StyledCheckCircleOutlineIcon $margin />
									) : (
										<StyledSyncIcon $margin />
									)}
									<div>Gestion de crise</div>
								</Flex>
								<Flex gap="0.5rem">
									{synchro === 0 ? (
										<StyledCheckCircleOutlineIcon $margin />
									) : (
										<StyledSyncIcon $margin />
									)}
									<div>Autres données</div>
								</Flex>
							</ul>
						</StyledCollapseContainer>
					</Collapse>
				</StyledSyncContainer>
			)}
			{!isSynchronising && (
				<StyledList>
					<ListItemButton onClick={() => setIsOpen(!isOpen)}>
						<StyledCheckCircleOutlineIcon $margin />
						<ListItemText primary="Synchronisation terminée" />
						{isOpen ? <ExpandLess /> : <ExpandMore />}
					</ListItemButton>
					<Collapse in={isOpen} timeout="auto" unmountOnExit>
						<StyledCollapseContainer>
							Synchronisation dans : {nextSynchroSecond} secondes
						</StyledCollapseContainer>
					</Collapse>
				</StyledList>
			)}
		</>
	)
}

export default SynchronisationStatusBar
