import DeleteIcon from "@mui/icons-material/Delete"
import EditIcon from "@mui/icons-material/Edit"
import {
	Alert,
	AlertTitle,
	Avatar,
	Card,
	Chip,
	IconButton,
} from "@mui/material"
import useCareMembers from "@/hooks/care/useCareMembers"
import useAccessRights from "@/hooks/useAccessRights"
import useSelectedEvent from "@/hooks/useSelectedEvent"
import { deletePointWithUpdateEvent } from "@/redux-toolkit/data/points/resources"
import { PATH_MAIN_COURANTE_CARE } from "@/redux-toolkit/userSettings/constants"
import Debug from "debug"
import _ from "lodash"
import React, { useMemo, useState } from "react"
import { useDispatch } from "react-redux"
import { useHistory } from "react-router"
import { Pie, PieChart, Tooltip } from "recharts"
import styled from "styled-components"
import ConfirmationModal from "utils/components/ConfirmationModal"
import TypeGeoloc from "utils/components/jsonSchema/properties/propertiesType/TypeGeoloc"
import TypePhone from "utils/components/jsonSchema/properties/propertiesType/TypePhone"
import TypeResponsables from "utils/components/jsonSchema/properties/propertiesType/TypeResponsables"
import { PrimaryTextButton } from "utils/components/style/button"
import { Flex } from "utils/components/style/flex"
import { H1 } from "utils/components/style/header"
import IGeoloc from "utils/types/IGeoloc"
import IPhones from "utils/types/IPhones"
import IPoint, { IGeojsonPropertyValue } from "utils/types/IPoint"
import Moment from "moment"
import { isDesktop, isMobile } from "react-device-detect"
import { alertTextSize, SectionTitle } from "../CareResumeCard"
import CapacityPie from "./CapacityPie"
import { EnumToolPath } from "@/hooks/services/useAccessRightsServices"
// app:javascript:components:maincourante:care:CareCard.tsx
const debug = Debug("app:javascript:components:maincourante:care:CareCard")
debug.log = console.log.bind(console)
const StyledCard = styled(Card)`
    padding: 1rem;
    width: 100%;
    overflow: auto !important;
`
// @ts-ignore
const StyledPieChart = styled(PieChart)`
    & > svg {
        overflow: visible !important;
    }
`
const Grid = styled.div`
    display: grid;
    grid-template-columns: 1fr auto auto;
    grid-gap: 0.5em;
`
const Square = styled.div`
    width: 20px;
    height: 10px;
    border-radius: 15px;
    align-self: center;
    background-color: ${({ color }) => color};
    box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 14%),
        0px 2px 2px 0px rgb(0 0 0 / 12%), 0px 1px 5px 0px rgb(0 0 0 / 8%);
`
const SAlert = styled(Alert)`
    width: 100%;
`
const TinyTitle = styled(H1)`
    font-size: 18px;
    padding: 0;
`
const SChip = styled(Chip)`
    & > span {
        font-size: 0.8rem;
    }
`
const SAvatar = styled(Avatar)`
    font-weight: bold;
`

const CareCard = ({
	care,
	showActions = true,
	showEdition = true,
	onEditClick = (_care: IPoint) => {},
}) => {
	const history = useHistory()
	const permissions = useAccessRights(EnumToolPath.CARE)
	const dispatch = useDispatch()
	const [isOpenModalConfirmation, setIsOpenModalConfirmation] = useState(false)
	const selectedEvent = useSelectedEvent()
	const { adultMember, childMember, babyMember, animalMember, specificities } =
		useCareMembers({ care })

	const DynamicFragment = isMobile ? React.Fragment : Grid

	const capacite = care.geojson.properties["Capacité"] ?? 0

	const pieData = useMemo(() => {
		const newData = [
			{
				name: "Adultes",
				value: adultMember,
				fill: "green",
			},
			{
				name: "Enfants",
				value: childMember,
				fill: "orange",
			},
			{
				name: `Bébés`,
				value: babyMember,
				fill: "purple",
			},
			{
				name: `Animaux`,
				value: animalMember,
				fill: "#513915",
				// brown
			},
		]
		return newData
	}, [adultMember, animalMember, babyMember, childMember])

	const carePhones: IGeojsonPropertyValue<IPhones[]> =
		care.geojson.properties?.Téléphones ?? []

	const careGeoloc: IGeojsonPropertyValue<IGeoloc> =
		care.geojson.properties?.Adresse ?? {}
	const freePlace = capacite - _.sumBy(pieData, "value")
	return (
		<>
			<StyledCard key={care.id}>
				<Flex directionColumn fullWidth gap="2rem">
					<SAlert severity="info">
						<AlertTitle>{care.geojson.properties.Lieu}</AlertTitle>
						<Flex directionColumn={isMobile} alignItemsInitial gap=".5em">
							<Flex
								directionColumn={isMobile}
								alignItemsInitial
								gap={isDesktop ? "1em" : null}
							>
								<SectionTitle>Téléphones : </SectionTitle>
								<TypePhone
									value={carePhones}
									align="left"
									editable={false}
									fontSize={isMobile ? alertTextSize : "0.9rem"}
								/>
							</Flex>
							<Flex directionColumn alignItemsStart gap=".5em">
								<Flex
									directionColumn={isMobile}
									alignItemsInitial
									gap={isDesktop ? "1em" : null}
								>
									<SectionTitle>Adresse : </SectionTitle>
									<TypeGeoloc
										value={careGeoloc}
										editable={false}
										align="left"
										rowDisplay={isDesktop}
										fontSize={alertTextSize}
									/>
								</Flex>
								{!_.isEmpty(care.geojson.properties?.Responsable) && (
									<Flex
										directionColumn={isMobile}
										alignItemsInitial
										gap={isDesktop ? "1em" : null}
									>
										<SectionTitle>Responsable(s) : </SectionTitle>
										<TypeResponsables
											value={care.geojson.properties?.Responsable}
											align="left"
											editable={false}
											directionColumn={isMobile}
											isSmall={isMobile}
										/>
									</Flex>
								)}
							</Flex>
						</Flex>
					</SAlert>
					<Flex fullWidth alignItemsStart>
						<Flex
							directionColumn={isMobile}
							fullWidth
							alignItemsStart
							spaceBetween
						>
							<Flex directionColumn alignItemsStart>
								<TinyTitle>Capacité</TinyTitle>
								<Flex>
									<CapacityPie
										adultMember={adultMember}
										childMember={childMember}
										babyMember={babyMember}
										animalMember={animalMember}
										freePlace={freePlace}
										capacite={capacite}
										pieData={pieData}
									/>
									<Flex directionColumn gap="2rem">
										{freePlace < 0 && (
											<Alert severity="error">
												Attention, le nombre de places maximum a été atteint
											</Alert>
										)}
										<Grid>
											<Square color="#a6b7fa" />

											<span>Places libres</span>
											<span>{capacite - _.sumBy(pieData, "value")}</span>
											<Square color="#0f206e" />
											<span>Places occupées</span>
											<span>
												{adultMember + childMember + babyMember + animalMember}
											</span>
										</Grid>
									</Flex>
								</Flex>
							</Flex>
							<Flex directionColumn alignItemsStart>
								<TinyTitle>Typologies</TinyTitle>
								<Flex>
									<StyledPieChart width={150} height={150}>
										<Pie
											data={[
												{
													name: "Adultes",
													value: adultMember,
													fill: "green",
												},
												{
													name: "Enfants",
													value: childMember,
													fill: "orange",
												},
												{
													name: `Bébés`,
													value: babyMember,
													fill: "purple",
												},
												{
													name: `Animaux`,
													value: animalMember,
													fill: "#513915",
													// brown
												},
											]}
											dataKey="value"
											innerRadius={45}
											outerRadius={60}
										/>
										<Tooltip />
										<text
											x={75}
											y={75}
											textAnchor="middle"
											dominantBaseline="middle"
											style={{ fontSize: "1.2rem" }}
										>
											{adultMember + childMember + babyMember + animalMember}
										</text>
									</StyledPieChart>
									<Flex directionColumn gap="2rem">
										<Grid>
											<Square color="green" />
											<span>Adultes</span>
											<span>{adultMember}</span>
											<Square color="orange" />
											<span>Enfants</span>
											<span>{childMember}</span>
											<Square color="purple" />
											<span>Bébés</span>
											<span>{babyMember}</span>
											<Square color="#513915" />
											<span>Animaux</span>
											<span>{animalMember}</span>
										</Grid>
									</Flex>
								</Flex>
							</Flex>
							<Flex directionColumn alignItemsStart>
								<TinyTitle>Spécificités</TinyTitle>
								<Flex directionColumn={isDesktop} gap=".5em" $wrap>
									<DynamicFragment>
										{_.isEmpty(specificities) && <div>Aucune spécificité</div>}
										{specificities.map((specificity) => (
											<SChip
												size="small"
												variant="outlined"
												key={specificity.label}
												label={specificity.label}
												avatar={<SAvatar>{specificity.count}</SAvatar>}
											/>
										))}
									</DynamicFragment>
								</Flex>
							</Flex>
						</Flex>
					</Flex>
					{showActions && (
						<Flex gap="0.5rem" flexEnd>
							<PrimaryTextButton
								onClick={() => history.push(`/maincourante/care/${care.id}`)}
							>
								Gérer
							</PrimaryTextButton>
							{showEdition && permissions.write && (
								<>
									<IconButton
										onClick={() => {
											onEditClick(care)
										}}
									>
										<EditIcon />
									</IconButton>
									<IconButton
										onClick={() => {
											setIsOpenModalConfirmation(true)
										}}
									>
										<DeleteIcon />
									</IconButton>
								</>
							)}
						</Flex>
					)}
				</Flex>
			</StyledCard>
			<ConfirmationModal
				modalShown={isOpenModalConfirmation}
				onClose={() => setIsOpenModalConfirmation(false)}
				message="Voulez-vous vraiment supprimer ce centre d'acccueil ?"
				onConfirm={() => {
					dispatch(
						deletePointWithUpdateEvent({
							point: care,
							event: {
								...selectedEvent,
								updated_at: Moment().format(),
							},
						}),
					)
					setIsOpenModalConfirmation(false)
				}}
			/>
		</>
	)
}

export default CareCard
