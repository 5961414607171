import DisplayBeautifulHumans from "@/styles/molecules/humans/DisplayBeautifulHumans/DisplayBeautifulHumans"
import PersonIcon from "@mui/icons-material/Person"
import { EnumToolPath } from "@/hooks/services/useAccessRightsServices"
import useAccessRights from "@/hooks/useAccessRights"
import useCommune from "@/hooks/useCommune"
import useHumans from "@/hooks/useHumans"
import { updateCommune } from "@/redux-toolkit/commune/ressources"
import { PATH_DASHBOARD } from "@/redux-toolkit/userSettings/constants"
import _ from "lodash"
import React, { useState } from "react"
import { useDispatch } from "react-redux"
import styled from "styled-components"
import {
	PrimaryOutlinedButton,
	PrimaryTextButton,
} from "utils/components/style/button"
import { Flex, FlexColumn } from "utils/components/style/flex"
import BoardWidget from "utils/components/widget/BoardWidget"
import FModalHumans from "utils/form/modal/FModalHumans"
import ICommune from "utils/types/ICommune"
import { DragDropContext } from "react-beautiful-dnd"
import SuperDraggable from "utils/dnd/SuperDraggable"
import SuperDroppable from "utils/dnd/SuperDroppable"
import { isMobile } from "react-device-detect"

const SPersonIcon = styled(PersonIcon)`
    color: white;
` as any

const SBeautifulHumansFlex = styled(Flex)<{ $isOrdering: boolean }>`
	width: 100%;
	min-width: ${isMobile ? "none" : "400px"};
    max-height: ${({ $isOrdering }) => ($isOrdering ? "auto" : "240px")};
	overflow: auto;
	padding-bottom: 8px;
`
const SFlex = styled(Flex)`
    height: 100%;
` as any
const SFlexColumn = styled(FlexColumn)`
	padding-left: .5rem;
	gap: 1rem;
`

const ResponsableWidget = ({
	responsableType,
	label,
}: { responsableType: keyof ICommune["responsables"]; label: string }) => {
	const isArray = {
		rgpd: false,
		pcc: true,
		astreinte: true,
		astreinteClosed: true,
	}[responsableType]
	const permissions = useAccessRights(EnumToolPath.CD_DASHBOARD)
	const commune = useCommune()
	const [isOpen, setIsOpen] = useState(false)
	const [isOrdering, setIsOrdering] = useState(false)
	const humans = useHumans()
	const dispatch = useDispatch()
	let responsableIds = (
		isArray
			? commune.responsables?.[responsableType]
			: [commune.responsables?.[responsableType]]
	) as Array<string>

	responsableIds = responsableIds?.filter((responsableId) => {
		return humans[responsableId]
	})

	const onDragEnd = (result) => {
		const newOrder = _.cloneDeep(responsableIds)
		const [respId] = newOrder.splice(result.source.index, 1)
		newOrder.splice(result.destination.index, 0, respId)

		dispatch(
			updateCommune({
				responsables: {
					...commune.responsables,
					[responsableType]: newOrder,
				},
			}),
		)
	}

	return (
		<BoardWidget
			title={label}
			icon={<SPersonIcon fontSize="large" />}
			responsiveHeight={isOrdering}
		>
			<>
				{_.isEmpty(commune.responsables?.[responsableType]) && (
					<Flex
						directionColumn
						alignItemsCenter
						gap="1rem"
						fullWidth
						fullHeight
						justifyCenter
					>
						<PrimaryOutlinedButton onClick={() => setIsOpen(true)}>
							Attribuer un Responsable
						</PrimaryOutlinedButton>
					</Flex>
				)}
				<Flex directionColumn justifyCenter gap="1rem">
					<SBeautifulHumansFlex
						directionColumn
						gap="1rem"
						$isOrdering={isOrdering}
					>
						<DragDropContext onDragEnd={onDragEnd}>
							<SuperDroppable component={SFlexColumn}>
								{(responsableIds ?? []).map((responsableId, index) => {
									const responsable = humans[responsableId]
									if (!responsable) {
										return null
									}
									return (
										<SuperDraggable
											key={responsableId}
											id={responsableId}
											index={index}
											disabled={!isOrdering}
										>
											<SFlex
												fullWidth
												directionColumn
												alignItemsInitial
												spaceBetween
											>
												{commune.responsables?.[responsableType] &&
													responsable && (
														<DisplayBeautifulHumans human={responsable} />
													)}
											</SFlex>
										</SuperDraggable>
									)
								})}
							</SuperDroppable>
						</DragDropContext>
					</SBeautifulHumansFlex>
					{!_.isEmpty(commune.responsables?.[responsableType]) &&
						permissions.write && (
							<Flex gap="1rem">
								{isOrdering && (
									<>
										<PrimaryOutlinedButton onClick={() => setIsOrdering(false)}>
											Terminer
										</PrimaryOutlinedButton>
									</>
								)}
								{!isOrdering && (
									<>
										{isArray && (
											<PrimaryOutlinedButton
												onClick={() => setIsOrdering(true)}
											>
												Ordonner
											</PrimaryOutlinedButton>
										)}
										<PrimaryTextButton onClick={() => setIsOpen(true)}>
											{isArray
												? "Changer les Responsables"
												: "Changer de Responsable"}
										</PrimaryTextButton>
									</>
								)}
							</Flex>
						)}
					<FModalHumans
						multiple={isArray}
						onChange={(humanId) => {
							dispatch(
								updateCommune({
									responsables: {
										...commune.responsables,
										[responsableType]: humanId,
									},
								}),
							)
							return
						}}
						value={commune.responsables?.[responsableType] ?? []}
						isOpen={isOpen}
						onClose={() => {
							setIsOpen(false)
						}}
					/>
				</Flex>
			</>
		</BoardWidget>
	)
}

export default ResponsableWidget
