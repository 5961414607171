import Axios from "axios"
import _ from "lodash"
import ErrorService from "@/services/ErrorService"

const overpassQueryBuilder = (geojson: any) => {
    const allPolygonsQueried = geojson.type === "MultiPolygon"
        ? "(" + fromGeoJsonToQuery(geojson) + ");"
        : geojson.type === "MultiLineString"
            ? "(" + fromGeoJsonToQuery(geojson) + ");"
            : fromGeoJsonToQuery(geojson)

    const query = `[out:json];${allPolygonsQueried}out;`

    return query
}

const fromGeoJsonToQuery = (geojson: any) => {
    switch (geojson.type) {
        case "FeatureCollection":
            const featureCollectionNodes = geojson.features.map((feature) => fromGeoJsonToQuery(feature)).filter((node) => node !== null)
            return "(" + featureCollectionNodes.join('') + ");"
        case "Feature":
            const featureNodes = fromGeoJsonToQuery(geojson.geometry)
            return featureNodes
        case 'Polygon':
            const node = geojson.coordinates[0].map((coo) => coo.reverse().join(' ')).join(' ')
            return `way[highway][name](poly: "${node}");`
        case 'MultiPolygon':
            const nodes = geojson.coordinates.map((polygon) => {
                return polygon[0].map((coo) => coo.reverse().join(' ')).join(' ')
            })
            return nodes.map((node) => `way[highway][name](poly: "${node}");`).join('')
        case "LineString":
            const node2 = geojson.coordinates.map((coo) => coo.reverse().join(', ')).join(', ')
            return `way(around:10, ${node2})["highway"]["name"];`
        case "MultiLineString":
            const nodes2 = geojson.coordinates.map((coo) => coo.map((x) => x.reverse()).join(', '))
            return nodes2.map((node2) => `way(around:10, ${node2})["highway"]["name"];`).join('')
        case "Point":
            return null
        case "MultiPoint":
            return null
        default:
            return null
    }
}

const fetchStreetsInPolygon = async (geojson: any, signal: any, dispatch: any) => {
    try {
        //* overpassQueryBuilder will need to reverse the geojson coordinates, so we clone it to avoid mutation
        const geojsonForQuery = _.cloneDeep(geojson)
        const query = overpassQueryBuilder(geojsonForQuery)
        const res = await Axios.post('https://overpass-api.de/api/interpreter?data=', query, {
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            signal: signal,
        })

        return res.data.elements
    } catch (error) {
        if (Axios.isCancel(error)) return error

        const errorMessage = `Erreur lors de la récupération des rues : ${error.message}`
        ErrorService.error({
            component: "ApiOverpassTurbo:fetchStreetsInPolygon",
            message: errorMessage,
            dispatch,
        })

        return error
    }
}

export default {
    fetchStreetsInPolygon,
}