import React, { useEffect, useMemo, useState } from "react"
import { Flex } from "@/utils/components/style/flex"
import FAdminJsonSchemas from "@/utils/form/FAdminJsonSchemas"
import useSelectedEvent from "@/hooks/useSelectedEvent"
import useJsonSchemasWithStatics from "@/hooks/useJsonSchemasWithStatics"
import useMapState from "@/hooks/useMapState"
import useMultiPolygons from "@/hooks/useMultiPolygons"
import {
	Alert,
	Checkbox,
	IconButton,
	ListSubheader,
	MenuItem,
	TextField,
} from "@mui/material"
import MultiPolygonColorSquare from "@/utils/components/map/polygon/MultiPolygonColorSquare"
import LayersIcon from "@mui/icons-material/Layers"
import { useBoolean } from "react-use"
import CartographyPreview from "@/pages/carto2/cartographie/CartographyPreview"
import { useFormContext } from "react-hook-form"
import usePoints from "@/hooks/usePoints"
import MarkerDaybook from "@/pages/maincourante/subComponents/carto/MarkerDaybook/MarkerDaybook"
import _ from "lodash"
import getPolygonStyle from "@/utils/components/map/polygon/getPolygonStyle"
import { Pane, GeoJSON } from "react-leaflet"
import DatabaseService from "@/services/DatabaseService"
import { v4 as uuidv4 } from "uuid"
import ModalSelectMultipolygon from "@/utils/modals/ModalSelectMultipolygon"
import styled from "styled-components"
import CustomTabs from "@/utils/components/customTabs/CustomTabs"
import SuperReactTable from "@/utils/components/tables/SuperReactTable/SuperReactTable"
import {
	useColumnsDaybookAlertContactTable,
	useTransformedAndSortedDataDaybookAlertContactTable,
} from "../DaybookAlertContactTable/DaybookAlertContactTable.hook"
import DaybookAlertConcernedStreets from "./DaybookAlertConcernedStreets"
import { useDebounce } from "react-use"
import DaybookAlertServices from "@/utils/DaybookAlertServices"
import { AnyGeojsonFeature } from "@/utils/types/ITurfGeojson"
import EditIcon from "@mui/icons-material/Edit"
import ModalBuffing from "@/utils/modals/UploadModalSteps/ModalBuffing"
import IPoint from "@/utils/types/IPoint"
import JsonSchemaService from "@/pages/carto2/cartographie/service/JsonSchemaService"
import { useDispatch } from "react-redux"
import FSwitch from "@/utils/form/FSwitch"
import { setPanel } from "@/redux-toolkit/common/reducer"
import { DAYBOOK_POINT } from "@/utils/panels/PanelCarto"
import IMultiPolygon from "@/utils/types/IMultiPolygon"
import { useOrgaPoints } from "@/hooks/useOrga"

const SAlert = styled(Alert)`
	width: 100%;
`
const SMenuItem = styled(MenuItem)`
	display: none !important;
`
const SFlex = styled(Flex)`
	width: 400px;
`

/**
 * @unit: Considered as kilo-octets (ko)
 * @location: See getGeojsonSize() from DaybookAlertServices.tsx
 */
const geojsonSizeThreshold = 500

const DaybookAlertPrepareUsingModal = () => {
	const points = usePoints()
	const multiPolygons = useMultiPolygons()
	const jsonSchemas = useJsonSchemasWithStatics()
	const { coucheMultiPolygons } = useMapState()
	const selectedEvent = useSelectedEvent()
	const isLocked = selectedEvent?.locked
	const columns = useColumnsDaybookAlertContactTable()
	const orgaPoints = useOrgaPoints()
	const { getValues, setValue } = useFormContext()
	const [isOpenModalSelectMultipolygon, setIsOpenModalSelectMultipolygon] =
		useBoolean(false)
	const [selectedJsonSchemaIds, setSelectedJsonSchemaIds] = useState<string[]>(
		[],
	)

	const [oldOriginalGeojson, setOldOrignialGeojson] =
		useState<AnyGeojsonFeature>(undefined)
	const [originalGeojson, setOriginalGeojson] =
		useState<AnyGeojsonFeature>(undefined)
	const [currentGeojson, setCurrentGeojson] =
		useState<AnyGeojsonFeature>(undefined)
	const [oldRangeValue, setOldRangeValue] = useState(0)
	const [isSelectingRange, setIsSelectingRange] = useBoolean(false)
	const [isLoading, setIsLoading] = useBoolean(false)
	const dispatch = useDispatch()

	const listeDiffusionIds = getValues(
		"geojson.properties.listeDiffusionIds",
	) as string[]
	const selectedMultiPolygonId = getValues(
		"geojson.properties.selectedMultiPolygonId",
	) as string
	const rangeValue = getValues("geojson.properties.rangeValue") ?? 0
	const keepUngeolocDatas =
		getValues("geojson.properties.keepUngeolocDatas") ?? true

	const data = useMemo(() => {
		return Object.values(points).filter((point) =>
			listeDiffusionIds.includes(point.id),
		)
	}, [listeDiffusionIds, points])

	const transformedData = useTransformedAndSortedDataDaybookAlertContactTable(
		data,
		listeDiffusionIds,
	)

	let pointsToDisplay = Object.values(points).filter((point) =>
		selectedJsonSchemaIds?.includes(point.jsonschema_id),
	)
	if (selectedJsonSchemaIds?.includes("Organigramme")) {
		pointsToDisplay.push(...orgaPoints.map((point) => points[point]))
		pointsToDisplay = pointsToDisplay.filter((point) => point)
		pointsToDisplay = _.uniqBy(pointsToDisplay, "id")
	}

	const updateRangeBuff = (newRangeValue: number) => {
		const concernedGeojson =
			newRangeValue > rangeValue ? currentGeojson : originalGeojson

		setIsSelectingRange(false)

		if (_.isEqual(newRangeValue, rangeValue)) return
		if (
			originalGeojson &&
			DaybookAlertServices.getGeojsonSize(concernedGeojson) >
				geojsonSizeThreshold &&
			newRangeValue > 0
		)
			setIsLoading(true)

		setOldRangeValue(rangeValue)
		setValue("geojson.properties.rangeValue", newRangeValue)
	}

	const getMultiPolygonBounds = async (polygonId: string) => {
		const res = (await DatabaseService.get(polygonId)) as string
		const parsedRes = JSON.parse(res) as AnyGeojsonFeature

		setOriginalGeojson(parsedRes)
	}

	useEffect(() => {
		if (_.isEmpty(selectedMultiPolygonId)) return
		getMultiPolygonBounds(selectedMultiPolygonId)
	}, [selectedMultiPolygonId])

	useEffect(() => {
		const listeDiffusions = getValues(
			"geojson.properties.listeDiffusions",
		) as string[]

		setSelectedJsonSchemaIds(listeDiffusions)
		if (_.isEmpty(listeDiffusions))
			setValue("geojson.properties.listeDiffusionIds", [])
	}, [getValues("geojson.properties.listeDiffusions")])

	useEffect(() => {
		if (
			!originalGeojson ||
			(rangeValue === 0 && _.isEqual(currentGeojson, originalGeojson))
		)
			return

		let geojson
		let buffValue

		const rangeDiff = !!currentGeojson ? rangeValue - oldRangeValue : rangeValue // Range value already set when geojson wasn't selected, we wont apply the diff
		const hasChanged = !_.isEqual(originalGeojson, oldOriginalGeojson)

		if (rangeDiff > 0 && !hasChanged) {
			//* Buffing the current buffred geojson with the rangeDiff for better process performance
			geojson = currentGeojson
			buffValue = rangeDiff
		} else {
			//* Cannot debuff a geojson with a negative rangeDiff, we have to re-buff the original geojson
			geojson = originalGeojson
			buffValue = rangeValue
		}

		if (
			DaybookAlertServices.getGeojsonSize(geojson) > geojsonSizeThreshold &&
			rangeValue > 0
		)
			setIsLoading(true)

		const process = async () => {
			const onError = () =>
				setValue("geojson.properties.rangeValue", oldRangeValue)
			const res = (await DaybookAlertServices.buffSelectedGeojson(
				geojson as AnyGeojsonFeature,
				buffValue as number,
				originalGeojson,
				rangeValue,
				setIsLoading,
				dispatch,
				onError,
			)) as AnyGeojsonFeature

			if (hasChanged) setOldOrignialGeojson(originalGeojson)
			setCurrentGeojson(res)
		}

		if (DaybookAlertServices.getGeojsonSize(geojson) > geojsonSizeThreshold) {
			// Let the time to the modal to open before process is freezing the UI
			setTimeout(() => process(), 1000)
		} else {
			process()
		}
	}, [originalGeojson, rangeValue])

	useEffect(() => {
		if (_.isEmpty(selectedJsonSchemaIds) || !currentGeojson) return

		const pointIdsWithoutAdresse: string[] = []
		const pointsToFilter: IPoint[] = Object.values(points)
			.map((point) => {
				if (
					selectedJsonSchemaIds.includes(point.jsonschema_id) ||
					(selectedJsonSchemaIds.includes("Organigramme") &&
						orgaPoints.includes(point.id))
				) {
					const geolocKey = JsonSchemaService.getGeolocProperty(
						jsonSchemas[point.jsonschema_id],
					).name
					const coos = point.geojson.properties[geolocKey].coo
					if (_.isEmpty(coos)) {
						if (keepUngeolocDatas) pointIdsWithoutAdresse.push(point.id)
						return
					}
					return point
				}
			})
			.filter((point) => point)

		const asyncProcess = async () => {
			const pointsInGeojson = await DaybookAlertServices.filterPointsInGeojson(
				pointsToFilter,
				currentGeojson,
				jsonSchemas,
			)

			const pointsIdsInGeojson = pointsInGeojson.map((point) => point.id)
			const result = [...pointIdsWithoutAdresse, ...pointsIdsInGeojson]

			setValue("geojson.properties.listeDiffusionIds", result)
		}

		asyncProcess()
	}, [selectedJsonSchemaIds, currentGeojson, keepUngeolocDatas])

	return (
		<>
			<Flex fullWidth>
				<CustomTabs
					fullWidthWrapper
					tabChildrens={[
						{
							key: "Sélection",
							render: (
								<Flex directionColumn gap="1rem">
									<FSwitch
										label="Inclure les numéros de téléphones fixes"
										name="geojson.properties.includeFixedPhones"
									/>
									<FAdminJsonSchemas
										multiple
										label="Liste de diffusion"
										name="geojson.properties.listeDiffusions"
										additionalFilter={(jsonSchemaId: string) => {
											return jsonSchemas[jsonSchemaId].is_telealert
										}}
										additionalList={["Organigramme"]}
										disabled={isLocked}
									/>
									<Flex fullWidth gap="1rem">
										<TextField
											fullWidth
											select
											value={selectedMultiPolygonId ?? ""}
											label="Zone cartographique"
											onChange={(event) => {
												if (event.target.value === "more") {
													return setIsOpenModalSelectMultipolygon(true)
												}
												setValue(
													"geojson.properties.selectedMultiPolygonId",
													event.target.value,
												)
											}}
											disabled={selectedEvent?.locked}
										>
											<ListSubheader>
												Surfaces cartographique de la crise
											</ListSubheader>
											{Object.values(coucheMultiPolygons).map(
												(multiPolygon: IMultiPolygon) => {
													if (!multiPolygons[multiPolygon.id]) return null
													return (
														<MenuItem
															key={multiPolygon.id}
															value={multiPolygon.id}
														>
															<Flex gap={10}>
																<MultiPolygonColorSquare
																	multiPolygon={multiPolygons[multiPolygon.id]}
																	size={"small"}
																/>
																{multiPolygons[multiPolygon.id]?.name}
															</Flex>
														</MenuItem>
													)
												},
											)}
											{Object.values(multiPolygons)
												.filter((multipolygon) => !multipolygon.event_id)
												.map((multiPolygon) => (
													<SMenuItem
														key={multiPolygon.id}
														value={multiPolygon.id}
													>
														<Flex gap={10}>
															<MultiPolygonColorSquare
																multiPolygon={multiPolygons[multiPolygon.id]}
																size={"small"}
															/>
															{multiPolygons[multiPolygon.id]?.name}
														</Flex>
													</SMenuItem>
												))}
											<ListSubheader>Autres</ListSubheader>
											<MenuItem value="more">
												<Flex gap={10}>
													<LayersIcon />
													<div>Choisir une autre surface</div>
												</Flex>
											</MenuItem>
										</TextField>
										<SFlex spaceBetween>
											<div>
												Étendue sur : <b>{rangeValue} mètres</b>.
											</div>
											<IconButton onClick={() => setIsSelectingRange(true)}>
												<EditIcon />
											</IconButton>
										</SFlex>
									</Flex>
									{!_.isEqual(
										DaybookAlertServices.nbDatasWithoutGeoloc(
											pointsToDisplay,
											jsonSchemas,
										),
										0,
									) && (
										<SAlert severity="info">
											<Flex directionColumn>
												<div>
													Attention, un où plusieurs annuaires contiennent des
													données sans géolocalisation.
												</div>
												<Flex fullWidth gap={10}>
													<Checkbox
														checked={keepUngeolocDatas}
														onChange={() =>
															setValue(
																"geojson.properties.keepUngeolocDatas",
																!keepUngeolocDatas,
															)
														}
													/>
													<div>
														Inclure les données sans géolocalisation{" "}
														<b>
															{DaybookAlertServices.nbDatasWithoutGeoloc(
																pointsToDisplay,
																jsonSchemas,
															)}{" "}
															donnée(s)
														</b>
													</div>
												</Flex>
											</Flex>
										</SAlert>
									)}
									{!_.isEmpty(listeDiffusionIds) && (
										<SAlert severity="success">
											{listeDiffusionIds.length === 1 && (
												<div>
													<b>{listeDiffusionIds.length} donnée</b> va être
													ajoutée à la liste de diffusion.
												</div>
											)}
											{listeDiffusionIds.length > 1 && (
												<div>
													<b>{listeDiffusionIds.length} données</b> vont être
													ajoutée à la liste de diffusion.
												</div>
											)}
										</SAlert>
									)}
								</Flex>
							),
						},
						{
							key: "Aperçu cartographique",
							render: (
								<CartographyPreview mapDimensions={{ height: "400px" }}>
									<>
										{pointsToDisplay.map((point) => (
											<MarkerDaybook
												key={uuidv4()}
												point={point}
												handleClick={() =>
													dispatch(
														setPanel({
															type: DAYBOOK_POINT,
															id: point.id,
														}),
													)
												}
												noInteraction
											/>
										))}
										{selectedMultiPolygonId && (
											<Pane
												name={selectedMultiPolygonId}
												key={`${selectedMultiPolygonId}:500`}
												style={{
													zIndex: 500,
												}}
											>
												{currentGeojson && (
													<GeoJSON
														key={uuidv4()}
														data={currentGeojson}
														style={() =>
															getPolygonStyle(
																multiPolygons[selectedMultiPolygonId],
															)
														}
													/>
												)}
											</Pane>
										)}
									</>
								</CartographyPreview>
							),
						},
						{
							key: "Rue(s) concernée(s)",
							render: (
								<DaybookAlertConcernedStreets
									multiPolygonBounds={currentGeojson}
								/>
							),
						},
						{
							key: "Donnée(s) concernée(s)",
							render: (
								<SuperReactTable
									columns={columns}
									data={transformedData}
									selectable={false}
									isEditable={false}
									isDeletable={false}
								/>
							),
						},
					]}
				/>
			</Flex>
			<ModalSelectMultipolygon
				isOpen={isOpenModalSelectMultipolygon}
				onClose={() => setIsOpenModalSelectMultipolygon(false)}
				value={selectedMultiPolygonId ?? ""}
				onSelect={(id) => {
					setValue("geojson.properties.selectedMultiPolygonId", id)
					setIsOpenModalSelectMultipolygon(false)
				}}
			/>
			<ModalBuffing
				isSelectingRange={isSelectingRange}
				setIsSelectingRange={setIsSelectingRange}
				isLoading={isLoading}
				updateRangeBuff={updateRangeBuff}
			/>
		</>
	)
}

export default DaybookAlertPrepareUsingModal
