import { ColumnDef } from "@tanstack/react-table"
import usePoints from "@/hooks/usePoints"
import Debug from "debug"
import _ from "lodash"
import React, { useEffect, useMemo, useState } from "react"
import { useFormContext } from "react-hook-form"
import { PrimaryOutlinedButton } from "@/utils/components/style/button"
import { Flex } from "@/utils/components/style/flex"
import SuperReactTable from "@/utils/components/tables/SuperReactTable"
import {
	B,
	IndeterminateCheckbox,
} from "@/utils/components/tables/SuperReactTable/SuperReactTable.styled"
import { width30 } from "@/utils/components/tables/widthProps"
import IJsonSchema from "@/utils/types/IJsonSchema"
import {
	useColumnsDaybookAlertContactTable,
	useTransformedAndSortedDataDaybookAlertContactTable,
} from "./DaybookAlertContactTable.hook"
import Filter from "@/pages/maincourante/following/filter/Filter"
import { useEffectOnce } from "react-use"
import useCommune from "@/hooks/useCommune"
import { useOrgaPoints } from "@/hooks/useOrga"
import useJsonSchemasWithStatics from "@/hooks/useJsonSchemasWithStatics"
import JsonSchemaService from "@/pages/carto2/cartographie/service/JsonSchemaService"
import PointProperty from "@/utils/components/jsonSchema/properties/PointProperty"
import useFilterForm from "@/pages/maincourante/following/filter/useFilterForm"
import FSelectWithImage from "@/utils/form/FSelectWithImage"

// app:javascript:components:maincourante:alert:alertCreation:DaybookAlertContactTable.tsx
const debug = Debug(
	"app:javascript:components:maincourante:alert:alertCreation:DaybookAlertContactTable",
)
debug.log = console.log.bind(console)

const DaybookAlertContactTable = ({
	jsonSchemaIds,
	disabled = false,
}: {
	jsonSchemaIds: IJsonSchema["id"][]
	disabled?: boolean
}) => {
	const { setValue, watch } = useFormContext()

	const columns = useColumnsDaybookAlertContactTable()
	const commune = useCommune()
	const orgaPoints = useOrgaPoints()
	const jsonSchemasWithStatics = useJsonSchemasWithStatics()

	const points = usePoints()

	const data = useMemo(() => {
		const tmpData = Object.values(points).filter((point) =>
			jsonSchemaIds.includes(point.jsonschema_id),
		)
		if (jsonSchemaIds.includes("Organigramme")) {
			orgaPoints.forEach((membreId) => {
				const person = points[membreId]
				if (person && tmpData.findIndex((p) => p.id === person.id) === -1) {
					tmpData.push(person)
				}
			})
		}
		return tmpData
	}, [jsonSchemaIds, points])

	const listeDiffusionsPersistante = watch(
		"geojson.properties.listeDiffusionsPersistante",
	)
	const listeDiffusions = watch("geojson.properties.listeDiffusions")
	const listeDiffusionIds = watch("geojson.properties.listeDiffusionIds")

	const [oldListeDiffusions, setOldListeDiffusions] = useState(
		listeDiffusionsPersistante,
	)
	const transformedData = useTransformedAndSortedDataDaybookAlertContactTable(
		data,
		listeDiffusions,
	)

	useEffectOnce(() => {
		if (listeDiffusionsPersistante) {
			setOldListeDiffusions(listeDiffusionsPersistante)
			setValue("geojson.properties.listeDiffusionsPersistante", undefined)
		}
	})

	useEffect(() => {
		if (_.isEmpty(listeDiffusions)) {
			setValue("geojson.properties.listeDiffusionIds", [])
			setOldListeDiffusions(undefined)
		}
		if (listeDiffusions && !_.isEqual(listeDiffusions, oldListeDiffusions)) {
			if (oldListeDiffusions || _.isEmpty(listeDiffusionIds || true)) {
				const addedJsonSchemaIds = _.difference(
					listeDiffusions,
					oldListeDiffusions,
				)
				const concernedPoints = data.filter((point) => {
					if (listeDiffusions.includes("Organigramme")) {
						if (orgaPoints.includes(point.id)) {
							return true
						}
					}
					return listeDiffusions.includes(point.jsonschema_id)
				})
				setValue(
					"geojson.properties.listeDiffusionIds",
					_.uniq(
						concernedPoints
							.map((point) => {
								if (
									addedJsonSchemaIds.includes(point.jsonschema_id) ||
									listeDiffusionIds.includes(point.id) ||
									(listeDiffusions.includes("Organigramme") &&
										orgaPoints.includes(point.id))
								)
									return point.id
							})
							.filter((element) => !!element),
					),
				)
			}
			setOldListeDiffusions(listeDiffusions)
		}
	}, [listeDiffusions, listeDiffusionIds, oldListeDiffusions])

	const rowDefaultSelection = useMemo(() => {
		const res = {}
		const addedJsonSchemaIds = _.difference(listeDiffusions, oldListeDiffusions)

		data?.forEach((point) => {
			if (
				listeDiffusionIds.includes(point.id) ||
				addedJsonSchemaIds.includes(point.jsonschema_id) ||
				(listeDiffusions.includes("Organigramme") &&
					orgaPoints.includes(point.id))
			)
				res[point.id] = true
		})

		return res
	}, [data])

	const listeDiffusionContainsSelectionWithImage = useMemo(() => {
		const res = listeDiffusions.filter((id) => {
			const jsonSchema = jsonSchemasWithStatics[id]
			if (!jsonSchema) {
				return false
			}
			return !!JsonSchemaService.getFilterProperty(jsonSchema)
		})
		return !_.isEmpty(res)
	}, [listeDiffusions, jsonSchemasWithStatics])

	const filterOption = useMemo(() => {
		const tmpFilterOptions = []
		listeDiffusions.forEach((id) => {
			const jsonSchema = jsonSchemasWithStatics[id]
			if (jsonSchema) {
				const tmpFilterOptionProperty =
					JsonSchemaService.getFilterProperty(jsonSchema)
				if (tmpFilterOptionProperty) {
					tmpFilterOptions.push({
						name: tmpFilterOptionProperty.name,
						component: (props) => {
							return (
								<FSelectWithImage
									{...props}
									options={tmpFilterOptionProperty.itemsImage}
									placeholder="Ajouter un filtre"
								/>
							)
						},
						default: [],
					})
				}
			}
		})
		return tmpFilterOptions
	}, [listeDiffusions, jsonSchemasWithStatics])

	const { filteredData, ...filterConfig } = useFilterForm({
		options: filterOption,
		data: transformedData,
	})

	const augmentedColumns = useMemo(
		() =>
			[
				{
					id: "selection_id",
					...width30,
					enableSorting: false,
					// The header can use the table's getToggleAllRowsSelectedProps method
					// to render a checkbox
					header: ({ table }) => {
						return (
							<IndeterminateCheckbox
								{...{
									checked: listeDiffusionIds?.length === data.length,
									onChange: () => {
										console.log(filteredData)
										console.log(listeDiffusionIds)
										if (
											_.uniq(listeDiffusionIds).length ===
											_.uniq([
												...filteredData.map((point) => point.id),
												...listeDiffusionIds,
											]).length
										) {
											setValue("geojson.properties.listeDiffusionIds", [])
										} else {
											setValue(
												"geojson.properties.listeDiffusionIds",
												_.uniq([
													...filteredData.map((point) => point.id),
													...listeDiffusionIds,
												]),
											)
										}
									},
									indeterminate:
										listeDiffusionIds?.length > 0 &&
										listeDiffusionIds?.length < data.length,
								}}
								disabled={disabled}
							/>
						)
					},
					// The cell can use the individual row's getToggleRowSelectedProps method
					// to the render a checkbox
					cell: ({ row }) => (
						<IndeterminateCheckbox
							{...{
								checked: listeDiffusionIds?.includes(row.original.id),
								onChange: () => {
									if (listeDiffusionIds?.includes(row.original.id)) {
										setValue(
											"geojson.properties.listeDiffusionIds",
											listeDiffusionIds.filter((id) => id !== row.original.id),
										)
									} else {
										setValue("geojson.properties.listeDiffusionIds", [
											...listeDiffusionIds,
											row.original.id,
										])
									}
								},
							}}
							disabled={disabled}
						/>
					),
				},
				...columns,
				...(listeDiffusionContainsSelectionWithImage
					? ([
							{
								id: "selection_image",
								cell: ({ row, table }) => {
									if (
										!JsonSchemaService.getFilterProperty(
											jsonSchemasWithStatics[row.original.jsonschema_id],
										)
									) {
										return null
									}
									return (
										<>
											<PointProperty
												point={row.original}
												property={JsonSchemaService.getFilterProperty(
													jsonSchemasWithStatics[row.original.jsonschema_id],
												)}
												editable
											/>
										</>
									)
								},
								enableSorting: false,
							},
						] as ColumnDef<any>[])
					: []),
			] as ColumnDef<any>[],
		[
			columns,
			data,
			listeDiffusionIds,
			listeDiffusionContainsSelectionWithImage,
		],
	)

	if (_.isEmpty(listeDiffusions)) {
		return null
	}

	return (
		<Flex directionColumn alignItemsInitial fullWidth>
			<span>
				<B>{listeDiffusionIds?.length}</B> personne(s) sélectionné(s) sur{" "}
				{data.length}
			</span>
			<SuperReactTable
				noDefer
				{...(listeDiffusionContainsSelectionWithImage
					? { actionsButtons: <Filter {...filterConfig} /> }
					: {})}
				rowSelection={rowDefaultSelection}
				onRowClick={(row) => {
					if (listeDiffusionIds?.includes(row.original.id)) {
						setValue(
							"geojson.properties.listeDiffusionIds",
							listeDiffusionIds.filter((id) => id !== row.original.id),
						)
					} else {
						setValue("geojson.properties.listeDiffusionIds", [
							...listeDiffusionIds,
							row.original.id,
						])
					}
				}}
				isDeletable={false}
				isEditable={false}
				selectable={false}
				disableMenuActions
				displayNumberOfSelectedRows
				columns={augmentedColumns}
				data={filteredData}
			/>
		</Flex>
	)
}

export default DaybookAlertContactTable
