import { EditorState } from "draft-js"
import { useContext } from "react"
import { EditorContext } from "../HTMLEditor"

function useEditorFocus() {
	const editor = useContext(EditorContext)

	const focus = () =>
		editor.setEditorState(
			EditorState.forceSelection(
				editor.editorState,
				editor.editorState.getSelection(),
			),
		)

	const delay = (fun) => (...args) => {
		setTimeout(() => fun(...args), 5)
	}

	return { focus, delay }
}

export default useEditorFocus
