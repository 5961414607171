import ReportService from "@/pages/carto2/cartographie/service/ReportService"
import _ from "lodash"
import React from "react"
import SmallText from "@/utils/pdf/SmallText"
import PCell from "@/utils/pdf/table/PCell"
import PRow from "@/utils/pdf/table/PRow"
import PTable from "@/utils/pdf/table/PTable"

const DaybookSuivisTable = ({ suivisList, humans }) => {
	return (
		<PTable marginBottom="0px">
			<PRow>
				<PCell minHeight={0} head>
					<SmallText>Action</SmallText>
				</PCell>
				<PCell minHeight={0} width={80} head>
					<SmallText>Date contact</SmallText>
				</PCell>
				<PCell minHeight={0} head>
					<SmallText>Référents</SmallText>
				</PCell>
				<PCell minHeight={0} head>
					<SmallText>Observations</SmallText>
				</PCell>
			</PRow>
			{suivisList.map((suivi: any, index) => {
				return (
					<PRow key={index} style={{ height: "auto" }}>
						<PCell>
							<SmallText>{suivi["Suites à donner"]}</SmallText>
						</PCell>
						<PCell width={80}>
							{suivi["Date prise de contact"] &&
								suivi["Date prise de contact"] !== "Invalid date" && (
									<>
										<SmallText>
											Le {suivi["Date prise de contact"].slice(0, 10)}
										</SmallText>
										<SmallText>
											à {suivi["Date prise de contact"].slice(11, 13)}
											h
											{suivi["Date prise de contact"].slice(14, 16)}
										</SmallText>
									</>
								)}
							{!suivi["Date prise de contact"] ||
								(suivi["Date prise de contact"] == "Invalid date" && (
									<SmallText>Aucune</SmallText>
								))}
						</PCell>
						<PCell>
							{ReportService.displayResps(humans, suivi.responsableId)}
						</PCell>
						<PCell>
							<SmallText>
								{!_.isEmpty(suivi["Observation"]) && suivi["Observation"]}
								{_.isEmpty(suivi["Observation"]) && "Aucune"}
							</SmallText>
						</PCell>
					</PRow>
				)
			})}
		</PTable>
	)
}

export default DaybookSuivisTable
