import { updateFiche } from "@/redux-toolkit/data/fiche/resources"
import { useCallback } from "react"
import { useDispatch } from "react-redux"
import IFiche, { TypeFiche } from "utils/types/IFiche"

const useRenameFiche = (fiche: IFiche) => {
	const dispatch = useDispatch()

	return useCallback(
		(newName: string) => {
			if (fiche.classification === TypeFiche.FICHE_ACTION) return
			dispatch(
				updateFiche({
					...fiche,
					nom: newName,
				}),
			)
		},
		[fiche],
	)
}

export default useRenameFiche
