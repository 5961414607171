import { TextField } from "@mui/material"
import React, { useEffect, useState } from "react"
import { PrimaryButton } from "utils/components/style/button"
import { Flex } from "utils/components/style/flex"
import FormStep from "../../component/FormStep"
import useStep from "../../hooks/useStep"

const StepName = ({ ...props }) => {
	const { value, setStepValue, onBack, dependencies, shouldReset } = useStep(
		"name",
		{ dependencies: ["file"] },
	)
	const [localValue, setLocalValue] = useState("")

	useEffect(() => {
		if (shouldReset || dependencies.file)
			setLocalValue(
				dependencies.file?.name.match(/(?<base>.+?)(?<ext>\.[a-zA-Z0-9]+)?$/)
					.groups.base,
			)
	}, [dependencies.file, shouldReset])

	return (
		<FormStep
			question="Comment souhaitez-vous nommer votre fichier ?"
			formatedValue={value ? value : undefined}
			onBack={onBack}
			{...props}
		>
			<Flex gap={5} directionColumn alignItemsStretch>
				<TextField
					fullWidth
					value={localValue}
					onChange={(event) => setLocalValue(event.target.value)}
					placeholder="Nom du fichier"
				/>
				<Flex flexEnd>
					<PrimaryButton
						disabled={!localValue}
						onClick={() => setStepValue(localValue)}
					>
						Valider
					</PrimaryButton>
				</Flex>
			</Flex>
		</FormStep>
	)
}

export default StepName
