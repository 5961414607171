import { updateCommune } from "@/redux-toolkit/commune/ressources"
import { useDispatch } from "react-redux"
import useCommune from "./useCommune"

function useOrderedElus() {
	const dispatch = useDispatch()
	return {
		orderedElus: useCommune().orderedElus,
		setOrderedElus: (order) =>
			dispatch(
				updateCommune({
					orderedElus: order,
				}),
			),
	}
}

export default useOrderedElus
