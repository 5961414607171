import React, { useState } from "react"
import Debug from "debug"
import { useFormContext, useWatch } from "react-hook-form"
import { IconButton } from "@mui/material"
import DeleteIcon from "@mui/icons-material/Delete"
import { Flex } from "utils/components/style/flex"
import ConfirmationModal from "utils/components/ConfirmationModal"
import FDateTime from "../FDateTime"
import styled from "styled-components"
import FDraft from "../specific/fiche/FDraft"
import { LINK, ORDERED } from "../specific/fiche/EditorToolbar"

// app:javascript:utils:form:FComments.tsx
const debug = Debug("app:javascript:utils:form:FComments")
debug.log = console.log.bind(console)

const SFlex = styled(Flex)`
    margin-bottom: 1em;
    &:first-child {
        margin-top: 1em;
    }
`

const FComments = ({
	name,
	messageConfirmation = "Voulez-vous vraiment supprimer le commentaire",
	disabled = false,
}) => {
	const { control, setValue } = useFormContext()
	const [isOpenModalConfirm, setIsOpenModalConfirm] = useState(false)
	const [currentIndex, setCurrentIndex] = useState(undefined)

	const value = useWatch({ name, control })

	return (
		<Flex fullWidth directionColumn gap=".75em">
			{(value ?? []).map((field, index) => (
				<SFlex key={index} fullWidth alignItemsStart gap=".5em">
					<Flex fullWidth directionColumn gap=".75em">
						<FDateTime
							name={`${name}[${index}].date`}
							label={`Date du point de situation - ${index + 1}`}
							disabled={disabled}
						/>
						<FDraft
							name={`${name}[${index}].objet`}
							label={`Point de situation - ${index + 1}`}
							extraToolbarButtons={[LINK]}
							hideToolbarButtons={[ORDERED]}
							isImportable={false}
							disabled={disabled}
						/>
					</Flex>
					<IconButton
						onClick={() => {
							setCurrentIndex(index)
							setIsOpenModalConfirm(true)
						}}
						disabled={disabled}
					>
						<DeleteIcon />
					</IconButton>
				</SFlex>
			))}

			<ConfirmationModal
				modalShown={isOpenModalConfirm}
				onClose={() => setIsOpenModalConfirm(false)}
				onConfirm={() => {
					setValue(
						name,
						value.filter((_, index) => index !== currentIndex),
					)
					setIsOpenModalConfirm(false)
				}}
				message={`${messageConfirmation} ${currentIndex + 1} ?`}
			/>
		</Flex>
	)
}

export default FComments
