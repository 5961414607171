import { Badge, IconButton, Popover } from "@mui/material"
import FormatColorFillIcon from "@mui/icons-material/FormatColorFill"
import FormatColorTextIcon from "@mui/icons-material/FormatColorText"
import React, { useRef, useState } from "react"
import styled from "styled-components"
import ColorPicker from "utils/components/ColorPicker"
import ToolbarPart from "./ToolbarPart"
import useEditorFocus from "./useEditorFocus"

const ColouredBadge = styled(Badge)<{ $badgeColor: string }>`
    .MuiBadge-badge {
        background-color: ${({ $badgeColor }) => $badgeColor};
    }
`

function ColorPickerToolbar({ currentState, onChange }) {
	const { bgColor, color } = currentState

	const [selected, setSelected] = useState(null)
	const colorRef = useRef()
	const bgColorRef = useRef()

	const pickerColor = selected === "color" ? color : bgColor
	const setPickerColor = (col) => {
		onChange(selected, col)
	}

	const { focus } = useEditorFocus()

	return (
		<ToolbarPart>
			<IconButton ref={colorRef} onClick={() => setSelected("color")}>
				<ColouredBadge
					overlap="circular"
					$badgeColor={color ?? "black"}
					variant="dot"
				>
					<FormatColorTextIcon color="action" />
				</ColouredBadge>
			</IconButton>
			<IconButton ref={bgColorRef} onClick={() => setSelected("bgcolor")}>
				<ColouredBadge
					overlap="circular"
					$badgeColor={bgColor ?? "white"}
					variant="dot"
				>
					<FormatColorFillIcon color="action" />
				</ColouredBadge>
			</IconButton>

			<Popover
				open={selected !== null}
				onClose={() => {
					setSelected(null)
					focus()
				}}
				anchorEl={selected === "color" ? colorRef.current : bgColorRef.current}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "center",
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: "center",
				}}
			>
				<ColorPicker value={pickerColor} setValue={setPickerColor} />
			</Popover>
		</ToolbarPart>
	)
}

export default ColorPickerToolbar
