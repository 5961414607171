import React, { Component } from "react"
import { string } from "prop-types"
import styled, { createGlobalStyle } from "styled-components"

const LoadingContainer = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    background-color: rgba(2, 5, 0, 0.7);
    width: 100vw;
    z-index: 999999;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`

const GlobalStyle = createGlobalStyle`
//Ellipsis
.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
}
.lds-ellipsis div {
  position: absolute;
  top: 27px;
  width: 11px;
  height: 11px;
  border-radius: 50%;
  background: #cef;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 6px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 6px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 26px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 45px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(19px, 0);
  }
}

`

class OverlayLoading extends Component {
	props: any
	render() {
		const { isActive, text } = this.props
		if (!isActive) {
			return null
		}
		return (
			<LoadingContainer>
				<GlobalStyle />
				<div className="lds-ellipsis">
					<div />
					<div />
					<div />
					<div />
				</div>
				<h4 style={{ color: "white" }}>{text}Chargement en cours</h4>
			</LoadingContainer>
		)
	}
}

export default OverlayLoading
