import { yupResolver } from "@hookform/resolvers/yup"
import useLivrets from "@/hooks/useLivrets"
import useStaticFiles from "@/hooks/useStaticFiles"
import useUploadedFiles from "@/hooks/useUploadedFiles"
import React, { useMemo } from "react"
import { FormProvider, useForm } from "react-hook-form"
import FList from "utils/form/FList"
import useParentSubmit from "utils/form/specific/fiche/links/utils/parentSubmit/useParentSubmit"
import Yup from "utils/yup"

const schema = Yup.object().shape({
	replace: Yup.string().required("Aucune fiche séléctionnée"),
})

const SelectStaticFile = () => {
	const methods = useForm({
		resolver: yupResolver(schema),
		defaultValues: schema.cast({}),
	})

	useParentSubmit(methods.handleSubmit)

	const uploadedFiles = useUploadedFiles()
	const disabledItems = useMemo(
		() =>
			Object.values(uploadedFiles)
				.filter((file) => "pcs" in file.config && "replace" in file.config.pcs)
				// @ts-ignore
				.map((file) => file.config.pcs.replace),
		[uploadedFiles],
	)

	const staticFiles = useStaticFiles({ withUploaded: false })
	const items = useMemo(
		() =>
			Object.values(staticFiles).filter(
				(staticFile) => staticFile.path !== undefined,
			),
		[staticFiles],
	)
	const livrets = useLivrets()

	return (
		<FormProvider {...methods}>
			<FList
				name="replace"
				disabledItems={disabledItems}
				items={items}
				idAccessor={(staticFile) => staticFile.id}
				titleAccessor={(staticFile) => staticFile.title}
				groupBy={(staticFile) => livrets[staticFile.path[0]].key}
				renderGroup={(group) => livrets[group]}
			/>
		</FormProvider>
	)
}

export default SelectStaticFile
