import React, { useState, useEffect, useMemo, useRef } from "react"
import { useDispatch, useSelector } from "react-redux"
import Debug from "debug"
import styled, { css } from "styled-components"
import Moment from "moment"
import { useHistory } from "react-router-dom"
import _ from "lodash"
import CheckIcon from "@mui/icons-material/Check"
import SyncIcon from "@mui/icons-material/Sync"
import ErrorIcon from "@mui/icons-material/Error"
import SendIcon from "@mui/icons-material/Send"
import ElectricBolt from "@mui/icons-material/ElectricBolt"

import IDaybookAlert, {
	DaybookAlertType,
} from "@/utils/types/daybook/IDaybookAlert"
import { StyledChip } from "utils/components/style/chip"
import ChatOutlined from "@mui/icons-material/ChatOutlined"
import Archive from "@mui/icons-material/Archive"

// app:javascript:components:maincourante:alert:components:DaybookAlertChip.tsx
const debug = Debug(
	"app:javascript:components:maincourante:alert:components:DaybookAlertChip",
)
debug.log = console.log.bind(console)

const OrangeSyncIcon = styled(SyncIcon)`
    color: var(--orange500) !important;
`
const SErrorIcon = styled(ErrorIcon)`
    color: var(--red500) !important;
`
const SSendIcon = styled(SendIcon)`
    color: var(--orange500) !important;
`

const GreenCheckIcon = styled(CheckIcon)`
    color: var(--green500) !important;
`
const PurpleElectricBolt = styled(ElectricBolt)`
    color: var(--purple500) !important;
`

const DaybookAlertChip = ({ status }: { status: DaybookAlertType }) => {
	switch (status) {
		case DaybookAlertType.PREPARE_DIFFUSE:
		case DaybookAlertType.PREPARE_MESSAGE:
			return (
				<StyledChip
					$backgroundColorProperty="var(--grey50)"
					$colorProperty="var(--grey800)"
					label={DaybookAlertType.PREPARE}
					avatar={<SyncIcon />}
					size="small"
				/>
			)
		case DaybookAlertType.READY:
			return (
				<StyledChip
					$backgroundColorProperty="var(--purple100)"
					$colorProperty="var(--purple800)"
					label={DaybookAlertType.READY}
					size="small"
					avatar={<PurpleElectricBolt />}
				/>
			)
		case DaybookAlertType.SENDING:
			return (
				<StyledChip
					$backgroundColorProperty="var(--orange50)"
					$colorProperty="var(--orange800)"
					label={DaybookAlertType.SENDING}
					avatar={<SSendIcon />}
					size="small"
				/>
			)
		case DaybookAlertType.FINISHED:
			return (
				<StyledChip
					$backgroundColorProperty="var(--green100)"
					$colorProperty="var(--green800)"
					label={DaybookAlertType.FINISHED}
					avatar={<GreenCheckIcon />}
					size="small"
				/>
			)
		case DaybookAlertType.ERROR:
			return (
				<StyledChip
					$backgroundColorProperty="var(--red50)"
					$colorProperty="var(--red800)"
					label={DaybookAlertType.ERROR}
					size="small"
					avatar={<SErrorIcon />}
				/>
			)
		case DaybookAlertType.ARCHIVED:
			return (
				<StyledChip
					$backgroundColorProperty="var(--brown50)"
					$colorProperty="var(--brown800)"
					label={DaybookAlertType.ARCHIVED}
					size="small"
					avatar={<Archive />}
				/>
			)
		default:
			return null
	}
}

export default DaybookAlertChip
