import { yupResolver } from "@hookform/resolvers/yup"
import { EnumToolPath } from "@/hooks/services/useAccessRightsServices"
import useAccessRights from "@/hooks/useAccessRights"
import useFiches from "@/hooks/useFiches"
import { createEvent, updateEvent } from "@/redux-toolkit/data/events/resources"
import { MAIN_COURANTE } from "@/redux-toolkit/userSettings/constants"
import Debug from "debug"
import _ from "lodash"
import Moment from "moment"
import React, { useEffect } from "react"
import { FormProvider, useForm } from "react-hook-form"
import { useDispatch } from "react-redux"
import { Flex } from "@/utils/components/style/flex"
import FDateTime from "@/utils/form/FDateTime"
import FSelect from "@/utils/form/FSelect"
import FSelectFiche from "@/utils/form/FSelectFiche"
import FSwitch from "@/utils/form/FSwitch"
import FText from "@/utils/form/FText"
import FFlag from "@/utils/form/specific/fiche/FFlag"
import SuperModal from "@/utils/modals/SuperModal"
import Yup from "@/utils/yup"

const debug = Debug("app:javascript:components:maincourante:event:EditEvent")
debug.log = console.log.bind(console)

const schema = Yup.object().shape({
	name: Yup.string().required("Nom de l'évènement requis"),
	vigilance: Yup.string()
		.typeError("Niveau de vigilance requis")
		.required("Niveau de vigilance requis"),
	isTest: Yup.boolean().default(false),
	active: Yup.boolean().default(true),
	startDate: Yup.string()
		.required("Date de début requis")
		.default(Moment().format()),
	endDate: Yup.string().nullable(),
})

const EditEvent = ({
	onClose,
	isOpen,
	isNew,
	initialValues,
	onSubmitCallback = (args) => {},
}) => {
	const fiches = useFiches()
	const dispatch = useDispatch()

	const daybookCreationCriseWritePerm = useAccessRights(
		EnumToolPath.CREATION_CRISE,
	).write
	const daybookGestionCriseWritePerm = useAccessRights(
		EnumToolPath.GESTION_CRISE,
	).write
	const writePermission = isNew
		? daybookCreationCriseWritePerm // Creating crisis
		: daybookGestionCriseWritePerm // Editing Crisis from Modal

	const buildInitialValues = {
		vigilance: "GREEN",
		active: true,
		orga: {},
		startDate: isNew ? Moment().format() : null,
		endDate: null,
		isTest: false,
		...initialValues,
		scenarioIds: (initialValues?.scenarioIds ?? []).map((scenId) => ({
			value: scenId,
			label: fiches[scenId]?.nom,
		})),
	}

	const methods = useForm({
		defaultValues: buildInitialValues,
		resolver: yupResolver(schema),
	})

	useEffect(() => {
		methods.reset(buildInitialValues)
	}, [initialValues])

	const onSubmit = (values) => {
		let newEvent
		newEvent = {
			...values,
			scenarioIds: [values?.scenarioIds?.[0]?.value],
		}
		if (isNew) {
			dispatch(createEvent(newEvent))
		} else {
			dispatch(updateEvent(newEvent))
		}
		methods.reset({ name: "", scenarioIds: [] })
		onClose()
		onSubmitCallback(newEvent)
	}

	return (
		<FormProvider {...methods}>
			<SuperModal
				title={
					isNew
						? "Nouvelle Crise"
						: writePermission
							? "Modifier Crise"
							: "Informations Crise"
				}
				onClose={onClose}
				isOpen={isOpen}
				isNew={isNew}
				onClick={methods.handleSubmit(onSubmit)}
				writePermissions={writePermission}
			>
				<Flex gap="1rem" directionColumn alignItemsInitial>
					<FText
						name="name"
						label="Nom de la crise (champ obligatoire)"
						disabled={!writePermission}
					/>
					<FSelectFiche
						name="scenarioIds"
						label="Scénario"
						disabled={!writePermission}
					/>
					<FSwitch
						name="active"
						label="Crise en cours"
						disabled={!writePermission}
					/>
					<FSwitch
						name="isTest"
						label="Crise de test"
						disabled={!writePermission}
					/>
					<FDateTime
						name="startDate"
						label="Date de début"
						disabled={!writePermission}
					/>
					<FDateTime
						name="endDate"
						label="Date de fin"
						disabled={!writePermission}
					/>
					<Flex directionColumn alignItemsInitial gap="0.5rem">
						<FFlag name="vigilance" isNewCrisis={isNew} />
					</Flex>
				</Flex>
			</SuperModal>
		</FormProvider>
	)
}

export default EditEvent
