import { yupResolver } from "@hookform/resolvers/yup"
import EventNoteIcon from "@mui/icons-material/EventNote"
import useJsonSchemasWithStatics from "@/hooks/useJsonSchemasWithStatics"
import usePoints from "@/hooks/usePoints"
import useSelectedEvent from "@/hooks/useSelectedEvent"
import { updateEvent } from "@/redux-toolkit/data/events/resources"
import {
	createPointWithUpdateEvent,
	updatePointWithUpdateEvent,
} from "@/redux-toolkit/data/points/resources"
import { setCurrentIntervention } from "@/redux-toolkit/edit/reducer"
import {
	INTERVENTION,
	PATH_MAIN_COURANTE_INTERVENTION,
} from "@/redux-toolkit/userSettings/constants"
import { diff } from "deep-object-diff"
import Debug from "debug"
import _ from "lodash"
import Moment from "moment"
import React, { useEffect } from "react"
import { isMobile } from "react-device-detect"
import { FormProvider, useForm } from "react-hook-form"
import { useDispatch, useSelector } from "react-redux"
import styled from "styled-components"
import { Flex } from "@/utils/components/style/flex"
import FAutocomplete from "@/utils/form/FAutocomplete"
import FCellules from "@/utils/form/FCellules"
import FDateTime from "@/utils/form/FDateTime"
import FGeoloc from "@/utils/form/FGeoloc"
import FSelectHumans from "@/utils/form/FSelectHumans"
import FText from "@/utils/form/FText"
import FLinkIntervention from "@/utils/form/specific/daybook/FLinkIntervention"
import Yup from "@/utils/yup"
import { v4 as uuidv4 } from "uuid"
import SuperModal from "../SuperModal"
import FModalInterventionStatut from "./FModalInterventionStatut"
import FCommentsButtonAdd from "@/utils/form/FComments/FCommentsButtonAdd"
import FComments from "@/utils/form/FComments/FComments"
import useAccessRights from "@/hooks/useAccessRights"
import { LINK, ORDERED } from "@/utils/form/specific/fiche/EditorToolbar"
import FDraft from "@/utils/form/specific/fiche/FDraft"
import { H6 } from "@/utils/components/style/header"
import { FormDependencies } from "@/utils/form/FormDependencies"
import FUpload from "@/utils/form/upload/FUpload"
import { getYupGeoloc } from "@/utils/types/IGeoloc"
import { EnumToolPath } from "@/hooks/services/useAccessRightsServices"

// app:javascript:utils:modals:ModalIntervention.tsx
const debug = Debug("app:javascript:utils:modals:ModalIntervention")
debug.log = console.log.bind(console)

const StyledFlex = styled(Flex)`
    flex-grow: 1;
`
const schema = Yup.object().shape({
	Statut: Yup.string().required(),
	"Date de début": Yup.string()
		.required()
		.default(() => Moment().format()),
	"Date de fin": Yup.string().nullable().default(null),
	Objet: Yup.string().required(),
	Détails: Yup.string().default(""),
	Réponses: Yup.array().of(Yup.object()).default([]),
	Cellules: Yup.array().of(Yup.string()).default([]),
	Membres: Yup.array().of(Yup.string()).default([]),
	Géolocalisation: getYupGeoloc({ isRequired: false }),
	"Pièces jointes": Yup.array()
		.of(
			Yup.object().shape({
				config: Yup.object(),
				title: Yup.string().test(
					"title",
					({ value }) => {
						return `Votre saisie comporte ${
							value.length - 50
						} caractères de trop. (Limite: 50)`
					},
					(value) => {
						return value.length <= 50
					},
				),
				extension: Yup.string(),
				filename: Yup.string(),
			}),
		)
		.default([]),
})
const ContainerFlex = styled(Flex)`
    flex-direction: ${isMobile ? "column" : "row"};
`
const interceptedKeys = ["Cellules", "Membres", "Adresse"]

const keyInterceptor = (key) => {
	switch (key) {
		case "Cellules":
		case "Membres":
		case "Adresse":
		case "En lien avec":
		case "Détails":
			return "Donnée modifiée."
		default:
	}
}
const keyModifier = (value, key) => {
	switch (key) {
		case "Date de début":
			return Moment(value).format("DD/MM/YYYY HH:mm")
		case "Date de fin":
			return Moment(value).format("DD/MM/YYYY HH:mm")
		default:
			return value
	}
}

const ModalIntervention = () => {
	const dispatch = useDispatch()
	const points = usePoints()
	const currentIntervention = useSelector(
		(state: any) => state.edit.currentIntervention,
	)

	const selectedEvent = useSelectedEvent()
	const permissions = useAccessRights(EnumToolPath.INTERVENTION)
	const isLocked = selectedEvent?.locked

	const isNew = currentIntervention?.id === undefined
	const buildDefaultValues = () => ({
		Statut: "A faire",
		"Date de début": Moment().format(),
		"Date de fin": null,
	})
	const buildCurrentValues = (values) => {
		return {
			"Date de début": Moment(values["Date de début"], "DD/MM/YYYY HH:mm"),
			Type: { value: values["Type"] },
			...values,
		}
	}

	const methods = useForm({
		resolver: yupResolver(schema),
		defaultValues: isNew
			? buildDefaultValues()
			: buildCurrentValues(currentIntervention),
	})

	/**
	 * It takes the current intervention's properties and the values of the form fields, and it returns
	 * a string that contains the HTML of the description of the intervention's modification
	 * @param values - The values that are being passed to the form.
	 */
	const buildInterventionHistoryUpdate = (values) => {
		const diffValue = diff(currentIntervention.geojson.properties, values)
		const textDetails = {}

		Object.keys(diffValue).forEach((key) => {
			if (diffValue[key]) {
				if (interceptedKeys.includes(key)) {
					textDetails[key] = keyInterceptor(key)
				} else {
					textDetails[key] = {
						oldValue: keyModifier(
							currentIntervention.geojson.properties[key],
							key,
						),
						newValue: keyModifier(values[key], key),
					}
				}
			}
		})
		return [
			...selectedEvent.history,
			{
				type: INTERVENTION,
				subTitle: "Modification d'Intervention",
				title: values.Objet,
				objectId: currentIntervention.id,
				date: Moment().format(),
				description: {
					Objet: "",
					details: textDetails,
				},
				status: values.Statut,
			},
		]
	}

	const onSubmit = (values) => {
		dispatch(setCurrentIntervention(undefined))
		setTimeout(() => {
			if (isNew) {
				const id = values?.tmpId ?? uuidv4()
				dispatch(
					createPointWithUpdateEvent({
						point: {
							id,
							geojson: {
								properties: values,
							},
							jsonschema_id: INTERVENTION,
							event_id: selectedEvent.id,
						},
						event: {
							...selectedEvent,
							history: [
								...selectedEvent.history,
								{
									type: INTERVENTION,
									subTitle: "Création d'Intervention",
									title: values.Objet,
									objectId: id,
									date: Moment().format(),
									status: values.Statut,
								},
							],
						},
					}),
				)
			} else {
				const clonedIntervention = _.cloneDeep(currentIntervention)
				delete clonedIntervention.isExpanded
				clonedIntervention.geojson.properties = {
					...clonedIntervention.geojson.properties,
					...values,
				}
				const newHistory = buildInterventionHistoryUpdate(values)
				dispatch(
					updatePointWithUpdateEvent({
						point: clonedIntervention,
						event: {
							...selectedEvent,
							history: newHistory,
							updated_at: Moment().format(),
						},
					}),
				)
			}
		}, 100)
	}
	useEffect(() => {
		if (currentIntervention?.id) {
			methods.reset(currentIntervention.geojson.properties)
		} else {
			methods.reset({ ...buildDefaultValues(), ...currentIntervention })
		}
	}, [currentIntervention])

	methods.watch("En lien avec")
	const linkValue = methods.getValues("En lien avec")
	const linkedPoint = points[linkValue]
	const options =
		linkedPoint?.jsonschema_id === "INCIDENT"
			? [linkedPoint.geojson.properties?.Objet]
			: []
	methods.watch("Statut")
	const statutValue = methods.getValues()?.["Statut"]
	useEffect(() => {
		if (methods.getValues()["Statut"] === "Terminé") {
			if (!methods.getValues()["Date de fin"]) {
				methods.setValue("Date de fin", Moment().format())
			}
		}
	}, [statutValue])
	return (
		<>
			<FormProvider {...methods}>
				<SuperModal
					size="5xl"
					fade={false}
					rightContent={
						<Flex gap="1rem" directionColumn fullWidth={isMobile}>
							<FModalInterventionStatut
								disabled={!permissions.write || isLocked}
							/>
							<FDateTime
								name="Date de début"
								disabled={!permissions.write || isLocked}
							/>
							<FDateTime
								name="Date de fin"
								disabled={!permissions.write || isLocked}
							/>
							<FCommentsButtonAdd
								name="Réponses"
								buttonTitle="Point de situation"
								disabled={!permissions.write || isLocked}
							/>
							<FLinkIntervention
								name="En lien avec"
								currentIntervention={currentIntervention}
							/>
						</Flex>
					}
					disableSave={!methods.formState.isDirty}
					toggleOnClickOutside={!methods.formState.isDirty}
					isOpen={!!currentIntervention}
					onClose={() => {
						dispatch(setCurrentIntervention(undefined))
					}}
					isNew={isNew}
					onClick={methods.handleSubmit(onSubmit)}
					writePermissions={!isLocked}
					title={isNew ? "Créer une intervention" : "Modifier  l'intervention"}
				>
					<ContainerFlex gap="1rem" alignItemsStart>
						<StyledFlex gap="1rem" directionColumn fullWidth={isMobile}>
							<FAutocomplete
								disabled={!permissions.write || isLocked}
								freeSolo
								name="Objet"
								label="Objet"
								autoFocus={!isMobile}
								options={options}
								renderOption={(props, option) => (
									<li {...props}>
										<Flex gap={10}>
											<EventNoteIcon />
											{option}
										</Flex>
									</li>
								)}
							/>
							<FDraft
								name="Détails"
								label="Détails"
								extraToolbarButtons={[LINK]}
								hideToolbarButtons={[ORDERED]}
								isImportable={false}
								disabled={!permissions.write || isLocked}
							/>
							<FComments
								name="Réponses"
								messageConfirmation="Voulez-vous vraiment supprimer le point de situation"
								disabled={!permissions.write || isLocked}
							/>
							<FCellules
								name="Cellules"
								label="Cellules"
								disabled={!permissions.write || isLocked}
							/>
							<FSelectHumans
								name="Membres"
								label="Membres"
								disabled={!permissions.write || isLocked}
							/>
							<FGeoloc
								name="Adresse"
								disabled={!permissions.write || isLocked}
							/>
							<H6>Pièces jointes</H6>
							<Flex fullWidth alignItemsStart>
								<FormDependencies targets={["Pièces jointes"]}>
									{({ "Pièces jointes": linkedFilesValue }) => (
										<FUpload
											values={linkedFilesValue}
											name="Pièces jointes"
											disabled={!permissions.write || isLocked}
										/>
									)}
								</FormDependencies>
							</Flex>
						</StyledFlex>
					</ContainerFlex>
				</SuperModal>
			</FormProvider>
		</>
	)
}

export default ModalIntervention
