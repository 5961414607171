import React from "react"
import DaybookEventsList from "../components/DaybookEventsList"
import DaybookCareTable from "../components/DaybookCareTable"
import DaybookArretesTable from "../components/DaybookArretesTable"
import DaybookSuiviPcs from "../components/DaybookSuiviPcs"
import {
	DAYBOOK,
	INCIDENTS_ACTIONS,
	ARRETES,
	CARE,
	PEOPLES_HELP,
} from "@/pages/carto2/cartographie/service/ReportService"
import _ from "lodash"

const DaybookPage = ({
	selectedEvent,
	points,
	pointsWithBlobs,
	multiPolygons,
	orgas,
	humans,
	arretes,
	objectOfArretesBlobsObject,
	jsonSchemas,
	daybookExistingDatas,
	indexInSummary,
	summaryCategories,
	objectOfWisiwygBlobsArray,
}) => {
	return (
		<>
			{daybookExistingDatas.includes(INCIDENTS_ACTIONS) && (
				<DaybookEventsList
					selectedEvent={selectedEvent}
					points={points}
					pointsWithBlobs={pointsWithBlobs}
					orgas={orgas}
					humans={humans}
					indexInSummary={indexInSummary}
					childIndexInSummary={
						_.indexOf(
							Object.values(summaryCategories[DAYBOOK].childrens),
							INCIDENTS_ACTIONS,
						) + 1
					}
					objectOfWisiwygBlobsArray={objectOfWisiwygBlobsArray}
				/>
			)}
			{daybookExistingDatas.includes(ARRETES) && (
				<DaybookArretesTable
					selectedEvent={selectedEvent}
					arretes={arretes}
					objectOfArretesBlobsObject={objectOfArretesBlobsObject}
					indexInSummary={indexInSummary}
					childIndexInSummary={
						_.indexOf(
							Object.values(summaryCategories[DAYBOOK].childrens),
							ARRETES,
						) + 1
					}
				/>
			)}
			{daybookExistingDatas.includes(CARE) && (
				<DaybookCareTable
					selectedEvent={selectedEvent}
					points={points}
					humans={humans}
					indexInSummary={indexInSummary}
					childIndexInSummary={
						_.indexOf(
							Object.values(summaryCategories[DAYBOOK].childrens),
							CARE,
						) + 1
					}
				/>
			)}
			{daybookExistingDatas.includes(PEOPLES_HELP) && (
				<DaybookSuiviPcs
					selectedEvent={selectedEvent}
					points={points}
					multiPolygons={multiPolygons}
					humans={humans}
					jsonSchemas={jsonSchemas}
					indexInSummary={indexInSummary}
					childIndexInSummary={
						_.indexOf(
							Object.values(summaryCategories[DAYBOOK].childrens),
							PEOPLES_HELP,
						) + 1
					}
				/>
			)}
		</>
	)
}
export default DaybookPage
