import usePoints from "@/hooks/usePoints"
import React from "react"
import sanitize from "sanitize-html"
import styled from "styled-components"
import usePointFormat from "utils/components/jsonSchema/usePointFormat"

const TextContainer = styled.div`
    padding-top: 5px;
    padding-bottom: 5px;

    :last-child {
        padding-bottom: 10px;
    }

    // Editor equivalent style
    p,
    ul,
    ol {
        margin: 0 0 0.5em 0;
        padding: 0;
    }
    p:last-child,
    ul:last-child,
    ol:last-child {
        margin-bottom: 0;
    }
    li {
        margin-left: 1.5em;
    }
`

const ActionText = ({ content }) => {
	const points = usePoints()
	const formatPoint = usePointFormat()

	const contentBuilder = (content: string) => {
		if (!content) return "Action non définie"

		const regex =
			/<span point="(?<id>[^"]+)" format="(?<format>[^"]+)">[^<]*<\/span>/g

		return content.replaceAll(regex, (...result) => {
			const groups = result[result.length - 1]

			return `<span>${formatPoint(points[groups.id], groups.format)}</span>`
		})
	}

	return (
		<TextContainer
			style={{ flexGrow: 1 }}
			dangerouslySetInnerHTML={{
				__html: sanitize(contentBuilder(content), {
					allowedStyles: {
						span: {
							color: [/^.*$/],
							"background-color": [/^.*$/],
						},
					},
					allowedAttributes: {
						...sanitize.defaults.allowedAttributes,
						span: ["style"],
					},
				}),
			}}
		/>
	)
}

export default ActionText
