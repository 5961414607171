import { useMemo } from "react"
import useJsonSchemasWithStatics from "../useJsonSchemasWithStatics"
import formatNfdLowerCase from "utils/formatNfdLowerCase"

const useSearchJsonSchemas = (words: string[]) => {
	const jsonSchemasWithStatics = useJsonSchemasWithStatics()

	const searchResultJsonSchemas = useMemo(() => {
		const matchJsonSchemas = Object.values(jsonSchemasWithStatics).filter(
			(jsonSchema) => {
				return words.every((word) => {
					const jsonSchemaTitle = formatNfdLowerCase(jsonSchema.title)
					const aliases = (jsonSchema.alias ?? [])
						.map((alias) => formatNfdLowerCase(alias))
						.join(" ")
					return jsonSchemaTitle.includes(word) || aliases.includes(word)
				})
			},
		)
		return matchJsonSchemas
	}, [words, jsonSchemasWithStatics])

	return searchResultJsonSchemas
}

export default useSearchJsonSchemas
