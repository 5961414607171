import bxsFileArchive from "@iconify-icons/bx/bxs-file-archive"
import bxsFileBlank from "@iconify-icons/bx/bxs-file-blank"
import bxsFileDoc from "@iconify-icons/bx/bxs-file-doc"
import bxsFileGif from "@iconify-icons/bx/bxs-file-gif"
import bxsFileJpg from "@iconify-icons/bx/bxs-file-jpg"
import bxsFilePdf from "@iconify-icons/bx/bxs-file-pdf"
import bxsFilePng from "@iconify-icons/bx/bxs-file-png"
import bxsFileTxt from "@iconify-icons/bx/bxs-file-txt"
import { Icon } from "@iconify/react"
import React from "react"
import { useMemo } from "react"
import styled, { css } from "styled-components"
import fileinfo from "utils/fileinfo"

const SIcon = styled(Icon)<{ color: string; backgroundColor: string }>`
    ${({ color, backgroundColor }) =>
			css`
            color: ${color};
            padding: 2px;
            margin-right: 5px;
            width: 30px;
            height: 30px;
            background-color: ${backgroundColor};
            border-radius: 5px;
        `}
`

export const getFilenameConfig = (filename: string) => {
	const finfo = fileinfo(filename)

	switch (finfo?.ext) {
		case ".png":
			return {
				color: "#4309BF",
				backgroundColor: "#EAE5F7",
				icon: bxsFilePng,
			}
		case ".pdf":
			return {
				color: "#E0021B",
				backgroundColor: "#FFE0E3",
				icon: bxsFilePdf,
			}
		case ".gif":
			return {
				color: "#4309BF",
				backgroundColor: "#EAE5F7",
				icon: bxsFileGif,
			}
		case ".doc":
		case ".docx":
		case ".odt":
			return {
				backgroundColor: "#DEEAFF",
				color: "#004FD4",
				icon: bxsFileDoc,
			}
		case ".txt":
			return {
				color: "black",
				backgroundColor: "#d3d3d3",
				icon: bxsFileTxt,
			}
		case ".jpg":
		case ".jpeg":
			return {
				color: "#4309BF",
				backgroundColor: "#EAE5F7",
				icon: bxsFileJpg,
			}
		case ".xls":
		case ".xlsx":
		case ".csv":
			return {
				color: "#2B8D20",
				backgroundColor: "#E2F8DF",
				icon: bxsFileBlank,
			}
		case ".zip":
			return {
				color: "#A39700",
				backgroundColor: "#FFF8A1",
				icon: bxsFileArchive,
			}
		default:
			return {
				color: "#B3B3BA",
				backgroundColor: "#EEEEF0",
				icon: bxsFileBlank,
			}
	}
}

const FileIcon = ({ filename }: { filename: string }) => {
	return useMemo(() => {
		const config = getFilenameConfig(filename)

		return (
			<SIcon
				color={config.color}
				backgroundColor={config.backgroundColor}
				icon={config.icon}
			/>
		)
	}, [filename])
}

export default FileIcon
