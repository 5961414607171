import EditIcon from "@mui/icons-material/Edit"
import { Checkbox, IconButton } from "@mui/material"
import useCurrentAction from "@/hooks/useCurrentAction"
import React, { useEffect, useRef } from "react"
import styled, { css } from "styled-components"
import { Flex } from "utils/components/style/flex"
import SuperDeleteButton from "utils/components/SuperDeleteButton"
import { defaultScrollOptions } from "utils/defaultScrollOptions"
import SuperDragHandle from "utils/dnd/SuperDragHandle"
import { IAction, Vigilance, VigilanceColor } from "utils/types/IFiche"
import useResponsive from "@/hooks/useResponsive"
import ActionCellules from "./subAction/ActionCellules"
import ActionLiens from "./subAction/ActionLiens"
import ActionText from "./subAction/ActionText"
import { FixOlUlStyle } from "@/utils/form/utils/FixOlUlStyle"

export const Outliner = styled.div<{ $selected: boolean }>`
    ${({ $selected }) =>
			$selected &&
			css`
            outline: solid 3px var(--primary);
        `}
`

const ButtonContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
`

const DragContainer = styled.div`
    display: flex;
    align-items: center;
    padding-left: 8px;
    align-self: stretch;
    width: 2.4em;
    justify-content: center;
`

const ContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    flex: 1;
    padding: 10px 10px 5px 10px;
    gap: 5px;
    min-width: 0;
`

const Container = styled.div<{ $vigilance: Vigilance; $isSmall: boolean }>`
    border-left: 12px solid ${({ $vigilance }) => VigilanceColor[$vigilance]};

    display: grid;

    ${({ $isSmall }) =>
			$isSmall
				? css`
                  grid-template-rows: auto auto;
                  //grid-template-columns: auto 40px 90px;
                  grid-template-areas:
                      'content  content content'
                      'empty drag buttons';
              `
				: css`
                  grid-template-rows: auto;
                  grid-template-columns: min-content auto min-content;
                  grid-template-areas: 'drag content buttons';
              `}

    ${DragContainer} {
        grid-area: drag;
    }
    ${ContentWrapper} {
        grid-area: content;
    }
    ${ButtonContainer} {
        grid-area: buttons;
    }
`

function Action({
	action,
	editable,
	handleDelete,
	handleStartEdit,
	checkable = undefined,
	withCrisisOrga = false,
	checkDisabled = false,
}: {
	action: IAction
	handleDelete?: () => void
	handleStartEdit?: () => void
	editable: boolean
	checkable?: {
		checked: boolean
		onChange: (checked: boolean) => void
	}
	checkDisabled?: boolean
	withCrisisOrga?: boolean
}) {
	const currentAction = useCurrentAction()
	const ref = useRef<HTMLDivElement>(undefined)

	useEffect(() => {
		if (currentAction && currentAction.id === action.id) {
			ref.current?.scrollIntoView(defaultScrollOptions)
		}
	}, [currentAction])

	const isSmall = useResponsive(900)

	return (
		<Outliner $selected={currentAction?.id === action.id} ref={ref}>
			<Container $vigilance={action.Vigilance} $isSmall={isSmall}>
				{checkable && (
					<Flex>
						<Checkbox
							checked={checkable.checked}
							onChange={(e) => checkable.onChange(e.target.checked)}
							disabled={checkDisabled}
						/>
					</Flex>
				)}
				{editable && <SuperDragHandle component={DragContainer} />}
				<FixOlUlStyle>
					<ContentWrapper>
						<ActionText content={action.Contenu} />
						<ActionLiens liens={action.Liens} />
						<ActionCellules
							celluleIds={action.Cellules}
							withCrisisOrga={withCrisisOrga}
						/>
					</ContentWrapper>
				</FixOlUlStyle>
				{editable && (
					<ButtonContainer>
						<IconButton onClick={handleStartEdit}>
							<EditIcon />
						</IconButton>
						<SuperDeleteButton onClick={handleDelete} />
					</ButtonContainer>
				)}
			</Container>
		</Outliner>
	)
}

export default Action
