import { MenuItem, Select } from "@mui/material"
import FormatSizeIcon from "@mui/icons-material/FormatSize"
import React, { useEffect } from "react"
import styled from "styled-components"
import ToolbarPart from "./ToolbarPart"
import useEditorFocus from "./useEditorFocus"
import { EditorState } from "draft-js"
import { useEffectOnce } from "react-use"

const Icon = styled(FormatSizeIcon)`
    position: absolute;
    top: 0;
    left: 7px;
    color: lightgrey;
    top: calc(50% - 0.5em);
    pointer-events: none;
`

function FontSizeToolbar({
	currentState,
	onChange,
	config: { options },
	...otherProps
}) {
	const { fontSize } = currentState
	const { focus, delay } = useEditorFocus()

	// Check if the current fontSize exists in the options, if not, add it dynamically
	const fontSizeExists = options.includes(fontSize)
	let displayOptions = fontSizeExists
		? options
		: [...options, fontSize].sort((a, b) => a - b)

	return (
		<ToolbarPart>
			<Select
				value={fontSize ?? ""}
				onClose={focus}
				onChange={delay((ev) => onChange(ev.target.value))}
				style={{ minWidth: "80px" }}
				size="small"
			>
				<MenuItem value="">
					<span style={{ color: "transparent" }}>T</span>
				</MenuItem>
				{displayOptions.map((option) => (
					<MenuItem key={option} value={option}>
						{option}
					</MenuItem>
				))}
			</Select>
		</ToolbarPart>
	)
}
export default FontSizeToolbar
