import React from "react"
import PPage from "utils/pdf/PPage"
import PImage from "utils/pdf/image/PImage"
import { View } from "@react-pdf/renderer"

const DaybookEndPage = ({ communeLogoSrc }) => {
	return (
		<PPage>
			<View
				style={{
					height: "100%",
					display: "flex",
					justifyContent: "flex-end",
					alignItems: "center",
				}}
			>
				<PImage
					src={communeLogoSrc}
					alt="[Logo de la commune]"
					width="256px"
					style={{ marginBottom: "32" }}
				/>
			</View>
		</PPage>
	)
}

export default DaybookEndPage
