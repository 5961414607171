import AddIcon from "@mui/icons-material/Add"
import useAccessRights from "@/hooks/useAccessRights"
import useSelectedEvent from "@/hooks/useSelectedEvent"
import { updateEvent } from "@/redux-toolkit/data/events/resources"
import { deletePdf } from "@/redux-toolkit/data/pdf/resources"
import {
	ARRETE,
	PATH_MAIN_COURANTE_ARRETE,
} from "@/redux-toolkit/userSettings/constants"
import Moment from "moment"
import React, { useMemo } from "react"
import { connect, useDispatch } from "react-redux"
import { useHistory } from "react-router-dom"
import OverlayLoading from "@/utils/components/OverlayLoading"
import { PrimaryButton } from "@/utils/components/style/button"
import SuperReactTable from "@/utils/components/tables/SuperReactTable/SuperReactTable"
import { width80 } from "@/utils/components/tables/widthProps"
import DownloadCell from "./DownloadCell"
import { useBoolean } from "react-use"
import { sortTypeDateFactory } from "@/utils/sort"
import { EnumToolPath } from "@/hooks/services/useAccessRightsServices"
import Button from "@/styles/atoms/Button"
import usePdfs from "@/hooks/usePdfs"

const TableArretes = ({ isLoading }) => {
	const selectedEvent = useSelectedEvent()
	const [loading, toggleLoading] = useBoolean(false)
	const dispatch = useDispatch()
	const permissions = useAccessRights(EnumToolPath.ARRETE)
	const isLocked = selectedEvent?.locked
	const pdfs = usePdfs()

	const history = useHistory()

	const eventPdfs = useMemo(
		() => Object.values(pdfs).filter((pdf) => pdf.event_id === selectedEvent?.id).reverse(),
		[pdfs, selectedEvent],
	)

	const columns = useMemo(
		() => [
			{
				header: "Type",
				accessorKey: "title",
			},
			{
				header: "Date",
				accessorKey: "date",
				sortType: sortTypeDateFactory("date"),
			},
			{
				header: "",
				accessorKey: "download",
				...width80,
				isAction: true,
				cell: ({ row }) => <DownloadCell arreteId={row.original.id} />,
				enableSorting: false,
				...width80,
			},
		],
		[],
	)

	const onDeleteClick = async (value) => {
		toggleLoading()
		await dispatch(deletePdf(value.id))

		dispatch(
			updateEvent({
				...selectedEvent,
				history: [
					...selectedEvent?.history,
					{
						type: ARRETE,
						subTitle: "Suppression d'un arrêté",
						title: value?.title ?? "",
						date: Moment().format(),
						noAction: true,
					},
				],
			}),
		)
		toggleLoading(false)
	}

	return (
		<>
			<SuperReactTable
				data={eventPdfs}
				columns={columns}
				writePermission={permissions.write}
				isEditable={false}
				isDeletable={permissions.canDeleteDatas}
				isLocked={isLocked}
				selectable={false}
				onDeleteClick={onDeleteClick}
				actionsButtons={
					permissions.write && (
						<Button
							color="primary"
							fullWidth
							startContent={<AddIcon />}
							onClick={() => history.push("/maincourante/arretes/create")}
							disabled={isLocked}
						>
							Créer un arrêté
						</Button>
					)
				}
				initialSortBy={[
					{
						desc: true,
						id: "date",
					},
				]}
			/>
			<OverlayLoading
				isActive={isLoading}
				text={"Arrêté en cours de construction, veuillez patienter"}
			/>
		</>
	)
}

const mapStateToProps = (state) => {
	return {
		isLoading: state.common.loading !== 0,
	}
}

export default connect(mapStateToProps)(TableArretes)
