import IPoint from "@/utils/types/IPoint"
import usePoints from "./usePoints"
import { SUIVI_PCS } from "@/redux-toolkit/userSettings/constants"
import _ from "lodash"
import moment from "moment"
import ISuivi from "@/utils/types/ISuivi"

const useSupportsFromFacility = (facilityId: string) => {
    const points = usePoints()

    //* get facility's supports
    const concerned_supports = Object.values(points)
        .filter((point: IPoint) => point.jsonschema_id == SUIVI_PCS)
        .filter((support: IPoint) => support.geojson.properties.suivi_group_id == facilityId)
    //* get all supports's responsables
    const concerned_resps = []
    concerned_supports.forEach((support: IPoint) => {
		return support.geojson.properties.responsableId?.forEach((humanId: string) => {
            const responsable = points[humanId]
            if (!responsable || concerned_resps.includes(responsable)) return
            concerned_resps.push(responsable)
        })
	})
    //* get all supports's status for datapie
    const getNbSupports = (state: "pending"|"ended") => {
        const now = moment()
        let res = 0

        concerned_supports.forEach((support: IPoint) => {
            const suivis = support.geojson.properties.suivi as ISuivi[]
            const sortedDesc = _.sortBy(suivis, ["Date prise de contact"]).reverse()
            const latest_date = sortedDesc[0]?.["Date prise de contact"]
            if (!latest_date) return
            if (state === 'ended') {
                if (now.isAfter(latest_date)) res++
            }
            if (state === 'pending') {
                if (now.isBefore(latest_date)) res++
            }
        })

        return res
    }

    const nb_pending = getNbSupports("pending")
    const nb_ended = getNbSupports("ended")

    const getNbUnless = () => {
        const followedPointsIds = concerned_supports.map((support) =>
            support.geojson.properties.relatedObject.objectId
        )
        const facilityJsonSchemaActivated = points[facilityId].geojson.properties.suiviJsonSchemaActivated as string[]
        const facilityVirtualPoints = Object.values(points).filter((point) => facilityJsonSchemaActivated.includes(point.jsonschema_id))
        const stillVitualPoints = _.remove(facilityVirtualPoints, (el: IPoint) => !followedPointsIds.includes(el.id))

        return concerned_supports.length - (nb_pending + nb_ended) + stillVitualPoints.length
    }
    const nb_unless = getNbUnless()

	return {
        supports: concerned_supports,
        groupInfos: {
            responsables: concerned_resps,
            nbPendingSupports: nb_pending,
            nbEndedSupports: nb_ended,
            nbUnlessSupports: nb_unless,
        }
    }
}

export default useSupportsFromFacility