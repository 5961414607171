import { SET_ACTIVE_LIVRET } from "./action"
export const initialPageState = {
	activeLivret: undefined,
}
export const pageReducer = (state, action) => {
	switch (action.type) {
		case SET_ACTIVE_LIVRET:
			return {
				activeLivret: action.activeLivret,
			}
		default:
			return state
	}
}
