import CitycService from "@/pages/carto2/cartographie/service/CitycService"
import Debug from "debug"
import React, { useMemo } from "react"
import { Pie, PieChart, Tooltip } from "recharts"
import styled from "styled-components"
import { Card } from "utils/components/style/card"
import { Flex } from "utils/components/style/flex"
import IDaybookAlert from "@/utils/types/daybook/IDaybookAlert"

// app:javascript:components:maincourante:alert:alertCreation:alertStep:components:sending:DaybookAlertStatusPie.tsx
const debug = Debug(
	"app:javascript:components:maincourante:alert:alertCreation:alertStep:components:sending:DaybookAlertStatusPie",
)
debug.log = console.log.bind(console)

const PieChartWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`

const RectangleColor = styled.div<{ color: string }>`
    width: 20px;
    height: 15px;
    background-color: ${({ color }) => color};
    border: 1px solid var(--neutral300);
    margin-right: 5px;
`

const SCard = styled(Card)`
    position: relative;
`
const NoDataText = styled.div`
    font-size: 1.2rem;
    color: var(--neutral500);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`
const DaybookAlertStatusPie = ({
	data,
}: {
	data: IDaybookAlert["geojson"]["properties"]["responses"][string][]
}) => {
	const pieData = useMemo(() => {
		// 1. Get the data from the API
		const finalData = {
			"Réponse reçue": {
				name: "Réponse reçue",
				value: 0,
				fill: "var(--green500)",
			},
			"Impossible de recevoir le message sur ce numéro": {
				name: "Impossible de recevoir le message sur ce numéro",
				fill: "var(--red500)",
				value: 0,
			},
			"Pas de numéro de téléphone": {
				name: "Pas de numéro de téléphone",
				fill: "var(--purple500)",
				value: 0,
			},
			"Pas de numéro de téléphone mobile": {
				name: "Pas de numéro de téléphone mobile",
				fill: "var(--brown)",
				value: 0,
			},
			"Pas de numéro de téléphone valide": {
				name: "Pas de numéro de téléphone valide",
				fill: "var(--red800)",
				value: 0,
			},
			Succès: {
				name: "Message envoyé",
				fill: "var(--blue500)",
				value: 0,
			},
			queued: {
				name: "En cours d'envoi",
				fill: "var(--red500)",
				value: 0,
			},
		}

		data.forEach((item) => {
			if (item.received.length > 0 && item.status === "2000") {
				finalData["Réponse reçue"].value += 1
			} else if (
				item.status === "2000" ||
				item.status === "sended" ||
				item.status === "200"
			) {
				finalData["Succès"].value += 1
			} else {
				if (!finalData[CitycService.getStatusToMessage(item.status)]) {
					finalData[CitycService.getStatusToMessage(item.status)] = {
						name: CitycService.getStatusToMessage(item.status),
						fill: "var(--red500)",
						value: 0,
					}
				}

				finalData[CitycService.getStatusToMessage(item.status)].value += 1
			}
		})

		return Object.values(finalData).filter((item) => item.value > 0)
	}, [data])

	return (
		<SCard>
			<Flex directionColumn gap="0.5rem">
				<PieChartWrapper>
					{pieData.length > 0 ? (
						<PieChart width={200} height={200}>
							<Pie
								data={pieData}
								dataKey="value"
								innerRadius={30}
								outerRadius={50}
								label
							/>
							<Tooltip />
						</PieChart>
					) : (
						<NoDataText>Aucune donnée à afficher pour le moment</NoDataText>
					)}
				</PieChartWrapper>
				{pieData.length > 0 && (
					<div>
						{pieData.map((item) => (
							<Flex key={item.name} gap="1rem">
								<RectangleColor color={item.fill} />
								<span>{item.name}</span>
								<span>{item.value}</span>
							</Flex>
						))}
					</div>
				)}
			</Flex>
		</SCard>
	)
}

export default DaybookAlertStatusPie
