import SynchroService from "@/services/SynchroService"
import { useSelector } from "react-redux"
import createDeepEqualSelector from "@/utils/createDeepEqualSelector"
import IFiche from "utils/types/IFiche"

const selectFiches = createDeepEqualSelector(
	(state: any) => state.data.fiches,
	(fiches) => SynchroService.notDeleted(fiches),
)

const useFiches: () => { [key: string]: IFiche } = () => {
	return useSelector(selectFiches)
}

export default useFiches
