import { setActiveTool } from "@/redux-toolkit/common/reducer"
import Button from "@/styles/atoms/Button"
import { Popover, PopoverContent, PopoverTrigger } from "@nextui-org/react"
import _ from "lodash"
import React, { useRef } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router"
import AppBarMenuPopoverContent from "./content/SideBarContent"
import AppBarMenuPopoverContentGrouped from "./content/SideBarContentGrouped"
import { SFlex } from "./SideBarDesktopContent.styled"
import Tooltip from "@/styles/atoms/Tooltip"

const SideBarDesktopContent = ({ setOpenIndex, openIndex, toolsList }) => {
	const history = useHistory()
	const dispatch = useDispatch()
	const activeTool = useSelector((state: any) => state?.common?.activeTool)
	const ref = useRef()

	console.log(activeTool)

	return (
		<SFlex directionColumn gap="1rem">
			{toolsList.map((tool, index) => {
				const asGroup = tool.subMenus?.some((subMenu) => subMenu.group)
				const hasOneSubActive = tool.subMenus?.some(
					(subMenu) => subMenu.route === activeTool,
				)
				return (
					<React.Fragment key={tool.name}>
						<Popover
							placement="right-start"
							showArrow
							isOpen={openIndex === index}
							onOpenChange={(open) => {
								ref.current?.blur()
								setOpenIndex(open ? index : -1)
							}}
							onClose={() => {
								ref.current?.blur()
							}}
							backdrop="blur"
						>
							<Tooltip content={tool.name} placement="right">
								<div className="max-w-fit">
									<PopoverTrigger>
										<Button
											ref={ref}
											isIconOnly
											variant="light"
											onClick={(e) => {
												if (_.isEmpty(tool.subMenus)) {
													e.preventDefault()
													dispatch(setActiveTool(tool.route))
													history.push(tool.route)
												}
											}}
											color={
												activeTool === tool?.route || hasOneSubActive
													? "primary"
													: "default"
											}
										>
											{React.cloneElement(tool.icon, { sx: { fontSize: 28 } })}
										</Button>
									</PopoverTrigger>
								</div>
							</Tooltip>
							{!_.isEmpty(tool.subMenus) && (
								<PopoverContent aria-label="Static Actions">
									{asGroup && (
										<AppBarMenuPopoverContentGrouped
											tool={tool}
											setOpenIndex={setOpenIndex}
											openIndex={openIndex}
											index={index}
										/>
									)}
									{!asGroup && (
										<AppBarMenuPopoverContent
											tool={tool}
											setOpenIndex={setOpenIndex}
											openIndex={openIndex}
											index={index}
										/>
									)}
								</PopoverContent>
							)}
						</Popover>
					</React.Fragment>
				)
			})}
		</SFlex>
	)
}

export default SideBarDesktopContent as React.FC<any>
