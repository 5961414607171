import ArticleIcon from "@mui/icons-material/Article"
import FilePresentIcon from "@mui/icons-material/FilePresent"
import GppMaybeIcon from "@mui/icons-material/GppMaybe"
import ImageIcon from "@mui/icons-material/Image"
import LinkIcon from "@mui/icons-material/Link"
import MapIcon from "@mui/icons-material/Map"
import MenuOpenIcon from "@mui/icons-material/MenuOpen"
import OpenInNewIcon from "@mui/icons-material/OpenInNew"
import TableRowsIcon from "@mui/icons-material/TableRows"
import TableViewIcon from "@mui/icons-material/TableView"
import colored from "utils/colored"
import { LinkType } from "utils/types/IFiche"

const CMapIcon = colored(MapIcon)
const CFilePresentIcon = colored(FilePresentIcon)
const CTableViewIcon = colored(TableViewIcon)
const CImageIcon = colored(ImageIcon)

const getGenericLinkIcon = (type: LinkType) => {
	switch (type) {
		case LinkType.EXTERNAL:
			return OpenInNewIcon
		case LinkType.INTERNAL:
			return MenuOpenIcon
		case LinkType.JSON_SCHEMA:
			return TableRowsIcon
		case LinkType.RISK_FILE:
			return GppMaybeIcon
		case LinkType.STATIC_FILE:
		case LinkType.DYNAMIC_FILE:
			return ArticleIcon
		case LinkType.UPLOADED_CARTO:
			return CMapIcon
		case LinkType.UPLOADED_CONVENTION:
			return CFilePresentIcon
		case LinkType.UPLOADED_EDITABLE:
			return CTableViewIcon
		case LinkType.UPLOADED_IMAGE:
			return CImageIcon
		default:
			return LinkIcon
	}
}

export default getGenericLinkIcon
