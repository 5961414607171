import { updateCommune } from "@/redux-toolkit/commune/ressources"
import { useDispatch } from "react-redux"
import useCommune from "./useCommune"

function useOrderedCARE() {
	const dispatch = useDispatch()
	return {
		orderedCARE: useCommune().orderedCARE,
		setOrderedCARE: (order) =>
			dispatch(
				updateCommune({
					orderedCARE: order,
				}),
			),
	}
}

export default useOrderedCARE
