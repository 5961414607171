import { Text, View } from "@react-pdf/renderer"
import React from "react"
import HeaderBanner from "utils/pdf/header/HeaderBanner"
import PPage from "utils/pdf/PPage"
import NormalText from "utils/pdf/NormalText"
import SmallText from "utils/pdf/SmallText"
import Html from "react-pdf-html"
import PTitle from "utils/pdf/PTitle"
import { stylesheet } from "./DaybookEventsList"
import PH2 from "utils/pdf/header/PH2"

const DaybookScenarios = ({
	selectedEvent,
	currentFiche,
	currentActionCategory,
	orgas,
	indexInSummary,
}) => {
	const checkedFichesActionsIds = selectedEvent.scenarioActionIds.map(
		(object) => object.actionId,
	)

	const currentFicheActions = Object.values(currentFiche.actions)
		.filter((action: any) => checkedFichesActionsIds.includes(action.id))
		.filter((action: any) =>
			currentActionCategory.actionIds.includes(action.id),
		)

	if (currentFicheActions.length === 0) {
		return null
	}

	const getVigilanceColor = (vigilance) =>
		vigilance === "RED"
			? "red"
			: vigilance === "ORANGE"
			? "orange"
			: vigilance === "YELLOW"
			? "yellow"
			: vigilance === "GREEN"
			? "green"
			: vigilance === "BLUE"
			? "aqua"
			: "gray"

	const displayCells = (orgaCellsId) => {
		const JsxElementNoCells = (
			<SmallText
				style={{
					padding: "3px 7px",
					margin: "2px",
					border: "1px solid #e2e2e2",
					borderRadius: "50%",
					backgroundColor: "#fcfcfc",
				}}
			>
				Aucune
			</SmallText>
		)

		if (!orgaCellsId) {
			return JsxElementNoCells
		}

		let cellsNotFound = 0
		return orgaCellsId.map((cellId, index) => {
			//* Prevent not displaying empty cells caused by a deleted orga cell
			if (!orgas[cellId]) {
				cellsNotFound++
				if (orgaCellsId.length === cellsNotFound) {
					return JsxElementNoCells
				}
				return
			}
			return (
				<SmallText
					key={index}
					style={{
						marginRight: "3px",
						padding: "3px 7px",
						margin: "3px",
						border: "1px solid #e2e2e2",
						borderRadius: "50%",
						backgroundColor: "#fcfcfc",
					}}
				>
					{orgas[cellId]?.idName}
				</SmallText>
			)
		})
	}

	return (
		<PPage orientation="landscape">
			<HeaderBanner>
				<PH2 marginBottom="0px">{indexInSummary}. Fiche scénario</PH2>
			</HeaderBanner>
			<PTitle color="#2e74b5" marginBottom={8} fontSize={14}>
				{currentActionCategory.categoryName}
			</PTitle>
			<View
				style={{
					borderBottom: "1px solid #cecece",
				}}
			/>
			{currentFicheActions.map((fiche: any) => {
				return (
					<View
						key={fiche.id}
						style={{
							display: "flex",
							flexDirection: "row",
							borderBottom: "1px solid #cecece",
						}}
						wrap={false}
					>
						<Text
							style={{
								width: "10px",
								backgroundColor: getVigilanceColor(fiche["Vigilance"]),
								marginRight: "10px",
							}}
						/>
						<View
							style={{
								width: "100%",
								padding: "4px 0",
							}}
						>
							<Html
								stylesheet={stylesheet}
								style={{
									fontSize: "10px",
								}}
							>
								{fiche["Contenu"]}
							</Html>
							<NormalText style={{ fontSize: 10 }}>
								Cellules associées :
							</NormalText>
							<View
								style={{
									width: "100%",
									display: "flex",
									flexDirection: "row",
									flexWrap: "wrap",
									backgroundColor: "#F0F5FF",
								}}
							>
								{displayCells(fiche["Cellules"])}
							</View>
						</View>
					</View>
				)
			})}
		</PPage>
	)
}

export default DaybookScenarios
