import { yupResolver } from "@hookform/resolvers/yup"
import CancelIcon from "@mui/icons-material/Cancel"
import CheckIcon from "@mui/icons-material/Check"
import {
	ContentState,
	convertToRaw,
	EditorChangeType,
	EditorState,
	Modifier,
	SelectionState,
} from "draft-js"
import draftToHtml from "draftjs-to-html"
import htmlToDraft from "html-to-draftjs"
import React, { createContext, useEffect, useState } from "react"
import { isDesktop } from "react-device-detect"
import { Editor } from "react-draft-wysiwyg"
import { FormProvider, useForm } from "react-hook-form"
import styled, { css } from "styled-components"
import {
	PrimaryButton,
	PrimaryOutlinedButton,
} from "@/utils/components/style/button"
import { Flex } from "@/utils/components/style/flex"
import ErrorService from "@/services/ErrorService"
import Yup from "@/utils/yup"
import { useDispatch } from "react-redux"
import imageRendererFn from "./imagePlugin/ImagePlugin"
import TitleInput from "./TitleInput"
import BlockToolbar from "./toolbar/BlockToolbar"
import ColorPickerToolbar from "./toolbar/ColorPickerToolbar"
import FontSizeToolbar from "./toolbar/FontSizeToolbar"
import HistoryToolbar from "./toolbar/HistoryToolbar"
import ImageToolbar from "./toolbar/ImageToolbar"
import InlineToolbar from "./toolbar/InlineToolbar"
import ListToolbar from "./toolbar/ListToolbar"
import TextAlignToolbar from "./toolbar/TextAlignToolbar"
import Button from "@/styles/atoms/Button"
import { FixOlUlStyle } from "@/utils/form/utils/FixOlUlStyle"

const EditorContainer = styled.div`
    margin: auto;
    max-width: 990px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;

    .editor-class {
        max-width: initial !important;
        padding: 1cm;

        .public-DraftStyleDefault-block {
            margin: 0px !important;
        }
        .rdw-center-aligned-block * {
            text-align: center !important;
        }
        .rdw-left-aligned-block * {
            text-align: left !important;
        }
        .rdw-right-aligned-block * {
            text-align: right !important;
        }
        .rdw-justify-aligned-block * {
            text-align: justify !important;
        }

        display: block !important;
        word-break: break-word !important;
        overflow-wrap: break-word !important;
        font-family: 'cursive' !important;
        font-weight: 400 !important;
        letter-spacing: 0.5px !important;
        line-height: 24px !important;
        font-size: 14px !important;

        h1, h2, h3, h4, h5, h6 {
            font-weight: bold !important;
            line-height: 1.1 !important;
            padding: 0em !important;
            letter-spacing: 0.5px !important;
            margin: 0em !important;
        }

        h1 > * {
            font-size: 24px !important;
        }
        h2 > * {
            font-size: 22px !important;
        }
        h3 > * {
            font-size: 20px !important;
        }
        h4 > * {
            font-size: 18px !important;
        }
        h5 > * {
            font-size: 16px !important;
        }
        h6 > * {
            font-size: 14px !important;
        }

        p {
            display: block !important;
            word-break: break-word !important;
            overflow-wrap: break-word !important;
            font-weight: 400 !important;
            letter-spacing: 0.5px !important;
            line-height: 24px;
            font-size: 14px;
            margin: 0em !important;
			white-space: pre-wrap !important;
        }
        span {
            word-break: break-word !important;
            overflow-wrap: inherit;
            letter-spacing: inherit;
            line-height: inherit;
            font-size: inherit;
            margin: inherit;
        }
        figure {
            margin: 0 !important;
        }
    }
    .toolbar-class {
        margin: 0;
        padding: 0;
        display: flex;
        ${
					isDesktop
						? css`
                  flex-wrap: wrap;
              `
						: css`
                  flex-wrap: nowrap;
                  overflow: auto;
              `
				};
    }

    .wrapper-class {
        display: flex;
        flex-direction: column;
        gap: 10px;
    }
    .buttons-class {
        flex-wrap: wrap;
        padding: 10px;
        gap: 5px;
        justify-content: flex-end;
    }

    .editor-class,
    .toolbar-class,
    .buttons-class {
        background-color: white;
        border-radius: 4px;
    }

    .editor-class,
    .buttons-class {
        box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%),
            0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
    }

    .toolbar-class {
        position: sticky;
        top: 0;
        z-index: 500;
        box-shadow: var(--shadow-scroll);
    }
`

export const EditorContext = createContext({
	editorState: null,
	setEditorState: (_state) => null,
	readOnly: false,
	setReadOnly: (_boo) => null,
})

const removeDeletedImages = (editorState) => {
	const selectionState = editorState.getSelection()
	const anchorKey = selectionState.getAnchorKey()
	const currentContent = editorState.getCurrentContent()
	const currentContentBlock = currentContent.getBlockForKey(anchorKey)

	if (
		currentContentBlock.getType() !== "atomic" &&
		currentContentBlock
			.getCharacterList()
			.some(
				(cm) =>
					cm.getEntity() &&
					currentContent.getEntity(cm.getEntity()).getType() === "IMAGE",
			)
	) {
		editorState._immutable = editorState._immutable.set("allowUndo", false)

		const selection = SelectionState.createEmpty(
			currentContentBlock.getKey(),
		).merge({
			anchorOffset: 0,
			focusOffset: currentContentBlock.getText().length,
		})

		return EditorState.push(
			editorState,
			Modifier.applyEntity(currentContent, selection, null),
			"apply-entity",
		)
	}
	return editorState
}

function HTMLEditor({
	title,
	htmlData,
	onCancel,
	onValidate,
	validateMessage = "Sauvegarder",
	titleLabel = "Titre",
	writePermission = true,
}) {
	const [editorState, setEditorState] = useState(EditorState.createEmpty())
	const dispatch = useDispatch()
	const methods = useForm({
		resolver: yupResolver(
			Yup.object().shape({
				title: Yup.string().required("L'arrêté doit avoir un nom"),
			}),
		),
	})

	useEffect(() => {
		if (htmlData) {
			const startsWithImage = /^([^<]*<[^>]*>[^<]*<img)/gm
			const isUnsafe = htmlData.replaceAll("\n", "").match(startsWithImage)

			if (isUnsafe)
				ErrorService.error({
					component: "HTMLEditor",
					message: `Arrêté "${title}" malformé : image without p before`,
					silence: true,
					dispatch,
				})

			const blocksFromHtml = htmlToDraft(
				isUnsafe ? "<p></p>" + htmlData : htmlData,
				(nodeName, node) => {
					if (nodeName === "p") {
						const imgNode = Array.from(node.childNodes).find(
							(node: any) => node.nodeName.toLowerCase() === "img",
						) as any
						if (imgNode) {
							const config = {
								type: "IMAGE",
								mutability: "MUTABLE",
								data: {
									alignment:
										node.style.textAlign === "center"
											? null
											: node.style.textAlign,
									src: imgNode.src ?? "",
									width: imgNode.style.width ?? "auto",
									height: imgNode.style.height ?? "auto",
								},
							}
							return config
						}
					}
				},
			)
			const { contentBlocks, entityMap } = blocksFromHtml
			const contentState = ContentState.createFromBlockArray(
				contentBlocks,
				entityMap,
			)
			const editorState = EditorState.createWithContent(contentState)
			setEditorState(editorState)
		}
	}, [htmlData])

	useEffect(() => {
		methods.reset({ title })
	}, [title])

	const save = ({ title }) => {
		const contentState = editorState.getCurrentContent()
		const rawContentState = convertToRaw(contentState)
		const markup = draftToHtml(rawContentState, {}, false, ({ type, data }) => {
			if (type === "IMAGE") {
				const alignment = data.alignment || "none"
				const textAlign = alignment === "none" ? "center" : alignment

				//! DON'T INDENT THIS HTML, else it can cause render troubles on PDF
				return `<p style="text-align:${textAlign};"><img src="${data.src}" alt="${data.alt}" style="height: ${data.height};width: ${data.width}"/></p>`
			}
		})
		onValidate(title, markup)
	}

	const cancel = () => {
		onCancel()
	}

	const [readOnly, setReadOnly] = useState(false)

	const onEditorStateChange = (newEditorState: EditorState) => {
		setEditorState(removeDeletedImages(newEditorState))
	}

	return (
		<FixOlUlStyle>
			<EditorContainer>
				<EditorContext.Provider
					value={{
						setEditorState,
						editorState,
						readOnly,
						setReadOnly,
					}}
				>
					<FormProvider {...methods}>
						<Flex className="buttons-class" $wrap>
							<TitleInput name="title" label={titleLabel} />
							{writePermission && (
								<Flex gap="5px" $wrap flexEnd>
									<Button
										color="primary"
										variant="bordered"
										onClick={cancel}
										startContent={<CancelIcon />}
									>
										Annuler
									</Button>
									<Button
										color="primary"
										onClick={methods.handleSubmit(save)}
										startContent={<CheckIcon />}
									>
										{validateMessage}
									</Button>
								</Flex>
							)}
						</Flex>
					</FormProvider>
					<Editor
						readOnly={readOnly}
						editorState={editorState}
						onEditorStateChange={onEditorStateChange}
						customBlockRenderFunc={imageRendererFn}
						wrapperClassName="wrapper-class"
						editorClassName="editor-class"
						toolbarClassName="toolbar-class"
						toolbar={{
							options: [
								"inline",
								"colorPicker",
								"blockType",
								"fontSize",
								"list",
								"textAlign",
								"image",
								"history",
							],
							image: {
								previewImage: true,
								uploadEnabled: true,
								uploadCallback: (file) => {
									return new Promise((resolve, reject) => {
										if (file) {
											const reader = new FileReader()
											reader.onload = (e) => {
												resolve({
													data: { link: e.target.result },
												})
											}
											reader.readAsDataURL(file)
										}
									})
								},
								defaultSize: {
									height: "auto",
									width: "200px",
								},
								component: ImageToolbar,
							},
							inline: {
								component: InlineToolbar,
								options: ["bold", "italic", "underline"],
							},
							blockType: {
								component: BlockToolbar,
							},
							fontSize: {
								component: FontSizeToolbar,
							},
							list: {
								component: ListToolbar,
							},
							textAlign: {
								component: TextAlignToolbar,
							},
							colorPicker: {
								component: ColorPickerToolbar,
							},
							history: {
								component: HistoryToolbar,
							},
						}}
					/>
				</EditorContext.Provider>
			</EditorContainer>
		</FixOlUlStyle>
	)
}

export default HTMLEditor
