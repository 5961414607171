import React from "react"
import { View } from "@react-pdf/renderer"
import PH2 from "utils/pdf/header/PH2"
import PImage from "utils/pdf/image/PImage"
import PPage from "utils/pdf/PPage"
import PPie from "utils/pdf/PPie"
import HeaderBanner from "utils/pdf/header/HeaderBanner"
import NormalText from "utils/pdf/NormalText"
import PTitle from "utils/pdf/PTitle"
import LargeText from "utils/pdf/LargeText"

const DaybookDashboardPage = ({
	selectedEvent,
	screenshotBlobs,
	indexInSummary,
}) => {
	const getVigilanceColor = (vigilance) => {
		switch (vigilance) {
			case "GREEN":
				return "Vert"
			case "YELLOW":
				return "Jaune"
			case "ORANGE":
				return "Orange"
			case "RED":
				return "Rouge"
			case "BLUE":
				return "Bleu"
		}
	}

	const getDate = (date) => {
		const dateDay = date?.slice(8, 10)
		const dateMonth = date?.slice(5, 7)
		const dateYear = date?.slice(0, 4)
		return `${dateDay}/${dateMonth}/${dateYear}`
	}

	const getTime = (date) => {
		const time = date?.slice(11, 16)
		return time
	}

	const legend1 = [
		{
			desc: "Normale",
			color: "grey",
		},
		{
			desc: "Urgente",
			color: "red",
		},
	]
	const legend2 = [
		{
			desc: "En cours",
			color: "orange",
		},
		{
			desc: "Terminé",
			color: "green",
		},
	]
	const legend3 = [
		{
			desc: "A faire",
			color: "blue",
		},
		{
			desc: "En cours",
			color: "orange",
		},
		{
			desc: "Terminé",
			color: "green",
		},
	]
	const legend4 = [
		{
			desc: "Normal",
			color: "grey",
		},
		{
			desc: "Temporaire",
			color: "black",
		},
	]

	return (
		<PPage>
			<HeaderBanner>
				<PH2 marginBottom="0px">{indexInSummary}. Tableau de bord</PH2>
			</HeaderBanner>
			<PTitle color="#2e74b5" marginBottom={10}>
				Niveau de vigilance :{" "}
				<LargeText>{getVigilanceColor(selectedEvent.vigilance)}</LargeText>
			</PTitle>
			<PTitle color="#2e74b5" marginBottom={10}>
				Actualité de la crise :{" "}
				<LargeText>{selectedEvent.active ? "En cours" : "Terminée"}</LargeText>
			</PTitle>
			<LargeText marginBottom={10}>
				Crise déclarée le {getDate(selectedEvent.startDate)} à{" "}
				{getTime(selectedEvent.startDate)}h
			</LargeText>
			{selectedEvent.endDate && (
				<LargeText marginBottom={10}>
					Fin de crise le {getDate(selectedEvent.endDate)} à{" "}
					{getTime(selectedEvent.endDate)}h
				</LargeText>
			)}

			<PTitle color="#2e74b5" marginBottom={10}>
				Synthèse :
			</PTitle>
			<View
				style={{
					display: "flex",
					flexDirection: "row",
					justifyContent: "space-around",
					alignItems: "center",
				}}
			>
				<PPie
					title="Événement par priorité"
					blobImage={screenshotBlobs.eventPriorityBlob}
					alt="Digramme des événements par priorité."
					legends={legend1}
					width="100px"
				/>
				<PPie
					title="Événement par statut"
					blobImage={screenshotBlobs.eventStatusBlob}
					alt="Digramme des événements par statut."
					legends={legend2}
					width="100px"
				/>
			</View>
			<View
				style={{
					display: "flex",
					flexDirection: "row",
					justifyContent: "space-around",
					alignItems: "center",
				}}
			>
				<PPie
					title="Intervention par statut"
					blobImage={screenshotBlobs.actionStatusBlob}
					alt="Digramme des interventions par statut."
					legends={legend3}
					width="100px"
				/>
				<PPie
					title="Centre d'accueil"
					blobImage={screenshotBlobs.careBlob}
					alt="Digramme des centres d'accueil."
					legends={legend4}
					width="100px"
				/>
			</View>

			<LargeText marginBottom={5}>Nombre d&apos;évènements par heure</LargeText>
			<View
				style={{
					display: "flex",
					flexDirection: "column",
				}}
			>
				<PImage
					src={screenshotBlobs.chartBlob}
					alt="Diagramme du nombre d'évènements par heure"
					width="520px"
				/>
			</View>
		</PPage>
	)
}

export default DaybookDashboardPage
