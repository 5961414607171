import { ColumnDef } from "@tanstack/react-table"
import JsonSchemaService from "@/pages/carto2/cartographie/service/JsonSchemaService"
import useJsonSchemasWithStatics from "@/hooks/useJsonSchemasWithStatics"
import React, { useMemo } from "react"
import PhonesCell from "@/utils/components/PhonesCell"
import { Flex } from "@/utils/components/style/flex"
import _ from "lodash"
import getImage from "@/utils/getImage"
import IPoint from "@/utils/types/IPoint"

export const useColumnsDaybookAlertContactTable = () => {
	const jsonSchemasWithStatics = useJsonSchemasWithStatics()

	const columns = useMemo(() => {
		return [
			{
				header: "Type de données",
				accessorKey: "jsonschema_id",
				cell: ({ getValue }) => {
					const value = getValue() as string
					const jsonSchema = jsonSchemasWithStatics[value]
					return (
						<Flex gap="1rem" padding="0.25rem">
							<img
								src={getImage(jsonSchema?.imgId)}
								alt={jsonSchema?.title}
								width="30"
								height="30"
							/>
							<span>{jsonSchema?.title}</span>
						</Flex>
					)
				},
			},
			{
				header: "Identifiant",
				accessorKey: "identifiant",
			},
			{
				header: "Téléphone",
				accessorKey: "phone",
				cell: ({ getValue }) => {
					const value = getValue() as string
					return <PhonesCell phonesList={JSON.parse(value ?? "[]")} />
				},
			},
		] as ColumnDef<any>[]
	}, [jsonSchemasWithStatics])

	return columns
}

export const useTransformedAndSortedDataDaybookAlertContactTable = (
	data: IPoint[],
	listeDiffusions: string[],
) => {
	const jsonSchemasWithStatics = useJsonSchemasWithStatics()

	const transformedData = useMemo(() => {
		return data
			.map((point) => {
				const jsonSchema = jsonSchemasWithStatics[point.jsonschema_id]
				const idProperties = JsonSchemaService.getIdProperties(jsonSchema)
				const phoneProperty = JsonSchemaService.getPhoneProperty(jsonSchema)
				if (_.isEmpty(phoneProperty)) {
					return null
				}
				return {
					id: point.id,
					identifiant: idProperties.reduce(
						(acc, property) =>
							`${acc} ${point.geojson.properties[property.name]}`,
						"",
					),
					phone: JSON.stringify(point.geojson.properties[phoneProperty.name]),
					jsonschema_id: point.jsonschema_id,
					...point,
				}
			})
			.filter((point) => point !== null)
			.sort((a, b) => {
				// sort by index of listDiffusionIds
				const aIndex = listeDiffusions?.indexOf(a.jsonschema_id) ?? -1
				const bIndex = listeDiffusions?.indexOf(b.jsonschema_id) ?? -1
				if (aIndex === -1 && bIndex === -1) {
					return 0
				}
				if (aIndex === -1) {
					return 1
				}
				if (bIndex === -1) {
					return -1
				}
				return aIndex - bIndex
			})
	}, [data, jsonSchemasWithStatics, listeDiffusions])

	return transformedData
}
