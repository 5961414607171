import React, { useContext } from "react"
import CartList from "./CartList"
import { TreeContext } from "../../redux/page/context"
import Debug from "debug"
import styled from "styled-components"

const debug = Debug("components:telechargement:Cart")

const Wrapper = styled.div`
    flex: 1 1 0;
    display: flex;
    flex-direction: column;
`

const Cart = ({
	keyOrder,
	setKeyOrder,
	updateSelectedKit = undefined,
	kitSelected = undefined,
	isEditing = false,
}) => {
	const { state: treeState, dispatch: treeDispatch } = useContext(TreeContext)

	const getCheckedName = (tree) => {
		let res = []
		const treeKeys = Object.keys(tree)
		const treeArray = treeKeys.map((key) => tree[key])
		treeArray.forEach((treeElement) => {
			if (treeElement.checked) {
				res.push(treeElement)
			} else if (treeElement.children) {
				const resArray = getCheckedName(treeElement.children)
				res = [...res, ...resArray]
			}
		})
		return res
	}

	if (!treeState) {
		return null
	}

	const checkedTreeElement = getCheckedName(treeState)
	debug("checkedTreeElement=", checkedTreeElement)
	return (
		<Wrapper>
			<CartList
				checkedTreeElement={checkedTreeElement}
				updateSelectedKit={updateSelectedKit}
				kitSelected={kitSelected}
				isEditing={isEditing}
				keyOrder={keyOrder}
				setKeyOrder={setKeyOrder}
			/>
		</Wrapper>
	)
}

export default Cart
