import React from "react"
import { Document } from "@react-pdf/renderer"
import DaybookDashboardPage from "./pages/DaybookDashboardPage"
import DaybookMapPage from "./pages/DaybookMapPage"
import DaybookPage from "./pages/DaybookPage"
import DaybookCoverPage from "./pages/DaybookCoverPage"
import DaybookEndPage from "./pages/DaybookEndPage"
import { Font } from "@react-pdf/renderer"
import { NO_BLOB } from "@/pages/maincourante/DaybookDashboard"
import DaybookCrisisOrga from "./components/DaybookCrisisOrga"
import {
	CARTOGRAPHY,
	CRISIS_ORGA,
	DASHBOARD,
	DAYBOOK,
	SCENARIOS,
} from "@/pages/carto2/cartographie/service/ReportService"
import _ from "lodash"
import DaybookScenarios from "./components/DaybookScenarios"
import IFiche from "utils/types/IFiche"

Font.register({
	family: "Roboto",
	src: "https://fonts.gstatic.com/s/roboto/v20/KFOmCnqEu92Fr1Me5Q.ttf",
})
Font.registerEmojiSource({
	format: "png",
	url: "https://twemoji.maxcdn.com/2/72x72/",
})

const ReportPDF = ({
	points,
	pointsWithBlobs,
	staticPoints,
	coucheEvents,
	jsonSchemas,
	multiPolygons,
	humans,
	orgas,
	crisisOrgaWithOrder,
	fiches,
	arretes,
	objectOfArretesBlobsObject,
	objectOfWisiwygBlobsArray,
	coucheJsonSchemas,
	coucheMultiPolygons,
	commune,
	communeLogoSrc,
	selectedEvent,
	screenshotBlobs,
	existingSignalMarker,
	daybookExistingDatas,
	summaryCategories,
}) => {
	const currentFiche: IFiche[] = (selectedEvent?.scenarioIds ?? []).map(
		(id) => fiches[id],
	)?.[0]

	return (
		<Document>
			<DaybookCoverPage
				selectedEvent={selectedEvent}
				commune={commune}
				communeLogoSrc={communeLogoSrc}
				summaryCategories={summaryCategories}
			/>
			<DaybookDashboardPage
				selectedEvent={selectedEvent}
				screenshotBlobs={screenshotBlobs}
				indexInSummary={
					_.indexOf(
						Object.values(summaryCategories),
						summaryCategories[DASHBOARD],
					) + 1
				}
			/>
			<DaybookCrisisOrga
				humans={humans}
				crisisOrgaWithOrder={crisisOrgaWithOrder}
				indexInSummary={
					_.indexOf(
						Object.values(summaryCategories),
						summaryCategories[CRISIS_ORGA],
					) + 1
				}
			/>
			{/* @ts-ignore ; actionsByCategory appear as not existing into IFiche else*/}
			{(currentFiche?.actionsByCategory ?? []).map((currentActionCategory) => {
				return (
					<DaybookScenarios
						key={currentActionCategory.id}
						selectedEvent={selectedEvent}
						currentFiche={currentFiche}
						currentActionCategory={currentActionCategory}
						orgas={orgas}
						indexInSummary={
							_.indexOf(
								Object.values(summaryCategories),
								summaryCategories[SCENARIOS],
							) + 1
						}
					/>
				)
			})}
			<DaybookPage
				selectedEvent={selectedEvent}
				points={points}
				pointsWithBlobs={pointsWithBlobs}
				multiPolygons={multiPolygons}
				humans={humans}
				orgas={orgas}
				arretes={arretes}
				objectOfArretesBlobsObject={objectOfArretesBlobsObject}
				jsonSchemas={jsonSchemas}
				daybookExistingDatas={daybookExistingDatas}
				indexInSummary={
					_.indexOf(
						Object.values(summaryCategories),
						summaryCategories[DAYBOOK],
					) + 1
				}
				summaryCategories={summaryCategories}
				objectOfWisiwygBlobsArray={objectOfWisiwygBlobsArray}
			/>
			{screenshotBlobs.mapScreenBlob !== NO_BLOB && (
				<DaybookMapPage
					staticPoints={staticPoints}
					coucheEvents={coucheEvents}
					jsonSchemas={jsonSchemas}
					multiPolygons={multiPolygons}
					mapSignalMarkers={selectedEvent.mapSignalMarkers}
					coucheJsonSchemas={coucheJsonSchemas}
					coucheMultiPolygons={coucheMultiPolygons}
					screenshotBlobs={screenshotBlobs}
					existingSignalMarker={existingSignalMarker}
					indexInSummary={
						_.indexOf(
							Object.values(summaryCategories),
							summaryCategories[CARTOGRAPHY],
						) + 1
					}
				/>
			)}
			<DaybookEndPage communeLogoSrc={communeLogoSrc} />
		</Document>
	)
}
export default ReportPDF
