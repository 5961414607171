import FilterAltIcon from "@mui/icons-material/FilterAlt"
import {
	FormControlLabel,
	IconButton,
	Menu,
	MenuItem,
	Switch,
} from "@mui/material"
import useCooperationFilters from "@/hooks/useCooperationFilters"
import { setCooperationFilters } from "@/redux-toolkit/edit/reducer"
import React, { useState } from "react"
import { useDispatch } from "react-redux"
import styled, { css } from "styled-components"
import { isMobile } from "react-device-detect"
import _ from "lodash"
const MyFormControlLabel = styled(FormControlLabel)`
    margin: 0 !important;
    padding: 4px 12px;
    width: 100%;

    & .MuiFormControlLabel-label {
        white-space: pre-wrap !important;
        ${
					isMobile &&
					css`
                display: inline-block;
                padding-left: 10px;
            `
				}
    }
`

const MyMenuItem = styled(MenuItem)`
    padding: 0 !important;
`

const IconButtonWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0 5px;
`

function SearchBarOptions({ excludeCommunes, layers }) {
	const [anchorEl, setAnchorEl] = useState(null)
	const open = (event) => setAnchorEl(event.currentTarget)
	const close = () => setAnchorEl(null)

	const coopFilters = useCooperationFilters()
	const dispatch = useDispatch()

	return (
		<>
			<IconButtonWrapper>
				<IconButton onClick={open} size={isMobile ? "small" : undefined}>
					<FilterAltIcon htmlColor="var(--primary500)" />
				</IconButton>
			</IconButtonWrapper>
			<Menu
				anchorEl={anchorEl}
				open={anchorEl}
				onClose={close}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "right",
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: "right",
				}}
			>
				{layers && (
					<MenuItem disabled style={{ opacity: 0.9 }}>
						{layers.label}
					</MenuItem>
				)}
				{layers &&
					coopFilters.availableLayers.map((layer) => (
						<MyMenuItem key={layer}>
							<MyFormControlLabel
								control={
									<Switch
										size={isMobile ? "small" : undefined}
										checked={_.isEqual(coopFilters.layer, layer)}
										onChange={(event) => {
											dispatch(
												setCooperationFilters({
													layer: event.target.checked ? layer : undefined,
												}),
											)
										}}
									/>
								}
								label={layer}
							/>
						</MyMenuItem>
					))}

				{excludeCommunes && (
					<MenuItem disabled style={{ opacity: 0.9 }}>
						Recherche avancée
					</MenuItem>
				)}

				{excludeCommunes && (
					<MyMenuItem>
						<MyFormControlLabel
							control={
								<Switch
									size={isMobile ? "small" : undefined}
									checked={coopFilters.excludeCommunes}
									onChange={(event) =>
										dispatch(
											setCooperationFilters({
												excludeCommunes: event.target.checked,
											}),
										)
									}
								/>
							}
							label="Exclure les communes sélectionnées"
						/>
					</MyMenuItem>
				)}

				{!excludeCommunes && <MyMenuItem>Aucune option disponible</MyMenuItem>}
			</Menu>
		</>
	)
}

export default SearchBarOptions
