import createDeepEqualSelector from "@/utils/createDeepEqualSelector"
import { useSelector } from "react-redux"

const selectIsOpenMenu = createDeepEqualSelector(
	(state: any) => state.common.openMenu,
	(openMenu: any) => openMenu,
)

const useIsOpenMenu = () => {
	return useSelector(selectIsOpenMenu)
}

export default useIsOpenMenu
