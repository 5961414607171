import Axios from "axios"
import ErrorService from "@/services/ErrorService"

function deleteAllCookies() {
	const cookies = document.cookie.split(";")

	for (let i = 0; i < cookies.length; i++) {
		const cookie = cookies[i]
		const eqPos = cookie.indexOf("=")
		const name = eqPos > -1 ? cookie.substr(0, eqPos).trim() : cookie.trim()

		// Create a regex to find all cookies with the same name
		const nameRegex = new RegExp(`(^|;\\s*)${name}=`, "g")

		// Delete all cookies with the same name
		document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/"

		let retryCount = 0
		while (document.cookie.match(nameRegex) && retryCount < 5) {
			document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/"
			retryCount++
		}
	}
}

const initializeInterceptors = () => {
	Axios.interceptors.response.use(
		function (response) {
			// Any status code that lie within the range of 2xx cause this function to trigger
			// Do something with response data
			return response
		},
		function (error) {
			// Any status codes that falls outside the range of 2xx cause this function to trigger
			// Do something with response error
			if (error?.response?.status === 401) {
				localStorage.clear()
				// clear cookies
				deleteAllCookies()
				window.DatabaseService.deleteDB()
				window.location.href = "/"
				return
			}

			return Promise.reject(error)
		},
	)
	Axios.defaults.timeout = 60000
}

const safeCall = async (fn, ...args) => {
	try {
		const result = await fn(...args)
		// Check for null or any other condition you deem as an error state
		if (result === null || result.data === undefined) {
			throw new Error("Received null or undefined response")
		}
		return result
	} catch (error) {
		console.log("Error:", error)
		// ErrorService.error({ component: "AxiosService", message: error.message });
		// Return null or a specific error structure if you want to differentiate this case
		return null
	}
}

export default {
	initializeInterceptors,
	safeCall,
}
