import { yupResolver } from "@hookform/resolvers/yup"
import React from "react"
import { FormProvider, useForm } from "react-hook-form"
import FText from "utils/form/FText"
import { LinkType } from "utils/types/IFiche"
import Yup from "utils/yup"
import useParentSubmit from "./utils/parentSubmit/useParentSubmit"

const schema = Yup.object().shape({
	type: Yup.string().default(LinkType.EXTERNAL),
	url: Yup.string().url("L'URL doit être valide").required(),
	text: Yup.string().required(),
})

const ExternalLink = () => {
	const methods = useForm({
		resolver: yupResolver(schema),
		defaultValues: schema.cast({}),
	})

	useParentSubmit(methods.handleSubmit)

	return (
		<FormProvider {...methods}>
			<FText label="URL du lien" name="url" />
			<FText label="Intitulé du lien" name="text" />
		</FormProvider>
	)
}

export default ExternalLink
