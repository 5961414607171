import LoadingButton from "@mui/lab/LoadingButton"
import { TextField } from "@mui/material"
import { isMobile } from "react-device-detect"
import styled, { css } from "styled-components"
import { Flex } from "utils/components/style/flex"

export const Explanation = styled.div`
    font-size: 0.75rem;
    color: var(--primary800);
`
export const TestContainer = styled(Flex)`
    width: 100%;
    max-width: 500px;
    ${
			isMobile &&
			css`
        width: 70%;
    `
		}
`
export const STextField = styled(TextField)`
    width: 100%;
    min-width: 500px;
`
export const SLoadingButton = styled(LoadingButton)<{ $hasSended: boolean }>`
    ${({ $hasSended }) =>
			$hasSended &&
			`
    border-color: var(--green500) !important;
    color: var(--green500) !important;
    `}
`
