import useSendSmsAlert from "@/hooks/api/useSendSmsAlert"
import useCommune from "@/hooks/useCommune"
import { useFormContext, useWatch } from "react-hook-form"
import { SMS_CHUNK_SIZE } from "utils/form/alert/FTextAlert"
import _ from "lodash"
import IDaybookAlert, {
	DaybookAlertCanal,
} from "@/utils/types/daybook/IDaybookAlert"

const SMS_COST = 0.08
const CREDITS_VOICE_COST_MOBILE = 0.9
const CREDITS_VOICE_COST_FIXED = 0.5

export const useCampaignCost = () => {
	const commune = useCommune()
	const { watch } = useFormContext()
	const { type, listeDiffusions, listeDiffusionIds, smsText, voiceContent } =
		watch("geojson.properties") as IDaybookAlert["geojson"]["properties"]
	const { analyzeCompatiblePhones } = useSendSmsAlert()
	const { uniqPhones } = analyzeCompatiblePhones()

	let cost = {}
	let campaignRemainingCost = 0
	if (type === DaybookAlertCanal.SMS) {
		const smsLength = smsText?.length || 0
		const smsCount = Math.ceil(smsLength / SMS_CHUNK_SIZE)

		cost = {
			"Cout de la campagne en crédits": uniqPhones.length * smsCount,
			"Nombre de crédits gratuits restants":
				commune.credits > 0 ? commune.credits : 0,
		}
		campaignRemainingCost =
			(cost["Cout de la campagne en crédits"] -
				cost["Nombre de crédits gratuits restants"]) *
			SMS_COST
	} else {
		// 06 XX XX XX XX or 07 XX XX XX XX
		const regex = /^0[67] [0-9]{2} [0-9]{2} [0-9]{2} [0-9]{2}$/
		const countPhonesMobile = uniqPhones.filter((phone) =>
			phone.match(regex),
		).length
		const countPhonesFixed = uniqPhones.length - countPhonesMobile
		cost = {
			"Cout de la campagne en crédits":
				countPhonesMobile * CREDITS_VOICE_COST_MOBILE +
				countPhonesFixed * CREDITS_VOICE_COST_FIXED,
			"Nombre de crédits gratuits restants":
				commune.credits > 0 ? commune.credits : 0,
		}
		campaignRemainingCost =
			(cost["Cout de la campagne en crédits"] -
				cost["Nombre de crédits gratuits restants"]) *
			SMS_COST
	}

	return { cost, campaignRemainingCost }
}
