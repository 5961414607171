import { yupResolver } from "@hookform/resolvers/yup"
import useRiskFiles from "@/hooks/useRiskFiles"
import useUploadedFiles from "@/hooks/useUploadedFiles"
import React, { useMemo } from "react"
import { FormProvider, useForm } from "react-hook-form"
import FList from "utils/form/FList"
import useParentSubmit from "utils/form/specific/fiche/links/utils/parentSubmit/useParentSubmit"
import Yup from "utils/yup"

const schema = Yup.object().shape({
	replace: Yup.string().required("Aucune fiche séléctionnée"),
})

const SelectRiskFile = () => {
	const methods = useForm({
		resolver: yupResolver(schema),
		defaultValues: schema.cast({}),
	})

	useParentSubmit(methods.handleSubmit)

	const uploadedFiles = useUploadedFiles()
	const disabledItems = useMemo(
		() =>
			Object.values(uploadedFiles)
				.filter((file) => "pcs" in file.config && "replace" in file.config.pcs)
				// @ts-ignore
				.map((file) => file.config.pcs.replace),
		[uploadedFiles],
	)

	const riskFiles = useRiskFiles(false)
	const items = useMemo(() => Object.values(riskFiles), [riskFiles])

	return (
		<FormProvider {...methods}>
			<FList
				name="replace"
				disabledItems={disabledItems}
				items={items}
				idAccessor={(riskFile) => riskFile.key}
				titleAccessor={(riskFile) => `Fiche pédagogique ${riskFile.risk}`}
			/>
		</FormProvider>
	)
}

export default SelectRiskFile
