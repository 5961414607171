import ContactPhone from "@mui/icons-material/ContactPhone"
import { Alert, AlertTitle, IconButton } from "@mui/material"
import Debug from "debug"
import _ from "lodash"
import React, { useState } from "react"
import { useController, useFormContext } from "react-hook-form"
import { useBoolean } from "react-use"
import styled, { css } from "styled-components"
import CloseIcon from "@mui/icons-material/Close"
import EditIcon from "@mui/icons-material/Edit"
import ConfirmationModal from "@/utils/components/ConfirmationModal"
import Button from "@/styles/atoms/Button"
import { Flex } from "@/utils/components/style/flex"
import { Popover, PopoverContent, PopoverTrigger } from "@nextui-org/react"
import ContactPopoverContent from "@/utils/popover/ContactPopoverContent"
// app:javascript:utils:form:specific:incident:FContact.sx
const debug = Debug("app:javascript:utils:form:specific:incident:FContact.sx")
debug.log = console.log.bind(console)

const ControledSizeValue = styled.div<{ $disabled: boolean }>`
    ${({ $disabled }) =>
			css`
            inline-size: ${$disabled ? "250px" : "150px"};
        `}
    overflow-wrap: break-word;
`
const SAlert = styled(Alert)`
    width: 100%;
`
const TriggerContainer = styled.div`
	width: 100%;
`

const FContact = ({ disabled = false }) => {
	const [isOpenPopoverContact, togglePopoverContact] = useBoolean(false)
	const [anchorEl, setAnchorEl] = useState(null)
	const [isOpenConfirmationModal, setIsOpenConfirmationModal] = useState(false)
	const { control } = useFormContext()

	const {
		field: { onChange, value },
	} = useController({
		name: "Contact",
		control,
	})

	const actionAlertProps = disabled
		? null
		: {
				action: (
					<Flex>
						<Popover>
							<PopoverTrigger>
								<IconButton
									onClick={(event) => {
										togglePopoverContact()
										setAnchorEl(event.currentTarget)
									}}
								>
									<EditIcon />
								</IconButton>
							</PopoverTrigger>
							<PopoverContent>
								<ContactPopoverContent
									open={isOpenPopoverContact}
									onSubmit={onChange}
									initialValues={value}
									onClose={() => {
										togglePopoverContact()
									}}
								/>
							</PopoverContent>
						</Popover>
						<IconButton onClick={() => setIsOpenConfirmationModal(true)}>
							<CloseIcon />
						</IconButton>
					</Flex>
				),
				onClose: () => {
					onChange({})
				},
			}

	return (
		<>
			<Popover
				placement="left"
				isOpen={isOpenPopoverContact}
				onOpenChange={togglePopoverContact}
			>
				{_.isEmpty(value) && (
					<PopoverTrigger>
						<TriggerContainer>
							<Button
								color="primary"
								variant="bordered"
								fullWidth
								onClick={(event) => {
									togglePopoverContact()
									setAnchorEl(event.currentTarget)
								}}
								startContent={<ContactPhone />}
								disabled={disabled}
							>
								Fiche contact
							</Button>
						</TriggerContainer>
					</PopoverTrigger>
				)}
				{!_.isEmpty(value) && (
					<TriggerContainer>
						<SAlert icon={null} severity="info" {...actionAlertProps}>
							<AlertTitle>Contact</AlertTitle>
							<Flex directionColumn alignItemsStart>
								{value.Identité && (
									<ControledSizeValue $disabled={disabled}>
										{value?.Identité}
									</ControledSizeValue>
								)}
								{value.Téléphone && (
									<ControledSizeValue $disabled={disabled}>
										{value?.Téléphone}
									</ControledSizeValue>
								)}
								{value.Courriel && (
									<ControledSizeValue $disabled={disabled}>
										{value?.Courriel}
									</ControledSizeValue>
								)}
							</Flex>
						</SAlert>
					</TriggerContainer>
				)}
				<PopoverContent>
					<ContactPopoverContent
						open={isOpenPopoverContact}
						onSubmit={onChange}
						initialValues={value}
						onClose={() => {
							togglePopoverContact()
						}}
					/>
				</PopoverContent>
			</Popover>

			<ConfirmationModal
				message={"Voulez-vous vraiment supprimer ce contact ?"}
				modalShown={isOpenConfirmationModal}
				onClose={() => setIsOpenConfirmationModal(false)}
				onConfirm={() => {
					onChange({})
					setIsOpenConfirmationModal(false)
				}}
			/>
		</>
	)
}

export default FContact
