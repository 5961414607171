import { IconButton } from "@mui/material"
import React, { useState } from "react"
import { toast } from "react-toastify"
import styled from "styled-components"
import ConfirmationModal from "utils/components/ConfirmationModal"
import CustomTooltip from "utils/components/style/CustomTooltip"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"

const Div = styled.div``

const ValidateButton = ({
	onClick,
	wrapper = undefined,
	small = false,
	disabled = false,
}) => {
	const [isOpen, setIsOpen] = useState(false)
	const Wrapper = wrapper ?? Div
	return (
		<>
			<Wrapper
				onClick={(event) => {
					if (disabled) {
						return
					}
					setIsOpen(true)
					event.stopPropagation()
				}}
			>
				<CustomTooltip title={"Valider"}>
					<IconButton
						size={small ? "small" : "medium"}
						style={{ fontSize: 24 }}
						aria-label="Validate"
						disabled={disabled}
					>
						<CheckCircleIcon style={{ fontSize: 24 }} />
					</IconButton>
				</CustomTooltip>
			</Wrapper>
			<ConfirmationModal
				modalShown={isOpen}
				message={"Êtes-vous certain(e) de vouloir valider ce(s) élément(s)"}
				onClose={() => setIsOpen(false)}
				onConfirm={() => {
					onClick()
					toast.info("Elément(s) validé(s)")
					setIsOpen(false)
				}}
			/>
		</>
	)
}

export default ValidateButton
