import livrets_pcs from "isoMorphicData/livrets.json"
import livrets_pics from "isoMorphicData/livrets_pics.json"
import { useMemo } from "react"
import useIsInterco from "./useIsInterco"

const livretsWithKey = (livrets) => Object.fromEntries(
	Object.entries(livrets).map(([key, detail], index) => [
		key,
		{ ...detail, key, index },
	]),
)

const useLivrets = () => {
	const isInterco = useIsInterco()
	const livrets = isInterco ? livrets_pics : livrets_pcs

	return useMemo(() => livretsWithKey(livrets), [])
}

export default useLivrets
