import { yupResolver } from "@hookform/resolvers/yup"
import { DialogActions, DialogContent, DialogTitle } from "@mui/material"
import React, { useEffect, useState } from "react"
import { FormProvider, useForm } from "react-hook-form"
import styled from "styled-components"
import SDialog from "@/utils/components/SDialog"
import FText from "@/utils/form/FText"
import Yup from "@/utils/yup"
import CancelButton from "@/styles/atoms/Button/specialized/CancelButton"
import Button from "@/styles/atoms/Button"

const SDialogContent = styled(DialogContent)`
    padding-top: 10px !important;
`

const schema = Yup.object().shape({
	title: Yup.string().required(),
})

function TitleDialog({
	title = "",
	open,
	onSave,
	onClose,
	onCancel = onClose,
	headerText,
	saveLabel = "Enregistrer",
	inputLabel = "Titre",
}) {
	const [isSubmitting, setIsSubmitting] = useState(false)
	const methods = useForm({
		resolver: yupResolver(schema),
	})
	methods.watch()

	const onClick = () => {
		if (isSubmitting) return
		setIsSubmitting(true)
		onSave(methods.getValues().title)
	}

	useEffect(() => {
		if (open) {
			methods.reset({ title })
			setIsSubmitting(false)
		}
	}, [open])

	return (
		<FormProvider {...methods}>
			<SDialog open={open} onClose={onClose} fullWidth={true} maxWidth={"sm"}>
				<DialogTitle>{headerText}</DialogTitle>
				<SDialogContent>
					<FText name="title" label={inputLabel} />
				</SDialogContent>
				<DialogActions>
					<CancelButton onClick={onCancel} />
					<Button color="primary" onClick={onClick} disabled={isSubmitting}>
						{saveLabel}
					</Button>
				</DialogActions>
			</SDialog>
		</FormProvider>
	)
}

export default TitleDialog
