import usePoints from "@/hooks/usePoints"
import useSelectedEvent from "@/hooks/useSelectedEvent"
import TableIncidentSubRow from "@/pages/maincourante/following/TableIncidentSubRow"
import { INTERVENTION } from "@/redux-toolkit/userSettings/constants"
import Debug from "debug"
import React from "react"
import { useFormContext } from "react-hook-form"
import styled from "styled-components"
import { H4 } from "utils/components/style/header"

// app:javascript:utils:form:specific:incident:FInterventionsEtActions.tsx
const debug = Debug(
	"app:javascript:utils:form:specific:incident:FInterventionsEtActions",
)
debug.log = console.log.bind(console)

const StyledH4 = styled(H4)`
    margin-bottom: -1rem !important;
`

const FInterventionsLinkedToIncident = ({ tmpId, isNew, addTask }) => {
	const points = usePoints()
	const { getValues } = useFormContext()
	const selectedEvent = useSelectedEvent()
	const linkedInterventions = Object.values(points).filter(
		(point) =>
			point.event_id === selectedEvent?.id &&
			point.jsonschema_id === INTERVENTION &&
			point?.geojson?.properties?.["En lien avec"] === tmpId,
	)

	return (
		<>
			{linkedInterventions?.length > 0 && (
				<>
					<StyledH4>Interventions</StyledH4>
					<TableIncidentSubRow
						incident={{
							geojson: { properties: getValues() },
							id: tmpId,
						}}
						type={isNew ? "NEW" : "EDIT"}
						addTask={addTask}
					/>
				</>
			)}
		</>
	)
}

export default FInterventionsLinkedToIncident
