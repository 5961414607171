import React from "react"
import ModalPoint from "./ModalPoint"
import ModalIncident from "./ModalIncident/ModalIncident"
import ModalIntervention from "./ModalIntervention/ModalIntervention"
/**
 * * Global Modals to edit Numerisk object
 */
const Modals = () => {
	return (
		<>
			<ModalPoint />
			<ModalIncident />
			<ModalIntervention />
		</>
	)
}

export default Modals
