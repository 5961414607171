import { DialogActions, DialogContent, DialogTitle } from "@mui/material"
import React, { useEffect, useState } from "react"
import SDialog from "utils/components/SDialog"
import {
	PrimaryButton,
	PrimaryOutlinedButton,
	PrimaryTextButton,
} from "utils/components/style/button"
import { Flex } from "utils/components/style/flex"
import ParentSubmitButtonWrapper from "utils/form/specific/fiche/links/utils/parentSubmit/ParentSubmitButtonWrapper"
import { ParentSubmitContextProvider } from "utils/form/specific/fiche/links/utils/parentSubmit/ParentSubmitContext"
import { IFileConfigCategory } from "utils/types/IUploadedFile"
import FormStep from "../../component/FormStep"
import SelectAfterPCS from "../../component/SelectAfterPCS"
import SelectRiskFile from "../../component/SelectRiskFile"
import SelectStaticFile from "../../component/SelectStaticFile"
import useStep from "../../hooks/useStep"
import useUploadLocation from "../../hooks/useUploadLocation"
import intercoNameAdapter from "utils/intercoNameAdapter"
import useIsInterco from "@/hooks/useIsInterco"
import useUploadedFiles from "@/hooks/useUploadedFiles"
import _ from "lodash"

const StepLocation = ({ file = undefined, ...props }) => {
	const {
		value,
		setStepValue,
		onBack,
		onSkip,
		dependencies,
		shouldLeave,
		disabled,
		setDisabled,
	} = useStep("location", { dependencies: ["category"] })

	const { getUploadLocation } = useUploadLocation()
	const uploadedFiles = useUploadedFiles()
	const uploadCategory = dependencies.category as IFileConfigCategory
	const isInterco = useIsInterco()

	const [mode, setMode] = useState<"after" | "replace">(undefined)
	const [modalOpen, setModalOpen] = useState(false)
	const [fileIdsToDisabled, setFileIdsToDisabled] = useState([])

	useEffect(() => {
		if (shouldLeave) setModalOpen(false)
	}, [shouldLeave])

	useEffect(() => {
		if (
			(uploadCategory === "IMAGE" || uploadCategory === "EDITABLE") &&
			!disabled
		)
			setDisabled(true)
		else if (disabled) setDisabled(false)
	}, [uploadCategory])

	const startMode = (m: "replace" | "after") => {
		setMode(m)
		setModalOpen(true)
	}

	useEffect(() => {
		if (!file) return
		const tempFileIdsToDisabled = [file.id]
		const findUploadedFileFollowingId = (fileId) => {
			Object.values(uploadedFiles).forEach((uploadedFile) => {
				const isAfterId = _.cloneDeep(uploadedFile.config?.pcs?.after) //bypass read-only
				if (isAfterId === fileId) {
					tempFileIdsToDisabled.push(uploadedFile.id)
					return findUploadedFileFollowingId(uploadedFile.id)
				}
			})
		}
		findUploadedFileFollowingId(file.id)
		setFileIdsToDisabled(tempFileIdsToDisabled)
	}, [file])

	return (
		<FormStep
			question="Où souhaitez-vous placer votre fichier ?"
			formatedValue={
				value && getUploadLocation({ pcs: value, category: uploadCategory })
			}
			onBack={onBack}
			disabled={disabled}
			{...props}
		>
			<Flex directionColumn gap="1rem">
				<Flex gap>
					{uploadCategory === "PEDAGOGIQUE" && (
						<PrimaryButton onClick={() => startMode("replace")}>
							A la place d&apos;une fiche pédagogique
						</PrimaryButton>
					)}

					{uploadCategory === "PROCEDURE" && (
						<PrimaryButton onClick={() => startMode("replace")}>
							A la place d&apos;une procédure
						</PrimaryButton>
					)}

					{uploadCategory !== "CONVENTION"}
					<PrimaryButton onClick={() => startMode("after")}>
						{intercoNameAdapter("Après un élément du PCS", isInterco)}
					</PrimaryButton>

					<PrimaryButton onClick={() => setStepValue({ appendix: true })}>
						En annexe
					</PrimaryButton>
					{file && (
						<PrimaryOutlinedButton onClick={() => onSkip(file.config)}>
							Passer
						</PrimaryOutlinedButton>
					)}
				</Flex>
			</Flex>
			<ParentSubmitContextProvider onSubmit={setStepValue}>
				<SDialog
					onClose={() => setModalOpen(false)}
					open={modalOpen}
					maxWidth="md"
					fullWidth
				>
					<DialogTitle>
						{mode === "replace" &&
							uploadCategory === "PEDAGOGIQUE" &&
							"Remplacer une fiche pédagogique"}
						{mode === "replace" &&
							uploadCategory === "PROCEDURE" &&
							"Remplacer une procédure"}
						{mode === "after" &&
							intercoNameAdapter("Ajouter au PCS", isInterco)}
					</DialogTitle>
					<DialogContent dividers>
						{mode === "replace" && uploadCategory === "PEDAGOGIQUE" && (
							<SelectRiskFile />
						)}
						{mode === "replace" && uploadCategory === "PROCEDURE" && (
							<SelectStaticFile />
						)}
						{mode === "after" && (
							<SelectAfterPCS
								title={intercoNameAdapter(
									"Sélectionnez un livret du PCS puis la catégorie ou la fiche qui précédera votre fichier téléversé",
									isInterco,
								)}
								fileIdsToDisabled={fileIdsToDisabled}
							/>
						)}
					</DialogContent>
					<DialogActions>
						<PrimaryTextButton onClick={() => setModalOpen(false)}>
							Annuler
						</PrimaryTextButton>
						<ParentSubmitButtonWrapper>
							{({ onClick }) => (
								<PrimaryButton onClick={onClick}>Valider</PrimaryButton>
							)}
						</ParentSubmitButtonWrapper>
					</DialogActions>
				</SDialog>
			</ParentSubmitContextProvider>
		</FormStep>
	)
}

export default StepLocation
