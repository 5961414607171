import styled, { css } from "styled-components"
import { Flex } from "utils/components/style/flex"
import { isMobile } from "react-device-detect"

const DaybookAlertRightContainer = styled(Flex)`
    background-color: white;
    padding: 1rem;
    width: 100%;
    ${
			!isMobile &&
			css`
        max-height: calc(100vh - 20rem);
    `
		}
    /* overflow-y: auto; */
    box-shadow: var(--cool-shadow);
`

export default DaybookAlertRightContainer
