import React from "react"
import styled, { css } from "styled-components"
import {
	Avatar,
	Card,
	CardContent,
	CardHeader,
	Grid,
	Table,
	TableBody,
	TableCell,
	TableRow,
} from "@mui/material"
import TwitterCard from "./TwitterCard"
import UpdateCard from "./UpdateCard"
import { isDesktop } from "react-device-detect"
import PersonIcon from "@mui/icons-material/Person"
import { H6 } from "utils/components/style/header"
import { Subtitle } from "utils/components/style/text"
import { Flex } from "utils/components/style/flex"
import { useSelector } from "react-redux"
import useCommune from "@/hooks/useCommune"
import _ from "lodash"
import Moment from "moment"
import { Button } from "@nextui-org/react"
import { toast } from "react-toastify"
const StyledContainer = styled.div`
    height: 100%;
    overflow: auto;
`
const SGrid = styled(Grid)`
    ${
			isDesktop &&
			css`
        height: 100%;
        width: 100%;
    `
		};
`
const SCard = styled(Card)`
    margin: 0.5rem;
    box-shadow: var(--cool-shadow) !important;
`

const referentsNumerisk = {
	"Alexis Thomine": {
		name: "Alexis THOMINE",
		email: "pcs@numerisk.fr",
		phone: "07.89.07.74.28",
	},
	"Paul Heymes": {
		name: "Paul HEYMES",
		email: "contact@numerisk.fr",
		phone: "06.33.01.80.38",
	},
	"Hadrien Buguet": {
		name: "Hadrien BUGUET",
		email: "hadrien.buguet@numerisk.fr",
		phone: "07.89.24.79.44",
	},
	"Léa Lamy": {
		name: "Léa LAMY",
		email: "lea.lamy@numerisk.fr",
		phone: "07.89.51.73.72",
	},
	"Alix Duhaut-Guillou": {
		name: "Alix DUHAUT-GUILLOU",
		email: "alix.duhaut@numerisk.fr",
		phone: "07.89.51.73.77",
	},
}

const Home2 = () => {
	const commune = useCommune()
	const referents = commune.coda?.referent?.split(",") ?? []
	const referentsList = referents.map((refer) => referentsNumerisk[refer])
	return (
		<StyledContainer>
			<SGrid container>
				<Grid item sm={6}>
					<TwitterCard />
				</Grid>
				<SGrid item sm={6}>
					<SCard>
						<CardHeader title="Informations générales" />
						<CardContent>
							<Table>
								<TableBody>
									{!_.isEmpty(referentsList) && (
										<>
											{referentsList.map((referent) => (
												<TableRow>
													<TableCell>Référent Numérisk</TableCell>

													<TableCell>
														<Flex gap="1rem">
															<Avatar>
																<PersonIcon />
															</Avatar>
															<div>
																<H6>
																	<span>{referent?.name}</span>
																</H6>
																<Subtitle>
																	{referent?.email} / {referent?.phone}
																</Subtitle>
															</div>
														</Flex>
													</TableCell>
												</TableRow>
											))}
										</>
									)}
									{commune.coda?.dateFinLicence && (
										<TableRow>
											<TableCell>Date de fin de licence</TableCell>
											<TableCell>
												<Flex gap="1rem">
													{Moment(commune.coda.dateFinLicence).format(
														"DD/MM/YYYY",
													)}
												</Flex>
											</TableCell>
										</TableRow>
									)}
									{commune.coda?.exercice && (
										<TableRow>
											<TableCell>Exercice</TableCell>
											<TableCell>
												<Flex gap="1rem">
													Un exercice est prévu sur la collectivité
												</Flex>
											</TableCell>
										</TableRow>
									)}
									<TableRow>
										<TableCell>Astreinte</TableCell>
										<TableCell>
											<Flex gap="1rem">05.46.46.14.70</Flex>
										</TableCell>
									</TableRow>
								</TableBody>
							</Table>
						</CardContent>
					</SCard>
					<UpdateCard />
				</SGrid>
			</SGrid>
		</StyledContainer>
	)
}

export default Home2
