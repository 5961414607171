import React, { useMemo, useState, useEffect } from "react"
import { Pie, PieChart, Tooltip } from "recharts"
import { Card, CardContent } from "@mui/material"
import { v4 as uuidv4 } from "uuid"
import Moment from "moment"
import styled from "styled-components"

const PieContainer = styled(Card)`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    max-width: 400px;
`

const PieChartWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`
const Tips = styled.div`
    margin: 0 1vw;
`
const TipsTitle = styled.div`
    font-weight: 600;
`
const TipsContent = styled.div``

const GreenLegend = styled.div`
    height: 10px;
    width: 10px;
    margin-right: 0.5em;
    background-color: green;
`

const OrangeLegend = styled.div`
    height: 10px;
    width: 10px;
    background-color: orange;
    margin-right: 0.5em;
`

const RedLegend = styled.div`
    height: 10px;
    width: 10px;
    margin-right: 0.5em;
    background-color: red;
`

const Wrapper = styled.div`
    display: flex;
    align-items: center;
    padding: 0.4em 0.2em;
`
const LowPaddingCardContent = styled(CardContent)`
    padding: 0 !important;
`

const DataPie = ({ data, isDashboard }) => {
	const [key, setKey] = useState(undefined)
	const [hasAnimated, setHasAnimated] = useState(false)
	const pieData = useMemo(() => {
		const newData = [
			{
				name: "Mis à jour récemment il y a moins de 1 an",
				value: 0,
				fill: "green",
			},
			{
				name: "Mis à jour il y a moins de 2 ans",
				value: 0,
				fill: "orange",
			},
			{
				name: `Mis à jour il y a plus de 2 ans`,
				value: 0,
				fill: "red",
			},
		]
		data.forEach((stock) => {
			const now = Moment()
			const stockDate = Moment(stock.updated_at)
			now.subtract(12, "months")
			if (stockDate.isAfter(now)) {
				newData[0].value += 1
				return
			}
			now.subtract(24, "months")
			if (stockDate.isAfter(now)) {
				newData[1].value += 1
				return
			}
			if (stockDate.isBefore(now)) {
				newData[2].value += 1
				return
			}
			return
		})

		return newData.filter((row) => row.value > 0)
	}, [data])

	useEffect(() => {
		setKey(uuidv4())
	}, [data])
	return (
		<PieContainer>
			<LowPaddingCardContent>
				<PieChartWrapper>
					<PieChart width={200} height={200}>
						<Pie
							isAnimationActive={!hasAnimated}
							onAnimationEnd={() => setHasAnimated(true)}
							key={key}
							data={pieData}
							dataKey="value"
							innerRadius={30}
							outerRadius={50}
							label
						/>
						<Tooltip />
						<text x={100} y={100} textAnchor="middle" dominantBaseline="middle">
							{data.length}
						</text>
					</PieChart>
				</PieChartWrapper>
			</LowPaddingCardContent>
			<LowPaddingCardContent>
				<Wrapper>
					<GreenLegend />
					Il y a - de 1 an
				</Wrapper>
				<Wrapper>
					<OrangeLegend />
					Il y a - de 2 ans
				</Wrapper>
				<Wrapper>
					<RedLegend />
					Il y a + de 2 ans
				</Wrapper>
			</LowPaddingCardContent>
			{!isDashboard && (
				<Tips>
					<TipsTitle>État :</TipsTitle>
					<TipsContent>
						Chaque icône de la colonne état du tableau représente la date de
						dernière mise-à-jour de la donnée selon la légende ci-dessus
					</TipsContent>
				</Tips>
			)}
		</PieContainer>
	)
}

export default DataPie
