import useFiches from "@/hooks/useFiches"
import Debug from "debug"
import React from "react"
import { TypeFiche } from "@/utils/types/IFiche"
import RiskService from "@/utils/services/RiskService"
import { Autocomplete, TextField } from "@mui/material"
import { useController, useFormContext } from "react-hook-form"
// app:javascript:utils:form:FSelectFiche.tsx
const debug = Debug("app:javascript:utils:form:FSelectFiche")
debug.log = console.log.bind(console)

const FSelectFiche = ({ name, label, disabled = false }) => {
	const fiches = useFiches()

	const optionFiches = Object.values(fiches).filter(
		(fiche) => fiche.classification === TypeFiche.FICHE_SCENARIO,
	)
	const options = optionFiches.map((fiche) => ({
		label: `${fiche.risk} : (${fiche.nom})`,
		value: fiche.id,
		group: RiskService.getRiskParent(fiche.risk),
	}))

	const { control } = useFormContext()
	const {
		field: { onChange, value, ref },
		fieldState: { error },
	} = useController({
		name,
		control,
		rules: { required: true },
	})

	return (
		<>
			<Autocomplete
				noOptionsText="Aucun risque correspondant"
				value={value?.[0]}
				onChange={(e, newFiche: any) => {
					onChange([newFiche])
				}}
				options={options}
				renderInput={(params) => <TextField {...params} label={label} />}
				groupBy={(option) => option.group}
			/>
		</>
	)
}

export default FSelectFiche
