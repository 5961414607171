import styled, { css } from "styled-components"
import React, { useState, useEffect, useMemo, useRef } from "react"
import { Flex } from "utils/components/style/flex"
import { isMobile } from "react-device-detect"

const LeftContainer = styled(Flex)`
    background-color: white;
    min-width: 400px;
    width: 400px;
    ${
			isMobile &&
			css`
        width: 100%;
        min-width: 100%;
    `
		}
    padding: 1rem;
    box-shadow: var(--cool-shadow);
`

export default LeftContainer
