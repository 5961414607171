import App from "@/pages/App"
import GlobalStyle from "@/pages/app/GlobalStyle"
import { yupResolver } from "@hookform/resolvers/yup"
import { NextUIProvider } from "@nextui-org/react"
import axios from "axios"
import React, { createContext, useContext, useEffect, useState } from "react"
import { FormProvider, useForm } from "react-hook-form"
import styled from "styled-components"
import LoginCard from "./LoginCard"
import { loginSchema } from "./LoginForm.schema"
if (
	localStorage.getItem("access-token") &&
	localStorage.getItem("client") &&
	localStorage.getItem("uid")
) {
	axios.defaults.headers.common["access-token"] =
		localStorage.getItem("access-token")
	axios.defaults.headers.common["client"] = localStorage.getItem("client")
	axios.defaults.headers.common["uid"] = localStorage.getItem("uid")
}

const BackgroundVideo = styled.video`
	position: fixed; /* or absolute; depending on use case */
    left: 0;
    bottom: 0;
    min-width: 100vw;
    min-height: 100%;
    width: 100vw;
    height: auto;
    z-index: -100; /* ensures video stays in the background */
    background-size: cover;
    overflow: hidden;
	max-width: 100vw;
    object-fit: cover;
	filter: brightness(0.45);
`

const GlobalContainer = styled.div`
	display: grid;
	place-items: center;
	height: 100vh;
	width: 100vw;
	position: fixed;
	top: 0;
	left: 0;
`
function getCookie(name) {
	const value = `; ${document.cookie}`
	const parts = value.split(`; ${name}=`)
	if (parts.length === 2) return parts.pop().split(";").shift()
}
export const LogingContext = createContext<{
	setIsLoggingIn: (isLoggingIn: boolean) => void
	isLoggingIn: boolean
}>({ setIsLoggingIn: () => {}, isLoggingIn: false })
const LoginForm = (props) => {
	const [isLoggingIn, setIsLoggingIn] = useState(false)

	return (
		<LogingContext.Provider value={{ isLoggingIn, setIsLoggingIn }}>
			<LoginFormContent {...props} />
		</LogingContext.Provider>
	)
}

const LoginFormContent = ({ env, token = {} }) => {
	const { isLoggingIn, setIsLoggingIn } = useContext(LogingContext)
	if (token["access-token"] && token["client"] && token["uid"]) {
		localStorage.setItem("access-token", token["access-token"])
		localStorage.setItem("client", token["client"])
		localStorage.setItem("uid", token["uid"])
	}

	if (
		document.cookie.includes("access-token") &&
		document.cookie.includes("client") &&
		document.cookie.includes("uid") &&
		getCookie("client") !== "null" &&
		getCookie("uid") !== "null" &&
		getCookie("access-token") !== "null" &&
		!localStorage.getItem("access-token")
	) {
		// retrieve access-token from cookie
		const accessToken = getCookie("access-token")
		const client = getCookie("client")
		const uid = getCookie("uid")
		localStorage.setItem("access-token", accessToken)
		localStorage.setItem("client", client)
		localStorage.setItem("uid", uid)
	}

	if (
		!document.cookie.includes("access-token") &&
		!document.cookie.includes("client") &&
		!document.cookie.includes("uid") &&
		localStorage.getItem("access-token") &&
		localStorage.getItem("client") &&
		localStorage.getItem("uid")
	) {
		document.cookie = `access-token=${localStorage.getItem("access-token")}`
		document.cookie = `client=${localStorage.getItem("client")}`
		document.cookie = `uid=${localStorage.getItem("uid")}`
	}

	if (
		localStorage.getItem("access-token") &&
		localStorage.getItem("client") &&
		localStorage.getItem("uid") &&
		localStorage.getItem("access-token") !== "null" &&
		localStorage.getItem("client") !== "null" &&
		localStorage.getItem("uid") !== "null"
	) {
		if (!axios.defaults.headers.common["access-token"]) {
			axios.defaults.headers.common["access-token"] =
				localStorage.getItem("access-token")
			axios.defaults.headers.common["client"] = localStorage.getItem("client")
			axios.defaults.headers.common["uid"] = localStorage.getItem("uid")
			// set cookies
			document.cookie = `access-token=${localStorage.getItem("access-token")}`
			document.cookie = `client=${localStorage.getItem("client")}`
			document.cookie = `uid=${localStorage.getItem("uid")}`
		}
		if (!isLoggingIn) {
			setIsLoggingIn(true)
		}
		return <App env={env} />
	}

	return (
		<NextUIProvider>
			<GlobalStyle />
			<BackgroundVideo autoPlay loop muted>
				<source src="/flood1.mp4" type="video/mp4" />
			</BackgroundVideo>
			<GlobalContainer>
				<LoginCard setIsLoggingIn={setIsLoggingIn} isLoggingIn={isLoggingIn} />
			</GlobalContainer>
		</NextUIProvider>
	)
}

export default LoginForm
