import organisationIcon from "@iconify/icons-ps/organisation"
import bulletCheckedList from "@iconify/icons-si-glyph/bullet-checked-list"
import houseIcon from "@iconify/icons-whh/house"
import { Icon } from "@iconify/react"
import { CardContent } from "@mui/material"
import AssessmentIcon from "@mui/icons-material/Assessment"
import useHumans from "@/hooks/useHumans"
import useJsonSchemasWithStatics from "@/hooks/useJsonSchemasWithStatics"
import usePoints from "@/hooks/usePoints"
import useStockPlaces from "@/hooks/useStockPlaces"
import useStocks from "@/hooks/useStocks"
import Moment from "moment"
import React, { useMemo } from "react"
import { connect } from "react-redux"
import styled from "styled-components"
import BoardWidget from "utils/components/widget/BoardWidget"
import CommunalDataStatus from "./CommunalDataStatus"
import DataStatus from "./DataStatus"
import useIsInterco from "@/hooks/useIsInterco"
import intercoNameAdapter from "utils/intercoNameAdapter"

const SAssessmentIcon = styled(AssessmentIcon)`
    color: white;
` as any

const StyledContent = styled.div`
    display: flex;
`
const DataTitle = styled.div`
    font-size: 15px;
    padding: 0 10px;
`
const SpaceItems = styled(CardContent)`
    display: flex;
    justify-content: space-between;
`
const CenterIcon = styled.div`
    display: flex;
    align-items: center;
`

export const RED_STATUS = "RED_STATUS"
export const ORANGE_STATUS = "ORANGE_STATUS"
export const GREEN_STATUS = "GREEN_STATUS"
const COMMUNAL_DATA_TITLE = "Données Communales"
const FICHES_TITLE = "Fiches"

const NumeriskIndicators = ({ fiches }) => {
	const stocks = Object.values(useStocks())
	const humans = Object.values(useHumans())
	const stockPlaces = Object.values(useStockPlaces())
	const points = Object.values(usePoints())
	const jsonSchemas = useJsonSchemasWithStatics()
	const jsonSchemaIds = Object.values(jsonSchemas).map(
		(schema: any) => schema.id,
	)

	const getStatusFromArrayOfData = (data) => {
		let tempDataState = GREEN_STATUS

		data.forEach((data: any) => {
			const now = Moment()
			const stockDate = Moment(data.updated_at)
			now.subtract(12, "months")
			if (stockDate.isAfter(now)) {
				tempDataState = GREEN_STATUS
				return
			}
			now.subtract(24, "months")
			if (stockDate.isAfter(now) && tempDataState !== RED_STATUS) {
				tempDataState = ORANGE_STATUS
				return
			}
			tempDataState = RED_STATUS
		})

		return tempDataState
	}

	const status = useMemo(() => {
		const result = {}
		const jsonSchemaIds = Object.keys(jsonSchemas)

		jsonSchemaIds.forEach((jsonSchemaId) => {
			const filteredPoints = points.filter(
				(point) => jsonSchemaId === point.jsonschema_id,
			)
			result[jsonSchemaId] = getStatusFromArrayOfData(filteredPoints)
		})

		return result
	}, [points, stocks, humans, stockPlaces, jsonSchemas])

	const isInterco = useIsInterco()

	return (
		<BoardWidget
			title="Indicateurs Généraux"
			colorLight="var(--orange400)"
			colorDark="var(--orange700)"
			icon={<SAssessmentIcon fontSize="large" />}
			responsiveHeight={false}
		>
			<>
				<CommunalDataStatus
					status={status}
					dataTitle={intercoNameAdapter(COMMUNAL_DATA_TITLE, isInterco)}
					imgName={houseIcon}
				/>
				<SpaceItems>
					<StyledContent>
						<Icon icon={organisationIcon} width={20} height={20} />
						<DataTitle>Organigramme</DataTitle>
					</StyledContent>
					<CenterIcon>
						<></>
					</CenterIcon>
				</SpaceItems>
				<DataStatus
					dataType={Object.values(fiches)}
					imgName={bulletCheckedList}
					dataTitle={FICHES_TITLE}
				/>
			</>
		</BoardWidget>
	)
}

const mapStateToProps = (state) => ({
	fiches: state.data.fiches,
})

export default connect(mapStateToProps, null)(NumeriskIndicators)
