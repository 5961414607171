import React, { useContext, useEffect, useMemo } from "react"
import ErrorService from "@/services/ErrorService"
import Axios from "axios"
import TreeSelection from "./TreeSelection"
import { toggleCheck, resetTree } from "../redux/tree/action"
import { TreeContext } from "../redux/page/context"
import { toast } from "react-toastify"
import { Flex } from "@/utils/components/style/flex"
import FText from "@/utils/form/FText"
import { FormProvider, useForm } from "react-hook-form"
import SuperModal from "@/utils/modals/SuperModal"
import { resetSelection } from "../redux/selection/action"
import { SelectionContext } from "../redux/selection/context"
import { useDispatch } from "react-redux"
import TreeService from "@/utils/services/TreeService"
import { ModalContent } from "@nextui-org/react"
import FSwitch from "@/utils/form/FSwitch"
import ModalBody from "@/styles/organisms/Modal/ModalBody"

const ModalEditKit = ({
	kitEdited,
	setKitSelected,
	updateSelectedKit,
	kitSelected,
	isOpen,
	onClose,
	fetchKits,
}) => {
	const MODAL_TITLE = "Edition du Kit : " + kitEdited.kit_name
	const methods = useForm({
		defaultValues: {
			newKitName: kitEdited.kit_name,
			config: {
				anonymize: kitEdited.config?.anonymize ?? false,
				generate_livret: kitEdited.config?.generate_livret ?? false,
			},
		},
	})
	const { state: treeState, dispatch: treeDispatch } = useContext(TreeContext)
	const flatTree = useMemo(() => TreeService.treeToFlat(treeState), [treeState])
	const { state: selectionState, dispatch: selectionDispatch } =
		useContext(SelectionContext)
	const dispatch = useDispatch()

	useEffect(() => {
		methods.reset({
			newKitName: kitEdited.kit_name,
			config: {
				anonymize: kitEdited.config?.anonymize ?? false,
				generate_livret: kitEdited.config?.generate_livret ?? false,
			},
		})
		treeDispatch(resetTree())
		const kitContent = kitEdited?.kit_content ?? []
		kitContent.forEach((element: any) => {
			let treeElement = flatTree[element]
			if (!treeElement?.key) {
			} else {
				let path = [treeElement.key]
				let i = 0
				while (treeElement.parent) {
					path.push("children", treeElement.parent.key)
					treeElement = treeElement.parent
					i++
					if (i > 4) {
						break
					}
				}
				treeDispatch(toggleCheck(path.reverse()))
			}
		})
	}, [kitEdited])

	const regroupNewAllChecked = []
	const getAllNewChecked = (element = {}, key) => {
		Object.values(element).forEach((subelement: any) => {
			if (subelement.checked) {
				regroupNewAllChecked.push(subelement.key)
			} else {
				getAllNewChecked(subelement.children ?? {}, [
					...key,
					subelement.key,
					"children",
				])
			}
		})
	}

	getAllNewChecked(treeState, [])

	const restoreTree = (newKitName, cancelEdit) => {
		if (kitSelected?.id === kitEdited.id && !cancelEdit) {
			setKitSelected({
				...kitSelected,
				kit_name: newKitName,
				kit_content: regroupNewAllChecked,
			})
			selectionDispatch(resetSelection())
			return
		}
		treeDispatch(resetTree())
		if (kitSelected) {
			kitSelected.kit_content.forEach((element: any) => {
				treeDispatch(toggleCheck(element.path))
			})
			return
		}
	}

	const onSubmitChangement = async (values) => {
		try {
			const orderedKitPcs = {}
			kitEdited.kit_content.forEach((element, index) => {
				orderedKitPcs[element] = index - kitEdited.kit_content.length - 1
			})
			const orderedNewKitPcs = regroupNewAllChecked.sort((a, b) => {
				const aOrder = orderedKitPcs[a] ?? 0
				const bOrder = orderedKitPcs[b] ?? 0
				return aOrder - bOrder
			})
			await Axios.put(`api/kit_pcs/${kitEdited.id}`, {
				data: {
					commune_id: kitEdited.kit_commune_id,
					kit_name: values.newKitName,
					kit_content: orderedNewKitPcs,
					config: {
						anonymize: values.config?.anonymize ?? false,
						generate_livret: values.config?.generate_livret ?? false,
					},
				},
			})
			toast.success("Les changements ont bien été appliqués !")
		} catch (error) {
			const errorMessage = `Une erreur est survenue : ${error}`
			ErrorService.error({
				component: "ModalKitPCS:onSubmit",
				message: errorMessage,
				dispatch,
			})
		}
		fetchKits()
		restoreTree(values.newKitName, false)
		onClose()
	}

	const cancel = () => {
		restoreTree(undefined, true)
		updateSelectedKit(undefined)
		onClose()
		methods.reset()
	}

	const handleCheck = (keys) => {
		treeDispatch(toggleCheck(keys))
	}

	return (
		<FormProvider {...methods}>
			<SuperModal
				isOpen={isOpen}
				title={MODAL_TITLE}
				onClick={methods.handleSubmit(onSubmitChangement)}
				onClose={cancel}
				isNew={false}
				size="2xl"
			>
				<>
					<Flex gap="1rem" directionColumn fullWidth alignItemsInitial>
						<FText name="newKitName" label="Nom du kit" />
						<FSwitch
							name="config.anonymize"
							label="Anonymiser les données (retire les numéros de téléphones et les mails)"
						/>
						<FSwitch
							name="config.generate_livret"
							label="Générer les livrets"
						/>
						<Flex alignItemsInitial>
							<TreeSelection handleCheck={handleCheck} />
						</Flex>
					</Flex>
				</>
			</SuperModal>
		</FormProvider>
	)
}

export default ModalEditKit
