import { Grow } from "@mui/material"
import React, { useEffect, useState } from "react"
import { isMobile } from "react-device-detect"
import {
	MapContainer,
	TileLayer,
	useMapEvents,
	ZoomControl,
} from "react-leaflet"
import styled, { css } from "styled-components"
import PointCard from "utils/components/jsonSchema/PointCard"
import SuperMap from "utils/components/map/SuperMap"
import useCommuneFilter from "../hooks/useCommuneFilter"
import useLayerFilter from "../hooks/useLayerFilter"
import useSearchHasStarted from "../hooks/useSearchHasStarted"
import useTagFilter from "../hooks/useTagFilter"
import AutoFitMarkerClusterGroup from "./AutoFitMarkerClusterGroup"
import EmptyMessage from "./EmptyMessage"
import IPoint from "utils/types/IPoint"
import MarkerDaybook from "@/pages/maincourante/subComponents/carto/MarkerDaybook"
import { setCooperationFilters } from "@/redux-toolkit/edit/reducer"
import { SearchView } from "@/redux-toolkit/edit/cooperationFiltersRessources"
import useCooperationFilters from "@/hooks/useCooperationFilters"
import { useDispatch } from "react-redux"
import useCdcSharedJsonSchemas from "@/hooks/useCdcSharedJsonSchemas"
import useJsonSchemasWithStatics from "@/hooks/useJsonSchemasWithStatics"
import JsonSchemaService from "@/pages/carto2/cartographie/service/JsonSchemaService"

const SPACE_TOP = 80

export const Drawer = styled.div<{ $isOpen: boolean; $spaceTop?: number }>`
    position: absolute;
    padding-bottom: 1rem;
    overflow: auto;
    right: 0;
    padding-right: 0.5rem;

    z-index: 1000;
    transition: transform 0.2s;
    transform: ${({ $isOpen }) =>
			$isOpen ? "translateX(0)" : "translateX(calc(100% + 10px))"};

    ${({ $spaceTop }) =>
			isMobile
				? css`
      		    top: 0.5em;
      		    width: calc(100% - 0.5rem);
      		    height: 100%;
      		`
				: css`
      		    top: ${$spaceTop ? $spaceTop : SPACE_TOP}px;
      		    width: 400px;
      		    max-height: calc(100% - (${$spaceTop ? $spaceTop : SPACE_TOP}px)) !important;
      		`}

    & > * {
        min-height: 100%;
    }
`

const MessageContainer = styled.div`
    position: absolute;
    pointer-events: none;
    bottom: 20px;
    left: ${isMobile ? "50px" : "0"};
    right: ${isMobile ? "5px" : "0"};
    z-index: 900;
    display: flex;
    justify-content: center;
`

const MapEvents = ({ ...events }) => {
	useMapEvents(events)
	return null
}

const SharedMap = ({
	allPointsWithShared,
}: {
	allPointsWithShared: IPoint[]
}) => {
	const coopFilters = useCooperationFilters()
	const dispatch = useDispatch()
	const [selected, setSelected] = useState(null)
	const [openInfos, setOpenInfos] = useState(false)
	const searchHasStarted = useSearchHasStarted()
	const cdcSharedJsonSchemas = useCdcSharedJsonSchemas()
	const jsonSchemas = useJsonSchemasWithStatics()
	const allJsonSchemas = { ...jsonSchemas, ...cdcSharedJsonSchemas }
	const [asSwitch, setAsSwitch] = useState(false)

	const layerFilter = useLayerFilter()
	const tagFilter = useTagFilter()
	const communeFilter = useCommuneFilter()

	return (
		<>
			<SuperMap
				hasSearchControl={false}
				zoom={10}
				disableMargin
				cooperation
				disableZoomControl
				displayOptions={{
					displayBottomControl: false,
					displayRightControl: false,
				}}
			>
				<TileLayer
					attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
					url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
				/>

				<AutoFitMarkerClusterGroup key={Date.now()}>
					{allPointsWithShared.map((marker: IPoint) => {
						const isSelected = marker === selected && openInfos
						if (isSelected) return null

						return (
							<MarkerDaybook
								key={marker.id}
								point={marker}
								handleClick={() => {
									setSelected(marker)
									setOpenInfos(true)
								}}
							/>
						)
					})}
				</AutoFitMarkerClusterGroup>
				{selected && openInfos && (
					<MarkerDaybook
						point={selected}
						handleClick={() => {
							setSelected(selected)
							setOpenInfos(true)
						}}
						isSelected
					/>
				)}

				<ZoomControl position="bottomright" />

				<MapEvents click={() => setOpenInfos(false)} />

				<Grow in={!searchHasStarted}>
					<MessageContainer>
						<EmptyMessage box />
					</MessageContainer>
				</Grow>
			</SuperMap>
			<Drawer $isOpen={openInfos}>
				<PointCard
					point={selected}
					key={selected?.id}
					onClose={() => setOpenInfos(false)}
					showCommune
				/>
			</Drawer>
		</>
	)
}

export default SharedMap
