import { SearchMode } from "@/redux-toolkit/edit/cooperationFiltersRessources"
import AccountTreeIcon from "@mui/icons-material/AccountTree"
import PeopleIcon from "@mui/icons-material/People"
import ConstructionIcon from "@mui/icons-material/Construction"
import React from "react"
import { isMobile } from "react-device-detect"
import _ from "lodash"
const searchConfig = [
	{
		mode: SearchMode.ORGANIGRAMMES,
		color: "#35b442",
		icon: <AccountTreeIcon htmlColor="#35b442" />,
		title: "Organigrammes",
		text: "Rechercher un organigramme",
		fields: {
			communes: true,
		},
		emptyMessage: "Sélectionnez une commune pour afficher son organigramme",
		hasStarted: (filters) => filters.communes.length,
	},
	{
		mode: SearchMode.DONNEES_PARTAGEES,
		color: "#d8eb09",
		icon: <ConstructionIcon htmlColor="#d8eb09" />,
		title: "Données partagées",
		text: "Rechercher une donnée",
		fields: {
			communes: true,
			layers: { label: "Quoi ?", inFilterMenu: false },
		},
		emptyMessage: isMobile
			? "Sélectionnez une donnée ou une commune"
			: "Sélectionnez une donnée ou une commune pour lancer la recherche",
		hasStarted: (filters) => {
			return (
				filters.communes.length ||
				!_.isEmpty(filters.tag) ||
				!_.isEmpty(filters.layer)
			)
		},
	},
]

export default searchConfig
