import Debug from "debug"
import React from "react"
import { useFormContext, useWatch } from "react-hook-form"
import FSelectWithImg from "utils/form/FSelectWithImg"

// app:javascript:utils:modals:ModalIntervention:FModalInterventionStatut.tsx
const debug = Debug(
	"app:javascript:utils:modals:ModalIntervention:FModalInterventionStatut",
)
debug.log = console.log.bind(console)

const FModalInterventionStatut = ({ disabled = false }) => {
	const options = [
		{
			label: "A faire",
			value: "A faire",
			img: "/img/maincourante/intervention_afaire_normale.png",
		},
		{
			label: "En cours",
			value: "En cours",
			img: "/img/maincourante/intervention_encours_normale.png",
		},
		{
			label: "Terminé",
			value: "Terminé",
			img: "/img/maincourante/intervention_termine_normale.png",
		},
	]
	return <FSelectWithImg options={options} name="Statut" disabled={disabled} />
}

export default FModalInterventionStatut
