import IconAdd from "@mui/icons-material/Add"
import DoneIcon from "@mui/icons-material/Done"
import ErrorIcon from "@mui/icons-material/Error"
import WarningIcon from "@mui/icons-material/Warning"
import ErrorPermissions, {
	BACK_PATH_HOME,
	BACK_TITLE_HOME,
	FEATURE_ERROR_PERMISSION,
} from "@/hooks/ErrorPermissions"
import useAccessRights from "@/hooks/useAccessRights"
import useFiches from "@/hooks/useFiches"
import { useOrgaWithOrder } from "@/hooks/useOrga"
import Moment from "moment"
import React, { useMemo } from "react"
import { isMobile } from "react-device-detect"
import { useDispatch } from "react-redux"
import { useHistory } from "react-router-dom"
import { useBoolean } from "react-use"
import styled from "styled-components"
import PersistentCustomTabs from "utils/components/customTabs/PersistentCustomTabs"
import { PrimaryButton } from "utils/components/style/button"
import SuperReactTable from "utils/components/tables/SuperReactTable/SuperReactTable"
import { width60 } from "utils/components/tables/widthProps"
import TitleHeader from "utils/components/TitleHeader"
import IFiche, { FICHE_SCENARIO, TypeFiche } from "utils/types/IFiche"
import ModalFicheAction from "./ModalFicheAction"
import ModalFicheScenario from "./ModalFicheScenario"
import { createFiche, deleteFiche } from "@/redux-toolkit/data/fiche/resources"
import useIsAdminNumerisk from "@/hooks/useIsAdminNumerisk"
import { IconButton } from "@mui/material"
import ContentCopy from "@mui/icons-material/ContentCopy"
import { v4 as uuidv4 } from "uuid"
import { EnumToolPath } from "@/hooks/services/useAccessRightsServices"

const StyledDoneIcon = styled(DoneIcon)`
    color: var(--green) !important;
`

const StyledErrorIcon = styled(ErrorIcon)`
    color: var(--red) !important;
`

const StyledWarningIcon = styled(WarningIcon)`
    color: var(--orange) !important;
`

const TableFiches = () => {
	const fiches = useFiches()

	const [isOpenModalScenario, toggleModalScenario] = useBoolean(false)
	const [isOpenModalFicheAction, toggleModalFicheAction] = useBoolean(false)
	const history = useHistory()
	const isAdminNumerisk = useIsAdminNumerisk()
	const { cellules: orga, order } = useOrgaWithOrder()
	const dispatch = useDispatch()

	const checkPermission = (
		tab_path: EnumToolPath.FICHES_ACTIONS | EnumToolPath.FICHES_SCENARIOS,
	) => {
		const permissions = useAccessRights(tab_path)
		return permissions
	}

	const columnsScenarios = useMemo(
		() => [
			{
				header: "État",
				accessorKey: "État",
				...width60,
				cell: (data) => {
					const now = Moment()
					const stockDate = Moment(data.row.original.updated_at)
					now.subtract(12, "months")
					if (stockDate.isAfter(now)) {
						return <StyledDoneIcon />
					}
					now.subtract(24, "months")
					if (stockDate.isAfter(now)) {
						return <StyledWarningIcon />
					}
					return <StyledErrorIcon />
				},
			},
			{
				header: "Nom de la fiche",
				accessorKey: "nom",
			},
			{
				header: "Type de risque",
				accessorKey: "risk",
			},
			...(isAdminNumerisk
				? [
						{
							header: "Dupliquer",
							accessorKey: "duplicate",
							...width60,
							cell: (data) => {
								return (
									<IconButton
										onClick={() => {
											dispatch(
												createFiche({
													...data.row.original,
													id: uuidv4(),
													nom: `${data.row.original.nom} (copie)`,
												}),
											)
										}}
									>
										<ContentCopy />
									</IconButton>
								)
							},
						},
				  ]
				: []),
		],
		[],
	)
	const columnsActions = useMemo(
		() => [
			{
				header: "État",
				accessorKey: "État",
				...width60,
				cell: (data) => {
					const now = Moment()
					const stockDate = Moment(data.row.original.updated_at)
					now.subtract(12, "months")
					if (stockDate.isAfter(now)) {
						return <StyledDoneIcon />
					}
					now.subtract(24, "months")
					if (stockDate.isAfter(now)) {
						return <StyledWarningIcon />
					}
					return <StyledErrorIcon />
				},
			},
			{
				header: "Cellule",
				accessorFn: (data) => orga[data.nom]?.idName,
			},
		],
		[],
	)

	const ficheScenarios = Object.keys(fiches)
		.map((id) => fiches[id])
		.filter((fiche: IFiche) => fiche.classification === FICHE_SCENARIO)
	const ficheActions = Object.keys(fiches)
		.map((id) => fiches[id])
		.filter((fiche: IFiche) => fiche.classification === TypeFiche.FICHE_ACTION)

	const fichesTabs = [
		{
			key: "Fiches Scénario",
			path: EnumToolPath.FICHES_SCENARIOS,
			render: (
				<>
					<SuperReactTable
						data={ficheScenarios}
						columns={columnsScenarios}
						actionsButtons={
							checkPermission(EnumToolPath.FICHES_SCENARIOS).write ? (
								<PrimaryButton
									startIcon={<IconAdd />}
									onClick={() => {
										toggleModalScenario()
									}}
								>
									{isMobile ? "Créer une fiche" : "Créer une fiche scénario"}
								</PrimaryButton>
							) : null
						}
						onDeleteClick={(original: any) => dispatch(deleteFiche(original))}
						onEditClick={(original: any) => {
							history.push(`/customdata/fiche/${original.id}`)
						}}
						isDeletable={checkPermission(EnumToolPath.FICHES_SCENARIOS).write}
						isEditable
					/>
				</>
			),
		},
		{
			key: "Fiches Action",
			path: EnumToolPath.FICHES_ACTIONS,
			render: (
				<>
					<SuperReactTable
						data={ficheActions}
						columns={columnsActions}
						actionsButtons={
							checkPermission(EnumToolPath.FICHES_ACTIONS).write ? (
								<PrimaryButton
									startIcon={<IconAdd />}
									onClick={() => {
										toggleModalFicheAction()
									}}
								>
									{isMobile ? "Créer une fiche" : "Créer une fiche action"}
								</PrimaryButton>
							) : null
						}
						onDeleteClick={(original: any) => dispatch(deleteFiche(original))}
						onEditClick={(original: any) => {
							history.push(`/customdata/fiche/${original.id}`)
						}}
						isDeletable={checkPermission(EnumToolPath.FICHES_ACTIONS).write}
						isEditable
						orderConfig={{
							order,
							accessor: (item) => item.nom,
						}}
					/>
				</>
			),
		},
	]

	const readableTabs = []
	fichesTabs.forEach((tab) => {
		const tabPath = tab.path as
			| EnumToolPath.FICHES_ACTIONS
			| EnumToolPath.FICHES_SCENARIOS
		if (checkPermission(tabPath).read) {
			readableTabs.push(tab)
		}
	})

	return (
		<>
			{readableTabs.length !== 0 && (
				<>
					<TitleHeader title="Fiches scénarios/actions" />

					<PersistentCustomTabs
						tabChildrens={readableTabs}
						localStorageKey="TableFiches"
					/>
				</>
			)}
			{/* //* Condition lock this page if any fiches are readable but the tool is, soon useless */}
			{readableTabs.length === 0 && (
				<ErrorPermissions
					errorMessage={FEATURE_ERROR_PERMISSION}
					backTitle={BACK_TITLE_HOME}
					backUrl={BACK_PATH_HOME}
				/>
			)}
			<ModalFicheScenario
				isOpen={isOpenModalScenario}
				toggleModal={toggleModalScenario}
			/>
			<ModalFicheAction
				isOpen={isOpenModalFicheAction}
				toggleModal={toggleModalFicheAction}
			/>
		</>
	)
}

export default TableFiches
