import Button from "@mui/material/Button"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import Grid from "@mui/material/Grid"
import React, { useEffect, useState } from "react"
import SizeInputs from "./SizeInputs"
import styled from "styled-components"

const ImageWrapper = styled.div`
    max-width: '100%';
    max-height: 300px;
    overflow: 'auto';
    margin-top: 20px;
    overflow: auto;
`

function ResizeImageDialog({
	open,
	onClose,
	src,
	originalWidth,
	originalHeight,
	onSave,
}) {
	const [width, setWidth] = useState(0)
	const [height, setHeight] = useState(0)

	useEffect(() => {
		setWidth(originalWidth)
		setHeight(originalHeight)
	}, [originalWidth, originalHeight])

	const handleSubmit = (ev) => {
		ev.preventDefault()
		onSave(width, height)
	}

	return (
		<Dialog open={open} onClose={onClose}>
			<form onSubmit={handleSubmit}>
				<DialogContent>
					<SizeInputs
						width={width}
						height={height}
						onChangeWidth={setWidth}
						onChangeHeight={setHeight}
						originalWidth={originalWidth}
						originalHeight={originalHeight}
					/>
					<Grid container alignItems="center" component="div">
						<ImageWrapper>
							<img alt="" width={width} height={height} src={src} />
						</ImageWrapper>
					</Grid>
				</DialogContent>
				<DialogActions>
					<Button type="button" onClick={() => onClose()}>
						Annuler
					</Button>
					<Button type="submit">Redimensionner</Button>
				</DialogActions>
			</form>
		</Dialog>
	)
}

export default ResizeImageDialog
