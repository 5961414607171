import { yupResolver } from "@hookform/resolvers/yup"
import useAccessRights from "@/hooks/useAccessRights"
import { PATH_MESSAGE_ALERTE_TEMPLATE } from "@/redux-toolkit/userSettings/constants"
import Debug from "debug"
import _ from "lodash"
import React, { useEffect } from "react"
import GraphicEqOutlinedIcon from "@mui/icons-material/GraphicEqOutlined"
import SmsOutlinedIcon from "@mui/icons-material/SmsOutlined"
import { FormProvider, useForm } from "react-hook-form"
import FIconRadios from "utils/form/FIconRadios"
import FText from "utils/form/FText"
import SuperModal from "utils/modals/SuperModal"
import Yup from "utils/yup"
import styled from "styled-components"
import FSelect from "utils/form/FSelect"
import useCommune from "@/hooks/useCommune"
import { Flex } from "utils/components/style/flex"
import { FormDependencies } from "utils/form/FormDependencies"
import { useDispatch } from "react-redux"
import { createPoint, updatePoint } from "@/redux-toolkit/data/points/resources"
import { JsonSchemaPropertiesType } from "@/utils/types/IJsonSchema"
import { StaticFormType } from "utils/services/StaticFormsService"
import { v4 as uuidv4 } from "uuid"
import FSelectFiche from "utils/form/FSelectFiche"
import {
	DaybookAlertCanal,
	risksIcons,
} from "@/utils/types/daybook/IDaybookAlert"
import FTextAlert from "utils/form/alert/FTextAlert"
import { EnumToolPath } from "@/hooks/services/useAccessRightsServices"

// app:javascript:components:customdata:alertMessage:AlertMessageModal.tsx
const debug = Debug(
	"app:javascript:components:customdata:alertMessage:AlertMessageModal",
)
debug.log = console.log.bind(console)

const schema = Yup.object().shape({
	title: Yup.string().default("").required(),
	riskType: Yup.array().default([]),
	type: Yup.array().default([DaybookAlertCanal.SMS]).required(),
	message: Yup.string().default("").required(),
})

const AlertMessageModal = ({ isOpen, onClose, currentPoint }) => {
	const commune = useCommune()
	const permissions = useAccessRights(EnumToolPath.MESSAGE_ALERTE_TEMPLATE)
	const dispatch = useDispatch()

	const methods = useForm({
		resolver: yupResolver(schema),
		defaultValues: currentPoint ? currentPoint : schema.getDefault(),
		mode: "onChange",
	})

	const onSubmit = (data) => {
		if (currentPoint) {
			dispatch(
				updatePoint({
					...currentPoint,
					geojson: {
						properties: {
							...currentPoint.geojson.properties,
							...data,
						},
					},
				}),
			)
			onClose()
			return
		}
		dispatch(
			createPoint({
				id: uuidv4(),
				geojson: {
					properties: {
						...data,
					},
				},
				jsonschema_id: StaticFormType.ALERTE_MESSAGE,
			}),
		)
		onClose()
		return
	}

	useEffect(() => {
		if (currentPoint) {
			methods.reset(currentPoint.geojson.properties)
		} else {
			methods.reset(schema.getDefault())
		}
	}, [currentPoint])

	return (
		<FormProvider {...methods}>
			<SuperModal
				disableSave={!methods.formState.isDirty}
				toggleOnClickOutside={!methods.formState.isDirty}
				allowKeepOpen
				isOpen={isOpen}
				onClose={onClose}
				onClick={methods.handleSubmit(onSubmit, console.info)}
				isNew={_.isEmpty(currentPoint)}
				title={"Message d'alerte"}
				writePermissions={permissions.write}
			>
				<Flex gap="1rem" directionColumn>
					<FText
						label="Nom du message d'alerte (champ obligatoire)"
						name="title"
					/>
					{/** TODO : replace with FselectFiche as not depreciated because it gives value and label */}
					<FSelect
						isMulti
						label="Type de risque"
						name="riskType"
						options={[...commune.risks].sort()}
					/>
					<FIconRadios
						multi
						name="type"
						label="Type de campagne (champ obligatoire)"
						iconOptions={risksIcons.filter((e) =>
							commune.alertSystems.includes(e.value),
						)}
					/>
					<FormDependencies targets={["type"]}>
						{({ type }) => {
							if (type === DaybookAlertCanal.SMS) {
								return (
									<FTextAlert
										label="Message (champ obligatoire)"
										name="message"
									/>
								)
							}
							return (
								<>
									<FText
										label="Message (champ obligatoire)"
										name="message"
										multiline
										minRows={4}
									/>
								</>
							)
						}}
					</FormDependencies>
				</Flex>
			</SuperModal>
		</FormProvider>
	)
}

export default AlertMessageModal
