import React, { useMemo } from "react"
import { IFileConfigCategory } from "utils/types/IUploadedFile"
import FileDropzone from "../../component/FileDropzone"
import FormStep from "../../component/FormStep"
import useStep from "../../hooks/useStep"

export const getAllowedExtension = (category: IFileConfigCategory) => {
	//? :NOTE: Existing files mime-type listed bellow are working on Chrome.
	//?        They can take another name depending of the used browser !
	switch (category) {
		case "PROCEDURE":
		case "PEDAGOGIQUE":
		case "CONVENTION":
		case "CARTO":
			return {
				extensionRefs: {
					"application/pdf": [], //.pdf
					"application/vnd.openxmlformats-officedocument.wordprocessingml.document": [], //.docx
				},
				extensionNames: [".pdf", ".docx"],
			}
		case "EDITABLE":
			return {
				extensionRefs: {
					"application/vnd.openxmlformats-officedocument.wordprocessingml.document":
						[], //.docx
					"application/msword": [], //.doc
					"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
						[], //.xlsx
					"application/vnd.ms-excel": [], //.xls
					"text/csv": [], //.csv
					"application/zip": [], //.zip
				},
				extensionNames: [".docx", ".doc", ".xlsx", ".xls", ".csv", ".zip"],
			}

		case "IMAGE":
			return {
				extensionRefs: {
					"image/png": [], //.png
					"image/jpeg": [], //.jpg and .jpeg
					"image/gif": [], //.gif
				},
				extensionNames: [".png", ".jpg", ".jpeg", ".gif"],
			}
		default:
			return {}
	}
}

const StepUpload = ({ ...props }) => {
	const { value, setStepValue, dependencies, onBack } = useStep("file", {
		dependencies: ["category"],
	})

	const allowedExtension = useMemo(
		() => getAllowedExtension(dependencies.category),
		[dependencies.category],
	)


	return (
		<FormStep
			question="Quel fichier voulez-vous téléverser ?"
			formatedValue={value?.name}
			onBack={onBack}
			{...props}
		>
			<FileDropzone
				allowedExtension={allowedExtension}
				onFileUpload={setStepValue}
			/>
		</FormStep>
	)
}

export default StepUpload
