import SynchroService from "@/services/SynchroService"
import { useSelector } from "react-redux"
import createDeepEqualSelector from "@/utils/createDeepEqualSelector"
import IPoint from "utils/types/IPoint"

const selectPoints = createDeepEqualSelector(
	(state: any) => state.data.points,
	(points) => SynchroService.notDeleted(points),
)

const useAllPoints: () => { [key: string]: IPoint } = () => {
	return useSelector(selectPoints) ?? {}
}

export default useAllPoints
