import React, { useEffect, useMemo, useState } from "react"
import { Flex } from "@/utils/components/style/flex"
import styled from "styled-components"
import { Card } from "@/utils/components/style/card"
import { isMobile } from "react-device-detect"
import {
	Alert,
	AlertTitle,
	Avatar,
	Chip,
	Grid,
	IconButton,
} from "@mui/material"
import { H1, H5 } from "@/utils/components/style/header"
import DeleteIcon from "@mui/icons-material/Delete"
import EditIcon from "@mui/icons-material/Edit"
import IPhones from "@/utils/types/IPhones"
import TypePhone from "@/utils/components/jsonSchema/properties/propertiesType/TypePhone"
import { ExpandLink } from "@/utils/components/jsonSchema/properties/propertiesType/TypeResponsables"
import IPoint from "@/utils/types/IPoint"
import { Pie, PieChart } from "recharts"
import { v4 as uuidv4 } from "uuid"
import _ from "lodash"
import { useHistory } from "react-router-dom"
import useSuivisFromGroup from "@/hooks/useSuivisFromGroup"
import usePoints from "@/hooks/usePoints"
import HumanChip from "@/styles/atoms/Chip/specialized/HumanChip"
import moment from "moment"
import ArchiveIcon from "@mui/icons-material/Archive"
import { SUIVI_PCS } from "@/redux-toolkit/userSettings/constants"
import Button from "@/styles/atoms/Button"
import { Tooltip } from "@nextui-org/react"
import useAccessRights from "@/hooks/useAccessRights"
import { EnumToolPath } from "@/hooks/services/useAccessRightsServices"
import useSelectedEvent from "@/hooks/useSelectedEvent"
import ISuivi from "@/utils/types/ISuivi"

const SCard = styled(Card)`
    padding: 1rem;
    width: 100%;
`
const SAlert = styled(Alert)`
    width: 50%;
`
const TinyTitle = styled(H1)`
    font-size: 18px;
    padding: 0;
`
// @ts-ignore
const StyledPieChart = styled(PieChart)`
    & > svg {
        overflow: visible !important;
    }
`
const Square = styled.div`
    width: 20px;
    height: 10px;
    border-radius: 15px;
    align-self: center;
    background-color: ${({ color }) => color};
    box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 14%),
        0px 2px 2px 0px rgb(0 0 0 / 12%), 0px 1px 5px 0px rgb(0 0 0 / 8%);
`
const SChip = styled(Chip)`
    & > span {
        font-size: 0.8rem;
    }
`
const SAvatar = styled(Avatar)`
    font-weight: bold;
`

const datapieWidth = isMobile ? 150 : 200
const datapieHeight = isMobile ? 150 : 200
const datapieInnerRadius = isMobile ? 45 : 60
const datapieOuterRadius = isMobile ? 60 : 80

const FacilityCard = ({
	facilityPoint,
	onEditClick,
	onDeleteClick,
	onFinishClick,
}: {
	facilityPoint: IPoint
	onEditClick: () => void
	onDeleteClick: () => void
	onFinishClick: (groupInfos) => void
}) => {
	const history = useHistory()
	const points = usePoints()
	const [nbDisplayedResp, setNbDisplayedResp] = useState(2)
	const [suiviActions, setSuiviActions] = useState({})
	const [key, setKey] = useState("")
	const isArchive = !!facilityPoint.geojson.properties.ended_at
	const facilityMainInfos = useSuivisFromGroup(facilityPoint.id)
	const { groupInfos } = isArchive
		? facilityPoint.geojson.properties
		: facilityMainInfos
	const selectedEvent = useSelectedEvent()
	const permissions = useAccessRights(EnumToolPath.SUIVI_PCS)
	const isDisabled = !permissions.write || selectedEvent.locked

	useEffect(() => {
		const tempRes = {}
		const supports = Object.values(points).filter(
			(point) =>
				point.jsonschema_id === SUIVI_PCS &&
				point.geojson.properties.suivi_group_id === facilityPoint.id,
		)
		supports.forEach((support) => {
			support.geojson.properties.suivi.forEach((suivi: ISuivi) => {
				const currentOption = suivi?.["Suites à donner"]
				if (!currentOption) return
				if (!tempRes[currentOption]) {
					tempRes[currentOption] = 1
				} else {
					tempRes[currentOption] += 1
				}
			})
		})

		setSuiviActions(tempRes)
	}, [points])

	const pieData = useMemo(() => {
		const newData = [
			{
				name: "Avec suivi(s)",
				value: groupInfos.nbEndedSupports + groupInfos.nbPendingSupports,
				fill: "#0f206e",
			},
			{
				name: "Sans suivi",
				value: groupInfos.nbUnlessSupports,
				fill: "var(--grey100)",
			},
		]
		setKey(uuidv4())
		return newData
	}, [facilityPoint])

	return (
		<SCard>
			<Flex fullWidth directionColumn gap="1rem">
				<Flex fullWidth spaceBetween>
					<Flex gap=".5rem">
						{facilityPoint.geojson.properties.ended_at && <ArchiveIcon />}
						<H5>{facilityPoint.geojson.properties.name}</H5>
					</Flex>
					<Flex gap=".5rem">
						{!isDisabled && (
							<>
								<Tooltip content="Modifier">
									<IconButton onClick={onEditClick}>
										<EditIcon />
									</IconButton>
								</Tooltip>
								{permissions.canDeleteDatas && (
									<Tooltip content="Supprimer">
										<IconButton onClick={onDeleteClick}>
											<DeleteIcon />
										</IconButton>
									</Tooltip>
								)}
							</>
						)}
						<Button
							color="primary"
							variant="bordered"
							onClick={() =>
								history.push("/maincourante/suivi/" + facilityPoint.id)
							}
						>
							Consulter
						</Button>
					</Flex>
				</Flex>
				<Flex fullWidth directionColumn alignItemsStart gap=".5rem">
					<div>
						Début du dispositif le :{" "}
						{moment(facilityPoint.geojson.properties.start_at).format(
							"DD/MM/YYYY HH:mm",
						)}
					</div>
					{/* {groupInfos.nbEndedSuivi > 0 && !groupInfos.nbPendingSuivi && !facilityPoint.geojson.properties.ended_at && (
						<BrownOutlinedButton onClick={() => onFinishClick(groupInfos)} startIcon={<ArchiveIcon />}>Terminer et Archiver</BrownOutlinedButton>
					)}
					{facilityPoint.geojson.properties["ended_at"] && (
						<div> Dispositif terminé le : {moment(facilityPoint.geojson.properties["ended_at"]).format("DD/MM/YYYY HH:mm")}</div>
					)} */}
				</Flex>
				<Flex fullWidth spaceAround gap="1rem">
					<SAlert>
						<AlertTitle>Responsables</AlertTitle>
						<Flex directionColumn alignItemsStart gap="0.5rem">
							{!facilityPoint.geojson.properties.responsables.length && (
								<div>Aucuns responsables</div>
							)}
							{facilityPoint.geojson.properties.responsables
								.slice(0, nbDisplayedResp)
								.map((el: string | IPoint) => {
									const resp = typeof el === "string" ? points[el] : el
									const responsablePhones = (resp?.geojson?.properties
										?.Téléphones ?? []) as IPhones[]
									return (
										<Flex key={resp?.id} gap="1rem">
											<HumanChip
												human={resp}
												size={isMobile ? "small" : "medium"}
											/>
											<TypePhone
												value={responsablePhones}
												align="left"
												editable={false}
												fontSize={isMobile ? "0.5rem" : "0.9rem"}
											/>
										</Flex>
									)
								})}
							{facilityPoint.geojson.properties.responsables.length > 2 &&
								(nbDisplayedResp ? (
									<ExpandLink onClick={() => setNbDisplayedResp(undefined)}>
										+
										{facilityPoint.geojson.properties.responsables.length -
											nbDisplayedResp}{" "}
										(responsables)
									</ExpandLink>
								) : (
									<ExpandLink onClick={() => setNbDisplayedResp(2)}>
										voir moins
									</ExpandLink>
								))}
						</Flex>
					</SAlert>
					<Flex alignItemsStart gap="5rem">
						<Flex directionColumn alignItemsStart>
							<TinyTitle>Nombre d'accompagnement</TinyTitle>
							<Flex gap="0.5rem">
								<StyledPieChart width={datapieWidth} height={datapieHeight}>
									<Pie
										key={key}
										data={[
											{
												name: "Avec suivi(s)",
												value:
													groupInfos.nbEndedSupports +
													groupInfos.nbPendingSupports,
												fill: "#0f206e",
											},
											{
												name: "Sans suivi",
												value: groupInfos.nbUnlessSupports,
												fill: "var(--grey100)",
											},
										]}
										dataKey="value"
										innerRadius={datapieInnerRadius}
										outerRadius={datapieOuterRadius}
									/>
									<Tooltip />
									<text
										x={datapieWidth / 2}
										y={datapieHeight / 2}
										textAnchor="middle"
										dominantBaseline="middle"
										style={{ fontSize: "1.2rem" }}
									>
										{_.sumBy(pieData, "value")}
									</text>
								</StyledPieChart>
								<Flex directionColumn alignItemsStart gap=".5rem">
									<Flex gap=".5rem">
										<Square color="#0f206e" />
										<span>Avec suivi(s) : </span>
										<span>
											{groupInfos.nbEndedSupports +
												groupInfos.nbPendingSupports}
										</span>
									</Flex>
									<Flex gap=".5rem">
										<Square color="var(--grey100)" />
										<span>Sans suivi : </span>
										<span>{groupInfos.nbUnlessSupports}</span>
									</Flex>
								</Flex>
							</Flex>
						</Flex>
						<Flex directionColumn alignItemsStart>
							<TinyTitle>Recensement des actions</TinyTitle>
							<Flex gap=".5em" $wrap>
								{_.isEmpty(suiviActions) && <div>Aucune donnée</div>}
								{Object.entries(suiviActions).map(([key, value]) => (
									<SChip
										size="small"
										variant="outlined"
										label={key}
										avatar={<SAvatar>{value}</SAvatar>}
									/>
								))}
							</Flex>
						</Flex>
					</Flex>
				</Flex>
			</Flex>
		</SCard>
	)
}

export default FacilityCard
