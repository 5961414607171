import React, { useMemo, useState, useEffect } from "react"
import { Pie, PieChart, Tooltip } from "recharts"
import { CardContent } from "@mui/material"
import { v4 as uuidv4 } from "uuid"
import Moment from "moment"
import styled from "styled-components"
import { Spacer } from "@nextui-org/react"
import { Legend } from "@/styles/atoms/Legend/Legend"

const PieContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`

const PieChartWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
	&  svg {
		overflow: visible !important;
	}
`
const Tips = styled.div`
    margin: 0 1vw;
`
const TipsTitle = styled.div`
    font-weight: 600;
`
const TipsContent = styled.div``
const Wrapper = styled.div`
    display: flex;
    align-items: center;
	font-size: 0.8rem;
	gap: 0.5rem;
`
const LowPaddingCardContent = styled(CardContent)`
    padding: 0 !important;
`

const DataPieWidget = ({ data, isDashboard }) => {
	const [key, setKey] = useState(undefined)
	const [hasAnimated, setHasAnimated] = useState(false)
	const pieData = useMemo(() => {
		const newData = [
			{
				name: "Mis à jour récemment il y a moins de 1 an",
				value: 0,
				fill: "green",
			},
			{
				name: "Mis à jour il y a moins de 2 ans",
				value: 0,
				fill: "orange",
			},
			{
				name: `Mis à jour il y a plus de 2 ans`,
				value: 0,
				fill: "red",
			},
		]
		data.forEach((stock) => {
			const now = Moment()
			const stockDate = Moment(stock.updated_at)
			now.subtract(12, "months")
			if (stockDate.isAfter(now)) {
				newData[0].value += 1
				return
			}
			now.subtract(24, "months")
			if (stockDate.isAfter(now)) {
				newData[1].value += 1
				return
			}
			if (stockDate.isBefore(now)) {
				newData[2].value += 1
				return
			}
			return
		})

		return newData.filter((row) => row.value > 0)
	}, [data])

	useEffect(() => {
		setKey(uuidv4())
	}, [data])
	return (
		<PieContainer>
			<LowPaddingCardContent>
				<PieChartWrapper>
					<PieChart width={200} height={120}>
						<Pie
							isAnimationActive={!hasAnimated}
							onAnimationEnd={() => setHasAnimated(true)}
							key={key}
							data={pieData}
							dataKey="value"
							innerRadius={30}
							outerRadius={50}
							label
						/>
						<Tooltip />
						<text x={100} y={60} textAnchor="middle" dominantBaseline="middle">
							{data.length}
						</text>
					</PieChart>
				</PieChartWrapper>
			</LowPaddingCardContent>
			<LowPaddingCardContent>
				<Spacer y={2} />
				<Wrapper>
					<Legend color="green" />
					Il y a - de 1 an
				</Wrapper>
				<Wrapper>
					<Legend color="orange" />
					Il y a - de 2 ans
				</Wrapper>
				<Wrapper>
					<Legend color="red" />
					Il y a + de 2 ans
				</Wrapper>
			</LowPaddingCardContent>
			{!isDashboard && (
				<Tips>
					<TipsTitle>Statut :</TipsTitle>
					<TipsContent>
						Chaque icône de la colonne statut du tableau représente la date de
						dernière mise-à-jour de la donnée selon la légende ci-dessus
					</TipsContent>
				</Tips>
			)}
		</PieContainer>
	)
}

export default DataPieWidget
