import Alert from "@mui/material/Alert"
import Debug from "debug"
import React, { useEffect, useState } from "react"
import { SemipolarLoading } from "react-loadingg"
import { connect } from "react-redux"
import styled from "styled-components"
import { initialization } from "../../redux-toolkit/common/reducer"
import { SYNCHRO_INITIAL } from "../../redux-toolkit/common/loadingKeys"
import version from "../../redux-toolkit/version"
import SynchroService, { SUCCESS_PING } from "../../services/SynchroService"
import { resetRedux } from "../../redux-toolkit/rootActions"
import { useSelector } from "react-redux"
import useKeyboardJs from "@/utils/keyboard/useKeyboardJs"
import {
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
} from "@mui/material"
import { PrimaryButton } from "@/utils/components/style/button"
import { useDispatch } from "react-redux"
import useEvents from "@/hooks/useEvents"
import { useHistory } from "react-router-dom"
import SectorMigration from "../SectorMigration"
import LoadingPage from "./LoadingPage"
import { isSafari } from "react-device-detect"
import DatabaseService from "@/services/DatabaseService"
import synchronisationKeysToKeep from "@/utils/synchronisationKeysToKeep"

export const TIME_BETWEEN_SYNCHRO = 300_000 // 300_000

const SynchronisationWrapper = ({ children }) => {
	const initialized = useSelector((state: any) => state.common.initialized)
	const loading = useSelector(
		(state: any) =>
			state.common[SYNCHRO_INITIAL] && state.common[SYNCHRO_INITIAL] !== 0,
	)
	const rehydrated = useSelector((state: any) => state._persist.rehydrated)
	const [initialize, setInitialize] = useState(false)
	const [isPressed] = useKeyboardJs("ctrl + shift + alt + s")
	const [isDialogOpen, setIsDialogOpen] = useState(false)
	const isOnline = useSelector((state: any) => state.common.isOnline)
	const dispatch = useDispatch()
	const [oldNetworkStatus, setOldNetworkStatus] = useState(isOnline)

	useEffect(() => {
		if (isPressed) {
			setIsDialogOpen(true)
		}
	}, [isPressed])

	useEffect(() => {
		const interval = setInterval(() => {
			dispatch(SynchroService.synchro({ withoutDelete: false, initial: false }))
		}, TIME_BETWEEN_SYNCHRO) //300000
		return () => clearInterval(interval)
	}, [])

	useEffect(() => {
		let intervalPing = undefined

		if (!isOnline) {
			const pingConnection = async () => {
				const pingResult = await SynchroService.ping(dispatch, isOnline)
				if (pingResult === SUCCESS_PING) {
					// after 1000 ms, we check if the network is still offline
					setOldNetworkStatus(true)
					dispatch(
						SynchroService.synchro({ withoutDelete: false, initial: false }),
					)
				}
			}
			intervalPing = setInterval(pingConnection, 5_000)
		}
		return () => intervalPing && clearInterval(intervalPing)
	}, [isOnline])

	useEffect(() => {
		if (!rehydrated) {
			return
		}
		const init = async () => {
			// ! important Change version to refresh the redux persist store
			if (initialized) {
				return
			}
			const localVersion = localStorage["version"] ?? "0"
			if (localVersion !== version) {
				console.warn("CLEARING CACHE !!!!!!!!!!!!!!!!!!!!!!!!!")
				console.log("logging version: " + version)
				localStorage.clear()
				dispatch(resetRedux())
				localStorage["version"] = version
				dispatch(SynchroService.synchroOncePerSession({ withoutDelete: true }))
				dispatch(
					SynchroService.synchro({
						withoutDelete: true,
						initial: true,
					}),
				)
				dispatch(initialization())
			} else {
				dispatch(initialization())
				dispatch(SynchroService.synchroOncePerSession({ withoutDelete: true }))
				dispatch(SynchroService.synchro({ withoutDelete: true, initial: true }))
			}
		}
		init()
	}, [rehydrated, initialized])

	// if (isSafari) {
	// 	return (
	// 		<div className="flex justify-center items-center h-screen flex-col bg-gray-100 text-gray-800">
	// 			<Alert
	// 				severity="info"
	// 				className="max-w-md mx-auto my-8 p-4 rounded-lg shadow-md"
	// 			>
	// 				L'application n'est pas compatible avec le navigateur Safari. veuillez
	// 				utiliser Google Chrome ou Edge
	// 			</Alert>
	// 		</div>
	// 	)
	// }

	if (loading || !initialized) {
		return <LoadingPage />
	}

	const resynchronize = () => {
		setIsDialogOpen(false)
		console.warn(`⚡CLEARING CACHE⚡ version : ${version}`)
		const resKeys = synchronisationKeysToKeep.map((key) => {
			return localStorage.getItem(key)
		})
		debugger
		localStorage.clear()
		synchronisationKeysToKeep.forEach((key, index) => {
			localStorage.setItem(key, resKeys[index])
		})
		dispatch(resetRedux())
		localStorage["version"] = version
		dispatch(SynchroService.synchroOncePerSession({ withoutDelete: true }))
		DatabaseService.clear()
		dispatch(
			SynchroService.synchro({
				withoutDelete: true,
				initial: true,
			}),
		)
		dispatch(initialization())
	}

	return (
		<>
			{children}
			<Dialog
				open={isDialogOpen}
				onClose={() => {
					setIsDialogOpen(false)
				}}
			>
				<DialogTitle>synchro</DialogTitle>
				<DialogContent>
					Voulez vous vraiment réinitialiser la synchronisation ?
				</DialogContent>
				<DialogActions>
					<PrimaryButton onClick={resynchronize}>Valider</PrimaryButton>
				</DialogActions>
			</Dialog>
			<SectorMigration />
		</>
	)
}

export default SynchronisationWrapper
