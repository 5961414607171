import { yupResolver } from "@hookform/resolvers/yup"
import { Alert } from "@mui/material"
import useLivrets from "@/hooks/useLivrets"
import useStaticFiles from "@/hooks/useStaticFiles"
import _ from "lodash"
import React, { useEffect, useMemo } from "react"
import { FormProvider, useForm } from "react-hook-form"
import FList from "utils/form/FList"
import { LinkType } from "utils/types/IFiche"
import Yup from "utils/yup"
import useParentSubmit from "./utils/parentSubmit/useParentSubmit"
import useIsInterco from "@/hooks/useIsInterco"
import pcsDynamicFiles from "../../../../../isoMorphicData/pcsDynamicFiles/pcsDynamicFiles.json"
import picsDynamicFiles from "../../../../../isoMorphicData/pcsDynamicFiles/picsDynamicFiles.json"

const schema = Yup.object().shape({
	type: Yup.string().default("AZERTY"),
	id: Yup.string().required("Aucune fiche séléctionnée"),
})

const StaticFileLink = ({ links, requestSelect }) => {
	const isInterco = useIsInterco()
	const methods = useForm({
		resolver: yupResolver(schema),
		defaultValues: schema.cast({}),
	})
	// @ts-ignore
	const yupIdValue = methods.watch("id")

	useEffect(() => {
		if (!yupIdValue) return
		methods.setValue(
			"type",
			isInterco
				? !!picsDynamicFiles[yupIdValue]
					? LinkType.DYNAMIC_FILE
					: LinkType.STATIC_FILE
				: !!pcsDynamicFiles[yupIdValue]
				? LinkType.DYNAMIC_FILE
				: LinkType.STATIC_FILE,
		)
	}, [yupIdValue])

	useParentSubmit(methods.handleSubmit)

	const disabledItems = useMemo(() => {
		return links
			.filter((link) =>
				[LinkType.STATIC_FILE, LinkType.DYNAMIC_FILE].includes(link.type),
			)
			.map((link) => link.id)
	}, [links])

	const staticFiles = useStaticFiles({ withUploaded: true, withDynamics: true })
	const livrets = useLivrets()
	const items = useMemo(
		() =>
			_.sortBy(Object.values(staticFiles), (item) => {
				return livrets[item?.livret]?.index ?? 0
			}),
		[staticFiles],
	)

	useEffect(() => {
		if (requestSelect) {
			//@ts-ignore
			methods.setValue("id", requestSelect)
		}
	}, [requestSelect])

	return (
		<FormProvider {...methods}>
			{requestSelect && (
				<Alert severity="success">
					Le fichier a été téléversé et sélectionné, cliquez sur
					&quot;Ajouter&quot; pour créer le lien.
				</Alert>
			)}
			<FList
				name="id"
				disabledItems={disabledItems}
				items={items}
				idAccessor={(staticFile) => staticFile.id}
				titleAccessor={(staticFile) => staticFile.title}
				groupBy={(staticFile) => {
					return staticFile?.path?.[0] ?? "Remplacement fiche"
				}}
				renderGroup={(group) => livrets?.[group]}
			/>
		</FormProvider>
	)
}

export default StaticFileLink
