import { updateFiche } from "@/redux-toolkit/data/fiche/resources"
import _ from "lodash"
import { useCallback } from "react"
import { useDispatch } from "react-redux"
import IFiche, { IAction } from "utils/types/IFiche"

const useAddAction = (fiche: IFiche) => {
	const dispatch = useDispatch()

	return useCallback(
		(action: IAction, categoryId: string) => {
			const newFiche = _.cloneDeep(fiche)

			newFiche.actions[action.id] = action
			newFiche.actionsByCategory = fiche.actionsByCategory.map((category) => {
				if (category.id !== categoryId) return category
				return {
					...category,
					actionIds: [...category.actionIds, action.id],
				}
			})

			dispatch(updateFiche(newFiche))
		},
		[fiche],
	)
}

export default useAddAction
