import { View as SView } from "@react-pdf/renderer"
import React from "react"
import BoldText from "@/utils/pdf/BoldText"
import HeaderBanner from "@/utils/pdf/header/HeaderBanner"
import PPage from "@/utils/pdf/PPage"
import NormalText from "@/utils/pdf/NormalText"
import PTitle from "@/utils/pdf/PTitle"
import { SUIVI_PCS } from "@/redux-toolkit/userSettings/constants"
import DaybookSuivisTable from "./DaybookSuivisTables"
import _ from "lodash"
import ReportService from "@/pages/carto2/cartographie/service/ReportService"
import IPoint from "@/utils/types/IPoint"
import JsonSchemaService from "@/pages/carto2/cartographie/service/JsonSchemaService"
import PH2 from "@/utils/pdf/header/PH2"
const View = SView as any

const DaybookSuiviPcs = ({
	selectedEvent,
	points,
	multiPolygons,
	humans,
	jsonSchemas,
	indexInSummary,
	childIndexInSummary,
}) => {
	const filteredHelps = Object.values(points)
		.filter((point: any) => point.jsonschema_id === SUIVI_PCS)
		.filter((point: any) => point.event_id === selectedEvent.id)

	const sortedFilteredHelps = _.sortBy(
		filteredHelps,
		(point: any) => point.created_at,
	).reverse()

	const displayIdentity = (relatedPoint: IPoint) => {
		if (!relatedPoint) return ""
		let relatedJsonSchema = jsonSchemas[relatedPoint.jsonschema_id]
		let identifiants = JsonSchemaService.getIdentifiants(
			relatedPoint,
			relatedJsonSchema,
		)
		return Object.values(identifiants).join(" ")
	}

	const DEFAULT_CONTACT_DATE = "9999-12-30"

	const displaySuite = (suivisList) => {
		let nearestContactDate = DEFAULT_CONTACT_DATE
		suivisList.forEach((suivi) => {
			if (!suivi["Date prise de contact"]) {
				return
			}
			const convertedDate =
				suivi["Date prise de contact"].slice(6, 10) +
				"-" +
				suivi["Date prise de contact"].slice(3, 5) +
				"-" +
				suivi["Date prise de contact"].slice(0, 2) +
				" " +
				suivi["Date prise de contact"].slice(11, 16)
			if (
				_.now() <= Date.parse(convertedDate) &&
				Date.parse(nearestContactDate) >= Date.parse(convertedDate)
			) {
				nearestContactDate = convertedDate
			}
		})

		if (nearestContactDate === DEFAULT_CONTACT_DATE) {
			return "Aucune suite n'est à venir pour ce suivi."
		}

		const convertedDate2 =
			nearestContactDate.slice(8, 10) +
			"/" +
			nearestContactDate.slice(5, 7) +
			"/" +
			nearestContactDate.slice(0, 4) +
			" " +
			nearestContactDate.slice(11, 16)

		const currentSuiteName = suivisList.filter(
			(suivi) => suivi["Date prise de contact"] == convertedDate2,
		)?.[0]?.["Suites à donner"]

		return currentSuiteName
	}

	return (
		<PPage>
			<HeaderBanner>
				<PH2 marginBottom="0px">{indexInSummary}. Main courante</PH2>
			</HeaderBanner>
			<PTitle color="#2e74b5" marginBottom={10}>
				{indexInSummary}.{childIndexInSummary}. Aide aux populations
			</PTitle>
			<View
				style={{
					borderBottom: "1px solid #cecece",
				}}
			/>
			{sortedFilteredHelps.map((help: any) => {
				const relatedPoint =
					points[help.geojson.properties.relatedObject.objectId]
				const categoryName =
					jsonSchemas[help.geojson.properties.relatedObject.categoryId]?.title
				const suivisList = help.geojson.properties.suivi
				return (
					<View
						key={help.id}
						style={{
							display: "flex",
							flexDirection: "column",
							padding: "8px 0",
							borderBottom: "1px solid #cecece",
						}}
						wrap={false}
					>
						<View
							style={{
								width: "100%",
								display: "flex",
								justifyContent: "space-between",
								alignItems: "center",
								flexDirection: "row",
								backgroundColor: "#ecf8ff",
								padding: "5px",
								marginBottom: "5px",
							}}
						>
							<BoldText style={{ width: "40%", fontSize: "11px" }}>
								Identitée : {displayIdentity(relatedPoint)}
							</BoldText>
							<BoldText style={{ width: "55%", fontSize: "11px" }}>
								Prochain suivi : {displaySuite(suivisList)}
							</BoldText>
						</View>
						<View
							style={{
								display: "flex",
								flexDirection: "row",
								alignItems: "center",
								margin: "2px 0",
							}}
						>
							<BoldText>Référent(s) : </BoldText>
							{ReportService.displayResps(
								humans,
								help.geojson.properties.responsableId,
								undefined,
								true,
							)}
						</View>
						<NormalText marginBottom={5}>
							<BoldText>Catégorie PCS : </BoldText>
							{categoryName}
						</NormalText>
						{suivisList.length > 0 && (
							<>
								<BoldText>Suivi(s) recensé(s) : {suivisList.length}</BoldText>
								<DaybookSuivisTable suivisList={suivisList} humans={humans} />
							</>
						)}
						{suivisList.length === 0 && (
							<View>
								<NormalText>Aucun suivi.</NormalText>
							</View>
						)}
					</View>
				)
			})}
		</PPage>
	)
}

export default DaybookSuiviPcs
