import React from "react"
import _ from "lodash"
import Axios from "axios"
import PdfApi from "@/redux-toolkit/data/pdf/PdfApi"
import { pdfjs } from "react-pdf"
import IPoint from "utils/types/IPoint"
import {
	INTERVENTION,
	SUIVI_PCS,
	TEMPORARY_CARE,
} from "@/redux-toolkit/userSettings/constants"
import SmallText from "utils/pdf/SmallText"
import { View as RView } from "@react-pdf/renderer"
import BoldText from "utils/pdf/BoldText"
import NormalText from "utils/pdf/NormalText"
import { Html } from "react-pdf-html"
import { ContentState, convertToRaw, EditorState } from "draft-js"
import htmlToDraft from "html-to-draftjs"
import QRCode from "qrcode"
import PImage from "utils/pdf/image/PImage"
import { IEventLinkedFile } from "utils/form/upload/FUpload"
import { stylesheet } from "@/pages/telechargement/reports/pdf/components/DaybookEventsList"
import chunkArray from "utils/chunkArray"
import { wait } from "utils/wait"
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`
const View = RView as any
// === To determinate which blobs will be treated ===
export const FROM_EVENTS = "fromEvents"
export const FROM_ARRETES = "fromArretes"

// === Report's summary title constants ===
export const DASHBOARD = "TABLEAU DE BORD"
export const CRISIS_ORGA = "ORGANIGRAMME DE CRISE"
export const SCENARIOS = "FICHES SCÉNARIOS"
export const DAYBOOK = "MAIN COURANTE"
/** */ export const INCIDENTS_ACTIONS = "ÉVÈNEMENTS ET INTERVENTIONS"
/** */ export const ARRETES = "ARRÊTÉS MUNICIPAUX"
/** */ export const CARE = "CENTRE D'ACCUEIL"
/** */ export const PEOPLES_HELP = "AIDE AUX POPULATIONS"
export const CARTOGRAPHY = "CARTOGRAPHIE"

// === Temporary event's linked files filter for display ===
const ALLOWED_FILES_EXTENSIONS = [".png", ".pdf", ".jpg", ".jpeg"]

interface IPointWithSrcBloc extends IPoint {
	srcBlob: { blob: string; type: string }[]
}

// ----------------------------------------------------------------------------------------------------
//? Internal :              - Function used by an external function
//* External :              - Exported function called by report's components
//! Internal & External :   - Exported function used also by others exported functions
//! (Be careful when you'll edit one of them)
// ----------------------------------------------------------------------------------------------------

//? Internal : Apply correct dimension size to provided image blob
const processImage = (imageUrl) => {
	// calculate height and width
	return new Promise((resolve) => {
		const img = new Image()
		img.onload = () => {
			// divide by 2 because of retina display
			const width = img.width / 2
			const height = img.height / 2
			resolve({ blob: imageUrl, width, height, type: "image" })
		}
		img.src = imageUrl
	})
}

//? Internal : Turning event's linked file in blobs, into object for images and object's array for pdf (for each page)
const elementToBlobProcessFromEvents = async ({ elements }) => {
	const tempObjectOfElementsBlobsObject = {}

	if (!_.isArray(elements)) elements = [elements]
	const firstPromises = elements.map(async (element: any) => {
		let blobUrl = ""
		//* for events, blobs are getted from uploaded_files
		const result = await Axios.get(`/api/uploaded_files/url/${element.id}`)
		blobUrl = result.data

		if ([".png", ".jpg", ".jpeg"].includes(element.extension)) {
			//* blob is returned as image into an object
			return { blob: blobUrl, type: "image" }
		}

		//* blobUrl is now a blob's pdf
		const pdfJavaScript = pdfjs
		const loadedPdf = await pdfJavaScript.getDocument(blobUrl).promise
		const secondPromises = _.range(1, loadedPdf.numPages + 1).map(
			async (index) => {
				//* creating canvas patern to draw pdf
				const { pdfImageBlobUrl, pdfWidth, pdfHeight } =
					await creatingImagePaternToDrawPdf({
						element,
						index,
						loadedPdf,
						tempObjectOfElementsBlobsObject,
					})
				tempObjectOfElementsBlobsObject[element.id][index] = {
					blob: pdfImageBlobUrl,
					type: "pdf",
					width: pdfWidth,
					height: pdfHeight,
				}
				return
			},
		)
		return await Promise.all(secondPromises)
	})

	await Promise.all(firstPromises)

	if ([".png", ".jpg", ".jpeg"].includes(elements[0].extension)) {
		//* get promise value before return
		const imgObj = await firstPromises[0]
		const imgObjWithDimensions = await processImage(imgObj.blob)
		return imgObjWithDimensions
	}

	// forced to be a pdf because of filter with allowed extensions.
	if (!ALLOWED_FILES_EXTENSIONS.includes(elements[0].extension)) {
		throw new Error(
			"elementToBlobProcessFromEvents is trying to process an extension not in ALLOWED_FILES_EXTENSIONS, ReportService",
		)
	}
	if ([".pdf"].includes(elements[0].extension)) {
		const arrayOfObj = Object.values(
			tempObjectOfElementsBlobsObject[elements[0].id],
		)
		return arrayOfObj
	}
}

//? Internal : Draw pdf as canvas, and return it as blob with draw's width and height
const creatingImagePaternToDrawPdf = async ({
	loadedPdf,
	index,
	tempObjectOfElementsBlobsObject,
	element,
}) => {
	const loadedPage = await loadedPdf.getPage(index)
	const scale = 2
	const viewport = loadedPage.getViewport({
		scale: scale,
	})

	const canvas = document.createElement("canvas")
	const context = canvas.getContext("2d")

	canvas.width = Math.floor(viewport.width)
	canvas.height = Math.floor(viewport.height)
	canvas.style.width = Math.floor(viewport.width) + "px"
	canvas.style.height = Math.floor(viewport.height) + "px"

	if (window.devicePixelRatio > 1) {
		canvas.width *= window.devicePixelRatio
		canvas.height *= window.devicePixelRatio
		canvas.style.width = Math.floor(viewport.width) + "px"
		canvas.style.height = Math.floor(viewport.height) + "px"
		context.scale(window.devicePixelRatio, window.devicePixelRatio)
	}
	const renderContext = {
		canvasContext: context,
		viewport: viewport,
	}

	//* draw pdf to get it as image
	await loadedPage.render(renderContext).promise
	const blob = (await new Promise((resolve) =>
		//* get image's pdf blob
		canvas.toBlob(resolve, "image/png", 1),
	)) as any

	const pdfImageBlobUrl = URL.createObjectURL(blob)
	const pdfWidth = canvas.width
	const pdfHeight = canvas.height

	if (_.isEmpty(tempObjectOfElementsBlobsObject[element.id])) {
		tempObjectOfElementsBlobsObject[element.id] = {}
	}

	return { pdfImageBlobUrl, pdfWidth, pdfHeight }
}

//? Internal : Constructing temporary editorState, then, check and return if there is any entity in stringValue
const getEntities = (stringValue) => {
	//* Constructing temporary editorState
	const contentBlock = htmlToDraft(stringValue)
	const contentState = ContentState.createFromBlockArray(
		contentBlock.contentBlocks,
	)
	const editorState = EditorState.createWithContent(contentState)

	//* Getting entities from editorState's current content
	const res = convertToRaw(editorState.getCurrentContent())

	//* Return entities into an array
	return Object.values(res.entityMap)
}

//? Internal : Return canvas qr-code as blob url
const getQrCodeAsBlobUrl = async (entityUrl) => {
	//* Create empty canvas and hydrate it with entityUrl
	const canvas = document.createElement("canvas")
	QRCode.toCanvas(
		canvas, // default canvas element
		entityUrl, // datas to apply
		(error) => error && console.error(error), // error callback
	)

	//* Transform canvas to blob
	const blob = (await new Promise((resolve) =>
		canvas.toBlob(resolve, "image/png", 1),
	)) as any

	//* Return it as blob url
	return URL.createObjectURL(blob)
}

//? Internal : Return updated wisiwyg's content with line breaks
const applyLineBreaks = (message: string) =>
	message.replaceAll("</p>", "</p><br/>").replaceAll("</li>", "</li><br/>")

//? Internal : Replace broken wysiwyg unordered list's for display
const replaceWysiwygLi = (message: string) =>
	message.replaceAll("<li>", "<p>• ").replaceAll("</li>", "</p>") //! Have same impact on ordered list ! "<li>" will be replaced by "<p>•"

//* External : Return all events with blobs of all their authorized linked files format
const processFromPointsBlobs = async ({
	pointsWithBlobs,
	setIsProcessingLinkedFiles,
	setValidEventsArray,
}: {
	pointsWithBlobs: IPoint[]
	setIsProcessingLinkedFiles: (boolean) => void
	setValidEventsArray: (IPointWithSrcBloc) => void
}) => {
	const finalArrayOfEvent = []

	try {
		// Set processing state to indicate progress
		setIsProcessingLinkedFiles(true)

		// Process elements in batches of 10
		console.log("start")
		for (let i = 0; i < pointsWithBlobs.length; i += 10) {
			const currentBatch = pointsWithBlobs.slice(i, i + 10)
			const processedBatch = await Promise.all(
				currentBatch.map(async (element) => {
					const elementLinkedFiles = element.geojson.properties[
						"Pièces jointes"
					] as IEventLinkedFile[]

					// Handle empty linked files directly instead of filtering
					if (!elementLinkedFiles || !elementLinkedFiles.length) {
						return element
					}

					const filteredElementLinkedFiles = elementLinkedFiles.filter(
						(element) => ALLOWED_FILES_EXTENSIONS.includes(element.extension),
					)

					// Construct new Events array with linked files blobs
					const arrayOfFilesBlobs = []

					for (const fileObj of filteredElementLinkedFiles) {
						try {
							const before = Date.now()
							console.log(`Element ${element.id} contenant ${fileObj.filename}`)
							const res = await elementToBlobProcessFromEvents({
								elements: fileObj,
							})
							arrayOfFilesBlobs.push(res)
							const after = Date.now()
							const displayBeautifullTime = (time) => {
								const minutes = Math.floor(time / 60000)
								const seconds = ((time % 60000) / 1000).toFixed(0)
								return `${minutes} minutes and ${seconds} seconds`
							}
							console.log(
								`Temps de traitement : ${displayBeautifullTime(
									after - before,
								)}`,
							)
						} catch (error) {
							console.error(
								`Error processing file "${fileObj.filename}":`,
								error,
							)
							// Optional: handle errors differently (e.g., retry, skip, log)
						}
					}

					return { ...element, srcBlob: arrayOfFilesBlobs }
				}),
			)

			finalArrayOfEvent.push(...processedBatch)

			// Add delay between batches
			await wait(800)
		}
		console.log("finished")
		// Update state with final processed data
		setValidEventsArray(finalArrayOfEvent)
	} catch (error) {
		console.error("An error occurred:", error)
	} finally {
		// Ensure processing state is always updated
		setIsProcessingLinkedFiles(false)
	}
}
//* External : Return all arretes into object with each of their pages as blobs, into another object
const elementToBlobProcessFromArretes = async ({
	elements,
	setIsProcessingArretesBlob,
	setObjectOfArretesBlobsObject,
}) => {
	const tempObjectOfElementsBlobsObject = {}
	//* arretes's pdf will be displayed to the chain, ordonnacement manager object is created
	elements.map(
		(element: any) => (tempObjectOfElementsBlobsObject[element.id] = {}),
	)
	if (!_.isArray(elements)) elements = [elements]
	const firstPromises = elements.map(async (element: any) => {
		let blobUrl = ""

		//* for arretes, blobs are getted by generated a template
		const uuid = await PdfApi.generate(element.id)
		const uri = await PdfApi.waitArreteGeneration(uuid)
		const res = await Axios.get(uri, { responseType: "blob" })
		const tempBlobUrl = URL.createObjectURL(res.data)
		blobUrl = tempBlobUrl

		//* blobUrl is now a blob's pdf
		const pdfJavaScript = pdfjs
		const loadedPdf = await pdfJavaScript.getDocument(blobUrl).promise
		const secondPromises = _.range(1, loadedPdf.numPages + 1).map(
			async (index) => {
				//* prepare arrete's blob place
				tempObjectOfElementsBlobsObject[element.id][index] = null

				// to facto
				const { pdfImageBlobUrl } = await creatingImagePaternToDrawPdf({
					element,
					index,
					loadedPdf,
					tempObjectOfElementsBlobsObject,
				})
				//* creating canvas patern to draw pdf

				tempObjectOfElementsBlobsObject[element.id][index] = pdfImageBlobUrl
				return
			},
		)
		return await Promise.all(secondPromises)
	})

	await Promise.all(firstPromises)

	if (_.isEmpty(tempObjectOfElementsBlobsObject)) {
		tempObjectOfElementsBlobsObject["noBlob"] = true
	}
	setIsProcessingArretesBlob(false)
	setObjectOfArretesBlobsObject(tempObjectOfElementsBlobsObject)
}

//* External : Creating Object who'll contain by element key, Objects of arrays with all wisiwyg's entities
const getWisiwigEntities = async ({
	elements,
	setIsProcessingWisiwygExtraction,
	setObjectOfWisiwygBlobsArray,
}: {
	elements: IPoint[]
	setIsProcessingWisiwygExtraction: React.Dispatch<
		React.SetStateAction<boolean>
	>
	setObjectOfWisiwygBlobsArray: (x: object) => void
}) => {
	const process = elements.map(async (element) => {
		const tmpFinalObject = {} as {
			key: string
			values: {
				detailsQrCodeBlobs?: string[]
				reponsesQrCodeBlobs?: { [key: string]: string[] }
			}
		}

		//* get element values into constant to made code more readable
		const elementDetails = element.geojson.properties["Détails"]
		const elementReponses = element.geojson.properties["Réponses"]

		//* If element have details
		if (elementDetails) {
			//* Create new object with detailsLinks key for details wisiwyg's links using element's id
			tmpFinalObject.key = element.id
			tmpFinalObject.values = { detailsQrCodeBlobs: [] }

			//* Get element's details wisiwyg's entities
			const entities = getEntities(elementDetails)

			const promises = entities.map(async (entity) => {
				//* Transform entity's url to qr-code as blob url
				const blobUrl = await getQrCodeAsBlobUrl(entity.data.url)
				await Promise.all(blobUrl)

				return blobUrl
			})
			const resPromises = await Promise.all(promises)
			tmpFinalObject.values.detailsQrCodeBlobs = resPromises
		}

		//* If element have reponses
		if (!_.isEmpty(elementReponses)) {
			//* Checking if element's id already exist in tmpFinalObject, else create it
			if (!tmpFinalObject.key) {
				//* Create new object for reponses wisiwyg's links using element's id
				tmpFinalObject.key = element.id
				tmpFinalObject.values = { reponsesQrCodeBlobs: {} }
			} else {
				//* Add reponsesLinks key for wisiwyg's links to the existing element's id object
				tmpFinalObject.values.reponsesQrCodeBlobs = {}
			}

			const promisesResponses = elementReponses.map(async (reponse, index) => {
				//* Get element's reponses wisiwyg's entities
				const entities = getEntities(reponse.objet)

				const promiseBlob = entities.map(async (entity) => {
					//* Transform entity's url to qr-code as blob url
					const blobUrl = await getQrCodeAsBlobUrl(entity.data.url)
					return blobUrl
				})
				const resPromises = await Promise.all(promiseBlob)

				return { key: index, value: resPromises }
			})
			const resPromisesResponses = await Promise.all(promisesResponses)
			resPromisesResponses.map((res) => {
				if (res.value) {
					tmpFinalObject.values.reponsesQrCodeBlobs[res.key] = res.value
				}
			})
		}
		return tmpFinalObject
	})

	const res = await Promise.all(process)

	//* Filter empty objects and event's ojects without wisiwyg's links
	const filteredRes = res.filter((obj) => {
		if (_.isEmpty(obj)) {
			return false
		} else if (
			_.isEmpty(obj.values?.detailsQrCodeBlobs) &&
			_.isEmpty(obj.values?.reponsesQrCodeBlobs)
		) {
			return false
		} else {
			return true
		}
	})

	//* Transform filteredRes's array to object for better use later
	const finalObject = _.chain(filteredRes)
		.keyBy("key")
		.mapValues("values")
		.value()

	setIsProcessingWisiwygExtraction(false)
	setObjectOfWisiwygBlobsArray(finalObject)
}

//* External : Checking "Main Courante" datas categories to defined which sub-categories's title of it will be displayed
const checkExistingDatas = (
	points,
	selectedEvent,
	eventsPoints,
	objectOfArretesBlobsObject,
) => {
	const getPointsFrom = (jsonschemaId) =>
		Object.values(points)
			.filter((point: any) => point.jsonschema_id === jsonschemaId)
			.filter(
				(filteredPoint: any) => filteredPoint.event_id === selectedEvent.id,
			)

	const datasToCheck = [
		{
			title: INCIDENTS_ACTIONS,
			datas: [...Object.values(eventsPoints), ...getPointsFrom(INTERVENTION)],
		},
		{
			title: ARRETES,
			// If no arretes was provided, remove "noBlob" key's value from array for checking
			datas: Object.values(objectOfArretesBlobsObject).filter(
				(element) => element !== true,
			),
		},
		{
			title: CARE,
			datas: getPointsFrom(TEMPORARY_CARE),
		},
		{
			title: PEOPLES_HELP,
			datas: getPointsFrom(SUIVI_PCS),
		},
	]

	// Hydrate array with strings for each categories with at least 1 data
	const datasTitleArray = []
	datasToCheck.map((object) => {
		if (!_.isEmpty(object.datas)) {
			datasTitleArray.push(object.title)
		}
	})

	return datasTitleArray
}

//! Internal & External : if datetime was provided, return it as readable format
const displayDatetime = (datetime) => {
	if (!datetime) {
		return "Aucune date."
	}
	return (
		datetime.slice(8, 10) +
		"/" +
		datetime.slice(5, 7) +
		"/" +
		datetime.slice(0, 4) +
		" à " +
		datetime.slice(11, 13) +
		"h" +
		datetime.slice(14, 16)
	)
}

//! Internal : Get orga cell color by checking its category
const getCategoryColor = (category) =>
	category === "Décisionnelles"
		? "#c34720"
		: category === "Opérationnelles"
			? "#1879ce"
			: category === "Support"
				? "#008000"
				: "gray"

//* External : Display orga cells by filtering with the provided id's array
const displayCells = (orgas, orgaCellsId) => {
	return orgaCellsId.map((cellId, index) => {
		if (!orgas[cellId]) {
			return null
		}
		return (
			<SmallText
				key={index}
				style={{
					marginRight: "3px",
					padding: "3px 7px",
					border: "1px solid #bfbfbf",
					borderRadius: "50%",
					backgroundColor: getCategoryColor(orgas[cellId]?.category) + "66", // absorption for better visibility
				}}
			>
				{orgas[cellId]?.idName}
			</SmallText>
		)
	})
}

//* External : Display each point's answer who're provided into the array
const displayAnswers = (answers, blobs) => {
	const containerStyle = {
		padding: "3px 7px",
		margin: "4px",
		border: "1px solid #bfbfbf",
		borderRadius: "8px",
		backgroundColor: "#f5f5f5",
	}
	return answers.map((obj, index) => {
		return (
			<View key={index} style={containerStyle}>
				<BoldText>
					{displayDatetime(obj.date)} - Point de situation n°
					{index + 1} :
				</BoldText>
				<View
					style={{
						margin: "3px 0",
						borderBottom: "0.5px solid #bfbfbf",
					}}
				/>
				<NormalText
					style={{
						marginRight: "8px",
					}}
				>
					-{" "}
					{/* //* if "obj" is string, so we're trying to display the older version of event's answer */}
					<Html
						stylesheet={stylesheet}
						style={{
							fontSize: 11,
						}}
					>
						{_.isString(obj)
							? obj
							: replaceWysiwygLi(applyLineBreaks(obj.objet))}
					</Html>
				</NormalText>
				{!_.isEmpty(blobs?.[index]) && (
					<View
						style={{
							display: "flex",
							flexDirection: "row",
						}}
					>
						{blobs[index].map((blobUrl, blobIndex) => (
							<View
								key={blobIndex}
								style={{
									display: "flex",
									alignItems: "center",
								}}
							>
								<PImage
									src={blobUrl}
									alt="[QR-Code]"
									width="76px"
									height="76px"
								/>
								<BoldText>Lien {_.indexOf(blobs[index], blobUrl) + 1}</BoldText>
							</View>
						))}
					</View>
				)}
			</View>
		)
	})
}

//* External : Display responsable or substitution first name and last name, as simple text or into a chip style
const displayResps = (
	humans,
	orgaRespIds = [],
	substitutions = undefined,
	asChip = false,
) => {
	const chipStyle = {
		padding: "3px 7px",
		margin: "2px",
		border: "1px solid #bfbfbf",
		borderRadius: "50%",
		backgroundColor: "#f5f5f5",
	}

	return orgaRespIds.map((respId, index) => {
		// Looking if a substituer is replacing a absent reponsable into orgas
		const substituteId = substitutions?.[respId]
		if (substituteId == "nobody") {
			// Responsable is absent but not substitued
			return (
				<SmallText key={index} style={asChip ? chipStyle : {}}>
					Aucun
				</SmallText>
			)
		}
		if (substituteId) {
			// Responsable is absent and substitued by someone
			return (
				<SmallText
					key={index}
					style={
						asChip
							? {
									marginRight: "3px",
									...chipStyle,
								}
							: {}
					}
				>
					{humans[substituteId]?.geojson?.properties?.["Nom"]}{" "}
					{humans[substituteId]?.geojson?.properties?.["Prénom"]}
				</SmallText>
			)
		}

		// Responsable found (default return)
		return (
			<SmallText
				key={index}
				style={
					asChip
						? {
								marginRight: "3px",
								...chipStyle,
							}
						: {}
				}
			>
				{humans[respId]?.geojson?.properties?.["Nom"]}{" "}
				{humans[respId]?.geojson?.properties?.["Prénom"]}
			</SmallText>
		)
	})
}

//* External : Display detail of a point if exist, else return a default text
const displayDetails = (message) => {
	let finalMessage = message
	if (finalMessage.length > 0) {
		// WISIWIGs will open simple <p> tags on each line breaks from editor
		// so we need to add <br/> tags to do it after each of their closing tags
		finalMessage = replaceWysiwygLi(applyLineBreaks(finalMessage))
	} else {
		finalMessage = "Aucun détail renseigné."
	}

	return finalMessage
}

//* External : Return commune's image or Numérisk image if not exist
const getCommuneLogo = async ({ commune, setCommuneLogoSrc }) => {
	if (commune.communePicture !== "[]" && commune.communePicture !== null) {
		const res = await Axios.get(
			`/api/uploaded_files/url/${commune.communePicture}`,
		)

		setCommuneLogoSrc(res.data)
	} else {
		setCommuneLogoSrc("/img/numerisk.PNG")
	}
}

export default {
	processFromPointsBlobs,
	elementToBlobProcessFromArretes,
	getWisiwigEntities,
	checkExistingDatas,
	displayCells,
	displayDatetime,
	getCategoryColor,
	displayAnswers,
	displayResps,
	displayDetails,
	getCommuneLogo,
}
