import { useSelector } from "react-redux"
import createDeepEqualSelector from "@/utils/createDeepEqualSelector"

const selectPdfGenerators = createDeepEqualSelector(
	(state: any) => state.data.pdfGenerators,
	(pdfg: any) => pdfg,
)

const usePdfGenerators = () => {
	return useSelector(selectPdfGenerators)
}

export default usePdfGenerators
