import React, { useCallback, useEffect, useState } from "react"
import AddTaskIcon from "@mui/icons-material/AddTask"

import styled from "styled-components"
import { Outliner } from "./Action"
import { defaultScrollOptions } from "utils/defaultScrollOptions"

const Wrapper = styled.div`
    border-left: solid #dddddd 12px;
    cursor: pointer;
    color: #888888;
    display: flex;
    align-items: center;
`

const Text = styled.div`
    padding: 10px;
`

const IconWrapper = styled.div`
    padding-left: 8px;
    width: 2.4em;
    display: flex;
    align-items: center;
    justify-content: center;
`

function NewAction({ active, requestCreateActionForCategory }) {
	const [node, setNode] = useState<HTMLDivElement>(undefined)

	const callbackRef = useCallback((node: HTMLDivElement) => {
		setNode(node)
		if (active) setTimeout(() => node?.scrollIntoView(defaultScrollOptions), 0)
	}, [])

	useEffect(() => {
		if (active) setTimeout(() => node?.scrollIntoView(defaultScrollOptions), 0)
	}, [active])

	return (
		<Outliner $selected={active}>
			<Wrapper
				ref={callbackRef}
				onClick={() => !active && requestCreateActionForCategory()}
			>
				<IconWrapper>
					<AddTaskIcon />
				</IconWrapper>
				{active ? (
					<Text>Enregistrez pour créer cette nouvelle action</Text>
				) : (
					<Text>Cliquez pour créer une nouvelle action</Text>
				)}
			</Wrapper>
		</Outliner>
	)
}

export default NewAction
