import { yupResolver } from "@hookform/resolvers/yup"
import useCooperationConfig from "@/pages/cooperation/customdata/hooks/useCooperationConfig"
import { SearchMode } from "@/redux-toolkit/edit/cooperationFiltersRessources"
import React from "react"
import { FormProvider, useForm } from "react-hook-form"
import FAutocomplete from "utils/form/FAutocomplete"
import FText from "utils/form/FText"
import { LinkType } from "utils/types/IFiche"
import Yup from "utils/yup"
import useParentSubmit from "./utils/parentSubmit/useParentSubmit"

const options = {
	"/customdata/my_forms": "Données PCS",
	"/customdata/organigramme": "Organigramme",
	"/customdata/arretes": "Arrêtés municipaux",
	"/download": "Document",
	"/customdata/cartographie": "Cartographie",
	[`/cooperation/${SearchMode.ORGANIGRAMMES}`]: "Coopération - Organigrammes",
	[`/cooperation/${SearchMode.DONNEES_PARTAGEES}`]: "Coopération - Ressources",
	// TODO : ajouter les chemins de gestion de crise
}

const schema = Yup.object()
	.shape({
		type: Yup.string().default(LinkType.INTERNAL),
		url: Yup.string().required(),
		text: Yup.string(),
	})
	.transform((value) => {
		if (!value.text)
			return {
				...value,
				text: options[value.url],
			}
		return value
	})

const InternalLink = () => {
	const methods = useForm({
		resolver: yupResolver(schema),
		defaultValues: schema.cast({}),
	})

	useParentSubmit(methods.handleSubmit)

	const { modesEnabled } = useCooperationConfig()

	const optionsKeys = Object.keys(options).filter((k) => {
		if (k === `/cooperation/${SearchMode.ORGANIGRAMMES}`)
			return modesEnabled[SearchMode.ORGANIGRAMMES]
		if (k === `/cooperation/${SearchMode.DONNEES_PARTAGEES}`)
			return modesEnabled[SearchMode.DONNEES_PARTAGEES]
		return true
	})

	return (
		<FormProvider {...methods}>
			<FAutocomplete
				label="Page de l'application"
				name="url"
				options={optionsKeys}
				getOptionLabel={(option) => options[option] ?? ""}
			/>
			<FText label="Intitulé du lien (optionnel)" name="text" />
		</FormProvider>
	)
}

export default InternalLink
