import { yupResolver } from "@hookform/resolvers/yup"
import AddIcon from "@mui/icons-material/Add"
import PersonIcon from "@mui/icons-material/Person"
import { Paper } from "@mui/material"
import useCareLeftPersonsByGroup from "@/hooks/care/useCareLeftPersonsByGroup"
import { EnumToolPath } from "@/hooks/services/useAccessRightsServices"
import useAccessRights from "@/hooks/useAccessRights"
import useSelectedEvent from "@/hooks/useSelectedEvent"
import {
	createPointWithUpdateEvent,
	updatePointWithUpdateEvent,
} from "@/redux-toolkit/data/points/resources"
import {
	CARE_FAMILY,
	PATH_MAIN_COURANTE_CARE,
} from "@/redux-toolkit/userSettings/constants"
import Debug from "debug"
import _ from "lodash"
import Moment from "moment"
import React, { useEffect, useRef, useState } from "react"
import { isMobile } from "react-device-detect"
import { FormProvider, useForm } from "react-hook-form"
import { useDispatch } from "react-redux"
import { useParams } from "react-router"
import styled from "styled-components"
import { PrimaryTextButton } from "@/utils/components/style/button"
import { StyledChip } from "@/utils/components/style/chip"
import { Flex } from "@/utils/components/style/flex"
import FGeoloc from "@/utils/form/FGeoloc"
import { FormDependencies } from "@/utils/form/FormDependencies"
import FSelect from "@/utils/form/FSelect"
import FText from "@/utils/form/FText"
import { specificityOptions } from "@/utils/form/specific/care/FamilyMemberCard"
import FFamily from "@/utils/form/specific/care/FFamily"
import FFamilyRenderMembers from "@/utils/form/specific/care/FFamilyRenderMembers"
import useKeyboardJs from "@/utils/keyboard/useKeyboardJs"
import SuperModal from "@/utils/modals/SuperModal"
import { IFamilyMemberType } from "@/utils/types/IFamilyMember"
import { getYupGeoloc } from "@/utils/types/IGeoloc"
import Yup from "@/utils/yup"

// app:javascript:components:maincourante:care:modals:ModalFamily.tsx
const debug = Debug(
	"app:javascript:components:maincourante:care:modals:ModalFamily",
)
debug.log = console.log.bind(console)

const StyledPaper = styled(Paper)`
    width: 100%;
`
const SFlex = styled(Flex)``
const StyledFlex = styled(Flex)`
    padding: 0.5rem;
`
const StyledReferent = styled.div`
    border: 1px solid var(--green500);
    color: var(--green500);
    width: 100%;
    padding: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 4px;
    gap: 1rem;
`
const ContainerFlex = styled(Flex)`
    flex-direction: ${isMobile ? "column" : "row"};
`

const ModalEnterFamily = ({ isOpen, onClose, currentFamily }) => {
	const schema = Yup.object().shape({
		geojson: Yup.object().shape({
			properties: Yup.object().shape({
				Identité: Yup.string().required().default(""),
				Spécificités: Yup.array(Yup.string()).default([]),
				Téléphone: Yup.string().default(""),
				besoin: Yup.string().default(""),
				Adresse: getYupGeoloc({ isRequired: false }),
				family: Yup.array()
					.of(
						Yup.object().shape({
							type: Yup.string().required(),
							presence: Yup.string(),
							Identité: Yup.string(),
							Spécificités: Yup.array().default([]),
							Commentaire: Yup.string(),
						}),
					)
					.default([]),
				"Heure arrivée": Yup.string().default(Moment().format()),
			}),
		}),
	})
	const methods = useForm({
		resolver: yupResolver(schema),
		defaultValues: schema.getDefault(),
	})
	const currentView = useRef(null)
	const [isPressed] = useKeyboardJs("ctrl + space")
	useEffect(() => {
		if (isPressed && !isOpen) {
			onClose()
		}
	}, [isPressed])
	const selectedEvent = useSelectedEvent()
	const permissions = useAccessRights(EnumToolPath.CARE)
	const isLocked = selectedEvent?.locked
	// @ts-ignore
	const { careId } = useParams()

	const dispatch = useDispatch()

	const [showComment, setShowComment] = useState(false)

	methods.watch()

	useEffect(() => {
		if (currentFamily) {
			methods.reset(currentFamily)
		} else {
			methods.reset({
				...schema.getDefault(),
				geojson: {
					// @ts-ignore
					properties: {
						...schema.getDefault().geojson.properties,
						"Heure arrivée": Moment().format(),
					},
				},
			})
		}
	}, [currentFamily])

	const leftPersonsByGroup = useCareLeftPersonsByGroup()

	const currentLeftPersons = leftPersonsByGroup[currentFamily?.id]

	const onSubmit = (data) => {
		onClose()
		const newData = _.cloneDeep(data)
		if (!currentFamily) {
			_.set(newData, "geojson.properties.careId", careId)

			dispatch(
				createPointWithUpdateEvent({
					point: {
						jsonschema_id: CARE_FAMILY,
						event_id: selectedEvent?.id,
						...newData,
					},
					event: {
						...selectedEvent,
						history: [
							...selectedEvent?.history,
							{
								type: CARE_FAMILY,
								subTitle: "Nouveau groupe en centre d'accueil",
								title: `${
									newData.geojson.properties.family.length + 1
								} personnes ajoutées`,
								objectId: careId,
								date: Moment().format(),
							},
						],
					},
				}),
			)

			return
		}

		dispatch(
			updatePointWithUpdateEvent({
				point: {
					jsonschema_id: CARE_FAMILY,
					event_id: selectedEvent?.id,
					id: currentFamily.id,
					...newData,
				},
				event: {
					...selectedEvent,
					history: [
						...selectedEvent?.history,
						{
							type: CARE_FAMILY,
							title: "Modification groupe en centre d'accueil",
							objectId: careId,
							date: Moment().format(),
						},
					],
				},
			}),
		)
		onClose()
	}

	useEffect(() => {
		methods.reset(currentFamily ?? schema.getDefault())
	}, [currentFamily, isOpen])
	const isNew = !currentFamily?.id
	return (
		<>
			<FormProvider {...methods}>
				<SuperModal
					isOpen={isOpen}
					rightContent={
						<FFamily
							currentView={currentView}
							name="geojson.properties.family"
							disabled={!permissions.write || isLocked}
						/>
					}
					size="5xl"
					onClose={onClose}
					toggleOnClickOutside={false}
					activateShortcut
					fade={false}
					onClick={methods.handleSubmit(onSubmit)}
					title={isNew ? "Ajouter une entrée" : "Modifier une entrée"}
					isNew={isNew}
					modalProps={{
						$forceFullHeight: true,
						classNames: { base: "max-w-7xl" },
					}}
					disableSave={
						(!methods.formState.isDirty && isNew) ||
						isLocked ||
						!permissions.write
					}
				>
					<ContainerFlex gap="1rem" fullWidth alignItemsStart ref={currentView}>
						<SFlex gap="1rem" directionColumn flexGrow={1} fullWidth>
							<StyledPaper>
								<StyledReferent>
									<Flex>
										<PersonIcon />
										<div>Référent</div>
									</Flex>
									{currentLeftPersons?.includes(-1) ? (
										<StyledChip
											$colorProperty="var(--red500)"
											variant="outlined"
											size="small"
											label="Sorti(e)"
										/>
									) : (
										<StyledChip
											$colorProperty="var(--green500)"
											variant="outlined"
											size="small"
											label="Présent(e)"
										/>
									)}
								</StyledReferent>
								<StyledFlex gap="1rem" directionColumn alignItemsStart>
									<Flex fullWidth directionColumn={isMobile} gap="0.5rem">
										<FText
											size="small"
											autoFocus
											name="geojson.properties.Identité"
											label="Identité"
											disabled={!permissions.write || isLocked}
										/>
										<FSelect
											size="small"
											name="geojson.properties.Spécificités"
											label="Spécificités"
											isMulti
											disabled={!permissions.write || isLocked}
											options={specificityOptions
												.filter((option) =>
													option.types.includes(IFamilyMemberType.ADULT),
												)
												.map((option) => option.label)}
										/>
									</Flex>
									<Flex fullWidth gap="0.5rem">
										<FText
											size="small"
											name="geojson.properties.Téléphone"
											label="Téléphone"
											disabled={!permissions.write || isLocked}
										/>
									</Flex>
									{(showComment ||
										methods.getValues().geojson.properties.Commentaire) && (
										<FText
											multiline={1}
											name={`geojson.properties.Commentaire`}
											label="Commentaire"
											disabled={!permissions.write || isLocked}
										/>
									)}
									{!showComment &&
										!methods.getValues().geojson.properties.Commentaire && (
											<PrimaryTextButton
												startIcon={<AddIcon />}
												onClick={() => setShowComment(true)}
												disabled={!permissions.write || isLocked}
											>
												Ajouter un commentaire
											</PrimaryTextButton>
										)}
								</StyledFlex>
							</StyledPaper>
							<FGeoloc
								name="geojson.properties.Adresse"
								label="Adresse"
								disabled={!permissions.write || isLocked}
							/>

							<FormDependencies targets={["geojson.properties.family"]}>
								{({ "geojson.properties.family": familyValue }) => (
									<FFamilyRenderMembers
										value={familyValue}
										currentLeftPersons={currentLeftPersons}
										name="geojson.properties.family"
										disabled={!permissions.write || isLocked}
									/>
								)}
							</FormDependencies>
						</SFlex>
					</ContainerFlex>
				</SuperModal>
			</FormProvider>
		</>
	)
}

export default ModalEnterFamily
