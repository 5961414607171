import { AppBar, Avatar } from "@mui/material"
import {
	Button,
	Dropdown,
	DropdownItem,
	DropdownMenu,
	DropdownSection,
	DropdownTrigger,
} from "@nextui-org/react"
import Axios from "axios"
import { EnumToolPath } from "@/hooks/services/useAccessRightsServices"
import useAccessRights from "@/hooks/useAccessRights"
import { setActiveTool } from "@/redux-toolkit/common/reducer"
import { resetRedux } from "@/redux-toolkit/rootActions"
import SynchroService from "@/services/SynchroService"
import Debug from "debug"
import React, { useContext, useState } from "react"
import { isDesktop, isMobile } from "react-device-detect"
import { connect, useDispatch } from "react-redux"
import { useHistory } from "react-router-dom"
import styled, { css } from "styled-components"
import Hamburger from "hamburger-react"
import { Flex } from "@/utils/components/style/flex"
import { Text20, WhiteText } from "@/utils/components/style/text"
import SettingsSuggestIcon from "@mui/icons-material/SettingsSuggest"
import ModalChangeCommune from "./changeCommunes/ModalChangeCommune"
import Notifications from "./Notifications"
import AccessibilityIcon from "@mui/icons-material/Accessibility"
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong"
import SyncAltIcon from "@mui/icons-material/SyncAlt"
import SearchBarMenu from "./search/SearchBarMenu"
import useOpenAppBar from "@/hooks/useOpenAppBar"
import LogoutIcon from "@mui/icons-material/Logout"
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import useIsAdminNumerisk from "@/hooks/useIsAdminNumerisk"
import { LogingContext } from "../devise/LoginForm"
const SAvatar = styled(Avatar)`
    width: 24px !important;
    height: 24px !important;
`
const SButton = styled(Button)`
	margin-right: 1.25rem;
`

const BrandContainer = styled.div`
    transition: 0.3s;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row !important;
    gap: 1rem;
`
const RightContainer = styled.div`
    display: flex;
    flex-direction: row !important;
    align-items: center;
    gap: 1rem;
	${isMobile &&
	css`
		gap: 0rem;
	`
	}
    width: 100%;
    justify-content: flex-end;
`

const SAppBar = styled(AppBar)`
	${isMobile &&
	css`
		padding: 0;
	`
	}
    z-index: 49 !important;
    background-color: var(--primary800) !important;
	max-width: 100vw !important;
    height: var(--height-top-bar) !important;
    display: flex !important;
    flex-direction: row !important;
    align-items: center;
    justify-content: space-between;
    position: fixed !important;
	left: 0 !important;
    overflow: hidden;
`
const HamburgerContainer = styled.div`
    transition: 0.3s;
    min-width: var(--width-navbar);
    display: flex;
    flex-direction: row-reverse !important;
    ${({ openMenu }: any) =>
		!openMenu &&
		css`
            justify-content: center;
            padding-right: ${isMobile ? "40px" : "0px"};
        `}
` as any

const debug = Debug("app:javascript:components:app:NumeriskTopBar")
debug.log = console.log.bind(console)

function deleteAllCookies() {
	const cookies = document.cookie.split(";")

	for (let i = 0; i < cookies.length; i++) {
		const cookie = cookies[i]
		const eqPos = cookie.indexOf("=")
		const name = eqPos > -1 ? cookie.substr(0, eqPos).trim() : cookie.trim()

		// Create a regex to find all cookies with the same name
		const nameRegex = new RegExp(`(^|;\\s*)${name}=`, "g")

		// Delete all cookies with the same name
		document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/"

		let retryCount = 0
		while (document.cookie.match(nameRegex) && retryCount < 5) {
			document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/"
			retryCount++
		}
	}
}

const NumeriskTopBar = ({ commune, userSettings, resetRedux }) => {
	const permissions = useAccessRights(EnumToolPath.CONFIGURATION)
	const [isOpenAppBar, setIsOpenAppBar] = useOpenAppBar()
	const [isOpenModalChangeCommune, setIsOpenModalChangeCommune] =
		useState(false)
	const dispatch = useDispatch()
	const isAdmin = useIsAdminNumerisk()
	const history = useHistory()
	const [anchorEl, setAnchorEl] = React.useState(null)
	const { setIsLoggingIn, isLoggingIn } = useContext(LogingContext)

	const handleClose = () => {
		setAnchorEl(null)
	}

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget)
	}

	const goToRgpd = () => {
		setAnchorEl(null)
		history.push("/rgpd")
	}
	const goToAccessibility = () => {
		setAnchorEl(null)
		history.push("/accessibility")
	}

	const deconnexion = async () => {
		try {
			await Axios.get("/users/sign_out", {})
		} catch (error) {
			console.log(error)
		}
		caches.delete(":s3-cache")
		caches.delete(":map-cache")
		Axios.defaults.headers.common["access-token"] = ""
		Axios.defaults.headers.common["client"] = ""
		Axios.defaults.headers.common["uid"] = ""
		deleteAllCookies()
		setIsLoggingIn(false)
		window.DatabaseService.deleteDB()
		localStorage.clear()
		dispatch(resetRedux())
	}

	return (
		<>
			<SAppBar>
				<HamburgerContainer openMenu={isOpenAppBar}>
					<Hamburger
						toggled={isOpenAppBar}
						toggle={() => {
							setIsOpenAppBar(!isOpenAppBar)
						}}
						size={20}
					/>
				</HamburgerContainer>
				{!isOpenAppBar && isDesktop && (
					<BrandContainer>
						<img src="/logo.png" width={32} />
						<Text20>Numérisk</Text20>
					</BrandContainer>
				)}
				{!(isOpenAppBar && isMobile) && (
					<RightContainer>
						<SearchBarMenu />
						<Dropdown backdrop="blur">
							<>
								{!isMobile && (
									<DropdownTrigger>
										<SButton variant="light" onPress={handleClick}>
											<Flex gap="1rem" justifyCenter>
												<WhiteText>{commune.name}</WhiteText>
												<SAvatar>{commune.name?.[0]}</SAvatar>
											</Flex>
										</SButton>
									</DropdownTrigger>
								)}

								{isMobile && (
									<DropdownTrigger>
										<Button variant="light" isIconOnly onPress={handleClick}>
											<SAvatar>{commune.name?.[0]}</SAvatar>
										</Button>
									</DropdownTrigger>
								)}
							</>
							<DropdownMenu aria-label="Static Actions" variant="bordered">
								<DropdownSection title={userSettings?.email} showDivider>
									{isAdmin && (
										<DropdownItem
											key="admin"
											color="danger"
											startContent={<AdminPanelSettingsIcon />}
											description="Accéder à l'espace administrateur"
											onClick={() => window.location.href = "/admin"}
										>
											Admin
										</DropdownItem>
									)}
									{isAdmin && (
										<DropdownItem
											color="success"
											startContent={<SyncAltIcon />}
											description="Permet de changer de communes"
											key="changeCommune"
											onClick={() => {
												setIsOpenModalChangeCommune(true)
											}}
										>
											Changer de commune
										</DropdownItem>
									)}
									<DropdownItem
										key="accessibility"
										onClick={goToAccessibility}
										description="Permet de changer la taille de la police"
										startContent={<AccessibilityIcon />}
									>
										Accessibilité
									</DropdownItem>
									{permissions.read && (
										<DropdownItem
											description="Choix des risques et des moyens d'alerte"
											key="configuration"
											startContent={<SettingsSuggestIcon />}
											onClick={() => {
												history.push("/configuration")
											}}
										>
											Configuration
										</DropdownItem>
									)}
									<DropdownItem
										key="rgpd"
										onClick={goToRgpd}
										startContent={<ReceiptLongIcon />}
										description="Génération du registre de traitement relatif au RGPD"
									>
										RGPD
									</DropdownItem>
									<DropdownItem
										key="deconnexion"
										onClick={deconnexion}
										startContent={<LogoutIcon />}
										color="danger"
									>
										Déconnexion
									</DropdownItem>
								</DropdownSection>
							</DropdownMenu>
						</Dropdown>
					</RightContainer>
				)}
			</SAppBar>

			{isAdmin && (
				<ModalChangeCommune
					isOpen={isOpenModalChangeCommune}
					setIsOpen={setIsOpenModalChangeCommune}
				/>
			)}
		</>
	)
}

const mapStateToProps = (state) => ({
	commune: state.commune,
	userSettings: state.userSettings,
	isOnline: state.common.isOnline,
	activeTool: state.common.activeTool,
})

const mapDispatchToProps = (dispatch) => ({
	synchro: (params) => {
		return dispatch(SynchroService.synchro(params))
	},
	synchroOncePerSession: () => {
		return dispatch(
			SynchroService.synchroOncePerSession({ withoutDelete: true }),
		)
	},
	resetRedux: () => {
		return dispatch(resetRedux())
	},
	setActiveTool: (tool) => {
		return dispatch(setActiveTool(tool))
	},
})

export default connect(mapStateToProps, mapDispatchToProps)(NumeriskTopBar)
