import React, { useState, useEffect } from "react"
import SuperMap from "utils/components/map/SuperMap"
import usePoints from "@/hooks/usePoints"
import useMultiPolygons from "@/hooks/useMultiPolygons"
import MarkerDaybook from "@/pages/maincourante/subComponents/carto/MarkerDaybook"
import DatabaseService from "@/services/DatabaseService"
import { Pane, GeoJSON } from "react-leaflet"
import getPolygonStyle from "utils/components/map/polygon/getPolygonStyle"
import { useSelector, useDispatch } from "react-redux"
import { setPanel } from "@/redux-toolkit/common/reducer"
import { DAYBOOK_COUCHE, DAYBOOK_POINT } from "utils/panels/PanelCarto"
import _ from "lodash"
import { v4 as uuidv4 } from "uuid"

export interface IProvidedSelection {
	id: string
	key: string
	title: string
	category: string
	pointsKeys: [
		{
			id: string
			style: {
				iconSize: number
			}
		},
	]
	couchesKeys: [
		{
			id: string
			style: any
		},
	]
	layerType: string
	viewParams: {
		center: [number, number]
		zoom: number
	}
	path: string
	sortedRank: number | undefined
	type: "CARTO" | string
	checked: boolean
}

const CartographyTemplate = ({
	providedSelection,
	setMapScreenBlob,
}: {
	providedSelection: IProvidedSelection
	setMapScreenBlob: React.Dispatch<any>
}) => {
	const dispatch = useDispatch()
	const position = [45.8870177, -1.1964972] as any
	const points = usePoints()
	const multiPolygons = useMultiPolygons()
	const [pointsToDisplay, setPointsToDisplay] = useState([])
	const [multiPolygonsToDisplay, setMultiPolygonsToDisplay] = useState([])
	const panel = useSelector((state: any) => state.common.panel)

	const jsonSchemasStyle = _.chain(providedSelection?.pointsKeys)
		.keyBy("id")
		.mapValues("style")
		.value()

	useEffect(() => {
		const processMultiPolygon = async () => {
			const couchesKeys = providedSelection?.couchesKeys ?? []
			if (!couchesKeys.length) {
				// if previous carto has multiPolygonsToDisplay, reseting it
				if (!_.isEmpty(multiPolygonsToDisplay)) setMultiPolygonsToDisplay([])
				return
			}
			const couchesKeyIds = couchesKeys.map((obj) => obj.id) ?? []
			const multiPolygonToRender = Object.values(multiPolygons).filter(
				(polygon: any) => couchesKeyIds.includes(polygon.id),
			)
			const geo = []
			await Promise.all(
				multiPolygonToRender.map(async (vcouche: any) => {
					const data = await DatabaseService.get(vcouche.id)
					if (data) {
						geo.push({
							...vcouche,
							geojson: data,
						})
					}
					return
				}),
			)
			setMultiPolygonsToDisplay(geo)
		}
		processMultiPolygon()
	}, [multiPolygons, providedSelection])

	useEffect(() => {
		const processPoints = async () => {
			const pointsKeys = providedSelection?.pointsKeys ?? []
			if (!pointsKeys.length) {
				// if previous carto has pointsToDisplay, reseting it
				if (!_.isEmpty(pointsToDisplay)) setPointsToDisplay([])
				return
			}
			const pointsKeyIds = pointsKeys.map((obj) => obj.id)
			const pointsToRender = Object.values(points).filter((point: any) =>
				pointsKeyIds?.includes(point.jsonschema_id),
			)
			setPointsToDisplay(pointsToRender)
		}
		processPoints()
	}, [points, providedSelection])

	return (
		<SuperMap
			center={position}
			zoom={10}
			disableZoomControl={true}
			hasSearchControl={false}
			displayOptions={{
				displayMinimap: false,
				displayDraw: false,
				displaySignal: false,
				displayIncidentControl: false,
				displayBottomControl: false,
				displayRightControl: false,
			}}
			setMapScreenBlob={setMapScreenBlob}
			invisible
			providedSelection={providedSelection}
		>
			<>
				{pointsToDisplay.map((point) => {
					if (point.id === panel?.id) {
						return null
					}
					return (
						<MarkerDaybook
							key={point.id}
							point={point}
							handleClick={() => {
								dispatch(
									setPanel({
										type: DAYBOOK_POINT,
										id: point.id,
									}),
								)
							}}
							forcedStyle={jsonSchemasStyle[point.jsonschema_id]}
						/>
					)
				})}
				{multiPolygonsToDisplay.map((elem, index) => {
					const elemPriority = _.isNaN(parseInt(elem.custom_props.priority))
						? 1
						: parseInt(elem.custom_props.priority)
					const zIndex = 500 + elemPriority

					//* Apply uploaded carto geojson style to fetched geojson from database
					const finalElem = {
						...elem,
						custom_props: {
							...elem.custom_props,
							style: providedSelection?.couchesKeys?.find(
								(obj) => obj.id === elem.id,
							)?.style,
						},
					}

					return (
						<Pane
							name={finalElem.id}
							key={`${uuidv4()}:${zIndex.toString()}`}
							style={{
								zIndex: zIndex,
							}}
						>
							<GeoJSON
								key={uuidv4()}
								data={JSON.parse(finalElem.geojson)}
								style={() => getPolygonStyle(finalElem)}
							/>
						</Pane>
					)
				})}
			</>
		</SuperMap>
	)
}

export default CartographyTemplate
