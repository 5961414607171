import { updateFiche } from "@/redux-toolkit/data/fiche/resources"
import { useCallback } from "react"
import { useDispatch } from "react-redux"
import IFiche from "utils/types/IFiche"
import { v4 as uuidv4 } from "uuid"

const useAddCategory = (fiche: IFiche) => {
	const dispatch = useDispatch()

	return useCallback(
		(title = "Nouvelle catégorie") => {
			const newFiche = {
				...fiche,
				actionsByCategory: [
					...fiche.actionsByCategory,
					{
						categoryName: title,
						actionIds: [],
						id: uuidv4(),
					},
				],
			}

			dispatch(updateFiche(newFiche))
		},
		[fiche],
	)
}

export default useAddCategory
