import { View } from "@react-pdf/renderer"
import React from "react"
import NormalText from "../NormalText"

const PLegend = ({ legends, rowDisplay = false }) => {
	return (
		<View
			style={
				rowDisplay
					? {
							display: "flex",
							flexDirection: "row",
							gap: "64px",
							margin: "4px 0 8px 48px",
					  }
					: {}
			}
		>
			{legends.map((legend, index) => {
				return (
					<View
						key={index}
						style={{
							display: "flex",
							flexDirection: "row",
							alignItems: "center",
							padding: "3px 0",
						}}
					>
						<NormalText
							style={{
								width: "10px",
								height: "10px",
								backgroundColor: legend.color,
								marginRight: "10px",
							}}
						/>
						<NormalText>{legend.desc}</NormalText>
					</View>
				)
			})}
		</View>
	)
}

export default PLegend
