import React from "react"
import { Text } from "@react-pdf/renderer"

const BoldText = ({ children, marginBottom = 0, style = {} }) => {
	return (
		<Text
			style={{
				fontSize: "11px",
				fontFamily: "Helvetica-Bold",
				textAlign: "justify",
				marginBottom: marginBottom,
				...style,
			}}
		>
			{children}
		</Text>
	)
}

export default BoldText
