export const getTreePathFromKey = ({ tree, key }) => {
	const treeValues = {}
	addToTreeValues({ currentTree: tree, prefix: [], treeValues })
	return treeValues[key]
}

const addToTreeValues = ({ treeValues, prefix, currentTree }) => {
	Object.values(currentTree).forEach((treeItem: any) => {
		const prefixValue =
			prefix.length === 0
				? [treeItem.key]
				: [...prefix, "children", treeItem.key]
		treeValues[treeItem.key] = prefixValue
		if (!treeItem.children) {
			return
		}

		addToTreeValues({
			treeValues,
			prefix: prefixValue,
			currentTree: treeItem.children,
		})
	})
}
