import React, { useContext, useEffect } from "react"
import PcsConfigContext from "../context/PcsConfigContext"
import { FormProvider, useForm } from "react-hook-form"
import FSwitch from "@/utils/form/FSwitch"
import { PrimaryTextButton } from "@/utils/components/style/button"
import Settings from "@mui/icons-material/Settings"
import ButtonPopover from "@/pages/maincourante/subComponents/ButtonPopover"
import { IconButton, Tooltip } from "@mui/material"
import { Padding16, Padding8 } from "@/utils/components/style/padding"

const IconButtonCustomizePcsGeneration = ({}) => {
	const { config, setConfig } = useContext(PcsConfigContext)

	// on change react hook form modifying context config
	const methods = useForm({
		defaultValues: {
			config: {
				anonymize: config?.anonymize,
				generate_livret: config?.generate_livret,
			},
		},
		mode: "onChange",
	})

	const anonymize = methods.watch("config.anonymize")
	const generate_livret = methods.watch("config.generate_livret")

	useEffect(() => {
		if (config?.anonymize !== undefined && config?.anonymize !== anonymize) {
			methods.setValue("config.anonymize", config?.anonymize)
		}
		if (
			config?.generate_livret !== undefined &&
			config?.generate_livret !== generate_livret
		) {
			methods.setValue("config.generate_livret", config?.generate_livret)
		}
		if (
			config?.generate_livret === undefined &&
			config?.generate_livret !== generate_livret
		) {
			methods.setValue("config.generate_livret", undefined)
		}
		if (config?.anonymize === undefined && config?.anonymize !== anonymize) {
			methods.setValue("config.anonymize", undefined)
		}
	}, [config])

	useEffect(() => {
		if (
			anonymize !== undefined &&
			generate_livret !== undefined &&
			(config?.anonymize !== anonymize ||
				config?.generate_livret !== generate_livret)
		) {
			setConfig({ anonymize, generate_livret })
		}
	}, [anonymize, generate_livret])

	return (
		<ButtonPopover
			popoverContent={({ handleClose }) => (
				<Padding8>
					<FormProvider {...methods}>
						<FSwitch
							name="config.anonymize"
							label="Anonymiser les données (retire les numéros de téléphones et les mails)"
						/>
						<FSwitch
							name="config.generate_livret"
							label="Générer les livrets"
						/>
					</FormProvider>
				</Padding8>
			)}
		>
			{({ handleClick }) => (
				<Tooltip title="Personnaliser la génération de PCS">
					<IconButton onClick={handleClick}>
						<Settings />
					</IconButton>
				</Tooltip>
			)}
		</ButtonPopover>
	)
}

export default IconButtonCustomizePcsGeneration
