import Debug from "debug"
import React from "react"
import { isDesktop, isMobile } from "react-device-detect"
import { useFieldArray, useFormContext } from "react-hook-form"
import styled from "styled-components"
import { Flex } from "@/utils/components/style/flex"
import { H6 } from "@/utils/components/style/header"
import FDateTime from "@/utils/form/FDateTime"
import _ from "lodash"
import { IFamilyMemberType } from "@/utils/types/IFamilyMember"
import MembersControl from "./MembersControl"

// app:javascript:utils:form:specific:care:FFamily.tsx
const debug = Debug("app:javascript:utils:form:specific:care:FFamily")
debug.log = console.log.bind(console)

const Container = styled.div`
    width: ${isMobile ? "100%" : null};
`

const FFamily = ({ name, currentView, disabled = false }) => {
	const { control, getValues, watch } = useFormContext()

	const { fields, append, remove } = useFieldArray({
		control,
		// @ts-ignore
		name,
	})
	watch(name)
	const family = getValues(name) ?? []

	const appendMembers = (type, quantity) => {
		const renderObject = { type: type }
		if (quantity > 1) {
			const typeName =
				type === IFamilyMemberType.ADULT
					? "adultes"
					: type === IFamilyMemberType.CHILD
						? "enfants"
						: type === IFamilyMemberType.BABY
							? "bébés"
							: type === IFamilyMemberType.ANIMAL
								? "animaux"
								: "entités"
			_.set(renderObject, "quantity", quantity)
			_.set(renderObject, "Identité", `Groupe de ${quantity} ${typeName}`)
		}
		append(renderObject, {
			shouldFocus: isDesktop,
		})
		if (isMobile) return
		setTimeout(() => {
			currentView.current.scrollIntoView({
				behavior: "smooth",
				block: "end",
			})
		}, 100)
	}

	return (
		<>
			<Container>
				<Flex directionColumn gap="0.5rem" alignItemsStart fullWidth>
					<FDateTime
						name="geojson.properties.Heure arrivée"
						label="Heure d'arrivée"
						disabled={disabled}
					/>
					<H6>Ajouter des membres</H6>
					<Flex fullWidth flexEnd gap="1rem" directionColumn>
						<MembersControl
							family={family}
							memberType={IFamilyMemberType.ADULT}
							disabled={disabled}
							appendMembers={appendMembers}
						/>
						<MembersControl
							family={family}
							memberType={IFamilyMemberType.CHILD}
							disabled={disabled}
							appendMembers={appendMembers}
						/>
						<MembersControl
							family={family}
							memberType={IFamilyMemberType.BABY}
							disabled={disabled}
							appendMembers={appendMembers}
						/>
						<MembersControl
							family={family}
							memberType={IFamilyMemberType.ANIMAL}
							disabled={disabled}
							appendMembers={appendMembers}
						/>
					</Flex>
				</Flex>
			</Container>
		</>
	)
}

export default FFamily
