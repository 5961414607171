import { yupResolver } from "@hookform/resolvers/yup"
import useJsonSchemasWithStatics from "@/hooks/useJsonSchemasWithStatics"
import React, { useMemo } from "react"
import { FormProvider, useForm } from "react-hook-form"
import FJsonSchema from "utils/form/FJsonSchema"
import { LinkType } from "utils/types/IFiche"
import Yup from "utils/yup"
import useParentSubmit from "./utils/parentSubmit/useParentSubmit"

const schema = Yup.object().shape({
	type: Yup.string().default(LinkType.JSON_SCHEMA),
	id: Yup.string().required("Aucun tableau séléctionné"),
})

const JsonSchemaLink = ({ links }) => {
	const methods = useForm({
		resolver: yupResolver(schema),
		defaultValues: schema.cast({}),
	})

	useParentSubmit(methods.handleSubmit)

	const disabledItems = useMemo(() => {
		return links
			.filter((link) => link.type === LinkType.JSON_SCHEMA)
			.map((link) => link.id)
	}, [links])

	return (
		<FormProvider {...methods}>
			<FJsonSchema name="id" disabledItems={disabledItems} />
		</FormProvider>
	)
}

export default JsonSchemaLink
