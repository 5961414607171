import Axios from "axios"
import { addPoint } from "@/redux-toolkit/data/points/reducer"
import { updatePointWithUpdateEvent } from "@/redux-toolkit/data/points/resources"
import _ from "lodash"
import { useFormContext } from "react-hook-form"
import { useDispatch } from "react-redux"
import { useParams } from "react-router-dom"
import IDaybookAlert, {
	DaybookAlertCanal,
	DaybookAlertType,
} from "@/utils/types/daybook/IDaybookAlert"
import { JsonSchemaPropertiesType } from "@/utils/types/IJsonSchema"
import useJsonSchemasWithStatics from "../useJsonSchemasWithStatics"
import usePoints from "../usePoints"
import Moment from "moment"
import useSelectedEvent from "../useSelectedEvent"
import { getUserCommune } from "@/redux-toolkit/commune/ressources"
import ErrorService from "@/services/ErrorService"
import IPoint from "utils/types/IPoint"
import useCommune from "../useCommune"
import JsonSchemaService from "@/pages/carto2/cartographie/service/JsonSchemaService"
import { useOrgaPoints } from "../useOrga"
export interface IPersonAlert {
	name: string
	phones: string[]
}

interface IAnalyzedPhone {
	phone: string
	pointId: string
	name: string
}
interface IAnalyzedPhones {
	mobile: IAnalyzedPhone[]
	landline: IAnalyzedPhone[]
	invalid: IAnalyzedPhone[]
	noPhone: Partial<IAnalyzedPhone>[]
}

export const guessPhoneType = (phone: string): "mobile" | "fixed" => {
	if (phone.startsWith("06") || phone.startsWith("07")) {
		return "mobile"
	}
	return "fixed"
}

const useSendSmsAlert = () => {
	const points = usePoints()
	const jsonSchemas = useJsonSchemasWithStatics()
	const { watch } = useFormContext()
	const { alertId } = useParams<{ alertId: string }>()
	const dispatch = useDispatch()
	const selectedEvent = useSelectedEvent()
	const commune = useCommune()
	const pointsOrga = useOrgaPoints()
	const {
		type,
		listeDiffusions,
		listeDiffusionIds,
		smsText,
		voiceContent,
		includeFixedPhones,
	} = watch("geojson.properties") as IDaybookAlert["geojson"]["properties"]

	const escapeTwimlTags = (voiceContent: string) => {
		// Remplace les espaces multiples par un seul espace
		let cleanedMessage = voiceContent.replace(/ {2,}/g, " ")

		// Looking for illegal keycaps into voiceContent
		const isSus = ["<", ">"].every((term) => voiceContent.includes(term))
		if (isSus) {
			ErrorService.error({
				component: "useSendSmsAlert:escapeTwimlTags",
				message: "Suspicious alert message found : " + voiceContent,
				silence: true,
				dispatch,
			})
			Axios.post("/api/warning_mail_twilio", {
				voiceContent,
				alertId,
			})
		}

		// Échappe les caractères < et > pour prévenir l'injection de TwiML
		cleanedMessage = cleanedMessage.replace(/</g, "").replace(/>/g, "")

		return cleanedMessage
	}

	const analyzeCompatiblePhones: (pointIds?: Array<IPoint["id"]>) => {
		finalPhones: IAnalyzedPhones
		uniqPhones: string[]
		duplicates: string[]
	} = (pointIds = []) => {
		let concernedPoints = pointIds.map((pointId) => points[pointId])

		if (_.isEmpty(pointIds)) {
			concernedPoints = (listeDiffusionIds ?? []).map((id) => points[id])
		}

		const finalPhones = {
			mobile: [],
			landline: [],
			invalid: [],
			noPhone: [],
		} as IAnalyzedPhones

		// uniq by point id
		concernedPoints = _.uniqBy(concernedPoints, "id")

		concernedPoints.forEach((point) => {
			if (!point) {
				return
			}
			const concernedJsonSchema = jsonSchemas[point.jsonschema_id]
			if (!concernedJsonSchema) {
				return
			}
			const idProperties =
				JsonSchemaService.getIdProperties(concernedJsonSchema)
			const phoneProperty =
				JsonSchemaService.getPhoneProperty(concernedJsonSchema)
			const name = idProperties
				.map((idProperty) => point.geojson.properties[idProperty.name])
				.join(" ")
			let phones = point?.geojson?.properties?.[phoneProperty.name]
			if (_.isEmpty(phones)) {
				if (!point) {
					return
				}
				finalPhones.noPhone.push({
					pointId: point.id,
					name,
				})
				return
			}

			phones = _.uniq(phones)

			phones
				.map((phone) => phone.Numéro)
				.forEach((phone) => {
					if (!phone.match(/^[0-9]{2} [0-9]{2} [0-9]{2} [0-9]{2} [0-9]{2}$/)) {
						finalPhones.invalid.push({
							phone,
							pointId: point.id,
							name,
						})
						return
					}
					const regex = /^0[67] [0-9]{2} [0-9]{2} [0-9]{2} [0-9]{2}$/
					const regexLandline = /^0[1-5] [0-9]{2} [0-9]{2} [0-9]{2} [0-9]{2}$/

					if (regex.test(phone)) {
						finalPhones.mobile.push({
							phone,
							pointId: point.id,
							name,
						})
					} else if (regexLandline.test(phone)) {
						finalPhones.landline.push({
							phone,
							pointId: point.id,
							name,
						})
					} else {
						finalPhones.invalid.push({
							phone,
							pointId: point.id,
							name,
						})
					}
				})
		})
		const allowedPhones = [...finalPhones.mobile]
		if (includeFixedPhones) {
			allowedPhones.push(...finalPhones.landline)
		}

		const phones = _.flatten(allowedPhones.map((phone) => phone.phone))
		const uniqPhones = _.uniq(phones)
		const duplicates = _.difference(phones, uniqPhones)

		return {
			finalPhones,
			uniqPhones,
			duplicates,
		}
	}

	const processAlertTest = async ({
		person,
	}: {
		person: IPersonAlert
	}): Promise<any> => {
		try {
			await Axios.post("/api/citycalert_campaign_test", {
				campaign_type: type,
				phone_number: person.phones[0],
				message: type === DaybookAlertCanal.SMS ? smsText : voiceContent,
				alertId,
			})
		} catch (error) {
			ErrorService.error({
				component: "useSendSmsAlert:processAlertTest",
				message: error.message,
				silence: true,
				dispatch,
			})
			return { error: error }
		}

		getUserCommune(dispatch)
		return "success"
	}

	const processTwilioAlertTest = async ({
		person,
	}: {
		person: IPersonAlert
	}): Promise<any> => {
		try {
			const phone_test = person.phones[0]
				.replaceAll(" ", "")
				.replace("0", "+33") //? internationnal format for Twilio

			await Axios.post("/api/twilio_campaign_test", {
				campaign_type: type,
				phone_number: phone_test,
				message:
					type === DaybookAlertCanal.SMS
						? smsText
						: escapeTwimlTags(voiceContent),
				alertId,
			})
		} catch (error) {
			ErrorService.error({
				component: "useSendSmsAlert:processTwilioAlertTest",
				message: error.message,
				silence: true,
				dispatch,
			})
			return { error: error }
		}

		getUserCommune(dispatch)
		return "success"
	}

	const processAlertCampaign = async (): Promise<any> => {
		const { uniqPhones } = analyzeCompatiblePhones()

		try {
			await Axios.post("/api/citycalert", {
				campaign_type: type,
				phone_numbers: uniqPhones,
				message: type === DaybookAlertCanal.SMS ? smsText : voiceContent,
				alertId,
			})
		} catch (error) {
			ErrorService.error({
				component: "useSendSmsAlert:processAlertCampaign",
				message: error.message,
				silence: true,
				dispatch,
			})
			return { error: error }
		}

		await dispatch(
			updatePointWithUpdateEvent({
				point: {
					...points[alertId],
					geojson: {
						...points[alertId].geojson,
						properties: {
							...points[alertId].geojson.properties,
							sendingDate: Moment().format(),
							status:
								type === DaybookAlertCanal.SMS
									? DaybookAlertType.SENDING
									: DaybookAlertType.FINISHED,
							usedService: "CITYCALERT",
						},
					},
				},
				event: {
					...selectedEvent,
					updated_at: Moment().format(),
				},
			}),
		)
		getUserCommune(dispatch)

		return "success"
	}

	const processTwilioAlertCampaign = async (): Promise<any> => {
		const { uniqPhones } = analyzeCompatiblePhones()
		const formattedUniqPhones = uniqPhones.map(
			(phone_number) => phone_number.replaceAll(" ", "").replace("0", "+33"), //? internationnal format for Twilio
		)

		try {
			await Axios.post("/api/twilio", {
				campaign_type: type,
				phone_numbers: formattedUniqPhones,
				message:
					type === DaybookAlertCanal.SMS
						? smsText
						: escapeTwimlTags(voiceContent),
				alertId,
			})
		} catch (error) {
			ErrorService.error({
				component: "useSendSmsAlert:processTwilioAlertCampaign",
				message: error.message,
				silence: true,
				dispatch,
			})
			return { error: error }
		}

		await dispatch(
			updatePointWithUpdateEvent({
				point: {
					...points[alertId],
					geojson: {
						...points[alertId].geojson,
						properties: {
							...points[alertId].geojson.properties,
							sendingDate: Moment().format(),
							status:
								type === DaybookAlertCanal.SMS
									? DaybookAlertType.SENDING
									: DaybookAlertType.FINISHED,
							usedService: "TWILIO",
						},
					},
				},
				event: {
					...selectedEvent,
					updated_at: Moment().format(),
				},
			}),
		)
		getUserCommune(dispatch)

		return "success"
	}

	return {
		sendAlertTest: processAlertTest,
		sendTwilioAlertTest: processTwilioAlertTest,
		sendAlertCampaign: processAlertCampaign,
		sendTwilioAlertCampaign: processTwilioAlertCampaign,
		analyzeCompatiblePhones,
	}
}

export default useSendSmsAlert
