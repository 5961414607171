import ChevronLeftIcon from "@mui/icons-material/ChevronLeft"
import ChevronRightIcon from "@mui/icons-material/ChevronRight"
import GetApp from "@mui/icons-material/GetApp"
import { IconButton } from "@mui/material"
import { BlobProvider, PDFDownloadLink } from "@react-pdf/renderer"
import React, { useEffect, useState } from "react"
import { Document, Page, pdfjs } from "react-pdf"
import styled from "styled-components"
import {
	PrimaryButton,
	PrimaryOutlinedButton,
} from "utils/components/style/button"
import { Flex } from "utils/components/style/flex"
import ReportPDF from "./ReportPDF"
import { useSelector } from "react-redux"
import useSelectedEvent from "@/hooks/useSelectedEvent"
import { useHistory, useLocation } from "react-router-dom"
import useJsonSchemasWithStatics from "@/hooks/useJsonSchemasWithStatics"
import useMultiPolygons from "@/hooks/useMultiPolygons"
import usePoints from "@/hooks/usePoints"
import useOrga from "@/hooks/useOrga"
import useHumans from "@/hooks/useHumans"
import useFiches from "@/hooks/useFiches"
import useCrisisOrgaWithOrder from "@/pages/maincourante/following/orga/utils/useCrisisOrgaWithOrder"
import { existingSignalMarker } from "./components/useSignalMarker"
import _ from "lodash"
import { useStaticPoints } from "@/hooks/useStaticPoints"
import useCoucheEvents from "@/hooks/useCoucheEvents"
import useOpenAppBar from "@/hooks/useOpenAppBar"
import ModalWaiting from "@/utils/modals/ModalWaiting"
import ReportService, {
	CARTOGRAPHY,
	CRISIS_ORGA,
	DASHBOARD,
	DAYBOOK,
	SCENARIOS,
} from "@/pages/carto2/cartographie/service/ReportService"
import { NO_BLOB } from "@/pages/maincourante/DaybookDashboard"
import ArrowBackIcon from "@mui/icons-material/ArrowBack"
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight"
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft"

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`

const SPDFDownloadLink = styled(PDFDownloadLink)`
    margin: 1em;
`
const StyledEmptyDiv = styled.div`
    width: 160px;
`
const SFlex = styled.div`
    flex-grow: 10;
    display: flex;
    justify-content: center;
    align-items: center;
`
const SPage = styled(Page)`
    box-shadow: 0 30px 40px 0 rgb(16 36 94 / 20%);
`

const Report = () => {
	const history = useHistory()
	const [numPages, setNumPages] = useState(null)
	const [_isOpenAppBar, setOpenAppBar] = useOpenAppBar()
	const points = usePoints()
	const staticPoints = useStaticPoints()
	const coucheEvents = useCoucheEvents()
	const selectedEvent = useSelectedEvent()
	const jsonSchemas = useJsonSchemasWithStatics()
	const [isProcessing, setIsProcessing] = useState(true)
	const [isProcessingArretesBlob, setIsProcessingArretesBlob] = useState(true)
	const [isProcessingLinkedFiles, setIsProcessingLinkedFiles] = useState(true)
	const [isProcessingWisiwygExtraction, setIsProcessingWisiwygExtraction] =
		useState(true)
	const multiPolygons = useMultiPolygons()
	const humans = useHumans()
	const crisisOrgaWithOrder = useCrisisOrgaWithOrder()
	const orgas = useOrga()
	const fiches = useFiches()
	const arretes = useSelector((state: any) => state.data.pdfs)
	const [objectOfArretesBlobsObject, setObjectOfArretesBlobsObject] = useState(
		{},
	)
	const [validEventsArray, setValidEventsArray] = useState([])
	const [objectOfWisiwygBlobsArray, setObjectOfWisiwygBlobsArray] = useState({})
	const [communeLogoSrc, setCommuneLogoSrc] = useState("")
	const filteredArretes = Object.values(arretes).filter(
		(arrete: any) => arrete.event_id === selectedEvent.id,
	)
	const coucheJsonSchemas = useSelector(
		(state: any) => state.daybook.coucheJsonSchemas,
	)
	const coucheMultiPolygons = useSelector(
		(state: any) => state.daybook.coucheMultiPolygons,
	)
	const [pageNumber, setPageNumber] = useState(1) //setting 1 to show fisrt page
	const propsLocation = useLocation() as any
	const commune = useSelector((state: any) => state.commune)
	const [pdfIsLoading, setPdfIsLoading] = useState(false)
	const [documentLoading, setDocumentLoading] = useState(false)

	const pointsWithBlobs = Object.values(points)
		.filter(
			(point: any) =>
				point.jsonschema_id === "INCIDENT" ||
				point.jsonschema_id === "INTERVENTION",
		)
		.filter((daybookPoint: any) => daybookPoint.event_id === selectedEvent.id)

	const checkedFichesActionsIds = selectedEvent.scenarioActionIds.map(
		(object) => object.actionId,
	)

	const daybookExistingDatas = ReportService.checkExistingDatas(
		points,
		selectedEvent,
		pointsWithBlobs,
		objectOfArretesBlobsObject,
	)

	useEffect(() => {
		setOpenAppBar(false)
		ReportService.getCommuneLogo({
			commune,
			setCommuneLogoSrc,
		})
		ReportService.processFromPointsBlobs({
			pointsWithBlobs,
			setIsProcessingLinkedFiles,
			setValidEventsArray,
		})
		ReportService.elementToBlobProcessFromArretes({
			elements: filteredArretes,
			setIsProcessingArretesBlob,
			setObjectOfArretesBlobsObject,
		})
		ReportService.getWisiwigEntities({
			elements: pointsWithBlobs,
			setIsProcessingWisiwygExtraction,
			setObjectOfWisiwygBlobsArray,
		})
	}, [])

	useEffect(() => {
		if (
			!isProcessingArretesBlob &&
			!isProcessingLinkedFiles &&
			!isProcessingWisiwygExtraction
		) {
			setIsProcessing(false)
			setPdfIsLoading(true)
			setDocumentLoading(true)
		}
	}, [
		isProcessingArretesBlob,
		isProcessingLinkedFiles,
		isProcessingWisiwygExtraction,
	])

	const onDocumentLoadSuccess = ({ numPages }) => {
		setNumPages(numPages)
		setPageNumber(1)
	}

	const changePage = (offset) => {
		setPageNumber((prevPageNumber) => prevPageNumber + offset)
	}

	const previousPage = () => {
		changePage(-1)
	}
	const nextPage = () => {
		changePage(1)
	}
	const previousTenPage = () => {
		changePage(-10)
	}
	const nextTenPage = () => {
		changePage(10)
	}

	// Defining report summary categories
	const tempSummaryCategories = {
		[DASHBOARD]: { title: DASHBOARD },
		[CRISIS_ORGA]: { title: CRISIS_ORGA },
		[SCENARIOS]: !_.isEmpty(checkedFichesActionsIds)
			? { title: SCENARIOS }
			: null,
		[DAYBOOK]: !_.isEmpty(daybookExistingDatas)
			? {
					title: DAYBOOK,
					childrens: daybookExistingDatas,
				}
			: null,
		[CARTOGRAPHY]:
			propsLocation.state.mapScreenBlob !== NO_BLOB
				? { title: CARTOGRAPHY }
				: null,
	}
	const summaryCategories = _.omitBy(tempSummaryCategories, (v) => v === null)

	return (
		<>
			<Flex spaceBetween>
				<PrimaryOutlinedButton
					$margin="1rem"
					onClick={() => history.push("/maincourante/dashboard")}
					startIcon={<ArrowBackIcon />}
				>
					Retour
				</PrimaryOutlinedButton>
				<StyledEmptyDiv />
				<SFlex>
					<IconButton onClick={previousTenPage} disabled={pageNumber - 10 < 1}>
						<KeyboardDoubleArrowLeftIcon />
					</IconButton>
					<IconButton onClick={previousPage} disabled={pageNumber === 1}>
						<ChevronLeftIcon />
					</IconButton>
					<div>
						Page <b>{pageNumber}</b> sur {numPages}
					</div>
					<IconButton onClick={nextPage} disabled={pageNumber === numPages}>
						<ChevronRightIcon />
					</IconButton>
					<IconButton
						onClick={nextTenPage}
						disabled={pageNumber + 10 > numPages}
					>
						<KeyboardDoubleArrowRightIcon />
					</IconButton>
				</SFlex>
				{!_.isEmpty(objectOfArretesBlobsObject) && (
					<SPDFDownloadLink
						document={
							<ReportPDF
								points={points}
								pointsWithBlobs={validEventsArray}
								staticPoints={staticPoints}
								coucheEvents={coucheEvents}
								jsonSchemas={jsonSchemas}
								multiPolygons={multiPolygons}
								humans={humans}
								orgas={orgas}
								crisisOrgaWithOrder={crisisOrgaWithOrder}
								fiches={fiches}
								arretes={arretes}
								objectOfArretesBlobsObject={objectOfArretesBlobsObject}
								objectOfWisiwygBlobsArray={objectOfWisiwygBlobsArray}
								coucheJsonSchemas={coucheJsonSchemas}
								coucheMultiPolygons={coucheMultiPolygons}
								commune={commune}
								communeLogoSrc={communeLogoSrc}
								selectedEvent={selectedEvent}
								screenshotBlobs={propsLocation.state}
								existingSignalMarker={existingSignalMarker}
								daybookExistingDatas={daybookExistingDatas}
								summaryCategories={summaryCategories}
							/>
						}
						fileName={"Rapport de crise - " + selectedEvent?.name + ".pdf"}
					>
						{({ blob, url, loading, error }) => {
							if (loading) {
								if (!pdfIsLoading) {
									setPdfIsLoading(true)
								}
								return <div>Loading document...</div>
							}
							if (pdfIsLoading) {
								setPdfIsLoading(false)
							}
							return (
								<PrimaryButton startIcon={<GetApp />}>
									Télécharger
								</PrimaryButton>
							)
						}}
					</SPDFDownloadLink>
				)}
			</Flex>
			{!_.isEmpty(objectOfArretesBlobsObject) && (
				<BlobProvider
					document={
						<ReportPDF
							points={points}
							pointsWithBlobs={validEventsArray}
							staticPoints={staticPoints}
							coucheEvents={coucheEvents}
							jsonSchemas={jsonSchemas}
							multiPolygons={multiPolygons}
							humans={humans}
							orgas={orgas}
							crisisOrgaWithOrder={crisisOrgaWithOrder}
							fiches={fiches}
							arretes={arretes}
							objectOfArretesBlobsObject={objectOfArretesBlobsObject}
							objectOfWisiwygBlobsArray={objectOfWisiwygBlobsArray}
							coucheJsonSchemas={coucheJsonSchemas}
							coucheMultiPolygons={coucheMultiPolygons}
							commune={commune}
							communeLogoSrc={communeLogoSrc}
							selectedEvent={selectedEvent}
							screenshotBlobs={propsLocation.state}
							existingSignalMarker={existingSignalMarker}
							daybookExistingDatas={daybookExistingDatas}
							summaryCategories={summaryCategories}
						/>
					}
				>
					{({ blob, url, loading, error }) => {
						// Do whatever you need with blob here
						if (loading) {
							if (!documentLoading) {
								setDocumentLoading(true)
							}
							return <div>Loading document...</div>
						}
						if (documentLoading) {
							setDocumentLoading(false)
						}
						return (
							<div>
								<Document file={url} onLoadSuccess={onDocumentLoadSuccess}>
									<Flex gap={"50px"} justifyCenter>
										<SPage
											pageNumber={pageNumber}
											renderAnnotationLayer={false}
										/>
									</Flex>
								</Document>
							</div>
						)
					}}
				</BlobProvider>
			)}
			<ModalWaiting
				isOpen={isProcessing || pdfIsLoading || documentLoading}
				titleText="Génération en cours"
				infoText="Récupération des données relatives à la génération,
                cette opération peut durer un certain temps, veuillez
                patienter."
				bodyText="Veuillez patienter, Génération du rapport de crise
                en cours..."
			/>
		</>
	)
}

export default Report
