import L from "leaflet"
import React, { useEffect, useRef } from "react"
import { useMap } from "react-leaflet"
import MarkerClusterGroup from "@changey/react-leaflet-markercluster"
import ReactDOMServer from "react-dom/server"
import styled from "styled-components"
import useCooperationFilters from "@/hooks/useCooperationFilters"
import { setCooperationFilters } from "@/redux-toolkit/edit/reducer"
import { useDispatch } from "react-redux"

const GroupMarker = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    background-color: var(--primary500);
    font-size: 1.6em;
    height: 100%;
    border-radius: 50%;
`

const iconCreateFunction = (cluster) => {
	return L.divIcon({
		html: ReactDOMServer.renderToString(
			<GroupMarker>{cluster.getChildCount()}</GroupMarker>,
		),
		iconSize: L.point(40, 40, true),
		className: null,
	})
}

function AutoFitMarkerClusterGroup({ children, ...props }) {
	const map = useMap()
	const ref = useRef()

	const dispatch = useDispatch()
	const coopFilters = useCooperationFilters()

	useEffect(() => {
		if (!coopFilters.changeMapView) return
		dispatch(setCooperationFilters({ changeMapView: false }))

		const group = ref?.current
		if (group) {
			const bounds = (group as any).getBounds()
			if (bounds.isValid())
				map.fitBounds(bounds, {
					maxZoom: 15,
					paddingTopLeft: [50, 120],
					paddingBottomRight: [50, 50],
				})
		}
	}, [coopFilters.changeMapView])

	return (
		<MarkerClusterGroup
			ref={ref}
			{...props}
			iconCreateFunction={iconCreateFunction}
		>
			{children}
		</MarkerClusterGroup>
	)
}

export default AutoFitMarkerClusterGroup
