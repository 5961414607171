import useSelectedEvent from "@/hooks/useSelectedEvent"
import Debug from "debug"
import _ from "lodash"
import React from "react"
import { useFormContext } from "react-hook-form"
import {
	PrimaryBaseButton,
	PrimaryButton,
	PrimaryOutlinedButton,
} from "@/utils/components/style/button"
import { Flex } from "@/utils/components/style/flex"
import { DaybookAlertType } from "@/utils/types/daybook/IDaybookAlert"
import DaybookAlertRightContainer from "../../../../components/DaybookAlertRightContainer"
import useCommune from "@/hooks/useCommune"
import { useCampaignCost } from "../CampaignCostCard/CampaignCostCard.hook"
import FIconRadios from "@/utils/form/FIconRadios"
import GroupsIcon from "@mui/icons-material/Groups"
import MapIcon from "@mui/icons-material/Map"
import DaybookAlertPrepareUsingModal from "./DaybookAlertPrepareUsingModal"
import DaybookAlertPrepareUsingTable from "./DaybookAlertPrepareUsingTable"
import { H5 } from "@/utils/components/style/header"
import styled from "styled-components"
import Button from "@/styles/atoms/Button"

// app:javascript:components:maincourante:alert:alertCreation:DaybookAlertPrepareDiffuseList.tsx
const debug = Debug(
	"app:javascript:components:maincourante:alert:alertCreation:DaybookAlertPrepareDiffuseList",
)
debug.log = console.log.bind(console)

const FlexContainer = styled(Flex)`
	padding-top: 0.5rem;
	overflow-y: auto;
`

const DaybookAlertPrepareDiffuseList = () => {
	const { watch, setValue } = useFormContext()
	const commune = useCommune()
	const { cost } = useCampaignCost()
	const freeAccount = commune?.free_credits === 0
	const disabled =
		cost["Nombre de crédits gratuits restants"] <
			cost["Cout de la campagne en crédits"] && freeAccount
	const selectedEvent = useSelectedEvent()
	const isLocked = selectedEvent?.locked

	const selectionMethod = watch("geojson.properties.method")
	const listeDiffusionIds = watch("geojson.properties.listeDiffusionIds")

	const resetSelectionProcess = () => {
		setValue("geojson.properties.method", undefined)
		setValue("geojson.properties.listeDiffusions", [])
		setValue("geojson.properties.listeDiffusionIds", [])
		setValue("geojson.properties.includeFixedPhones", true)
	}

	return (
		<DaybookAlertRightContainer directionColumn gap="1rem">
			<Flex fullWidth>
				<H5>Liste de diffusion</H5>
			</Flex>
			{!selectionMethod && (
				<FIconRadios
					disabled={disabled}
					name="geojson.properties.method"
					label="Méthode de sélection"
					padding="0.5rem"
					iconOptions={[
						{
							icon: <GroupsIcon />,
							value: "table",
							label: "Annuaires",
						},
						{
							icon: <MapIcon />,
							value: "cartography",
							label: "Zone",
						},
					]}
				/>
			)}
			<FlexContainer fullWidth gap="1rem" directionColumn>
				{selectionMethod === "table" && <DaybookAlertPrepareUsingTable />}
				{selectionMethod === "cartography" && <DaybookAlertPrepareUsingModal />}
			</FlexContainer>
			{selectionMethod && (
				<Flex fullWidth flexEnd gap="1rem">
					<Button
						color="primary"
						variant="bordered"
						onClick={() => resetSelectionProcess()}
					>
						Précédent
					</Button>
					<Button
						color="primary"
						onClick={() =>
							setValue(
								"geojson.properties.status",
								DaybookAlertType.PREPARE_MESSAGE,
							)
						}
						disabled={isLocked || _.isEmpty(listeDiffusionIds)}
					>
						Suivant
					</Button>
				</Flex>
			)}
		</DaybookAlertRightContainer>
	)
}

export default DaybookAlertPrepareDiffuseList
