import {
	DialogActions,
	DialogTitle,
	Divider,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableRow,
} from "@mui/material"
import React from "react"
import styled, { css } from "styled-components"
import PointPropertyValue from "@/utils/components/jsonSchema/properties/PointPropertyValue"
import { getPointTitle } from "@/utils/components/jsonSchema/useGetPointSummary"
import SDialog from "@/utils/components/SDialog"

import {
	GreenOutlinedChip,
	OrangeOutlinedChip,
	RedOutlinedChip,
} from "@/utils/components/style/chip"
import { Flex } from "@/utils/components/style/flex"
import { IJsonSchemaProperty } from "@/utils/types/IJsonSchema"
import IMapping from "@/utils/types/IMapping/IMapping"
import { IImportDiffItem } from "../utils/buildImportDiff"
import { DiffSpan } from "@/utils/components/jsonSchema/properties/propertiesType/PropertiesType.styled"
import CancelButton from "@/styles/atoms/Button/specialized/CancelButton"

const InitialFontSize = styled.div`
    font-size: initial;
`

const Title = ({
	diffItem,
	jsonSchemaProperties,
}: {
	diffItem: IImportDiffItem
	jsonSchemaProperties: IJsonSchemaProperty[]
}) => {
	switch (diffItem.status) {
		case "added":
			return <div>{getPointTitle(jsonSchemaProperties, diffItem.new)}</div>
		case "removed":
			return <div>{getPointTitle(jsonSchemaProperties, diffItem.old)}</div>
		case "changed":
			const oldTitle = getPointTitle(jsonSchemaProperties, diffItem.old)
			const newTitle = getPointTitle(jsonSchemaProperties, diffItem.new)
			if (oldTitle === newTitle) return <div>{newTitle}</div>
			return (
				<InitialFontSize>
					<DiffSpan>{oldTitle}</DiffSpan> <DiffSpan add>{newTitle}</DiffSpan>
				</InitialFontSize>
			)
		default:
			return null
	}
}

export const DiffIcon = ({ status }: { status: IImportDiffItem["status"] }) => {
	switch (status) {
		case "added":
			return <GreenOutlinedChip variant="outlined" label="Ajoutée" />
		case "removed":
			return <RedOutlinedChip variant="outlined" label="Supprimée" />
		case "changed":
			return <OrangeOutlinedChip variant="outlined" label="Modifiée" />
		default:
			return null
	}
}

const STableRow = styled(TableRow)<{ disabled?: boolean }>`
    ${({ disabled }) =>
			disabled &&
			css`
            filter: opacity(0.5);
            background-color: #eee;
        `}
`

const DiffDisplayItemModal = ({
	open,
	onClose,
	diffItem,
	jsonSchemaProperties,
	mapping,
}: {
	open: boolean
	onClose: () => void
	diffItem: IImportDiffItem
	jsonSchemaProperties: IJsonSchemaProperty[]
	mapping: IMapping
}) => {
	return (
		<SDialog open={open} onClose={onClose} fullWidth>
			<DialogTitle>
				<Flex gap={10} flex={1}>
					<DiffIcon status={diffItem.status} />
					<Flex flex={1} directionColumn alignItemsStart>
						<Title
							diffItem={diffItem}
							jsonSchemaProperties={jsonSchemaProperties}
						/>
					</Flex>
					{diffItem.from !== undefined && (
						<InitialFontSize>(Ligne {diffItem.from + 1})</InitialFontSize>
					)}
				</Flex>
			</DialogTitle>
			<Divider />
			<TableContainer>
				<Table>
					<TableBody>
						{jsonSchemaProperties.map((property) => {
							return (
								<STableRow
									key={property.name}
									disabled={!mapping.schema[property.name]}
								>
									<TableCell>{property.name}</TableCell>
									<TableCell>
										<PointPropertyValue
											value={(diffItem.new ?? diffItem.old)[property.name]}
											property={property}
											diffWith={
												(diffItem.new ? diffItem.old : undefined)?.[
													property.name
												]
											}
										/>
									</TableCell>
								</STableRow>
							)
						})}
					</TableBody>
				</Table>
			</TableContainer>
			<DialogActions>
				<CancelButton onClick={onClose} />
			</DialogActions>
		</SDialog>
	)
}

export default DiffDisplayItemModal
