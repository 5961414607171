import AxiosService from "@/services/AxiosService"
import Axios from "axios"
import { useState } from "react"

export const useHasDoubleAuth = () => {
	const [hasDoubleAuth, setHasDoubleAuth] = useState(false)

	const checkUserDoubleAuth = async (email) => {
		const encodedEmail = encodeURIComponent(email)
		const res = await AxiosService.safeCall(
			Axios.get,
			"/api/user_otp/" + encodedEmail,
		)

		// Check if res is not null to ensure the request was successful and valid
		if (res) {
			if (res.data.double_auth) {
				setHasDoubleAuth(true)
			} else {
				setHasDoubleAuth(false)
			}
		}
		return res?.data?.double_auth
		// If res is null, it means an error or null response was handled in safeCall
	}

	return { hasDoubleAuth, setHasDoubleAuth, checkUserDoubleAuth }
}
