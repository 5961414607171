import React, { useState, useEffect, useMemo, useRef } from "react"
import { useDispatch, useSelector } from "react-redux"
import Debug from "debug"
import styled, { css } from "styled-components"
import Moment from "moment"
import { useHistory } from "react-router-dom"
import _ from "lodash"
import IJsonSchema from "@/utils/types/IJsonSchema"
import { FormProvider, useForm } from "react-hook-form"
import FText from "utils/form/FText"
import { PrimaryButton } from "utils/components/style/button"
import useCommune from "@/hooks/useCommune"
import { updateCommune } from "@/redux-toolkit/commune/ressources"
import { toast } from "react-toastify"
import { Flex } from "utils/components/style/flex"
import useAccessRights from "@/hooks/useAccessRights"
import { JSONSCHEMAS } from "@/redux-toolkit/userSettings/constants"
import { EnumToolPath } from "@/hooks/services/useAccessRightsServices"

// app:javascript:components:admin:formulaire_dynamique:MyForms:MyFormSettings.tsx
const debug = Debug(
	"app:javascript:components:admin:formulaire_dynamique:MyForms:MyFormSettings.tsx",
)
debug.log = console.log.bind(console)

const SFlex = styled(Flex)`
  width: 100%;
`

const MyFormSettings = ({ jsonSchema }: { jsonSchema: IJsonSchema }) => {
	const commune = useCommune()
	const dispatch = useDispatch()
	//@ts-ignore
	const { accessAll, WHITELIST } = useAccessRights(EnumToolPath.JSONSCHEMAS)
	const writePermission = accessAll || !!WHITELIST[jsonSchema.id]?.write

	const methods = useForm({
		defaultValues: {
			"Responsable de la collecte":
				commune?.collectInfos?.[jsonSchema?.id]?.["Responsable de la collecte"],
			Source: commune?.collectInfos?.[jsonSchema?.id]?.Source,
			"Date de dernière mise à jour":
				commune?.collectInfos?.[jsonSchema?.id]?.[
					"Date de dernière mise à jour"
				],
		},
	})

	const onSubmit = (data) => {
		dispatch(
			updateCommune({
				collectInfos: {
					...commune.collectInfos,
					[jsonSchema.id]: {
						...commune.collectInfos[jsonSchema.id],
						"Responsable de la collecte": data["Responsable de la collecte"],
						Source: data.Source,
						"Date de dernière mise à jour":
							data["Date de dernière mise à jour"],
					},
				},
			}),
		)
		methods.reset({
			"Responsable de la collecte": data["Responsable de la collecte"],
			Source: data.Source,
			"Date de dernière mise à jour": data["Date de dernière mise à jour"],
		})
		toast.success("Les modifications ont bien été enregistrées")
	}

	useEffect(() => {
		methods.reset({
			"Responsable de la collecte":
				commune?.collectInfos?.[jsonSchema?.id]?.["Responsable de la collecte"],
			Source: commune?.collectInfos?.[jsonSchema?.id]?.Source,
			"Date de dernière mise à jour":
				commune?.collectInfos?.[jsonSchema?.id]?.[
					"Date de dernière mise à jour"
				],
		})
	}, [commune])

	return (
		<>
			<FormProvider {...methods}>
				<SFlex gap="1rem" directionColumn>
					<FText
						name="Responsable de la collecte"
						multiline
						disabled={!writePermission}
					/>
					<FText name="Source" multiline disabled={!writePermission} />
					<FText
						name="Date de dernière mise à jour"
						multiline
						disabled={!writePermission}
					/>
					{methods.formState.isDirty && (
						<PrimaryButton onClick={methods.handleSubmit(onSubmit)}>
							Enregistrer les modifications
						</PrimaryButton>
					)}
				</SFlex>
			</FormProvider>
		</>
	)
}

export default MyFormSettings
