import { Vigilance } from "./IFiche"
import ILocal from "@/utils/types/ILocal"

export interface IHystoryElementDescriptionDetailed {
	oldValue: string
	newValue: string
}
export interface IHystoryElementDescription {
	Objet: string
	details: {
		[key: string]: string | IHystoryElementDescriptionDetailed
	}
}
export interface IHystoryElement {
	type: string
	title?: string
	subTitle?: string
	objectId?: string
	date?: string
	description?: string | IHystoryElementDescription
	status?: string
	noAction?: boolean
}

export interface IHystoryElementAugmented extends IHystoryElement {
	icon?: JSX.Element
	color?: string
	editIcon?: JSX.Element
}

export const NOBODY = "nobody"

export const ABSENT = "absent"

export const PRESENT = "present"

type IOrgaSubstitutions = {
	[key: string]: string | typeof NOBODY
}

interface IEvent {
	id: string
	mapSignalMarkers?: any
	name?: string
	suiviJsonSchemaActivated?: string[]
	risk_type?: string[]
	commune_id?: string
	scenarioIds?: string[]
	active?: boolean
	startDate: string
	endDate: string
	deleted?: boolean
	locked?: boolean
	updated_at?: string
	multiPolygonsIds?: string[]
	vigilance: Vigilance
	orga: {
		substitutions: IOrgaSubstitutions
		absents: string[]
	}
	history: IHystoryElement[]
	scenarioActionIds: {
		actionId: string
		date: string
	}[]
}

export interface IEventLocal extends IEvent, ILocal {}

export default IEvent
