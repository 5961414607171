import SynchroService from "@/services/SynchroService"
import { useSelector } from "react-redux"
import createDeepEqualSelector from "@/utils/createDeepEqualSelector"
import IPoint from "utils/types/IPoint"

const selectPoints = createDeepEqualSelector(
	(state: any) => state.data.points,
	(state: any) => state.userSettings.commune_id ?? state.commune.id,
	(points, commune_id) =>
		SynchroService.notDeletedWithCurrentCommune(points, commune_id, {
			isValidated: false,
		}),
)

const useNotValidatedPoints: () => { [key: string]: IPoint } = () => {
	return useSelector(selectPoints) ?? {}
}

export default useNotValidatedPoints
