import { MenuItem, Select } from "@mui/material"
import React from "react"
import ToolbarPart from "./ToolbarPart"
import useEditorFocus from "./useEditorFocus"

function BlockToolbar({ currentState, onChange, config: { options } }) {
	const { blockType } = currentState

	const optionsTranslation = {
		Normal: "Paragraphe",
		H1: "Titre 1",
		H2: "Titre 2",
		H3: "Titre 3",
		H4: "Titre 4",
		H5: "Titre 5",
		H6: "Titre 6",
		Blockquote: "Citation",
		Code: "Code",
	}

	const { focus, delay } = useEditorFocus()

	return (
		<ToolbarPart>
			<Select
				onClose={focus}
				value={blockType}
				onChange={delay((ev) => onChange(ev.target.value))}
				style={{ minWidth: "140px" }}
				size="small"
			>
				{options.map((option) => (
					<MenuItem key={option} value={option}>
						{optionsTranslation[option] ?? option}
					</MenuItem>
				))}
			</Select>
		</ToolbarPart>
	)
}

export default BlockToolbar
