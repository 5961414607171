import { yupResolver } from "@hookform/resolvers/yup"
import { Alert } from "@mui/material"
import useUploadedFiles from "@/hooks/useUploadedFiles"
import React, { useEffect, useMemo } from "react"
import { FormProvider, useForm } from "react-hook-form"
import FList from "utils/form/FList"
import { LinkType } from "utils/types/IFiche"
import { IFileConfigCategory } from "utils/types/IUploadedFile"
import Yup from "utils/yup"
import useParentSubmit from "./utils/parentSubmit/useParentSubmit"

export const uploadedToLinkMapping: {
	[key in IFileConfigCategory]: LinkType
} = {
	["CARTO"]: LinkType.UPLOADED_CARTO,
	["IMAGE"]: LinkType.UPLOADED_IMAGE,
	["CONVENTION"]: LinkType.UPLOADED_CONVENTION,
	["EDITABLE"]: LinkType.UPLOADED_EDITABLE,
	["PEDAGOGIQUE"]: LinkType.RISK_FILE,
	["PROCEDURE"]: LinkType.STATIC_FILE,
}

const uploadedFileLink = (category: keyof typeof uploadedToLinkMapping) => {
	const schema = Yup.object().shape({
		type: Yup.string().default(uploadedToLinkMapping[category]),
		id: Yup.string().required("Aucun fichier séléctionné"),
	})

	return ({ links, requestSelect }) => {
		const methods = useForm({
			resolver: yupResolver(schema),
			defaultValues: schema.cast({}),
		})

		useParentSubmit(methods.handleSubmit)

		const uploadedFiles = useUploadedFiles()
		const items = useMemo(
			() =>
				Object.values(uploadedFiles).filter(
					(file) => file.config.category === category,
				),
			[uploadedFiles],
		)

		const disabledItems = useMemo(() => {
			return links
				.filter((link) => link.type === uploadedToLinkMapping[category])
				.map((link) => link.id)
		}, [links])

		useEffect(() => {
			//@ts-ignore
			if (requestSelect) methods.setValue("id", requestSelect)
		}, [requestSelect])

		return (
			<FormProvider {...methods}>
				{requestSelect && (
					<Alert severity="success">
						Le fichier a été téléversé et sélectionné, cliquez sur
						&quot;Ajouter&quot; pour créer le lien.
					</Alert>
				)}
				<FList
					name="id"
					disabledItems={disabledItems}
					items={items}
					idAccessor={(item) => item.id}
					titleAccessor={(item) => item.filename}
				/>
			</FormProvider>
		)
	}
}

export default uploadedFileLink
